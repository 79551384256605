import { styled } from "styletron-react";

const Container = styled("div", {
  // maxWidth: "1000px",
  // // margin: "auto",
  // "@media screen and (max-width: 1360px)": {
  //   maxWidth: "1032px"
  // },
});

export default Container;
