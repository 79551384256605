import { Box } from "@chakra-ui/react";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  DragDropContext,
  DraggableLocation,
  Droppable,
  DroppableProvided,
  DropResult,
} from "react-beautiful-dnd";
import reorder, { reorderCandidateMap } from "src/util/board";
import { CandidateFunnelStage } from "./CandidateFunnelStage";
import { Candidate, CandidateMap } from "./CandidateFunnelTypes";

export type CandidateFunnelBoardProps = {
  initial: CandidateMap;
  setColumns: (c: CandidateMap) => {};
  initialOrder: string[];
  setOrdered: (l: string[]) => {};
  withScrollableColumns?: boolean;
  isCombineEnabled?: boolean;
  containerHeight?: string;
  useClone?: boolean;
  renderItem: (candidate: Candidate) => ReactNode;
  renderHeader: (lane: string) => ReactNode;
  onColumnsUpdate: (newLanes: string[]) => Promise<void>;
  onItemUpdate: (
    itemId: string,
    fromLaneId: string,
    fromPositions: string[],
    toLaneId: string,
    toPositions: string[]
  ) => Promise<void>;
};

export const CandidateFunnelBoard = React.memo(
  ({
    initial: columns,
    initialOrder: ordered,
    setColumns,
    setOrdered,
    containerHeight,
    useClone,
    isCombineEnabled,
    renderItem,
    renderHeader,
    withScrollableColumns,
    onColumnsUpdate,
    onItemUpdate,
  }: CandidateFunnelBoardProps) => {
    // const [columns, setColumns] = useState(initial);
    // const [ordered, setOrdered] = useState(initialOrder);

    // useEffect(() => {
    //   setColumns(initial);
    //   setOrdered(initialOrder);
    // }, [initial, initialOrder]);

    const onDragEnd = (result: DropResult) => {
      if (result.combine) {
        if (result.type === "COLUMN") {
          const shallow: string[] = [...ordered];
          shallow.splice(result.source.index, 1);
          setOrdered(shallow);
          return;
        }

        const column: Candidate[] = columns[result.source.droppableId];
        const withQuoteRemoved: Candidate[] = [...column];
        withQuoteRemoved.splice(result.source.index, 1);
        const newColumns: CandidateMap = {
          ...columns,
          [result.source.droppableId]: withQuoteRemoved,
        };
        setColumns(newColumns);
        return;
      }

      // dropped nowhere
      if (!result.destination) {
        return;
      }

      const source: DraggableLocation = result.source;
      const destination: DraggableLocation = result.destination;

      // did not move anywhere - can bail early
      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return;
      }

      // reordering column
      if (result.type === "COLUMN") {
        const newOrdered: string[] = reorder(
          ordered,
          source.index,
          destination.index
        );
        setOrdered(newOrdered);
        onColumnsUpdate(newOrdered);
        return;
      }

      const data = reorderCandidateMap({
        candidateMap: columns,
        source,
        destination,
      });
      setColumns(data.candidateMap);
      onItemUpdate(
        result.draggableId,
        source.droppableId,
        data.candidateMap[source.droppableId].map((d) => d.id),
        destination.droppableId,
        data.candidateMap[destination.droppableId].map((d) => d.id)
      );
    };

    console.log("lanes", ordered);
    console.log("columns", columns);
    const board = (
      <Droppable
        droppableId="board"
        type="COLUMN"
        direction="horizontal"
        ignoreContainerClipping={Boolean(containerHeight)}
        isCombineEnabled={isCombineEnabled}
      >
        {(provided: DroppableProvided) => (
          <Box
            minHeight="100vh"
            // minWidth="100vw"
            display="inline-flex"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {ordered.map((key: string, index: number) => (
              <CandidateFunnelStage
                key={key}
                index={index}
                title={key}
                candidates={columns[key]}
                renderItem={renderItem}
                renderHeader={renderHeader}
                isScrollable={withScrollableColumns}
                isCombineEnabled={isCombineEnabled}
                useClone={useClone}
              />
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    );
    return (
      <React.Fragment>
        <DragDropContext onDragEnd={onDragEnd}>
          {containerHeight ? (
            <Box
              height={containerHeight}
              overflow="hidden"
              // overflowX="hidden"
              // overflowY="auto"
            >
              {board}
            </Box>
          ) : (
            board
          )}
        </DragDropContext>
        {/* <Global
        styles={css`
          body {
            background: ${colors.B200};
          }
        `}
      /> */}
      </React.Fragment>
    );
  }
);
