import React from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Input,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { AutoComplete, AutoCompleteItem } from "src/components/AutoComplete";
import { searchEntity } from "src/api/entity";
import { UserProps } from "src/api/userT";

export type UpdateUserFormProps = UserProps;

export const UpdateUserForm = ({
  values,
  onChange,
}: {
  values: UserProps;
  onChange: (values: UserProps) => void;
}) => {
  const {
    control,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useForm<UpdateUserFormProps>({
    defaultValues: values,
  });

  useEffect(() => {
    const subscription = watch(() => {
      const values = getValues();
      onChange({ ...values });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <form>
      <Box mr={6} flex={1}>
        <FormControl>
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input disabled {...register("email")} />
          <Box height={"40px"}>
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="phone">Phone</FormLabel>
          <Input disabled {...register("phone")} />
          <Box height={"40px"}>
            <FormErrorMessage>{errors.phone}</FormErrorMessage>
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor={`roles`}>Roles</FormLabel>
          <Controller
            name={`roles`}
            control={control}
            render={({ field: { value, ...rest } }) => {
              return (
                <>
                  <AutoComplete
                    {...rest}
                    items={["ADMIN", "DATAVERIFIER", "APPROVER"].map((v) => ({
                      id: v,
                      name: v,
                    }))}
                    id={`roles`}
                    isMultiple
                    isSearchable={false}
                    selected={(value || []).map((v) => ({ id: v, name: v }))}
                    onItemSelect={(
                      value: AutoCompleteItem | AutoCompleteItem[]
                    ) => {
                      setValue(
                        `roles`,
                        (value as AutoCompleteItem[]).map((v) => v.id)
                      );
                    }}
                    fetchItems={async (q) => searchEntity("job", q)}
                  />
                  <Box height={"40px"}>
                    <FormErrorMessage>
                      {errors.roles?.map((r) => r.message).join()}
                    </FormErrorMessage>
                  </Box>
                </>
              );
            }}
          />
        </FormControl>
      </Box>
    </form>
  );
};
