import React from "react";
import { styled } from "styletron-react";
import ScrollToTop from "../components/ScrollToTop";

const PageWrapper = styled("div", {
  marginTop: "50px",
  // minWidth: "360px",
  display: "flex",
  zIndex: 1,
  // background: "#F7F6F3", //"#f4f5f7", //#da0f0f
  // position: "relative",
  // marginLeft: "300px",
  // marginRight: "400px",
  // "@media screen and (max-width: 1360px)": {
  //   // marginLeft: "300px",
  //   // marginRight: "400px",
  // },
  // "@media screen and (max-width: 780px)": {
  //   // marginLeft: "0px",
  //   // marginRight: "0px",
  //   paddingLeft: "16px",
  //   paddingRight: "16px",
  // },
});

export default (props) => (
  <PageWrapper>
    <ScrollToTop />
    {props.children}
  </PageWrapper>
);
