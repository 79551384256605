import React from "react";
import { PinCode } from "baseui/pin-code";
import Button from "./Button";
import { Link } from "react-router-dom";
import Bold from "./Bold";
import { styled, withStyle } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { Block } from "baseui/block";
import { Icon } from "./Icon";
import Card from "./Card";

const Resend = styled("div", {
  marginTop: "8px",
  marginBottom: "32px",
});

const ResendText = styled("div", {
  fontSize: "12px",
  color: "#919191",
});

const DetailText = styled("div", {
  marginTop: "8px",
  marginBottom: "8px",
});

const Title = withStyle(Bold, {
  fontSize: "18px",
});

class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendType: props.sendType,
      sendId: props.sendId,
      displaySendId: props.displaySendId,
      timeRemaining: 30,
      isVerified: props.isVerified,
      isVerifying: props.isVerifying,
      isResending: props.isResending,
      editUrl: props.editUrl,
      values: ["", "", "", "", "", ""],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isVerified !== this.props.isVerified) {
      this.setState({
        isVerified: this.props.isVerified,
      });
    }
    if (prevProps.isVerifying !== this.props.isVerifying) {
      this.setState({
        isVerifying: this.props.isVerifying,
      });
    }
    if (prevProps.isResending !== this.props.isResending) {
      this.setState({
        isResending: this.props.isResending,
      });
    }
    if (prevProps.sendId !== this.props.sendId) {
      this.setState({
        sendType: this.props.sendType,
        sendId: this.props.sendId,
      });
    }
  }

  componentDidMount() {
    this.updateTimeRemaining();
  }

  updateTimeRemaining() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      let timeRemaining = this.state.timeRemaining;
      if (timeRemaining > 0) {
        this.setState({
          timeRemaining: this.state.timeRemaining - 1,
        });
        this.updateTimeRemaining();
      }
    }, 1000);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  getSendTypeName() {
    switch (this.state.sendType) {
      case "email":
        return "email";
      case "workemail":
        return "work email";
      case "phone":
        return "phone number";
      default:
        return "";
    }
  }

  getDetailText() {
    if (this.state.sendType === "email") {
      return (
        "Please enter the 6 digit code that we sent to your email id " +
        this.state.displaySendId +
        "."
      );
    }
    if (this.state.sendType === "phone") {
      return (
        "Please enter the 6 digit code that we sent to your phone number " +
        this.state.displaySendId +
        "."
      );
    }
    if (this.state.sendType === "workemail") {
      return (
        "Please enter the 6 digit code that we sent to your work email id " +
        this.state.displaySendId +
        "."
      );
    }
  }

  getSuccessMessage() {
    if (this.state.sendType === "workemail") {
      return (
        "We have successfully verified your work email id " +
        this.state.displaySendId
      );
    }
    if (this.state.sendType === "email") {
      return (
        "We have successfully verified your email id " +
        this.state.displaySendId
      );
    }
    if (this.state.sendType === "phone") {
      return (
        "We have successfully verified your phone number " +
        this.state.displaySendId
      );
    }
  }

  renderIcon() {
    if (this.state.sendType === "workemail") {
      return (
        <Block $paddingRight="scale400">
          <Icon name="mail" size="30"></Icon>
        </Block>
      );
    }
    if (this.state.sendType === "email") {
      return (
        <Block $paddingRight="scale400">
          <Icon name="mail" size="30"></Icon>
        </Block>
      );
    }
    if (this.state.sendType === "phone") {
      return (
        <Block $paddingRight="scale400">
          <Icon name="phone" size="30"></Icon>
        </Block>
      );
    }
  }

  render() {
    return (
      <Card>
        <div></div>
        {this.state.isVerified ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Block $paddingRight="scale400">
              <Icon name="check" size="40"></Icon>
            </Block>
            {this.getSuccessMessage()}
          </div>
        ) : (
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {this.renderIcon()}
              <Title>{"Verify your " + this.getSendTypeName()}</Title>
            </div>
            <DetailText>{this.getDetailText()}</DetailText>
            <PinCode
              values={this.state.values}
              onChange={({ values }) => this.setState({ values })}
            />
            <Resend>
              {this.state.timeRemaining > 0 ? (
                <ResendText>
                  {"Not received ? Resend in " +
                    this.state.timeRemaining +
                    " seconds"}
                </ResendText>
              ) : (
                <React.Fragment>
                  <Button
                    kind={KIND.tertiary}
                    size={SIZE.mini}
                    isLoading={this.state.isResending}
                    onClick={async () => await this.props.resendCode()}
                  >
                    Resend Code
                  </Button>
                  <ResendText>
                    {this.state.isResending
                      ? "Sending code. Please wait..."
                      : ""}
                  </ResendText>
                </React.Fragment>
              )}
            </Resend>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                isLoading={this.state.isVerifying}
                onClick={async () =>
                  await this.props.verifyCode(this.state.values.join(""))
                }
              >
                {"Verify"}
              </Button>
              <Link to={this.state.editUrl}>
                <Button kind={KIND.minimal}>
                  {"Edit " + this.props.displaySendId}
                </Button>
              </Link>
            </div>
          </div>
        )}
      </Card>
    );
  }
}

export default Verify;
