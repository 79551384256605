import React from "react";
import { withRouter } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import {
  EMPLOYER_PROFILE_COMPANY,
  EMPLOYER_PROFILE_DETAILS,
  EMPLOYER_PROFILE_REVIEW,
  EMPLOYER_PROFILE_WORKSPACE,
  EMPLOYER_POST_JOB,
} from "../util/routes";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";

class SidebarEmployerProfile extends React.Component {
  onPromoteProfileChange() {}

  render() {
    return (
      <div>
        {this.props.user.employerProfile ? (
          <SidebarItem
            enabled
            title={"Back to home"}
            icon={"arrow-left"}
            onClick={() => {
              this.props.history.push(EMPLOYER_POST_JOB);
            }}
          />
        ) : null}
        <SidebarItem
          enabled={
            (this.props.user.employerOnboarding || []).indexOf("basic") !== -1
          }
          title={"Personal Info"}
          icon={"user"}
          selected={this.props.page === "company"}
          onClick={() => this.props.history.push(EMPLOYER_PROFILE_COMPANY)}
        />
        <SidebarItem
          icon={"briefcase"}
          enabled={
            (this.props.user.employerOnboarding || []).indexOf("work") !== -1
          }
          title={"Work details"}
          onClick={() => this.props.history.push(EMPLOYER_PROFILE_DETAILS)}
          selected={this.props.page === "details"}
        />
        <SidebarItem
          icon={"box"}
          enabled={
            (this.props.user.employerOnboarding || []).indexOf("work") !== -1
          }
          title={"Hiring Workspace"}
          onClick={() => this.props.history.push(EMPLOYER_PROFILE_WORKSPACE)}
          selected={this.props.page === "workspace"}
        />
        <SidebarItem
          enabled={
            (this.props.user.employerOnboarding || []).indexOf("work") !== -1
          }
          icon={"check-square"}
          title={"Review"}
          onClick={() => this.props.history.push(EMPLOYER_PROFILE_REVIEW)}
          selected={this.props.page === "review"}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(SidebarEmployerProfile));
