import React, { useState } from "react";
import { Slider } from "baseui/slider";

const FilterSliderMenu = ({
  onChange,
  appliedFilters = [0, 5],
  min = 0,
  max = 40,
  step = 1,
  name,
}) => {
  if (appliedFilters.length === 0) appliedFilters = [0, 5];
  const [currentVal, setCurrentVal] = useState(appliedFilters);
  return (
    <div style={{ width: 200, minHeight: 40 }}>
      <Slider
        min={min || 0}
        max={max || 40}
        step={step || 1}
        value={currentVal || [0, (max || 10) / 2]}
        onChange={({ value }) => setCurrentVal(value)}
        onFinalChange={({ value }) => {
          onChange(name, value);
        }}
      />
    </div>
  );
};

export default FilterSliderMenu;

// tipFormatter={props.formatter}
// marks={props.marks}
// defaultValue={props.appliedFilters || [0, 20]}
