import React, { useState } from "react";
import { connect } from "react-redux";
import { setUser } from "../../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../../components/Title";
import Select from "../../components/Select";
import Pagination from "../../components/Pagination";
import {
  getPublicJobApplications,
  updatePublicJobApplications,
} from "../../api/public_job_applications";
import {
  StyledCell,
  StyledHead,
  StyledHeadCell,
  StyledRow,
  StyledTable,
  StyledBody,
} from "baseui/table";
import { Block } from "baseui/block";
import { ParagraphSmall, ParagraphXSmall } from "baseui/typography";
import Button from "../../components/Button";

const JobApplication = ({ jobApplication, index, onAccept, onReject }) => {
  return (
    <StyledRow key={index}>
      <StyledCell>
        <a href={jobApplication.resume} target="_blank">
          Resume
        </a>
      </StyledCell>
      {/* <StyledCell>
        <Block>
          <ParagraphSmall>{jobApplication.email}</ParagraphSmall>
          <ParagraphXSmall>
            {jobApplication.phonePrefix.dialCode + jobApplication.phone}
          </ParagraphXSmall>
          <ParagraphXSmall>
            {jobApplication.location.name}
            {jobApplication.linkedInProfile && (
              <a href={jobApplication.linkedInProfile} target="_blank">
                {" LinkedIn"}
              </a>
            )}
          </ParagraphXSmall>
        </Block>
      </StyledCell> */}
      <StyledCell>
        <Block>
          <ParagraphSmall>{jobApplication.job.jobTitle.name}</ParagraphSmall>
          <ParagraphXSmall>{jobApplication.job.company.name}</ParagraphXSmall>
        </Block>
      </StyledCell>
      <StyledCell>
        {jobApplication.status === "ACCEPTED" && <Block>Accepted</Block>}
        {jobApplication.status === "REJECTED" && <Block>Rejected</Block>}
        {(jobApplication.status === "CREATED" || !jobApplication.status) && (
          <>
            <Button onClick={onAccept}>Accept</Button>
            <Block width="16px"></Block>
            <Button onClick={onReject} kind="tertiary">
              Reject
            </Button>
          </>
        )}
      </StyledCell>
    </StyledRow>
  );
};

class JobApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      jobApplications: [],
      hasNext: false,
      hasPrev: false,
      filter: { id: "CREATED", name: "New" },
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    try {
      let { data: jobApplications, hasNext } = await getPublicJobApplications(
        this.state.filter.id,
        this.state.page,
        this.state.limit
      );
      this.setState({
        jobApplications,
        hasNext,
      });
    } catch (e) {
      console.log(e);
    }
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onUpdateStatus = async (id, status) => {
    try {
      await updatePublicJobApplications(id, status);
      await this.onLoad();
    } catch (e) {}
  };

  render() {
    return (
      <div>
        <Block
          display="flex"
          justifyContent="space-between"
          marginBottom="scale600"
        >
          <Title>Job Applications</Title>
          <Block width="150px">
            <Select
              clearable={false}
              defaultOptions={[
                { id: "", name: "All" },
                { id: "CREATED", name: "New" },
                { id: "ACCEPTED", name: "Accepted" },
                { id: "REJECTED", name: "Rejected" },
              ]}
              onChange={(value) => {
                this.setState(
                  {
                    filter: value,
                    page: 0,
                  },
                  () => {
                    this.onLoad();
                  }
                );
              }}
              value={this.state.filter}
            />
          </Block>
        </Block>
        <div style={{ marginBottom: "32px", width: "1000px" }}>
          <StyledTable>
            <StyledHead>
              <StyledHeadCell>Name</StyledHeadCell>
              {/* <StyledHeadCell>Details</StyledHeadCell> */}
              <StyledHeadCell>Job</StyledHeadCell>
              <StyledHeadCell>Actions</StyledHeadCell>
            </StyledHead>
            <StyledBody>
              <div style={{ marginBottom: "32px" }}>
                {this.state.jobApplications.map((jobApplication, index) => (
                  <JobApplication
                    history={this.props.history}
                    key={index}
                    jobApplication={jobApplication}
                    onAccept={async () => {
                      await this.onUpdateStatus(jobApplication.id, "ACCEPTED");
                    }}
                    onReject={async () => {
                      await this.onUpdateStatus(jobApplication.id, "REJECTED");
                    }}
                  />
                ))}
              </div>
            </StyledBody>
          </StyledTable>
        </div>
        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(JobApplications));
