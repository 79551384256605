import React from "react";
import Button from "./Button";
import { KIND as BUTTON_KIND, SIZE as BUTTON_SIZE } from "baseui/button";
import ChevronLeft from "baseui/icon/chevron-left";
import ChevronRight from "baseui/icon/chevron-right";
const Pagination = (props) => {
  if (!props.hasPrev && !props.hasNext) return null;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Button
        disabled={!props.hasPrev}
        kind={BUTTON_KIND.tertiary}
        size={BUTTON_SIZE.mini}
        onClick={props.onPrevClick}
      >
        <ChevronLeft />
        Prev
      </Button>
      <div style={{ width: 16 }} />
      <Button
        disabled={!props.hasNext}
        kind={BUTTON_KIND.tertiary}
        size={BUTTON_SIZE.mini}
        onClick={props.onNextClick}
      >
        Next
        <ChevronRight />
      </Button>
    </div>
  );
};

export default Pagination;
