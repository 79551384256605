import React from "react";
import { styled, withStyle } from "baseui";
import StatusPill from "./StatusPill";
import Currency from "./Currency";
import Card from "./Card";
import FlexRow from "./FlexRow";
import Bold from "./Bold";
import { prettyPrint } from "../util/time";
import PopConfirm from "./PopConfirm";
import { removeMyCandidate } from "../api/talentscout";
import { toaster } from "baseui/toast";
import { Icon } from "./Icon";
import { Block } from "baseui/block";
import { ParagraphSmall, ParagraphXSmall } from "baseui/typography";

const Wrapper = withStyle(Card, {
  marginBottom: "16px",
});

const Line = styled("div", {
  height: "1px",
  color: "#843a16",
});

const CandidateStatusMap = {
  PROFILE_NOT_CREATED: {
    // title: "Upload done. Candidate yet to sign-up",
    // description:
    //   "Your candidate has not yet signed-up with FindingJo (1 of 4 steps)",
    title: "Resume created—verification pending.",
    description: "Pending FindingJo approval.(1 of 2 steps)",
  },
  PROFILE_CREATED: {
    // title: "Candidate signed-up. Resume not yet created",
    // description: "Your candidate is yet to create the resume.(2 of 4 steps)",
    title: "Resume created—verification pending.",
    description: "Pending FindingJo approval.(1 of 2 steps)",
  },
  PROFILE_SUBMITTED: {
    title: "Resume created—verification pending.",
    description: "Pending FindingJo approval.(1 of 2 steps)",
  },
  PROFILE_APPROVED: {
    title: "Promote your candidate now.",
    description:
      "Your candidate can now receive an interview request. It will help if you start promoting your candidate's anonymous profile on LinkedIn.(2 of 2 steps)",
  },
  PROFILE_REJECTED: {
    title: "Your candidate's resume has errors. Need editing!",
    description:
      "FindingJo has emailed the details of a few correctable mistakes in your candidate's resume. Ask your candidate to fix them and resubmit for review",
  },
};

const CandidateStatus = ({ status, at }) => {
  const { title, description } = CandidateStatusMap[status];
  return (
    <Block marginTop="scale200" marginBottom="scale200">
      <ParagraphSmall color="primary500">{title}</ParagraphSmall>
      <ParagraphXSmall color="primary500">{description}</ParagraphXSmall>
      {at && (
        <ParagraphXSmall color="primary500">{prettyPrint(at)}</ParagraphXSmall>
      )}
    </Block>
  );
};

const CandidateStatusChanges = ({ candidateStatusChanges }) => {
  if (!candidateStatusChanges) {
    candidateStatusChanges = [];
  }
  candidateStatusChanges.unshift({ status: "PROFILE_NOT_CREATED" });
  candidateStatusChanges = candidateStatusChanges.reverse().slice(0, 1);
  return (
    <div>
      {candidateStatusChanges.map((statusChanges, index) => (
        <>
          <CandidateStatus
            at={statusChanges.at}
            status={statusChanges.status}
          />
          {index !== candidateStatusChanges.length - 1 && <Line />}
        </>
      ))}
    </div>
  );
};

const TSMyCandidateUpload = ({ myCandidate, onRemove }) => {
  return (
    <Wrapper>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <FlexRow style={{ justifyContent: "space-between" }}>
            <Bold>{myCandidate.candidateEmail}</Bold>
            {true || myCandidate.status === "CREATED" ? (
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <PopConfirm
                  showArrow
                  title={
                    <div>
                      <div>
                        {"You want to remove this candidate from your list ?"}
                      </div>
                      <div>
                        {
                          "You will have to sent another talent-scout request if you want to scout for this candidate again"
                        }
                      </div>
                    </div>
                  }
                  confirmText={"Remove"}
                  onConfirm={async () => {
                    try {
                      await removeMyCandidate(myCandidate.id);
                      toaster.positive(
                        "We have removed the candidate from your list",
                        {
                          autoHideDuration: 3000,
                        }
                      );
                      onRemove && onRemove();
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                >
                  <Block $cursor="pointer">
                    <Icon
                      size={20}
                      name="trash-2"
                      onClick={(e) => {
                        // e.preventDefault();
                        // e.stopPropagation();
                      }}
                    ></Icon>
                  </Block>
                </PopConfirm>
              </div>
            ) : null}
          </FlexRow>
          <div>{myCandidate.candidatePhone}</div>
          <Block paddingLeft="scale400" color="primary500">
            <CandidateStatusChanges
              candidateStatusChanges={myCandidate.candidateStatusChanges}
            />
          </Block>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ textAlign: "center" }}>Total Earned</div>
        <div
          style={{ color: "#0F4C81", textAlign: "center", marginLeft: "8px" }}
        >
          <Currency
            currency={myCandidate.totalEarnedCurrency}
            value={myCandidate.totalEarned / 100}
          />
        </div>
      </div>
      <Block display="flex" alignItems="center">
        <div style={{ marginRight: "8px" }}>Status: </div>
        <StatusPill>{myCandidate.status}</StatusPill>
      </Block>
    </Wrapper>
  );
};

export default TSMyCandidateUpload;
