import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { FiChevronsDown, FiMoreVertical, FiTrash } from "react-icons/fi";
import { Education } from "src/lib/types/candidate";
import { EducationForm } from "./EducationForm";
import { EducationRow } from "./EducationList";

const EducationSummary = ({
  education: { college, course, startYear, endYear },
}: {
  education: Education;
}) => {
  return (
    <Box>
      <Text fontWeight={"600"} _groupHover={{ color: "blue.500" }}>
        {(college?.name || "College Name") + " - " + (course?.name || "Course")}
      </Text>
      <Text color="gray.300">
        {startYear || "Start year"}-{endYear || "End year"}
      </Text>
    </Box>
  );
};

export type EducationSectionProps = {
  educationRow: EducationRow;
  onDelete: () => void;
  onDiscard: () => void;
  onSave: (ex: Education) => void;
  isNew?: boolean;
  shouldValidate: boolean;
};

export const EducationSection = ({
  educationRow,
  onDelete,
  onDiscard,
  onSave,
  shouldValidate,
}: EducationSectionProps) => {
  const [showForm, setShowForm] = useState(false);
  useEffect(() => {
    if (educationRow.isNew) setShowForm(true);
  }, [educationRow.isNew]);
  return (
    <Box
      borderRadius="md"
      borderColor="gray.200"
      borderWidth={1}
      mb={2}
      p={4}
      bgGradient="linear(to-r, gray.50, white)"
      boxShadow="6px 6px 25px
      #e1e1e1, -6px -6px 25px #ffffff"
    >
      <Flex
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowForm(educationRow.isNew ? true : !showForm);
        }}
        role="group"
        cursor="pointer"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <EducationSummary education={educationRow.education} />
        <Flex alignItems={"center"}>
          <Flex
            alignItems={"center"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Menu>
              <MenuButton
                mr={2}
                as={IconButton}
                aria-label="More"
                icon={<FiMoreVertical />}
                variant="link"
              />
              <MenuList>
                <MenuItem onClick={onDelete} icon={<FiTrash />}>
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Icon
            as={FiChevronsDown}
            ml="auto"
            transform={showForm ? "rotate(180deg)" : undefined}
          />
        </Flex>
      </Flex>
      <Collapse in={showForm}>
        <EducationForm
          shouldValidate={shouldValidate}
          educationRow={educationRow}
          onSave={async (education) => {
            try {
              await onSave(education);
              setShowForm(false);
            } catch (e) {}
          }}
          onDiscard={onDiscard}
          onDelete={onDelete}
        />
      </Collapse>
    </Box>
  );
};
