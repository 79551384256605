import React, { useState } from "react";
import Button from "./Button";
import { KIND, SIZE } from "baseui/button";
import Card from "./Card";
import Bold from "./Bold";
import * as Analytics from "../analytics";
import {
  approveTalentScoutRequest,
  rejectTalentScoutRequest,
} from "../api/candidate";
import { toaster } from "baseui/toast";
import UniqueIdModalForm from "./UniqueIdModalForm";
import { useDispatch } from "react-redux";
import { fetchUser } from "../redux/actions/user";
import { styled, withStyle } from "baseui";
import { SIZE as BUTTON_SIZE } from "baseui/button";

const Wrapper = withStyle(Card, {
  marginBottom: "8px",
  maxWidth: "720px",
});

const Description = styled("div", {
  marginBottom: "8px",
});

const TSRequest = ({
  user,
  setUser,
  tsRequest,
  onApprove,
  onReject,
  onViewAnonymousResume,
  isAnonymousCandidateLoading,
}) => {
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [idProofModalVisible, setIdProofModalVisible] = useState(false);
  const dispatch = useDispatch();
  const onApproveClick = async () => {
    Analytics.logEvent("[C] click approve talentscout request");
    try {
      setIsApproving(true);
      await approveTalentScoutRequest(tsRequest);
      setIsApproving(false);
      await onApprove();
    } catch (e) {
      setIsApproving(false);
      onApproveError(e);
    }
    dispatch(fetchUser());
  };

  const onRejectClick = async () => {
    Analytics.logEvent("[C] click reject talentscout request");
    try {
      setIsRejecting(true);
      await rejectTalentScoutRequest(tsRequest);
      setIsRejecting(false);
      await onReject();
      toaster.show(<div>Talent-Scout request rejected</div>, {
        autoHideDuration: 3000,
      });
    } catch (e) {
      setIsRejecting(false);
      let { response } = e;
      if (
        response &&
        response.data &&
        response.data.code === "ANONYMOUS_ENTRY_REQUIRED"
      ) {
        toaster.show(<div>{response.data.message}</div>, {
          autoHideDuration: 3000,
        });
      }
    }
    dispatch(fetchUser());
  };

  const onApproveError = (e) => {
    let { response } = e;
    if (
      response &&
      response.data &&
      response.data.code === "ANONYMOUS_ENTRY_REQUIRED"
    ) {
      toaster.show(<div>{response.data.message}</div>, {
        autoHideDuration: 3000,
      });
    }
    if (
      response &&
      response.data &&
      response.data.code === "ERROR_UNIQUE_ID_EMPTY"
    ) {
      setIdProofModalVisible(true);
    }
  };

  return (
    <Wrapper>
      <div>
        <Bold>{tsRequest.talentscoutName}</Bold>
        {tsRequest.status === "CREATED" ? (
          <Description>
            {tsRequest.talentscoutName +
              " would like to arrange interviews for you "}
          </Description>
        ) : null}
        {tsRequest.status === "APPROVED" ||
        tsRequest.status === "USER_VERIFICATION_PENDING" ? (
          <>
            <Description>
              The talent-scout will share your anonymous profile with hiring
              managers and employers looking for a premium candidate like you
            </Description>
            <Button
              isLoading={isAnonymousCandidateLoading}
              onClick={() => {
                onViewAnonymousResume();
              }}
              size={BUTTON_SIZE.mini}
            >
              View your anonymous profile
            </Button>
          </>
        ) : null}
        {tsRequest.status === "REJECTED" ? (
          <Description>You have rejected this request</Description>
        ) : null}
      </div>
      {tsRequest.status === "CREATED" ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <div>
            <Button
              isLoading={isRejecting}
              kind={KIND.minimal}
              size={SIZE.compact}
              onClick={onRejectClick}
            >
              Reject
            </Button>
          </div>
          <div>
            <Button
              isLoading={isApproving}
              type={"primary"}
              size={SIZE.compact}
              onClick={onApproveClick}
            >
              Approve
            </Button>
          </div>
        </div>
      ) : null}
      {tsRequest.status === "USER_VERIFICATION_PENDING" ? (
        <div
          style={{
            padding: "8px",
            border: "1px solid #e5e5e5",
            background: "#f5f5f5",
            borderRadius: "4px",
            marginTop: "8px",
          }}
        >
          Your ID Proof is under review. You will start receiving interview
          requests once the review is complete.
        </div>
      ) : null}
      {tsRequest.status === "USER_VERIFICATION_REJECTED" ? (
        <div
          style={{
            padding: "8px",
            border: "1px solid #e5e5e5",
            background: "#f5f5f5",
            borderRadius: "4px",
            marginTop: "8px",
          }}
        >
          <div>
            <div style={{ marginBottom: "8px" }}>
              <div style={{ color: "#F8756C" }}>
                Your ID Proof submission has been rejected
              </div>
              <div>{tsRequest.statusReason}</div>
            </div>
            <Button
              onClick={() => {
                setIdProofModalVisible(true);
              }}
            >
              Submit ID Proof
            </Button>
          </div>
        </div>
      ) : null}
      <UniqueIdModalForm
        user={user}
        setUser={setUser}
        modalVisible={idProofModalVisible}
        onSubmit={onApproveClick}
        onClose={() => {
          setIdProofModalVisible(false);
        }}
      />
    </Wrapper>
  );
};

export default TSRequest;
