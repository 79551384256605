import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Text,
  Flex,
  ThemeTypings,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Spinner,
  Link,
  useClipboard,
} from "@chakra-ui/react";
import {
  getReferCandidatesMy,
  ReferredCandidateProps,
} from "../../api/referred_candidate";
import { Column, useTable } from "react-table";
import { FiArrowRight } from "react-icons/fi";
import { prettyPrint } from "../../util/time";
import { Pagination } from "../../lib/components/Pagination";
import { keyBy } from "lodash";
import { FaChevronDown } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { getCurrencyWords } from "src/util/currency";
import { EmailShareButton } from "react-share";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { appUrl } from "src/api/endpoints";

export type ScoutReferralsProps = {};

export const getStatusColorScheme = (
  status: string
): ThemeTypings["colorSchemes"] => {
  switch (status) {
    case "PREAPPROVED":
      return "yellow";
    case "NEEDSCORRECTION":
      return "orange";
    case "APPROVED":
      return "green";
    case "REJECTED":
      return "red";
  }
  return "gray";
};

const CopyLink = ({ link }: { link: string }) => {
  const { onCopy, hasCopied } = useClipboard(link);
  return (
    <Button
      variant="link"
      onClick={(e) => {
        e.stopPropagation();
        onCopy();
      }}
    >
      {hasCopied ? "Copied" : "Copy link"}
    </Button>
  );
};

export const ScoutReferrals = (props: ScoutReferralsProps) => {
  const [page, setPage] = useState<number>(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [referredCandidates, setReferredCandidates] = useState([]);
  const [talentscouts, setTalentscouts] = useState<any>();
  const [talentscoutData, setTalentscoutData] = useState<any>();
  const [status, setStatus] = useState("");
  const [isVerifiedFilter, setIsVerifiedFilter] = useState("");
  const history = useHistory();
  const limit = 10;
  const fetchReferredCandidates = async (_page: number, _limit: number) => {
    setIsFetching(true);
    try {
      const {
        data: _data,
        hasNext: _hasNext,
        talentscoutData: _talentscoutData,
      } = await getReferCandidatesMy(
        {
          status,
          sortField: "createdAt",
          sortOrder: "DESC",
          isVerified: isVerifiedFilter,
        },
        _page,
        _limit
      );
      setReferredCandidates(_data);
      setTalentscouts(keyBy(talentscouts, "id"));
      setTalentscoutData(_talentscoutData);
      setHasNext(_hasNext);
    } catch (e) {}
    setIsFetching(false);
  };
  useEffect(() => {
    fetchReferredCandidates(page, limit);
  }, [page, limit, status, isVerifiedFilter]);
  const data = useMemo(() => referredCandidates, [referredCandidates]);
  const getEmailBody = (r: ReferredCandidateProps) => {
    return (
      "Congratulations " +
      r.firstName +
      "," +
      "\n" +
      "Please confirm your details by going to this link" +
      "\n" +
      appUrl +
      "/public/referredCandidate/verification?rid=" +
      r.id
    );
  };
  const columns = useMemo<Column<any>[]>(
    () => [
      {
        Header: "Created At",
        accessor: ({ createdAt }) => (
          <Box>
            <Text color={"gray"}>{prettyPrint(createdAt, true)}</Text>
          </Box>
        ),
      },
      {
        Header: "Candidate",
        accessor: "email",
        Cell: ({ value, row }) => {
          return (
            <Box>
              <Text>{value}</Text>
              <Text>{row.original.phone}</Text>
            </Box>
          );
        },
      },
      {
        Header: "Verified",
        accessor: "isVerified",
        Cell: ({ value, row }) => {
          return (
            <Box>
              <Text>{value ? "Yes" : "No"}</Text>
              <Box>
                {!value ? (
                  <Link
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    variant="link"
                    href={
                      "mailto:" +
                      row.original.email +
                      "?Subject=FindingJo - Confirm your details&body=" +
                      getEmailBody(row.original)
                    }
                    isExternal
                  >
                    Send Verification <ExternalLinkIcon mx="2px" />
                  </Link>
                ) : null}
              </Box>
              {!value ? (
                <CopyLink
                  link={
                    appUrl +
                    "/public/referredCandidate/verification?rid=" +
                    row.original.id
                  }
                />
              ) : null}
            </Box>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <Badge colorScheme={getStatusColorScheme(value)}>{value}</Badge>
        ),
      },
      {
        Header: "Remarks",
        accessor: "statusMessage",
        Cell: ({ value, row }) => (
          <Text>
            {(row.original.status === "REJECTED" ||
              row.original.status === "NEEDSCORRECTION") &&
              value}
          </Text>
        ),
      },
      //   {
      //     Header: "Current Price",
      //     accessor: "noticePeriod",
      //     Cell: ({ value }) => <Text>{value}</Text>,
      //   },
      {
        Header: "",
        accessor: "id",
        Cell: ({ value, row }) =>
          row.original.status !== "APPROVED" && <FiArrowRight />,
      },
    ],
    [talentscouts]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <Box mt={8} ml={4} width="100%">
      <Flex alignItems={"center"} mb={4}>
        <Text textStyle="h3" mr={4}>
          My Referrals
        </Text>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                rightIcon={<FaChevronDown />}
              >
                {status || "Status"}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setStatus("")}>All</MenuItem>
                <MenuItem onClick={() => setStatus("CREATED")}>
                  CREATED
                </MenuItem>
                <MenuItem onClick={() => setStatus("NEEDSCORRECTION")}>
                  NEEDS CORRECTION
                </MenuItem>
                <MenuItem onClick={() => setStatus("APPROVED")}>
                  APPROVED
                </MenuItem>
                <MenuItem onClick={() => setStatus("REJECTED")}>
                  REJECTED
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>

        <Text ml={8}>Verified Status: </Text>
        <Menu>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={Button}
                rightIcon={<FaChevronDown />}
              >
                {isVerifiedFilter === "true"
                  ? "Only Verified"
                  : isVerifiedFilter === "false"
                  ? "Not Verified"
                  : "All"}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => setIsVerifiedFilter("")}>All</MenuItem>
                <MenuItem onClick={() => setIsVerifiedFilter("true")}>
                  Only Verified
                </MenuItem>
                <MenuItem onClick={() => setIsVerifiedFilter("false")}>
                  Not Verified
                </MenuItem>
              </MenuList>
            </>
          )}
        </Menu>
      </Flex>
      {talentscoutData?.needsCorrectionCount > 0 ? (
        <Box backgroundColor="yellow.50" p={4}>
          <Text textStyle={"h3"} color="yellow.800">
            Needs Correction:
          </Text>
          <Text
            mb={2}
          >{`${talentscoutData?.needsCorrectionCount} uploaded candidate profile data needs correction. Do correct and resubmit the profile.`}</Text>
          <Button
            colorScheme={"yellow"}
            onClick={() => {
              setStatus("NEEDSCORRECTION");
            }}
          >
            See Incorrect Profiles
          </Button>
        </Box>
      ) : null}
      <Box mt={4}>
        <Flex mb={4}>
          <Flex
            bg={"gray.100"}
            p={2}
            borderRadius="md"
            justifyContent={"space-between"}
            flex={1}
            mr={4}
          >
            <Stat>
              <StatLabel>Referred</StatLabel>
              <StatNumber>
                {talentscoutData?.referred?.lastMonth || "--"}
              </StatNumber>
              <StatHelpText>Last Month</StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Referred</StatLabel>
              <StatNumber>
                {talentscoutData?.referred?.currentMonth || "--"}
              </StatNumber>
              <StatHelpText>This Month</StatHelpText>
            </Stat>
          </Flex>
          <Flex
            bg={"gray.100"}
            p={2}
            borderRadius="md"
            justifyContent={"space-between"}
            flex={1}
            mr={4}
          >
            <Stat>
              <StatLabel>Approved</StatLabel>
              <StatNumber>
                {talentscoutData?.approved?.lastMonth || "--"}
              </StatNumber>
              <StatHelpText>Last Month</StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Approved</StatLabel>
              <StatNumber>
                {talentscoutData?.approved?.currentMonth || "--"}
              </StatNumber>
              <StatHelpText>This Month</StatHelpText>
            </Stat>
          </Flex>
          <Flex
            bg={"gray.100"}
            p={2}
            pb={0}
            borderRadius="md"
            justifyContent={"space-between"}
            flex={1}
            mr={4}
          >
            <Stat>
              <StatLabel>Earned</StatLabel>
              <StatNumber>
                {talentscoutData?.earned?.lastMonth / 100 || "--"}
              </StatNumber>
              <StatHelpText>Last Month</StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Earned</StatLabel>
              <StatNumber>
                {talentscoutData?.earned?.currentMonth / 100 || "--"}
              </StatNumber>
              <StatHelpText>This Month</StatHelpText>
            </Stat>
          </Flex>
        </Flex>
        {isFetching ? (
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            height="100vh"
            width="100%"
          >
            <Spinner />
          </Flex>
        ) : (
          <Table {...getTableProps()}>
            <Thead>
              {headerGroups.map((headerGroup) => (
                <Tr
                  {...headerGroup.getHeaderGroupProps()}
                  borderBottomWidth={2}
                >
                  {headerGroup.headers.map((column) => (
                    <Th textTransform={"none"} {...column.getHeaderProps()}>
                      <Text textStyle={"label"}>{column.render("Header")}</Text>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr
                    {...row.getRowProps()}
                    _hover={{ background: "gray.100" }}
                    cursor="pointer"
                    onClick={() => {
                      if (row.original.status !== "APPROVED")
                        history.push(
                          "/talentscout/home/referrals/" +
                            row.original.id +
                            "/edit"
                        );
                    }}
                  >
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        )}

        {!isFetching && (
          <Box mt={4}>
            <Pagination page={page} setPage={setPage} hasNext={hasNext} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
