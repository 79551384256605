import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { ReviewPriorityProps } from "src/api/review_priority";
import { PriorityForm, PriorityFormProps } from "./PriorityForm";

export type AddPriorityModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onActionClick: (values: ReviewPriorityProps) => void;
  mode?: "ADD" | "UPDATE";
  job?: any;
  selectedPriority?: ReviewPriorityProps;
};

export const AddPriorityModal = ({
  mode = "ADD",
  selectedPriority,
  job,
  isOpen,
  onClose,
  onActionClick,
}: AddPriorityModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setPriorityValues = useCallback(
    () =>
      selectedPriority
        ? {
            ...selectedPriority,
            jobId: job?.id,
            job: {
              id: job?.id,
              name: job?.company?.name,
            },
          }
        : {
            id: "",
            idType: "JOBID",
            predefinedId: "",
            jobId: job?.id,
            count: 10,
            createdAt: Date.now(),
            modifiedAt: Date.now(),
            updatedBy: "",
            sequenceNo: 0,
            job: {
              id: job?.id,
              name: job?.company?.name,
            },
          },
    [job, selectedPriority]
  );
  const [values, setValues] = useState<PriorityFormProps>(setPriorityValues);
  useEffect(() => {
    setValues(setPriorityValues());
  }, [selectedPriority, job, setPriorityValues]);
  const actionText = mode === "ADD" ? "Add" : "Update";
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`${actionText} Item`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <PriorityForm
            values={values}
            onChange={(values: ReviewPriorityProps) => {
              setValues(values);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await onActionClick(values);
              setIsLoading(false);
              await onClose();
            }}
          >
            {`${actionText}`}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
