import React, { useEffect, useRef, useState } from "react";
import {
  FiAlignCenter,
  FiAlignLeft,
  FiAlignRight,
  FiArrowLeft,
  FiArrowRight,
  FiCopy,
  FiEdit2,
  FiEye,
  FiEyeOff,
  FiMinimize2,
  FiMoreVertical,
  FiMove,
  FiPlus,
  FiPlusCircle,
  FiTrash2,
} from "react-icons/fi";
import {
  IconButton,
  Button,
  Box,
  Heading,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Link,
  useClipboard,
  Spinner,
} from "@chakra-ui/react";
import { getCandidateInfo } from "src/api/admin";
import { AddCandidatesToJobModal } from "./AddCandidatesToJobModal";
import {
  BulkCreateInterviewDrawer,
  BulkCreateInterviewDrawerRef,
} from "src/screens/employer/jobs/BulkCreateInterviewDrawer";

export type FunnelLaneHeaderProps = {
  id: string;
  value: string;
  jobId: string;
  candidateUrls: {
    userId: string;
    experienceDuration: string;
    experienceTitle: string;
    isNoticePeriod: boolean;
    hasAnotherOffer: boolean;
    url: string;
    summary: string;
  }[];
  candidates?: any[];
  interviewRequests?: any;
  onFunnelHide: (id: string) => void;
  onFunnelRename: (id: string, name: string) => void;
  onStageAdd: (name: string, dir: "left" | "right") => Promise<void>;
  onFunnelDelete: (id: string) => void;
  onInterviewCreation: () => void;
  isAdmin?: boolean;
};

export const FunnelLaneHeader = ({
  id,
  value,
  jobId,
  candidateUrls = [],
  onFunnelHide,
  onFunnelRename,
  onFunnelDelete,
  candidates,
  interviewRequests,
  onStageAdd,
  isAdmin,
  onInterviewCreation,
}: FunnelLaneHeaderProps) => {
  console.log(candidates, interviewRequests);
  console.log(
    (candidates || []).filter((c) => !interviewRequests?.[c.userId as string])
  );
  const [val, setVal] = useState(value);
  const [isViewCandidateUrlsOpen, setIsViewCandidateUrlsOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isCandidateInfoModalOpen, setIsCandidateInfoModalOpen] = useState(
    false
  );
  const [
    isAddCandidatesToJDModalOpen,
    setIsAddCandidatesToJDModalOpen,
  ] = useState(false);
  const [addLaneVal, setAddLaneVal] = useState("");
  const [addLaneDir, setAddLanDir] = useState<"" | "left" | "right">("");
  const [isSaving, setIsSaving] = useState(false);
  const { onCopy, hasCopied } = useClipboard(
    candidateUrls.map((cu) => cu.summary).join("\n\n")
  );
  const bulkCreateInterviewDrawerRef = useRef<BulkCreateInterviewDrawerRef>(
    null
  );
  const cancelRef = useRef(null);
  const [candidatesInfo, setCandidatesInfo] = useState<
    { anonymousId: string; email: string; company: { name: string } }[]
  >([]);
  const [isFetchingCandidatesInfo, setIsFetchingCandidatesInfo] = useState(
    false
  );
  const fetchCandidateInfos = async (candidateIds: string[]) => {
    setIsFetchingCandidatesInfo(true);
    try {
      const _candidatesInfo = await getCandidateInfo({
        candidateIds,
      });
      setCandidatesInfo(_candidatesInfo);
    } catch (e) {}
    setIsFetchingCandidatesInfo(false);
  };
  useEffect(() => {
    setVal(value);
  }, [value]);
  useEffect(() => {
    setAddLaneVal("");
  }, [addLaneDir]);
  useEffect(() => {
    if (isCandidateInfoModalOpen) {
      fetchCandidateInfos(candidateUrls.map((cu) => cu.userId));
    }
  }, [isCandidateInfoModalOpen, candidateUrls]);
  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      display="flex"
      height="40px"
      p={2}
      className="card-column-header column-drag-handle"
    >
      <Text fontWeight="medium" fontSize="md">
        {val}
      </Text>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<FiMoreVertical />}
          borderRadius="full"
          variant="ghost"
        />
        <MenuList>
          <MenuItem
            icon={<FiAlignCenter />}
            onClick={() => {
              bulkCreateInterviewDrawerRef.current?.open();
            }}
          >
            Create bulk interviews
          </MenuItem>
          <MenuItem
            icon={<FiEdit2 />}
            onClick={() => {
              setIsRenameModalOpen(true);
            }}
          >
            Rename
          </MenuItem>
          <MenuItem icon={<FiMinimize2 />} onClick={() => onFunnelHide(id)}>
            Hide Stage
          </MenuItem>
          <MenuItem icon={<FiTrash2 />} onClick={() => onFunnelDelete(id)}>
            Delete Stage
          </MenuItem>
          <MenuItem icon={<FiArrowLeft />} onClick={() => setAddLanDir("left")}>
            Add Stage to Left
          </MenuItem>
          <MenuItem
            icon={<FiArrowRight />}
            onClick={() => setAddLanDir("right")}
          >
            Add Stage to Right
          </MenuItem>
          <MenuItem icon={<FiCopy />} onClick={() => onCopy()}>
            {`Copy ${candidateUrls.length} candidate urls`}
          </MenuItem>
          <MenuItem
            icon={<FiEye />}
            onClick={() => setIsViewCandidateUrlsOpen(true)}
          >
            {`View ${candidateUrls.length} candidate urls`}
          </MenuItem>
          {candidateUrls?.length > 0 ? (
            <MenuItem
              icon={<FiPlusCircle />}
              onClick={() => setIsAddCandidatesToJDModalOpen(true)}
            >
              {`Add to another job`}
            </MenuItem>
          ) : null}

          {isAdmin && (
            <MenuItem
              icon={<FiEyeOff />}
              onClick={() => setIsCandidateInfoModalOpen(true)}
            >
              {`Reveal candidates info`}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <AlertDialog
        isOpen={isRenameModalOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => setIsRenameModalOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Enter new name
            </AlertDialogHeader>
            <AlertDialogBody>
              <Input
                value={val}
                onChange={(e: any) => {
                  setVal(e.target.value);
                }}
              ></Input>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setIsRenameModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                colorScheme="orange"
                onClick={() => {
                  onFunnelRename(id, val);
                  setIsRenameModalOpen(false);
                }}
                ml={3}
              >
                Update Name
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog
        isOpen={!!addLaneDir}
        leastDestructiveRef={cancelRef}
        onClose={() => setAddLanDir("")}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Enter stage name
            </AlertDialogHeader>
            <AlertDialogBody>
              <Input
                value={addLaneVal}
                onChange={(e: any) => {
                  setAddLaneVal(e.target.value);
                }}
              ></Input>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={() => setAddLanDir("")}>
                Cancel
              </Button>
              <Button
                isLoading={isSaving}
                colorScheme="orange"
                onClick={async () => {
                  setIsSaving(true);
                  if (addLaneDir) {
                    await onStageAdd(addLaneVal, addLaneDir);
                  }
                  setIsSaving(false);
                  setAddLanDir("");
                }}
                ml={3}
              >
                Add Stage
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal
        size="2xl"
        isOpen={isViewCandidateUrlsOpen}
        onClose={() => setIsViewCandidateUrlsOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Candidate urls`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {candidateUrls.map((c) => (
              <Text mb={4}>
                <Link href={c.url} isExternal whiteSpace={"pre"}>
                  {c.summary}
                </Link>
              </Text>
            ))}
            <Button mt={4} onClick={onCopy}>
              {hasCopied ? "Copied" : "Copy urls"}
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsViewCandidateUrlsOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        size="2xl"
        isOpen={isCandidateInfoModalOpen}
        onClose={() => setIsCandidateInfoModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`Candidate infos`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isFetchingCandidatesInfo ? (
              <Spinner />
            ) : (
              candidateUrls.map((c) => (
                <Text mb={4} borderWidth={1} p={4}>
                  <Link href={c.url} isExternal whiteSpace={"pre"}>
                    {c.summary}
                  </Link>
                  <Text>
                    {`Email: ${
                      (candidatesInfo || []).find(
                        (info) => info.anonymousId === c.userId
                      )?.email
                    }`}
                  </Text>
                  <Text>
                    {`Current company: ${
                      (candidatesInfo || []).find(
                        (info) => info.anonymousId === c.userId
                      )?.company?.name
                    }`}
                  </Text>
                </Text>
              ))
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsViewCandidateUrlsOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AddCandidatesToJobModal
        jobId={jobId}
        isOpen={isAddCandidatesToJDModalOpen}
        onClose={() => {
          setIsAddCandidatesToJDModalOpen(false);
        }}
        candidates={candidateUrls.map((cu) => ({ userId: cu.userId }))}
      />
      <BulkCreateInterviewDrawer
        jobId={jobId}
        ref={bulkCreateInterviewDrawerRef}
        candidates={(candidates || []).filter(
          (c) => !interviewRequests?.[c.userId as string]
        )}
        onDone={onInterviewCreation}
      />
    </Box>
  );
};
