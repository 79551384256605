import React, { Suspense } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { Link, withRouter } from "react-router-dom";
import Title from "../components/Title";
import Button from "../components/Button";
import Resume from "../components/Resume";
import { styled } from "styletron-react";
import {
  getCandidateSubmission,
  approveCandidateSubmission,
  updateCandidateSubmission,
  rejectCandidateSubmission,
  getUserDetails,
  checkScoutEligible,
  reloadCandidateSubmission,
  getCandidateUploadDetails,
} from "../api/admin";
import { FormControl } from "baseui/form-control";
import Label from "../components/Label";
import TextArea from "../components/TextArea";
import EmptyCaption from "../components/EmptyCaption";
import LoadingScreen from "../components/LoadingScreen";
import Modal from "../components/Modal";
import Card from "../components/Card";
import Entry from "../components/Entry";
import { ModalHeader, ModalBody } from "baseui/modal";
import { SIZE } from "baseui/modal";
import { KIND as BUTTON_KIND } from "baseui/button";
import * as Analytics from "../analytics";
import CandidateSubmissionForm from "../components/CandidateSubmissionForm";
import { Icon } from "../components/Icon";
import { LabelLarge, ParagraphLarge, ParagraphSmall } from "baseui/typography";
import { AdminUserSubmissionForm } from "./AdminUserSubmissionForm";
import Currency from "../components/Currency";
import { Block } from "baseui/block";

const LazyResumePDFDownload = React.lazy(() =>
  import("../components/ResumePDFDownload")
);

const ActionBar = styled("div", {
  bottom: "16px",
  left: "0px",
  right: "0px",
  margin: "auto",
  padding: "16px",
  display: "flex",
});

class AdminCandidateSubmission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      error: false,
      original: undefined,
      anonymous: undefined,
      isScoutEligible: false,
      approvers: [],
      isHandPickModalVisible: false,
    };
  }

  getSubmissionId() {
    return this.props.isNew
      ? this.props.match.params.submissionId
      : this.props.submissionId;
  }

  copy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  async onLoad(loadDisabled) {
    try {
      if (!loadDisabled) {
        this.setState({ isFetching: true });
      }

      let submission = await getCandidateSubmission(this.getSubmissionId());
      let userData = await getUserDetails(submission.userId);
      let { isScoutEligible } = await checkScoutEligible({
        email: userData.user.email,
        id: userData.user.id,
      });
      const original =
        submission.originalUpdate || this.copy(submission.userSubmission);
      const anonymous =
        submission.anonymousUpdate || this.copy(submission.userSubmission);

      this.setState({
        isScoutEligible,
        userData,
        submission,
        original,
        anonymous: this.updateAnonymousValues(anonymous, original),
        statusReason: submission.statusReason,
        isFetching: false,
        approvers: submission.approvers,
        isModalVisible: false,
      });

      const { uploads } = await getCandidateUploadDetails(userData.user.email);
      this.setState({
        uploads,
      });
    } catch (e) {
      this.setState({
        isFetching: false,
      });
    }
  }

  updateAnonymousValues(anonymous, original) {
    let companies = (original.experience || []).map((ex) => ex.company);
    let colleges = (original.education || []).map((ed) => ed.college);
    (anonymous.projects || []).forEach((pr) => {
      pr.company.id = "";
      let company = companies.find((c) => c && c.name === pr.company.name);
      if (company) {
        pr.company.name = company.anonymousName;
      }
      let college = colleges.find((c) => c && c.name === pr.company.name);
      if (college) {
        pr.company.name = college.anonymousName;
      }
    });

    let exDurations = [];
    (original.experience || []).forEach((ex) => {
      let endYear = ex.endYear;
      let endMonth = ex.endMonth;
      if (ex.endYear === 0) {
        endYear = new Date().getFullYear();
      }
      if (ex.endMonth === 0) {
        endMonth = new Date().getMonth() + 1;
      }
      let duration = (endYear - ex.startYear) * 12 + (endMonth - ex.startMonth);
      if (duration >= 0 && duration < 1200) {
        if (duration <= 12) {
          exDurations.push(1);
        } else if (duration <= 36) {
          exDurations.push(3);
        } else if (duration <= 60) {
          exDurations.push(5);
        } else if (duration <= 120) {
          exDurations.push(10);
        } else {
          exDurations.push(100);
        }
      } else {
        exDurations.push(0);
      }
    });

    let edDurations = [];
    (original.education || []).forEach((ed) => {
      let endYear = ed.endYear;
      if (ed.endYear === 0) {
        endYear = new Date().getFullYear();
      }
      let duration = endYear - ed.startYear;
      if (endYear === ed.startYear) {
        duration = 1;
      }
      edDurations.push(duration);
    });

    (anonymous.experience || []).forEach((ex, idx) => {
      ex.company.id = "";
      if (ex.company.anonymousName) {
        ex.company.name = ex.company.anonymousName;
      } else {
        let company = companies.find((c) => c && c.name === ex.company.name);
        if (company) {
          ex.company.name = company.anonymousName;
        }
      }
      ex.duration = exDurations[idx];
      ex.startYear = -1;
      ex.endYear = -1;
      ex.startMonth = -1;
      ex.endMonth = -1;
    });

    (anonymous.education || []).forEach((ed, idx) => {
      ed.college.id = "";
      ed.college.name = ed.college.anonymousName;
      ed.duration = edDurations[idx];
      ed.startYear = -1;
      ed.endYear = -1;
      ed.startMonth = -1;
      ed.endMonth = -1;
    });

    if (anonymous.premium) {
      (anonymous.premium.workShowcases || []).forEach((ws) => {
        ws.url = "hidden-url";
      });

      (anonymous.premium.existingOffers || []).forEach((jobOffer) => {
        jobOffer.company.name = "";
      });

      anonymous.premium.linkedInReferences = (
        anonymous.premium.linkedInReferences || []
      ).map(() => "hidden-url");

      anonymous.premium.articles = (anonymous.premium.articles || []).map(
        () => "hidden-url"
      );

      anonymous.premium.patents = (anonymous.premium.patents || []).map(
        (pt) => ({
          url: "hidden-url",
        })
      );

      anonymous.premium.certifications = (
        anonymous.premium.certifications || []
      ).map((cr) => ({
        link: "hidden-url",
      }));
    }
    return anonymous;
  }

  async componentDidMount() {
    try {
      await this.onLoad();
    } catch (e) {
      this.setState({ error: true });
      console.log(e);
    }
  }

  onSubmissionApproveAndScout = async () => {
    try {
      await approveCandidateSubmission(this.getSubmissionId(), {
        ...this.state.submission,
        originalUpdate: this.state.original,
        anonymousUpdate: this.state.anonymous,
        statusReason: this.state.statusReason,
        isScoutEnabled: true,
      });
      Analytics.logEvent("[A] click approve and scout candidate", {
        candidateId: this.state.original.userId,
      });
      await this.onLoad();
    } catch (e) {
      console.log(e);
    }
  };

  onSubmissionApprove = async () => {
    try {
      await approveCandidateSubmission(this.getSubmissionId(), {
        ...this.state.submission,
        originalUpdate: this.state.original,
        anonymousUpdate: this.state.anonymous,
        statusReason: this.state.statusReason,
      });
      Analytics.logEvent("[A] click approve candidate", {
        candidateId: this.state.original.userId,
      });
      await this.onLoad();
    } catch (e) {
      console.log(e);
    }
  };

  onSubmissionReject = async () => {
    if (!this.state.statusReason) {
      this.setState({
        statusReasonError:
          "Please add some comments if you are rejecting the submission",
      });
      return;
    } else {
      this.setState({
        statusReasonError: "",
      });
    }
    try {
      await rejectCandidateSubmission(this.getSubmissionId(), {
        ...this.state.submission,
        originalUpdate: this.state.original,
        anonymousUpdate: this.state.anonymous,
        statusReason: this.state.statusReason,
      });
      Analytics.logEvent("[A] click reject candidate", {
        candidateId: this.state.original.userId,
      });
      await this.onLoad();
    } catch (e) {
      console.log(e);
    }
  };

  onSubmissionUpdate = async (loadDisabled) => {
    try {
      await updateCandidateSubmission(this.getSubmissionId(), {
        ...this.state.submission,
        originalUpdate: this.state.original,
        anonymousUpdate: this.state.anonymous,
        statusReason: this.state.statusReason,
      });
      Analytics.logEvent("[A] click update candidate", {
        candidateId: this.state.original.userId,
      });
      await this.onLoad(loadDisabled);
    } catch (e) {
      console.log(e);
    }
  };

  onSubmissionReload = async () => {
    try {
      await reloadCandidateSubmission(this.getSubmissionId(), {
        id: this.getSubmissionId(),
      });
      await this.onLoad();
    } catch (e) {
      console.log(e);
    }
  };

  onNewEntityCreated = (entity) => {};

  render() {
    if (this.state.isFetching) return <LoadingScreen />;
    if (this.state.error) return <div>Error occured for this page</div>;
    return (
      <div style={{ maxWidth: 820, position: "relative" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Title>{this.state.userData && this.state.userData.user.name}</Title>
          {/* {this.state.anonymous && (
            <div>
              <Button
                kind={BUTTON_KIND.minimal}
                startEnhancer={<Icon size={16} name="download" />}
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <LazyResumePDFDownload
                    textColor={"#333333"}
                    isAnonymous={true}
                    candidate={this.state.anonymous}
                  />
                </Suspense>
              </Button>
            </div>
          )} */}
        </div>
        {this.state.userData && (
          <Card style={{ marginBottom: "16px" }}>
            <Entry label={"email"} value={this.state.userData.user.email} />
            <Entry label={"phone"} value={this.state.userData.user.phone} />
            <Entry
              label={"linkedIn"}
              value={
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.state.userData.user.linkedInProfile}
                >
                  {this.state.userData.user.linkedInProfile}
                </a>
              }
            />
            <Entry
              label={"resume"}
              value={
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.state.original.resumeURL}
                >
                  {this.state.original.resumeURL}
                </a>
              }
            />
            <Entry
              label={"current CTC"}
              value={
                <Currency
                  value={this.state.original.totalCTC}
                  currency={this.state.original.totalCTCCurrency}
                />
              }
            />
            {this.state.isScoutEligible && (
              <Entry
                label={"Selected for Scout"}
                value={this.state.submission.isScoutEnabled}
              />
            )}
          </Card>
        )}
        {this.state.uploads &&
          this.state.uploads.length &&
          this.state.uploads.map((upload) => (
            <Card marginBottom="scale600" marginTop="scale600">
              <LabelLarge marginBottom="scale200">Upload Details</LabelLarge>
              <ParagraphSmall>{`Talentscout Name: ${upload.talentscoutUser.name}`}</ParagraphSmall>
              <ParagraphSmall>{`Talentscout Email: ${upload.talentscoutUser.email}`}</ParagraphSmall>
              <ParagraphSmall>{`Talentscout Phone: ${upload.talentscoutUser.phone}`}</ParagraphSmall>
              {upload.job && !this.props.isNew && (
                <Link
                  to={`/N0VHxB8M/admin/details/job/${upload.job.id}`}
                  target="_blank"
                >
                  {`Click here for job info - ${upload.job.jobTitle.name} | ${upload.job.company.name}`}
                </Link>
              )}
            </Card>
          ))}
        {this.state.submission && this.state.submission.status === "REJECTED" && (
          <Card
            style={{
              background: "#F8756C",
              color: "white",
              marginBottom: "16px",
            }}
          >
            {"This is rejected"}
          </Card>
        )}
        {this.state.submission && this.state.submission.status === "APPROVED" && (
          <Card
            style={{
              background: "green",
              color: "white",
              marginBottom: "32px",
            }}
          >
            {"This is approved"}
          </Card>
        )}
        {this.state.approvers && this.state.approvers.length ? (
          <div>
            <span>Approvals:</span>
            <span>
              {this.state.approvers.map((approver) => approver.email).join(",")}
            </span>
          </div>
        ) : (
          <div>No approvals</div>
        )}

        <ActionBar>
          <Button onClick={async () => await this.onSubmissionReload()}>
            Reload
          </Button>
          <div style={{ width: "16px" }}></div>
          <Button onClick={async () => await this.onSubmissionUpdate()}>
            Save
          </Button>
          <div style={{ width: "16px" }}></div>
          <Button onClick={async () => await this.onSubmissionReject()}>
            Reject
          </Button>
          <div style={{ width: "16px" }}></div>
          <Button
            onClick={() => {
              this.setState({
                isModalVisible: true,
              });
            }}
          >
            Submit
          </Button>
          {!this.props.isNew &&
            this.state.userData &&
            this.state.userData.user &&
            this.state.userData.anonymousCandidate && (
              <Block marginLeft="scale200" padding="scale300">
                <Link
                  to={`/N0VHxB8M/admin/details/pick-jobs/${this.state.userData.anonymousCandidate.userId}`}
                  target="_blank"
                >
                  {`Pick job`}
                </Link>
              </Block>
            )}
        </ActionBar>

        <FormControl
          label={<Label>{"Any comments"}</Label>}
          caption={<EmptyCaption />}
          error={this.state.statusReasonError}
        >
          <TextArea
            resize
            placeholder={""}
            onChange={(e) => {
              if (typeof e.target.value !== "undefined") {
                this.setState({
                  statusReason: e.target.value,
                });
              }
            }}
            value={this.state.statusReason}
            autoComplete={"off"}
          />
        </FormControl>

        <AdminUserSubmissionForm
          user={(this.state.userData && this.state.userData.user) || {}}
          onUpdate={() => this.onLoad(true)}
        />
        <CandidateSubmissionForm
          candidate={this.state.original}
          anonymousCandidate={this.state.anonymous}
          onCandidateChange={(val) => {
            this.setState({
              original: {
                ...this.state.original,
                ...this.copy(val),
              },
            });
          }}
          onAnonymousCandidateChange={(val) => {
            const anonUpdate = {
              ...this.state.anonymous,
              ...this.copy(val),
            };
            this.setState({
              anonymous: this.updateAnonymousValues(
                anonUpdate,
                this.copy(this.state.original)
              ),
            });
          }}
        />

        {/* <div style={{ display: "flex", paddingBottom: 200 }}>
          <div
            style={{
              padding: 16,
              marginRight: 16,
              border: "1px solid #d8d8d8",
              background: "#FFFFFF",
              width: 400,
            }}
          >
            <Headline>Complete Profile</Headline>
            {this.state.original && (
              <CandidateCompleteForm
                kind={"original"}
                onNewEntityCreated={this.onNewEntityCreated}
                values={this.state.original}
                errors={{}}
                onChange={(val, save) => {
                  this.setState(
                    {
                      original: this.copy(val),
                      anonymous: this.updateAnonymousValues(
                        this.copy(val),
                        this.copy(val)
                      ),
                    },
                    () => {
                      if (save) {
                        this.onSubmissionUpdate(true);
                      }
                    }
                  );
                }}
              />
            )}
          </div>

          <div
            style={{
              background: "#FFFFFF",
              padding: 16,
              border: "1px solid #d8d8d8",
              width: 400,
            }}
          >
            <Headline>Anonymous Profile</Headline>
            {this.state.anonymous && (
              <CandidateCompleteForm
                kind={"anonymous"}
                onCreateEntityClick={this.onCreateEntityClick}
                values={this.state.anonymous}
                errors={{}}
                onChange={(val, save) => {
                  this.setState(
                    {
                      anonymous: this.updateAnonymousValues(
                        this.copy(val),
                        this.state.original
                      ),
                    },
                    () => {
                      if (save) {
                        this.onSubmissionUpdate(true);
                      }
                    }
                  );
                }}
              />
            )}
          </div>
        </div> */}
        <Modal
          animate
          autoFocus
          size={SIZE.auto}
          isOpen={this.state.isModalVisible}
          closeable
          onClose={() => {
            this.setState({
              isModalVisible: false,
            });
          }}
        >
          <ModalHeader>
            <Title>Review Submission</Title>
          </ModalHeader>
          <ModalBody>
            <ActionBar>
              <Button onClick={this.onSubmissionApprove}>Approve</Button>
              <div style={{ width: "16px" }}></div>
              {this.state.isScoutEligible ? (
                <Button onClick={this.onSubmissionApproveAndScout}>
                  {"Approve & Scout"}
                </Button>
              ) : null}
            </ActionBar>
            <Resume
              candidate={this.state.anonymous}
              isAnonymous={true}
            ></Resume>
          </ModalBody>
        </Modal>
        {/* {this.state.userData && this.state.userData.user && (
          <AdminHandPickCandidateModal
            isVisible={this.state.isHandPickModalVisible}
            candidateId={this.state.userData.user.id}
            onClose={() =>
              this.setState({
                isHandPickModalVisible: false,
              })
            }
          />
        )} */}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminCandidateSubmission));
