import { Block } from "baseui/block";
import { SIZE } from "baseui/button";
import { Modal, ModalBody, ModalHeader } from "baseui/modal";
import {
  LabelMedium,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router";
import {
  getAllJobs,
  getTalentscoutAssignedJobs,
  updateTalentscoutAssignedJobs,
} from "../api/admin";
import { updateTalentscout } from "../api/talentscout";
import Button from "../components/Button";
import Card from "../components/Card";
import { Icon } from "../components/Icon";
import LoadingScreen from "../components/LoadingScreen";
import Pagination from "../components/Pagination";
import PopConfirm from "../components/PopConfirm";
import Spacing from "../components/Spacing";
import Title from "../components/Title";

const JobDetails = ({ job, talentscoutId, isPicked, onPicked }) => {
  const [isPushing, setIsPushing] = useState(false);
  const onHandPick = async () => {
    setIsPushing(true);
    try {
      await updateTalentscoutAssignedJobs("add", {
        jobId: job.id,
        talentscoutId,
      });
      setIsPushing(false);
      onPicked && onPicked();
    } catch (e) {
      setIsPushing(false);
    }
  };
  return (
    <Card style={{ marginBottom: "16px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <LabelMedium>{job.company.name}</LabelMedium>
          <div>{job.jobTitle.name}</div>
          <div>{job.minExperience + "-" + job.maxExperience + " Years"}</div>
          <div>{job.minSalary}</div>
        </div>
        <div>
          {!isPicked ? (
            <Button isLoading={isPushing} size={SIZE.mini} onClick={onHandPick}>
              Pick
            </Button>
          ) : (
            <div>Picked</div>
          )}
        </div>
      </div>
    </Card>
  );
};
const JobModal = ({ talentscoutId, isVisible, onClose, picked, onPicked }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(0);
  const limit = 10;
  const fetchAssignedJobs = useCallback(async () => {
    setIsLoading(true);
    try {
      let { data: jobs, hasNext } = (await getAllJobs(page, limit)) || {};
      setJobs(jobs);
      setHasNext(hasNext);
    } catch (e) {}
    setIsLoading(false);
  }, [page]);

  const onNextClick = async () => {
    setPage(page + 1);
  };

  const onPrevClick = async () => {
    setPage(page - 1);
  };

  useEffect(() => {
    fetchAssignedJobs();
  }, [isVisible, fetchAssignedJobs]);

  useEffect(() => {
    setHasPrev(page - 1 >= 0);
    fetchAssignedJobs();
  }, [page]);

  return (
    <Modal animate autoFocus isOpen={isVisible} closeable onClose={onClose}>
      <ModalHeader>
        <Title>{"Jobs to assign"}</Title>
      </ModalHeader>
      <ModalBody>
        {isLoading && <LoadingScreen />}
        {!isLoading && (
          <div>
            {jobs.map((job, idx) => (
              <JobDetails
                key={idx}
                job={job}
                onPicked={() => {
                  fetchAssignedJobs();
                  onPicked();
                }}
                isPicked={picked.indexOf(job.id) !== -1}
                talentscoutId={talentscoutId}
              />
            ))}
            <Pagination
              onPrevClick={onPrevClick}
              onNextClick={onNextClick}
              hasNext={hasNext}
              hasPrev={hasPrev}
            />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export const AdminTalentscoutJobs = (props) => {
  const { talentscoutId } = props;
  const [jobs, setJobs] = useState([]);
  const history = useHistory();
  const [isJobModalVisible, setIsJobModalVisible] = useState(false);
  const fetchTalentscoutAssignedJobs = async () => {
    try {
      const { data } = await getTalentscoutAssignedJobs(talentscoutId);
      setJobs(data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchTalentscoutAssignedJobs();
  }, []);

  const onRemove = async (jobId) => {
    try {
      await updateTalentscoutAssignedJobs("remove", {
        talentscoutId,
        jobId,
      });
      fetchTalentscoutAssignedJobs();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Block>
      <Title marginBottom="scale600">
        <>Talentscout Jobs</>
        <Button onClick={() => setIsJobModalVisible(true)}>Add Job</Button>
      </Title>
      {jobs.map((job) => {
        return (
          <Card marginBottom="scale400">
            <LabelMedium>{job.jobTitle.name}</LabelMedium>
            <ParagraphMedium>{job.company.name}</ParagraphMedium>
            <ParagraphSmall>
              {job.skills.map((sk) => sk.name).join()}
            </ParagraphSmall>
            <ParagraphSmall color="primary200">
              {job.locations.map((sk) => sk.name).join()}
            </ParagraphSmall>
            <Spacing size={8} />
            <PopConfirm
              showArrow
              title={"Remove this job ?"}
              onConfirm={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                await onRemove(job.id);
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Button
                size={"compact"}
                kind={"tertiary"}
                startEnhancer={<Icon size={12} name={"x"} color="primary" />}
              >
                Remove
              </Button>
            </PopConfirm>
            <Button
              size={"compact"}
              kind={"tertiary"}
              startEnhancer={<Icon size={12} name={"upload"} color="primary" />}
              onClick={() =>
                history.push(
                  "/N0VHxB8M/admin/details/talentscout-uploaded/" +
                    talentscoutId +
                    "?jobId=" +
                    job.id
                )
              }
            >
              Uploaded
            </Button>
            <Button
              size={"compact"}
              kind={"tertiary"}
              startEnhancer={
                <Icon size={12} name={"check-circle"} color="primary" />
              }
              onClick={() =>
                history.push(
                  "/N0VHxB8M/admin/details/talentscout-handpicked/" +
                    talentscoutId +
                    "?jobId=" +
                    job.id
                )
              }
            >
              HandPicked
            </Button>
          </Card>
        );
      })}
      <JobModal
        isVisible={isJobModalVisible}
        onClose={async () => {
          setIsJobModalVisible(false);
          await fetchTalentscoutAssignedJobs();
        }}
        onPicked={async () => {
          await fetchTalentscoutAssignedJobs();
        }}
        talentscoutId={talentscoutId}
        picked={jobs.map((job) => job.id)}
      />
    </Block>
  );
};
