import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Icon,
  Spacer,
  Spinner,
  Text,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { FiCheck, FiCheckCircle, FiX } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { getJobById } from "src/api/job";
import {
  getReferCandidate,
  ReferredCandidateProps,
} from "src/api/referred_candidate";
import Currency from "src/components/Currency";
import { RichTextViewer } from "src/components/RichTextEditor";
import { Job } from "src/screens/talentscout/UploadCandidate";

export const Confirmation = () => {
  const location = useLocation();
  const [isInvalidPage, setIsInvalidPage] = useState(false);
  const [referredCandidate, setReferredCandidate] = useState<
    ReferredCandidateProps
  >();
  const [isFetching, setIsFetching] = useState(false);
  const [job, setJob] = useState<Job>();
  const toast = useToast();
  const buttonSize = useBreakpointValue({ base: "lg", md: "md" });
  const [confirmStatus, setConfirmStatus] = useState(false);
  const fetchReferredCandidate = useCallback(
    async (id: string) => {
      setIsFetching(true);
      try {
        const referredCandidate = await getReferCandidate(id);
        setReferredCandidate(referredCandidate);
        const { job } = await getJobById(referredCandidate.jobId);
        setJob(job);
        setIsFetching(false);
      } catch (e) {
        toast({
          title: "Error while fetching details",
          description: "Please try again",
        });
        setIsFetching(false);
      }
    },
    [toast]
  );
  useEffect(() => {
    let params = new URLSearchParams(location.search);
    if (!params.get("rid")) {
      setIsInvalidPage(true);
      return;
    }
    if (!params.get("confirm")) {
      setIsInvalidPage(true);
      return;
    }
    setConfirmStatus(params.get("confirm") === "true");
    fetchReferredCandidate(params.get("rid") ?? "");
  }, [setIsInvalidPage, fetchReferredCandidate, location.search]);
  if (isInvalidPage) {
    return (
      <Center height="100vh">
        <Box>
          <Center mb={16}>
            <WarningIcon fontSize="5xl"></WarningIcon>
          </Center>
          <Heading fontWeight="semibold" fontSize="3xl" textAlign={"center"}>
            This link is not correct. Please check again.
          </Heading>
        </Box>
      </Center>
    );
  }

  if (isFetching) {
    return (
      <Center height="100vh">
        <Box textAlign={"center"}>
          <Spinner color="green.500" />
        </Box>
      </Center>
    );
  }

  if (!referredCandidate) return null;
  return (
    <Box py={8} px={4} mb={"60px"}>
      <Heading fontWeight="semibold" fontSize="xl">
        Thank you
      </Heading>
      <Spacer height={16} />
      {confirmStatus ? (
        <VStack spacing={5} alignItems="flex-start" justifyContent={"center"}>
          <Text>
            {`👍 We have sent your profile to the hiring manager to approve your
            expected salary`}
          </Text>
          <Divider variant={"dashed"} />
          <Flex>
            <Icon as={FiCheckCircle} fontSize={"75px"} color="green.500" />
            <Heading fontWeight="semibold" fontSize="3xl" ml={8}>
              {job?.company?.name}
            </Heading>
          </Flex>
          <Divider variant={"dashed"} />
          <Text color="gray.500">
            You can close this tab now. We will contact you after the discussion
            with the hiring manager.
          </Text>
        </VStack>
      ) : (
        <VStack spacing={5} alignItems="flex-start" justifyContent={"center"}>
          <Text fontSize={"3xl"}>{`😞`}</Text>
          <Text>
            We will inform you if we have any jobs which matches your salary
            expections.
          </Text>
          <Text>Have a nice day. You can close this tab now.</Text>
        </VStack>
      )}
    </Box>
  );
};
