import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { setWidgetState } from "../redux/actions/widget";
import { Link, withRouter } from "react-router-dom";
import Title from "../components/Title";
import PhoneInput from "../components/PhoneInput";
import {
  updateEmployer,
  getUserDetails,
  updateUser,
  updateTalentscout,
  getUploadedCandidateStats,
  disableCandidate,
} from "../api/admin";
import { getReferCandidatesAdmin } from "../api/referred_candidate";
import Input from "../components/Input";
import EmptyCaption from "../components/EmptyCaption";
import Select from "../components/Select";
import Button from "../components/Button";
import { searchEntity } from "../api/entity";
import { FormControl } from "baseui/form-control";
import Label from "../components/Label";
import LoadingScreen from "../components/LoadingScreen";
import { Tabs, Tab } from "baseui/tabs";
import { Checkbox } from "baseui/checkbox";
import Card from "../components/Card";
import { getMyAnonymousCandidate } from "../api/candidate";
import { convertToCandidateSearch } from "../util/common";
import { toaster } from "baseui/toast";
import { Block } from "baseui/block";
import {
  LabelLarge,
  LabelSmall,
  DisplayLarge,
  DisplayXSmall,
} from "baseui/typography";
import {
  Flex,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";

class AdminUserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      isMounted: false,
      isDisabling: false,
      activeKey: "0",
      candidateErrors: {},
      employerErrors: {},
      talentscoutErrors: {},
      talentscoutData: {},
      userErrors: {},
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    try {
      let { user, candidate, employer, talentscout } = await getUserDetails(
        this.props.userId
      );
      this.setState({
        user,
        candidate,
        employer,
        talentscout,
        isMounted: true,
      });
      let { talentscoutData } = await getReferCandidatesAdmin(
        { talentscoutId: this.props.userId },
        0,
        10
      );
      this.setState({ talentscoutData });
    } catch (e) {
      console.log(e);
    }

    // try {
    //   const {
    //     currentMonthUploaded,
    //     lastMonthUploaded,
    //     currentMonthApproved,
    //     lastMonthApproved,
    //   } = await getUploadedCandidateStats(this.props.userId);
    //   this.setState({
    //     currentMonthUploaded,
    //     lastMonthUploaded,
    //     currentMonthApproved,
    //     lastMonthApproved,
    //   });
    // } catch (e) {
    //   console.log(e);
    // }
  }

  onUpdateEmployer = async () => {
    try {
      await updateEmployer(this.state.employer.userId, this.state.employer);
    } catch (e) {
      console.log(e);
    }
  };

  onUpdateTalentscout = async () => {
    try {
      await updateTalentscout(
        this.state.talentscout.userId,
        this.state.talentscout
      );
      await this.onLoad();
      toaster.show(<div>Updated successfully</div>, { autoHideDuration: 3000 });
    } catch (e) {
      console.log(e);
    }
  };

  onUpdateUser = async () => {
    try {
      await updateUser(this.state.user.id, this.state.user);
    } catch (e) {
      console.log(e);
    }
  };

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateEmployerValues(id, value) {
    this.setState({
      employer: { ...this.state.employer, ...{ [id]: value } },
    });
  }

  updateUserValues(id, value) {
    this.setState({
      user: { ...this.state.user, ...{ [id]: value } },
    });
  }

  updateTalentscoutValues(id, value) {
    this.setState({
      talentscout: {
        ...this.state.talentscout,
        ...{ [id]: value },
      },
    });
  }

  updateCandidateValues(id, value) {
    this.setState({
      candidate: { ...this.state.candidate, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && !this.state.values[id]) {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  getValidations(id) {
    switch (id) {
      case "jobTitle":
        return { requiredSelect: true };
      case "workEmail":
        return { required: true };
      default:
        return {};
    }
  }

  submitEmployer = async (e) => {
    e.preventDefault();
    await this.onUpdateEmployer();
  };

  submitTalentscout = async (e) => {
    e.preventDefault();
    await this.onUpdateTalentscout();
  };

  submitCandidate = async (e) => {
    e.preventDefault();
    await this.onUpdateCandidate();
  };

  submitUser = async (e) => {
    e.preventDefault();
    await this.onUpdateUser();
  };

  renderUser = () => {
    return (
      <form onSubmit={this.submitUser} autoComplete="off">
        <Card
          style={{
            marginBottom: "32px",
          }}
        >
          <FormControl
            label={<Label>{"Email"}</Label>}
            caption={<EmptyCaption />}
            error={this.state.userErrors["email"]}
          >
            <Input
              id={"email"}
              onChange={(e) => this.updateUserValues("email", e.target.value)}
              value={this.state.user["email"] || ""}
              autoComplete={"off"}
            />
          </FormControl>

          <FormControl
            label={<Label>{"Phone"}</Label>}
            caption={<EmptyCaption />}
            error={
              this.state.userErrors["phone"] ||
              this.state.userErrors["phonePrefix"]
            }
          >
            <PhoneInput
              autoComplete={"no"}
              country={this.state.user["phonePrefix"] || {}}
              onCountryChange={({ option }) =>
                this.updateUserValues("phonePrefix", option)
              }
              text={this.state.user["phone"]}
              onTextChange={(e) =>
                this.updateUserValues("phone", e.target.value)
              }
            />
          </FormControl>

          <FormControl
            label={<Label>{"linkedin profile"}</Label>}
            caption={<EmptyCaption />}
            error={this.state.userErrors["linkedInProfile"]}
          >
            <Input
              id={"linkedInProfile"}
              onChange={(e) =>
                this.updateUserValues("linkedInProfile", e.target.value)
              }
              value={this.state.user["linkedInProfile"] || ""}
              autoComplete={"off"}
            />
          </FormControl>
          <FormControl
            label={<Label>{"email verified"}</Label>}
            caption={<EmptyCaption />}
            error={this.state.userErrors["emailVerified"]}
          >
            <Checkbox
              id={"emailVerified"}
              onChange={(e) =>
                this.updateUserValues("emailVerified", e.target.checked)
              }
              checked={this.state.user["emailVerified"] || ""}
              autoComplete={"off"}
            />
          </FormControl>
        </Card>
        <Card
          style={{
            marginBottom: "32px",
          }}
        >
          <FormControl
            label={<Label>{"Wallet amount"}</Label>}
            caption={<EmptyCaption />}
            error={this.state.userErrors["walletAmount"]}
          >
            <Input
              id={"walletAmount"}
              onChange={(e) =>
                this.updateUserValues("walletAmount", e.target.value)
              }
              value={this.state.user["walletAmount"] || ""}
              autoComplete={"off"}
            />
          </FormControl>
          <FormControl
            label={<Label>{"Wallet currency"}</Label>}
            caption={<EmptyCaption />}
            error={this.state.userErrors["walletCurrency"]}
          >
            <Input
              id={"walletCurrency"}
              onChange={(e) =>
                this.updateUserValues("walletCurrency", e.target.value)
              }
              value={this.state.user["walletCurrency"] || ""}
              autoComplete={"off"}
            />
          </FormControl>
        </Card>
        {this.state.user.taxData && (
          <Card
            style={{
              marginBottom: "32px",
            }}
          >
            <FormControl
              label={<Label>{"has GST"}</Label>}
              caption={<EmptyCaption />}
              error={this.state.userErrors["hasGST"]}
            >
              <Checkbox
                id={"hasGST"}
                onChange={(e) =>
                  this.updateUserTaxValues("hasGST", e.target.checked)
                }
                checked={this.state.user.taxData.hasGST || ""}
                autoComplete={"off"}
              />
            </FormControl>
            {this.state.user.taxData.hasGST && (
              <FormControl
                label={<Label>{"gst number"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.userErrors["gstNumber"]}
              >
                <Input
                  id={"gstNumber"}
                  onChange={(e) =>
                    this.updateUserTaxValues("gstNumber", e.target.value)
                  }
                  value={this.state.user.taxData.gstNumber || ""}
                  autoComplete={"off"}
                />
              </FormControl>
            )}
            {this.state.user.taxData.hasGST && (
              <FormControl
                label={<Label>{"inside karnataka"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.userErrors["insideState"]}
              >
                <Checkbox
                  id={"insideState"}
                  onChange={(e) =>
                    this.updateUserTaxValues("insideState", e.target.checked)
                  }
                  checked={this.state.user.taxData.insideState || ""}
                  autoComplete={"off"}
                />
              </FormControl>
            )}
          </Card>
        )}
        {this.state.user.idProof && (
          <Card
            style={{
              marginBottom: "32px",
            }}
          >
            {
              <FormControl
                label={<Label>{"id proof"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.userErrors["idProofId"]}
              >
                <Input
                  id={"id"}
                  onChange={(e) =>
                    this.updateUserIdProofValues("id", e.target.value)
                  }
                  value={this.state.user.idProof.id || ""}
                  autoComplete={"off"}
                />
              </FormControl>
            }
          </Card>
        )}
        <Button isLoading={this.state.isLoading} type={"submit"}>
          Save
        </Button>
      </form>
    );
  };

  renderEmployer = () => {
    return (
      <form onSubmit={this.submitEmployer} autoComplete="off">
        <FormControl
          label={<Label>{"job title"}</Label>}
          caption={<EmptyCaption />}
          error={this.state.employerErrors["jobTitle"]}
        >
          <Select
            creatable
            getOptions={async (query) => searchEntity("jobTitle", query)}
            onChange={({ value }) => {
              this.updateEmployerValues("jobTitle", value[0]);
            }}
            value={this.state.employer["jobTitle"]}
          />
        </FormControl>
        <FormControl
          label={<Label>{"work email"}</Label>}
          caption={<EmptyCaption />}
          error={this.state.employerErrors["workEmail"]}
        >
          <Input
            id={"workEmail"}
            onChange={(e) =>
              this.updateEmployerValues("workEmail", e.target.value)
            }
            value={this.state.employer["workEmail"] || ""}
            autoComplete={"off"}
          />
        </FormControl>
        <FormControl
          label={<Label>{"work email verified"}</Label>}
          caption={<EmptyCaption />}
          error={this.state.employerErrors["workEmailVerified"]}
        >
          <Checkbox
            id={"workEmail"}
            onChange={(e) =>
              this.updateEmployerValues("workEmailVerified", e.target.checked)
            }
            checked={this.state.employer["workEmailVerified"] || ""}
            autoComplete={"off"}
          />
        </FormControl>
        <FormControl
          label={<Label>{"company"}</Label>}
          caption={<EmptyCaption />}
          error={this.state.employerErrors["company"]}
        >
          <Select
            type={"search"}
            id={"company"}
            onChange={(e) =>
              this.updateEmployerValues("company", e.target.value)
            }
            value={this.state.employer["company"] || ""}
            autoComplete={"off"}
          />
        </FormControl>
        <Button isLoading={this.state.isLoading} type={"submit"}>
          Save
        </Button>
      </form>
    );
  };

  renderTalentscout() {
    const talentscoutData = this.state.talentscoutData;
    return (
      <Block>
        <Flex mb={4}>
          <Flex
            bg={"gray.100"}
            p={2}
            borderRadius="md"
            justifyContent={"space-between"}
            flex={1}
            mr={4}
          >
            <Stat>
              <StatLabel>Referred</StatLabel>
              <StatNumber>
                {talentscoutData?.referred?.lastMonth || "--"}
              </StatNumber>
              <StatHelpText>Last Month</StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Referred</StatLabel>
              <StatNumber>
                {talentscoutData?.referred?.currentMonth || "--"}
              </StatNumber>
              <StatHelpText>This Month</StatHelpText>
            </Stat>
          </Flex>
          <Flex
            bg={"gray.100"}
            p={2}
            borderRadius="md"
            justifyContent={"space-between"}
            flex={1}
            mr={4}
          >
            <Stat>
              <StatLabel>Approved</StatLabel>
              <StatNumber>
                {talentscoutData?.approved?.lastMonth || "--"}
              </StatNumber>
              <StatHelpText>Last Month</StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Approved</StatLabel>
              <StatNumber>
                {talentscoutData?.approved?.currentMonth || "--"}
              </StatNumber>
              <StatHelpText>This Month</StatHelpText>
            </Stat>
          </Flex>
          <Flex
            bg={"gray.100"}
            p={2}
            pb={0}
            borderRadius="md"
            justifyContent={"space-between"}
            flex={1}
            mr={4}
          >
            <Stat>
              <StatLabel>Earned</StatLabel>
              <StatNumber>
                {talentscoutData?.earned?.lastMonth / 100 || "--"}
              </StatNumber>
              <StatHelpText>Last Month</StatHelpText>
            </Stat>
            <Stat>
              <StatLabel>Earned</StatLabel>
              <StatNumber>
                {talentscoutData?.earned?.currentMonth / 100 || "--"}
              </StatNumber>
              <StatHelpText>This Month</StatHelpText>
            </Stat>
          </Flex>
        </Flex>

        <form onSubmit={this.submitTalentscout} autoComplete="off">
          <FormControl
            label={<Label>{"Enter scout slots"}</Label>}
            caption={<EmptyCaption />}
            error={this.state.talentscoutErrors["scoutSlots"]}
          >
            <Input
              id={"scoutSlots"}
              onChange={(e) =>
                this.updateTalentscoutValues("scoutSlots", +e.target.value)
              }
              value={this.state.talentscout["scoutSlots"] || ""}
              autoComplete={"off"}
            />
          </FormControl>
          <FormControl
            label={<Label>{"Enter scout slots per month"}</Label>}
            caption={<EmptyCaption />}
            error={this.state.talentscoutErrors["scoutSlotsPerMonth"]}
          >
            <Input
              id={"scoutSlotsPerMonth"}
              onChange={(e) =>
                this.updateTalentscoutValues(
                  "scoutSlotsPerMonth",
                  +e.target.value
                )
              }
              value={this.state.talentscout["scoutSlotsPerMonth"] || ""}
              autoComplete={"off"}
            />
          </FormControl>
          <FormControl
            label={<Label>{"Enter scout slots per day"}</Label>}
            caption={<EmptyCaption />}
            error={this.state.talentscoutErrors["scoutSlotsPerDay"]}
          >
            <Input
              id={"scoutSlotsPerDay"}
              onChange={(e) =>
                this.updateTalentscoutValues(
                  "scoutSlotsPerDay",
                  +e.target.value
                )
              }
              value={this.state.talentscout["scoutSlotsPerDay"] || ""}
              autoComplete={"off"}
            />
          </FormControl>
          {/* <FormControl
          label={<Label>{"Enter interview share"}</Label>}
          caption={<EmptyCaption />}
          error={this.state.talentscoutErrors["interviewShare"]}
        >
          <Input
            id={"interviewShare"}
            onChange={(e) =>
              this.updateTalentscoutValues("interviewShare", +e.target.value)
            }
            value={this.state.talentscout["interviewShare"] || ""}
            autoComplete={"off"}
          />
        </FormControl>
        <FormControl
          label={
            <Label>
              <div>{"Finding jo bot: " + this.state.talentscout["jobot"]}</div>
            </Label>
          }
          caption={<EmptyCaption />}
          error={this.state.talentscoutErrors["interviewShare"]}
        >
          <div />
        </FormControl> */}

          <Button isLoading={this.state.isLoading} type={"submit"}>
            Save
          </Button>
          <Block marginTop="scale800">
            <Link
              to={`/N0VHxB8M/admin/details/talentscout-jobs/${this.state.talentscout.userId}`}
              target="_blank"
            >
              Go to assigned Jobs
            </Link>
          </Block>
        </form>
      </Block>
    );
  }

  renderCandidate() {
    return (
      <div>
        <Button
          onClick={() => {
            this.props.history.push(
              "/N0VHxB8M/admin/details/candidate/" + this.state.candidate.userId
            );
          }}
        >
          View candidate submissions
        </Button>
        <div style={{ marginTop: "30px" }}>
          <Button
            onClick={async () => {
              try {
                let candidate = convertToCandidateSearch(
                  await getMyAnonymousCandidate(this.state.candidate.userId)
                );
                this.props.setWidgetState({
                  isShareCandidateModelVisible: true,
                  shareCandidate: candidate,
                  shareCandidateImageUrl: candidate.shareImageUrl,
                });
              } catch (e) {
                console.log(e);
                toaster.negative(
                  <div>Error occured while getting candidate data</div>,
                  {
                    autoHideDuration: 3000,
                  }
                );
              }
            }}
          >
            Share Candidate Profile
          </Button>
          {this.state.candidate.status !== "DISABLED" ? (
            <div style={{ marginTop: "30px" }}>
              <Button
                isLoading={this.state.isDisabling}
                onClick={async () => {
                  try {
                    this.setState({
                      isDisabling: true,
                    });
                    await disableCandidate({
                      userId: this.state.candidate.userId,
                    });
                    await this.onLoad();
                  } catch (e) {
                    toaster.negative(<div>Error while disabling</div>, {
                      autoHideDuration: 3000,
                    });
                  }
                  this.setState({
                    isDisabling: false,
                  });
                }}
              >
                Disable Candidate Profile
              </Button>
            </div>
          ) : (
            <div>DISABLED PROFILE</div>
          )}
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.isMounted) {
      return <LoadingScreen />;
    }
    return (
      <div>
        <Title>{this.state.user.name}</Title>
        <div style={{ maxWidth: 420 }}>
          <Tabs
            onChange={({ activeKey }) => {
              this.setState({
                activeKey,
              });
            }}
            activeKey={this.state.activeKey}
          >
            {this.state.user && <Tab title="User">{this.renderUser()}</Tab>}
            {this.state.candidate && (
              <Tab title="Candidate">{this.renderCandidate()}</Tab>
            )}
            {this.state.employer && (
              <Tab title="Employer">{this.renderEmployer()}</Tab>
            )}
            {this.state.talentscout && (
              <Tab title="Talentscout">{this.renderTalentscout()}</Tab>
            )}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser, setWidgetState }
)(withRouter(AdminUserDetails));
