import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Client as Styletron } from "styletron-engine-monolithic";
import { Provider as StyletronProvider } from "styletron-react";
import { BaseProvider, createTheme } from "baseui";
import { ChakraProvider } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import store from "./redux/store";
/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "firebase" }]*/
import firebase from "./firebase";
import * as Analytics from "./analytics";
import * as Sentry from "./util/sentry";
import * as Hotjar from "./util/hotjar";

import "@fontsource/inter/variable.css";
// import "@fontsource/inter/500.css";
// import "@fontsource/inter/600.css";

import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import "@fontsource/lato/900.css";
import "@fontsource/lato/900-italic.css";

const engine = new Styletron();

Analytics.initialise();
Sentry.initialise();
Hotjar.initialise();

const breakpoints = {
  small: 880,
  medium: 1200,
  large: 1460,
};

const primitives = {
  accent: "#E16359",
  accent50: "#FCEFEE",
  accent100: "#F6CECB",
  accent200: "#EFAEA9",
  accent300: "#E98D86",
  accent400: "#E16359",
  accent500: "#DC4C41",
  accent600: "#CF3126",
  accent700: "#AC2920",
};

const chakraTheme = extendTheme({
  shadows: { outline: "0 0 0 3px var(--chakra-colors-green-500)" },
  config: {
    cssVarPrefix: "fj",
    // initialColorMode: "dark",
  },
  fonts: {
    heading: "InterVariable",
    body: "InterVariable",
  },
  textStyles: {
    h1: {
      fontSize: "2.4rem",
      // fontWeight: "900",
      lineHeight: 1.2,
      opacity: 1,
      marginTop: 0,
      marginBottom: "10px",
      // letterSpacing: "-0.72px",
      wordSpacing: 0,
    },
    h2: {
      fontSize: "1.6rem",
      letterSpacing: "-0.02em",
      fontWeight: "640",
      lineHeight: 1.2,
      opacity: 1,
      marginTop: "30px",
      marginBottom: "5px",
      wordSpacing: 0,
    },
    h3: {
      fontSize: "1.3rem",
      fontWeight: "500",
      // lineHeight: 1.2,
      opacity: 1,
      marginBottom: "5px",
      letterSpacing: "0.01em",
      wordSpacing: 0,
    },
    h4: {
      fontSize: "inherit",
      fontWeight: "600",
    },
    p: {
      fontSize: "18px",
      // fontWeight: "400",
      lineHeight: 1.6,
      wordSpacing: "-0.10px",
    },
    s: {
      fontSize: "11px",
      // letterSpacing: "0.012em",
    },
  },
  colors: {
    brand: {
      50: "#fdedea",
      100: "#f7c5bc",
      200: "#f5b1a5",
      300: "#f29d8f",
      400: "#ef8a78",
      500: "#EA624A",
      600: "#e53a1c",
      700: "#cf3418",
      800: "#b92e15",
      900: "#8b2310",
    },
    primary: {
      50: "#fdedea",
      100: "#f7c5bc",
      200: "#f5b1a5",
      300: "#f29d8f",
      400: "#ef8a78",
      500: "#EA624A",
      600: "#e53a1c",
      700: "#cf3418",
      800: "#b92e15",
      900: "#8b2310",
    },
    secondary: {
      50: "#d0efd6",
      100: "#a9e2b6",
      200: "#96dca5",
      300: "#83d595",
      400: "#70cf85",
      500: "#4AC264",
      600: "#37a24e",
      700: "#308f45",
      800: "#2a7c3c",
      900: "#1d5629",
    },
  },
  styles: {
    global: {
      body: {
        background: "blackAlpha.50",
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: "medium",
      },
      defaultProps: {
        size: "md",
      },
    },
    Input: {
      baseStyle: {
        field: {},
      },
      defaultProps: {
        // size: "lg",
      },
    },
    Select: {
      defaultProps: {
        // size: "lg",
      },
    },
    FormLabel: {
      defaultProps: {
        color: "red",
      },
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="robots" content="noindex" />
      <meta name="AdsBot-Google" content="noindex" />)
      <title>Interview Candidate</title>
    </Helmet>
    <ChakraProvider theme={chakraTheme}>
      <StyletronProvider value={engine}>
        <BaseProvider
          theme={createTheme(store.getState().theme, {
            breakpoints,
            colors: {
              buttonPrimaryFill: primitives.accent,
              buttonPrimaryText: "#FFFFFF",
              buttonPrimaryHover: primitives.accent700,
              buttonPrimaryActive: primitives.accent700,
              buttonPrimarySelectedFill: primitives.accent600,
              buttonPrimarySelectedText: primitives.accent,
              buttonPrimarySpinnerForeground: primitives.accent,
              buttonPrimarySpinnerBackground: "#FFFFFF",

              buttonSecondaryFill: "#FFFFFF",
              buttonSecondaryText: "#323435",
              buttonSecondaryHover: "#CACACA",
              buttonSecondaryActive: "#AAAAAA",
              buttonSecondarySelectedFill: "#AAAAAA",
              buttonSecondarySelectedText: "#323435",
              buttonSecondarySpinnerForeground: "#323435",
              buttonSecondarySpinnerBackground: "#FFFFFF",

              buttonTertiaryFill: "transparent",
              buttonTertiaryText: "#323435",
              buttonTertiaryHover: "#CACACA",
              buttonTertiaryActive: "#AAAAAA",
              buttonTertiarySelectedFill: "#AAAAAA",
              buttonTertiarySelectedText: "#323435",
              buttonTertiarySpinnerForeground: "#323435",
              buttonTertiarySpinnerBackground: "#FFFFFF",

              buttonMinimalFill: "transparent",
              buttonMinimalText: "#323435",
              buttonMinimalHover: "#CACACA",
              buttonMinimalActive: "#AAAAAA",
              buttonMinimalSelectedFill: "#AAAAAA",
              buttonMinimalSelectedText: "#323435",
              buttonMinimalSpinnerForeground: "#323435",
              buttonMinimalSpinnerBackground: "#FFFFFF",

              contentPrimary: "#283c46",

              toastInfoBackground: "#608EBF",
              toastPositiveBackground: "#41B86C",
              toastWarningBackground: "#BC8B2F",
              toastNegativeBackground: "#C5283D",
            },

            typography: {
              LabelSmall: {
                fontWeight: 600,
              },
              LabelMedium: {
                fontWeight: 600,
              },
              LabelLarge: {
                fontWeight: 600,
              },
              HeaderSmall: {
                fontWeight: 600,
              },
              HeadingMedium: {
                fontWeight: 600,
              },
            },
          })}
        >
          {/* <Body> */}
          <App />
          {/* </Body> */}
        </BaseProvider>
      </StyletronProvider>
    </ChakraProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
