import { Box, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Project, Entity } from "src/lib/types/candidate";
import { ProjectSection } from "./ProjectSection";

export type ProjectRow = {
  project: Project;
  isNew?: boolean;
};

export type ProjectListProps = {
  projectCompanies: Entity[];
  projects: Project[];
  onAdd: () => void;
  shouldValidate: boolean;
  onSave: (ed: Project[]) => void;
};

const edToRow = (ed: Project[]) => (ed || []).map((e) => ({ project: e }));

export const ProjectList = ({
  projects: saved,
  projectCompanies,
  onSave,
  onAdd,
  shouldValidate,
}: ProjectListProps) => {
  const [projectRows, setProjectRows] = useState(edToRow(saved || []));

  useEffect(() => {
    setProjectRows(edToRow(saved));
  }, [saved]);

  return (
    <Box>
      {projectRows.map((row, idx) => {
        return (
          <ProjectSection
            key={idx}
            projectRow={row}
            shouldValidate={shouldValidate}
            projectCompanies={projectCompanies}
            onDiscard={() => {
              const updatedRows = projectRows
                .slice(0, idx)
                .concat(projectRows.slice(idx + 1));
              setProjectRows(updatedRows);
            }}
            onDelete={async () => {
              const updatedRows = projectRows
                .slice(0, idx)
                .concat(projectRows.slice(idx + 1));
              await onSave(updatedRows.map(({ project }) => project));
              setProjectRows(updatedRows);
            }}
            onSave={async (ed) => {
              const updatedRows = projectRows
                .slice(0, idx)
                .concat({ project: ed })
                .concat(projectRows.slice(idx + 1));
              await onSave(updatedRows.map(({ project }) => project));
              setProjectRows(updatedRows);
            }}
          />
        );
      })}
      <Button
        leftIcon={<FaPlus />}
        variant={"ghost"}
        colorScheme={"blue"}
        size="sm"
        onClick={() => {
          const newEx: ProjectRow = {
            project: {},
            isNew: true,
          } as ProjectRow;
          setProjectRows(projectRows.concat([newEx]));
        }}
      >
        Add project
      </Button>
    </Box>
  );
};
