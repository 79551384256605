import React from "react";
import { SIZE } from "baseui/select";
import Select from "./Select";

const SortBy = (props) => (
  <div style={{ flexDirection: "row", alignItems: "center", display: "flex" }}>
    <div
      style={{ whiteSpace: "nowrap", marginRight: "8px", fontSize: "0.8rem" }}
    >
      Sort by:{" "}
    </div>
    <Select
      backgroundColor={"inherit"}
      size={SIZE.compact}
      defaultOptions={props.options}
      value={props.value}
      onChange={props.onChange}
      clearable={false}
      searchable={false}
    />
  </div>
);

export default SortBy;
