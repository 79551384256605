import React from "react";
import Button from "./Button";
import { styled, withStyle } from "baseui";
import Bold from "./Bold";

const Wrapper = styled("div", {
  display: "flex",
  width: "100%",
  flexDirection: "row",
  padding: "16px",
  border: "1px solid #d8d8d8",
  borderRadius: "4px",
  marginBottom: "8px",
});

const Action = styled("div");

const Details = styled("div", {
  flex: 1,
});

const LocationDetails = styled("div");

const CompanyName = styled("div", {
  color: "#fe8761",
  marginRight: "8px",
});

const JobTitle = withStyle(Bold, {
  textTransform: "capitalize",
});

const SelectJob = ({ job, onSelect }) => (
  <Wrapper>
    <Details>
      <JobTitle>{job.jobTitle.name}</JobTitle>
      <LocationDetails>
        <CompanyName>{job.company.name}</CompanyName>
        {job.locations.map((loc, idx) => (
          <span
            key={idx}
            style={{
              color: "#999",
              margin: "0px 2px",
              textTransform: "capitalize",
            }}
          >
            {loc.name}
          </span>
        ))}
      </LocationDetails>
      <div>{"ID: " + job.jobInternalId}</div>
    </Details>
    <Action>
      <Button type={"primary"} onClick={async () => await onSelect(job.id)}>
        <Bold>Select Job</Bold>
      </Button>
    </Action>
  </Wrapper>
);

export default SelectJob;
