import { Box, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Showcase as WorkShowcase } from "src/lib/types/candidate";
import { WorkShowcaseSection } from "./WorkShowcaseSection";

export type WorkShowcaseRow = {
  workShowcase: WorkShowcase;
  isNew?: boolean;
};

export type WorkShowcaseListProps = {
  workShowcases: WorkShowcase[];
  onAdd: () => void;
  onSave: (ed: WorkShowcase[]) => void;
};

const toRow = (ed: WorkShowcase[]) =>
  (ed || []).map((e) => ({ workShowcase: e }));

export const WorkShowcaseList = ({
  workShowcases: saved,
  onSave,
  onAdd,
}: WorkShowcaseListProps) => {
  const [workShowcaseRows, setWorkShowcaseRows] = useState(toRow(saved || []));

  useEffect(() => {
    setWorkShowcaseRows(toRow(saved));
  }, [saved]);

  return (
    <Box>
      {workShowcaseRows.map((row, idx) => {
        return (
          <WorkShowcaseSection
            key={idx}
            workShowcaseRow={row}
            onDiscard={() => {
              const updatedRows = workShowcaseRows
                .slice(0, idx)
                .concat(workShowcaseRows.slice(idx + 1));
              setWorkShowcaseRows(updatedRows);
            }}
            onDelete={async () => {
              const updatedRows = workShowcaseRows
                .slice(0, idx)
                .concat(workShowcaseRows.slice(idx + 1));
              await onSave(updatedRows.map(({ workShowcase }) => workShowcase));
              setWorkShowcaseRows(updatedRows);
            }}
            onSave={async (ed) => {
              const updatedRows = workShowcaseRows
                .slice(0, idx)
                .concat({ workShowcase: ed })
                .concat(workShowcaseRows.slice(idx + 1));
              await onSave(updatedRows.map(({ workShowcase }) => workShowcase));
              setWorkShowcaseRows(updatedRows);
            }}
          />
        );
      })}
      <Button
        leftIcon={<FaPlus />}
        variant={"ghost"}
        colorScheme={"blue"}
        size="sm"
        onClick={() => {
          const newEx: WorkShowcaseRow = {
            workShowcase: {},
            isNew: true,
          } as WorkShowcaseRow;
          setWorkShowcaseRows(workShowcaseRows.concat([newEx]));
        }}
      >
        Add workshowcase
      </Button>
    </Box>
  );
};
