import React from "react";
import Bold from "./Bold";

const ErrorPage = (props) => (
  <div
    style={{
      margin: "auto",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <img
      height="420"
      width="420"
      src={
        "https://storage.googleapis.com/quickhire-stage/assets/errors/18.svg"
      }
      alt={""}
    ></img>
    <Bold style={{ fontSize: "32px" }}>Something went wrong</Bold>
    <Bold style={{ fontSize: "32px" }}>Please reload the page</Bold>
  </div>
);

export default ErrorPage;
