import React from "react";
import Title from "../components/Title";
import Modal from "../components/Modal";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import Button from "../components/Button";

const CandidateExistsModal = ({
  candidateEmail,
  candidatePhone,
  onUploadClick,
  isVisible,
  onClose,
}) => {
  return (
    <Modal
      animate
      autoFocus
      isOpen={isVisible}
      closeable
      onClose={() => {
        onClose();
      }}
    >
      <ModalHeader>
        <Title>{"Candidate Exists"}</Title>
      </ModalHeader>
      <ModalBody>
        <div
          style={{
            background: "#F6F5F0",
            padding: "16px",
            marginBottom: "16px",
          }}
        >
          <div>Candidate</div>
          <div>{candidateEmail}</div>
          <div>{candidatePhone}</div>
        </div>
        <div>
          Sorry, your candidate was first uploaded & shortlisted by another
          talentscout.
        </div>
        <div>Be the first one to upload a premium candidate</div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onUploadClick}>Upload another candidate</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CandidateExistsModal;
