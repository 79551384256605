import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import { RichTextViewer } from "../components/RichTextEditor";
import Currency from "./Currency";
import Card from "./Card";
import SubTitle from "./SubTitle";
import Bold from "./Bold";
import { withStyle } from "baseui";
import { Icon } from "./Icon";
import { Block } from "baseui/block";

const Wrapper = withStyle(Card, {
  marginBottom: "16px",
});

const Field = (props) => (
  <div
    style={{
      display: "flex",
      width: props.isStat ? "auto" : "300px",
      marginRight: "16px",
      marginBottom: "8px",
    }}
  >
    {props.img ? (
      <Block $marginRight="scale200" $marginTop="scale200">
        <Icon size={16} name={props.img} />
      </Block>
    ) : null}
    {props.name ? (
      <div style={{ marginRight: "4px", minWidth: "150px" }}>{props.name}</div>
    ) : null}
    <div style={{}}>{props.value}</div>
  </div>
);

const Job = (props) => {
  return (
    <Wrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <Bold
          style={{
            fontSize: "20px",
          }}
        >
          {props.job.jobTitle.name}
        </Bold>
      </div>

      <div style={{ marginBottom: "16px" }}>
        <Field
          img={"user"}
          isStat
          name={"Company"}
          value={props.job.company.name}
        />
        {props.showSalary && (
          <Field
            img={"m2"}
            isStat
            name={"Salary"}
            value={
              <Currency
                inWords
                currency={props.job.salaryCurrency}
                value={props.job.maximumSalary}
              />
            }
          />
        )}
        <Field
          img={"file-text"}
          isStat
          name={"Experience"}
          value={
            props.job.minExperience + "-" + props.job.maxExperience + " Yrs"
          }
        />
        <Field
          img={"map-pin"}
          isStat
          name={"Locations"}
          value={props.job.locations.map((l) => l.name).join(", ")}
        />
        <Field
          img={"tag"}
          isStat
          name={"Skills"}
          value={props.job.skills.map((s) => s.name).join(", ")}
        />
        {props.showTargetCompanies && (
          <Field
            img={"target"}
            isStat
            name={"Target Companies"}
            value={(props.job.targetCompanies || [])
              .map((c) => c.name)
              .join(", ")}
          />
        )}
      </div>
      <div style={{}}>
        <SubTitle>{"Job Description"}</SubTitle>
        <div>
          <RichTextViewer value={props.job.jobDescription} />
        </div>
      </div>
    </Wrapper>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(Job));
