import React, { cloneElement, forwardRef } from "react";
import ReactSelect, {
  ActionMeta,
  components as selectComponents,
  MenuProps,
  MultiValue,
  OnChangeValue,
  OptionProps,
  SingleValue,
} from "react-select";
import AsyncReactSelect from "react-select/async";
import CreatableReactSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import {
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Divider,
  CloseButton,
  Center,
  Box,
  Portal,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
  useTheme,
  useColorModeValue,
  useFormControl,
  createIcon,
  BoxProps,
  ThemeTypings,
  InputProps,
} from "@chakra-ui/react";
import { Group } from "konva/types/Group";

// Taken from the @chakra-ui/icons package to prevent needing it as a dependency
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/icons/src/ChevronDown.tsx
const ChevronDown = createIcon({
  displayName: "ChevronDownIcon",
  d: "M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z",
});

type Size = "sm" | "md" | "lg";

// type SelectOption = {
//   id: string;
//   name: string;
//   isCreatable: boolean;
// };

type GroupOption = {
  label: string;
  options: AutoCompleteItem[];
};

const valueAdapter = ({ id, name, ...rest }: any) => ({
  label: name,
  value: id,
  ...rest,
});
const reverseAdapter = ({ value, label, __isNew__, ...rest }: any) => ({
  name: label,
  id: value,
  isCreatable: __isNew__,
  ...rest,
});

// Custom styles for components which do not have a chakra equivalent
const chakraStyles = {
  // When disabled, react-select sets the pointer-state to none
  // which prevents the `not-allowed` cursor style from chakra
  // from getting applied to the Control
  container: (provided: any) => ({
    ...provided,
    pointerEvents: "auto",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "inherit",
    lineHeight: 1,
  }),
  menu: (provided: any) => ({
    ...provided,
    boxShadow: "none",
  }),
  valueContainer: (provided: any, { selectProps: { size } }: any) => {
    const px = {
      sm: "0.75rem",
      md: "1rem",
      lg: "1rem",
    };

    return {
      ...provided,
      padding: `0.125rem ${px[size as Size]}`,
    };
  },
  loadingMessage: (provided: any, { selectProps: { size } }: any) => {
    const fontSizes = {
      sm: "0.875rem",
      md: "1rem",
      lg: "1.125rem",
    };

    const paddings = {
      sm: "6px 9px",
      md: "8px 12px",
      lg: "10px 15px",
    };

    return {
      ...provided,
      fontSize: fontSizes[size as Size],
      padding: paddings[size as Size],
    };
  },
  // Add the chakra style for when a TagCloseButton has focus
  multiValueRemove: (
    provided: any,
    { isFocused, selectProps: { multiValueRemoveFocusStyle } }: any
  ) => (isFocused ? multiValueRemoveFocusStyle : {}),
  control: () => ({}),
  menuList: () => ({}),
  option: () => ({}),
  multiValue: () => ({}),
  multiValueLabel: () => ({}),
  group: () => ({}),
};

const chakraComponents = {
  // Control components
  Control: ({
    children,
    innerRef,
    innerProps,
    isDisabled,
    isFocused,
    selectProps: { size, isInvalid },
  }: any) => {
    const inputStyles = useMultiStyleConfig("Input", { size });

    const heights = {
      sm: 8,
      md: 10,
      lg: 12,
    };

    return (
      <StylesProvider value={inputStyles}>
        <Flex
          ref={innerRef}
          sx={{
            ...inputStyles.field,
            p: 0,
            overflow: "hidden",
            h: "auto",
            minH: heights[size as Size],
            borderRadius: "lg",
          }}
          {...innerProps}
          data-focus={isFocused ? true : undefined}
          data-invalid={isInvalid ? true : undefined}
          data-disabled={isDisabled ? true : undefined}
        >
          {children}
        </Flex>
      </StylesProvider>
    );
  },
  MultiValueContainer: ({
    children,
    innerRef,
    innerProps,
    data,
    selectProps,
  }: any) => (
    <Tag
      ref={innerRef}
      {...innerProps}
      m="0.125rem"
      // react-select Fixed Options example: https://react-select.com/home#fixed-options
      variant={data.isFixed ? "solid" : "subtle"}
      colorScheme={data.colorScheme || selectProps.colorScheme}
      size={selectProps.size}
    >
      {children}
    </Tag>
  ),
  MultiValueLabel: ({ children, innerRef, innerProps }: any) => (
    <TagLabel ref={innerRef} {...innerProps}>
      {children}
    </TagLabel>
  ),
  MultiValueRemove: ({
    children,
    innerRef,
    innerProps,
    data: { isFixed },
  }: any) => {
    if (isFixed) {
      return null;
    }

    return (
      <TagCloseButton ref={innerRef} {...innerProps} tabIndex={-1}>
        {children}
      </TagCloseButton>
    );
  },
  IndicatorSeparator: ({ innerProps }: any) => (
    <Divider {...innerProps} orientation="vertical" opacity="1" />
  ),
  ClearIndicator: ({ innerProps, selectProps: { size } }: any) => (
    <CloseButton {...innerProps} size={size} mx={2} tabIndex={-1} />
  ),
  DropdownIndicator: ({ innerProps, selectProps: { size } }: any) => {
    const { addon } = useStyles();

    const iconSizes = {
      sm: 4,
      md: 5,
      lg: 6,
    };
    const iconSize = iconSizes[size as Size];

    return (
      <Center
        {...innerProps}
        sx={{
          ...addon,
          h: "100%",
          borderRadius: 0,
          borderWidth: 0,
          cursor: "pointer",
        }}
      >
        <ChevronDown h={iconSize} w={iconSize} />
      </Center>
    );
  },
  // Menu components
  MenuPortal: ({ children }: any) => <Portal>{children}</Portal>,
  Menu: (props: MenuProps<AutoCompleteItem, boolean, GroupOption>) => {
    const menuStyles = useMultiStyleConfig("Menu", {});
    return (
      <selectComponents.Menu<AutoCompleteItem, boolean, GroupOption> {...props}>
        <StylesProvider value={menuStyles}>{props.children}</StylesProvider>
      </selectComponents.Menu>
    );
  },
  MenuList: ({ innerRef, children, maxHeight, selectProps: { size } }: any) => {
    const { list } = useStyles();
    const chakraTheme = useTheme();

    const borderRadii = {
      sm: chakraTheme.radii.sm,
      md: chakraTheme.radii.md,
      lg: chakraTheme.radii.md,
    };

    return (
      <Box
        sx={{
          ...list,
          maxH: `${maxHeight}px`,
          overflowY: "auto",
          borderRadius: borderRadii[size as Size],
        }}
        ref={innerRef}
      >
        {children}
      </Box>
    );
  },
  GroupHeading: ({ innerProps, children }: any) => {
    const { groupTitle } = useStyles();
    return (
      <Box sx={groupTitle} {...innerProps}>
        {children}
      </Box>
    );
  },
  Option: (props: OptionProps<AutoCompleteItem, boolean, GroupOption>) => {
    const {
      innerRef,
      children,
      innerProps,
      isFocused,
      isDisabled,
      selectProps,
    } = props;
    const { item } = useStyles();
    return (
      <Box
        role="button"
        sx={{
          ...item,
          w: "100%",
          textAlign: "start",
          bg: isFocused ? "#EDF2F7" : "transparent",
          fontSize: "sm",
          //   ...(isDisabled && item._disabled),
        }}
        ref={innerRef}
        {...innerProps}
        {...(isDisabled && { disabled: true })}
      >
        <selectComponents.Option {...props} />
      </Box>
    );
  },
};

// const ChakraReactSelect = ({
//   children,
//   styles = {},
//   components = {},
//   theme = () => ({}),
//   size = "md",
//   colorScheme = "gray",
//   isDisabled,
//   isInvalid,
//   ...props
// }) => {
//   const select = cloneElement(children, {
//     components: {
//       ...chakraComponents,
//       ...components,
//     },
//     styles: {
//       ...chakraStyles,
//       ...styles,
//     },
//     theme: (baseTheme) => {
//       const propTheme = theme();

//       return {
//         ...baseTheme,
//         ...propTheme,
//         colors: {
//           ...baseTheme.colors,
//           neutral50: placeholderColor, // placeholder text color
//           neutral40: placeholderColor, // noOptionsMessage color
//           //   ...propTheme.colors,
//         },
//         spacing: {
//           ...baseTheme.spacing,
//           ...propTheme.spacing,
//         },
//       };
//     },
//     colorScheme,
//     size: realSize,
//     multiValueRemoveFocusStyle,
//     // isDisabled and isInvalid can be set on the component
//     // or on a surrounding form control
//     isDisabled: inputProps.disabled,
//     isInvalid: !!inputProps["aria-invalid"],
//     ...props,
//   });

//   return select;
// };

// const Select = forwardRef((props, ref) => (
//   <ChakraReactSelect {...props}>
//     <ReactSelect ref={ref} />
//   </ChakraReactSelect>
// ));

// const AsyncSelect = forwardRef((props, ref) => (
//   <ChakraReactSelect {...props}>
//     <AsyncReactSelect ref={ref} />
//   </ChakraReactSelect>
// ));

// const CreatableSelect = forwardRef((props, ref) => (
//   <ChakraReactSelect {...props}>
//     <CreatableReactSelect ref={ref} />
//   </ChakraReactSelect>
// ));
export type AutoCompleteItem = {
  id: string;
  name: string;
  isCreatable?: boolean;
};

export type AutoCompleteProps = {
  items: AutoCompleteItem[];
  isMultiple?: boolean;
  isSearchable?: boolean;
  isCreatable?: boolean;
  placeholder?: string;
  selected: AutoCompleteItem | AutoCompleteItem[] | undefined;
  fetchItems?: (q: string) => Promise<AutoCompleteItem[]>;
  onItemSelect: (items: AutoCompleteItem | AutoCompleteItem[]) => void;
  size?: ThemeTypings["components"]["Input"]["sizes"];
} & InputProps;

export const AutoComplete = React.forwardRef(
  (
    {
      items,
      isMultiple,
      isSearchable = true,
      isCreatable = true,
      placeholder,
      selected,
      fetchItems,
      onItemSelect,
      colorScheme,
      size = "md",
      isDisabled = false,
      ...rest
    }: AutoCompleteProps,
    ref
  ) => {
    const chakraTheme = useTheme();

    // Combine the props passed into the component with the props
    // that can be set on a surrounding form control to get
    // the values of isDisabled and isInvalid

    const inputProps = useFormControl({
      isDisabled,
      isInvalid: isDisabled,
    });

    // The chakra theme styles for TagCloseButton when focused
    const closeButtonFocus =
      chakraTheme.components.Tag.baseStyle.closeButton._focus;
    const multiValueRemoveFocusStyle = {
      background: closeButtonFocus.bg,
      boxShadow: chakraTheme.shadows[closeButtonFocus.boxShadow],
    };

    // The chakra UI global placeholder color
    // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/styles.ts#L13
    const placeholderColor = useColorModeValue(
      chakraTheme.colors.gray[400],
      chakraTheme.colors.whiteAlpha[400]
    );

    // Ensure that the size used is one of the options, either `sm`, `md`, or `lg`
    let realSize = size;
    const sizeOptions = ["sm", "md", "lg"];
    if (!sizeOptions.includes(size)) {
      realSize = "md";
    }
    const selectProps = {
      components: {
        ...chakraComponents,
      },
      styles: {
        ...chakraStyles,
      },
      theme: (baseTheme: any) => {
        return {
          ...baseTheme,
          colors: {
            ...baseTheme.colors,
            neutral50: placeholderColor, // placeholder text color
            neutral40: placeholderColor, // noOptionsMessage color
            //   ...propTheme.colors,
          },
          spacing: {
            ...baseTheme.spacing,
          },
        };
      },
      colorScheme,
      size: realSize,
      multiValueRemoveFocusStyle,
      // isDisabled and isInvalid can be set on the component
      // or on a surrounding form control
      isDisabled: inputProps.disabled,
      isInvalid: !!inputProps["aria-invalid"],
    };
    if (items.length) {
      console.log({ items });
    }
    if (isCreatable) {
      return (
        <Box>
          <AsyncCreatableSelect<AutoCompleteItem, boolean, GroupOption>
            {...selectProps}
            isMulti={isMultiple}
            defaultOptions={items || []}
            placeholder={placeholder || "Type to search"}
            isSearchable={isSearchable}
            isClearable
            value={selected}
            getOptionLabel={(option) => {
              return option.name;
            }}
            getOptionValue={(option) => {
              return option.id;
            }}
            ref={ref as any}
            formatCreateLabel={(val) => "Create " + val}
            getNewOptionData={(inputValue, optionLabel): AutoCompleteItem => {
              return {
                id: inputValue,
                name: optionLabel?.toString() || "",
                isCreatable: true,
              };
            }}
            onChange={(value) => {
              onItemSelect(value as AutoCompleteItem | AutoCompleteItem[]);
            }}
            onInputChange={(val) => {}}
            loadOptions={async (input: string) => {
              return await fetchItems?.(input);
            }}
          />
        </Box>
      );
    } else {
      return (
        <Box>
          <AsyncReactSelect<AutoCompleteItem, boolean, GroupOption>
            {...selectProps}
            isMulti={isMultiple}
            defaultOptions={items || []}
            placeholder={placeholder || "Type to search"}
            isSearchable={isSearchable}
            isClearable
            value={selected}
            getOptionLabel={(option) => {
              return option.name;
            }}
            getOptionValue={(option) => {
              return option.id;
            }}
            ref={ref as any}
            onChange={(value) => {
              onItemSelect(value as AutoCompleteItem | AutoCompleteItem[]);
            }}
            onInputChange={(val) => {}}
            loadOptions={async (input: string) => {
              return await fetchItems?.(input);
            }}
          />
        </Box>
      );
    }
  }
);

// export { Select as default, AsyncSelect, CreatableSelect };
