import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { getWorkspaces, selectWorkspace } from "../api/employer";
import { fetchUserConfig } from "../redux/actions/user";
import Bold from "./Bold";
import Card from "./Card";
import Modal from "./Modal";
import Button from "./Button";
import { ModalBody, ModalHeader } from "baseui/modal";
import Title from "./Title";
import { toaster } from "baseui/toast";
import { KIND, SIZE } from "baseui/button";
import { EMPLOYER_PROFILE_WORKSPACE } from "../util/routes";
import HiringWorkspaceMembers from "../screens/HiringWorkspaceMembers";
import {
  LabelMedium,
  LabelSmall,
  ParagraphMedium,
  ParagraphSmall,
  ParagraphXSmall,
} from "baseui/typography";
import { Block } from "baseui/block";
import { Icon } from "./Icon";

const WorkspaceRow = ({ workspace, onSelected }) => {
  // const [isSelecting, setIsSelecting] = useState(false);
  const dispatch = useDispatch();
  const selectWorkspaceAPI = async (workspaceId) => {
    // setIsSelecting(true);
    try {
      await selectWorkspace({
        workspaceId,
      });
      dispatch(fetchUserConfig());
      onSelected();
    } catch (e) {
      toaster.show(
        <div>Error while updating workspace. Please try again</div>,
        { autoHideDuration: 3000 }
      );
    }
    // setIsSelecting(false);
  };
  return (
    <Card
      style={{
        backgroundColor: "#f5f5f5",
        marginBottom: "8px",
        cursor: "pointer",
      }}
      onClick={async () => await selectWorkspaceAPI(workspace.id)}
    >
      <Bold>{workspace.name}</Bold>
      <div>{workspace.createdByName}</div>
    </Card>
  );
};

const WorkspaceList = ({ onSelected }) => {
  const [workspaces, setWorkspaces] = useState([]);
  const [memberships, setMemberships] = useState({});
  const getWorkspacesAPI = async () => {
    try {
      let { data, memberships } = await getWorkspaces();
      setWorkspaces(data);
      setMemberships(memberships);
    } catch (e) {}
  };

  useEffect(() => {
    getWorkspacesAPI();
  }, []);

  return (
    <div>
      {workspaces.map((workspace) => (
        <WorkspaceRow
          workspace={workspace}
          membership={memberships[workspace.id]}
          onSelected={onSelected}
        ></WorkspaceRow>
      ))}
    </div>
  );
};

const WorkspaceSelector = (props) => {
  const [
    isChangeWorkspaceModalVisible,
    setIsChangeWorkspaceModalVisible,
  ] = useState(false);
  const [isMembersFormVisible, setIsMembersFormVisible] = useState(false);
  return (
    <Card marginLeft="scale600" marginBottom="scale600">
      <ParagraphXSmall color="accent" letterSpacing={"1px"}>
        WORKSPACE
      </ParagraphXSmall>
      <LabelMedium marginBottom="scale600">{props.workspace.name}</LabelMedium>
      <Block $flexDirection="row" $display="flex" $marginTop="scale200">
        <Block $marginRight="scale200">
          <Button
            kind={KIND.tertiary}
            size={SIZE.mini}
            onClick={() => {
              setIsChangeWorkspaceModalVisible(true);
            }}
            startEnhancer={<Icon name="shuffle" size="12"></Icon>}
          >
            Change
          </Button>
        </Block>
        {props.workspaceMember && props.workspaceMember.role === "ADMIN" && (
          <Button
            kind={KIND.tertiary}
            size={SIZE.mini}
            onClick={() => {
              setIsMembersFormVisible(true);
            }}
            startEnhancer={<Icon name="plus" size="12"></Icon>}
          >
            <span>Add Members</span>
          </Button>
        )}
      </Block>

      <Modal
        animate
        autoFocus
        isOpen={isChangeWorkspaceModalVisible}
        closeable
        onClose={() => {
          setIsChangeWorkspaceModalVisible(false);
        }}
      >
        <ModalHeader>
          <Title>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Select Workspace</div>
              <Button
                size={SIZE.mini}
                kind={KIND.secondary}
                onClick={() => {
                  props.history.push(EMPLOYER_PROFILE_WORKSPACE);
                }}
              >
                Create Workspace
              </Button>
            </div>
          </Title>
        </ModalHeader>
        <ModalBody>
          <WorkspaceList
            onSelected={() => {
              setIsChangeWorkspaceModalVisible(false);
            }}
          />
        </ModalBody>
      </Modal>
      <Modal
        animate
        autoFocus
        isOpen={isMembersFormVisible}
        closeable
        onClose={() => {
          setIsMembersFormVisible(false);
        }}
      >
        <ModalHeader>
          <Title>Workspace Members</Title>
        </ModalHeader>
        <ModalBody>
          <HiringWorkspaceMembers workspaceId={props.workspace.id} />
        </ModalBody>
      </Modal>
    </Card>
  );
};

export default connect(
  (state) => ({
    user: state.user,
    employer: state.employer,
    workspace: state.workspace,
    workspaceMember: state.workspaceMember,
  }),
  {}
)(withRouter(WorkspaceSelector));
