import React, { useState } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import Button from "../Button";
import { SIZE } from "baseui/button";
import { Icon } from "../Icon";
import Card from "../Card";
import { LabelLarge, ParagraphSmall } from "baseui/typography";
import { Block } from "baseui/block";
import Currency from "../Currency";
import { connect } from "react-redux";
import { setWidgetState } from "../../redux/actions/widget";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tag,
  useDisclosure,
} from "@chakra-ui/react";
import { FiCopy, FiEdit, FiMoreVertical, FiX } from "react-icons/fi";

const JobCard = ({
  job,
  onViewCandidates,
  onEdit,
  onDuplicate,
  setWidgetState,
  isOpenNewWindow,
  editJobLink,
  onShare,
  onClose: onJobClose,
  isClickable,
  viewCandidatesLink,
  onApply,
  to,
  isCandidateView,
}) => {
  const history = useHistory();
  const target = isOpenNewWindow ? { target: "_blank" } : {};
  const [, setIsApplying] = useState(false);
  const onViewCandidatesClick = async () => {
    await onViewCandidates(job);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const onEditClick = async () => {
    await onEdit(job);
  };

  const onApplyJobClick = async () => {
    try {
      setIsApplying(true);
      await onApply(job);
    } catch (e) {
      console.log(e);
    }
    setIsApplying(false);
  };

  const onShareClick = async () => {
    await onShare();
    setWidgetState({
      isShareJobModelVisible: true,
      shareJob: job,
      shareJobImageUrl: job.shareImageUrl,
    });
  };

  // const renderEditButtonLink = () => (
  //   <Link {...target} to={editJobLink}>
  //     <Button
  //       size={SIZE.compact}
  //       kind={"tertiary"}
  //       onClick={async (e) => {
  //         await onEditClick(job);
  //       }}
  //       startEnhancer={<Icon size={12} name="edit" />}
  //     >
  //       Edit Job
  //     </Button>
  //   </Link>
  // );

  const renderViewCandidatesButton = () => {
    return (
      <Link {...target} to={viewCandidatesLink}>
        <Button
          size={SIZE.compact}
          kind={"primary"}
          onClick={async (e) => {
            e.stopPropagation();
            await onViewCandidatesClick(job);
          }}
          startEnhancer={<Icon size={12} name="users" color="background" />}
        >
          Source Candidates
        </Button>
      </Link>
    );
  };

  const renderApplyJobButton = () => {
    return (
      <Button
        size={SIZE.compact}
        kind={"primary"}
        onClick={async (e) => {
          // e.stopPropagation();
          // e.preventDefault();
          await onApplyJobClick(job);
        }}
        startEnhancer={<Icon size={12} name="arrow-right" color="background" />}
      >
        Apply Now
      </Button>
    );
  };

  const renderShareButton = () => {
    return (
      <Button
        size={SIZE.compact}
        kind={"minimal"}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          await onShareClick(job);
        }}
        startEnhancer={<Icon size={12} name="share-2" color="primary" />}
      >
        Share
      </Button>
    );
  };

  const renderContent = () => {
    const {
      company,
      jobTitle,
      locations,
      salaryCurrency,
      maximumSalary,
      minExperience,
      maxExperience,
      skills,
    } = job;
    return (
      <Card marginBottom="scale400" isClickable={isClickable}>
        <LabelLarge marginBottom="scale200">
          {isCandidateView ? jobTitle.name : company.name}
        </LabelLarge>
        <Block display="flex" flexWrap="wrap">
          <Block color="primary500" width="250px" paddingRight="scale400">
            {isCandidateView ? (
              <Block display="flex" alignItems="center" marginBottom="scale100">
                <Icon name="at-sign" size={12} color="primary500" />
                <ParagraphSmall marginLeft="scale200" color="primary500">
                  {company.name}
                </ParagraphSmall>
              </Block>
            ) : (
              <Block display="flex" alignItems="center" marginBottom="scale100">
                <Icon name="user" size={12} color="primary500" />
                <ParagraphSmall marginLeft="scale200" color="primary500">
                  {jobTitle.name}
                </ParagraphSmall>
              </Block>
            )}

            {/* <Block display="flex" alignItems="center" marginBottom="scale100">
              <Icon name="heart" size={12} color="primary500" />
              <Block marginLeft="scale200">
                <Currency
                  inWords
                  currency={salaryCurrency}
                  value={maximumSalary}
                ></Currency>
              </Block>
            </Block> */}
            <Block display="flex" alignItems="center" marginBottom="scale100">
              <Icon name="calendar" size={12} color="primary500" />
              <ParagraphSmall marginLeft="scale200" color="primary500">
                {minExperience + "-" + maxExperience + " Yrs"}
              </ParagraphSmall>
            </Block>
            <Block display="flex" alignItems="center" marginBottom="scale100">
              <Icon name="map-pin" size={12} color="primary500" />
              <ParagraphSmall marginLeft="scale200" color="primary500">
                {locations.map((l) => l.name).join(", ")}
              </ParagraphSmall>
            </Block>
            {skills.length > 0 && (
              <Block display="flex" alignItems="center" marginBottom="scale100">
                <Icon name="tag" size={12} color="primary500" />
                <ParagraphSmall marginLeft="scale200" color="primary500">
                  {skills
                    .slice(0, 3)
                    .map((s) => s.name)
                    .join(", ")}
                </ParagraphSmall>
              </Block>
            )}
            {job.status === "CLOSED" ? (
              <Tag colorScheme={"red"}>
                <Text>This job is closed now</Text>
              </Tag>
            ) : null}
          </Block>
        </Block>
        <Block
          display="flex"
          alignItems="center"
          marginTop="scale800"
          flexWrap="wrap"
        >
          {onViewCandidates && (
            <Block marginRight="scale200">{renderViewCandidatesButton()}</Block>
          )}
          {onApply && (
            <Block marginRight="scale200">{renderApplyJobButton()}</Block>
          )}
          {/* {onEdit && (
            <Block marginRight="scale200">{renderEditButtonLink()}</Block>
          )} */}
          <Block flex={1} />
          <Block marginRight="scale200">{onShare && renderShareButton()}</Block>
          {onEdit && (
            <Menu
              className="job-options-menu"
              onOpen={() => {
                setIsMenuOpen(true);
              }}
              onClose={() => {
                onClose();
                setTimeout(() => {
                  setIsMenuOpen(false);
                }, 1000);
              }}
              isOpen={isOpen}
            >
              <MenuButton
                as={IconButton}
                className="menu-options"
                aria-label="Options"
                icon={<FiMoreVertical />}
                variant="outline"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onOpen();
                  setIsMenuOpen(true);
                }}
              />
              <MenuList>
                <MenuItem
                  icon={<FiEdit />}
                  onClick={async () => {
                    await onEditClick();
                  }}
                >
                  Edit Job
                </MenuItem>
                <MenuItem
                  icon={<FiCopy />}
                  onClick={async () => {
                    await onDuplicate();
                  }}
                >
                  Duplicate Job
                </MenuItem>
                {job.status !== "CLOSED" && (
                  <MenuItem
                    icon={<FiX />}
                    onClick={async () => {
                      await onJobClose();
                    }}
                  >
                    Close Job
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )}
        </Block>
      </Card>
    );
  };

  return to ? (
    <div
      onClick={(e) => {
        console.log({ isMenuOpen, isOpen });
        const jobOptions = document.querySelectorAll(".job-options-menu")
          .length;
        if (
          !e.currentTarget.classList.contains("menu-options") &&
          !isMenuOpen &&
          !jobOptions.length
        ) {
          if (isOpenNewWindow) {
            window.open(to);
          } else {
            history.push(to);
          }
        }
        e.stopPropagation();
      }}
    >
      {renderContent()}
    </div>
  ) : (
    renderContent()
  );
};

export default withRouter(connect(() => ({}), { setWidgetState })(JobCard));
