import React from "react";
import Button from "../components/Button";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Spacing from "../components/Spacing";
import TextArea from "../components/TextArea";
import Popover from "../components/Popover";
import { searchEntity } from "../api/entity";
import {
  getCandidateProjection,
  updateCandidateProfile,
} from "../api/candidate";
import EmptyCaption from "../components/EmptyCaption";
import Select from "../components/Select";
import Title from "../components/Title";
import { FormControl } from "baseui/form-control";
import Label from "../components/Label";
import { CANDIDATE_PROFILE_PROJECT } from "../util/routes";
import LoadingScreen from "../components/LoadingScreen";
import * as Analytics from "../analytics";
import { TRIGGER_TYPE } from "baseui/popover";
import Bold from "../components/Bold";
import { CenterContainer } from "../components/CenterContainer";
import Card from "../components/Card";

class CandidateProfileProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isFetching: true,
      isAdding: false,
      isSaving: false,
      mode: "ADD",
      modalProps: {},
      editId: "",
      skills: [],
      careerSummary: "",
      idealNextJob: "",
      values: {
        company: "",
        briefIntro: "",
      },
      errors: {},
    };
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && typeof this.state.values[id] === "undefined") {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  getValidations(id) {
    switch (id) {
      case "idealNextJob":
        return { required: true };
      default:
        return {};
    }
  }

  async componentDidMount() {
    Analytics.logEvent("[C] page view profile profile");
    this.setState({
      isFetching: true,
    });
    try {
      let { careerSummary, idealNextJob, skills } =
        (await getCandidateProjection(this.props.user.id, "profile")) || {};
      this.setState({
        careerSummary,
        idealNextJob,
        skills: skills || [],
      });
      this.setState({
        isFetching: false,
      });
    } catch (ex) {
      this.setState({
        isFetching: false,
      });
      console.log(ex);
    }
  }

  render() {
    if (this.state.isFetching)
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    return (
      <CenterContainer>
        <Title>Profile</Title>
        <Card>
          <FormControl
            label={
              <Label
                appendText={
                  "(" + (this.state.careerSummary || "").length + "/120 chars)"
                }
                subtitle={
                  "The shortest way someone will introduce you to your next hiring manager"
                }
              >
                {"Add a short profile summary"}
              </Label>
            }
            caption={<EmptyCaption />}
            error={this.state.careerSummaryError}
          >
            <TextArea
              placeholder={""}
              resize
              onChange={(e) => {
                if (typeof e.target.value !== "undefined") {
                  this.setState({
                    careerSummary: e.target.value.substring(0, 120),
                  });
                }
              }}
              value={this.state.careerSummary}
              autoComplete={"off"}
            />
          </FormControl>

          <FormControl
            caption={<EmptyCaption />}
            label={
              <Label
                subtitle={
                  <div>
                    <div>
                      Well, there are no right or wrong answers. This is an
                      opportunity to write your Job Description (JD)
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Popover
                        triggerType={TRIGGER_TYPE.hover}
                        content={
                          <div style={{ maxWidth: 420, padding: "16px" }}>
                            <Bold style={{ marginBottom: "16px" }}>
                              Describe what are you looking for in your next
                              ideal job?
                            </Bold>
                            <div style={{ marginBottom: "16px" }}>
                              Premium candidates know what they want and can
                              clearly articulate them to potential employers.
                            </div>
                            <div style={{ marginBottom: "16px" }}>
                              Your potential employers want to know about your
                              passion, aspirations, and expectations from your
                              new role. This helps them to interview you for
                              suitable roles.
                            </div>
                            <div
                              style={{ color: "#919191", fontSize: ".8rem" }}
                            >
                              To answer this question successfully, describe the
                              roles and responsibilities you would like to
                              handle. Write how your career goals, passion, and
                              motivations overlap with the Job Description (JD)
                              you just created. Lastly, by showcasing the unique
                              skills and experiences that you bring to the
                              table, demonstrate that you are the perfect
                              candidate for this role
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Tips
                        </div>
                      </Popover>
                      <Popover
                        triggerType={TRIGGER_TYPE.hover}
                        content={
                          <div style={{ maxWidth: "420px", padding: "16px" }}>
                            <div>
                              Artificial Intelligence (AI) and Machine Learning
                              (ML) are two of the emerging trends I am keen to
                              explore in my new role.
                            </div>
                            <div>
                              In my current role, I have built production-ready
                              back-end apps and scalable AWS-cloud services. In
                              the new role, I would like to use these skills and
                              experience to architect and build cloud-ready
                              AI-use cases.
                            </div>
                            <div>
                              Preferably, I want to work in an AI-project team
                              to learn and develop beautiful and practical AI/ML
                              apps.
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            marginLeft: 16,
                            cursor: "pointer",
                          }}
                        >
                          Example 1
                        </div>
                      </Popover>
                      <Popover
                        triggerType={TRIGGER_TYPE.hover}
                        content={
                          <div style={{ maxWidth: "420px", padding: "16px" }}>
                            <ul>
                              <li>
                                As an Interaction Designer, I enjoy the
                                challenge of taking on complex tasks & transform
                                them into intuitive, accessible, and easy-to-use
                                designs for first-time as well as the
                                sophisticated expert users. Many times, this
                                involves, developing wireframes, mockups,
                                micro-interaction matrices, & prototypes.
                              </li>
                              <li>
                                In my new role, I would like to join a company
                                that has a mature design culture, well-defined
                                design processes, and design roles.
                              </li>
                            </ul>
                          </div>
                        }
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            marginLeft: 16,
                            cursor: "pointer",
                          }}
                        >
                          Example 2
                        </div>
                      </Popover>
                      <Popover
                        triggerType={TRIGGER_TYPE.hover}
                        content={
                          <div style={{ maxWidth: "420px", padding: "16px" }}>
                            <ul>
                              <li>
                                I like to work with an early-stage startup that
                                is solving a challenging problem
                              </li>
                              <li>
                                For this opportunity to work with the founding
                                team, I am open to taking a pay cut and opt for
                                stock-option based compensation
                              </li>
                              <li>
                                Currently, as a Product Manager, I interact with
                                customers to identify & analyze product feedback
                                to shape new product features. Further, I
                                coordinate with the designers & engineers to
                                deliver features on time
                              </li>
                              <li>
                                I hope that my skills in bringing ideas to life
                                will be valuable in a startup
                              </li>
                            </ul>
                          </div>
                        }
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            marginLeft: 16,
                            cursor: "pointer",
                          }}
                        >
                          Example 3
                        </div>
                      </Popover>
                    </div>
                  </div>
                }
                appendText={
                  "(" +
                  (this.state.idealNextJob || "").length +
                  "/500 chars" +
                  ")"
                }
              >
                {"Describe what are you looking for in your next ideal job?"}
              </Label>
            }
            error={this.state.idealNextJobError}
          >
            <TextArea
              resize
              placeholder={""}
              onChange={(e) => {
                if (typeof e.target.value !== "undefined") {
                  this.setState({
                    idealNextJob: e.target.value.substring(0, 500),
                  });
                }
              }}
              value={this.state.idealNextJob}
              autoComplete={"off"}
            />
          </FormControl>

          <FormControl
            label={
              <Label
                subtitle={
                  <div>
                    <div>
                      Your top 10 skills, preferably transferable skills, that
                      you will bring to your next job. When a hiring manager
                      searches for these skills, your profile will show up in
                      search results
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Popover
                        triggerType={TRIGGER_TYPE.hover}
                        content={
                          <div style={{ maxWidth: 420, padding: "16px" }}>
                            <div>Creative Problem Solving</div>
                            <div>Visual Storytelling</div>
                            <div>Adobe Creative Suite</div>
                            <div>User Experience Design (UED)</div>
                            <div>Wire-Framing</div>
                            <div>Responsive Web Design</div>
                            <div>Prototyping</div>
                            <div>Interaction Design</div>
                            <div>Hire & Manage Designers</div>
                            <div>User Research</div>
                            <div
                              style={{
                                fontSize: "10px",
                                fontWeight: "400",
                                color: "#919191",
                                marginTop: "8px",
                              }}
                            >
                              The examples shown here are for illustrative
                              purposes only. The skills you may have gained in
                              similar job titles might be different.
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Designer
                        </div>
                      </Popover>
                      <Popover
                        triggerType={TRIGGER_TYPE.hover}
                        content={
                          <div style={{ maxWidth: "420px", padding: "16px" }}>
                            <div>
                              Excellent Verbal & Written Communication Skills
                            </div>
                            <div>Drive Experimentation Strategy</div>
                            <div>A/B Testing</div>
                            <div>Funnel Optimization</div>
                            <div>Prototyping</div>
                            <div>Hire, Mentor & Coach Product Managers</div>
                            <div>Agile Methodologies</div>
                            <div>Market Research</div>
                            <div>User Stories</div>
                            <div>Product Road Mapping</div>
                            <div
                              style={{
                                fontSize: "10px",
                                fontWeight: "400",
                                color: "#919191",
                                marginTop: "8px",
                              }}
                            >
                              The examples shown here are for illustrative
                              purposes only. The skills you may have gained in
                              similar job titles might be different.
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            marginLeft: 16,
                            cursor: "pointer",
                          }}
                        >
                          Product Manager
                        </div>
                      </Popover>
                      <Popover
                        triggerType={TRIGGER_TYPE.hover}
                        content={
                          <div style={{ maxWidth: "420px", padding: "16px" }}>
                            <div>Design Patterns</div>
                            <div>Swift (Programming Language)</div>
                            <div>Core Animation</div>
                            <div>Objective-C</div>
                            <div>Xcode</div>
                            <div>Cocoa Touch</div>
                            <div>Release Engineering</div>
                            <div>Strong Communication Skills</div>
                            <div>MVC Architecture</div>
                            <div>Application Memory Management</div>
                            <div
                              style={{
                                fontSize: "10px",
                                fontWeight: "400",
                                color: "#919191",
                                marginTop: "8px",
                              }}
                            >
                              The examples shown here are for illustrative
                              purposes only. The skills you may have gained in
                              similar job titles might be different.
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            marginLeft: 16,
                            cursor: "pointer",
                          }}
                        >
                          iOS Developer
                        </div>
                      </Popover>
                      <Popover
                        triggerType={TRIGGER_TYPE.hover}
                        content={
                          <div style={{ maxWidth: "420px", padding: "16px" }}>
                            <div>Javascript</div>
                            <div>Cascading Style Sheets (CSS)</div>
                            <div>jQquery</div>
                            <div>SQL</div>
                            <div>Node.js</div>
                            <div>Mongodb</div>
                            <div>Html5</div>
                            <div>Bootstrap</div>
                            <div>Algorithms</div>
                            <div>
                              Excellent Verbal & Written Communication Skills
                            </div>
                            <div
                              style={{
                                fontSize: "10px",
                                fontWeight: "400",
                                color: "#919191",
                                marginTop: "8px",
                              }}
                            >
                              The examples shown here are for illustrative
                              purposes only. The skills you may have gained in
                              similar job titles might be different.
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            marginLeft: 16,
                            cursor: "pointer",
                          }}
                        >
                          Full Stack Developer
                        </div>
                      </Popover>
                      <Popover
                        triggerType={TRIGGER_TYPE.hover}
                        content={
                          <div style={{ maxWidth: "420px", padding: "16px" }}>
                            <div>Python (Programming Language)</div>
                            <div>R (Programming Language)</div>
                            <div>Machine Learning</div>
                            <div>Deep Learning</div>
                            <div>Search Personalization</div>
                            <div>Experimentation Strategy</div>
                            <div>Storytelling With Data</div>
                            <div>Manage High-Performance Teams</div>
                            <div>Statistical Modelling</div>
                            <div>Manage Cross-Functional Teams</div>
                            <div
                              style={{
                                fontSize: "10px",
                                fontWeight: "400",
                                color: "#919191",
                                marginTop: "8px",
                              }}
                            >
                              The examples shown here are for illustrative
                              purposes only. The skills you may have gained in
                              similar job titles might be different.
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            marginLeft: 16,
                            cursor: "pointer",
                          }}
                        >
                          Data Scientist
                        </div>
                      </Popover>
                      <Popover
                        triggerType={TRIGGER_TYPE.hover}
                        content={
                          <div style={{ maxWidth: "420px", padding: "16px" }}>
                            <div>Javascript</div>
                            <div>Cascading Style Sheets (CSS)</div>
                            <div>jQquery</div>
                            <div>SQL</div>
                            <div>Node.js</div>
                            <div>Mongodb</div>
                            <div>Html5</div>
                            <div>Bootstrap</div>
                            <div>Algorithms</div>
                            <div>
                              Excellent Verbal & Written Communication Skills
                            </div>
                            <div
                              style={{
                                fontSize: "10px",
                                fontWeight: "400",
                                color: "#919191",
                                marginTop: "8px",
                              }}
                            >
                              The examples shown here are for illustrative
                              purposes only. The skills you may have gained in
                              similar job titles might be different.
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            marginLeft: 16,
                            cursor: "pointer",
                          }}
                        >
                          Full Stack Developer
                        </div>
                      </Popover>
                    </div>
                  </div>
                }
                appendText={
                  "(" + (this.state.skills || "").length + "/10 skills)"
                }
              >
                {"Add your skills"}
              </Label>
            }
            caption={<EmptyCaption />}
            error={this.state.skillsError}
          >
            <Select
              creatable
              multi
              getOptions={async (query) => searchEntity("skill", query)}
              onChange={(value) => {
                if ((value || []).length > 10) {
                  return;
                }
                this.setState({ skills: value });
              }}
              value={this.state.skills}
            />
          </FormControl>

          <Spacing size={4} />
          <Button
            isLoading={this.state.isSaving}
            onClick={async () => {
              if (!this.state.careerSummary) {
                this.setState({
                  careerSummaryError: "Please enter profile summary",
                });
                return;
              }
              if (!this.state.idealNextJob) {
                this.setState({
                  idealNextJobError:
                    "Please enter details about your ideal next job",
                });
                return;
              }
              if (!this.state.skills || !this.state.skills.length) {
                this.setState({
                  skillsError: "Please enter minimum one skill",
                });
                return;
              }
              this.setState({
                isSaving: true,
                skillsError: "",
                careerSummaryError: "",
              });
              try {
                await updateCandidateProfile(this.props.user.Id, "profile", {
                  careerSummary: this.state.careerSummary,
                  idealNextJob: this.state.idealNextJob,
                  skills: this.state.skills,
                });
                this.setState({
                  isSaving: false,
                });
              } catch (e) {
                this.setState({
                  isSaving: false,
                });
                return;
              }
              let candidateOnboarding =
                this.props.user.candidateOnboarding || [];
              if (candidateOnboarding.indexOf("profile") === -1) {
                candidateOnboarding.push("profile");
                candidateOnboarding.push("projects");
              }
              this.props.setUser({
                candidateOnboarding,
              });
              this.props.history.push(CANDIDATE_PROFILE_PROJECT);
            }}
          >
            Save & Continue
          </Button>
          <Spacing size={4} />
        </Card>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateProfileProfile));

// <FormControl
//                 label={<Label>{"Number of people in the project"}</Label>}
//               >
//                 <Input
//                   id={"teamSize"}
//                   onChange={e =>
//                     this.updateFormValues("teamSize", +e.target.value)
//                   }
//                   value={this.state.values["teamSize"]}
//                   autoComplete={"off"}
//                   endEnhancer={
//                     <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
//                       team members
//                     </span>
//                   }
//                   type={"number"}
//                   min={"0"}
//                   max={"100000"}
//                   onBlur={e => {
//                     let value = parseInt(e.target.value + "");
//                     if (!value) {
//                       value = 0;
//                     }
//                     this.setState({
//                       values: {
//                         ...this.state.values,
//                         ...{
//                           teamSize: value
//                         }
//                       }
//                     });
//                   }}
//                 />
//               </FormControl>
//               <FormControl label={<Label>{"Your role in the project"}</Label>}>
//                 <TextArea
//                   id={"projectRole"}
//                   onChange={e =>
//                     this.updateFormValues("projectRole", e.target.value)
//                   }
//                   value={this.state.values["projectRole"]}
//                   autoComplete={"off"}
//                 />
//               </FormControl>
//               <FormControl label={<Label>{"Outcome of the project"}</Label>}>
//                 <TextArea
//                   id={"outcome"}
//                   onChange={e =>
//                     this.updateFormValues("outcome", e.target.value)
//                   }
//                   value={this.state.values["outcome"]}
//                   autoComplete={"off"}
//                 />
//               </FormControl>
//               <FormControl label={<Label>{"Why this project mattered"}</Label>}>
//                 <TextArea
//                   id={"whyMattered"}
//                   onChange={e =>
//                     this.updateFormValues("whyMattered", e.target.value)
//                   }
//                   value={this.state.values["whyMattered"]}
//                   autoComplete={"off"}
//                 />
//               </FormControl>
//               <FormControl
//                 label={<Label>{"Skills demonstrated in this project"}</Label>}
//                 caption={<EmptyCaption />}
//                 error={this.state.errors["skills"]}
//               >
//                 <Select
//                   creatable
//                   multi
//                   getOptions={async query => searchEntity("skill", query)}
//                   onChange={value => {
//                     this.updateFormValues("skills", value);
//                   }}
//                   value={this.state.values["skills"]}
//                 />
//               </FormControl>
