import React from "react";
import * as Analytics from "./analytics";
import { Button } from "@chakra-ui/react";
import firebase from "firebase";
import { useDispatch } from "react-redux";
import { googleSignIn } from "./api/user";
import { fetchUserConfig } from "./redux/actions/user";
import { toaster } from "baseui/toast";
import { useState } from "react";
import { Block } from "baseui/block";
import { Icon } from "./components/Icon";
import { LabelMedium } from "baseui/typography";
export const GoogleLogin = ({ signoutUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const onLoginClick = async () => {
    Analytics.logEvent("click submit google login");
    setIsLoading(true);
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().useDeviceLanguage();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        // var token = result.credential.accessToken;
        var user = result.user;
        try {
          await googleSignIn({
            email: user.email,
            userId: user.uid,
            name: user.displayName,
          });
          //   Analytics.setUser(savedUser);
          //   Sentry.setUser(savedUser);
          dispatch(fetchUserConfig(user.uid)).then(() => setIsLoading(false));
        } catch (e) {
          signoutUser();
          await firebase.auth().signOut();
          toaster.negative(
            <div>{"Error while creating user. Please try again"}</div>,
            {
              autoHideDuration: 5000,
            }
          );
        }
      })
      .catch(async (error) => {
        Analytics.logEvent("error submit google login", {
          code: error && error.code,
          message: error && error.message,
        });
        toaster.show(
          <div>
            <div>Login Cancelled</div>
            <div>
              If you are using incognito mode, Please do login with normal mode.
            </div>
          </div>,
          {
            autoHideDuration: 15000,
          }
        );
        var errorMessage = error.message;
        signoutUser();
        await firebase.auth().signOut();
        setIsLoading(false);
        toaster.negative(<div>{errorMessage}</div>, {
          autoHideDuration: 5000,
        });
      });
  };
  return (
    <Button isLoading={isLoading} kind={"tertiary"} onClick={onLoginClick}>
      <Block $marginRight={"scale400"}>
        <Icon name="google" size={16} />
      </Block>
      <LabelMedium>Sign In with Google</LabelMedium>
    </Button>
  );
};

// var errorCode = error.code;
// var email = error.email;
// var credential = error.credential;
