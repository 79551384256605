import api from "./index";
import { baseUrl } from "./endpoints";

export async function getEmployer(userId) {
  return await api({
    baseUrl,
    url: "/api/rc/" + userId,
    method: "GET",
  });
}

export async function getEmployerBandDetails(userId) {
  return await api({
    baseUrl,
    url: "/api/rc/" + userId + "/bank/details",
    method: "GET",
  });
}

export async function getTSRequests(userId, params) {
  return await api({
    baseUrl,
    url: "/api/rc/" + userId + "/candidates/active",
    method: "GET",
    params,
  });
}

export async function getSavedCandidates(userId, params) {
  return await api({
    baseUrl,
    url: "/api/rc/" + userId + "/candidates/saved",
    method: "GET",
    params,
  });
}

export async function updateWorkEmail(data) {
  return await api({
    baseUrl,
    url: "/api/rc/workemail",
    method: "POST",
    data: data,
  });
}

export async function updateEmployer(data) {
  return await api({
    baseUrl,
    url: "/api/rc/",
    method: "POST",
    data: data,
  });
}

export async function updateEmployerBasic(data) {
  return await api({
    baseUrl,
    url: "/api/rc/profile/basic",
    method: "POST",
    data: data,
  });
}

export async function updateEmployerWork(data) {
  return await api({
    baseUrl,
    url: "/api/rc/profile/work",
    method: "POST",
    data: data,
  });
}

export async function updateEmployerTaxData(data) {
  return await api({
    baseUrl,
    url: "/api/rc/profile/taxdata",
    method: "POST",
    data: data,
  });
}

export async function submitEmployer(data) {
  return await api({
    baseUrl,
    url: "/api/rc/submit",
    method: "POST",
    data: data,
  });
}

export async function getEmployerInterviewRequest(interviewRequestId) {
  return await api({
    baseUrl,
    url: "/api/rc/interview/" + interviewRequestId,
    method: "GET",
  });
}

export async function createInterviewRequest(data) {
  return await api({
    baseUrl,
    url: "/api/rc/interview/create",
    method: "POST",
    data: data,
  });
}

export async function reportInterviewRequest(data) {
  return await api({
    baseUrl,
    url: "/api/rc/interview/report",
    method: "POST",
    data: data,
  });
}

export async function getEmployerInterviewRequests(
  employerId,
  page,
  limit,
  status
) {
  return await api({
    baseUrl,
    url: "/api/rc/" + employerId + "/interviews",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
      { name: "status", value: status },
    ],
  });
}

export async function getWorkspaceInterviewRequests(
  workspaceId,
  page,
  limit,
  status
) {
  return await api({
    baseUrl,
    url: "/api/rc/workspace/" + workspaceId + "/interviews",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
      { name: "status", value: status },
    ],
  });
}

export async function getWorkspaceMembers(workspaceId, page, limit) {
  return await api({
    baseUrl,
    url: "/api/rc/workspace/" + workspaceId + "/members",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getWorkspaces(page, limit) {
  return await api({
    baseUrl,
    url: "/api/rc/workspace/list",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getCountInterviewRequestsPerCandidate(
  anonymousId,
  jobId
) {
  return await api({
    baseUrl,
    url: "/api/rc/interviewRequests/candidateCount",
    method: "GET",
    params: [
      { name: "anonymousId", value: anonymousId },
      { name: "jobId", value: jobId },
    ],
  });
}

export async function getInterviewRequestsPerCandidate(
  anonymousId,
  jobId,
  page,
  limit
) {
  return await api({
    baseUrl,
    url: "/api/rc/interviewRequests/candidate",
    method: "GET",
    params: [
      { name: "anonymousId", value: anonymousId },
      { name: "jobId", value: jobId },
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function addWorkspaceMember(data) {
  return await api({
    baseUrl,
    url: "/api/rc/workspace/members/create",
    method: "POST",
    data: data,
  });
}

export async function editWorkspaceMember(membershipId, data) {
  return await api({
    baseUrl,
    url: "/api/rc/workspace/members/" + membershipId + "/edit",
    method: "POST",
    data: data,
  });
}

export async function removeWorkspaceMember(membershipId, data) {
  return await api({
    baseUrl,
    url: "/api/rc/workspace/members/" + membershipId + "/remove",
    method: "POST",
    data: data,
  });
}

export async function createWorkspace(data) {
  return await api({
    baseUrl,
    url: "/api/rc/workspace/create",
    method: "POST",
    data: data,
  });
}

export async function editWorkspace(workspaceId, data) {
  return await api({
    baseUrl,
    url: "/api/rc/workspace/" + workspaceId + "/edit",
    method: "POST",
    data: data,
  });
}

export async function selectWorkspace(data) {
  return await api({
    baseUrl,
    url: "/api/rc/workspace/select",
    method: "POST",
    data: data,
  });
}
