import api from "./index";
import { baseUrl } from "./endpoints";

export async function getTalentscout(userId) {
  return await api({
    baseUrl,
    url: "/api/ts/" + userId,
    method: "GET",
  });
}

export async function getUploadedCandidatesStats(userId) {
  return await api({
    baseUrl,
    url: "/api/ts/candidates/my/stats",
    method: "GET",
  });
}

export async function updateTalentscout(data) {
  return await api({
    baseUrl,
    url: "/api/ts/",
    method: "POST",
    data: data,
  });
}

export async function updateTalentscoutLinkedInShareStatus(data) {
  return await api({
    baseUrl,
    url: "/api/ts/profile/linkedInShare",
    method: "POST",
    data: data,
  });
}

export async function updateTalentscoutProfile(data) {
  return await api({
    baseUrl,
    url: "/api/ts/profile/details",
    method: "POST",
    data: data,
  });
}

export async function updateTalentscoutTaxData(data) {
  return await api({
    baseUrl,
    url: "/api/ts/profile/taxdata",
    method: "POST",
    data,
  });
}

export async function addMyCandidates(data) {
  return await api({
    baseUrl,
    url: "/api/ts/candidates/my",
    method: "POST",
    data,
  });
}

export async function removeMyCandidate(tsRequestId) {
  return await api({
    baseUrl,
    url: "/api/ts/candidates/my/" + tsRequestId + "/remove",
    method: "POST",
  });
}

export async function getMyAssignedJobs(scoutId, candidateId) {
  return await api({
    baseUrl,
    url: "/api/ts/" + scoutId + "/jobs/assigned",
    method: "GET",
    params: candidateId ? [{ name: "candidateId", value: candidateId }] : [],
  });
}

export async function getMyCandidates(scoutId, filter, page, limit) {
  return await api({
    baseUrl,
    url: "/api/ts/" + scoutId + "/candidates/my",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
      { name: "candidateStatus", value: filter },
    ],
  });
}

export async function getMyCandidateDetail(tsRequestId) {
  return await api({
    baseUrl,
    url: "/api/ts/candidates/my/" + tsRequestId,
    method: "GET",
  });
}

export async function uploadCandidate(data) {
  return await api({
    baseUrl,
    url: "/api/ts/candidates/upload",
    method: "POST",
    data,
  });
}

export async function submitTalentScout(data) {
  return await api({
    baseUrl,
    url: "/api/ts/submit",
    method: "POST",
    data: data,
  });
}
