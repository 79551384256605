import React, { useEffect, useState } from "react";
import { Block } from "baseui/block";
import { CenterContainer } from "../../components/CenterContainer";
import Title from "../../components/Title";
import {
  getUploadedCandidate,
  getUploadedCandidates,
} from "../../api/reviewer/uploaded_candidates";
import Pagination from "../../components/Pagination";
import {
  StyledCell,
  StyledHead,
  StyledHeadCell,
  StyledRow,
  StyledBody,
  StyledTable,
} from "baseui/table";
import { Link } from "react-router-dom";

export const UploadedCandidates = (props) => {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(30);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [uploadedCandidates, setUploadedCandidates] = useState([]);
  const fetchUploadedCandidates = async () => {
    const { data, hasNext } = await getUploadedCandidates(page, limit);
    setUploadedCandidates(data);
    setHasNext(hasNext);
    setHasPrev(page > 0);
  };

  const onPrevClick = () => {
    setPage(page - 1);
  };

  const onNextClick = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    fetchUploadedCandidates();
  }, [page]);

  return (
    <CenterContainer>
      <Title>Uploaded Candidates</Title>
      <StyledTable>
        <StyledHead>
          <StyledHeadCell>Email</StyledHeadCell>
        </StyledHead>
        <StyledBody>
          <div style={{ marginBottom: "32px" }}>
            {uploadedCandidates.map((uploadedCandidate, index) => (
              <StyledRow key={index}>
                <StyledCell>
                  <Link
                    to={
                      "/R0VHxB8M/reviewer/details/uploadedCandidates/" +
                      uploadedCandidate.id +
                      "/details"
                    }
                  >
                    {uploadedCandidate.candidateEmail}
                  </Link>
                </StyledCell>
              </StyledRow>
            ))}
          </div>
        </StyledBody>
      </StyledTable>
      <Pagination
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        hasNext={hasNext}
        hasPrev={hasPrev}
      />
    </CenterContainer>
  );
};
