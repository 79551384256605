import api from "./index";
import { baseUrl } from "./endpoints";

export type UserProps = {
    id: string
    email: string
    phone: string
    roles: string[]
}

export type UserFilter = {
    email: string
}

export type GetUserPropsResponse = {
    data: UserProps[]
    hasNext: boolean
}

export async function getUserList({ email, sortField, sortOrder, page, limit }: { email?: string, sortField?: string, sortOrder?: string, page: number, limit: number }): Promise<GetUserPropsResponse> {
    const res: GetUserPropsResponse = await api({
        baseUrl,
        url: "/api/user/list",
        method: "GET",
        data: undefined,
        params: [
            { name: "sortField", value: sortField },
            { name: "sortOrder", value: sortOrder },
            { name: "page", value: page },
            { name: "limit", value: limit },
            { name: "email", value: email },
        ],
    });
    return res
}


export async function updateUser(id: string, data: { roles: string[] }) {
    return api({
        baseUrl,
        url: `/api/user/${id}`,
        method: "POST",
        data: {
            id,
            ...data
        }
    });
}