import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Title from "../../../components/Title";
import { logEvent } from "../../../analytics/index";
import Pagination from "../../../components/Pagination";
import { CenterContainer } from "../../../components/CenterContainer";
import LoadingScreen from "../../../components/LoadingScreen";
import { Block } from "baseui/block";
import CandidateCard from "../../../components/employer/CandidateCard";
import { getCurrency, getCurrencyWords } from "../../../util/currency";
import { prettyPrint } from "../../../util/time";
import { getInterviewRequestsPerCandidate } from "../../../api/employer";
import { LabelSmall } from "baseui/typography";

const InterviewHistory = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [candidate, setCandidate] = useState({});
  const [interviewRequests, setInterviewRequests] = useState([]);
  const [jobs, setJobs] = useState({});
  const [page, setPage] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const { jobId } = props;
  const { anonymousId } = props.match.params;
  const limit = 10;

  const onLoad = useCallback(
    async (page) => {
      try {
        setIsFetching(true);
        let {
          jobs,
          data: interviewRequests,
          candidate,
          hasNext,
        } = await getInterviewRequestsPerCandidate(
          anonymousId,
          jobId,
          page,
          limit
        );
        setIsFetching(false);
        setJobs(jobs);
        setCandidate(candidate || []);
        setInterviewRequests(interviewRequests || {});
        setHasNext(hasNext);
      } catch (e) {
        console.log(e);
      }
    },
    [jobId]
  );

  const onNextClick = () => {
    setPage(page + 1);
    setHasPrev(page + 1 > 0);
    onLoad(page + 1);
  };

  const onPrevClick = () => {
    setPage(page - 1);
    setHasPrev(page - 1 > 0);
    onLoad(page - 1);
  };

  useEffect(() => {
    logEvent("[E] page view job bought");
    onLoad(page);
  }, [page, onLoad]);

  const getStatusInfo = (interviewRequest) => {
    if (!interviewRequest) return null;
    const offerText =
      "Offer Made: " +
      getCurrency(interviewRequest.offeredCTCCurrency).name +
      " " +
      getCurrencyWords(interviewRequest.offeredCTC);

    const offerDetails = interviewRequest.createdByName
      ? offerText + " by " + interviewRequest.createdByName
      : offerText;
    const expireText = "Expiry: " + prettyPrint(interviewRequest.expireAt);
    const job = jobs[interviewRequest.jobId];
    const jobDetails = job ? `Job: ${job.jobTitle.name}` : "";
    switch (interviewRequest.status) {
      case "APPROVED":
        return {
          title: "APPROVED",
          messages: [
            <LabelSmall>{jobDetails}</LabelSmall>,
            offerDetails,
            "Interview request has been approved by the candidate. Please schedule the interview with in a week",
            expireText,
          ],
        };
      case "REJECTED":
      case "ONHOLD":
        return {
          title: "REJECTED",
          messages: [
            <LabelSmall>{jobDetails}</LabelSmall>,
            offerDetails,
            "Interview request has been rejected by the candidate",
            interviewRequest.statusReason ? (
              <LabelSmall>{`Reason: ${interviewRequest.statusReason ||
                ""}`}</LabelSmall>
            ) : (
              ""
            ),
            expireText,
          ],
        };
      case "CREATED":
        return {
          title: "PENDING",
          messages: [
            <LabelSmall>jobDetails</LabelSmall>,
            offerDetails,
            "Interview request is pending for approval from FindingJo",
            expireText,
          ],
        };
      case "REVIEWED":
        return {
          title: "PENDING",
          messages: [
            <LabelSmall>jobDetails</LabelSmall>,
            offerDetails,
            "Interview request is pending for approval from FindingJo",
            expireText,
          ],
        };
      default:
    }
  };
  const getInterviewRequestInfo = (interviewRequest) => {
    return getStatusInfo(interviewRequest);
  };

  return (
    <CenterContainer>
      <Block
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="scale400"
      >
        <Title>Interview History</Title>
      </Block>
      {interviewRequests.length === 0 ? (
        <CenterContainer padding="0px">
          <Block marginTop="70px">No interview requests found.</Block>
        </CenterContainer>
      ) : (
        interviewRequests.map((interviewRequest, index) => {
          return (
            <CandidateCard
              key={index}
              to={
                "/employer/mycandidate/" +
                interviewRequest.id +
                "/details?jobId=" +
                jobId
              }
              onShare={() => {}}
              candidate={candidate}
              isClickable
              status={getInterviewRequestInfo(interviewRequest)}
            />
          );
        })
      )}

      <Pagination
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        hasNext={hasNext}
        hasPrev={hasPrev}
      />
      <LoadingScreen show={isFetching} />
    </CenterContainer>
  );
};

export default withRouter(InterviewHistory);
