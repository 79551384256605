import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { setUser } from "../redux/actions/user";
import { searchCandidate, searchCandidateFacets } from "../api/candidate";
import { addShortlistCandidate, removeShortlistCandidate } from "../api/job";
import DeleteIcon from "baseui/icon/delete";
import FilterSearchMenu from "../components/FilterSearchMenu";
import FilterSliderMenu from "../components/FilterSliderMenu";
import { CenterContainer } from "../components/CenterContainer";
import { RightContainer } from "../components/RightContainer";
import FilterCheckboxList from "../components/FilterCheckboxList";
import Popover from "../components/Popover";
import SortBy from "../components/SortBy";
import Input from "../components/Input";
import { SIZE as INPUT_SIZE } from "baseui/input";
import debounce from "debounce-async";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import LoadingScreen from "../components/LoadingScreen";
import * as Analytics from "../analytics";
import { TALENTSCOUT_UPLOAD_CANDIDATE } from "../util/routes";
import { LabelSmall, ParagraphSmall, ParagraphXSmall } from "baseui/typography";
import { Block } from "baseui/block";
import { Icon } from "../components/Icon";
import { Accordion, Panel } from "../components/Accordion";
import Card from "../components/Card";
import CandidateCard from "../components/employer/CandidateCard";
import { toaster } from "baseui/toast";
import Modal from "../components/Modal";
import { ModalBody, ModalHeader } from "baseui/modal";
import Button from "../components/Button";
import { createPickedCandidate } from "../api/admin";
import { CandidateFunnelModal } from "../lib/views/CandidateFunnelModal";
import {
  Box,
  Checkbox,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { LockIcon } from "@chakra-ui/icons";
import { getLastExperienceTitle } from "src/util/common";

class RCDashboardSearch extends React.Component {
  constructor(props) {
    super(props);
    const { filters, sort } = this.getFiltersInStorage();
    this.state = {
      loading: false,
      error: false,
      isFetched: false,
      candidates: [],
      searchQuery: "",
      page: 0,
      hasNext: false,
      hasPrev: false,
      tsRequests: {},
      interviewRequests: {},
      isFilterModalVisible: false,
      appliedFilters: filters || {},
      sort: sort || { id: "LAST_LOGIN", name: "Last Login" },
      funnelModalProps: {
        jobId: this.props.jobId,
        anonymousId: "",
        title: "",
        isModalOpen: false,
        onModalClose: this.onModalClose,
      },
    };
  }

  onModalClose = () => {
    this.setState({
      funnelModalProps: {
        ...this.state.funnelModalProps,
        isModalOpen: false,
      },
    });
  };

  async componentDidMount() {
    Analytics.logEvent(
      (this.props.isEmployer ? "[E] " : "[S] ") + "page view candidate search"
    );
    this.setState({
      loading: true,
      error: false,
    });
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let appliedFilters = this.state.appliedFilters;
    let page = this.state.page;
    let sort = this.state.sort;
    try {
      appliedFilters =
        JSON.parse(params.get("filter")) || this.state.appliedFilters;
      page = parseInt(params.get("page")) || this.state.page;
      sort = JSON.parse(params.get("sort")) || this.state.sort;
    } catch (e) {}
    this.setState(
      {
        appliedFilters,
        page,
        sort,
      },
      async () => {
        await this.onSearch();
        this.setState({
          isFetched: true,
          loading: false,
        });
      }
    );
  }

  async onSearch(isSilent) {
    if (!isSilent) {
      this.setState({
        loading: true,
      });
    }
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    params.set("filter", JSON.stringify(this.state.appliedFilters));
    params.set("sort", JSON.stringify(this.state.sort));
    params.set("page", this.state.page);
    this.props.history.push({ search: params.toString() });
    try {
      let experience = this.state.appliedFilters.experience;
      let experienceFilter = [];
      if (experience && experience.length === 2) {
        experienceFilter = [experience[0] * 365, experience[1] * 365];
      }
      let salary = this.state.appliedFilters.salary;
      let salaryFilter = [];
      if (salary && salary.length === 2) {
        salaryFilter = [salary[0] * 100000, salary[1] * 100000];
      }
      let noticePeriod = this.state.appliedFilters.noticePeriod;
      let noticePeriodFilter = [];
      if (noticePeriod && noticePeriod.length) {
        if (noticePeriod[0] === 30) {
          noticePeriodFilter = [0, 30];
        } else if (noticePeriod[0] === 60) {
          noticePeriodFilter = [0, 60];
        } else {
          noticePeriodFilter = [0, 120];
        }
      }
      let alreadyResigned = this.state.appliedFilters.alreadyResigned;
      let {
        result: { Hits = [], nbPages, page },
        tsRequests,
        interviewRequests,
        shortlisted,
        funnels,
        handPickedCandidates,
      } = await searchCandidate({
        query: this.state.searchQuery,
        page: this.state.page,
        pool: this.props.isEmployer || this.props.isAdmin ? "GREEN" : "ORANGE",
        filter: {
          ...this.state.appliedFilters,
          experience: experienceFilter,
          noticePeriod: noticePeriodFilter,
          salary: salaryFilter,
          alreadyResigned: !!alreadyResigned,
          targetCompany: (this.state.appliedFilters.targetCompany || [])[0],
        },
        jobId: this.props.jobId,
        sort: this.state.sort.id,
      });
      this.setState({
        loading: false,
        candidates: Hits,
        page: page,
        hasNext: nbPages - 1 > page,
        hasPrev: page !== 0,
        tsRequests: tsRequests || {},
        funnels: funnels,
        interviewRequests: interviewRequests || {},
        shortlisted: shortlisted || {},
        handPickedCandidates: handPickedCandidates || {},
      });
      if (
        this.state.searchQuery &&
        this.state.searchQuery.trim().length &&
        Hits &&
        !Hits.length
      ) {
        Analytics.logEvent(this.getRolePrefix() + "Empty Candidate Search", {
          query: this.state.searchQuery,
        });
      }
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
    }
  }

  getRolePrefix() {
    if (this.props.isEmployer) return "[E] ";
    if (this.props.isAdmin) return "[A] ";
    return "[S] ";
  }

  getCandidateDetailsLink(candidate) {
    if (this.props.isEmployer) {
      if (!this.props.user.email) {
        return `/public/candidate/${candidate.userId}`;
      }
      const jobParams = this.props.job ? `?jobId=${this.props.job.id}` : "";
      return `/employer/candidate/${candidate.userId}/details${jobParams}`;
    }
    if (this.props.isAdmin) {
      return `/public/candidate/${candidate.userId}`;
    }
    return `/talentscout/candidate/${candidate.userId}/details`;
  }

  getActions(candidate) {
    if (this.props.isEmployer) {
      const baseInterviewLink =
        "/employer/create-interview/" + candidate.userId;
      const interviewLink = this.props.jobId
        ? baseInterviewLink + "?jobId=" + this.props.jobId
        : baseInterviewLink;
      const isShortlisted = this.state.shortlisted[candidate.userId];
      const isInterviewed = this.state.interviewRequests[candidate.userId];
      return {
        onShare: () => {},
        interviewLink,
        onInterview: () => {},
        onShortlist:
          this.props.user.email &&
          this.props.jobId &&
          (async (candidate) => {
            try {
              let { jobId } = this.props;
              await addShortlistCandidate(jobId, {
                jobId,
                anonymousId: candidate.userId,
              });
              toaster.positive(<Block>Shortlisted successfully</Block>, {
                autoHideDuration: 3000,
              });
              await this.onSearch(true);
            } catch (e) {}
          }),
        showRCAmount: true,
        isShortlisted,
        isInterviewed,
        isSelected: isShortlisted || isInterviewed,
        onRemoveShortlist: async (candidate) => {
          try {
            let shortlistCandidate = this.state.shortlisted[candidate.userId];
            if (shortlistCandidate.id) {
              await removeShortlistCandidate(
                this.props.jobId,
                candidate.userId
              );
              toaster.positive(<Block>Shortlist removed successfully</Block>, {
                autoHideDuration: 3000,
              });
              await this.onSearch(true);
            }
          } catch (e) {}
        },
      };
    }
    if (this.props.isAdmin)
      return {
        onShare: () => {},
        onScout:
          !this.state.handPickedCandidates[candidate.userId] &&
          (async (candidate) => {
            try {
              await createPickedCandidate({
                jobId: this.props.jobId,
                candidateId: candidate.userId,
              });
              toaster.show(<div>Successfully completed</div>, {
                autoHideDuration: 3000,
              });
              await this.onSearch();
            } catch (e) {}
          }),
        isDisabled: !!this.state.handPickedCandidates[candidate.userId],
      };
    return {
      onShare: () => {},
      onScout: () => {},
    };
  }

  onChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  handleMenuClick(key) {
    console.log("Clicked on", key);
  }

  displayFilter(filterName) {
    const filterValues = this.state.appliedFilters[filterName];
    if (["experience", "salary"].includes(filterName)) {
      return (
        <Tag borderRadius="md" colorScheme={"blue"} mr={2} mb={2}>
          <TagLabel>
            {this.displayFilterText(
              filterName,
              `${filterValues[0]}-${filterValues[1]}`
            )}
          </TagLabel>
          <TagCloseButton
            onClick={() => {
              this.onRemoveFilter(filterName, null);
            }}
          />
        </Tag>
      );
    } else if (Array.isArray(filterValues)) {
      return filterValues.map((val, idx) => {
        return (
          <Tag borderRadius="md" colorScheme={"blue"} mr={2} mb={2}>
            <TagLabel>{this.displayFilterText(filterName, val)}</TagLabel>
            <TagCloseButton
              onClick={() => {
                this.onRemoveFilter(filterName, [
                  ...filterValues.slice(0, idx),
                  ...filterValues.slice(idx + 1),
                ]);
              }}
            />
          </Tag>
        );
      });
    } else {
      return (
        <Tag borderRadius="md" colorScheme={"blue"} mr={2} mb={2}>
          <TagLabel>
            {this.displayFilterText(filterName, filterValues)}
          </TagLabel>
          <TagCloseButton
            onClick={() => {
              this.onRemoveFilter(filterName, null);
            }}
          />
        </Tag>
      );
    }
    //   case "salary":
    //     return (
    //       "Salary : " +
    //       this.state.appliedFilters[filterName][0] +
    //       " - " +
    //       this.state.appliedFilters[filterName][1] +
    //       " lakhs"
    //     );
    // }
  }

  displayFilterText(filterName, filterValue) {
    switch (filterName) {
      case "noticePeriod":
        return "Notice period: " + filterValue + " days";
      case "alreadyResigned":
        return "Already Resigned";
      case "experience":
        return "Experience: " + filterValue + " years";
      case "jobLocations":
        return "Location: " + filterValue;
      case "college":
        return "College: " + filterValue;
      case "company":
        return "Company: " + filterValue;
      case "startupTypes":
        return "Startup Ready: " + filterValue;
      case "remoteReady":
        return "Remote Options: " + filterValue;
      case "salary":
        return "Salary : " + filterValue + " lakhs";
      case "skills":
        return "Skill: " + filterValue;
      case "jobFunctions":
        return "Job Function: " + filterValue;
      case "targetCompany":
        return filterValue === 1
          ? "Target Company: Current"
          : "Target Company: Current or past";
      case "isRemoteReady":
        return "Ready for Remote";
      case "isStartupReady":
        return "Startup Ready";
      case "isInNoticePeriod":
        return "In Notice Period";
      case "isImmediateJoinee":
        return "In Immediate Joinee";
      default:
        return filterName;
    }
  }

  onFilterChange = async (name, val) => {
    if (Array.isArray(val) && !val.length) {
      this.onRemoveFilter(name, "");
      return;
    }
    Analytics.logEvent(
      this.getRolePrefix() + "click change filter candidate search ",
      {
        name: name,
        value: val,
      }
    );
    this.setState(
      {
        appliedFilters: {
          ...this.state.appliedFilters,
          ...{
            [name]: val,
          },
        },
      },
      async () => {
        await this.onSearch();
        this.setFiltersInStorage();
      }
    );
  };

  onRemoveFilter = async (name, val) => {
    Analytics.logEvent(
      this.getRolePrefix() + "click remove filter candidate search ",
      {
        name: name,
        value: val,
      }
    );
    if (!val) {
      delete this.state.appliedFilters[name];
      this.setState(
        {
          appliedFilters: {
            ...this.state.appliedFilters,
          },
        },
        async () => {
          await this.onSearch();
          this.setFiltersInStorage();
        }
      );
    } else {
      this.setState(
        {
          appliedFilters: {
            ...this.state.appliedFilters,
            ...{
              [name]: val,
            },
          },
        },
        async () => {
          await this.onSearch();
          this.setFiltersInStorage();
        }
      );
    }
  };

  setFiltersInStorage = () => {
    localStorage.setItem(
      this.props?.user?.id + "-employer-filters",
      JSON.stringify(this.state.appliedFilters)
    );
    localStorage.setItem(
      this.props?.user?.id + "-employer-sort",
      JSON.stringify(this.state.sort)
    );
  };

  getFiltersInStorage = () => {
    const savedFilters = localStorage.getItem(
      this.props?.user?.id + "-employer-filters"
    );
    const savedSort = localStorage.getItem(
      this.props?.user?.id + "-employer-sort"
    );
    return {
      filters: savedFilters ? JSON.parse(savedFilters) : undefined,
      sort: savedSort ? JSON.parse(savedSort) : undefined,
    };
  };

  onNextClick = () => {
    if (this.props.isPublicPage) {
      this.props.history.push("/login");
      return;
    }
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onSearch();
      }
    );
  };

  onPrevClick = () => {
    if (this.props.isPublicPage) {
      this.props.history.push("/login");
      return;
    }
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onSearch();
      }
    );
  };

  onSearchQueryChange = async (e) => {
    this.setState({
      searchQuery: e.target.value,
    });
    await this.debounceSearch();
  };

  debounceSearch = debounce(async () => {
    await this.onSearch();
  }, 1000);

  render() {
    const FilterContainer = (
      <Card margin="scale400">
        <Box ml={4}>
          <Checkbox
            isChecked={this.state.appliedFilters["isRemoteReady"] === 1}
            onChange={(e) => {
              if (e.target.checked) {
                this.onFilterChange("isRemoteReady", 1);
              } else {
                this.onRemoveFilter("isRemoteReady", null);
              }
            }}
          >
            <Text fontSize="sm">Remote Ready</Text>
          </Checkbox>
          <Checkbox
            isChecked={this.state.appliedFilters["isStartupReady"] === 1}
            onChange={(e) => {
              if (e.target.checked) {
                this.onFilterChange("isStartupReady", 1);
              } else {
                this.onRemoveFilter("isStartupReady", null);
              }
            }}
          >
            <Text fontSize="sm">Startup Ready</Text>
          </Checkbox>
          <Checkbox
            isChecked={this.state.appliedFilters["isInNoticePeriod"] === 1}
            onChange={(e) => {
              if (e.target.checked) {
                this.onFilterChange("isInNoticePeriod", 1);
              } else {
                this.onRemoveFilter("isInNoticePeriod", null);
              }
            }}
          >
            <Text fontSize="sm">In Notice Period</Text>
          </Checkbox>
          <Checkbox
            isChecked={this.state.appliedFilters["isImmediateJoinee"] === 1}
            onChange={(e) => {
              if (e.target.checked) {
                this.onFilterChange("isImmediateJoinee", 1);
              } else {
                this.onRemoveFilter("isImmediateJoinee", null);
              }
            }}
          >
            <Text fontSize="sm">Immediate Joinee</Text>
          </Checkbox>
        </Box>
        <Accordion>
          {this.props.job && (this.props.isEmployer || this.props.isAdmin) ? (
            <Panel title={<ParagraphSmall>Target Companies</ParagraphSmall>}>
              {this.props.job.targetCompanies &&
              this.props.job.targetCompanies.length > 0 ? (
                <Block>
                  <ParagraphXSmall marginBottom="scale400">
                    <ParagraphXSmall as="span">
                      {this.props.job.targetCompanies[0].name}
                    </ParagraphXSmall>

                    <Popover
                      content={
                        <Block padding="scale400">
                          {this.props.job.targetCompanies.map((company) => (
                            <ParagraphSmall marginBottom="scale200">
                              {company.name}
                            </ParagraphSmall>
                          ))}
                          <Button
                            kind={"tertiary"}
                            size={"mini"}
                            startEnhancer={<Icon name="edit" size={16} />}
                            onClick={() =>
                              this.props.history.push(
                                "/employer/postedjob/" +
                                  this.props.job.id +
                                  "/details"
                              )
                            }
                          >
                            Edit target company list
                          </Button>
                        </Block>
                      }
                    >
                      <Block>
                        <ParagraphXSmall
                          as="span"
                          color="accent"
                          marginLeft="scale200"
                        >
                          {this.props.job.targetCompanies.length - 1 + " more"}
                        </ParagraphXSmall>
                      </Block>
                    </Popover>
                  </ParagraphXSmall>
                  <FilterCheckboxList
                    single
                    options={[
                      { id: 1, name: "Current Company" },
                      { id: 2, name: "Current or past company" },
                    ]}
                    name={"targetCompany"}
                    value={this.state.appliedFilters["targetCompany"]}
                    onChange={this.onFilterChange}
                  />
                </Block>
              ) : (
                <Block>
                  <Button
                    kind={"tertiary"}
                    size={"mini"}
                    startEnhancer={<Icon name="edit" size={16} />}
                    onClick={() =>
                      this.props.history.push(
                        "/employer/postedjob/" + this.props.job.id + "/details"
                      )
                    }
                  >
                    Add target companies
                  </Button>
                </Block>
              )}
            </Panel>
          ) : null}

          <Panel title={<ParagraphSmall>Job Functions</ParagraphSmall>}>
            <Block maxHeight="200px" overflow="scroll">
              <FilterCheckboxList
                isFetchOptions
                name={"jobFunctions"}
                value={this.state.appliedFilters["jobFunctions"] || []}
                onChange={this.onFilterChange}
              />
            </Block>
          </Panel>
          <Panel title={<ParagraphSmall>Experience</ParagraphSmall>}>
            <React.Fragment>
              <LabelSmall $marginBottom={"8px"}>{"Experience"}</LabelSmall>
              <FilterSliderMenu
                name={"experience"}
                appliedFilters={this.state.appliedFilters["experience"] || []}
                onChange={(name, val) => {
                  this.onFilterChange(name, val);
                }}
              />
              <div style={{ color: "#919191", textAlign: "center" }}>
                in years
              </div>
            </React.Fragment>
          </Panel>
          <Panel title={<ParagraphSmall>Locations</ParagraphSmall>}>
            <FilterSearchMenu
              name={"jobLocations"}
              placeholder={"Search Location"}
              appliedFilters={this.state.appliedFilters["jobLocations"] || []}
              fetchPopularFilters={async () => {
                let { FacetHits = [] } = await searchCandidateFacets(
                  "jobLocations.name",
                  "b"
                );
                return FacetHits.map((facet) => ({
                  id: facet.value,
                  name: facet.value,
                }));
              }}
              onSearch={async (q) => {
                let { FacetHits = [] } = await searchCandidateFacets(
                  "jobLocations.name",
                  q || ""
                );
                return FacetHits.map((facet) => ({
                  id: facet.value,
                  name: facet.value,
                }));
              }}
              onChange={this.onFilterChange}
            />
          </Panel>
          {/* <Panel title={<ParagraphSmall>Company</ParagraphSmall>}>
            <FilterCheckboxList
              isFetchOptions
              name={"company"}
              value={this.state.appliedFilters["company"] || []}
              onChange={this.onFilterChange}
            />
          </Panel>
          <Panel title={<ParagraphSmall>College</ParagraphSmall>}>
            <FilterCheckboxList
              isFetchOptions
              name={"college"}
              value={this.state.appliedFilters["college"] || []}
              onChange={this.onFilterChange}
            />
          </Panel> */}
          <Flex
            justifyContent={"space-between"}
            alignItems="center"
            p={2}
            px={5}
          >
            <Text fontSize={"sm"}>Company</Text>
            <Tooltip
              hasArrow
              label="To enable filter by company name(Google, Amazon etc..) Contact sales@findingjo.com"
              bg="gray.300"
              color="black"
              cursor="pointer"
            >
              <LockIcon />
            </Tooltip>
          </Flex>
          <Flex
            justifyContent={"space-between"}
            alignItems="center"
            p={2}
            px={5}
          >
            <Text fontSize={"sm"}>College</Text>
            <Tooltip
              hasArrow
              label="To enable filter by college name(IITM, IITD etc..) Contact sales@findingjo.com"
              bg="gray.300"
              color="black"
              cursor="pointer"
            >
              <LockIcon />
            </Tooltip>
          </Flex>
          {/* <Panel title={<ParagraphSmall>Startup Ready</ParagraphSmall>}>
            <FilterCheckboxList
              options={[
                {
                  id: "EARLY_STAGE",
                  name: "Non funded early stage startups",
                },
                {
                  id: "FUNDED_STARTUPS",
                  name: "Well funded startups",
                },
              ]}
              name={"startupTypes"}
              value={this.state.appliedFilters["startupTypes"] || []}
              onChange={this.onFilterChange}
            />
          </Panel> */}

          {/* <Panel title={<ParagraphSmall>Remote Ready</ParagraphSmall>}>
            <FilterCheckboxList
              options={[
                {
                  id: "remoteReady",
                  name: "Ready to work Remote",
                },
              ]}
              name={"remoteReady"}
              value={this.state.appliedFilters["remoteReady"] || []}
              onChange={this.onFilterChange}
            />
          </Panel> */}

          <Panel title={<ParagraphSmall>Salary</ParagraphSmall>}>
            <Block paddingTop="scale1200">
              <FilterSliderMenu
                name={"salary"}
                max={100}
                appliedFilters={this.state.appliedFilters["salary"] || []}
                onChange={this.onFilterChange}
                formatter={(value) => `${value} lakhs`}
              />
              <div style={{ color: "#919191", textAlign: "center" }}>
                in lakhs
              </div>
            </Block>
          </Panel>

          <Panel title={<ParagraphSmall>Skills</ParagraphSmall>}>
            <FilterSearchMenu
              name={"skills"}
              placeholder={"Search Skills"}
              appliedFilters={this.state.appliedFilters["skills"] || []}
              fetchPopularFilters={async () => {
                let { FacetHits = [] } = await searchCandidateFacets(
                  "skills.name",
                  "jav"
                );
                return FacetHits.map((facet) => ({
                  id: facet.value,
                  name: facet.value,
                }));
              }}
              onSearch={async (q) => {
                let { FacetHits = [] } = await searchCandidateFacets(
                  "skills.name",
                  q || ""
                );
                return FacetHits.map((facet) => ({
                  id: facet.value,
                  name: facet.value,
                }));
              }}
              onChange={this.onFilterChange}
            />
          </Panel>

          <Panel title={<ParagraphSmall>Notice Period</ParagraphSmall>}>
            <>
              <FilterCheckboxList
                single
                options={[
                  { id: 30, name: "30 days" },
                  { id: 60, name: "60 days" },
                  { id: 120, name: "120 days" },
                ]}
                name={"noticePeriod"}
                value={this.state.appliedFilters["noticePeriod"]}
                onChange={(name, val) => {
                  this.onFilterChange(name, val);
                }}
              />
              <FilterCheckboxList
                single
                options={[{ id: "yes", name: "Now serving notice period" }]}
                name={"alreadyResigned"}
                value={this.state.appliedFilters["alreadyResigned"]}
                onChange={(name, val) => {
                  this.onFilterChange(name, val);
                }}
              />
            </>
          </Panel>
        </Accordion>
      </Card>
    );

    const FilterButton = (
      <Block width={["auto", "auto", "0px"]} overflow="hidden">
        <Button
          kind="tertiary"
          onClick={() => {
            this.setState({
              isFilterModalVisible: true,
            });
          }}
          startEnhancer={<Icon name="filter" size={14} />}
        >
          Filter
        </Button>
      </Block>
    );

    if (this.state.error) {
      return <div>Error occured. Please reload the page</div>;
    }
    return (
      <Block display="flex" flex={1}>
        <CenterContainer>
          {this.props.isEmployer || this.props.isAdmin ? (
            <Block
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Title marginBottom="scale300">Source Candidates</Title>
              {FilterButton}
            </Block>
          ) : (
            <div>
              <Block
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Title>Scout Candidate</Title>
                {FilterButton}
              </Block>
              <ParagraphXSmall marginBottom="scale400">
                <ParagraphXSmall as="span">
                  Scout a candidate from FindingJo database
                </ParagraphXSmall>
                <Popover
                  content={
                    <div>
                      <div
                        style={{
                          fontWeight: "400",
                          fontSize: "12px",
                          textTransform: "none",
                        }}
                      >
                        <div>
                          You have to pay a small amount to scout already
                          shortlisted premium candidates from our database.
                        </div>
                        <Link to={TALENTSCOUT_UPLOAD_CANDIDATE}>
                          {"Upload your own candidate "}
                        </Link>
                        to avoid this fee.
                      </div>
                    </div>
                  }
                >
                  <ParagraphXSmall
                    as="span"
                    color="accent"
                    marginLeft="scale200"
                  >
                    Why Pay?
                  </ParagraphXSmall>
                </Popover>
              </ParagraphXSmall>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "8px",
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                flexWrap: "wrap",
                maxWidth: "420px",
                marginRight: "16px",
                position: "relative",
              }}
            >
              <Input
                size={INPUT_SIZE.compact}
                placeholder={"Search here"}
                onChange={this.onSearchQueryChange}
                value={this.state.searchQuery}
              />
              <Block $position="absolute" $right="scale600" $top="scale400">
                <Icon size={16} name="search" />
              </Block>
            </div>
            <SortBy
              options={[
                { id: "LAST_LOGIN", name: "Last Login" },
                { id: "EXPERIENCE_DESC", name: "Experience high low" },
                { id: "EXPERIENCE_ASC", name: "Experience low high" },
                { id: "NOTICE_PERIOD", name: "Notice period" },
                { id: "CTC_DESC", name: "Salary high low" },
                { id: "CTC_ASC", name: "Salary low high" },
              ]}
              value={this.state.sort}
              onChange={(value) =>
                this.setState(
                  {
                    sort: value,
                  },
                  async () => {
                    await this.onSearch();
                  }
                )
              }
            />
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", flex: 1, flexWrap: "wrap" }}>
              {Object.keys(this.state.appliedFilters).map((filter) =>
                this.displayFilter(filter)
              )}
            </div>
          </div>

          <div>
            <div style={{ minHeight: "240px" }}>
              {this.state.candidates.map((candidate, index) => (
                <CandidateCard
                  key={index}
                  to={this.getCandidateDetailsLink(candidate)}
                  candidate={candidate}
                  funnels={
                    this.state.funnels && this.state.funnels[candidate.userId]
                  }
                  isClickable
                  isOpenNewWindow
                  onCardPress={() => {
                    if (this.props.jobId) {
                      this.setState({
                        funnelModalProps: {
                          jobId: this.props.jobId,
                          anonymousId: candidate.userId,
                          title:
                            getLastExperienceTitle(candidate.experience) ||
                            candidate.lastExperienceTitle ||
                            "Fresher",
                          isModalOpen: true,
                          onModalClose: this.onModalClose,
                        },
                      });
                    } else {
                      this.props.history.push(
                        "/public/candidate/" + candidate.userId
                      );
                    }
                  }}
                  {...this.getActions(candidate)}
                />
              ))}
              {!this.state.candidates.length &&
                !this.state.searchQuery &&
                !this.state.loading && (
                  <div
                    style={{
                      height: "250px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>No Candidate Found</div>
                  </div>
                )}
              {!this.state.candidates.length &&
                this.state.searchQuery &&
                !this.state.loading && (
                  <div
                    style={{
                      height: "250px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div>
                      <div style={{ marginBottom: "16px" }}>
                        Your search -"%keyword%" - did not match any candidate
                        profile
                      </div>
                      <div>Suggestions:</div>
                      <div>Make sure that all words are spelled correctly.</div>
                      <div>Try different keywords.</div>
                      <div>Try more general keywords.</div>
                    </div>
                  </div>
                )}
            </div>
            <Pagination
              onPrevClick={this.onPrevClick}
              onNextClick={this.onNextClick}
              hasNext={this.state.hasNext}
              hasPrev={this.state.hasPrev}
            />
          </div>
          <LoadingScreen show={this.state.loading} />
        </CenterContainer>
        <RightContainer marginTop="48px">{FilterContainer}</RightContainer>
        <Modal
          isOpen={this.state.isFilterModalVisible}
          onClose={() =>
            this.setState({
              isFilterModalVisible: false,
            })
          }
        >
          <ModalHeader>Filter Candidates</ModalHeader>
          <ModalBody>{FilterContainer}</ModalBody>
        </Modal>
        <CandidateFunnelModal {...this.state.funnelModalProps} />
      </Block>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(RCDashboardSearch));
