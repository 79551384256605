import { Box, useToast } from "@chakra-ui/react";
import React from "react";
import { ReferredCandidateProps } from "src/api/referred_candidate";
import {
  CandidateView,
  ResumePreviews,
} from "src/screens/tools/CandidateDataVerification";

export type DataVerificationProps = {
  candidate: ReferredCandidateProps;
  onUpdated: (values: ReferredCandidateProps) => Promise<void>;
};

export const DataVerificationTab = ({
  candidate,
  onUpdated,
}: DataVerificationProps) => {
  const { status } = candidate || {};
  return (
    <Box pb={10}>
      <Box>
        <Box
          borderRightWidth={1}
          marginRight={2}
          pr={4}
          width="45%"
          display={"inline-block"}
          verticalAlign={"top"}
        >
          <CandidateView
            id={candidate.id || ""}
            resume={candidate}
            onUpdate={onUpdated}
            status={status}
          />
        </Box>
        <Box p={4} width="45%" display={"inline-block"} verticalAlign={"top"}>
          <Box
            position="fixed"
            top={"110px"}
            bottom={0}
            overflow={"scroll"}
            mb="80px"
          >
            <ResumePreviews
              linkedInResumeUrl={candidate?.linkedInResumeUrl}
              resumeUrl={candidate?.resumeUrl}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
