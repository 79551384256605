import React, { useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Spacer,
  Text,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Currency from "src/components/Currency";
import { validateEmail } from "src/util/validator";
import { sendPaymentLink } from "src/api/user";
import { useSelector } from "react-redux";

export type EmployerSendPaymentLinkProps = {};
export const EmployerSendPaymentLink = ({}: EmployerSendPaymentLinkProps) => {
  const router = useHistory();
  const [amount, setAmount] = useState("0");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const user = useSelector((state: any) => state.user);
  const workspace = useSelector((state: any) => state.workspace);
  const [isSending, setIsSending] = useState(false);
  const toast = useToast();
  type FormErrors = {
    firstName?: string;
    lastName?: string;
    email?: string;
    amount?: string;
  };
  const [errors, setErrors] = useState<FormErrors>({});

  const onSubmit = async () => {
    const errors: FormErrors = {};
    if (!firstName) {
      errors.firstName = "first name cannot be empty";
    }
    if (!lastName) {
      errors.lastName = "last name cannot be empty";
    }
    if (!email) {
      errors.email = "email cannot be empty";
    } else if (!validateEmail(email)) {
      errors.email = "email is not valid";
    }

    if (isNaN(+amount)) {
      errors.amount = "amount is not valid";
    }
    setErrors(errors);
    if (Object.keys(errors).length) {
      return;
    }

    setIsSending(true);
    try {
      await sendPaymentLink(user.id, {
        firstName,
        lastName,
        emailToSend: email,
        currency: "INR",
        amount: +amount,
        workspaceId: workspace.id,
      });
      toast({
        title: "Payment link request sent successfully.",
      });
      setFirstName("");
      setLastName("");
      setEmail("");
      setAmount("0");
    } catch (e) {
      toast({
        title: "Error while creating payment link. Please try again",
      });
    }
    setIsSending(false);
  };

  return (
    <Box py={8}>
      <Box p={8} bg="white" borderRadius={"md"} shadow="lg">
        <Heading fontWeight="semibold" fontSize="2xl">
          Send payment link
        </Heading>
        <Text color="gray.500">With finance team</Text>
        <Spacer height={6} />
        <FormControl>
          <FormLabel>Name of the finance team member</FormLabel>
          <Flex>
            <Input
              width="200px"
              mr={"16px"}
              placeholder="first name"
              value={firstName}
              onChange={(e: any) => setFirstName(e.target.value)}
            ></Input>
            <Input
              width="200px"
              placeholder="last name"
              value={lastName}
              onChange={(e: any) => setLastName(e.target.value)}
            ></Input>
          </Flex>
          {errors["firstName"] ? (
            <Text color="red.500">{errors["firstName"]}</Text>
          ) : null}
          {errors["lastName"] ? (
            <Text color="red.500">{errors["lastName"]}</Text>
          ) : null}
        </FormControl>
        <Spacer height={6} />
        <FormControl>
          <FormLabel>Email id of the finance team member</FormLabel>
          <Flex>
            <Input
              width="416px"
              placeholder="email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            ></Input>
          </Flex>
          {errors["email"] ? (
            <Text color="red.500">{errors["email"]}</Text>
          ) : null}
        </FormControl>
        <Spacer height={6} />
        <FormControl>
          <FormLabel>Enter amount</FormLabel>
          <InputGroup>
            <InputLeftAddon>₹</InputLeftAddon>
            <Input
              width="376px"
              placeholder="amount"
              value={amount + ""}
              onChange={(e: any) => setAmount(e.target.value)}
            ></Input>
          </InputGroup>
          <Currency inWords currency={"INR"} value={amount}></Currency>
          {errors["amount"] ? (
            <Text color="red.500">{errors["amount"]}</Text>
          ) : null}
        </FormControl>
        <Spacer height={8} />
        <Box>
          <Button
            colorScheme="orange"
            onClick={() => onSubmit()}
            isLoading={isSending}
          >
            Email payment link
          </Button>
          <Button
            variant="ghost"
            ml={4}
            onClick={() => {
              router.goBack();
            }}
          >
            Go back
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
