import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import { getUserDetails, reviewTalentscout, updateTSSlots } from "../api/admin";
import Button from "../components/Button";
import Entry from "../components/Entry";
import * as Analytics from "../analytics";
import LoadingScreen from "../components/LoadingScreen";
import FormControl from "../components/FormControl";
import Label from "../components/Label";
import EmptyCaption from "../components/EmptyCaption";
import TextArea from "../components/TextArea";
import FlexRow from "../components/FlexRow";

class AdminTalentscoutReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isLoading: false,
      talentscout: { taxData: {} },
      user: { idProof: {} },
      errors: {},
    };
  }

  async onLoad() {
    try {
      this.setState({
        isFetching: true,
      });
      let { talentscout, user } = await getUserDetails(
        this.props.talentscoutId
      );
      this.setState({ talentscout, user, isFetching: false });
    } catch (e) {
      this.setState({
        isFetching: false,
      });
      console.log(e);
    }
  }

  async componentDidMount() {
    await this.onLoad();
  }

  updateTalentscoutStatus = async (status) => {
    try {
      await reviewTalentscout({
        id: this.props.talentscoutId,
        status,
        statusReason: this.state.statusReason || "",
      });
      if (status === "REVIEWED") {
        Analytics.logEvent("[A] approve talentscout", {
          talentscoutId: this.props.talentscoutId,
        });
      } else if (status === "SUSPENDED") {
        Analytics.logEvent("[A] suspend talentscout", {
          talentscoutId: this.props.talentscoutId,
        });
      }
      this.props.history.goBack();
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    if (this.state.isFetching) {
      return <LoadingScreen />;
    }

    return (
      <div>
        <Title>Talentscout Detail</Title>
        {this.state.talentscout.status !== "REVIEWED" && (
          <div style={{ display: "flex", marginBottom: "32px" }}>
            <div style={{ paddingRight: "32px" }}>
              <Button
                onClick={() => {
                  this.updateTalentscoutStatus("REVIEWED");
                }}
              >
                Approve
              </Button>
            </div>
            <Button
              onClick={() => {
                this.updateTalentscoutStatus("SUSPENDED");
              }}
            >
              Reject
            </Button>
          </div>
        )}
        <FormControl
          label={<Label>{"Any comments"}</Label>}
          caption={<EmptyCaption />}
          error={this.state.statusReasonError}
        >
          <TextArea
            placeholder={""}
            onChange={(e) => {
              if (typeof e.target.value !== "undefined") {
                this.setState({
                  statusReason: e.target.value,
                });
              }
            }}
            value={this.state.statusReason}
            autoComplete={"off"}
          />
        </FormControl>
        <div style={{ maxWidth: 420 }}>
          <Entry
            label={"Scout slots"}
            value={this.state.talentscout.scoutSlots}
          ></Entry>
          <Entry
            label={"Interview Share"}
            value={this.state.talentscout.interviewShare}
          ></Entry>

          <Entry
            label={"GST Enabled"}
            value={
              this.state.talentscout.taxData &&
              this.state.talentscout.taxData.hasGST
                ? "true"
                : "false"
            }
          ></Entry>
          <Entry
            label={"GST Number"}
            value={
              this.state.talentscout.taxData &&
              this.state.talentscout.taxData.gstNumber
            }
          ></Entry>
          <Entry
            label={"Inside Karnataka"}
            value={
              this.state.talentscout.taxData &&
              this.state.talentscout.taxData.insideState
                ? "true"
                : "false"
            }
          ></Entry>
          <Entry
            label={"Billing Name"}
            value={
              this.state.talentscout.taxData &&
              this.state.talentscout.taxData.billingName
            }
          ></Entry>
          <Entry
            label={"Billing Address"}
            value={
              this.state.talentscout.taxData &&
              this.state.talentscout.taxData.billingAddress
            }
          ></Entry>
          <Entry
            label={"LinkedIn"}
            value={this.state.user.linkedInProfile}
          ></Entry>
          <Entry
            label={"Id Proof"}
            value={this.state.user.idProof && this.state.user.idProof.name}
          ></Entry>
          <Entry
            label={"Id Proof Id"}
            value={this.state.user.idProof && this.state.user.idProof.id}
          ></Entry>
          <img
            alt={"idproof"}
            src={this.state.user.idProof && this.state.user.idProof.photoUrl}
            height={"400"}
          ></img>
          <Entry
            label={"Id Proof Verification"}
            value={
              this.state.user.idProof && this.state.user.idProof.isVerified
                ? "true"
                : "false"
            }
          ></Entry>
        </div>
        {this.state.talentscout.linkedInShareStatus === "PENDING" && (
          <div style={{ display: "flex", marginBottom: "32px" }}>
            <FlexRow>
              <div style={{ marginRight: "16px" }}>Shared on LinkedIn</div>
              <div style={{ marginRight: "16px" }}>
                {"New Slot Count " + (this.state.talentscout.scoutSlots + 5)}
              </div>
              <div style={{ paddingRight: "32px" }}>
                <Button
                  onClick={async () => {
                    try {
                      await updateTSSlots({
                        id: this.state.talentscout.userId,
                        status: "CONFIRMED",
                        slotCount: this.state.talentscout.scoutSlots + 5,
                      });
                      await this.onLoad();
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                >
                  Verified
                </Button>
              </div>
              <Button
                onClick={async () => {
                  try {
                    await updateTSSlots({
                      id: this.state.talentscout.userId,
                      status: "",
                    });
                    await this.onLoad();
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                Not Verified
              </Button>
            </FlexRow>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminTalentscoutReview));
