import React, { useState } from "react";
import { LinkedinShareButton } from "react-share";
import { SIZE, KIND } from "baseui/button";
import { styled, withStyle } from "baseui";
import Card from "./Card";
import FlexRow from "./FlexRow";
import Bold from "./Bold";
import Spacing from "./Spacing";
import { updateTalentscoutLinkedInShareStatus } from "../api/talentscout";
import Button from "./Button";
import Modal from "./Modal";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import Title from "./Title";
import { TALENTSCOUT_PROFILE_DETAILS } from "../util/routes";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Icon } from "./Icon";

const TextSection = styled("div", {
  marginBottom: "8px",
});

const SubTextSection = styled("div", {
  fontSize: "13px",
});

const ImageWrapper = styled("div", {
  marginLeft: "16px",
  marginRight: "32px",
  alignSelf: "flex-start",
  marginTop: "32px",
});

const ButtonText = styled("div", {
  marginLeft: "16px",
});

const NextText = styled("div", {
  marginRight: "16px",
  color: "#919191",
  fontSize: "12px",
});

const SpaceBetween = withStyle(FlexRow, {
  justifyContent: "space-between",
  flexWrap: "wrap",
});

const TSLinkedInShare = ({
  linkedInShareStatus: status,
  onLinkedInShare,
  linkedInShareImage,
  linkedInShareUrl,
  history,
  user,
}) => {
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isShareDone, setIsShareDone] = useState(false);
  return (
    <Card>
      <FlexRow>
        <ImageWrapper>
          <Icon name="gift" size={50} />
        </ImageWrapper>
        <div>
          <TextSection>
            <Bold>Share and get your Welcome Gift</Bold>
            <SubTextSection>
              <Spacing size={4} />
              <div>Power up to upload 10 resumes at a time.</div>
              <div style={{ color: "#919191", fontSize: "12px" }}>
                (Now you can upload 5 resumes at a time)
              </div>
              <Spacing size={4} />
              <div style={{ color: "#919191", fontSize: "12px" }}>
                When you upload more resumes, you can setup more interviews.
              </div>
              <div style={{ color: "#919191", fontSize: "12px" }}>
                Your potential to earn money on FindingJo increases with every
                resume you upload.
              </div>
            </SubTextSection>
          </TextSection>
          <SpaceBetween>
            <FlexRow>
              <NextText>Next</NextText>
              <Button
                kind={KIND.secondary}
                onClick={() => setIsShareModalVisible(true)}
                size={SIZE.mini}
              >
                View Shareable Message
              </Button>
            </FlexRow>
            {status === "PENDING" ? (
              <div style={{ color: "#00a676" }}>Pending Verification</div>
            ) : null}
          </SpaceBetween>
        </div>
      </FlexRow>
      <Modal
        animate
        autoFocus
        isOpen={isShareModalVisible}
        closeable
        onClose={() => {
          setIsShareModalVisible(false);
          setIsShareDone(false);
        }}
      >
        <ModalHeader>
          <Title>Share on LinkedIn</Title>
        </ModalHeader>
        <ModalBody>
          {!isShareDone ? (
            <img alt={""} src={linkedInShareImage} width={"100%"}></img>
          ) : (
            <div>
              <Bold>
                Thank you for helping us spread the word about FindingJo
              </Bold>
              <Spacing size={16} />
              <FlexRow>
                <div style={{ marginRight: "16px" }}>
                  <div style={{ fontSize: "10px", color: "#919191" }}>
                    Your LinkedIn Profile as per our records
                  </div>
                  <div>{user.linkedInProfile}</div>
                </div>
                <Button
                  kind={KIND.secondary}
                  size={SIZE.mini}
                  onClick={() => history.push(TALENTSCOUT_PROFILE_DETAILS)}
                >
                  Edit
                </Button>
              </FlexRow>
              <Spacing size={16} />
              <div style={{ fontSize: "12px" }}>
                Once your LinkedIn Post is verified by FindingJo team, you will
                be able to upload 10 candidate profiles at a time
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {!isShareDone ? (
            <LinkedinShareButton
              title={"Register at https://bit.ly/33QVrk6"}
              summary={
                "To Become a Freelance Talent Recruiter on www.FindingJo.com\n\n\n\n\n\n#recruiters #hiring #opentowork #hr #humanresources #recruiting #talentacquisition"
              }
              onShareWindowClose={async () => {
                try {
                  await updateTalentscoutLinkedInShareStatus();
                  setIsShareDone(true);
                } catch (e) {
                  console.log(e);
                }
              }}
              url={linkedInShareUrl}
              className="react-share-button"
            >
              <Button size={SIZE.mini}>
                <FlexRow>
                  <Icon name="linkedin" background="dark" size={16} />
                  <ButtonText>Share on LinkedIn</ButtonText>
                </FlexRow>
              </Button>
            </LinkedinShareButton>
          ) : (
            <Button
              onClick={async () => {
                setIsShareModalVisible(false);
                setIsShareDone(false);
                await onLinkedInShare();
              }}
              size={SIZE.mini}
            >
              Go to Home
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  {}
)(withRouter(TSLinkedInShare));
