import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Showcase as WorkShowcase } from "src/lib/types/candidate";
import { FormLabel, FormErrorMessage } from "src/lib/uikit";
import { Box, Flex, Select, Button } from "@chakra-ui/react";
import { FiCheck, FiTrash, FiX } from "react-icons/fi";
import { WorkShowcaseRow } from "./WorkShowcaseList";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useToast, Input } from "@chakra-ui/react";

const schema = yup
  .object({
    category: yup.string().required("Required"),
    url: yup.string().required("Required"),
  })
  .required();

export type WorkShowcaseFormProps = {
  workShowcaseRow: WorkShowcaseRow;
  onSave: (e: WorkShowcase) => void;
  onDiscard: () => void;
  onDelete: () => void;
};

export const WorkShowcaseForm = ({
  workShowcaseRow: { workShowcase, isNew },
  onSave,
  onDiscard,
  onDelete,
}: WorkShowcaseFormProps) => {
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<WorkShowcase>({
    defaultValues: workShowcase,
    resolver: yupResolver(schema),
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  useEffect(() => reset(workShowcase), [workShowcase]);
  const toast = useToast();
  const onFormSubmit = async (values: any) => {
    setIsSaving(true);
    await onSave(values as WorkShowcase);
    setIsSaving(false);
  };
  const onFormError = async () => {
    toast({
      title: "Validation error",
      description: "Please check the fields in the form",
      status: "error",
    });
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
        <Box mb={6} mt={4}>
          <FormLabel htmlFor={`category`}>Category</FormLabel>
          <Select
            {...register(`category` as const)}
            size="lg"
            placeholder=" "
            fontSize="md"
            id={`category`}
          >
            {["GITHUB", "BEHANCE", "DRIBBLE", "WEBSITE"].map((c, idx) => (
              <option key={idx} value={c}>
                {c}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.category?.message}</FormErrorMessage>
          <FormLabel
            htmlFor="phone"
            fontWeight="500"
            fontSize="sm"
            color="gray.500"
            mt={2}
          >
            Url
          </FormLabel>
          <Input {...register(`url` as const)} size={"lg"} />
          <FormErrorMessage>{errors.url?.message}</FormErrorMessage>
        </Box>

        <Flex mt={4} justifyContent="space-between">
          <Flex>
            <Button
              type="submit"
              mr={2}
              leftIcon={<FiCheck />}
              isLoading={isSaving}
              loadingText="Saving"
            >
              Save WorkShowcase
            </Button>
            {isNew && (
              <Button
                type="button"
                onClick={onDiscard}
                variant={"ghost"}
                leftIcon={<FiX />}
              >
                Discard
              </Button>
            )}
          </Flex>
          {!isNew && (
            <Button
              type="button"
              onClick={async () => {
                setIsDeleting(true);
                await onDelete();
                setIsDeleting(false);
              }}
              variant={"ghost"}
              isLoading={isDeleting}
              loadingText="Deleting"
              leftIcon={<FiTrash />}
            >
              Delete
            </Button>
          )}
        </Flex>
      </form>
    </Box>
  );
};
