import React from "react";
import { FormControl } from "baseui/form-control";

const WrappedFormControl = (props) => (
  <FormControl
    {...props}
    overrides={{
      ControlContainer: {
        style: ({ $theme }) => {
          return {
            marginBottom: "24px",
          };
        },
      },
      Label: {
        style: ({ $theme }) => ({
          marginBottom: "0px",
        }),
      },
    }}
  >
    {props.children}
  </FormControl>
);

export default WrappedFormControl;
