import React, { useState, useRef, useEffect } from "react";
import Modal from "../components/Modal";
import Title from "../components/Title";
import firebase from "firebase";
import { toaster } from "baseui/toast";
import { ModalHeader, ModalBody } from "baseui/modal";
import { setWidgetState } from "../redux/actions/widget";
import { setUser } from "../redux/actions/user";
import { connect } from "react-redux";
import { shareEndpoint } from "../api/endpoints";
import { Stage, Layer, Rect, Text, Image } from "react-konva";
import useImage from "use-image";
import { LinkedinShareButton, EmailShareButton } from "react-share";
import { SIZE } from "baseui/modal";
import { updateJobShareImage } from "../api/job";
import { StyledLoadingSpinner } from "baseui/button";
import { getCurrencyWords } from "../util/currency";
import { Icon } from "../components/Icon";

const LogoImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/logo_dark.svg",
    "Anonymous"
  );
  return (
    <Image image={image} height={50} width={141} x={props.x} y={props.y} />
  );
};

const UserImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/hiring_v2.svg",
    "Anonymous"
  );
  return (
    <Image image={image} height={160} width={120} x={props.x} y={props.y} />
  );
};

const JobTitleImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/title.svg",
    "Anonymous"
  );
  return <Image image={image} height={20} width={20} x={props.x} y={props.y} />;
};

const CompanyImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/company.svg",
    "Anonymous"
  );
  return <Image image={image} height={16} width={16} x={props.x} y={props.y} />;
};

const SalaryImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/salary.svg",
    "Anonymous"
  );
  return <Image image={image} height={16} width={20} x={props.x} y={props.y} />;
};

const LocationImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/location.svg",
    "Anonymous"
  );
  return <Image image={image} height={16} width={16} x={props.x} y={props.y} />;
};

const SkillsImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/skills.svg",
    "Anonymous"
  );
  return <Image image={image} height={16} width={16} x={props.x} y={props.y} />;
};

const ImageBackground = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/bg_job_v2.jpg",
    "Anonymous"
  );
  return (
    <Image
      image={image}
      height={props.height}
      width={props.width}
      x={props.x}
      y={props.y}
    />
  );
};

const overflowText = (text, len) => {
  if (!text) return "";
  if (text.length > len) {
    return text.substring(0, len) + "...";
  }
  return text;
};

const ShareJobModel = (props) => {
  const [isUploading, setIsUploading] = useState(false);
  const [shareUrl, setShareUrl] = useState(props.shareJobImageUrl);
  const stageRef = useRef(null);

  useEffect(() => {
    setShareUrl(props.shareJobImageUrl);
  }, [props.shareJobImageUrl]);

  const uploadPicture = async () => {
    if (shareUrl) return;
    var dataURL = stageRef.current.toDataURL({ pixelRatio: 2 });
    var storageRef = firebase.storage().ref();
    try {
      setIsUploading(true);
      let uploadSnapshot = await storageRef
        .child(
          "share_pictures/" + props.shareJob.id + "_" + Date.now() + ".jpg"
        )
        .putString(dataURL, "data_url");
      let shareUrl = await uploadSnapshot.ref.getDownloadURL();
      await updateJobShareImage(props.shareJob.id, shareUrl);
      setShareUrl(shareUrl);
      setIsUploading(false);
    } catch (e) {
      console.log(e);
      setIsUploading(false);
      showUploadError();
    }
  };

  const showUploadError = () => {
    toaster.negative(
      <div>Unable to create the share content. Please try again.</div>,
      {
        autoHideDuration: 3000,
      }
    );
  };

  if (!props.shareJob) {
    return null;
  }

  let midSectionHeight = 100;

  const shareJob = props.shareJob;

  const expInYearsText =
    shareJob.minExperience + "-" + shareJob.maxExperience + " Years Exp";

  return (
    <Modal
      animate
      autoFocus
      isOpen={props.isShareJobModelVisible}
      closeable
      size={SIZE.auto}
      onClose={() => {
        props.setWidgetState({
          isShareJobModelVisible: false,
        });
      }}
    >
      <ModalHeader>
        <Title>{"Share this job in your network "}</Title>
        <div style={{ color: "#919191", fontSize: "12px", marginTop: "-12px" }}>
          This helps to reach more candidates
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          {shareUrl ? (
            <img src={shareUrl} width={625} height={327} alt={""} />
          ) : (
            <Stage ref={stageRef} width={625} height={327}>
              <Layer>
                <Rect x={0} y={0} width={625} height={327} fill="#03294E" />
                <ImageBackground x={0} y={0} width={625} height={327} />
                <UserImage x={40} y={75} />
                <Text
                  fill={"#FFFFFF80"}
                  text="Apply for this job"
                  fontFamily={"Lato"}
                  x={40}
                  y={20}
                  fontSize={14}
                  fontStyle={"400"}
                />
                <Text
                  fill={"#FFFFFF80"}
                  text="First get an offer. Then interview."
                  fontFamily={"Lato"}
                  x={40}
                  y={40}
                  fontSize={24}
                  fontStyle={"700"}
                />

                <JobTitleImage x={200} y={midSectionHeight} />
                <Text
                  text={overflowText(
                    shareJob.jobTitle.name + " - " + expInYearsText,
                    35
                  )}
                  fontFamily={"Lato"}
                  fill={"#FFFFFF"}
                  x={230}
                  y={midSectionHeight + 2}
                  fontSize={20}
                  fontStyle={"600"}
                />
                {(midSectionHeight = midSectionHeight + 30) && null}
                <CompanyImage x={202} y={midSectionHeight} />
                <Text
                  fill={"#FFFFFFA0"}
                  text={shareJob.company.name}
                  x={230}
                  y={midSectionHeight + 2}
                  fontFamily={"Lato"}
                  fontSize={16}
                />
                {shareJob.skills.length ? (
                  <>
                    {(midSectionHeight = midSectionHeight + 25) && null}
                    <SkillsImage x={202} y={midSectionHeight} />
                    <Text
                      fill={"#FFFFFFA0"}
                      text={overflowText(
                        shareJob.skills
                          .slice(0, 4)
                          .map((sk) => sk.name)
                          .join(", "),
                        40
                      )}
                      x={230}
                      y={midSectionHeight + 2}
                      fontFamily={"Lato"}
                      fontSize={16}
                    />
                  </>
                ) : null}
                {(midSectionHeight = midSectionHeight + 25) && null}
                <LocationImage x={202} y={midSectionHeight} />
                <Text
                  fill={"#FFFFFFA0"}
                  text={shareJob.locations[0].name}
                  x={230}
                  y={midSectionHeight + 2}
                  fontFamily={"Lato"}
                  fontSize={16}
                />
                {/* {(midSectionHeight = midSectionHeight + 25) && null}
                <SalaryImage x={202} y={midSectionHeight} />
                <Text
                  fill={"#FFFFFF"}
                  text={
                    shareJob.salaryCurrency +
                    " " +
                    getCurrencyWords(shareJob.maximumSalary)
                  }
                  x={230}
                  fontFamily={"Lato"}
                  fontStyle={"600"}
                  y={midSectionHeight + 2}
                  fontSize={16}
                /> */}
                <Text
                  fill={"#FFFFFF80"}
                  text="Apply now!"
                  x={40}
                  y={280}
                  fontSize={20}
                  fontStyle={"600"}
                  fontFamily={"Lato"}
                />
                <LogoImage x={445} y={255} />
              </Layer>
            </Stage>
          )}
        </div>
        <div>
          <div
            style={{
              padding: "32px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div>
              <LinkedinShareButton
                beforeOnClick={uploadPicture}
                url={shareEndpoint + "/api/share/job/" + props.shareJob.id}
                className="react-share-button"
              >
                <div
                  style={{
                    width: "220px",
                    padding: "6px 12px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    border: "1px solid #222",
                    borderRadius: "4px",
                    marginBottom: "8px",
                  }}
                >
                  <Icon size={16} name="linkedin" />
                  <div style={{ marginLeft: "16px" }}>Share on LinkedIn</div>
                </div>
              </LinkedinShareButton>
            </div>
            <div>
              <EmailShareButton
                beforeOnClick={uploadPicture}
                url={""}
                body={
                  "Would you like to apply for this job ? \n\n " +
                  "\n" +
                  shareJob.jobTitle.name +
                  " - " +
                  expInYearsText +
                  "\n" +
                  shareJob.company.name +
                  "\n" +
                  shareJob.locations[0].name +
                  "\n" +
                  // "Minimum Salary: " +
                  // getCurrencyWords(shareJob.minimumSalary) +
                  "\n\n" +
                  "Apply for this job now!" +
                  "\n" +
                  "Link: " +
                  shareEndpoint +
                  "/api/share/job/" +
                  shareJob.id
                }
                subject={
                  shareJob.jobTitle.name +
                  " - " +
                  expInYearsText +
                  " - " +
                  "Apply job"
                }
                className={"react-share-button"}
              >
                <div
                  style={{
                    width: "220px",
                    padding: "6px 12px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    border: "1px solid #222",
                    borderRadius: "4px",
                    marginBottom: "8px",
                  }}
                >
                  <Icon size={16} name="mail" />
                  <div style={{ marginLeft: "16px" }}>Share on email</div>
                </div>
              </EmailShareButton>
            </div>
          </div>
          {
            <div
              style={{
                border: "1px solid #e1e1e1",
                borderRadius: "4px",
                padding: "8px",
                display: "flex",
                background: "#f5f5f5",
              }}
            >
              <div style={{ marginRight: "8px", color: "#919191" }}>
                Copy Link:
              </div>
              <div>{shareEndpoint + "/api/share/job/" + shareJob.id}</div>
            </div>
          }
          <div style={{ height: "32px" }}>
            {isUploading ? (
              <div>
                Creating share content <StyledLoadingSpinner />
              </div>
            ) : null}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default connect(
  (state) => ({
    user: state.user,
    isShareJobModelVisible: state.widget.isShareJobModelVisible,
    shareJob: state.widget.shareJob,
    shareJobImageUrl: state.widget.shareJobImageUrl,
  }),
  { setUser, setWidgetState }
)(ShareJobModel);
