import React from "react";
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";
import { setUser } from "../redux/actions/user";
import { styled, withStyle } from "baseui";
import {
  getCandidate,
  updateCandidateStat,
  submitCandidate,
  disableCandidate,
  getMyAnonymousCandidate,
} from "../api/candidate";
import Title from "../components/Title";
import CandidateProgress from "../components/CandidateProgress";
import LoadingScreen from "../components/LoadingScreen";
import Resume from "../components/Resume";
import Button from "../components/Button";
import PopConfirm from "../components/PopConfirm";
import { CANDIDATE_FIND_JOBS, CANDIDATE_PROFILE_BASIC } from "../util/routes";
import Card from "../components/Card";
import * as Analytics from "../analytics";
import Bold from "../components/Bold";
import { ModalHeader, ModalBody, SIZE as MODAL_SIZE } from "baseui/modal";
import Modal from "../components/Modal";
import { toaster } from "baseui/toast";
import { isMobile } from "react-device-detect";
import { KIND, SIZE } from "baseui/button";
import { Block } from "baseui/block";
import { Icon } from "../components/Icon";
import { LabelMedium, ParagraphSmall } from "baseui/typography";
import { CenterContainer } from "../components/CenterContainer";

const SubTitle = withStyle(Bold, {
  fontSize: "14px",
  marginBottom: "8px",
  marginTop: "8px",
});

const Seperator = styled("div", {
  height: "16px",
});

class CandidateDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      isFetched: false,
      candidate: {},
      userCounts: {
        ...props.user.candidateCounts,
      },
      talentScoutRequests: [],
      interviewRequests: [],
      uniqueIdModal: false,
      isAnonymousCandidateLoading: false,
      isAnonymousModalVisible: false,
      anonymousCandidate: {},
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[C] page view dashboard");
    await this.fetchCandidate();
  }

  async onViewAnonymousResume() {
    try {
      this.setState({
        isAnonymousCandidateLoading: true,
      });
      let anonymousCandidate = await getMyAnonymousCandidate(
        this.props.user.id
      );
      this.setState({
        isAnonymousCandidateLoading: false,
        anonymousCandidate,
        isAnonymousModalVisible: true,
      });
    } catch (e) {
      this.setState({
        isAnonymousCandidateLoading: false,
      });
      toaster.negative(
        <div>
          Error occured while fetching anonymous resume. Please try again.
        </div>,
        {
          autoHideDuration: 4000,
        }
      );
    }
  }

  checkForLastActiveAt() {
    let lastActiveAt = localStorage.getItem(
      this.props.user.id + ".lastActiveAt"
    );
    if (!lastActiveAt || Date.now() - lastActiveAt > 3600000) {
      localStorage.setItem(this.props.user.id + ".lastActiveAt", Date.now());
      updateCandidateStat(this.props.user.id, "active").then(() =>
        localStorage.setItem(this.props.user.id + ".lastActiveAt", Date.now())
      );
    }
  }

  fetchCandidate = async () => {
    this.setState({
      loading: true,
    });
    try {
      let candidate = await getCandidate(this.props.user.id);
      this.setState({
        candidate,
        loading: false,
      });
      this.checkForLastActiveAt().then(() => console.log("updated active at"));
    } catch (ex) {
      this.setState({
        loading: false,
      });
    }
  };

  onDisableCandidate = async () => {
    try {
      this.setState({
        isDisabling: true,
      });
      await disableCandidate(this.props.user.id);
      this.setState({
        isDisabling: false,
      });
      await this.fetchCandidate();
    } catch (e) {
      this.setState({
        isDisabling: false,
      });
    }
  };

  submitForReview = async () => {
    Analytics.logEvent("submit review dashboard candidate");
    this.setState({
      isSubmitting: true,
    });
    try {
      await submitCandidate({
        userId: this.props.user.id,
      });
      await this.fetchCandidate();
    } catch (e) {}
    this.setState({
      isSubmitting: false,
    });
  };

  render() {
    // if (!this.props.user.candidateProfile) {
    //   return <Redirect to={CANDIDATE_FIND_JOBS} />;
    // }

    if (this.state.error) {
      return (
        <CenterContainer>
          <div>Error occured. Please reload the page</div>
        </CenterContainer>
      );
    }
    if (this.state.loading) {
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    }
    return (
      <CenterContainer>
        <div style={{ paddingBottom: 48 }}>
          <Title marginBottom="scale600">Dashboard</Title>
          {this.state.candidate.pendingSubmission &&
          this.props.user.candidateProfile ? (
            <Card
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginBottom="scale800"
            >
              <div>You have made changes to your profile.</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{ position: "relative", top: "-4px", left: "-14px" }}
                >
                  <div className="dot" />
                </div>
                <Button
                  onClick={this.submitForReview}
                  isLoading={this.state.isSubmitting}
                >
                  Submit for review
                </Button>
              </div>
            </Card>
          ) : null}
          {this.state.candidate.status === "REJECTED" ? (
            <div
              style={{
                padding: 16,
                background: "#FFFFFF",
                borderRadius: "4px",
                alignItems: "center",
                justifyContent: "space-between",
                boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.2)",
                marginBottom: "16px",
                whiteSpace: "pre-line",
              }}
            >
              <div style={{ color: "#e63946" }}>
                We found some issues with your recent profile submission
              </div>
              {this.state.candidate.statusReason && (
                <div style={{ marginBottom: "16px" }}>
                  {this.state.candidate.statusReason ||
                    "Your education which was provided does not seem to be correct. It is not as the given guidelines. Please edit your profile and submit it again. "}
                </div>
              )}
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    onClick={() => {
                      this.props.history.push(CANDIDATE_PROFILE_BASIC);
                    }}
                    isLoading={this.state.isSubmitting}
                  >
                    Submit again
                  </Button>
                  <div
                    style={{ position: "relative", top: "-4px", left: "-14px" }}
                  >
                    <div className="dot" />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <CandidateProgress
            status={this.state.candidate.status}
            anonymousStatus={this.state.candidate.anonymousStatus}
          />
          <Seperator />
          {/* <CandidateStats
          status={this.state.candidate.status}
          anonymousStatus={this.state.candidate.anonymousStatus}
          userCounts={this.state.userCounts}
        /> */}
          <div>
            <Block
              display="flex"
              alignItems="center"
              marginTop="scale400"
              marginBottom="scale400"
              justifyContent="space-between"
            >
              <Block display="flex" alignItems="center">
                <LabelMedium>Your Complete Profile</LabelMedium>
                <Link
                  style={{ marginLeft: "8px" }}
                  to={CANDIDATE_PROFILE_BASIC}
                >
                  <Block
                    $display="flex"
                    $flexDirection="row"
                    $alignItems="center"
                  >
                    <Block $marginRight="scale200">
                      <Icon size={12} name="edit" />
                    </Block>
                    <ParagraphSmall>Edit</ParagraphSmall>
                  </Block>
                </Link>
              </Block>
              {this.state.candidate.anonymousStatus === "GREEN" ||
              this.state.candidate.anonymousStatus === "ORANGE" ? (
                <Button
                  size={SIZE.mini}
                  kind={KIND.tertiary}
                  isLoading={this.state.isAnonymousCandidateLoading}
                  onClick={() => {
                    this.onViewAnonymousResume();
                  }}
                >
                  Your Anonymous Profile
                </Button>
              ) : null}
            </Block>
            <Resume
              user={this.props.user}
              candidate={this.state.candidate}
            ></Resume>
          </div>
          <Seperator />
          {this.state.candidate.status !== "DISABLED" ? (
            <div>
              <SubTitle>Disable Profile</SubTitle>
              <div style={{ display: "flex" }}>
                <Card
                  style={{
                    marginRight: "16px",
                    width: "50%",
                  }}
                >
                  <PopConfirm
                    showArrow
                    width={"420px"}
                    confirmText={"Disable"}
                    title={
                      <div>
                        <div style={{ marginBottom: "16px" }}>
                          {"Are you sure to disable your profile ?"}
                        </div>
                        <div style={{ marginBottom: "16px" }}>
                          {
                            "Disable your profile when you are not looking for a job. Once disabled, you will not get any more interview offers. This also removes all your existing Talent-Scout requests and interview requests"
                          }
                        </div>
                      </div>
                    }
                    onConfirm={async () => await this.onDisableCandidate()}
                  >
                    <Button isLoading={this.state.isDisabling} kind="tertiary">
                      Disable Profile
                    </Button>
                  </PopConfirm>
                  <div style={{ color: "#919191", marginTop: 16 }}>
                    Hide your profile from talent-scouts and employers if you
                    are not looking for a job
                  </div>
                </Card>
              </div>
            </div>
          ) : null}
          <Modal
            animate
            autoFocus
            isOpen={this.state.isAnonymousModalVisible}
            closeable
            size={isMobile ? MODAL_SIZE.auto : "800px"}
            onClose={() => {
              this.setState({
                isAnonymousModalVisible: false,
              });
            }}
          >
            <ModalHeader>
              <Title>{"Your anonymous resume"}</Title>
            </ModalHeader>
            <ModalBody>
              <Resume
                isAnonymous
                candidate={this.state.anonymousCandidate}
              ></Resume>
            </ModalBody>
          </Modal>
        </div>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateDashboard));
