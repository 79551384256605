import React, { useState } from "react";
import { styled, withStyle } from "baseui";
import StatusPill from "./StatusPill";
import { Icon } from "./Icon";
import { LabelSmall } from "baseui/typography";

const Row = styled("div", {
  display: "flex",
  alignItems: "center",
});

const StepTitle = styled(LabelSmall, ({ isComplete, isActive }) => ({
  color: isComplete ? "#777777" : "inherit",
}));
const StepDescription = styled("div", (props) => ({}));

const Circle = styled("div", {
  width: "24px",
  height: "24px",
  borderRadius: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const RedCircle = withStyle(Circle, ({ $theme }) => ({
  backgroundColor: $theme.colors.negative,
}));

const GreenCircle = withStyle(Circle, ({ $theme }) => ({
  backgroundColor: $theme.colors.green,
}));

const WhiteCircle = withStyle(Circle, {
  backgroundColor: "transparent",
  border: "1px solid #e1e1e1",
});

const BottomLine = styled("div", ({ $theme, isComplete }) => ({
  width: "1px",
  flex: 1,
  backgroundColor: isComplete ? $theme.colors.green : "#e1e1e1",
}));

const Expandable = styled("div", ({ isExpanded }) => ({
  transition: "all 1s",
  maxHeight: isExpanded ? "1000px" : "0px",
  overflow: "hidden",
  marginTop: isExpanded ? "16px" : "0px",
}));

const PendingIndicator = () => {
  return (
    <GreenCircle>
      <div
        style={{
          width: "12px",
          height: "12px",
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          animation: "blink-animation 1s infinite",
        }}
      ></div>
    </GreenCircle>
  );
};

const SuccessIndicator = () => {
  return (
    <GreenCircle>
      <Icon name="check" color="white" size={16} />
    </GreenCircle>
  );
};

const FailureIndicator = () => {
  return (
    <RedCircle>
      <Icon name="x" size={16} color="white" />
    </RedCircle>
  );
};

const InActiveIndicator = () => {
  return <WhiteCircle></WhiteCircle>;
};

const Step = ({ step, isComplete, isActive, isLast, currentStepStatus }) => {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: "24px",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isComplete && <SuccessIndicator />}
        {isActive && currentStepStatus === "SUCCESS" && <SuccessIndicator />}
        {isActive && currentStepStatus === "FAILURE" && <FailureIndicator />}
        {isActive &&
          currentStepStatus !== "SUCCESS" &&
          currentStepStatus !== "FAILURE" && <PendingIndicator />}
        {!isComplete && !isActive && <InActiveIndicator />}
        {!isLast && <BottomLine isComplete={isComplete} isActive={isActive} />}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          paddingBottom: "16px",
          paddingLeft: "16px",
        }}
      >
        <StepTitle isComplete={isComplete} isActive={isActive}>
          {step.title}
        </StepTitle>
        {!isComplete && (
          <StepDescription isComplete={isComplete} isActive={isActive}>
            {step.description}
          </StepDescription>
        )}
      </div>
    </div>
  );
};

const ProgressSteps = ({
  steps,
  currentStep,
  currentStatus,
  currentStepStatus,
  isExpanded: pIsExpanded,
}) => {
  const [isExpanded, setIsExpanded] = useState(pIsExpanded);
  return (
    <div>
      <Row
        style={{ justifyContent: "space-between", cursor: "pointer" }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
      >
        <StatusPill>{currentStatus}</StatusPill>
        <div>
          <Icon size={24} name={isExpanded ? "chevron-up" : "chevron-down"} />
        </div>
      </Row>
      <Expandable isExpanded={isExpanded}>
        {steps.map((step, idx) => (
          <Step
            step={step}
            isComplete={idx < currentStep}
            isActive={idx === currentStep}
            currentStepStatus={currentStepStatus}
            isLast={idx === steps.length - 1}
          />
        ))}
      </Expandable>
    </div>
  );
};

export default ProgressSteps;
