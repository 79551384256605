import React, { createRef } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Input from "../components/Input";
import EmptyCaption from "../components/EmptyCaption";
import PhoneInput from "../components/PhoneInput";
import Button from "../components/Button";
import { FormControl } from "baseui/form-control";
import Label from "../components/Label";
import { uploadCandidate } from "../api/talentscout";
import { toaster } from "baseui/toast";
import Title from "../components/Title";
import CandidateItemEmployer from "../components/CandidateItemEmployer";
import CandidateExistsModal from "../components/CandidateExistsModal";
import * as Analytics from "../analytics";
import Modal from "../components/Modal";
import Select from "../components/Select";
import TextArea from "../components/TextArea";
import { TALENTSCOUT_MY_CANDIDATES } from "../util/routes";
import { validateEmail } from "../util/validator";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import { CenterContainer } from "../components/CenterContainer";
import SalaryInput from "../components/SalaryInput";
import FileUploader from "../components/FileUploader";
import { getNoticePeriodOptions } from "../util/noticePeriod";
import { searchEntity } from "../api/entity";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import CheckboxGroup from "../components/CheckboxGroup";
import { getCurrency } from "../util/currency";
import Currency from "../components/Currency";
import Card from "../components/Card";
import { LabelMedium, ParagraphSmall } from "baseui/typography";
import { getJobById } from "../api/job";

const random = (length = 8) => {
  return Math.random()
    .toString(16)
    .substr(2, length);
};

class ScoutUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basic: {},
      isFetching: false,
      isLoading: false,
      isConfirmModalVisible: false,
      values: {
        email: "",
        phone: "",
        resume: "",
        expectedCTCCurrency: getCurrency("INR"),
        expectedCTC: 0,
        totalCTCCurrency: getCurrency("INR"),
        totalCTC: 0,
        noticePeriod: 0,
        jobFunctions: [],
        idealNextJob: "",
        startupTypes: [],
        isRemoteReady: 0,
        jobLocations: [],
        skills: [],
        phonePrefix: {
          label: "India (भारत)",
          id: "IN",
          dialCode: "+91",
        },
      },
      existingCandidateMessage: "",
      existingCandidate: undefined,
      errors: {},
    };
    this.fileUploaderRef = createRef();
  }

  async componentDidMount() {
    Analytics.logEvent("[S] page view upload candidate");
    const { jobId } = (this.props.match && this.props.match.params) || {};
    try {
      if (jobId) {
        const { job } = await getJobById(jobId);
        this.setState({
          jobId,
          selectedJob: job,
        });
      }
    } catch (e) {
      this.setState({
        jobId,
      });
    }
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && !this.state.values[id]) {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  validateFields = () => {
    let isErrorFound = false;
    let errors = {};
    if (!validateEmail(this.state.values.email)) {
      this.setState({
        errors: {
          email: "Email is not valid",
        },
      });
      return true;
    }

    ["email", "phone", "linkedInProfile"].forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });

    if (isErrorFound) {
      this.setState({
        errors,
      });
    }
    return isErrorFound;
  };

  handleSubmit = async () => {
    if (!this.validateFields()) {
      await this.onContinue(this.state.values);
    } else {
      this.setState({
        isLoading: false,
      });
    }
  };

  showConfirm = async (e) => {
    e.preventDefault();
    if (!this.validateFields()) {
      this.setState({
        isConfirmModalVisible: true,
      });
    }
  };

  async onContinue(values) {
    Analytics.logEvent("[S] click submit upload candidate");
    if (!values.resume) {
      toaster.negative(<div>Please attach the resume</div>, {
        autoHideDuration: 3000,
      });
      this.setState({
        isLoading: false,
      });
      return;
    }
    this.setState({
      existingCandidate: undefined,
      existingCandidateMessage: "",
      isLoading: true,
    });
    try {
      await uploadCandidate({
        talentscoutId: this.props.user.id,
        jobId: this.state.jobId,
        candidateEmail: values.email && values.email.trim(),
        candidateLinkedInProfile:
          values.linkedInProfile && values.linkedInProfile.trim(),
        candidatePhone: values.phone && values.phone.trim(),
        candidatePhonePrefix: values.phonePrefix,
        candidateCTC: values.expectedCTC,
        candidateCTCCurrency: values.expectedCTCCurrency.id || "INR",
        candidateCurrentCTC: values.totalCTC,
        candidateCurrentCTCCurrency: values.totalCTCCurrency.id || "INR",
        candidateResumeURL: values.resume,
        candidateNoticePeriod: values.noticePeriod.id,
        candidateJobFunctions: values.jobFunctions,
        candidateIdealNextJob: values.idealNextJob,
        candidateSkills: values.skills,
        candidateStartupTypes: values.startupTypes,
        candidateRemoteReady: values.isRemoteReady,
        candidateLocations: values.jobLocations,
      });
      this.setState({
        values: {
          email: "",
          phone: "",
          linkedInProfile: "",
          phonePrefix: {
            label: "India (भारत)",
            id: "IN",
            dialCode: "+91",
          },
          totalCTCCurrency: getCurrency("INR"),
          totalCTC: 0,
          expectedCTCCurrency: getCurrency("INR"),
          expectedCTC: 0,
          noticePeriod: 0,
          jobFunctions: [],
          linkedInProfile: "",
          idealNextJob: "",
          startupTypes: [],
          isRemoteReady: 0,
          jobLocations: [],
          skills: [],
        },
        isLoading: false,
        errors: {},
      });
      toaster.positive(
        <div>
          Uploaded successfully. Please inform the candidate to signin and
          approve your talentscout request
        </div>,
        {
          autoHideDuration: 3000,
        }
      );
      // this.props.history.push(TALENTSCOUT_MY_CANDIDATES);
    } catch (e) {
      let { response } = e;
      if (
        response &&
        response.data &&
        response.data.code === "ERROR_CANDIDATE_EXISTS"
      ) {
        // toaster.show(<div>{response.data.message}</div>, {
        //   autoHideDuration: 3000
        // });
        this.setState({
          existingCandidateMessage: response.data.message,
        });
        if (response.data.candidate) {
          this.setState({
            existingCandidate: response.data.candidate,
          });
        }
      } else {
        toaster.show(
          <div>{"Error during upload. Please try after some time."}</div>,
          {
            autoHideDuration: 3000,
          }
        );
      }
      this.setState({
        isLoading: false,
      });
    }
  }

  getValidations(id) {
    switch (id) {
      case "email":
      case "phone":
      case "linkedInProfile":
        return { required: true };
      default:
        return {};
    }
  }

  render() {
    return (
      <CenterContainer>
        <div>
          <Title marginBottom="scale400">Upload Candidate</Title>
          {this.state.selectedJob ? (
            <Card marginBottom="scale400" maxWidth={"420px"}>
              <ParagraphSmall color="accent">SELECTED JOB</ParagraphSmall>
              <LabelMedium>{this.state.selectedJob.jobTitle.name}</LabelMedium>
              <ParagraphSmall>
                {this.state.selectedJob.company.name}
              </ParagraphSmall>
            </Card>
          ) : null}
          <div style={{ maxWidth: "420px", display: "flex" }}>
            <Card>
              <form
                onSubmit={(e) => {
                  this.showConfirm(e);
                }}
                autoComplete="off"
              >
                <FormControl
                  label={<Label>{"Enter candidate email"}</Label>}
                  caption={<EmptyCaption />}
                  error={this.state.errors["email"]}
                >
                  <Input
                    id={"email"}
                    onChange={(e) =>
                      this.updateFormValues("email", e.target.value)
                    }
                    value={this.state.values["email"]}
                    autoComplete={"off"}
                    onBlur={(e) =>
                      this.updateFormErrors(
                        "email",
                        this.validate("email", this.getValidations("email"))
                      )
                    }
                  />
                </FormControl>
                <FormControl
                  label={<Label>{"Enter candidate linkedIn profile"}</Label>}
                  caption={<EmptyCaption />}
                  error={this.state.errors["linkedInProfile"]}
                >
                  <Input
                    id={"linkedInProfile"}
                    onChange={(e) =>
                      this.updateFormValues("linkedInProfile", e.target.value)
                    }
                    value={this.state.values["linkedInProfile"]}
                    autoComplete={"off"}
                    onBlur={(e) =>
                      this.updateFormErrors(
                        "linkedInProfile",
                        this.validate(
                          "linkedInProfile",
                          this.getValidations("linkedInProfile")
                        )
                      )
                    }
                  />
                </FormControl>
                <FormControl
                  label={<Label>{"Enter candidate phone number"}</Label>}
                  caption={<EmptyCaption />}
                  error={
                    this.state.errors["phone"] ||
                    this.state.errors["phoneCountry"]
                  }
                >
                  <PhoneInput
                    country={this.state.values["phonePrefix"]}
                    onCountryChange={({ option }) =>
                      this.updateFormValues("phonePrefix", option)
                    }
                    text={this.state.values["phone"]}
                    autoComplete={"off"}
                    onTextChange={(e) =>
                      this.updateFormValues("phone", e.target.value)
                    }
                  />
                </FormControl>
                <FormControl
                  label={<Label>{"Candidate's current salary ?"}</Label>}
                  caption={<EmptyCaption />}
                  error={this.state.errors["totalCTC"]}
                >
                  <SalaryInput
                    name={"totalCTC"}
                    onChange={(val) => this.updateFormValues("totalCTC", val)}
                    onCurrencyChange={(val) =>
                      this.updateFormValues("totalCTCCurrency", val)
                    }
                    value={this.state.values["totalCTC"]}
                    currencyValue={this.state.values["totalCTCCurrency"]}
                  ></SalaryInput>
                </FormControl>
                <FormControl
                  label={<Label>{"Candidate's desired salary ?"}</Label>}
                  caption={<EmptyCaption />}
                  error={this.state.errors["expectedCTC"]}
                >
                  <SalaryInput
                    name={"expectedCTC"}
                    onChange={(val) =>
                      this.updateFormValues("expectedCTC", val)
                    }
                    onCurrencyChange={(val) =>
                      this.updateFormValues("expectedCTCCurrency", val)
                    }
                    value={this.state.values["expectedCTC"]}
                    currencyValue={this.state.values["expectedCTCCurrency"]}
                  ></SalaryInput>
                </FormControl>

                <FormControl
                  label={<Label>{"Candidate's Resume"}</Label>}
                  caption={<EmptyCaption />}
                  error={this.state.errors["resume"]}
                >
                  <FileUploader
                    ref={this.fileUploaderRef}
                    // isImmediate
                    uploadUrl={"resumes/" + random() + "_" + Date.now()}
                    onError={(error) => {
                      this.setState({
                        isLoading: false,
                      });
                      toaster.negative(<div>{error + ""}</div>, {
                        autoHideDuration: 2000,
                      });
                    }}
                    onSuccess={async (downloadUrl) => {
                      this.setState(
                        {
                          isLoading: true,
                          values: {
                            ...this.state.values,
                            ...{ resume: downloadUrl },
                          },
                        },
                        () => {
                          this.handleSubmit();
                        }
                      );
                    }}
                  />
                </FormControl>

                <FormControl
                  label={<Label>{"Candidate's notice period"}</Label>}
                  caption={<EmptyCaption />}
                  error={this.state.errors["noticePeriod"]}
                >
                  <Select
                    clearable={false}
                    searchable={false}
                    defaultOptions={getNoticePeriodOptions()}
                    onChange={(value) => {
                      this.updateFormValues("noticePeriod", value);
                    }}
                    value={this.state.values["noticePeriod"]}
                  />
                </FormControl>

                <FormControl
                  label={<Label>Candidate's Job Function</Label>}
                  caption={<EmptyCaption />}
                  error={this.state.errors["jobFunctions"]}
                >
                  <Select
                    autoComplete={"no"}
                    multi
                    getOptions={async (query) => {
                      let options = await searchEntity("jobFunction", query);
                      options.sort((o1, o2) => (o1.name > o2.name ? 1 : -1));
                      return options;
                    }}
                    onChange={(value) => {
                      this.updateFormValues("jobFunctions", value);
                    }}
                    value={this.state.values["jobFunctions"]}
                  />
                </FormControl>

                <FormControl
                  label={
                    <Label
                      appendText={
                        "(" +
                        (this.state.values.skills || "").length +
                        "/10 skills)"
                      }
                    >
                      {"Candidate's skills"}
                    </Label>
                  }
                  caption={<EmptyCaption />}
                  error={this.state.skillsError}
                >
                  <Select
                    creatable
                    multi
                    getOptions={async (query) => searchEntity("skill", query)}
                    onChange={(value) => {
                      if ((value || []).length > 10) {
                        return;
                      }
                      this.updateFormValues("skills", value);
                    }}
                    value={this.state.values.skills}
                  />
                </FormControl>
                <FormControl
                  label={<Label>Candidate's Preferred Location</Label>}
                  caption={<EmptyCaption />}
                  error={this.state.errors["jobLocations"]}
                >
                  <Select
                    creatable
                    multi
                    type={"search"}
                    searchable
                    getOptions={async (query) =>
                      searchEntity("location", query)
                    }
                    onChange={(value) => {
                      this.updateFormValues("jobLocations", value);
                    }}
                    value={this.state.values["jobLocations"]}
                  />
                </FormControl>
                <FormControl
                  label={
                    <Label>{"Is candidate ready to work remotely ?"}</Label>
                  }
                  caption={<EmptyCaption />}
                  error={this.state.errors["isRemoteReady"]}
                >
                  <RadioGroup
                    value={this.state.values["isRemoteReady"]}
                    onChange={(e) => {
                      this.updateFormValues("isRemoteReady", +e.target.value);
                    }}
                    align={ALIGN.vertical}
                  >
                    <Radio value={0}>No</Radio>
                    <Radio value={1}>Yes</Radio>
                  </RadioGroup>
                </FormControl>
                <FormControl
                  label={
                    <Label>
                      {"Is candidate willing to work for a startup ?"}
                    </Label>
                  }
                >
                  <RadioGroup
                    value={
                      (this.state.values["startupTypes"] || []).length
                        ? "YES_INTERESTED"
                        : "NOT_INTERESTED"
                    }
                    onChange={(e) => {
                      if (e.target.value === "NOT_INTERESTED") {
                        this.updateFormValues("startupTypes", []);
                        return;
                      }
                      this.updateFormValues("startupTypes", [
                        e.target.value,
                        "EARLY_STAGE",
                        "FUNDED_STARTUPS",
                      ]);
                    }}
                    align={ALIGN.vertical}
                  >
                    <Radio value={"NOT_INTERESTED"}>
                      {"No, I am not interested in startups"}
                    </Radio>
                    <Radio value={"YES_INTERESTED"}>
                      {"Yes, I am interested in startups"}
                    </Radio>
                  </RadioGroup>
                </FormControl>
                <Button isLoading={this.state.isLoading} type={"submit"}>
                  Upload Candidate
                </Button>
              </form>
            </Card>
          </div>
          <Modal
            animate
            autoFocus
            isOpen={this.state.isConfirmModalVisible}
            closeable
            onClose={() => {
              this.setState({
                isConfirmModalVisible: false,
              });
            }}
          >
            <ModalHeader>
              <Title>{"Confirm Candidate Details"}</Title>
            </ModalHeader>
            <ModalBody>
              <div>
                <div style={{ marginBottom: "16px" }}>
                  Please verify the details below
                </div>
                <div
                  style={{
                    backgroundColor: "#F5F5F5",
                    borderRadius: "4px",
                    padding: "8px",
                  }}
                >
                  <div>
                    <span style={{ fontWeight: "500" }}>Email:</span>
                    {this.state.values.email}
                  </div>
                  <div>
                    <span style={{ fontWeight: "500" }}>LinkedInProfile:</span>
                    {this.state.values.linkedInProfile}
                  </div>
                  <div>
                    <span style={{ fontWeight: "500" }}>Phone:</span>
                    {(this.state.values.phonePrefix || {}).dialCode +
                      this.state.values.phone}
                  </div>
                  <div>
                    <span style={{ fontWeight: "500" }}>Resume:</span>
                    {this.state.values.resume ? "Uploaded" : ""}
                  </div>
                  <div>
                    <span style={{ fontWeight: "500" }}>Current Salary:</span>
                    <Currency
                      value={this.state.values.totalCTC}
                      currency={this.state.values.totalCTCCurrency}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "500" }}>Desired Salary:</span>
                    <Currency
                      value={this.state.values.expectedCTC}
                      currency={this.state.values.expectedCTCCurrency}
                    />
                  </div>
                  <div>
                    <span style={{ fontWeight: "500" }}>Notice Period:</span>
                    {this.state.values.noticePeriod.id + " Weeks"}
                  </div>
                  <div>
                    <span style={{ fontWeight: "500" }}>Job Function:</span>
                    {this.state.values.jobFunctions
                      .map((jf) => jf.name)
                      .join(", ")}
                  </div>
                  <div>
                    <span style={{ fontWeight: "500" }}>Skills:</span>
                    {this.state.values.skills.map((sk) => sk.name).join(", ")}
                  </div>
                  <div>
                    <span style={{ fontWeight: "500" }}>
                      Preferred Locations:
                    </span>
                    {this.state.values.jobLocations
                      .map((pf) => pf.name)
                      .join(", ")}
                  </div>
                  <div>
                    <span style={{ fontWeight: "500" }}>Ready for remote:</span>
                    {this.state.values.isRemoteReady ? "Yes" : "No"}
                  </div>
                  <div>
                    <span style={{ fontWeight: "500" }}>Ready for remote:</span>
                    {this.state.values.startupTypes.join(", ")}
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={(e) => {
                  this.setState(
                    {
                      isConfirmModalVisible: false,
                    },
                    async () => {
                      e.preventDefault();
                      await this.fileUploaderRef.current?.upload();
                    }
                  );
                }}
              >
                Upload Candidate
              </Button>
            </ModalFooter>
          </Modal>
          {this.state.existingCandidateMessage ? (
            // <div
            //   style={{
            //     marginTop: "16px",
            //     padding: "16px",
            //     borderRadius: "4px",
            //     border: "1px solid #ED1C24",
            //     backgroundColor: "#ffffff",
            //   }}
            // >
            //   {this.state.existingCandidateMessage}
            // </div>
            <CandidateExistsModal
              isVisible={this.state.existingCandidateMessage}
              onUploadClick={() =>
                this.setState({
                  existingCandidateMessage: false,
                  values: {
                    ...this.state.values,
                    email: "",
                    linkedInProfile: "",
                    phone: "",
                    resume: "",
                    totalCTCCurrency: getCurrency("INR"),
                    totalCTC: 0,
                    expectedCTCCurrency: getCurrency("INR"),
                    expectedCTC: 0,
                    noticePeriod: 0,
                    jobFunctions: [],
                    idealNextJob: "",
                    startupTypes: [],
                    isRemoteReady: 0,
                    jobLocations: [],
                    skills: [],
                    phonePrefix: {
                      label: "India (भारत)",
                      id: "IN",
                      dialCode: "+91",
                    },
                  },
                })
              }
              candidateEmail={this.state.values.email}
              candidatePhone={this.state.values.phone}
              onClose={() =>
                this.setState({
                  existingCandidateMessage: false,
                  // values: {
                  //   email: "",
                  //   phone: "",
                  //   phonePrefix: {
                  //     label: "India (भारत)",
                  //     id: "IN",
                  //     dialCode: "+91",
                  //   },
                  // },
                })
              }
            />
          ) : null}
          <div style={{ marginTop: "32px" }}>
            {this.state.existingCandidate ? (
              <CandidateItemEmployer
                isEmployer={false}
                isClickable
                onDetailsClick={() =>
                  this.props.history.push(
                    "/talentscout/details/candidate-details/" +
                      this.state.existingCandidate.userId
                  )
                }
                onScoutClick={() =>
                  this.props.history.push(
                    "/talentscout/details/candidate-details/" +
                      this.state.existingCandidate.userId
                  )
                }
                user={this.props.user}
                candidate={this.state.existingCandidate}
              />
            ) : null}
          </div>
        </div>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(ScoutUpload));
