import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Input from "../components/Input";
import EmptyCaption from "../components/EmptyCaption";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import Button from "../components/Button";
import { FormControl } from "baseui/form-control";
import Label from "../components/Label";
import { EMPLOYER_PROFILE_REVIEW } from "../util/routes";
import Title from "../components/Title";
import TextArea from "../components/TextArea";
import { updateEmployerTaxData, getEmployer } from "../api/employer";
import * as Analytics from "../analytics";
import { CenterContainer } from "../components/CenterContainer";

class EmployerGST extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      values: {
        billingAddress: "",
        billingName: "",
        hasGST: false,
        gstNumber: "",
        insideState: false,
      },
      errors: {},
    };
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && !this.state.values[id]) {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let errors = {};
    if (this.state.values.hasGST) {
      let validationError = this.validate(
        "gstNumber",
        this.getValidations("gstNumber")
      );
      if (validationError) {
        errors["gstNumber"] = validationError;
        isErrorFound = true;
      }
    }

    if (!isErrorFound) {
      ["billingName", "billingAddress"].forEach((id) => {
        let validationError = this.validate(id, this.getValidations(id));
        if (validationError) {
          errors[id] = validationError;
          isErrorFound = true;
        }
      });
    }

    if (isErrorFound) {
      this.setState({
        errors,
      });
    } else {
      await this.onContinue(this.state.values);
    }
  };

  getValidations(id) {
    switch (id) {
      case "gstNumber":
      case "billingAddress":
      case "billingName":
        return { required: true };
      default:
        return {};
    }
  }

  async componentDidMount() {
    Analytics.logEvent("[E] page view profile gst");
    this.setState({
      isFetching: false,
    });
    try {
      let { taxData } = (await getEmployer(this.props.user.id)) || {};
      taxData = taxData || {};
      this.setState({
        values: {
          hasGST: taxData.hasGST || false,
          gstNumber: taxData.gstNumber || "",
          insideState: taxData.insideState || false,
          billingAddress: taxData.billingAddress || "",
          billingName: taxData.billingName || this.props.user.name || "",
        },
      });
    } catch (ex) {
      console.log(ex);
    }
    this.setState({
      isFetching: false,
    });
  }

  onContinue = async (values) => {
    try {
      await updateEmployerTaxData(values);
      let employerOnboarding = this.props.user.employerOnboarding || [];
      if (employerOnboarding.indexOf("taxDetails") === -1) {
        employerOnboarding.push("taxDetails");
      }
      this.props.setUser({
        employerOnboarding,
      });
      this.props.history.push(EMPLOYER_PROFILE_REVIEW);
    } catch (e) {
      console.log(e);
      alert("Error while saving. Please retry");
    }
  };

  render() {
    return (
      <CenterContainer>
        <Title>Tax Details</Title>
        <div>
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <FormControl
              label={<Label>{"Do you have GST number ?"}</Label>}
              caption={<EmptyCaption />}
              error={this.state.errors["hasGST"]}
            >
              <RadioGroup
                value={this.state.values.hasGST ? 1 : 0}
                onChange={(e) => {
                  this.updateFormValues("hasGST", !!+e.target.value);
                }}
                align={ALIGN.vertical}
              >
                <Radio value={0}>
                  <div style={{ fontSize: "14px" }}>No</div>
                </Radio>
                <Radio value={1}>
                  <div style={{ fontSize: "14px" }}>Yes</div>
                </Radio>
              </RadioGroup>
            </FormControl>
            {this.state.values.hasGST ? (
              <FormControl
                label={<Label>{"Enter GST number"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["gstNumber"]}
              >
                <Input
                  id={"gstNumber"}
                  onChange={(e) =>
                    this.updateFormValues("gstNumber", e.target.value)
                  }
                  value={this.state.values["gstNumber"]}
                  autoComplete={"off"}
                />
              </FormControl>
            ) : null}
            <FormControl
              label={
                <Label
                  appendText={
                    "For GST billing, your registered office is located:"
                  }
                >
                  {"Your place of business ?"}
                </Label>
              }
              caption={<EmptyCaption />}
              error={this.state.errors["insideState"]}
            >
              <RadioGroup
                value={this.state.values.insideState ? 0 : 1}
                onChange={(e) => {
                  this.updateFormValues("insideState", +e.target.value === 0);
                }}
                align={ALIGN.vertical}
              >
                <Radio value={0}>
                  <div style={{ fontSize: "14px" }}>
                    Inside Karnataka, India
                  </div>
                </Radio>
                <Radio value={1}>
                  <div style={{ fontSize: "14px" }}>
                    Outside Karnataka, India
                  </div>
                </Radio>
              </RadioGroup>
            </FormControl>
            <FormControl
              label={
                <Label appendText={"For GST billing"}>
                  {"Enter billing name"}
                </Label>
              }
              caption={<EmptyCaption />}
              error={this.state.errors["billingName"]}
            >
              <Input
                id={"billingName"}
                onChange={(e) =>
                  this.updateFormValues("billingName", e.target.value)
                }
                value={this.state.values["billingName"]}
                autoComplete={"no"}
              />
            </FormControl>
            <FormControl
              label={
                <Label appendText={"For GST billing"}>
                  {"Enter billing address"}
                </Label>
              }
              error={this.state.errors["billingAddress"]}
            >
              <TextArea
                id={"billingAddress"}
                resize
                height={"100px"}
                onChange={(e) => {
                  if (typeof e.target.value !== "undefined") {
                    this.updateFormValues(
                      "billingAddress",
                      e.target.value.substring(0, 400)
                    );
                  }
                }}
                value={this.state.values["billingAddress"]}
                autoComplete={"off"}
              />
            </FormControl>
            <Button isLoading={this.state.isLoading} type={"submit"}>
              Save and Continue
            </Button>
          </form>
        </div>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(EmployerGST));
