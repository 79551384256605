import api from "./index";
import { baseUrl } from "./endpoints";

export async function createPublicJobApplication(data) {
  return await api({
    baseUrl,
    url: "/api/public/job/application/create",
    method: "POST",
    data,
  });
}

export async function getPublicJobApplications(status, page, limit) {
  return await api({
    baseUrl,
    url: "/api/public/job/applications",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
      { name: "status", value: status },
    ],
  });
}

export async function updatePublicJobApplications(id, status) {
  return await api({
    baseUrl,
    url: "/api/public/job/application/" + id + "/" + status,
    method: "POST",
  });
}
