import React from "react";
import { connect } from "react-redux";
import { setUser, signoutUser } from "../redux/actions/user";
import { setWidgetState } from "../redux/actions/widget";
import { withRouter } from "react-router";
import { styled } from "baseui";
import FlexRow from "./FlexRow";
import Button from "./Button";
import firebase from "firebase";
import { KIND, SIZE } from "baseui/button";
import logo from "../icons/logo.svg";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { Link } from "react-router-dom";
import UserSection from "./UserSection";
import {
  CANDIDATE_PROFILE_BASIC,
  EMPLOYER_PROFILE_COMPANY,
  TALENTSCOUT_PROFILE_DETAILS,
} from "../util/routes";
import { ParagraphSmall } from "baseui/typography";
import { Icon } from "./Icon";
import { Block } from "baseui/block";

const Wrapper = styled("div", ({ $theme }) => ({
  position: "fixed",
  width: "100%",
  top: 0,
  left: 0,
  // background: "#FFFFFF",
  boxSizing: "border-box",
  // boxShadow: "0px 3px 6px -5px rgb(1 0 0 / 50%)",
  webkitBackdropFilter: "blur(20px) saturate(200%)",
  backdropFilter: "blur(20px) saturate(200%)",
  boxShadow: "0 0 20px rgb(0 0 0 / 8%)",
  background: "rgba(255,255,255,.8)",
}));

const Logo = styled("img", {
  height: "50px",
  width: "120px",
  position: "relative",
});

const LogoWrapper = styled("div", {
  // paddingLeft: "50px",
  // position: "relative",
  // width: "300px",
  // left: "-312px",
  // display: "flex",
  // justifyContent: "center",
  // "@media screen and (max-width: 1360px)": {
  //   left: "0px",
  // },
});

const MenuWrapper = styled("div", {
  display: "flex",
  paddingTop: "8px",
  paddingBottom: "8px",
  paddingLeft: "12px",
  paddingRight: "12px",
  // justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  ":hover": {
    background: "#F5F5F5",
  },
});

const SidebarMenu = styled("div", {
  display: "none",
  "@media screen and (max-width: 880px)": {
    display: "flex",
  },
});

const getProfileLink = (role) => {
  switch (role) {
    case "CANDIDATE":
      return CANDIDATE_PROFILE_BASIC;
    case "EMPLOYER":
      return EMPLOYER_PROFILE_COMPANY;
    case "TALENTSCOUT":
      return TALENTSCOUT_PROFILE_DETAILS;
    default:
      return CANDIDATE_PROFILE_BASIC;
  }
};

const Header = (props) => {
  // if (props.notLoggedIn) {
  //   return (
  //     <div
  //       style={{
  //         position: "fixed",
  //         top: 0,
  //         right: 0,
  //         left: 0,
  //         boxSizing: "border-box",
  //         background: "#eff2f5",
  //       }}
  //     >
  //       <div style={{ width: props.headerWidth || "160px", margin: "auto" }}>
  //         <Link to={"/"} style={{ display: "flex" }}>
  //           <img
  //             src={logoDark}
  //             alt={"Finding Jo"}
  //             height={"80px"}
  //             width={"160px"}
  //           />
  //         </Link>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <Wrapper>
      <Block
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingLeft={["10px", "50px", "50px"]}
        paddingRight={["10px", "50px", "50px"]}
      >
        <React.Fragment>
          <SidebarMenu>
            {props.isSidebarVisible ? (
              <Block $paddingLeft={"scale400"}>
                <Icon
                  onClick={() =>
                    props.setWidgetState({
                      isSidebarVisible: false,
                    })
                  }
                  name="x"
                />
              </Block>
            ) : (
              <Block $paddingLeft={"scale400"}>
                <Icon
                  onClick={() =>
                    props.setWidgetState({
                      isSidebarVisible: true,
                    })
                  }
                  name="menu"
                />
              </Block>
            )}
          </SidebarMenu>
          <LogoWrapper>
            <Link to={"/"} style={{ display: "flex" }}>
              <Logo src={logo} alt={"Finding Jo"} />
            </Link>
          </LogoWrapper>
          <FlexRow>
            {/* {props.showTutorial && !isMobile ? (
              <Block
                display={["none", "flex", "flex"]}
                onClick={() => {
                  props.setWidgetState({
                    isTutorialModelVisible: true,
                  });
                }}
              >
                <ParagraphSmall>How FindingJo Works?</ParagraphSmall>
              </Block>
            ) : null} */}

            {!props.notLoggedIn && (
              <StatefulPopover
                returnFocus
                autoFocus
                placement={PLACEMENT["bottomRight"]}
                overrides={{
                  Inner: {
                    style: ({ $theme }) => {
                      return {
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                        borderBottomRightRadius: "12px",
                        borderBottomLeftRadius: "12px",
                        backgroundColor: "#FFFFFF",
                      };
                    },
                  },
                  Body: {
                    style: ({ $theme }) => {
                      return {
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                        borderBottomRightRadius: "12px",
                        borderBottomLeftRadius: "12px",
                      };
                    },
                  },
                }}
                content={({ close }) => (
                  <div style={{ minWidth: "300px", padding: "8px" }}>
                    <MenuWrapper
                      onClick={() => {
                        close();
                        props.history.push(getProfileLink(props.user.role));
                      }}
                    >
                      <Block $marginRight={"scale400"}>
                        <Icon name="edit" size={16}></Icon>
                      </Block>
                      <ParagraphSmall>Edit Profile</ParagraphSmall>
                    </MenuWrapper>
                    <MenuWrapper
                      onClick={() => {
                        close();
                        props.history.push("/user/role");
                      }}
                    >
                      <Block $marginRight={"scale400"}>
                        <Icon name="user" size={16}></Icon>
                      </Block>
                      <ParagraphSmall>Switch Role</ParagraphSmall>
                    </MenuWrapper>
                    <MenuWrapper
                      onClick={() => {
                        close();
                        props.setWidgetState({
                          isChangePictureModelVisible: true,
                        });
                      }}
                    >
                      <Block $marginRight={"scale400"}>
                        <Icon name="image" size={16}></Icon>
                      </Block>
                      <ParagraphSmall>Change Picture</ParagraphSmall>
                    </MenuWrapper>
                    <MenuWrapper
                      onClick={async () => {
                        try {
                          close();
                          props.signoutUser();
                          await firebase.auth().signOut();
                        } catch (e) {
                          console.log(e);
                        }
                      }}
                    >
                      <Block $marginRight={"scale400"}>
                        <Icon name="log-out" size={16}></Icon>
                      </Block>
                      <ParagraphSmall>Sign Out</ParagraphSmall>
                    </MenuWrapper>
                  </div>
                )}
              >
                <Button kind={KIND.minimal} size={SIZE.mini}>
                  <UserSection user={props.user} />
                </Button>
              </StatefulPopover>
            )}
            {props.showRegister && props.notLoggedIn && (
              <Button
                onClick={() => {
                  props.history.push("/register");
                }}
              >
                Register
              </Button>
            )}
          </FlexRow>
        </React.Fragment>
      </Block>
    </Wrapper>
  );
};

export default connect(
  (state) => ({
    user: state.user,
    isSidebarVisible: state.widget.isSidebarVisible,
    isTutorialModelVisible: state.widget.isTutorialModelVisible,
  }),
  { setUser, signoutUser, setWidgetState }
)(withRouter(Header));
