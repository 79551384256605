import { SET_WIDGET_STATE } from "../actionTypes";

export const setWidgetState = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_WIDGET_STATE,
      payload: {
        ...data,
      },
    });
  };
};
