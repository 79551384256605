import React, { useEffect, useRef, useState } from "react";
import { styled, useStyletron, withStyle } from "baseui";
import PageHeader from "../../components/Header";
import { setUser } from "../../redux/actions/user";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as Analytics from "../../analytics";
import LoadingScreen from "../../components/LoadingScreen";
import Title from "../../components/Title";
import Button from "../../components/Button";
import Card from "../../components/Card";
import { withRouter } from "react-router-dom";
import { getJobById } from "../../api/job";
import { createPublicJobApplication } from "../../api/public_job_applications";
import Bold from "../../components/Bold";
import FormControl from "../../components/FormControl";
import Input from "../../components/Input";
import PhoneInput from "../../components/PhoneInput";
import Select from "../../components/Select";
import logoDark from "../../icons/logo.svg";
import { Block } from "baseui/block";
import {
  LabelLarge,
  LabelMedium,
  LabelSmall,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import Label from "../../components/Label";
import EmptyCaption from "../../components/EmptyCaption";
import { searchEntity } from "../../api/entity";
import { toaster } from "baseui/toast";
import FileUploader from "../../components/FileUploader";
import { Icon } from "../../components/Icon";
import { validateEmail } from "../../util/validator";

const Container = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "1080px",
  margin: "auto",
  marginTop: "80px",
});

const Flex = styled("div", {
  flex: 1,
});

const RightBar = styled("div", {
  width: "300px",
  marginTop: "75px",
  marginLeft: "32px",
});

const LogoImage = styled("img", {
  marginLeft: "-4px",
});

const ImageSubTitle = withStyle(Bold, {
  marginTop: "-20px",
  marginBottom: "16px",
  color: "#919191",
});

const PublicApplyJob = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isApplying, setIsApplying] = useState(false);
  const [isApplied, setIsApplied] = useState(false);
  const [job, setJob] = useState({ jobTitle: {}, company: {}, locations: [] });
  const [errors, setErrors] = useState({});
  const [name, setName] = useState("");
  const [location, setLocation] = useState({});
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [phonePrefix, setPhonePrefix] = useState({
    label: "India (भारत)",
    id: "IN",
    dialCode: "+91",
  });
  const [linkedInProfile, setLinkedInProfile] = useState("");
  const [resume, setResume] = useState();
  const fileUploaderRef = useRef();
  const [css, theme] = useStyletron();
  const onLoad = async () => {
    setIsLoading(true);
    Analytics.logEvent("page view public apply job", {});
    try {
      let { jobId } = props.match.params;
      let { job } = await getJobById(jobId);
      setJob(job);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const random = (length = 8) => {
    return Math.random()
      .toString(16)
      .substr(2, length);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsApplying(true);
    let isErrorFound = false;
    const errors = {};
    // if (!email) {
    //   errors["email"] = "Email is required";
    //   isErrorFound = true;
    // }
    // if (!validateEmail(email)) {
    //   errors["email"] = "Email is not valid";
    //   isErrorFound = true;
    // }
    // if (!name) {
    //   errors["name"] = "Name is required";
    //   isErrorFound = true;
    // }
    if (!resume) {
      errors["resume"] = "Resume is required";
      isErrorFound = true;
    }
    // if (!phone) {
    //   errors["phone"] = "Phone is required";
    //   isErrorFound = true;
    // }
    setErrors(errors);
    if (isErrorFound) {
      return;
    }
    try {
      await createPublicJobApplication({
        jobId: job.id,
        name,
        email,
        phone,
        phonePrefix,
        resume,
        linkedInProfile,
        location,
      });
      setIsApplied(true);
    } catch (e) {}
    Analytics.logEvent("submit public apply job", {});
    setIsApplying(false);
  };

  useEffect(() => {
    Analytics.logEvent("page view public apply job", {
      jobId: job.id,
      jobTitle: job.jobTitle.name,
      company: job.company.name,
    });
    onLoad();
  }, [job.id, job.jobTitle.name, job.company.name]);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Job Description</title>
      </Helmet>
      <Container>
        <Flex>
          <Card
            maxWidth="480px"
            minHeight="480px"
            marginBottom="scale800"
            backgroundColor="#fff8f1"
          >
            <Block marginBottom="scale800">
              <ParagraphSmall>Apply For</ParagraphSmall>
              <Title>{job.jobTitle.name}</Title>
              <LabelSmall>{job.company.name}</LabelSmall>
              <ParagraphSmall>
                {job.locations.map((loc) => loc.name).join(",")}
              </ParagraphSmall>
            </Block>
            {isApplied ? (
              <Block>
                <Block
                  marginBottom="100px"
                  marginTop="30px"
                  alignItems="center"
                  display="flex"
                  flexDirection="column"
                >
                  <Block
                    className={css({
                      borderColor: theme.colors.green,
                      borderWidth: 1,
                      borderStyle: "solid",
                      borderRadius: "32px",
                      padding: "12px",
                      marginBottom: "24px",
                    })}
                  >
                    <Icon name="check" size={32} color="green" />
                  </Block>
                  <LabelMedium marginBottom="scale400">
                    Your application has been successfully submitted
                  </LabelMedium>
                  <ParagraphSmall className={css({ textAlign: "center" })}>
                    If shorlisted, our recruiter will reach out to you in
                  </ParagraphSmall>
                  <ParagraphSmall className={css({ textAlign: "center" })}>
                    2 working days
                  </ParagraphSmall>
                </Block>
                <Card padding="scale600">
                  <ParagraphSmall marginBottom="scale800">
                    Want to apply for similar jobs ?
                  </ParagraphSmall>
                  <Button
                    onClick={() => {
                      props.history.push("/register");
                      return;
                    }}
                  >
                    Register on FindingJo
                  </Button>
                </Card>
              </Block>
            ) : (
              <form noValidate onSubmit={handleSubmit} autoComplete="off">
                {/* <FormControl
                  label={<Label>{"Your Full Name"}</Label>}
                  caption={<EmptyCaption />}
                  error={errors["name"]}
                >
                  <Input
                    id={"name"}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    autoComplete={"no"}
                  />
                </FormControl>
                <FormControl
                  label={<Label>{"Your Email Id"}</Label>}
                  caption={<EmptyCaption />}
                  error={errors["email"]}
                >
                  <Input
                    id={"email"}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    autoComplete={"no"}
                  />
                </FormControl>
                <FormControl
                  label={<Label>{"Your Phone Number"}</Label>}
                  caption={<EmptyCaption />}
                  error={errors["phone"] || errors["phoneCountry"]}
                >
                  <PhoneInput
                    autoComplete={"no"}
                    country={phonePrefix}
                    onCountryChange={({ option }) => setPhonePrefix(option)}
                    text={phone}
                    onTextChange={(e) => setPhone(e.target.value)}
                  />
                </FormControl>
                <FormControl
                  label={
                    <Label appendText={<div>Optional</div>}>
                      {"Your LinkedIn Profile Url"}
                    </Label>
                  }
                  caption={<EmptyCaption />}
                  error={errors["linkedInProfile"]}
                >
                  <Input
                    id={"linkedInProfile"}
                    onChange={(e) => setLinkedInProfile(e.target.value)}
                    value={linkedInProfile}
                    autoComplete={"no"}
                  />
                </FormControl>
                <FormControl
                  label={<Label>{"Your Location"}</Label>}
                  caption={<EmptyCaption />}
                  error={errors["location"]}
                >
                  <Select
                    creatable
                    type={"search"}
                    searchable
                    getOptions={async (query) =>
                      searchEntity("location", query)
                    }
                    onChange={(value) => setLocation(value)}
                    value={location}
                  />
                </FormControl> */}
                <Block padding="scale400" />
                <FormControl
                  label={<Label>{"Upload your resume"}</Label>}
                  caption={<EmptyCaption />}
                  error={errors["resume"]}
                >
                  <FileUploader
                    ref={fileUploaderRef}
                    isImmediate
                    uploadUrl={"resumes/" + random() + "_" + Date.now()}
                    onError={(error) => {
                      toaster.negative(<div>{error}</div>, {
                        autoHideDuration: 2000,
                      });
                    }}
                    onSuccess={async (downloadUrl) => {
                      setResume(downloadUrl);
                    }}
                  />
                </FormControl>
                <Block padding="scale400" />
                <Button isLoading={isLoading} type={"submit"}>
                  Apply Now
                </Button>
              </form>
            )}
          </Card>
          {/* <Job job={this.state.job} isAnonymous={true} /> */}
        </Flex>
        <RightBar>
          <div>
            <LogoImage
              src={logoDark}
              alt={"Finding Jo"}
              height={"60px"}
              width={"120px"}
            />
            <ImageSubTitle>The interview booking platform</ImageSubTitle>
          </div>
          <div>
            First get the salary offer from employers. Then start the interview.
            Remain anonymous till you accept the job offer.
          </div>
        </RightBar>
      </Container>
      <PageHeader headerWidth={"1060px"} notLoggedIn />
      <LoadingScreen show={isLoading} />;
    </div>
  );
};
// class PublicApplyJob extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isLoading: false,
//       isFetched: false,
//       job: {},
//       isErrored: false,
//     };
//   }

//   async componentDidMount() {
//     try {
//       this.setState({
//         isLoading: true,
//       });
//       let { jobId } = this.props.match.params;
//       let { job } = await getJobById(jobId);
//       this.setState({ job, isLoading: false, isFetched: true });
//       Analytics.logEvent("page view public job", {
//         jobId: job.id,
//         jobTitle: job.jobTitle.name,
//         company: job.company.name,
//       });
//     } catch (e) {
//       this.setState({
//         isLoading: false,
//         isFetched: true,
//         isErrored: true,
//       });
//     }
//   }

//   render() {
//     if (!this.state.isFetched) {
//       return <div />;
//     }
//     if (this.state.isLoading) {
//       return <LoadingScreen />;
//     }
//     if (this.state.isErrored) {
//       return <ErrorPage />;
//     }
//     return (

//     );
//   }
// }

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(PublicApplyJob));
