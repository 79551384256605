import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import SelectJob from "../components/SelectJob";
import { CandidateInterviewHistory } from "../components/CandidateInterviewHistory";
import { FormControl } from "baseui/form-control";
import Label from "../components/Label";
import { getJobById, getJobsByWorkspace } from "../api/job";
import { getCandidateOffers, updateCandidateStat } from "../api/candidate";
import { createInterviewRequest as createInterviewRequestAPI } from "../api/employer";
import { toaster } from "baseui/toast";
import Button from "../components/Button";
import { SIZE as BUTTON_SIZE, KIND as BUTTON_KIND } from "baseui/button";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { Block } from "baseui/block";
import Modal from "../components/Modal";
import { ModalHeader, ModalBody } from "baseui/modal";
import { ParagraphSmall, LabelSmall } from "baseui/typography";
import { EMPLOYER_POST_JOB } from "../util/routes";
import LoadingScreen from "../components/LoadingScreen";
import Currency from "../components/Currency";
import { handleApiErrorWithAction } from "../util/common";
import * as Analytics from "../analytics";
import Bold from "../components/Bold";
import Card from "../components/Card";
import SalaryInput from "../components/SalaryInput";
import SubTitle from "../components/SubTitle";
import { CenterContainer } from "../components/CenterContainer";
import Pagination from "../components/Pagination";
import { Flex, Text } from "@chakra-ui/react";

class JobList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 10,
      jobs: [],
      hasNext: false,
      hasPrev: false,
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    this.setState({
      isFetching: false,
    });
    try {
      // let jobs = (await getReviewedJobs()) || {};
      let { data: jobs, hasNext } = await getJobsByWorkspace(
        this.props.workspace.id,
        this.state.page,
        this.state.limit
      );
      this.setState({
        jobs,
        hasNext,
      });
    } catch (ex) {
      console.log(ex);
    }
    this.setState({
      isFetching: false,
    });
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  render() {
    return (
      <div>
        {this.state.jobs.length === 0 && (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
            }}
          >
            <div style={{ marginBottom: "8px" }}>
              You do not have any posted jobs.
            </div>
            <Button
              onClick={() => {
                this.props.history.push(EMPLOYER_POST_JOB);
              }}
            >
              Post Job
            </Button>
          </div>
        )}
        {this.state.jobs.map((job, index) => (
          <SelectJob
            job={job}
            onSelect={async () => await this.props.onJobSelect(job)}
            key={index}
          />
        ))}
        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
      </div>
    );
  }
}

class CreateInterviewRequest extends React.Component {
  constructor(props) {
    super(props);
    let { candidateId } = props.match.params;
    this.state = {
      isFetching: true,
      isCreating: false,
      selectedCandidateId: candidateId,
      candidate: null,
      created: false,
      selectedJob: "",
      selectedJobError: "",
      rcAmount: 0,
      rcAmountCurrency: "",
      offeredCTCCurrency: { id: "INR", name: "₹" },
      offeredCTC: 0,
      offeredCTCOption: 1,
      offeredCTCOptions: [],
      offeredCTCError: "",
      fjOffers: {},
      outsideOffers: {},
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[E] page view create interview request");
    this.setState({
      isFetching: true,
    });
    try {
      let {
        offers = [],
        expectedCTC,
        expectedCTCCurrency,
        rcAmount,
        rcAmountCurrency,
      } = (await getCandidateOffers(this.state.selectedCandidateId)) || {};

      const fjOffers = offers.filter((off) => off.source === "FINDINGJO");
      const outsideOffers = offers.filter((off) => off.source === "OUTSIDE");

      fjOffers.sort((a, b) => a.ctc - b.ctc);
      outsideOffers.sort((a, b) => a.ctc - b.ctc);

      const maxOffer = fjOffers
        .concat(outsideOffers)
        .concat([{ ctc: expectedCTC, currency: expectedCTCCurrency }])
        .reduce((prev, current) => {
          if (prev.ctc > current.ctc) {
            return prev;
          }
          return current;
        });

      const recommendedOffer = {
        currency: maxOffer.currency,
        ctc: parseInt(maxOffer.ctc * 1.1),
      };

      const offeredCTCOptions = [];

      offeredCTCOptions.push({
        currency: maxOffer.currency,
        ctc: maxOffer.ctc,
      });

      offeredCTCOptions.push(recommendedOffer);

      this.setState({
        fjOffers: fjOffers,
        outsideOffers: outsideOffers,
        expectedCTC,
        rcAmount,
        rcAmountCurrency,
        expectedCTCCurrency,
        isFetching: false,
        offeredCTCOptions,
      });
    } catch (e) {
      this.setState({
        isFetching: false,
      });
      console.log(e);
    }
    try {
      const params = new URLSearchParams(this.props.location.search);
      const jobId = params.get("jobId");
      if (jobId) {
        const { job } = await getJobById(jobId);
        this.setState({
          selectedJob: job,
        });
      }
    } catch (e) {}
  }

  openJobModel = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  onJobSelect = async (job) => {
    this.setState({
      isModalVisible: false,
      selectedJob: job,
      selectedJobError: "",
    });
  };

  getOfferedCTC = () => {
    switch (this.state.offeredCTCOption) {
      case 0:
      case 1:
        return this.state.offeredCTCOptions[this.state.offeredCTCOption];
      case 2:
        return {
          ctc: this.state.offeredCTC,
          currency: this.state.offeredCTCCurrency.id,
        };
      default:
        this.setState({
          offeredCTCError: "Please select a ctc option",
        });
        return;
    }
  };

  onInterviewRequestCreate = async () => {
    if (!this.state.selectedJob) {
      alert("Please select the job for this interview");
    }
    Analytics.logEvent("[E] click create interview request");

    const offeredCTC = this.getOfferedCTC();
    try {
      this.setState({
        isCreating: true,
      });
      await createInterviewRequestAPI({
        createdByName: this.props.user.name,
        workspaceId: this.props.workspace.id,
        anonymousId: this.state.selectedCandidateId,
        employerId: this.props.user.id,
        jobId: this.state.selectedJob.id,
        offeredCTC: offeredCTC.ctc,
        offeredCTCCurrency: offeredCTC.currency,
      });
      toaster.show(
        <div>
          Interview request sent. Please check your my candidates section for
          more
        </div>,
        {
          autoHideDuration: 3000,
        }
      );
      this.setState({
        isCreating: false,
      });
      updateCandidateStat(
        this.state.selectedCandidateId,
        "buy"
      ).catch(() => {});
      this.props.history.push(
        "/employer/jobapplication/" + this.state.selectedJob.id + "/search"
      );
    } catch (e) {
      this.setState({
        isCreating: false,
      });
      handleApiErrorWithAction(e, this.props.history);
      console.log(e);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.state.selectedJob) {
      this.setState({
        selectedJobError: "Please select the job for this interview request",
      });
      return;
    }
    if (this.state.offeredCTCOption === 2 && !this.state.offeredCTC) {
      this.setState({
        offeredCTCError: "Please enter a minimum ctc for this job request",
      });
      return;
    }
    await this.onInterviewRequestCreate();
  };

  render() {
    if (this.state.isFetching)
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    return (
      <CenterContainer>
        <Title>Create Interview Request</Title>
        {this.state.selectedJob && (
          <CandidateInterviewHistory
            jobId={this.state.selectedJob.id}
            anonymousId={this.state.selectedCandidateId}
          />
        )}
        <Card>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "32px",
            }}
          >
            <div style={{ marginRight: "16px" }}>{"Expected CTC : "}</div>
            <div>
              <Currency
                inWords
                value={this.state.expectedCTC}
                currency={this.state.expectedCTCCurrency}
              />
            </div>
          </div>
          {this.state.fjOffers.length ? (
            <div>
              <Bold style={{ textDecoration: "underline" }}>
                {"FindingJo Offers"}
              </Bold>
              {this.state.fjOffers.map((off, idx) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={"idx"}
                >
                  <div style={{ color: "#919191" }}>{off.company}</div>
                  <Currency inWords value={off.ctc} currency={off.currency} />
                </div>
              ))}
            </div>
          ) : null}
          {this.state.outsideOffers.length ? (
            <div>
              <Bold style={{ textDecoration: "underline" }}>
                {"External Offers"}
              </Bold>
              {this.state.outsideOffers.map((off, idx) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  key={"idx"}
                >
                  <div style={{ color: "#919191" }}>{off.company}</div>
                  <Currency inWords value={off.ctc} currency={off.currency} />
                </div>
              ))}
            </div>
          ) : null}
        </Card>
        <div style={{ height: "32px" }}></div>
        <Card>
          <div
            style={{
              display: "inline-block",
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingTop: "8px",
              paddingBottom: "8px",
              border: "1px solid #e1e1e1",
              borderRadius: "4px",
              marginBottom: "16px",
            }}
          >
            <SubTitle>Job for this Interview</SubTitle>
            {this.state.selectedJob ? (
              <div>
                <div>
                  <Bold>{this.state.selectedJob.jobTitle.name}</Bold>
                  <div>
                    <div>{this.state.selectedJob.company.name}</div>
                    {this.state.selectedJob.locations.map((loc, idx) => (
                      <span
                        key={idx}
                        style={{
                          color: "#999",
                          margin: "0px 2px",
                          textTransform: "capitalize",
                        }}
                      >
                        {loc.name}
                      </span>
                    ))}
                  </div>
                  <div>{"ID: " + this.state.selectedJob.jobInternalId}</div>
                </div>
                <Button
                  size={BUTTON_SIZE.mini}
                  kind={BUTTON_KIND.tertiary}
                  onClick={this.openJobModel}
                >
                  Change Job
                </Button>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    marginBottom: "8px",
                  }}
                >
                  {"Select the job which you interviewing for"}
                </div>
                <Button
                  size={BUTTON_SIZE.mini}
                  kind={BUTTON_KIND.tertiary}
                  onClick={this.openJobModel}
                >
                  Select Job
                </Button>
              </div>
            )}
          </div>
          {this.state.selectedJobError ? (
            <div style={{ color: "#F8756C" }}>
              {this.state.selectedJobError}
            </div>
          ) : null}
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <FormControl
              label={
                <Label>{"Enter CTC(Fixed + Variable) you are offering"}</Label>
              }
              caption={<div>{"required"}</div>}
              error={this.state.offeredCTCError}
            >
              <RadioGroup
                value={this.state.offeredCTCOption}
                onChange={(e) => {
                  this.setState({ offeredCTCOption: +e.target.value });
                }}
                align={ALIGN.vertical}
              >
                <Radio value={0}>
                  <Block display="flex" alignItems="center">
                    <Currency
                      inWords
                      value={this.state.offeredCTCOptions[0].ctc}
                      currency={this.state.offeredCTCOptions[0].currency}
                    />
                    <LabelSmall marginLeft="scale200">Per Year</LabelSmall>
                    <LabelSmall marginLeft="scale200" color="grey">
                      (Match Best Offer)
                    </LabelSmall>
                  </Block>
                </Radio>
                <Radio value={1}>
                  <Block display="flex" alignItems="center">
                    <Currency
                      inWords
                      value={this.state.offeredCTCOptions[1].ctc}
                      currency={this.state.offeredCTCOptions[1].currency}
                    />
                    <LabelSmall marginLeft="scale200">Per Year</LabelSmall>
                    <Block
                      backgroundColor="#8989ec"
                      color="white"
                      borderRadius={16}
                      padding="scale200"
                      marginLeft="scale400"
                    >
                      <ParagraphSmall color="white">Recommended</ParagraphSmall>
                    </Block>
                    <LabelSmall marginLeft="scale200" color="grey">
                      (Best Offer + 10%)
                    </LabelSmall>
                  </Block>
                </Radio>
                <Radio value={2}>
                  <SalaryInput
                    name={"offeredCTC"}
                    onChange={(val) =>
                      this.setState({ offeredCTC: val, offeredCTCOption: 2 })
                    }
                    onCurrencyChange={(val) =>
                      this.setState({ offeredCTCCurrency: val })
                    }
                    value={this.state.offeredCTC}
                    currencyValue={this.state.offeredCTCCurrency}
                  />
                </Radio>
              </RadioGroup>
            </FormControl>
            <Flex alignItems={"center"}>
              <Button isLoading={this.state.isCreating}>
                Create Interview Request
              </Button>
              <Text ml={4}>
                <Currency
                  currency={this.state.rcAmountCurrency}
                  value={this.state.rcAmount / 100}
                ></Currency>
              </Text>
            </Flex>
          </form>
        </Card>
        <Modal
          animate
          autoFocus
          isOpen={this.state.isModalVisible}
          closeable
          onClose={() => this.setState({ isModalVisible: false })}
        >
          <ModalHeader>Select the job</ModalHeader>
          <ModalBody>
            <JobList
              workspace={this.props.workspace}
              history={this.props.history}
              onJobSelect={this.onJobSelect}
            />
          </ModalBody>
        </Modal>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
    workspace: state.workspace,
  }),
  { setUser }
)(withRouter(CreateInterviewRequest));
