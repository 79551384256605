import React, { ReactNode, useEffect, useState } from "react";
import { ReferredCandidateProps } from "src/api/referred_candidate";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";

export type UpdateModalProps = {
  isOpen: boolean;
  onClose: () => void;
  render: (
    values: Partial<ReferredCandidateProps>,
    onChange: (values: Partial<ReferredCandidateProps>) => void
  ) => ReactNode;
  onSaveClick: (values: Partial<ReferredCandidateProps>) => Promise<boolean>;
  referredCandidate: ReferredCandidateProps;
};

export const UpdateModal = ({
  referredCandidate,
  isOpen,
  onClose,
  render,
  onSaveClick,
}: UpdateModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [values, setValues] = useState(referredCandidate);
  const modalSize = useBreakpointValue({ base: "full", md: "xl" });
  useEffect(() => {
    setValues(referredCandidate);
  }, [referredCandidate]);
  const onChange = (newValues: Partial<ReferredCandidateProps>) => {
    console.log("updated values", { ...values, ...newValues });
    setValues({ ...values, ...newValues });
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={modalSize}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{render(values, onChange)}</ModalBody>
        <ModalFooter marginBottom={{ base: "90px", md: "0px" }}>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              const saved = await onSaveClick(values);
              setIsLoading(false);
              if (saved) {
                await onClose();
              }
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
