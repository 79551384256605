import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import { getJobById } from "src/api/job";
import { ReferredCandidateProps } from "src/api/referred_candidate";

export type JobDetailsTabProps = {
  candidate: ReferredCandidateProps;
  onUpdated: (values: ReferredCandidateProps) => Promise<void>;
};

export const JobDetailsTab = ({ candidate, onUpdated }: JobDetailsTabProps) => {
  const [job, setJob] = useState<any>();
  const fetchJob = useCallback(async () => {
    try {
      const { job: data } = await getJobById(candidate.jobId);
      setJob(data);
    } catch (e) {
      console.log(e);
    }
  }, [candidate.jobId]);
  useEffect(() => {
    if (candidate.jobId) fetchJob();
  }, [candidate.jobId, fetchJob]);
  return (
    <Box pb={10}>
      <Box width="400px">
        <Flex justifyContent={"space-between"} mb={4}>
          <Text>Job Title: </Text>
          <Text textStyle={"label"}>{job?.jobTitle?.name}</Text>
        </Flex>
        <Flex justifyContent={"space-between"} mb={4}>
          <Text>Company: </Text>
          <Text textStyle={"label"}>{job?.company?.name}</Text>
        </Flex>
        <Flex justifyContent={"space-between"} mb={4}>
          <Text>Salary: </Text>
          <Text
            textStyle={"label"}
          >{`${job?.minimumSalary} - ${job?.maximumSalary}`}</Text>
        </Flex>
        <Flex justifyContent={"space-between"} mb={4}>
          <Text>Skills: </Text>
          <Text textStyle={"label"}>
            {job?.skills.map((sk: any) => sk.name).join(",")}
          </Text>
        </Flex>
        <Flex justifyContent={"space-between"} mb={4}>
          <Text>Locations: </Text>
          <Text textStyle={"label"}>
            {job?.locations.map((lc: any) => lc.name).join(",")}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};
