import React, { useEffect } from "react";
import {
  Route,
  useRouteMatch,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import PageHeader from "../components/Header";
import ChangePictureModel from "./ChangePictureModel";
import TutorialModel from "./TutorialModel";
import ShareCandidateModel from "./ShareCandidateModel";
import ShareJobModel from "./ShareJobModel";
import CandidateDashboard from "./CandidateDashboard";
import CandidateTalentscoutRequests from "./CandidateTalentscoutRequests";
import CandidateInterviewRequests from "./CandidateInterviewRequests";
import CandidateFindJobs from "./CandidateFindJobs";
import CandidateAppliedJobs from "./CandidateAppliedJobs";
import SidebarCandidateHome from "../components/SidebarCandidateHome";
import SidebarCandidateProfile from "../components/SidebarCandidateProfile";
import CandidateProfileBasic from "./CandidateProfileBasic";
import CandidateProfileExperience from "./CandidateProfileExperience";
import CandidateProfileEducation from "./CandidateProfileEducation";
import CandidateProfileNoticePeriod from "./CandidateProfileNoticePeriod";
import CandidateProfileProjects from "./CandidateProfileProjects";
import CandidateProfileProfile from "./CandidateProfileProfile";
import CandidateProfilePremium from "./CandidateProfilePremium";
import CandidateProfileVerifications from "./CandidateProfileVerifications";
import CandidateProfileSummary from "./CandidateProfileSummary";
import CandidateInterviewDetails from "./CandidateInterviewDetails";
import CandidateJobDetails from "./CandidateJobDetails";
import SidebarWrapper from "../components/SidebarWrapper";
import CandidateProfileExpectations from "./CandidateProfileExpectations";
import PageWrapper from "../components/PageWrapper";
import Container from "../components/Container";
import SidebarDetails from "../components/SidebarDetails";
import { connect } from "react-redux";
import { setWidgetState } from "../redux/actions/widget";
import { getHomeRedirect } from "../util/common";
import { Details } from "src/pages/candidate/referred-candidate/Details";
import { Container as ChContainer } from "@chakra-ui/react";
import { JobMatches } from "src/pages/candidate/referred-candidate/JobMatches";
import { JobDescription } from "src/pages/candidate/referred-candidate/JobDescription";
import { Confirmation } from "src/pages/candidate/referred-candidate/Confirmation";

const SidebarHome = ({ page }) => {
  return (
    <SidebarWrapper>
      <SidebarCandidateHome page={page} />
    </SidebarWrapper>
  );
};

const SidebarProfile = ({ page }) => {
  return (
    <SidebarWrapper>
      <SidebarCandidateProfile page={page} />
    </SidebarWrapper>
  );
};

const SidebarWithBackButton = () => {
  return (
    <SidebarWrapper>
      <SidebarDetails />
    </SidebarWrapper>
  );
};

const CandidateHomeRoute = connect(
  (state) => ({
    user: state.user,
  }),
  { setWidgetState }
)(({ user, setWidgetState }) => {
  let { path } = useRouteMatch();
  // if (user && !user.candidateProfile) {
  //   return <Redirect to={getProfileRedirect(user)} />;
  // }

  const checkTutorialToBeShown = () => {
    let isTutorialShown = localStorage.getItem(
      user.id + "." + user.role + ".tutorialShown"
    );
    if (!isTutorialShown) {
      setWidgetState({ isTutorialModelVisible: true });
      localStorage.setItem(user.id + "." + user.role + ".tutorialShown", true);
    }
  };

  useEffect(() => {
    checkTutorialToBeShown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Switch>
        <Route path={`${path}/dashboard`}>
          <PageWrapper>
            <SidebarHome page={"dashboard"} />
            <CandidateDashboard />
          </PageWrapper>
        </Route>
        <Route path={`${path}/talentscoutrequests`}>
          <PageWrapper>
            <SidebarHome page={"talentscoutrequests"} />
            <CandidateTalentscoutRequests />
          </PageWrapper>
        </Route>
        <Route path={`${path}/interviewrequests`}>
          <PageWrapper>
            <SidebarHome page={"interviewrequests"} />
            <CandidateInterviewRequests />
          </PageWrapper>
        </Route>
        <Route path={`${path}/findjobs`}>
          <PageWrapper>
            <SidebarHome page={"findjobs"} />
            <CandidateFindJobs />
          </PageWrapper>
        </Route>
        <Route path={`${path}/appliedjobs`}>
          <PageWrapper>
            <SidebarHome page={"appliedjobs"} />
            <CandidateAppliedJobs />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
});

const CandidateProfileRoute = ({ user }) => {
  let { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${path}/basic`}>
          <PageWrapper>
            <SidebarProfile page={"basic"} />
            <CandidateProfileBasic />
          </PageWrapper>
        </Route>
        <Route path={`${path}/experience`}>
          <PageWrapper>
            <SidebarProfile page={"experience"} />
            <CandidateProfileExperience />
          </PageWrapper>
        </Route>
        <Route path={`${path}/education`}>
          <PageWrapper>
            <SidebarProfile page={"education"} />
            <CandidateProfileEducation />
          </PageWrapper>
        </Route>
        <Route path={`${path}/notice-period`}>
          <PageWrapper>
            <SidebarProfile page={"notice-period"} />
            <CandidateProfileNoticePeriod />
          </PageWrapper>
        </Route>
        <Route path={`${path}/profile`}>
          <PageWrapper>
            <SidebarProfile page={"profile"} />
            <CandidateProfileProfile />
          </PageWrapper>
        </Route>
        <Route path={`${path}/projects`}>
          <PageWrapper>
            <SidebarProfile page={"projects"} />
            <CandidateProfileProjects />
          </PageWrapper>
        </Route>
        <Route path={`${path}/salary`}>
          <PageWrapper>
            <SidebarProfile page={"salary"} />
            <CandidateProfileExpectations />
          </PageWrapper>
        </Route>
        <Route path={`${path}/premium`}>
          <PageWrapper>
            <SidebarProfile page={"premium"} />
            <CandidateProfilePremium />
          </PageWrapper>
        </Route>
        <Route path={`${path}/verifications`}>
          <PageWrapper>
            <SidebarProfile page={"verifications"} />
            <CandidateProfileVerifications />
          </PageWrapper>
        </Route>
        <Route path={`${path}/summary`}>
          <PageWrapper>
            <SidebarProfile page={"summary"} />
            <CandidateProfileSummary />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const CandidateInterviewsRoute = ({ user }) => {
  let { path } = useRouteMatch();
  // if (user && !user.candidateProfile) {
  //   return <Redirect to={getProfileRedirect(user)} />;
  // }

  return (
    <div>
      <Switch>
        <Route path={`${path}/:interviewRequestId/details`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <CandidateInterviewDetails />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const CandidateJobsRoute = ({ user }) => {
  let { path } = useRouteMatch();
  // if (user && !user.candidateProfile) {
  //   return <Redirect to={getProfileRedirect(user)} />;
  // }

  return (
    <div>
      <Switch>
        <Route path={`${path}/:jobId/details`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <CandidateJobDetails />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const CandidateAppliedJobsRoute = ({ user }) => {
  let { path } = useRouteMatch();
  // if (user && !user.candidateProfile) {
  //   return <Redirect to={getProfileRedirect(user)} />;
  // }

  return (
    <div>
      <Switch>
        <Route path={`${path}/:jobId/details`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <CandidateJobDetails />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const CandidateVerificationRoute = ({ user }) => {
  let { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${path}/details`}>
          <ChContainer maxW="6xl" mt="60px" minH="100vh" background={"white"}>
            <Details />
          </ChContainer>
        </Route>
        <Route path={`${path}/matches`}>
          <ChContainer maxW="6xl" mt="60px" minH="100vh" background={"white"}>
            <JobMatches />
          </ChContainer>
        </Route>
        <Route path={`${path}/jobdescription`}>
          <ChContainer maxW="6xl" mt="60px" minH="100vh" background={"white"}>
            <JobDescription />
          </ChContainer>
        </Route>
        <Route path={`${path}/confirmation`}>
          <ChContainer maxW="6xl" mt="60px" minH="100vh" background={"white"}>
            <Confirmation />
          </ChContainer>
        </Route>
      </Switch>
    </div>
  );
};

const CandidateRoute = ({ user }) => {
  let { path } = useRouteMatch();
  const location = useLocation();
  let params = new URLSearchParams(location?.search);
  if (params.get("redirect")) {
    return <Redirect to={params.get("redirect")} />;
  }
  if (user && user.role !== "CANDIDATE") {
    return <Redirect to={getHomeRedirect(user)} />;
  }
  return (
    <Container>
      <Switch>
        <Route path={`${path}/profile`}>
          <CandidateProfileRoute user={user} />
        </Route>
        <Route path={`${path}/home`}>
          <CandidateHomeRoute user={user} />
        </Route>
        <Route path={`${path}/interviews`}>
          <CandidateInterviewsRoute user={user} />
        </Route>
        <Route path={`${path}/jobs`}>
          <CandidateJobsRoute user={user} />
        </Route>
        <Route path={`${path}/appliedjobs`}>
          <CandidateAppliedJobsRoute user={user} />
        </Route>
        <Route path={`${path}/verification`}>
          <CandidateVerificationRoute user={user} />
        </Route>
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      <PageHeader showTutorial />
      <ChangePictureModel />
      <TutorialModel />
      <ShareCandidateModel />
      <ShareJobModel />
    </Container>
  );
};

export default CandidateRoute;
