import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import { getEmployer, submitEmployer } from "../api/employer";
import { sendCode, verifyCode } from "../api/otp";
import { getUser } from "../api/user";
import Button from "../components/Button";
import Verify from "../components/Verify";
import { toaster } from "baseui/toast";
import { EMPLOYER_PROFILE_DETAILS, EMPLOYER_POST_JOB } from "../util/routes";
import Title from "../components/Title";
import * as Analytics from "../analytics";
import { CenterContainer } from "../components/CenterContainer";

class UserReviewDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      employer: {},
      phoneVerified: false,
      // workEmailVerified: false,
      // isResendingWorkEmail: false,
      isResendingPhone: false,
      // isVerifyingWorkEmail: false,
      isVerifyingPhone: false,
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[E] page view profile review ");
    this.setState({
      isFetching: true,
    });
    console.log("this.props.user", this.props.user);
    try {
      if (
        !this.props.user.phonePrefix ||
        !this.props.user.phonePrefix.dialCode
      ) {
        throw new Error("No dial code found");
      }
      let employer = (await getEmployer(this.props.user.id)) || {};
      this.setState({
        employer,
        phoneVerified: this.props.user.phoneVerified,
        // workEmailVerified: employer.workEmailVerified,
      });
      this.sendOTPs(
        employer.workEmail,
        this.props.user.phonePrefix.dialCode + this.props.user.phone
      );
    } catch (ex) {
      console.log(ex);
    }
    this.setState({
      isFetching: false,
    });
  }

  // async verifyWorkEmailOTP(code) {
  //   this.setState({
  //     isVerifyingWorkEmail: true,
  //   });
  //   try {
  //     await verifyCode({
  //       sendType: "workemail",
  //       sendId: this.state.employer.workEmail,
  //       code: code,
  //     });
  //     this.setState({
  //       workEmailVerified: true,
  //     });
  //   } catch (e) { }
  //   this.setState({
  //     isVerifyingWorkEmail: false,
  //   });
  // }

  async verifyPhoneOTP(code) {
    this.setState({
      isVerifyingPhone: true,
    });
    try {
      if (
        !this.props.user.phonePrefix ||
        !this.props.user.phonePrefix.dialCode
      ) {
        throw new Error("No dial code found");
      }
      await verifyCode({
        sendType: "phone",
        sendId: this.props.user.phonePrefix.dialCode + this.props.user.phone,
        code: code,
      });
      this.setState({
        phoneVerified: true,
      });
    } catch (e) {}
    this.setState({
      isVerifyingPhone: false,
    });
  }

  // async sendWorkEmailOTP(workEmail) {
  //   this.setState({
  //     isResendingWorkEmail: true,
  //   });
  //   try {
  //     await sendCode({
  //       sendType: "workemail",
  //       sendId: workEmail,
  //     });
  //   } catch (e) { }
  //   this.setState({
  //     isResendingWorkEmail: false,
  //   });
  // }

  async sendPhoneOTP(phone) {
    this.setState({
      isResendingPhone: true,
    });
    try {
      await sendCode({
        sendType: "phone",
        sendId: phone,
      });
    } catch (e) {}
    this.setState({
      isResendingPhone: false,
    });
  }

  async sendOTPs(workEmail, phone) {
    // if (
    //   this.state.employer.hiringFor === 1 &&
    //   !this.state.workEmailVerified &&
    //   workEmail
    // ) {
    //   await this.sendWorkEmailOTP(workEmail);
    // }
    if (!this.state.phoneVerified && phone) {
      await this.sendPhoneOTP(phone);
    }
  }

  goToDashboard = async () => {
    if (
      !this.state.phoneVerified
      // ||
      // (this.state.employer.hiringFor === 1 && !this.state.workEmailVerified)
    ) {
      toaster.info(<div>Please complete the verification to proceed</div>, {
        autoHideDuration: 3000,
        onClose: () => console.log("Toast closed."),
        overrides: {
          InnerContainer: {
            style: { width: "100%" },
          },
        },
      });
      return;
    }

    try {
      this.setState({
        loading: true,
      });
      await submitEmployer({
        userId: this.props.user.id,
      });
      Analytics.logEvent("[E] submit employer profile", {
        isFirstSubmission: !this.props.user.employerProfile,
      });
      this.setState({
        loading: false,
      });
    } catch (e) {
      this.setState({
        loading: false,
      });
      console.log(e);
      return;
    }
    let user = await getUser(this.props.user.id);
    this.props.setUser({
      employerProfile: user.employerProfile,
    });
    toaster.positive(<div>Going to employer dashboard</div>, {
      autoHideDuration: 3000,
      onClose: () => console.log("Toast closed."),
    });
    this.props.history.push(EMPLOYER_POST_JOB);
  };

  buttonEnabled = () => {
    // let isWorkEmailVerified = true;
    // if (this.state.employer.hiringFor === 1 && !this.state.workEmailVerified) {
    //   isWorkEmailVerified = false;
    // }
    return this.state.phoneVerified; // && isWorkEmailVerified;
  };

  render() {
    if (this.state.isFetching) {
      return <div>loading...</div>;
    }
    return (
      <CenterContainer>
        <Title>Verify Details</Title>
        <div style={{ marginBottom: 16 }}>
          <div>
            <Verify
              editUrl={EMPLOYER_PROFILE_DETAILS}
              sendType={"phone"}
              displaySendId={
                this.props.user.phonePrefix.dialCode +
                " " +
                this.props.user.phone
              }
              sendId={
                this.props.user.phonePrefix.dialCode + this.props.user.phone
              }
              isVerified={this.state.phoneVerified}
              isVerifying={this.state.isVerifyingPhone}
              isResending={this.state.isResendingPhone}
              resendCode={async () => {
                await this.sendPhoneOTP(
                  this.props.user.phonePrefix.dialCode + this.props.user.phone
                );
              }}
              verifyCode={async (code) => {
                await this.verifyPhoneOTP(code);
              }}
            />
            <div style={{ height: 32 }}></div>
            {/* {this.state.employer.hiringFor === 1 ? (
              <Verify
                sendType={"workemail"}
                editUrl={EMPLOYER_PROFILE_DETAILS}
                displaySendId={this.state.employer.workEmail}
                sendId={this.state.employer.workEmail}
                isVerified={this.state.workEmailVerified}
                isVerifying={this.state.isVerifyingWorkEmail}
                isResending={this.state.isResendingWorkEmail}
                resendCode={async () => {
                  await this.sendWorkEmailOTP(this.state.employer.workEmail);
                }}
                verifyCode={async (code) => {
                  await this.verifyWorkEmailOTP(code);
                }}
              />
            ) : null} */}
          </div>
        </div>
        <Button
          isLoading={this.state.loading}
          onClick={this.goToDashboard}
          buttonStyle={{ opacity: this.buttonEnabled() ? 1 : 0.3 }}
        >
          Go to Dashboard
        </Button>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(UserReviewDetails));
