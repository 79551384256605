import React, { ReactElement, useCallback, useEffect, useState } from "react";
import firebase from "firebase";
import { otpAuth } from "src/api/auth";
import {
  Box,
  Button,
  Container,
  FormControl,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  Collapse,
  PinInput,
  PinInputField,
  useToast,
  Flex,
  IconButton,
  Spinner,
  Switch,
  SkeletonText,
  Link,
} from "@chakra-ui/react";
import logo from "src/icons/logo.svg";
import * as Analytics from "src/analytics";
import * as Sentry from "src/util/sentry";
import { useOTPVerification } from "./useOTPVerification";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "src/api/user";
import { setUser, signoutUser } from "src/redux/actions/user";
import { sendCode } from "src/api/otp";
import {
  getInterviewRequestPublic,
  getReferCandidate,
  getReferCandidatePublic,
  ReferredCandidateProps,
  updateReferCandidate,
} from "src/api/referred_candidate";
import { useHistory, useLocation } from "react-router-dom";
import { ReferredCandidateUpdateModal } from "./ReferredCandidateUpdateModal";
import { FiCheck, FiEdit2, FiX } from "react-icons/fi";
import { getJobById, getPublicJobById } from "src/api/job";
import { FaLaptopHouse } from "react-icons/fa";
import { getInterviewRequestDetail } from "src/api/admin";
import {
  acceptInterviewRequest,
  getCandidateInterviewRequest,
  rejectInterviewRequest,
} from "src/api/candidate";

type LoginSectionProps = {
  referredCandidateId: string;
  email: string;
  onSuccess: () => Promise<void>;
};
const LoginSection = ({
  email,
  referredCandidateId,
  onSuccess,
}: LoginSectionProps) => {
  const [otp, setOTP] = useState("");
  const toast = useToast();
  const dispatch = useDispatch();
  const onOTPVerify = async () => {
    const { token } = await otpAuth({
      sendId: referredCandidateId,
      code: otp,
      sendType: "INTERVIEW_REQUEST",
    });
    if (!token) return;
    let { user = {} } = (await firebase
      .auth()
      .signInWithCustomToken(token)) as any;
    if (user && user.uid) {
      let savedUser = await getUser(user.uid);
      Analytics.setUser(savedUser);
      Sentry.setUser(savedUser);
      dispatch(
        setUser({
          displayName: user.displayName,
          email: user.email,
          emailVerified: true,
          isAnonymous: user.isAnonymous,
          id: user.uid,
          providerData: user.providerData,
          ...savedUser,
        })
      );
      await onSuccess?.();
    }
  };
  const onOTPSend = async () => {
    setOTP("");
    await sendCode({
      sendType: "INTERVIEW_REQUEST",
      sendId: referredCandidateId,
    });
    toast({
      title: "OTP sent to given email id",
      status: "success",
    });
  };
  const onOTPSuccess = () => {};
  const onOTPError = (message: string) => {
    setOTP("");
    toast({
      title: `Error occured: ${message}`,
      status: "error",
    });
  };
  const {
    isSending: isOTPSending,
    isResending: isOTPResending,
    isOTPSend,
    isOTPVerifying,
    otpTimeRemaining,
    onOTPSendClick,
    onOTPVerifyClick,
    onOTPResendClick,
  } = useOTPVerification({
    onOTPSend,
    onOTPVerify,
    onOTPSuccess,
    onOTPError,
  });

  return (
    <Stack spacing="8">
      <Stack spacing="6" alignItems={"center"}>
        {/* <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading fontWeight="semibold" letterSpacing="tight" fontSize="2xl">
              Log in to your account
            </Heading>
            <Text color="muted">{`You don't need a password. Is safer that way.`}</Text>
          </Stack> */}
      </Stack>
      <Box py={8} px={4}>
        <Stack spacing="6">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              // onEmailFormSubmit();
              onOTPVerifyClick();
            }}
            autoComplete="off"
          >
            <Stack spacing="5">
              <Collapse in={isOTPSend} animateOpacity>
                <>
                  <Box
                    mb={4}
                    background="blue.50"
                    p={2}
                    borderRadius="md"
                    borderColor={"blue.200"}
                    borderWidth={1}
                    fontSize="sm"
                  >
                    <Text>
                      {`We have sent a 6 digit code to ${email}. Please
                        enter the code below.`}
                    </Text>
                  </Box>
                  <FormControl
                    label={"Enter OTP sent to your email"}
                    //   isInvalid={!otpError}
                  >
                    <PinInput
                      otp
                      value={otp}
                      onChange={(value) => {
                        setOTP(value);
                      }}
                    >
                      <PinInputField mr={2} />
                      <PinInputField mr={2} />
                      <PinInputField mr={2} />
                      <PinInputField mr={2} />
                      <PinInputField mr={2} />
                      <PinInputField mr={2} />
                    </PinInput>
                  </FormControl>
                  <Box mt={2}>
                    {otpTimeRemaining > 0 ? (
                      <Text fontSize={"sm"} color="muted">
                        {`Not received? Resend code in ${otpTimeRemaining} seconds`}
                      </Text>
                    ) : (
                      <Button
                        type="button"
                        variant="link"
                        size="xs"
                        isLoading={isOTPResending}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          e.preventDefault();
                          e.stopPropagation();
                          onOTPResendClick();
                        }}
                      >
                        Resend OTP
                      </Button>
                    )}
                  </Box>
                </>
              </Collapse>
              {isOTPSend ? (
                <Button
                  colorScheme="green"
                  isLoading={isOTPVerifying}
                  type="submit"
                  size="lg"
                >
                  Verify and confirm
                </Button>
              ) : (
                <Button
                  size="lg"
                  colorScheme="green"
                  isLoading={isOTPSending}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onOTPSendClick();
                  }}
                >
                  Verify and confirm
                </Button>
              )}
            </Stack>
          </form>
        </Stack>
      </Box>
    </Stack>
  );
};

const DetailRow = ({
  left,
  right,
  onEdit,
}: {
  left: string | ReactElement;
  right: string | ReactElement;
  onEdit?: () => void;
}) => {
  return (
    <Flex alignItems={"center"} justifyContent="space-between" py={1}>
      <Text color="gray.500">{left}</Text>
      {typeof right === "string" ? (
        <Text fontWeight={"500"} flex={1} textAlign="right">
          {right}
        </Text>
      ) : (
        <Flex
          fontWeight={"500"}
          flex={1}
          textAlign="right"
          alignItems={"center"}
          justifyContent="flex-end"
        >
          {right}
        </Flex>
      )}

      {onEdit ? (
        <IconButton
          ml={1}
          aria-label="edit"
          icon={<FiEdit2 />}
          variant="ghost"
          size="sm"
          mb={1}
          onClick={onEdit}
          color="gray.300"
        ></IconButton>
      ) : null}
    </Flex>
  );
};

export const getCurrencyLocale = (currency: string) => {
  switch (currency) {
    case "INR":
      return "en-IN";
    case "EUR":
      return "de-DE";
    case "USD":
      return "en-US";
    case "GBP":
      return "en-GB";
    default:
      return "en-US";
  }
};

export const getCurrencyText = (
  amount: number,
  currency: string,
  format: "s" | "l" | "w" = "s"
) => {
  if (format === "l") {
    return new Intl.NumberFormat(getCurrencyLocale(currency), {
      style: "currency",
      currency: currency,
    })
      .format(amount)
      .replace("T", "K");
  }
  return new Intl.NumberFormat(getCurrencyLocale(currency), {
    style: "currency",
    currency: currency,
    notation: "compact",
    compactDisplay: "long",
  })
    .format(amount)
    .replace("T", "K");
};

const InterviewRequestSummary = () => {
  const location = useLocation();
  const toast = useToast();
  const history = useHistory();
  const [isInvalidPage, setIsInvalidPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const user = useSelector((state: any) => state.user);
  const [interviewRequest, setInterviewRequest] = useState<any>();
  const dispatch = useDispatch();
  const [job, setJob] = useState<any>();
  const fetchInterviewRequest = useCallback(
    async (id: string) => {
      setIsLoading(true);
      try {
        const { interviewRequest, job } = await getCandidateInterviewRequest(
          id
        );
        // const { job } = await getJobById(referredCandidate.jobId);
        // setJob(job);
        setJob(job);
        setInterviewRequest(interviewRequest);
      } catch (e) {
        if (user.email) {
          setIsInvalidPage(true);
        }

        toast({
          title: "Error while fetching details",
          description: "Please try again",
        });
      }
      setIsLoading(false);
    },
    [toast, user.email]
  );
  const fetchPublicInterviewRequest = useCallback(
    async (id: string) => {
      setIsLoading(true);
      try {
        const interviewRequest = await getInterviewRequestPublic(id);
        const { job } = await getPublicJobById(interviewRequest.jobId);
        setJob(job);
        setInterviewRequest(interviewRequest);
      } catch (e) {
        toast({
          title: "Error while fetching details",
          description: "Please try again",
        });
      }
      setIsLoading(false);
    },
    [toast]
  );
  useEffect(() => {
    let params = new URLSearchParams(location.search);
    const id = params.get("id");
    if (!id) {
      setIsInvalidPage(true);
      return;
    }
    if (!user?.id) {
      fetchPublicInterviewRequest(id);
    } else {
      fetchInterviewRequest(id);
    }
  }, [
    user,
    location.search,
    fetchInterviewRequest,
    fetchPublicInterviewRequest,
  ]);

  //   const handleEdit = () => {
  //     if (!user?.id) {
  //       toast({
  //         title: "Confirm email to edit the details",
  //         description: "Proceed with verification",
  //       });
  //       return true;
  //     }
  //     return false;
  //   };

  const redirectToConfirmation = () => {
    if (interviewRequest) {
      if (interviewRequest.status === "APPROVED") {
        history.push("/public/interviewRequest/confirmation/accepted");
      } else if (interviewRequest.status === "REJECTED") {
        history.push("/public/interviewRequest/confirmation/rejected");
      }
    }
  };

  if (
    interviewRequest?.status === "APPROVED" ||
    interviewRequest?.status === "REJECTED"
  ) {
    redirectToConfirmation();
  }

  if (isInvalidPage) {
    return (
      <Container
        maxW="lg"
        py={{ base: "2", md: "4" }}
        px={{ base: "4", sm: "4" }}
        bg="white"
        minH="100vh"
        boxShadow={"base"}
        position="relative"
      >
        <Flex justifyContent={"space-between"} mb={4} alignItems="center">
          <Heading size="lg" letterSpacing={"tight"}>
            Invalid email
          </Heading>
          <img src={logo} alt={"Finding Jo"} height="100px" width="120px" />
        </Flex>
        <Text>
          Email does not match the referred email. Please use the correct email.
        </Text>
        <Button
          onClick={async () => {
            try {
              dispatch(signoutUser());
              await firebase.auth().signOut();
              history.go(0);
            } catch (e) {
              console.log(e);
            }
          }}
        >
          Sign out
        </Button>
      </Container>
    );
  }

  return (
    <Container
      maxW="lg"
      py={{ base: "2", md: "4" }}
      px={{ base: "4", sm: "4" }}
      bg="white"
      minH="100vh"
      boxShadow={"base"}
      position="relative"
    >
      <Flex justifyContent={"space-between"} mb={4} alignItems="center">
        <Heading size="lg" letterSpacing={"tight"}>
          Interview request
        </Heading>
        <img src={logo} alt={"Finding Jo"} height="100px" width="120px" />
      </Flex>
      <Box>
        {job ? (
          <Box mb={2}>
            <Text color="gray.500">You have an interview request for</Text>
            <Box borderWidth={1} p={4} borderRadius="md" background="gray.50">
              <Link href={"/public/job/" + job.id}>
                <Heading fontWeight="500" fontSize={"md"} color="blue.500">
                  {job.jobTitle.name}
                </Heading>
              </Link>
              <Text>{job.company.name}</Text>
              <Text fontSize="xs">
                {job.locations.map((loc: any) => loc.name).join(", ")}
              </Text>
            </Box>
          </Box>
        ) : null}
        {interviewRequest ? (
          <Box>
            <DetailRow
              left={"Offered salary"}
              right={getCurrencyText(
                interviewRequest?.offeredCTC,
                interviewRequest?.offeredCTCCurrency
              )}
            />
          </Box>
        ) : null}

        <Box
          position="fixed"
          bottom={[0, 0, "auto"]}
          right={0}
          left={0}
          maxWidth={"500px"}
          margin="auto"
        >
          {!user?.id && interviewRequest && interviewRequest.id ? (
            <LoginSection
              referredCandidateId={interviewRequest.id}
              email={interviewRequest.email}
              onSuccess={async () => {}}
            />
          ) : interviewRequest?.id ? (
            <Flex p={4}>
              <Button
                variant="solid"
                colorScheme={"green"}
                flex={1}
                size="lg"
                leftIcon={<FiCheck />}
                isLoading={isConfirming}
                onClick={async () => {
                  if (!interviewRequest?.id) {
                    return;
                  }
                  setIsConfirming(true);
                  try {
                    await acceptInterviewRequest(interviewRequest);
                    setIsConfirming(false);
                    const {
                      interviewRequest: updatedInterviewRequest,
                    } = await getCandidateInterviewRequest(interviewRequest.id);
                    setInterviewRequest(updatedInterviewRequest);
                    redirectToConfirmation();
                  } catch (e) {
                    setIsConfirming(false);
                    return false;
                  }
                }}
              >
                Accept
              </Button>
              <Box width={8}></Box>
              <Button
                variant="solid"
                colorScheme={"gray"}
                flex={1}
                size="lg"
                leftIcon={<FiX />}
                isLoading={isRejecting}
                onClick={async () => {
                  if (!interviewRequest?.id) {
                    return;
                  }
                  setIsRejecting(true);
                  try {
                    await rejectInterviewRequest(interviewRequest);
                    setIsRejecting(false);
                    const {
                      interviewRequest: updatedInterviewRequest,
                    } = await getCandidateInterviewRequest(interviewRequest.id);
                    setInterviewRequest(updatedInterviewRequest);
                    redirectToConfirmation();
                  } catch (e) {
                    setIsRejecting(false);
                    return false;
                  }
                }}
              >
                Reject
              </Button>
            </Flex>
          ) : null}
        </Box>
      </Box>
      {isLoading ? (
        <Flex
          position="absolute"
          top="100px"
          left="0px"
          right="0px"
          margin="auto"
          width="140px"
          bg="gray.100"
          boxShadow={"lg"}
          borderRadius="md"
          borderWidth={1}
          px={4}
          py={2}
          alignItems="center"
        >
          <Text textAlign={"center"} fontWeight="600" mr={4}>
            Loading...
          </Text>
          <Spinner />
        </Flex>
      ) : null}
    </Container>
  );
};

export default InterviewRequestSummary;
