import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import { searchJob, searchJobFacets, applyJob } from "../api/job";
import DeleteIcon from "baseui/icon/delete";
import FilterSearchMenu from "../components/FilterSearchMenu";
import FilterSliderMenu from "../components/FilterSliderMenu";
import { toaster } from "baseui/toast";
import Title from "../components/Title";
import LoadingScreen from "../components/LoadingScreen";
import Input from "../components/Input";
import { SIZE as INPUT_SIZE } from "baseui/input";
import Pagination from "../components/Pagination";
import debounce from "debounce-async";
import * as Analytics from "../analytics";
import { Block } from "baseui/block";
import { Icon } from "../components/Icon";
import { CenterContainer } from "../components/CenterContainer";
import JobCard from "../components/employer/JobCard";
import { Accordion, Panel } from "../components/Accordion";
import Card from "../components/Card";
import { ParagraphSmall } from "baseui/typography";
import { RightContainer } from "../components/RightContainer";
import Modal from "../components/Modal";
import { ModalBody, ModalHeader } from "baseui/modal";
import Button from "../components/Button";
import { GetCallback } from "../components/common/GetCallback";

class CandidateFindJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basic: {},
      appliedFilters: {},
      isFetching: true,
      isLoading: false,
      jobs: [],
      isDisabled: false,
      query: "",
      page: 0,
      hasNext: false,
      hasPrev: false,
      isFilterModalVisible: false,
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[C] page view find jobs");
    this.setState({
      loading: true,
      error: false,
    });
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let appliedFilters = this.state.appliedFilters;
    let page = this.state.page;
    let sort = this.state.sort;
    try {
      appliedFilters =
        JSON.parse(params.get("filter")) || this.state.appliedFilters;
      page = parseInt(params.get("page")) || this.state.page;
      sort = JSON.parse(params.get("sort")) || this.state.sort;
    } catch (e) {}
    this.setState(
      {
        appliedFilters,
        page,
        sort,
      },
      async () => {
        await this.onSearch();
        this.setState({
          isFetched: true,
          loading: false,
        });
      }
    );
  }

  async onSearch() {
    this.setState({
      isFetching: true,
    });

    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    params.set("filter", JSON.stringify(this.state.appliedFilters));
    params.set("sort", JSON.stringify(this.state.sort));
    params.set("page", this.state.page);
    this.props.history.push({ search: params.toString() });

    try {
      let salary = this.state.appliedFilters.salary;
      let salaryFilter = [];
      if (salary && salary.length === 2) {
        salaryFilter = [salary[0] * 100000, salary[1] * 100000];
      }
      let { data, isDisabled } = await searchJob({
        query: this.state.query,
        page: this.state.page,
        filter: {
          ...this.state.appliedFilters,
          salary: salaryFilter,
        },
      });
      let { Hits = [], page, nbPages } = data || {};
      this.setState({
        jobs: Hits,
        isFetching: false,
        page: page,
        hasNext: page < nbPages - 1,
        hasPrev: page > 0,
        isDisabled,
      });
    } catch (e) {
      this.setState({
        isFetching: false,
      });
      console.log(e);
    }
  }

  displayFilter(filterName) {
    switch (filterName) {
      case "noticePeriod":
        return (
          "Notice period: " +
          this.state.appliedFilters[filterName][0] +
          " weeks"
        );
      case "experience":
        return (
          "Experience: " +
          this.state.appliedFilters[filterName][0] +
          " - " +
          this.state.appliedFilters[filterName][1] +
          " years"
        );
      case "jobLocations":
      case "locations":
        return "Locations: " + this.state.appliedFilters[filterName].join(",");
      case "functionalAreas":
        return (
          "Functional Areas: " + this.state.appliedFilters[filterName].join(",")
        );
      case "college":
        return "College: " + this.state.appliedFilters[filterName].join(",");
      case "company":
        return "Company: " + this.state.appliedFilters[filterName].join(",");
      case "startupTypes":
        return (
          "Startup Ready: " + this.state.appliedFilters[filterName].join(",")
        );
      case "remoteReady":
        return (
          "Remote Options: " + this.state.appliedFilters[filterName].join(",")
        );
      case "salary":
        return (
          "Salary : " +
          this.state.appliedFilters[filterName][0] +
          " - " +
          this.state.appliedFilters[filterName][1] +
          " lakhs"
        );
      case "skills":
        return "Skills: " + this.state.appliedFilters[filterName].join(",");
      default:
        return filterName;
    }
  }

  onFilterChange = async (name, val) => {
    Analytics.logEvent("[C] click change filter find jobs", {
      name: name,
      value: val,
    });
    this.setState(
      {
        appliedFilters: {
          ...this.state.appliedFilters,
          ...{
            [name]: val,
          },
        },
      },
      async () => {
        await this.onSearch();
      }
    );
  };

  onRemoveFilter = async (name, val) => {
    Analytics.logEvent("[C] click remove filter find jobs", {
      name: name,
      value: val,
    });
    if (!val) {
      delete this.state.appliedFilters[name];
      this.setState(
        {
          appliedFilters: {
            ...this.state.appliedFilters,
          },
        },
        async () => {
          await this.onSearch();
        }
      );
    } else {
      this.setState(
        {
          appliedFilters: {
            ...this.state.appliedFilters,
            ...{
              [name]: val,
            },
          },
        },
        async () => {
          await this.onSearch();
        }
      );
    }
  };

  onApplyJob = async (job) => {
    try {
      this.setState({
        isApplying: job.id,
      });
      await applyJob({
        employerId: job.createdBy,
        employerName: job.employerName,
        candidateId: this.props.user.id,
        jobId: job.objectID,
      });
      toaster.positive(<div>Job Application Sent</div>, {
        autoHideDuration: 3000,
      });
    } catch (e) {
      console.log(e);
    }
    this.setState({
      isApplying: "",
    });
  };

  onViewJobDetails = (jobId) => {
    this.props.history.push("/candidate/details/view-job/" + jobId);
  };

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onSearch();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onSearch();
      }
    );
  };

  onSearchQueryChange = async (e) => {
    this.setState({
      query: e.target.value,
    });
    await this.debounceSearch();
  };

  debounceSearch = debounce(async () => {
    await this.onSearch();
  }, 1000);

  render() {
    const FilterContainer = (
      <Card>
        <Accordion>
          <Panel title={<ParagraphSmall>Locations</ParagraphSmall>}>
            <FilterSearchMenu
              name={"locations"}
              appliedFilters={this.state.appliedFilters["locations"] || []}
              popularFilters={[{ name: "Bangalore", id: "Bangalore" }]}
              onSearch={async (q) => {
                let { FacetHits = [] } = await searchJobFacets(
                  "locations.name",
                  q || ""
                );
                return FacetHits.map((facet) => ({
                  id: facet.value,
                  name: facet.value,
                }));
              }}
              onChange={this.onFilterChange}
            />
          </Panel>
          <Panel title={<ParagraphSmall>Experience</ParagraphSmall>}>
            <React.Fragment>
              <FilterSliderMenu
                name={"experience"}
                appliedFilters={this.state.appliedFilters["experience"]}
                onChange={this.onFilterChange}
                formatter={(value) => `${parseInt(value / 5)} Year`}
                marks={{
                  0: "0",
                  100: "20",
                }}
              />
              <div>{"Experience in years"}</div>
            </React.Fragment>
          </Panel>
          <Panel title={<ParagraphSmall>Skills</ParagraphSmall>}>
            <FilterSearchMenu
              name={"skills"}
              appliedFilters={this.state.appliedFilters["skills"] || []}
              popularFilters={[
                { name: "Javascript", id: "Javascript" },
                { name: "Java", id: "Java" },
              ]}
              onSearch={async (q) => {
                let { FacetHits = [] } = await searchJobFacets(
                  "skills.name",
                  q || ""
                );
                return FacetHits.map((facet) => ({
                  id: facet.value,
                  name: facet.value,
                }));
              }}
              onChange={this.onFilterChange}
            />
          </Panel>
          <Panel title={<ParagraphSmall>Functional Area</ParagraphSmall>}>
            <FilterSearchMenu
              name={"functionalAreas"}
              appliedFilters={
                this.state.appliedFilters["functionalAreas"] || []
              }
              popularFilters={[
                {
                  name: "Full Stack Engineer",
                  id: "Full Stack Engineer",
                },
                { name: "Frontend Engineer", id: "Frontend Engineer" },
              ]}
              onSearch={async (q) => {
                let { FacetHits = [] } = await searchJobFacets(
                  "functionalAreas.name",
                  q || ""
                );
                return FacetHits.map((facet) => ({
                  id: facet.value,
                  name: facet.value,
                }));
              }}
              onChange={this.onFilterChange}
            />
          </Panel>
          <Panel title={<ParagraphSmall>Company</ParagraphSmall>}>
            <FilterSearchMenu
              name={"company"}
              appliedFilters={this.state.appliedFilters["company"] || []}
              popularFilters={[
                {
                  name: "Myntra",
                  id: "Myntra",
                },
                { name: "Flipkart", id: "Flipkart" },
              ]}
              onSearch={async (q) => {
                let { FacetHits = [] } = await searchJobFacets(
                  "company.name",
                  q || ""
                );
                return FacetHits.map((facet) => ({
                  name: facet.value,
                  id: facet.value,
                }));
              }}
              onChange={this.onFilterChange}
            />
          </Panel>
          <Panel title={<ParagraphSmall>Salary</ParagraphSmall>}>
            <Block paddingTop="scale1200">
              <FilterSliderMenu
                name={"salary"}
                appliedFilters={this.state.appliedFilters["salary"]}
                onChange={this.onFilterChange}
                formatter={(value) => `${value} lakhs`}
                max={100}
                min={0}
                marks={{
                  0: "0",
                  100: "100",
                }}
              />
              <div>{"Salary in lakhs"}</div>
            </Block>
          </Panel>
        </Accordion>
      </Card>
    );

    const FilterButton = (
      <Block width={["auto", "auto", "0px"]} overflow="hidden">
        <Button
          kind="tertiary"
          onClick={() => {
            this.setState({
              isFilterModalVisible: true,
            });
          }}
          startEnhancer={<Icon name="filter" size={14} />}
        >
          Filter
        </Button>
      </Block>
    );

    // if (this.state.isDisabled) {
    //   if (this.state.isFetching) return <LoadingScreen />;
    //   return (
    //     <div>
    //       <Title>Find Jobs</Title>
    //       <div
    //         style={{
    //           marginTop: "64px",
    //           marginLeft: "64px",
    //           width: "420px",
    //           height: "420px",
    //         }}
    //       >
    //         <Block display="flex" flexDirection="column" alignItems="center">
    //           <Icon size={48} name="lock" />
    //           <div style={{ textAlign: "center" }}>
    //             This page will be enabled once a Talent-Scout shortlists your
    //             profile as a premium candidate.
    //           </div>
    //         </Block>
    //       </div>
    //     </div>
    //   );
    // }
    return (
      <Block display="flex" flex={1}>
        <CenterContainer>
          <div>
            <GetCallback />
            <Title>Find Jobs</Title>
            <div
              style={{
                display: "flex",
                flex: 1,
                flexWrap: "wrap",
                maxWidth: "420px",
                marginRight: "16px",
                marginBottom: "16px",
                position: "relative",
              }}
            >
              <Input
                size={INPUT_SIZE.compact}
                placeholder={"Search here"}
                onChange={this.onSearchQueryChange}
                value={this.state.query}
              />
              <Block $position="absolute" $right="scale600" $top="scale400">
                <Icon size={16} name="search" />
              </Block>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex", flex: 1, flexWrap: "wrap" }}>
              {Object.keys(this.state.appliedFilters).map((filter, idx) => {
                return (
                  <span
                    key={idx}
                    style={{
                      color: "#0F4C81",
                      background: "#f6f7f8",
                      padding: "2px 8px",
                      borderRadius: "6px",
                      marginBottom: 4,
                      marginRight: 4,
                      display: "flex",
                      border: "1px solid",
                      fontSize: "14px",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    {this.displayFilter(filter)}
                    <DeleteIcon
                      size={16}
                      onClick={() => {
                        this.onRemoveFilter(filter, null);
                      }}
                    />
                  </span>
                );
              })}
            </div>
          </div>
          {this.state.isFetching ? (
            <LoadingScreen />
          ) : (
            this.state.jobs.map((job, index) => (
              <JobCard
                job={job}
                onApply={() => {}}
                onShare={() => {}}
                to={"/candidate/jobs/" + job.id + "/details"}
                isClickable
                isCandidateView
              />
              // <SearchJobItem job={job} key={index} showApply={true} />
            ))
          )}
          {!this.state.jobs.length && !this.state.query && (
            <div
              style={{
                height: "250px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>No job found</div>
            </div>
          )}
          {!this.state.jobs.length && this.state.query && (
            <div
              style={{
                height: "250px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div>
                <div style={{ marginBottom: "16px" }}>
                  Your search -"%keyword%" - did not match any job descriptions
                </div>
                <div>Suggestions:</div>
                <div>Make sure that all words are spelled correctly.</div>
                <div>Try different keywords.</div>
                <div>Try more general keywords.</div>
              </div>
            </div>
          )}
          <Pagination
            onPrevClick={this.onPrevClick}
            onNextClick={this.onNextClick}
            hasNext={this.state.hasNext}
            hasPrev={this.state.hasPrev}
          />
        </CenterContainer>
        <RightContainer marginTop="48px">{FilterContainer}</RightContainer>
        <Modal
          isOpen={this.state.isFilterModalVisible}
          onClose={() =>
            this.setState({
              isFilterModalVisible: false,
            })
          }
        >
          <ModalHeader>Filter Candidates</ModalHeader>
          <ModalBody>{FilterContainer}</ModalBody>
        </Modal>
      </Block>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateFindJobs));
