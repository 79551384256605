import React, { useEffect, useState, useMemo } from "react";
import {
  Heading,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button,
  useDisclosure,
  Flex,
  Badge,
  ThemeTypings,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  toast,
  useToast,
} from "@chakra-ui/react";
import {
  getNextReferCandidateForReview,
  getReferCandidates,
  ReferredCandidateProps,
} from "src/api/referred_candidate";
import { Column, useTable } from "react-table";
import { FiArrowRight } from "react-icons/fi";
import { prettyPrint } from "src/util/time";
import { Pagination } from "src/lib/components/Pagination";
import { JobPicker } from "src/screens/tools/JobPicker";
import { FaChevronDown } from "react-icons/fa";
import { useHistory } from "react-router-dom";

export const getStatusColorScheme = (
  status?: string
): ThemeTypings["colorSchemes"] => {
  switch (status) {
    case "PREAPPROVED":
      return "yellow";
    case "APPROVED":
      return "green";
    case "REJECTED":
      return "red";
  }
  return "gray";
};

export const DataVerification = () => {
  const [page, setPage] = useState<number>(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [referredCandidates, setReferredCandidates] = useState<
    ReferredCandidateProps[]
  >([]);
  const [selectedJob, setSelectedJob] = useState<any>(null);
  const [linkedInParseStatus, setLinkedInParseStatus] = useState("");
  const jobPickerDisclosure = useDisclosure();
  const history = useHistory();
  const toast = useToast();
  const limit = 10;
  const fetchReferredCandidates = async (
    jobId: string,
    _page: number,
    _limit: number
  ) => {
    setIsFetching(true);
    try {
      const { data: _data, hasNext: _hasNext } = await getReferCandidates(
        {
          jobId,
          status: "PREAPPROVED",
          sortField: "modifiedAt",
          sortOrder: "ASC",
          linkedInParseStatus,
        },
        _page,
        _limit
      );
      setReferredCandidates(_data);
      setHasNext(_hasNext);
    } catch (e) {}
    setIsFetching(false);
  };
  // useEffect(() => {
  //   fetchReferredCandidates(selectedJob?.id, page, limit);
  // }, [selectedJob?.id, linkedInParseStatus, page, limit]);
  const data = useMemo(() => referredCandidates, [referredCandidates]);
  const columns = useMemo<Column<ReferredCandidateProps>[]>(
    () => [
      { Header: "Email", accessor: "email" },
      { Header: "Phone", accessor: "phone" },
      {
        Header: "Modified At",
        accessor: ({ modifiedAt }) => (
          <Text color={"gray"}>{prettyPrint(modifiedAt, true)}</Text>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <Badge colorScheme={getStatusColorScheme(value)}>{value}</Badge>
        ),
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ value }) => <FiArrowRight />,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });
  return (
    <Box p={4}>
      <Text textStyle="h2" mr={4} mb={10}>
        Data Verification
      </Text>
      <Button
        isLoading={isFetching}
        onClick={async () => {
          setIsFetching(true);
          try {
            const { referredCandidate } = await getNextReferCandidateForReview(
              "PREAPPROVED"
            );
            setIsFetching(false);
            if (referredCandidate) {
              history.push(
                "/tools/candidates/referred/all/" +
                  referredCandidate.id +
                  "?purpose=DATAVERIFICATION"
              );
            } else {
              toast({
                title: "No candidate for review now",
                description: "Please check again",
              });
            }
          } catch (e) {
            setIsFetching(false);
          }
        }}
      >
        Get Candidate
      </Button>
      {/* <Flex alignItems={"center"} mb={4}>
        <Flex p={4} alignItems={"center"}>
          <Box ml={8} mr={8}>
            <Button
              onClick={() => jobPickerDisclosure.onOpen()}
              rightIcon={<FaChevronDown />}
            >
              {selectedJob ? (
                <Box fontWeight={"400"} textAlign="left" fontSize="xs">
                  <Text>{selectedJob.jobTitle?.name}</Text>
                  <Text color="gray">{selectedJob.company?.name}</Text>
                </Box>
              ) : (
                <Box fontWeight="400" textAlign="left" fontSize="xs">
                  <Text>All Jobs</Text>
                  <Text color="gray">No job selected</Text>
                </Box>
              )}
            </Button>
          </Box>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={Button}
                  rightIcon={<FaChevronDown />}
                >
                  {linkedInParseStatus || "Parse Status"}
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => setLinkedInParseStatus("")}>
                    All
                  </MenuItem>
                  <MenuItem onClick={() => setLinkedInParseStatus("SUCCESS")}>
                    SUCCESS
                  </MenuItem>
                  <MenuItem onClick={() => setLinkedInParseStatus("FAILED")}>
                    FAILED
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </Flex>
      </Flex>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} borderBottomWidth={2}>
              {headerGroup.headers.map((column) => (
                <Th
                  textTransform={"none"}
                  fontSize="sm"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                _hover={{ background: "gray.50" }}
                cursor="pointer"
                onClick={() => {
                  history.push(
                    "/tools/candidates/referred/verification/" + row.original.id
                  );
                }}
              >
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {!isFetching && (
        <Box mt={4}>
          <Pagination page={page} setPage={setPage} hasNext={hasNext} />
        </Box>
      )}
      <JobPicker
        {...jobPickerDisclosure}
        onChange={(job) => setSelectedJob(job)}
      /> */}
    </Box>
  );
};
