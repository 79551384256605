import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { FiChevronsDown, FiMoreVertical, FiTrash } from "react-icons/fi";
import { Project, Entity } from "src/lib/types/candidate";
import { ProjectForm } from "./ProjectForm";
import { ProjectRow } from "./ProjectList";

const ProjectSummary = ({
  project: { company, briefIntro },
}: {
  project: Project;
}) => {
  return (
    <Box>
      <Text fontWeight={"600"} _groupHover={{ color: "blue.500" }}>
        {company?.name || "Company Name"}
      </Text>
      <Text color="gray.300">{briefIntro || "Description"}</Text>
    </Box>
  );
};

export type ProjectSectionProps = {
  projectRow: ProjectRow;
  projectCompanies: Entity[];
  onDelete: () => void;
  onDiscard: () => void;
  onSave: (ex: Project) => void;
  isNew?: boolean;
  shouldValidate: boolean;
};

export const ProjectSection = ({
  projectRow,
  projectCompanies,
  onDelete,
  onDiscard,
  onSave,
  shouldValidate,
}: ProjectSectionProps) => {
  const [showForm, setShowForm] = useState(false);
  useEffect(() => {
    if (projectRow.isNew) setShowForm(true);
  }, [projectRow.isNew]);
  return (
    <Box
      borderRadius="md"
      borderColor="gray.200"
      borderWidth={1}
      mb={2}
      p={4}
      bgGradient="linear(to-r, gray.50, white)"
      boxShadow="6px 6px 25px
      #e1e1e1, -6px -6px 25px #ffffff"
    >
      <Flex
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowForm(projectRow.isNew ? true : !showForm);
        }}
        role="group"
        cursor="pointer"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <ProjectSummary project={projectRow.project} />
        <Flex alignItems={"center"}>
          <Flex
            alignItems={"center"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Menu>
              <MenuButton
                mr={2}
                as={IconButton}
                aria-label="More"
                icon={<FiMoreVertical />}
                variant="link"
              />
              <MenuList>
                <MenuItem onClick={onDelete} icon={<FiTrash />}>
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
          <Icon
            as={FiChevronsDown}
            ml="auto"
            transform={showForm ? "rotate(180deg)" : undefined}
          />
        </Flex>
      </Flex>
      <Collapse in={showForm}>
        <ProjectForm
          shouldValidate={shouldValidate}
          projectRow={projectRow}
          projectCompanies={projectCompanies}
          onSave={async (project) => {
            try {
              await onSave(project);
              setShowForm(false);
            } catch (e) {}
          }}
          onDiscard={onDiscard}
          onDelete={onDelete}
        />
      </Collapse>
    </Box>
  );
};
