import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Spinner,
  Stack,
  Text,
  toast,
  useToast,
} from "@chakra-ui/react";
import { Container, Draggable } from "react-smooth-dnd";
import {
  addReviewPriority,
  getReviewPriorities,
  removeReviewPriority,
  ReviewPriorityProps,
  updateReviewPriority,
} from "src/api/review_priority";
import { keyBy } from "lodash";
import { prettyPrint } from "src/util/time";
import { FaPlus } from "react-icons/fa";
import { AddPriorityModal, AddPriorityModalProps } from "./AddPriorityModal";
import { FiRefreshCw, FiTrash2 } from "react-icons/fi";
import { resetReferCandidatePriorities } from "src/api/referred_candidate";

export const ReviewPriorityConfig = () => {
  const [priorities, setPriorities] = useState<ReviewPriorityProps[]>([]);
  const [jobs, setJobs] = useState<any>();
  const [isFetching, setIsFetching] = useState(false);
  const onAddModalClose = () => {
    setAddPriorityModalProps({
      ...addPriorityModalProps,
      isOpen: false,
    });
  };
  const toast = useToast();
  const onAddModalAction = async (values: ReviewPriorityProps) => {
    try {
      if (values.id) {
        await updateReviewPriority(values.id, values);
      } else {
        await addReviewPriority(values);
      }
      await fetchReviewPriorities({ isSilent: true });
    } catch (e) {
      toast({
        title: "Error while saving review priority",
      });
    }
  };
  const [addPriorityModalProps, setAddPriorityModalProps] = useState<
    AddPriorityModalProps
  >({
    isOpen: false,
    onClose: onAddModalClose,
    onActionClick: onAddModalAction,
    job: {},
  });
  useEffect(() => {
    fetchReviewPriorities({});
  }, []);
  const fetchReviewPriorities = async ({
    isSilent,
  }: {
    isSilent?: boolean;
  }) => {
    !isSilent && setIsFetching(true);
    try {
      const { data, jobs } = await getReviewPriorities();
      setPriorities(data);
      setJobs(keyBy(jobs, "id"));
    } catch (e) {}
    !isSilent && setIsFetching(false);
  };
  const data = useMemo(() => priorities, [priorities]);
  return (
    <Box py={4}>
      <Text textStyle="h2" ml={4} mb={6}>
        Review Priority
      </Text>
      <Box ml={4} mb={4}>
        <Stack direction="row" spacing={4} align="center">
          <Button
            variant="solid"
            leftIcon={<FaPlus />}
            onClick={() => {
              setAddPriorityModalProps({
                ...addPriorityModalProps,
                isOpen: true,
                mode: "ADD",
              });
            }}
          >
            Add Item
          </Button>
          <Button
            variant="solid"
            ml={8}
            colorScheme={"red"}
            leftIcon={<FiRefreshCw />}
            onClick={async () => {
              try {
                await resetReferCandidatePriorities();
                toast({
                  title: "Triggered reset queue",
                  description: "Please wait for a minute to reflect",
                });
              } catch (e) {}
            }}
          >
            Reset Queue
          </Button>
        </Stack>
      </Box>

      <Container
        onDrop={async ({ removedIndex, addedIndex }) => {
          if (removedIndex != null && addedIndex != null) {
            if (removedIndex === addedIndex) return;
            const nextSequenceNo =
              addedIndex === 0
                ? priorities[addedIndex].sequenceNo - 1
                : priorities[addedIndex - 1].sequenceNo + 1;
            const itemId = priorities[removedIndex].id;
            // Local update
            const priorityToAdd = priorities[removedIndex];
            const removed = priorities
              .slice(0, removedIndex)
              .concat(priorities.slice(removedIndex + 1));
            const added = removed
              .slice(0, addedIndex)
              .concat([priorityToAdd])
              .concat(removed.slice(addedIndex));
            setPriorities(added);
            // Remote update
            try {
              await updateReviewPriority(itemId, {
                sequenceNo: nextSequenceNo,
              });
              await fetchReviewPriorities({ isSilent: true });
            } catch (e) {}
          }
        }}
        render={(ref) => {
          return (
            <Box ref={ref} borderTopWidth={1}>
              {!isFetching &&
                data.map((d) => {
                  const {
                    idType,
                    predefinedId,
                    jobId,
                    count,
                    modifiedAt,
                    id,
                  } = d;
                  return (
                    <Draggable
                      key={id}
                      render={() => {
                        return (
                          <Flex
                            _hover={{ background: "gray.50" }}
                            cursor="pointer"
                            width="640px"
                            onClick={() => {
                              setAddPriorityModalProps({
                                ...addPriorityModalProps,
                                isOpen: true,
                                job: jobs[jobId],
                                selectedPriority: d,
                                mode: "UPDATE",
                              });
                            }}
                          >
                            <Flex
                              alignItems={"center"}
                              justifyContent="space-between"
                              p={4}
                              px={8}
                            >
                              {idType === "JOBID" ? (
                                <Box flex={1}>
                                  <Text textStyle="h4">{`${jobs?.[jobId]?.company.name}`}</Text>
                                  <Text>{`${jobs?.[jobId]?.jobTitle.name}`}</Text>
                                </Box>
                              ) : (
                                <Box flex={1}>
                                  <Text textStyle="h4">{`${predefinedId}`}</Text>
                                </Box>
                              )}
                              <Text mr={8}>{count}</Text>
                              <Text>{prettyPrint(modifiedAt)}</Text>
                              <IconButton
                                ml={8}
                                aria-label="remove"
                                icon={<FiTrash2 />}
                                onClick={async (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  try {
                                    await removeReviewPriority(id);
                                    await fetchReviewPriorities({
                                      isSilent: true,
                                    });
                                  } catch (e) {}
                                }}
                              />
                            </Flex>
                          </Flex>
                        );
                      }}
                    />
                  );
                })}
            </Box>
          );
        }}
      ></Container>
      {isFetching && (
        <Flex height="200px" alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      )}
      <AddPriorityModal {...addPriorityModalProps} />
    </Box>
  );

  // const columns = useMemo<Column<ReviewPriorityProps>[]>(
  //   () => [
  //     {
  //       Header: "Job",
  //       accessor: ({ jobId }) => {
  //         return (
  //           <>
  //             <Text textStyle="label">{`${jobs?.[jobId]?.company.name}`}</Text>
  //             <Text>{`${jobs?.[jobId]?.jobTitle.name}`}</Text>
  //           </>
  //         );
  //       },
  //     },
  //     { Header: "Number of Items", accessor: "count" },
  //     {
  //       Header: "Modified At",
  //       accessor: ({ modifiedAt }) => {
  //         return <Text color={"gray"}>{prettyPrint(modifiedAt)}</Text>;
  //       },
  //     },
  //     {
  //       Header: "",
  //       accessor: "id",
  //       Cell: ({ value }) => (
  //         <IconButton
  //           aria-label="remove"
  //           icon={<FiTrash2 />}
  //           onClick={async (e) => {
  //             e.preventDefault();
  //             e.stopPropagation();
  //             try {
  //               await removeReviewPriority(value);
  //               await fetchReviewPriorities({ isSilent: true });
  //             } catch (e) {}
  //           }}
  //         />
  //       ),
  //     },
  //   ],
  //   [jobs]
  // );
  // const {
  //   getTableProps,
  //   getTableBodyProps,
  //   headerGroups,
  //   rows,
  //   prepareRow,
  // } = useTable({ columns, data });
  // return (
  //   <Box py={10}>
  //     <Text textStyle="h1" ml={4} mb={6}>
  //       Review Priority
  //     </Text>
  //     <Box ml={4} mb={4}>
  //       <Stack direction="row" spacing={4} align="center">
  //         <Button
  //           variant="solid"
  //           leftIcon={<FaPlus />}
  //           onClick={() => {
  //             setAddPriorityModalProps({
  //               ...addPriorityModalProps,
  //               isOpen: true,
  //               mode: "ADD",
  //             });
  //           }}
  //         >
  //           Add Item
  //         </Button>
  //       </Stack>
  //     </Box>

  //     <Table {...getTableProps()}>
  //       {/* <Thead>
  //         {headerGroups.map((headerGroup) => (
  //           <Tr {...headerGroup.getHeaderGroupProps()} borderBottomWidth={2}>
  //             {headerGroup.headers.map((column) => (
  //               <Th textTransform={"none"} {...column.getHeaderProps()}>
  //                 <Text textStyle={"label"}>{column.render("Header")}</Text>
  //               </Th>
  //             ))}
  //           </Tr>
  //         ))}
  //       </Thead> */}
  //       <Container
  //         onDrop={async ({ removedIndex, addedIndex }) => {
  //           if (removedIndex != null && addedIndex != null) {
  //             if (removedIndex === addedIndex) return;
  //             const nextSequenceNo =
  //               addedIndex === 0
  //                 ? priorities[addedIndex].sequenceNo - 1
  //                 : priorities[addedIndex - 1].sequenceNo + 1;
  //             const itemId = priorities[removedIndex].id;
  //             // Local update
  //             const priorityToAdd = priorities[removedIndex];
  //             const removed = priorities
  //               .slice(0, removedIndex)
  //               .concat(priorities.slice(removedIndex + 1));
  //             const added = removed
  //               .slice(0, addedIndex)
  //               .concat([priorityToAdd])
  //               .concat(removed.slice(addedIndex));
  //             setPriorities(added);
  //             // Remote update
  //             try {
  //               await updateReviewPriority(itemId, {
  //                 sequenceNo: nextSequenceNo,
  //               });
  //               await fetchReviewPriorities({ isSilent: true });
  //             } catch (e) {}
  //           }
  //         }}
  //         render={(ref) => {
  //           return (
  //             <Tbody ref={ref} {...getTableBodyProps()} borderTopWidth={1}>
  //               {!isFetching &&
  //                 rows.map((row) => {
  //                   prepareRow(row);
  //                   return (
  //                     <Draggable
  //                       key={row.original.id}
  //                       render={() => {
  //                         return (
  //                           <Tr
  //                             {...row.getRowProps()}
  //                             _hover={{ background: "gray.50" }}
  //                             cursor="pointer"
  //                             width="640px"
  //                             onClick={() => {
  //                               setAddPriorityModalProps({
  //                                 ...addPriorityModalProps,
  //                                 isOpen: true,
  //                                 job: jobs[row.original.jobId],
  //                                 selectedPriority: row.original,
  //                                 mode: "UPDATE",
  //                               });
  //                             }}
  //                           >
  //                             {row.cells.map((cell) => (
  //                               <Td {...cell.getCellProps()}>
  //                                 {cell.render("Cell")}
  //                               </Td>
  //                             ))}
  //                           </Tr>
  //                         );
  //                       }}
  //                     />
  //                   );
  //                 })}
  //             </Tbody>
  //           );
  //         }}
  //       ></Container>
  //     </Table>
  //     {isFetching && (
  //       <Flex height="200px" alignItems="center" justifyContent="center">
  //         <Spinner />
  //       </Flex>
  //     )}
  //     <AddPriorityModal {...addPriorityModalProps} />
  //   </Box>
  // );
};
