import React, { useState, useEffect } from "react";
import { Select, TYPE } from "baseui/select";
import { LabelSmall } from "baseui/typography";
import Button from "./Button";
import { KIND, SIZE } from "baseui/button";
import { Block } from "baseui/block";
import { Tag, TagLabel } from "@chakra-ui/react";

class SearchSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      value: [],
      fetching: false,
    };
  }

  onSearch = async (e) => {
    this.setState({ data: [], fetching: true });
    try {
      let data = await this.props.onSearch(e.target.value);
      this.setState({ data, fetching: false });
    } catch (e) {
      console.log(e);
      this.props.onSearchError && this.props.onSearchError();
    }
  };

  handleChange = ({ value }) => {
    this.props.onChange(value[0]);
  };

  onOpen = async () => {
    this.setState({ data: [], fetching: true });
    try {
      let data = await this.props.onSearch("");
      this.setState({ data, fetching: false });
    } catch (e) {
      console.log(e);
      this.props.onSearchError && this.props.onSearchError();
    }
  };

  render() {
    const { data, value } = this.state;
    return (
      <Select
        showSearch
        type={TYPE.search}
        options={data}
        value={value}
        placeholder={this.props.placeholder || "Search " + this.props.name}
        onChange={this.handleChange}
        labelKey="name"
        valueKey="id"
        onInputChange={this.onSearch}
        style={{ width: "100%" }}
        onOpen={this.onOpen}
        overrides={{
          ControlContainer: {
            style: ({ $theme }) => {
              return {
                //   borderTopWidth: "1px",
                //   borderBottomWidth: "1px",
                //   borderLeftWidth: "1px",
                //   borderRightWidth: "1px",
                //   backgroundColor: "#F5F5F5",
                //   borderTopLeftRadius: "4px",
                //   borderBottomLeftRadius: "4px",
                //   borderTopRightRadius: "4px",
                //   borderBottomRightRadius: "4px",
              };
            },
          },
        }}
      ></Select>
    );
  }
}

const FilterSearchMenu = (props) => {
  const [popularFilters, setPopularFilters] = useState([]);
  useEffect(() => {
    async function fetchPopularFilters() {
      if (props.fetchPopularFilters) {
        try {
          let popularFilters = await props.fetchPopularFilters();
          setPopularFilters(popularFilters);
        } catch (e) {}
      }
    }
    fetchPopularFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div style={{ minHeight: 200 }}>
      <SearchSelect
        name={props.name}
        placeholder={props.placeholder}
        onChange={(val) => {
          if (props.appliedFilters.indexOf(val.name) === -1) {
            props.appliedFilters.push(val.name);
            props.onChange(props.name, props.appliedFilters);
          }
        }}
        onSearch={props.onSearch}
      />
      {/* {props.appliedFilters.length ? (
        <LabelSmall $marginBottom={"scale400"} $marginTop={"scale400"}>
          SELECTED
        </LabelSmall>
      ) : null}
      <Block $display="flex" $flexDirection="column">
        {(props.appliedFilters || []).map((fil, index) => (
          <Block $marginBottom="scale200" $display="flex" $flexDirection="column">
            <Button
              key={index}
              size={SIZE.mini}
              kind={KIND.tertiary}
            >
              {fil}
            </Button>
          </Block>
        ))}
      </Block> */}
      {(popularFilters || []).length ? (
        <LabelSmall $marginBottom={"scale400"} $marginTop={"16px"}>
          POPULAR
        </LabelSmall>
      ) : null}
      <Block $display="flex" $flexDirection="column">
        {(popularFilters || []).map((fil, index) => (
          <Block
            $marginBottom="scale200"
            $display="flex"
            $flexDirection="column"
          >
            <Tag
              size="lg"
              borderRadius="md"
              colorScheme={"blue"}
              mb={1}
              onClick={() => {
                if (props.appliedFilters.indexOf(fil.name) === -1) {
                  props.appliedFilters.push(fil.name);
                  props.onChange(props.name, props.appliedFilters);
                }
              }}
            >
              <TagLabel>{fil.name}</TagLabel>
            </Tag>

            {/* <Button
              key={index}
              kind={KIND.tertiary}
              size={SIZE.mini}
              onClick={() => {
                if (props.appliedFilters.indexOf(fil.name) === -1) {
                  props.appliedFilters.push(fil.name);
                  props.onChange(props.name, props.appliedFilters);
                }
              }}
            >
              {fil.name}
            </Button> */}
          </Block>
        ))}
      </Block>
    </div>
  );
};

export default FilterSearchMenu;
