import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import Modal from "../components/Modal";
import Button from "../components/Button";
import Label from "../components/Label";
import Input from "../components/Input";
import FormControl from "../components/FormControl";
import { setWidgetState } from "../redux/actions/widget";
import Title from "../components/Title";
import { updateBankDetails } from "../api/user";
import { toaster } from "baseui/toast";

const BankDetailsModal = ({
  user,
  workspace,
  widgetState,
  setWidgetState,
  onAdded,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [bankValues, setBankValues] = useState(widgetState.bankValues || {});
  const [bankErrors, setBankErrors] = useState({});

  useEffect(() => {
    setBankValues(widgetState.bankValues || {});
  }, [widgetState.bankValues]);

  const updateBankFormValues = (values) => {
    setBankValues({
      ...bankValues,
      ...values,
    });
  };

  const validate = (id, validations = {}) => {
    if (validations.required && !bankValues[id]) {
      return "Required field";
    }
  };

  const getValidations = (id) => {
    switch (id) {
      case "bankName":
      case "accountNumber":
      case "ifscCode":
        return { required: true };
      default:
        return {};
    }
  };

  const bankFormSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let bankErrors = {};
    ["bankName", "accountNumber", "ifscCode"].forEach((id) => {
      let validationError = validate(id, getValidations(id));
      if (validationError) {
        bankErrors[id] = validationError;
        isErrorFound = true;
      }
    });

    if (!isErrorFound) {
      if (bankValues.accountNumber !== bankValues.confirmAccountNumber) {
        bankErrors.confirmAccountNumber = "Account numbers are not same";
        isErrorFound = true;
      }
      if (bankValues.ifscCode !== bankValues.confirmIfscCode) {
        bankErrors.confirmIfscCode = "IFSC codes are not same";
        isErrorFound = true;
      }
    }

    let accountId, accountType;

    if (user.role === "EMPLOYER") {
      accountId = workspace.id;
      accountType = "WORKSPACE";
    } else {
      accountId = user.id;
      accountType = "USER";
    }

    bankValues.accountId = accountId;
    bankValues.accountType = accountType;

    if (isErrorFound) {
      setBankErrors(bankErrors);
    } else {
      setIsSaving(true);
      try {
        await updateBankDetails(user.id, bankValues);
        setIsSaving(false);
      } catch (e) {
        setIsSaving(false);
        toaster.negative(<div>Error while saving. Please try again.</div>, {
          autoHideDuration: 3000,
        });
        return;
      }
      setBankErrors({});
      setWidgetState({
        isBankModalVisible: false,
      });
      onAdded(bankValues);
    }
  };

  return (
    <Modal
      animate
      autoFocus
      isOpen={widgetState.isBankModalVisible}
      closeable
      onClose={() => {
        setWidgetState({
          isBankModalVisible: false,
        });
      }}
    >
      <form onSubmit={bankFormSubmit} autoComplete="off">
        <ModalHeader>
          <Title>{"Confirm your bank details"}</Title>
        </ModalHeader>
        <ModalBody>
          <FormControl
            label={
              <Label subtitle={"Bank to which we should transfer the amount"}>
                {"Enter bank name"}
              </Label>
            }
            caption={<div></div>}
            error={bankErrors.bankName}
          >
            <Input
              onChange={(e) =>
                updateBankFormValues({ bankName: e.target.value })
              }
              value={bankValues.bankName}
              autoComplete={"off"}
            />
          </FormControl>
          <FormControl
            label={
              <Label subtitle={"Account number in above bank"}>
                {"Enter bank account number"}
              </Label>
            }
            caption={<div></div>}
            error={bankErrors.accountNumber}
          >
            <Input
              type={"password"}
              overrides={{
                MaskToggleButton: () => null,
              }}
              onChange={(e) =>
                updateBankFormValues({ accountNumber: e.target.value })
              }
              value={bankValues.accountNumber}
              autoComplete={"off"}
            />
          </FormControl>
          <FormControl
            label={
              <Label subtitle={"Enter the same account number as above"}>
                {"Confirm bank account number"}
              </Label>
            }
            caption={<div></div>}
            error={bankErrors.confirmAccountNumber}
          >
            <Input
              onChange={(e) =>
                updateBankFormValues({
                  confirmAccountNumber: e.target.value,
                })
              }
              value={bankValues.confirmAccountNumber}
              autoComplete={"off"}
            />
          </FormControl>
          <FormControl
            label={
              <Label subtitle={"IFSC code of your bank branch"}>
                {"Enter IFSC code"}
              </Label>
            }
            caption={<div></div>}
            error={bankErrors.ifscCode}
          >
            <Input
              type={"password"}
              overrides={{
                MaskToggleButton: () => null,
              }}
              onChange={(e) =>
                updateBankFormValues({ ifscCode: e.target.value })
              }
              value={bankValues.ifscCode}
              autoComplete={"off"}
            />
          </FormControl>
          <FormControl
            label={
              <Label subtitle={"Enter the same IFSC code as above"}>
                {"Confirm IFSC code"}
              </Label>
            }
            caption={<div></div>}
            error={bankErrors.confirmIfscCode}
          >
            <Input
              onChange={(e) =>
                updateBankFormValues({ confirmIfscCode: e.target.value })
              }
              value={bankValues.confirmIfscCode}
              autoComplete={"off"}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={isSaving} type={"submit"}>
            Update Bank Account Details
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    user: state.user,
    widgetState: state.widget,
    workspace: state.workspace,
  }),
  { setWidgetState }
)(BankDetailsModal);
