import FormControl from "../components/FormControl";
import React from "react";
import Input from "../components/Input";
import Button from "../components/Button";
import Select from "../components/Select";
import {
  addWorkspaceMember,
  editWorkspaceMember,
  getWorkspaceMembers,
  removeWorkspaceMember,
} from "../api/employer";
import { KIND, SHAPE, SIZE } from "baseui/button";
import { toaster } from "baseui/toast";
import { Icon } from "../components/Icon";
const { useEffect, useState } = require("react");

const WorkspaceMember = ({ workspaceId, member, onEdit, onCreate }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [addMemberEmail, setAddMemberEmail] = useState(
    member.memberEmail || ""
  );
  const [addMemberRole, setAddMemberRole] = useState(
    member.role
      ? { id: member.role, name: member.role }
      : { id: "MEMBER", name: "MEMBER" }
  );

  const handleAdd = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isEditable) return await handleEdit();

    if (!addMemberEmail) {
      toaster.show(<div>Please enter email</div>, { autoHideDuration: 2000 });
      return;
    }
    try {
      setIsAdding(true);
      await addWorkspaceMember({
        ...member,
        workspaceId: workspaceId,
        memberEmail: addMemberEmail,
        role: addMemberRole.id,
      });
      setIsAdding(false);
      onCreate();
    } catch (e) {
      setIsAdding(false);
    }
  };

  const handleEdit = async (e) => {
    if (!addMemberEmail) {
      toaster.show(<div>Please enter email</div>, { autoHideDuration: 2000 });
      return;
    }

    try {
      setIsAdding(true);
      await editWorkspaceMember(member.id, {
        ...member,
        workspaceId: workspaceId,
        memberEmail: addMemberEmail,
        role: addMemberRole.id,
      });
      setIsAdding(false);
      setIsEditable(false);
      onEdit();
    } catch (e) {
      setIsAdding(false);
    }
  };

  const handleRemove = async () => {
    try {
      setIsRemoving(true);
      await removeWorkspaceMember(member.id, member);
      setIsRemoving(false);
      await onEdit();
    } catch (e) {
      setIsRemoving(false);
    }
  };

  return (
    <div>
      {onCreate || isEditable ? (
        <form onSubmit={handleAdd}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <div style={{ width: "420px" }}>
              <FormControl>
                <Input
                  id={"email"}
                  placeholder={"Add email ID of the team member"}
                  onChange={(e) =>
                    setAddMemberEmail(
                      e.target.value && e.target.value.toLowerCase()
                    )
                  }
                  value={addMemberEmail}
                  autoComplete={"no"}
                />
              </FormControl>
            </div>
            <div
              style={{ width: "200px", marginLeft: "8px", marginRight: "8px" }}
            >
              <FormControl>
                <Select
                  clearable={false}
                  defaultOptions={[
                    { id: "ADMIN", name: "ADMIN" },
                    { id: "MEMBER", name: "MEMBER" },
                  ]}
                  onChange={(value) => {
                    setAddMemberRole(value);
                  }}
                  value={addMemberRole}
                />
              </FormControl>
            </div>

            <Button
              kind={KIND.secondary}
              size={SIZE.mini}
              type={"submit"}
              isLoading={isAdding}
            >
              Save
            </Button>
            {isEditable ? (
              <div style={{ marginLeft: "16px" }}>
                <Button
                  kind={KIND.minimal}
                  size={SIZE.mini}
                  type={"button"}
                  onClick={() => setIsEditable(false)}
                >
                  Cancel
                </Button>
              </div>
            ) : null}
          </div>
        </form>
      ) : (
        <div>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <div style={{ width: "420px" }}>
              <div>{addMemberEmail}</div>
            </div>

            <div
              style={{ width: "200px", marginLeft: "8px", marginRight: "8px" }}
            >
              <div>{addMemberRole.name}</div>
            </div>

            <Button
              kind={KIND.minimal}
              height={24}
              size={SIZE.compact}
              shape={SHAPE.round}
              buttonStyle={{ padding: 0, marginRight: "16px" }}
              onClick={() => {
                console.log("onEdit", onEdit, onCreate);
                setIsEditable(true);
              }}
            >
              <Icon name="edit" size={16} />
            </Button>
            <Button
              kind={KIND.minimal}
              size={SIZE.compact}
              isLoading={isRemoving}
              onClick={async () => {
                await handleRemove();
              }}
            >
              <Icon name="trash" size={16} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

const HiringWorkspaceMembers = ({ workspaceId }) => {
  const [members, setMembers] = useState([]);
  const getWorkspaceMembersAPI = async (workspaceId) => {
    try {
      let { data } = await getWorkspaceMembers(workspaceId);
      setMembers(data);
    } catch (e) {}
  };

  useEffect(() => {
    getWorkspaceMembersAPI(workspaceId);
  }, [workspaceId]);

  return (
    <div>
      {members.map((member, idx) => (
        <WorkspaceMember
          key={idx}
          workspaceId={workspaceId}
          member={member}
          onEdit={async () => {
            await getWorkspaceMembersAPI(workspaceId);
          }}
        />
      ))}
      <WorkspaceMember
        key={members.length}
        workspaceId={workspaceId}
        member={{}}
        onCreate={async () => await getWorkspaceMembersAPI(workspaceId)}
        onEdit={async () => {
          await getWorkspaceMembersAPI(workspaceId);
        }}
      />
    </div>
  );
};

export default HiringWorkspaceMembers;
