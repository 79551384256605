import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { setWidgetState } from "../redux/actions/widget";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import Job from "../components/Job";
import { reviewJob, getJobDetail, updateJobDetail } from "../api/admin";
import Button from "../components/Button";
import LoadingScreen from "../components/LoadingScreen";
import { toaster } from "baseui/toast";
import FormControl from "../components/FormControl";
import Label from "../components/Label";
import EmptyCaption from "../components/EmptyCaption";
import TextArea from "../components/TextArea";
import * as Analytics from "../analytics";
import PostJobForm from "../components/PostJobForm";
import Modal from "../components/Modal";
import { ModalBody, ModalHeader } from "baseui/modal";
import { LabelLarge, LabelMedium, ParagraphSmall } from "baseui/typography";
import { Block } from "baseui/block";
import Card from "../components/Card";

class AdminJobDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      job: undefined,
      errors: {},
    };
  }

  async onLoad() {
    try {
      let { job, user } = await getJobDetail(this.props.jobId);
      this.setState({ job, user });
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    await this.onLoad();
  }

  onReviewJob = async (status) => {
    try {
      await reviewJob(this.state.job.id, status, {
        statusReason: this.state.statusReason || "",
      });
    } catch (e) {
      console.log(e);
    }
  };

  onApproveClick = async () => {
    try {
      await this.onReviewJob("REVIEWED");
      toaster.positive(<div>{"Approved"}</div>, { autoHideDuration: 3000 });
      Analytics.logEvent("[A] approve job", {
        jobId: this.state.job.id,
      });
      this.props.history.goBack();
    } catch (e) {
      console.log(e);
    }
  };

  onRejectClick = async () => {
    if (!this.state.statusReason) {
      this.setState({
        statusReasonError: "Please add some comments if you are rejecting",
      });
      return;
    } else {
      this.setState({
        statusReasonError: "",
      });
    }
    try {
      await this.onReviewJob("ONHOLD");
      toaster.positive(<div>{"Rejected"}</div>, { autoHideDuration: 3000 });
      Analytics.logEvent("[A] reject job", {
        jobId: this.state.job.id,
      });
      this.props.history.goBack();
    } catch (e) {
      console.log(e);
    }
  };

  onUpdateJob = async (job) => {
    try {
      let newValues = JSON.parse(JSON.stringify(job));
      newValues.salaryCurrency = job.salaryCurrency.id;
      newValues.jobDescription = job.jobDescription.toString("html");
      // await updateJob(newValues.id, newValues);
      this.setState(
        {
          isEditModalVisible: false,
        },
        async () => {
          await this.onLoad();
        }
      );
    } catch (ex) {
      console.log(ex);
    }
  };

  render() {
    if (!this.state.job) {
      return <LoadingScreen />;
    }
    return (
      <div>
        <Title>Job Detail</Title>
        {this.state.user && (
          <Card marginBottom="scale600">
            <LabelLarge marginBottom={"scale400"}>Posted By</LabelLarge>
            <ParagraphSmall>{`Name: ${this.state.user.name}`}</ParagraphSmall>
            <ParagraphSmall>{`Email: ${this.state.user.email}`}</ParagraphSmall>
            <ParagraphSmall>{`Phone: ${this.state.user.phone}`}</ParagraphSmall>
            <ParagraphSmall>{`LinkedIn: ${this.state.user.linkedInProfile}`}</ParagraphSmall>
          </Card>
        )}

        <div>
          {this.state.job.status === "CREATED" ? (
            <div style={{ display: "flex", marginBottom: "32px" }}>
              <div style={{ paddingRight: "32px" }}>
                <Button
                  isLoading={this.state.isLoading}
                  onClick={this.onRejectClick}
                >
                  Reject
                </Button>
              </div>
              <Button
                isLoading={this.state.isLoading}
                onClick={this.onApproveClick}
              >
                Accept
              </Button>
              <Button
                onClick={() => {
                  this.setState({
                    isEditModalVisible: true,
                  });
                }}
              >
                Edit
              </Button>
            </div>
          ) : null}
          {this.state.job.status === "REVIEWED" ? (
            <div>
              <Button
                onClick={() => {
                  this.props.setWidgetState({
                    isShareJobModelVisible: true,
                    shareJob: this.state.job,
                    shareJobImageUrl: this.state.job.shareImageUrl,
                  });
                }}
              >
                Share
              </Button>
            </div>
          ) : null}

          <FormControl
            label={<Label>{"Any comments"}</Label>}
            caption={<EmptyCaption />}
            error={this.state.statusReasonError}
          >
            <TextArea
              placeholder={""}
              onChange={(e) => {
                if (typeof e.target.value !== "undefined") {
                  this.setState({
                    statusReason: e.target.value,
                  });
                }
              }}
              value={this.state.statusReason}
              autoComplete={"off"}
            />
          </FormControl>

          {/* <div>
            <Bold>Company Details</Bold>
            <div>
              <Select
                placeholder={"Select Company"}
                creatable
                getOptions={async (query) => searchEntity("company", query)}
                onChange={(value) => {
                  this.setState({
                    job: {
                      ...this.state.job,
                      ...{
                        company: value || { id: "", name: "" },
                      },
                    },
                  });
                }}
                value={this.state.job["company"]}
              />
            </div>
          </div> */}
          <Job job={this.state.job} showTargetCompanies showSalary>
            {this.state.job.id}
          </Job>
          <Modal
            animate
            autoFocus
            isOpen={this.state.isEditModalVisible}
            closeable
            onClose={() => {
              this.setState({
                isEditModalVisible: false,
              });
            }}
          >
            <ModalHeader>
              <Title>Edit Job Posting</Title>
            </ModalHeader>
            <ModalBody>
              <PostJobForm
                isAdmin
                buttonLabel={"Update Job"}
                job={this.state.job}
                onSubmit={async (job) => {
                  try {
                    await updateJobDetail(job);
                    await this.onLoad();
                    this.setState({
                      isEditModalVisible: false,
                    });
                  } catch (e) {
                    console.log(e);
                  }
                }}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser, setWidgetState }
)(withRouter(AdminJobDetails));
