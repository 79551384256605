import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { fetchUserConfig, setUser } from "./redux/actions/user";
import Register from "./Register";
import Login from "./Login";
import EmailVerify from "./EmailVerify";
import firebase from "firebase";
import UserRoleSelect from "./screens/UserRoleSelect";
import { ToasterContainer, PLACEMENT } from "baseui/toast";
// import {
//   TALENTSCOUT_PROFILE_DETAILS,
//   EMPLOYER_PROFILE_COMPANY,
//   CANDIDATE_FIND_JOBS,
// } from "./util/routes";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import PublicCandidate from "./screens/PublicCandidate";
import LoadingScreen from "./components/LoadingScreen";
import Admin from "./screens/Admin";
import AdminDetails from "./screens/AdminDetails";
import PublicJob from "./screens/PublicJob";
import CandidateRoute from "./screens/CandidateRoute";
import TalentscoutRoute from "./screens/TalentscoutRoute";
import EmployerRoute from "./screens/EmployerRoute";
import * as Analytics from "./analytics";
import * as Sentry from "./util/sentry";
import { getHomeRedirect } from "./util/common";
import PublicApplyJob from "./screens/public/PublicApplyJob";
import CandidatesPage from "./screens/public/CandidatesPage";
import ReviewerRoute from "./screens/ReviewerRoute";
import { ToolsRoute } from "./screens/routes/ToolsRoute";
import SubmissionReviewerRoute from "./screens/SubmissionReviewerRoute";
import "focus-visible/dist/focus-visible";
import { EmployerViewPaymentLink } from "./lib/views/EmployerViewPaymentLink";
import ReferredCandidateSummary from "./pages/candidate/referred-candidate/Summary";
import { SummaryConfirmation } from "./pages/candidate/referred-candidate/SummaryConfirmation";
import InterviewRequestSummary from "./pages/candidate/referred-candidate/InterviewRequestSummary";
import { InterviewAcceptConfirmation } from "./pages/candidate/referred-candidate/InterviewAcceptConfirmation";
import { InterviewRejectConfirmation } from "./pages/candidate/referred-candidate/InterviewRejectConfirmation";
import { SignInWithToken } from "./screens/SignInWithToken";

function NonLoggedInRoute({ user, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!(user && user.id)) {
          return children;
        }
        if (!user.emailVerified) {
          return (
            <Redirect
              to={{
                pathname: "/verifyEmail",
                state: { from: location },
              }}
            />
          );
        }
        let params = new URLSearchParams(location?.search);
        if (params.get("redirect")) {
          return <Redirect to={params.get("redirect")} />;
        }
        return (
          <Redirect
            to={{
              pathname: getHomeRedirect(user),
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

function LoggedInRoute({ user, children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (
          !user.role &&
          location.pathname !== "/user/role" &&
          location.pathname.indexOf("/candidate/verification") !== 0
        ) {
          return (
            <Redirect
              to={{
                pathname: "/user/role",
                state: { from: location },
              }}
            />
          );
        }
        if (user && user.id) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: "/login",
              search:
                "redirect=" +
                encodeURIComponent(location.pathname + "" + location.search),
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

const LoginRedirect = (props) => (
  <Redirect
    to={{
      pathname: "/login",
      state: { from: props.location },
    }}
  />
);

const App = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    firebase.auth().onIdTokenChanged(async (user) => {
      if (user && user.uid) {
        Analytics.setUser(user);
        Sentry.setUser(user);
        console.log("id token changed", user, firebase.auth().currentUser);
        dispatch(fetchUserConfig(user.uid)).then(() => setIsLoading(false));
      } else {
        setIsLoading(false);
      }
    });
  }, [dispatch]);

  if (isLoading) return <LoadingScreen show={isLoading} />;

  return (
    <ToasterContainer
      placement={PLACEMENT.top}
      overrides={{
        ToastBody: {
          style: ({ $theme }) => ({
            borderRadius: "12px",
          }),
        },
      }}
    >
      <Router>
        <Switch>
          <Route exact path="/" component={LoginRedirect} />
          <NonLoggedInRoute user={props.user} path="/login">
            <Login />
          </NonLoggedInRoute>
          <NonLoggedInRoute user={props.user} path="/register">
            <Register />
          </NonLoggedInRoute>
          <Route exact path="/verifyEmail" component={EmailVerify} />
          <Route exact path="/forgotPassword" component={ForgotPassword} />
          <Route exact path="/resetPassword" component={ResetPassword} />
          <LoggedInRoute user={props.user} path="/user/role">
            <UserRoleSelect />
          </LoggedInRoute>
          <LoggedInRoute user={props.user} path="/N0VHxB8M/admin/home/:page">
            <Admin />
          </LoggedInRoute>
          <LoggedInRoute
            user={props.user}
            path="/N0VHxB8M/admin/details/:item/:itemId"
          >
            <AdminDetails />
          </LoggedInRoute>
          <LoggedInRoute user={props.user} path="/candidate">
            <CandidateRoute />
          </LoggedInRoute>
          <LoggedInRoute user={props.user} path="/talentscout">
            <TalentscoutRoute user={props.user} />
          </LoggedInRoute>
          <LoggedInRoute user={props.user} path="/employer">
            <EmployerRoute user={props.user} />
          </LoggedInRoute>
          <LoggedInRoute user={props.user} path="/tools">
            <ToolsRoute user={props.user} />
          </LoggedInRoute>
          <LoggedInRoute user={props.user} path="/R0VHxB8M/reviewer">
            <ReviewerRoute user={props.user} />
          </LoggedInRoute>
          <LoggedInRoute user={props.user} path="/P0GIbA9C/reviewer">
            <SubmissionReviewerRoute user={props.user} />
          </LoggedInRoute>
          <Route
            exact
            path="/public/candidate/:candidateId"
            component={PublicCandidate}
          />
          <Route exact path="/public/job/:jobId" component={PublicJob} />
          <Route
            exact
            path="/public/applyjob/:jobId"
            component={PublicApplyJob}
          />
          <Route exact path="/swt" component={SignInWithToken} />
          <Route
            exact
            path="/public/referredCandidate/verification"
            component={ReferredCandidateSummary}
          />
          <Route
            exact
            path="/public/interviewRequest/summary"
            component={InterviewRequestSummary}
          />
          <Route
            exact
            path="/public/interviewRequest/confirmation/accepted"
            component={InterviewAcceptConfirmation}
          />
          <Route
            exact
            path="/public/interviewRequest/confirmation/rejected"
            component={InterviewRejectConfirmation}
          />
          <Route
            exact
            path="/public/referredCandidate/confirmation"
            component={SummaryConfirmation}
          />
          <Route
            exact
            path="/paymentLink/view"
            component={EmployerViewPaymentLink}
          />
          <Route exact path="/page/candidates" component={CandidatesPage} />
        </Switch>
      </Router>
      <LoadingScreen show={isLoading} />
    </ToasterContainer>
  );
};

// class App extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isIdTokenFetched: false,
//       isError: false,
//     };
//   }

//   async timeout(ms) {
//     return new Promise((resolve) => setTimeout(resolve, ms));
//   }

//   async componentDidMount() {
//     firebase.auth().currentUser &&
//       (await firebase.auth().currentUser.getIdToken());
//     this.setState({
//       isIdTokenFetched: false,
//       isError: false,
//     });
//     firebase.auth().onIdTokenChanged(async (user) => {
//       this.setState({
//         isIdTokenFetched: true,
//       });
//       if (!user) {
//         this.props.setUser({ id: "" });
//       } else {
//         try {
//           let savedUser = await getUser(user.uid);
//           Analytics.setUser(user);
//           Sentry.setUser(user);
//           this.props.setUser({
//             email: user.email,
//             photoURL: user.photoURL,
//             id: user.uid,
//             provider: "email",
//             idToken: await user.getIdToken(),
//             ...savedUser,
//           });
//         } catch (e) {}
//       }
//     });
//   }

//   render() {
//     if (!this.state.isIdTokenFetched) {
//       return <LoadingScreen />;
//     }

//     if (this.state.isError) {
//       return (
//         <div>
//           <ErrorPage />
//         </div>
//       );
//     }

//     return (
//       <ToasterContainer placement={PLACEMENT.top}>
//         <Router>
//           <Switch>
//             <Route exact path="/" component={LoginRedirect} />
//             <NonLoggedInRoute user={this.props.user} path="/login">
//               <Login />
//             </NonLoggedInRoute>
//             <NonLoggedInRoute user={this.props.user} path="/register">
//               <Register />
//             </NonLoggedInRoute>
//             <Route exact path="/verifyEmail" component={EmailVerify} />
//             <Route exact path="/forgotPassword" component={ForgotPassword} />
//             <Route exact path="/resetPassword" component={ResetPassword} />
//             <LoggedInRoute user={this.props.user} path="/user/role">
//               <UserRoleSelect />
//             </LoggedInRoute>
//             <LoggedInRoute
//               user={this.props.user}
//               path="/N0VHxB8M/admin/home/:page"
//             >
//               <Admin />
//             </LoggedInRoute>
//             <LoggedInRoute
//               user={this.props.user}
//               path="/N0VHxB8M/admin/details/:item/:itemId"
//             >
//               <AdminDetails />
//             </LoggedInRoute>
//             <LoggedInRoute user={this.props.user} path="/candidate">
//               <CandidateRoute />
//             </LoggedInRoute>
//             <LoggedInRoute user={this.props.user} path="/talentscout">
//               <TalentscoutRoute user={this.props.user} />
//             </LoggedInRoute>
//             <LoggedInRoute user={this.props.user} path="/employer">
//               <EmployerRoute user={this.props.user} />
//             </LoggedInRoute>
//             <Route
//               exact
//               path="/public/candidate/:candidateId"
//               component={PublicCandidate}
//             />
//             <Route exact path="/public/job/:jobId" component={PublicJob} />
//           </Switch>
//         </Router>
//       </ToasterContainer>
//     );
//   }
// }

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(App);
