import api from "./index";
import { baseUrl } from "./endpoints";
import { Education, Entity, Experience, Showcase, Certification, Article, Project } from "src/lib/types/candidate";

export type CandidateProps = {
    userId: string;
    careerSummary: string;
    experience: Experience[];
    education: Education[];
    skills: Entity[];
    projects: Project[];
    premium?: {
        workShowcases?: Showcase[];
        certifications?: Certification[];
        articles?: Article[];
    };
};


export async function getAnonymousCandidateApi(id: string) {
    const candidate: CandidateProps = await api({
        baseUrl,
        url: "/api/anonymousCandidate/" + id,
        method: "GET",
        data: {},
    });
    return candidate
}

export type UpdateProps = Partial<CandidateProps>


export async function updateAnonymousCandidateApi(id: string, data: UpdateProps) {
    const updated: CandidateProps = await api({
        baseUrl,
        url: "/api/anonymousCandidate/" + id,
        method: "POST",
        data,
    });
    return updated;
}