import React, { Suspense } from "react";
import { styled } from "baseui";
import { getWalletTransactionDetails } from "../api/wallet";
import { prettyPrint as printDate } from "../util/time";
import Currency from "./Currency";
import Button from "./Button";
import Bold from "./Bold";
import { KIND, SIZE } from "baseui/button";
import Card from "./Card";
import { LabelSmall, ParagraphSmall, ParagraphXSmall } from "baseui/typography";

const LazyInvoicePDFDownload = React.lazy(() =>
  import("../components/InvoicePDFDownload")
);

const Wrapper = styled(Card, ({ $theme }) => ({
  borderRadius: 0,
  borderBottom: 0,
  ":first-child": {
    borderTopLeftRadius: $theme.borders.radius200,
    borderTopRightRadius: $theme.borders.radius200,
  },
  ":last-child": {
    borderBottomLeftRadius: $theme.borders.radius200,
    borderBottomRightRadius: $theme.borders.radius200,
    borderBottom: `${$theme.borders.border300.borderWidth} ${$theme.borders.border300.borderStyle} ${$theme.borders.border300.borderColor}`,
  },
}));

const Row = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

const Positive = styled("div", (props) => {
  return {
    color: "#018E42",
  };
});

const Negative = styled("div", {
  color: "#ED1C24",
});

class WalletTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMore: false,
      isFetching: false,
      details: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.walletTransaction.id !== prevProps.walletTransaction.id) {
      this.setState({
        isMore: false,
        details: {},
      });
    }
  }

  onMoreClick = async () => {
    if (this.state.isMore) {
      this.setState({
        isMore: false,
        details: {},
      });
      return;
    }
    try {
      this.setState({ isFetching: true });
      let details = await getWalletTransactionDetails(
        this.props.walletTransaction.accountId,
        this.props.walletTransactionaccountType,
        this.props.walletTransaction.id
      );
      this.setState({
        details,
        isFetching: false,
        isMore: true,
      });
    } catch (e) {
      this.setState({ isFetching: false });
      console.log(e);
    }
  };

  render() {
    return (
      <Wrapper>
        <Row>
          <div>
            <LabelSmall>
              {this.props.walletTransaction.description ||
                this.props.walletTransaction.description}
            </LabelSmall>
            <ParagraphSmall>
              {printDate(this.props.walletTransaction.createdAt)}
            </ParagraphSmall>
            {this.props.walletTransaction.paymentId ? (
              <div>
                <ParagraphXSmall color="primary500">
                  {`Payment Id:${this.props.walletTransaction.paymentId}`}
                </ParagraphXSmall>
              </div>
            ) : null}
          </div>
          <div>
            {this.props.walletTransaction.amount > 0 ? (
              <Positive>
                <Currency
                  decimals
                  currency={this.props.walletTransaction.currency}
                  value={parseFloat(this.props.walletTransaction.amount / 100)}
                />
              </Positive>
            ) : (
              <Negative>
                <Currency
                  decimals
                  currency={this.props.walletTransaction.currency}
                  value={parseFloat(this.props.walletTransaction.amount / 100)}
                />
              </Negative>
            )}
          </div>
        </Row>
        {!!this.props.walletTransaction.invoices.length && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              isLoading={this.state.isFetching}
              onClick={this.onMoreClick}
              kind={KIND.tertiary}
              size={SIZE.mini}
            >
              {this.state.isMore ? "Close Details" : "View Details"}
            </Button>
          </div>
        )}
        {this.state.details && this.state.details.data ? (
          <div>
            {this.state.details.data.map((txn, idx) => (
              <div
                key={idx}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  borderBottom:
                    idx !== this.state.details.data.length - 1
                      ? "1px solid #eee"
                      : "0px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div style={{ paddingRight: "16px" }}>{"#" + idx}</div>
                  <div>{txn.description}</div>
                </div>
                {txn.receiptEnabled ? (
                  <div className={"link"}>
                    <Suspense fallback={<div>Loading ...</div>}>
                      <LazyInvoicePDFDownload
                        textColor={"#333333"}
                        invoice={txn}
                      />
                    </Suspense>
                  </div>
                ) : null}
                {txn.lines.map((line) => (
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{line.description + " : "}</div>
                      <div
                        style={{
                          minWidth: "100px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Currency
                          decimals
                          currency={line.currency}
                          value={parseFloat(line.amount / 100)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Bold
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>{"Total : "}</div>
                    <div
                      style={{
                        minWidth: "100px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Currency
                        decimals
                        currency={txn.currency}
                        value={parseFloat(txn.amount / 100)}
                      />
                    </div>
                  </Bold>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </Wrapper>
    );
  }
}

export default WalletTransaction;
