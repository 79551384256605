import React, { useState } from "react";
import { Block } from "baseui/block";
import { SIZE } from "baseui/button";
import { toaster } from "baseui/toast";
import { LabelSmall } from "baseui/typography";
import { connect, useDispatch } from "react-redux";
import { updateUserMobile } from "../../api/user";
import { setUser } from "../../redux/actions/user";
import Card from "../Card";
import { useStyletron } from "baseui";
import PhoneInput from "../PhoneInput";
import Button from "../Button";
import { logEvent } from "../../analytics";

export const GetCallback = connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(({ user }) => {
  const [isLoading, setisLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [phonePrefix, setPhonePrefix] = useState({
    label: "India (भारत)",
    id: "IN",
    dialCode: "+91",
  });
  const [, theme] = useStyletron();
  const dispatch = useDispatch();
  if (user.phone) {
    return null;
  }
  return (
    <Card backgroundColor={theme.colors.accent50} marginBottom="scale600">
      <LabelSmall>
        We will call you and work with you to arrange job interviews. It's Free
        for Candidates ?
      </LabelSmall>
      <Block display="flex" alignItems="center" marginTop="scale200">
        <Block width="300px">
          <PhoneInput
            autoComplete={"no"}
            country={phonePrefix}
            onCountryChange={({ option }) => setPhonePrefix(option)}
            text={phone}
            onTextChange={(e) => setPhone(e.target.value)}
          />
        </Block>
        <Block padding="scale400" />
        <Button
          isLoading={isLoading}
          onClick={async () => {
            setisLoading(true);
            try {
              await updateUserMobile({
                phone: phone,
                phonePrefix: phonePrefix,
              });
              dispatch(
                setUser({
                  phone,
                  phonePrefix,
                })
              );
              logEvent("[C] click get callback", {
                phone,
                phonePrefix,
                email: user.email,
              });
              toaster.positive(
                <Block>
                  We will reach out to you soon. Meanwhile you can apply for few
                  jobs.
                </Block>,
                {
                  autoHideDuration: 3000,
                }
              );
            } catch (e) {}
            setisLoading(false);
          }}
          size={SIZE.compact}
        >
          Get a Callback
        </Button>
      </Block>
    </Card>
  );
});
