import React, { useState } from "react";
import { styled, withStyle } from "baseui";
import FlexRow from "./FlexRow";
import Flex from "./Flex";
import Button from "./Button";
import { KIND, SIZE } from "baseui/button";
import Card from "./Card";
import Bold from "./Bold";
import Modal from "./Modal";
import { ModalBody, ModalHeader } from "baseui/modal";
import Title from "./Title";
import HiringWorkspaceMembers from "../screens/HiringWorkspaceMembers";
import HiringWorkspaceForm from "../screens/HiringWorkspaceForm";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Icon } from "./Icon";
import { Block } from "baseui/block";

const Left = withStyle(Flex, {
  justifyContent: "space-between",
  width: "100%",
});

const Company = styled(Bold, {
  marginBottom: "4px",
});

const Wrapper = withStyle(Card, ({ isFirst, isLast, $theme }) => ({
  position: "relative",
  borderRadius: 0,
  borderTopLeftRadius: isFirst ? $theme.borders.radius300 : 0,
  borderTopRightRadius: isFirst ? $theme.borders.radius300 : 0,
  borderBottomLeftRadius: isLast ? $theme.borders.radius300 : 0,
  borderBottomRightRadius: isLast ? $theme.borders.radius300 : 0,
  ...(!isFirst && { borderTopWidth: 0 }),
}));

const TopWrapper = withStyle(Flex, {
  flexDirection: "coloum",
  justifyContent: "space-between",
});

const SkillRow = withStyle(FlexRow, {
  whiteSpace: "pre-line",
});

const WorkspaceSummary = ({
  workspace,
  membership,
  isLast,
  isFirst,
  onEdit,
}) => {
  const [isMembersFormVisible, setIsMembersFormVisible] = useState(false);
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);
  return (
    <Wrapper isLast={isLast} isFirst={isFirst}>
      <TopWrapper>
        <Left>
          <Company>{workspace.name}</Company>
          {membership && membership.role === "ADMIN" ? (
            <Block
              display="flex"
              top="scale200"
              right="scale200"
              position="absolute"
            >
              <Button
                kind={KIND.minimal}
                size={SIZE.mini}
                onClick={() => {
                  setIsMembersFormVisible(true);
                }}
              >
                <Block $marginRight="scale200">
                  <Icon size={16} name="users" />
                </Block>
                View Members
              </Button>
              <Button
                kind={KIND.minimal}
                size={SIZE.mini}
                onClick={() => {
                  setIsEditFormVisible(true);
                }}
              >
                <Block $marginRight="scale200">
                  <Icon size={16} name="edit" />
                </Block>
                Edit
              </Button>
            </Block>
          ) : null}
        </Left>
      </TopWrapper>
      <SkillRow>{workspace.createdByName}</SkillRow>
      <Modal
        animate
        autoFocus
        isOpen={isMembersFormVisible}
        closeable
        onClose={() => {
          setIsMembersFormVisible(false);
        }}
      >
        <ModalHeader>
          <Title>Workspace Members</Title>
        </ModalHeader>
        <ModalBody>
          <HiringWorkspaceMembers workspaceId={workspace.id} />
        </ModalBody>
      </Modal>
      <Modal
        animate
        autoFocus
        isOpen={isEditFormVisible}
        closeable
        onClose={() => {
          setIsEditFormVisible(false);
        }}
      >
        <ModalHeader>
          <Title>Edit Hiring Workspace</Title>
        </ModalHeader>
        <ModalBody>
          <HiringWorkspaceForm
            workspace={workspace}
            editMode
            onEdit={async () => {
              setIsEditFormVisible(false);
              await onEdit();
            }}
          />
        </ModalBody>
      </Modal>
    </Wrapper>
  );
};

export default connect((state) => ({}), {})(withRouter(WorkspaceSummary));
