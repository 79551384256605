import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import { sendCode, verifyCode } from "../api/otp";
import Button from "../components/Button";
import Verify from "../components/Verify";
import { getUser, updateUserOnboarding } from "../api/user";
import { toaster } from "baseui/toast";
import {
  TALENTSCOUT_PROFILE_DETAILS,
  TALENTSCOUT_PROFILE_REVIEW,
} from "../util/routes";
import Title from "../components/Title";
import * as Analytics from "../analytics";
import { CenterContainer } from "../components/CenterContainer";

class ScoutVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      employer: {},
      phoneVerified: false,
      isResendingPhone: false,
      isVerifyingPhone: false,
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[S] page view profile review");
    this.setState({
      isFetching: true,
    });
    try {
      this.setState({
        phoneVerified: this.props.user.phoneVerified,
      });
      if (!this.props.user.phoneVerified) {
        this.sendPhoneOTP(
          this.props.user.phonePrefix.dialCode + this.props.user.phone
        );
      }
    } catch (ex) {
      console.log(ex);
    }
    this.setState({
      isFetching: false,
    });
  }

  async verifyPhoneOTP(code) {
    this.setState({
      isVerifyingPhone: true,
    });
    try {
      await verifyCode({
        sendType: "phone",
        sendId: this.props.user.phonePrefix.dialCode + this.props.user.phone,
        code: code,
      });
      this.setState({
        phoneVerified: true,
      });
    } catch (e) {}
    this.setState({
      isVerifyingPhone: false,
    });
  }

  async sendPhoneOTP(phone) {
    this.setState({
      isResendingPhone: true,
    });
    try {
      await sendCode({
        sendType: "phone",
        sendId: phone,
      });
    } catch (e) {}
    this.setState({
      isResendingPhone: false,
    });
  }

  goToDashboard = async () => {
    if (!this.state.phoneVerified) {
      toaster.negative(<div>Please complete the verification to proceed</div>, {
        autoHideDuration: 3000,
        onClose: () => console.log("Toast closed."),
        overrides: {
          InnerContainer: {
            style: { width: "100%" },
          },
        },
      });
      return;
    }
    try {
      await updateUserOnboarding({
        role: "TALENTSCOUT",
        section: "verify",
      });
    } catch (e) {
      console.log(e);
      return;
    }
    let user = await getUser(this.props.user.id);
    this.props.setUser({
      talentScoutProfile: user.talentScoutProfile,
      talentscoutOnboarding: user.talentscoutOnboarding,
    });
    this.props.history.push(TALENTSCOUT_PROFILE_REVIEW);
  };

  buttonEnabled = () => {
    return this.state.phoneVerified;
  };

  render() {
    if (this.state.isFetching) {
      return (
        <CenterContainer>
          <div>loading...</div>
        </CenterContainer>
      );
    }
    return (
      <CenterContainer>
        <Title>Verify Details</Title>
        <div style={{ marginBottom: 16 }}>
          <div style={{ marginBottom: 16 }}>
            <Verify
              editUrl={TALENTSCOUT_PROFILE_DETAILS}
              sendType={"phone"}
              displaySendId={
                this.props.user.phonePrefix.dialCode +
                " " +
                this.props.user.phone
              }
              sendId={
                this.props.user.phonePrefix.dialCode + this.props.user.phone
              }
              isVerified={this.state.phoneVerified}
              isVerifying={this.state.isVerifyingPhone}
              isResending={this.state.isResendingPhone}
              resendCode={async () => {
                await this.sendPhoneOTP(
                  this.props.user.phonePrefix.dialCode + this.props.user.phone
                );
              }}
              verifyCode={async (code) => {
                await this.verifyPhoneOTP(code);
              }}
            />
          </div>
          <Button
            buttonStyle={{ opacity: this.buttonEnabled() ? 1 : 0.3 }}
            onClick={this.goToDashboard}
          >
            Save and Continue
          </Button>
        </div>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(ScoutVerify));
