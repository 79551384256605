import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import { getEmployersForReview } from "../api/admin";
import Button from "../components/Button";
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table";

const EmployerRow = (props) => (
  <StyledRow key={props.index}>
    <StyledCell>{props.employer.email}</StyledCell>
    <StyledCell>{props.employer.name}</StyledCell>
    <StyledCell>
      <Button onClick={() => props.onDetailsClick(props.employer.id)}>
        See details
      </Button>
    </StyledCell>
  </StyledRow>
);

class AdminEmployerReviewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      employers: [],
      hasNext: false,
      hasPrev: false,
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    try {
      let { data: employers, hasNext } = await getEmployersForReview(
        this.state.page,
        this.state.limit
      );
      this.setState({
        employers,
        hasNext,
      });
    } catch (e) {
      console.log(e);
    }
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  render() {
    return (
      <div>
        <Title>Employers</Title>
        {/* <FindUser
          onSearch={async (by, value) => {
            try {
              let { data: users, hasNext } = await findUsers(by.id, value);
              this.setState({
                employers: users,
                hasNext: hasNext,
              });
            } catch (e) {}
          }}
          onClear={async () => {
            await this.onLoad();
          }}
        /> */}
        <StyledTable>
          <StyledHead>
            <StyledHeadCell>Email</StyledHeadCell>
            <StyledHeadCell>Name</StyledHeadCell>
            <StyledHeadCell>Details</StyledHeadCell>
          </StyledHead>
          <StyledBody>
            <div style={{ marginBottom: "32px" }}>
              {this.state.employers.map((employer, index) => (
                <EmployerRow
                  key={index}
                  employer={employer}
                  onDetailsClick={(employerId) =>
                    this.props.history.push(
                      "/N0VHxB8M/admin/details/employer-review/" + employerId
                    )
                  }
                />
              ))}
            </div>
          </StyledBody>
        </StyledTable>

        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminEmployerReviewList));
