import React from "react";
import Button from "../components/Button";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import FormControl from "../components/FormControl";
import Datepicker from "../components/DateSelector";
import {
  getCandidateProjection,
  updateCandidateProfile,
} from "../api/candidate";
import Select from "../components/Select";
import EmptyCaption from "../components/EmptyCaption";
import Label from "../components/Label";
import FormWrapper from "../components/FormWrapper";
import { CANDIDATE_PROFILE_PROFILE } from "../util/routes";
import {
  getNoticePeriodOptions,
  getNoticePeriodValue,
} from "../util/noticePeriod";
import LoadingScreen from "../components/LoadingScreen";
import Title from "../components/Title";
import { RadioGroup, ALIGN, Radio } from "baseui/radio";
import * as Analytics from "../analytics";
import { CenterContainer } from "../components/CenterContainer";
import Card from "../components/Card";

class CandidateProfileNoticePeriod extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isLoading: false,
      errors: {},
      values: {},
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[C] page view profile notice period");
    this.setState({
      isFetching: true,
    });
    try {
      let candidate =
        (await getCandidateProjection(this.props.user.id, "noticeperiod")) ||
        {};
      this.setState({
        isFetching: false,
        values: {
          noticePeriod: getNoticePeriodValue(candidate.noticePeriod),
          alreadyResigned: candidate.alreadyResigned || 0,
          resignationDate: candidate.resignationDate,
        },
      });
    } catch (ex) {
      this.setState({
        isFetching: false,
      });
      console.log(ex);
    }
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && !this.state.values[id]) {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
  }

  getValidations(id) {
    switch (id) {
      case "noticePeriod":
        return { required: true };
      default:
        return {};
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let errors = {};
    ["noticePeriod"].forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });

    if (isErrorFound) {
      this.setState({
        errors,
      });
    } else {
      await this.onContinue(this.state.values);
    }
  };

  onContinue = async (values) => {
    this.setState({
      isLoading: true,
    });
    try {
      await updateCandidateProfile(this.props.user.id, "noticeperiod", {
        noticePeriod: values.noticePeriod.id,
        alreadyResigned: values.alreadyResigned,
        resignationDate: values.resignationDate,
      });
      let candidateOnboarding = this.props.user.candidateOnboarding || [];
      if (candidateOnboarding.indexOf("notice-period") === -1) {
        candidateOnboarding.push("notice-period");
      }
      this.props.setUser({
        candidateOnboarding,
      });
      this.setState({
        isLoading: false,
      });
      this.props.history.push(CANDIDATE_PROFILE_PROFILE);
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.log(e);
    }
  };

  render() {
    return (
      <CenterContainer>
        <Card>
          <FormWrapper>
            <Title>{"Notice Period"}</Title>
            <form noValidate onSubmit={this.handleSubmit} autoComplete="off">
              <FormControl
                label={<Label>{"Select your notice period"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["noticePeriod"]}
              >
                <Select
                  clearable={false}
                  searchable={false}
                  defaultOptions={getNoticePeriodOptions()}
                  onChange={(value) => {
                    this.updateFormValues("noticePeriod", value);
                  }}
                  value={this.state.values["noticePeriod"]}
                />
              </FormControl>
              <FormControl
                label={<Label>{"Are you already resigned ?"}</Label>}
                caption={<div>{""}</div>}
                error={this.state.errors["alreadyResigned"]}
              >
                <RadioGroup
                  value={this.state.values["alreadyResigned"]}
                  onChange={(e) => {
                    this.updateFormValues("alreadyResigned", +e.target.value);
                  }}
                  align={ALIGN.vertical}
                >
                  <Radio value={0}>No</Radio>
                  <Radio value={1}>Yes</Radio>
                </RadioGroup>
              </FormControl>
              {this.state.values["alreadyResigned"] === 1 ? (
                <FormControl
                  label={<Label>{"When did you resigned ?"}</Label>}
                  caption={<div>{""}</div>}
                  error={this.state.errors["resignationDate"]}
                >
                  <Datepicker
                    value={
                      this.state.values["resignationDate"]
                        ? new Date(this.state.values["resignationDate"])
                        : null
                    }
                    onChange={({ date }) => {
                      this.updateFormValues(
                        "resignationDate",
                        date && date.getTime()
                      );
                    }}
                  ></Datepicker>
                </FormControl>
              ) : null}
              <Button isLoading={this.state.isLoading} type={"submit"}>
                Save & Continue
              </Button>
            </form>
          </FormWrapper>
        </Card>
        <LoadingScreen show={this.state.isFetching} />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateProfileNoticePeriod));
