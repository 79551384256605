import React from "react";
import { styled } from "baseui";
import PageHeader from "./components/Header";
import { setUser, signoutUser } from "./redux/actions/user";
import { getUser } from "./api/user";
import { connect } from "react-redux";
import firebase from "firebase";
import Input from "./components/Input";
import Label from "./components/Label";
import { FormControl } from "baseui/form-control";
import { validateEmail, validatePassword } from "./util/validator";
import { toaster } from "baseui/toast";
import * as Analytics from "./analytics";
import * as Sentry from "./util/sentry";
import RouteLink from "./components/RouteLink";
import Card from "./components/Card";
import { HeadingSmall, ParagraphSmall } from "baseui/typography";
import { GoogleLogin } from "./GoogleLogin";
import Title from "./components/Title";
import { Block } from "baseui/block";
import { sendCode } from "./api/otp";
import { Text, Button, Box, PinInput, PinInputField } from "@chakra-ui/react";
import { otpAuth } from "./api/auth";
import { withRouter } from "react-router-dom";

const Container = styled(Card, {
  maxWidth: "500px",
  margin: "auto",
});

const TitleWrapper = styled("div", {
  marginBottom: "16px",
});

const GButtonWrapper = styled("div", {
  textAlign: "center",
  paddingTop: "16px",
});

// const GImage = styled("img", {
//   marginRight: "16px",
// });

const ORWrapper = styled("div", {
  textAlign: "center",
  marginTop: "32px",
  marginBottom: "32px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Line = styled("div", {
  display: "flex",
  flex: 1,
  height: "1px",
  backgroundColor: "#f1f1f1",
});

const ORText = styled("div", {
  color: "#919191",
  marginRight: "16px",
  marginLeft: "16px",
});

const ButtonWrapper = styled("div", {
  marginBottom: "8px",
});
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      googleSignInLoading: false,
      otp: "",
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
      isOTPSent: false,
      isOTPSending: false,
      isOTPResending: false,
      isOTPVerifying: false,
    };
  }

  componentDidMount() {
    Analytics.logEvent("page view login");
    this.updateTimeRemaining();
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    this.setState({
      email: params.get("email"),
    });
  }

  updateTimeRemaining() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      let timeRemaining = this.state.timeRemaining;
      if (timeRemaining > 0) {
        this.setState({
          timeRemaining: this.state.timeRemaining - 1,
        });
        this.updateTimeRemaining();
      }
    }, 1000);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  async onResendOTP() {
    console.log("track", "onResendOTP");
    this.setState({
      isOTPResending: true,
    });
    await this.onSendOTP();
    this.setState(
      {
        timeRemaining: 60,
      },
      () => {
        this.updateTimeRemaining();
      }
    );
    this.setState({
      isOTPResending: false,
    });
  }

  async onSendOTP() {
    console.log("track", "onSendOTP");
    if (!this.checkEmail()) {
      return;
    }
    Analytics.logEvent("click login email get otp");
    sendCode({ sendType: "email", sendId: this.state.email });
    this.setState(
      {
        isOTPSent: true,
        timeRemaining: 60,
      },
      () => {
        this.updateTimeRemaining();
      }
    );
  }

  async onSendOTPClick() {
    console.log("track", "onSendOTPClick");
    this.setState({
      isOTPSending: true,
    });
    this.onSendOTP();
    this.setState({
      isOTPSending: false,
    });
  }

  async onContinue() {
    console.log("track", "onContinue");
    if (!this.checkEmail()) {
      return;
    }
    Analytics.logEvent("click submit email get otp");
    this.setState({
      isOTPVerifying: true,
    });
    try {
      const { token } = await otpAuth({
        sendId: this.state.email,
        code: this.state.otp,
      });
      let { user = {} } = await firebase.auth().signInWithCustomToken(token);
      let savedUser = await getUser(user.uid);
      Analytics.setUser(savedUser);
      Sentry.setUser(savedUser);
      this.props.setUser({
        displayName: user.displayName,
        email: user.email,
        emailVerified: true,
        isAnonymous: user.isAnonymous,
        id: user.uid,
        providerData: user.providerData,
        ...savedUser,
      });
    } catch (e) {
      toaster.negative(
        <div>{"Error while signing in. " + e?.response?.data?.message}</div>,
        {
          autoHideDuration: 4000,
        }
      );
    }
    this.setState({
      isOTPVerifying: false,
    });
  }

  handleSubmit = async (e) => {
    console.log("track", "handleSubmit");
    e.preventDefault();
    await this.onContinue();
  };

  checkEmail = (e) => {
    if (!validateEmail(this.state.email)) {
      this.setState({
        emailError: "Email is not valid",
      });
      return false;
    } else {
      this.setState({
        emailError: "",
      });
      return true;
    }
  };

  render() {
    return (
      <Block minHeight="100vh" paddingTop="80px">
        <Container padding="scale800">
          <TitleWrapper>
            <Title>
              <Block>Login to your account</Block>
            </Title>
          </TitleWrapper>
          <GButtonWrapper>
            <GoogleLogin signoutUser={this.props.signoutUser} />
          </GButtonWrapper>
          <ORWrapper>
            <Line />
            <ORText>OR</ORText>
            <Line />
          </ORWrapper>
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <FormControl
              label={<Label>{"Enter your email"}</Label>}
              error={this.state.emailError}
            >
              <Input
                id={"email"}
                onChange={(e) =>
                  this.setState({
                    email: e.target.value && e.target.value.toLowerCase(),
                  })
                }
                value={this.state.email}
                autoComplete={"off"}
                onBlur={this.checkEmail}
              />
            </FormControl>
            {this.state.isOTPSent ? (
              <>
                <FormControl
                  label={<Label>{"Enter OTP sent to your email"}</Label>}
                  error={this.state.otpError}
                >
                  <PinInput
                    otp
                    value={this.state.otp}
                    onChange={(value) => {
                      this.setState({
                        otp: value,
                      });
                    }}
                  >
                    <PinInputField mr={2} />
                    <PinInputField mr={2} />
                    <PinInputField mr={2} />
                    <PinInputField mr={2} />
                    <PinInputField mr={2} />
                    <PinInputField mr={2} />
                  </PinInput>
                </FormControl>
                <Box mb={4}>
                  {this.state.timeRemaining > 0 ? (
                    <Text textStyle={"s"}>
                      {"Not received? Resend code in " +
                        this.state.timeRemaining +
                        " seconds"}
                    </Text>
                  ) : (
                    <Button
                      type="button"
                      variant="link"
                      size="xs"
                      isLoading={this.state.isOTPResending}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.onResendOTP();
                      }}
                    >
                      Resend OTP
                    </Button>
                  )}
                </Box>
              </>
            ) : null}
            {!this.state.isOTPSent ? (
              <ButtonWrapper>
                <Button
                  colorScheme="orange"
                  isLoading={this.state.isOTPSending}
                  type={"button"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.onSendOTPClick();
                  }}
                >
                  Send OTP to Email
                </Button>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                <Button
                  colorScheme="orange"
                  isLoading={this.state.isOTPVerifying}
                  type={"submit"}
                >
                  Verify OTP
                </Button>
              </ButtonWrapper>
            )}
          </form>
          {/* <RouteLink to={"/forgotPassword"} secondary>
            Forgot your password?
          </RouteLink> */}
        </Container>
        <PageHeader notLoggedIn />
      </Block>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser, signoutUser }
)(withRouter(Login));
