import React from "react";
import { withRouter } from "react-router-dom";
import { EMPLOYER_POSTED_JOBS } from "../util/routes";
import JobSection from "./employer/JobSection";
import SidebarItem from "./SidebarItem";

class SidebarJobApplications extends React.Component {
  onPromoteProfileChange() {}

  render() {
    const { pageId, jobId, job } = this.props;
    return (
      <div>
        <JobSection job={job} />
        <SidebarItem
          icon="arrow-left"
          title={"Go back"}
          enabled
          onClick={() => {
            this.props.history.push(EMPLOYER_POSTED_JOBS);
          }}
        />
        <SidebarItem
          enabled
          icon="search"
          title={"Source"}
          selected={pageId === "search"}
          onClick={() => {
            this.props.history.push(
              "/employer/jobapplication/" + jobId + "/search"
            );
          }}
        />
        <SidebarItem
          title={"Candidate Tracker"}
          enabled
          icon="filter"
          selected={pageId === "funnel"}
          onClick={() => {
            this.props.history.push(
              "/employer/jobapplication/" + jobId + "/funnel"
            );
          }}
        />
        {/* <SidebarItem
          enabled
          icon="check-circle"
          title={"Hand Picked"}
          selected={pageId === "handpicked"}
          onClick={() => {
            this.props.history.push(
              "/employer/jobapplication/" + jobId + "/handpicked"
            );
          }}
        />
        <SidebarItem
          enabled
          icon="shopping-bag"
          title={"Bought"}
          selected={pageId === "bought"}
          onClick={() => {
            this.props.history.push(
              "/employer/jobapplication/" + jobId + "/bought"
            );
          }}
        />
        <SidebarItem
          title={"Shortlist"}
          enabled
          icon="heart"
          selected={pageId === "shortlist"}
          onClick={() => {
            this.props.history.push(
              "/employer/jobapplication/" + jobId + "/shortlist"
            );
          }}
        />
        <SidebarItem
          title={"Bin"}
          enabled
          icon="trash-2"
          selected={pageId === "bin"}
          onClick={() => {
            this.props.history.push(
              "/employer/jobapplication/" + jobId + "/bin"
            );
          }}
        /> */}
      </div>
    );
  }
}

export default withRouter(SidebarJobApplications);
