import React from "react";
import { Link } from "react-router-dom";
import Currency from "./Currency";
import Card from "./Card";
import { setWidgetState } from "../redux/actions/widget";
import ProgressSteps from "../components/ProgressSteps";
import Button from "../components/Button";
import Bold from "./Bold";
import { connect } from "react-redux";
import PopConfirm from "./PopConfirm";
import { toaster } from "baseui/toast";
import { KIND as BUTTON_KIND, SIZE as BUTTON_SIZE } from "baseui/button";
import { removeMyCandidate } from "../api/talentscout";
import { withStyle } from "baseui";
import { Block } from "baseui/block";
import { Icon } from "./Icon";

const Wrapper = withStyle(Card, ({ isClickable }) => ({
  marginBottom: "16px",
  ":hover": isClickable
    ? {
        boxShadow: "1px 2px 20px 0px rgba(0, 0, 0, 0.1)",
      }
    : {},
}));

// const Highlights = styled("div", ({ $theme }) => ({
//   color: $theme.colors.green,
//   background: $theme.colors.lightGreen,
//   borderRadius: "4px",
//   padding: "8px 16px",
//   marginBottom: "8px",
//   marginTop: "8px",
//   fontSize: "13px",
// }));

function getExperienceDuration(days) {
  return (
    <span style={{ marginRight: "8px" }}>
      {parseInt(days / 365) + " yrs " + parseInt((days % 365) / 30) + " mos"}
    </span>
  );
}

const Field = (props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      width: props.isStat ? "auto" : "300px",
      marginRight: "16px",
    }}
  >
    {props.img ? (
      <img
        src={props.img}
        height={"12px"}
        width={"12px"}
        alt={""}
        style={{ marginRight: "4px" }}
      />
    ) : null}
    {props.name ? <div style={{ marginRight: "4px" }}>{props.name}</div> : null}
    <div style={{}}>{props.value}</div>
  </div>
);

function getProgressSteps(tsRequestStatus) {
  switch (tsRequestStatus) {
    case "CREATED":
    case "USER_VERIFICATION_PENDING":
    case "USER_VERIFICATION_REJECTED":
      return {
        steps: [
          { title: "Talent-scout request created", description: "" },
          {
            title: "Pending approval from Candidate",
            description:
              "Your talent-scout request is sent to the candidate. Once candidate approves, we will notify you.",
          },
          {
            title: "Talent-scout request accepted/rejected",
            description: "Your talent-scout request has been accepted/rejected",
          },
        ],
        currentStep: 1,
        currentStatus: "PENDING FROM CANDIDATE",
        currentStepStatus: "PENDING",
      };

    case "APPROVED":
      return {
        steps: [
          { title: "Talent-scout request created", description: "" },
          {
            title: "Pending approval from Candidate",
            description:
              "Your talent-scout request is sent to the candidate. Once candidate approves, we will notify you.",
          },
          {
            title: "Talent-scout request accepted",
            description: "Your talent-scout request has been accepted",
          },
        ],
        currentStep: 2,
        currentStatus: "APPROVED",
        currentStepStatus: "SUCCESS",
      };
    case "REJECTED":
      return {
        steps: [
          { title: "Talent-scout request created", description: "" },
          {
            title: "Pending approval from Candidate",
            description:
              "Your talent-scout request is sent to the candidate. Once candidate approves, we will notify you.",
          },
          {
            title: "Talent-scout request rejected",
            description:
              "Your talent-scout request has been rejected by the candidate",
          },
        ],
        currentStep: 2,
        currentStatus: "REJECTED",
        currentStepStatus: "FAILURE",
      };
    default:
      return {
        steps: [
          { title: "Talent-scout request created", description: "" },
          {
            title: "Pending approval from Candidate",
            description:
              "Your talent-scout request is sent to the candidate. Once candidate approves, we will notify you.",
          },
          {
            title: "Talent-scout request accepted/rejected",
            description: "Your talent-scout request has been accepted/rejected",
          },
        ],
        currentStep: 1,
        currentStatus: "PENDING",
        currentStepStatus: "PENDING",
      };
  }
}

const TSMyCandidate = ({
  myCandidate,
  candidate,
  setWidgetState,
  onRemove,
  isExpandedStatus,
  isClickable,
}) => {
  const progressSteps = getProgressSteps(myCandidate.status);
  return (
    <Link
      style={{ color: "inherit" }}
      to={"/talentscout/mycandidate/" + myCandidate.id + "/details"}
    >
      <Wrapper
        isClickable={isClickable}
        className={isClickable ? "clickable" : ""}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Bold
              style={{
                fontSize: "16px",
              }}
              className={"linkText"}
            >
              {candidate.lastExperienceTitle
                ? candidate.lastExperienceTitle
                : "Fresher"}
            </Bold>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {myCandidate.status === "APPROVED" ? (
              <Button
                size={BUTTON_SIZE.mini}
                kind={BUTTON_KIND.tertiary}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setWidgetState({
                    isShareCandidateModelVisible: true,
                    shareCandidate: candidate,
                    shareCandidateImageUrl: candidate.shareImageUrl,
                  });
                }}
              >
                <Block $marginRight="scale400">
                  <Icon name="trending-up" size={16} />
                </Block>
                <span>Promote</span>
              </Button>
            ) : null}
            {myCandidate.status === "APPROVED" ? (
              <div style={{ marginLeft: "8px" }}>
                <Button
                  size={BUTTON_SIZE.mini}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setWidgetState({
                      isTSHandPickCandidateModalVisible: true,
                      tsHandPickTSRequestId: myCandidate.id,
                      tsHandPickCandidateId: candidate.userId,
                    });
                  }}
                >
                  <span style={{ fontSize: "12px" }}>
                    Send to Hiring Manager
                  </span>
                </Button>
              </div>
            ) : null}

            <div style={{ width: "16px" }}></div>
            {myCandidate.status === "APPROVED" ? (
              <div
                style={{ display: "flex", alignItems: "center" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <PopConfirm
                  showArrow
                  title={
                    <div>
                      <div>
                        {"You want to remove this candidate from your list ?"}
                      </div>
                      <div>
                        {
                          "You will have to sent another talent-scout request if you want to scout for this candidate again"
                        }
                      </div>
                    </div>
                  }
                  confirmText={"Remove"}
                  onConfirm={async () => {
                    try {
                      await removeMyCandidate(myCandidate.id);
                      toaster.positive("We have removed this candidate", {
                        autoHideDuration: 3000,
                      });
                      onRemove && onRemove();
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                >
                  <Block $cursor="pointer">
                    <Icon
                      size={20}
                      name="trash-2"
                      onClick={(e) => {
                        // e.preventDefault();
                        // e.stopPropagation();
                      }}
                    ></Icon>
                  </Block>
                </PopConfirm>
              </div>
            ) : null}
          </div>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {(candidate.experience || []).length ? (
            <Field
              img={"file-text"}
              name={"Exp"}
              value={getExperienceDuration(candidate.totalExperience)}
            />
          ) : null}
          {(candidate.experience || []).length ? (
            <Field
              img={"archive"}
              name={"Work at"}
              value={candidate.experience[0].company.name}
            />
          ) : null}
          {(candidate.education || []).length ? (
            <Field
              img={"book"}
              name={"Studied at"}
              value={candidate.education[0].college.name}
            />
          ) : null}
          <Field
            name={"Desired Salary"}
            img={"m2"}
            value={
              <Currency
                inWords
                currency={candidate.expectedCTCCurrency}
                value={candidate.expectedCTC}
              ></Currency>
            }
          />
          <Field
            name={"Notice"}
            img={"log-out"}
            value={candidate.noticePeriod + " weeks"}
          />
        </div>
        {/* {myCandidate.type === "upload" && (
          <Highlights>
            <div>Promote your candidate now.</div>
            <div>
              Your candidate can now receive an interview request. It will help
              if you start promoting your candidate's anonymous profile on
              LinkedIn.(4 of 4 steps)
            </div>
          </Highlights>
        )} */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "16px",
          }}
        >
          <div style={{ marginRight: "8px" }}>Total Earned</div>
          <Bold style={{ color: "#0F4C81" }}>
            <Currency
              currency={myCandidate.totalEarnedCurrency || "INR"}
              value={myCandidate.totalEarned / 100}
            ></Currency>
          </Bold>
        </div>
        <div
          style={{
            borderTop: "1px solid #f1f1f1",
            marginTop: "8px",
            paddingTop: "8px",
          }}
        >
          <ProgressSteps
            isExpanded={isExpandedStatus}
            steps={progressSteps.steps}
            currentStep={progressSteps.currentStep}
            currentStatus={progressSteps.currentStatus}
            currentStepStatus={progressSteps.currentStepStatus}
          />
        </div>
      </Wrapper>
    </Link>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setWidgetState }
)(TSMyCandidate);
