import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Button from "../components/Button";
import { EMPLOYER_PROFILE_DETAILS } from "../util/routes";
import Title from "../components/Title";
import FormControl from "../components/FormControl";
import Label from "../components/Label";
import PhoneInput from "../components/PhoneInput";
import Input from "../components/Input";
import EmptyCaption from "../components/EmptyCaption";
import NotServiceableModal from "./NotServiceableModal";
import { updateEmployerBasic } from "../api/employer";
import * as Analytics from "../analytics";
import { CenterContainer } from "../components/CenterContainer";

class UserCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isNotServiceableModalVisible: false,
      values: {
        name: props.user.name || "",
        phonePrefix: props.user.phonePrefix || {
          label: "India (भारत)",
          id: "IN",
          dialCode: "+91",
        },
        phone: props.user.phone || "",
        linkedInProfile: props.user.linkedInProfile,
      },
      errors: {},
      employer: {},
    };
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && !this.state.values[id]) {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
  }

  getValidations(id) {
    switch (id) {
      case "name":
      case "phone":
      case "phoneCountry":
      case "linkedInProfile":
        return { required: true };
      default:
        return {};
    }
  }

  async componentDidMount() {
    Analytics.logEvent("[E] page view profile company");
    // this.setState({
    //   isFetching: false
    // });
    // try {
    // } catch (ex) {
    //   console.log(ex);
    // }
    // this.setState({
    //   isFetching: false
    // });
  }

  onContinue = async () => {
    let values = this.state.values;
    try {
      this.setState({
        isLoading: true,
      });
      await updateEmployerBasic({
        name: values.name,
        phone: values.phone,
        phonePrefix: values.phonePrefix,
        linkedInProfile: values.linkedInProfile,
      });
      this.setState({
        isLoading: false,
      });
      let employerOnboarding = this.props.user.employerOnboarding || [];
      if (employerOnboarding.indexOf("basic") === -1) {
        employerOnboarding.push("basic");
      }
      this.props.setUser({
        name: values.name,
        phone: values.phone,
        phonePrefix: values.phonePrefix,
        linkedInProfile: values.linkedInProfile,
        employerOnboarding,
      });
      if (employerOnboarding.indexOf("taxDetails") === -1) {
        this.setState({
          isNotServiceableModalVisible: true,
        });
      } else {
        this.props.history.push(EMPLOYER_PROFILE_DETAILS);
      }
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.log(e);
      alert("Error while saving. Please retry");
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let errors = {};
    ["name", "phone", "linkedInProfile"].forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });
    if (isErrorFound) {
      this.setState({
        errors,
      });
    } else {
      console.log(this.state.values);
      await this.onContinue(this.state.values);
    }
  };

  render() {
    return (
      <CenterContainer>
        <Title>Basic Details</Title>
        <div style={{ maxWidth: "420px" }}>
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <FormControl
              label={
                <Label subtitle={"eg: John Doe"}>{"Enter your name"}</Label>
              }
              caption={<EmptyCaption />}
              error={this.state.errors["name"]}
            >
              <Input
                id={"name"}
                onChange={(e) => this.updateFormValues("name", e.target.value)}
                value={this.state.values["name"]}
                autoComplete={"off"}
                onBlur={(e) =>
                  this.updateFormErrors(
                    "name",
                    this.validate("name", this.getValidations("name"))
                  )
                }
              />
            </FormControl>
            <FormControl
              label={
                <Label subtitle={"+91 9xxxx xxxxx"}>
                  {"Enter your phone number"}
                </Label>
              }
              caption={<EmptyCaption />}
              error={
                this.state.errors["phone"] || this.state.errors["phonePrefix"]
              }
            >
              <PhoneInput
                country={this.state.values["phonePrefix"]}
                onCountryChange={({ option }) => {
                  this.updateFormValues("phonePrefix", option);
                }}
                text={this.state.values["phone"]}
                autoComplete={"off"}
                onTextChange={(e) => {
                  this.updateFormValues("phone", e.target.value);
                }}
              />
            </FormControl>
            <FormControl
              label={
                <Label
                  subtitle={
                    <div>
                      <div>This helps us to verify your details</div>
                      <div>
                        Note: FindingJo will never post anything on LinkedIn
                        without your permission
                      </div>
                    </div>
                  }
                >
                  {"Enter LinkedIn profile"}
                </Label>
              }
              caption={<EmptyCaption />}
              error={this.state.errors["linkedInProfile"]}
            >
              <Input
                id={"linkedInProfile"}
                onChange={(e) =>
                  this.updateFormValues("linkedInProfile", e.target.value)
                }
                value={this.state.values["linkedInProfile"]}
                autoComplete={"off"}
                onBlur={(e) =>
                  this.updateFormErrors(
                    "linkedInProfile",
                    this.validate(
                      "linkedInProfile",
                      this.getValidations("linkedInProfile")
                    )
                  )
                }
              />
            </FormControl>
            <Button isLoading={this.state.isLoading} type="primary">
              Save and Continue
            </Button>
          </form>
        </div>
        <NotServiceableModal
          isVisible={this.state.isNotServiceableModalVisible}
          onClose={() => {
            this.setState({
              isNotServiceableModalVisible: false,
            });
          }}
          onContinue={() => {
            this.props.history.push(EMPLOYER_PROFILE_DETAILS);
          }}
        />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(UserCompany));
