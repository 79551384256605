export function getNoticePeriodOptions() {
  let options = [];
  for (let i = 0; i <= 12; i = i + 1) {
    options.push({ id: i, name: i + " weeks" });
  }
  return options;
}

export function getNoticePeriodValue(val) {
  return {
    id: val,
    name: val + " weeks",
  };
}
