import { Box, Text, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  getReferCandidate,
  updateReferCandidate,
} from "src/api/referred_candidate";
import { ReferCandidateForm } from "src/lib/forms/Talentscout/ReferCandidate/ReferCandidateForm";
export type EditReferralType = {};
export const EditReferral = (props: EditReferralType) => {
  const [referredCandidate, setReferredCandidate] = useState<any>();
  const { referredCandidateId }: any = useParams();
  const toast = useToast();
  const history = useHistory();
  const fetchReferredCandidate = async () => {
    try {
      const _referredCandidate = await getReferCandidate(referredCandidateId);
      setReferredCandidate({
        ..._referredCandidate,
        resumeFile: _referredCandidate.resumeUrl,
        linkedInResumeFile: _referredCandidate.linkedInResumeUrl,
        hasJobOffers: !!_referredCandidate.jobOffer,
      });
    } catch (e) {}
  };
  useEffect(() => {
    fetchReferredCandidate();
  }, []);
  if (!referredCandidate) return null;
  return (
    <Box p={4} maxW={"400px"}>
      <Text textStyle="h3" mb={4}>
        Edit Referred Candidate
      </Text>
      <ReferCandidateForm
        values={referredCandidate}
        disabledFields={["email", "phone"]}
        isSubmitDisabled={referredCandidate?.status === "REJECTED"}
        onSubmit={async (values) => {
          values.status = "CREATED";
          await updateReferCandidate(values.id, values);
          toast({
            title: "Saved successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          history.goBack();
        }}
        resetOnSubmit={false}
      />
    </Box>
  );
};
