import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Switch,
  Text,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { searchEntity } from "src/api/entity";
import {
  getReferCandidate,
  ReferredCandidateProps,
  updateReferCandidate,
} from "src/api/referred_candidate";
import { AutoComplete, AutoCompleteItem } from "src/components/AutoComplete";
import Currency from "src/components/Currency";
import { ReferredCandidateSchema } from "src/lib/forms/Talentscout/ReferCandidate/ReferredCandidateSchema";
import { Entity } from "src/lib/types/candidate";
import {
  formatCurrency,
  getCurrencyWords,
  parseCurrency,
} from "src/util/currency";
import { prettyPrint } from "src/util/time";
import { Salary } from "./Salary";
import { UpdateModal } from "./UpdateModal";
export const Details = () => {
  const location = useLocation();
  const [isInvalidPage, setIsInvalidPage] = useState(false);
  const [referredCandidate, setReferredCandidate] = useState<
    ReferredCandidateProps
  >();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [editKey, setEditKey] = useState<keyof ReferredCandidateProps>("id");
  const toast = useToast();
  const history = useHistory();
  const buttonSize = useBreakpointValue({ base: "lg", md: "md" });
  const [errors, setErrors] = useState<
    {
      [key in keyof ReferredCandidateProps]?: string;
    }
  >({});
  const user = useSelector((state: any) => state.user);
  const fetchReferredCandidate = useCallback(
    async (id: string) => {
      try {
        const referredCandidate = await getReferCandidate(id);
        setReferredCandidate(referredCandidate);
      } catch (e) {
        toast({
          title: "Error while fetching details",
          description: "Please try again",
        });
      }
    },
    [toast]
  );
  const onFormValidate = async (
    editKey: keyof ReferredCandidateProps,
    value: any
  ) => {
    try {
      await ReferredCandidateSchema.validateAt(editKey, value);
      setErrors({ [editKey]: undefined });
    } catch (e) {
      if (e instanceof Error) {
        setErrors({ [editKey]: e.message });
      }
    }
  };
  useEffect(() => {
    let params = new URLSearchParams(location.search);
    if (!params.get("rid")) {
      setIsInvalidPage(true);
      return;
    }
    fetchReferredCandidate(params.get("rid") ?? "");
  }, [setIsInvalidPage, fetchReferredCandidate, location.search]);

  if (isInvalidPage) {
    return (
      <Center height="100vh">
        <Box>
          <Center mb={16}>
            <WarningIcon fontSize="5xl"></WarningIcon>
          </Center>
          <Heading fontWeight="semibold" fontSize="3xl" textAlign={"center"}>
            This link is not correct. Please check again.
          </Heading>
        </Box>
      </Center>
    );
  }

  if (!referredCandidate) return null;

  if (referredCandidate.isVerified) {
    history.push(
      "/candidate/verification/confirmation?rid=" +
        referredCandidate.id +
        "&confirm=true"
    );
    return null;
  }

  if (referredCandidate.status === "REJECTED") {
    history.push(
      "/candidate/verification/confirmation?rid=" +
        referredCandidate.id +
        "&confirm=false"
    );
    return null;
  }

  if (user.email?.toLowerCase() !== referredCandidate.email?.toLowerCase()) {
    return (
      <Center height="100vh">
        <Box>
          <Center mb={16}>
            <WarningIcon fontSize="5xl"></WarningIcon>
          </Center>
          <Heading fontWeight="semibold" fontSize="3xl" textAlign={"center"}>
            Email does not match with the submitted data.
          </Heading>
        </Box>
      </Center>
    );
  }

  return (
    <Box py={8} px={4} mb={"60px"}>
      <Heading fontWeight="semibold" fontSize="3xl">
        Confirm your details
      </Heading>
      <VStack spacing={4} alignItems="flex-start" mt={8}>
        <HStack
          justifyContent="space-between"
          width={{ base: "100%", md: "420px" }}
        >
          <Box>
            <Text>Name</Text>
            <Text fontSize="sm" color="gray.500">
              {`${referredCandidate?.firstName} ${referredCandidate?.lastName}`}
            </Text>
          </Box>
          <></>
          {/* <IconButton
            icon={<FiEdit />}
            aria-label="edit"
            onClick={() => {
              setIsUpdateModalOpen(true);
              setEditKey("email");
            }}
          ></IconButton> */}
        </HStack>
        <HStack
          justifyContent="space-between"
          width={{ base: "100%", md: "420px" }}
        >
          <Box>
            <Text>Email</Text>
            <Text fontSize="sm" color="gray.500">
              {referredCandidate?.email}
            </Text>
          </Box>
          <></>
          {/* <IconButton
            icon={<FiEdit />}
            aria-label="edit"
            onClick={() => {
              setIsUpdateModalOpen(true);
              setEditKey("email");
            }}
          ></IconButton> */}
        </HStack>
        <HStack
          width={{ base: "100%", md: "420px" }}
          justifyContent="space-between"
        >
          <Box>
            <Text>Phone</Text>
            <Text fontSize="sm" color="gray.500">
              {referredCandidate?.phone}
            </Text>
          </Box>
          <></>
          {/* <IconButton
            icon={<FiEdit />}
            aria-label="edit"
            onClick={() => {
              setIsUpdateModalOpen(true);
              setEditKey("phone");
            }}
          ></IconButton> */}
        </HStack>
        <Divider />
        <HStack
          width={{ base: "100%", md: "420px" }}
          justifyContent="space-between"
          alignItems={"flex-start"}
          height="140px"
        >
          <Box>
            <Flex>
              <Currency
                value={referredCandidate?.currentSalary / 100000}
                currency={"INR"}
                decimals
              />
              <Text ml={2}>{"Lakhs"}</Text>
            </Flex>
            <Text fontSize="sm" color="gray.500" height="48px">
              Current salary
            </Text>
            <IconButton
              mt={4}
              icon={<FiEdit />}
              aria-label="edit"
              variant="outline"
              onClick={() => {
                setIsUpdateModalOpen(true);
                setEditKey("currentSalary");
              }}
            ></IconButton>
          </Box>
          <Divider orientation="vertical" />
          <Box>
            {referredCandidate?.jobOffer?.offeredSalary ? (
              <Flex>
                <Currency
                  value={referredCandidate?.jobOffer?.offeredSalary / 100000}
                  currency={"INR"}
                  decimals
                />
                <Text ml={2}>{"Lakhs"}</Text>
              </Flex>
            ) : (
              <Text color="yellow.500">{`Not available`}</Text>
            )}
            <Text fontSize="sm" color="gray.500" height="48px">
              Highest another offer
            </Text>
            <IconButton
              mt={4}
              icon={<FiEdit />}
              aria-label="edit"
              variant="outline"
              onClick={() => {
                setIsUpdateModalOpen(true);
                setEditKey("jobOffer");
              }}
            ></IconButton>
          </Box>
          <Divider orientation="vertical" />
          <Box>
            <Flex>
              <Currency
                value={referredCandidate?.expectedSalary / 100000}
                currency={"INR"}
                decimals
              />
              <Text ml={2}>{"Lakhs"}</Text>
            </Flex>
            <Text fontSize="sm" color="gray.500" height="48px">
              Expected salary
            </Text>
            <IconButton
              mt={4}
              icon={<FiEdit />}
              aria-label="edit"
              variant="outline"
              onClick={() => {
                setIsUpdateModalOpen(true);
                setEditKey("expectedSalary");
              }}
            ></IconButton>
          </Box>
        </HStack>
        <Divider />
        <HStack
          width={{ base: "100%", md: "420px" }}
          justifyContent="space-between"
        >
          <Box>
            <Text>Resigned</Text>
            <Text fontSize="sm" color="gray.500">
              Have you already resigned ?
            </Text>
          </Box>
          <Switch
            id="isResigned"
            colorScheme="green"
            checked={referredCandidate.isResigned}
            onChange={(e) =>
              setReferredCandidate({
                ...referredCandidate,
                isResigned: e.target.checked,
              })
            }
          />
        </HStack>
        {referredCandidate.isResigned ? (
          <HStack
            width={{ base: "100%", md: "420px" }}
            justifyContent="space-between"
          >
            <Box>
              <Text>Last working date</Text>
              <Text fontSize="sm" color="gray.500">
                {prettyPrint(referredCandidate?.lastWorkingDate, true)}
              </Text>
            </Box>
            <IconButton
              icon={<FiEdit />}
              aria-label="edit"
              onClick={() => {
                setIsUpdateModalOpen(true);
                setEditKey("lastWorkingDate");
              }}
            ></IconButton>
          </HStack>
        ) : null}
        <HStack
          width={{ base: "100%", md: "420px" }}
          justifyContent="space-between"
        >
          <Box>
            <Text>Notice period</Text>
            <Text fontSize="sm" color="gray.500">
              {`${referredCandidate?.noticePeriod} weeks`}
            </Text>
          </Box>
          <IconButton
            icon={<FiEdit />}
            aria-label="edit"
            onClick={() => {
              setIsUpdateModalOpen(true);
              setEditKey("noticePeriod");
            }}
          ></IconButton>
        </HStack>

        <HStack
          width={{ base: "100%", md: "420px" }}
          justifyContent="space-between"
        >
          <Box>
            <Text>Startups</Text>
            <Text fontSize="sm" color="gray.500">
              Are you ready to work in startups ?
            </Text>
          </Box>
          <Switch
            id="isStartupReady"
            colorScheme="green"
            checked={referredCandidate.isStartupReady}
            onChange={(e) =>
              setReferredCandidate({
                ...referredCandidate,
                isStartupReady: e.target.checked,
              })
            }
          />
        </HStack>
        <HStack
          width={{ base: "100%", md: "420px" }}
          justifyContent="space-between"
        >
          <Box>
            <Text>Remote</Text>
            <Text fontSize="sm" color="gray.500">
              Are you ready to work 100% remotely ?
            </Text>
          </Box>
          <Switch
            id="isRemoteReady"
            colorScheme="green"
            checked={referredCandidate.isRemoteReady}
            onChange={(e) =>
              setReferredCandidate({
                ...referredCandidate,
                isRemoteReady: e.target.checked,
              })
            }
          />
        </HStack>
        <HStack
          width={{ base: "100%", md: "420px" }}
          justifyContent="space-between"
        >
          <Box>
            <Text>Preferred job locations</Text>
            <Text fontSize="sm" color="gray.500">
              {referredCandidate?.preferredLocations
                ?.map((loc: Entity) => loc.name)
                .join(",")}
            </Text>
          </Box>
          <IconButton
            icon={<FiEdit />}
            aria-label="edit"
            onClick={() => {
              setIsUpdateModalOpen(true);
              setEditKey("preferredLocations");
            }}
          ></IconButton>
        </HStack>
      </VStack>
      <UpdateModal
        referredCandidate={referredCandidate}
        isOpen={isUpdateModalOpen}
        onClose={() => {
          setIsUpdateModalOpen(false);
        }}
        onSaveClick={async (values) => {
          if (!referredCandidate.id) return false;
          if (Object.values(errors).filter((v) => !!v).length > 0) {
            return false;
          }
          try {
            const updatedCandidate = await updateReferCandidate(
              referredCandidate.id,
              {
                ...referredCandidate,
                ...values,
              }
            );
            setReferredCandidate(updatedCandidate);
          } catch (e) {
            return false;
          }
          return true;
        }}
        render={(values, onChange) => {
          if (editKey === "email" || editKey === "phone") {
            return (
              <FormControl isInvalid={!!errors?.[editKey]}>
                <FormLabel textTransform={"capitalize"} htmlFor={editKey}>
                  {editKey}
                </FormLabel>
                <Input
                  value={values[editKey]}
                  autoFocus
                  autoCorrect="no"
                  autoComplete="no"
                  id={editKey}
                  placeholder={`Enter ${editKey}`}
                  onChange={(e) => {
                    onChange({ [editKey]: e.target.value });
                    onFormValidate(editKey, {
                      [editKey]: e.target.value,
                    });
                  }}
                />
                <FormErrorMessage>{errors?.[editKey]}</FormErrorMessage>
              </FormControl>
            );
          }
          if (editKey === "expectedSalary" || editKey === "currentSalary") {
            return (
              <FormControl isInvalid={!!errors?.[editKey]}>
                <FormLabel htmlFor="currentSalary">
                  {editKey === "expectedSalary"
                    ? "Expected salary"
                    : "Current salary"}
                </FormLabel>
                {/* <InputGroup>
                  <Input
                    id={editKey}
                    placeholder={`Enter ${editKey}`}
                    onChange={(e) => {
                      onChange({
                        [editKey]: parseCurrency(e.target.value) * 100000,
                      });
                      onFormValidate(editKey, {
                        [editKey]: parseCurrency(e.target.value) * 100000,
                      });
                    }}
                    value={formatCurrency((values[editKey] || 0) / 100000)}
                  />
                  <InputRightAddon children="Lakhs per annum" />
                </InputGroup> */}

                <Salary
                  id={editKey}
                  placeholder={`Enter ${editKey}`}
                  onChange={(value) => {
                    onChange({
                      [editKey]: value,
                    });
                    onFormValidate(editKey, {
                      [editKey]: value,
                    });
                  }}
                  value={values[editKey] || 0}
                />

                <FormHelperText>
                  {getCurrencyWords(values[editKey] || 0)}
                </FormHelperText>
                <FormErrorMessage>{errors?.[editKey]}</FormErrorMessage>
              </FormControl>
            );
          }
          if (editKey === "lastWorkingDate") {
            return (
              <FormControl isInvalid={!!errors?.lastWorkingDate}>
                <FormLabel htmlFor="lastWorkingDate" fontWeight="500">
                  Last Working Date
                </FormLabel>
                <Input
                  id="lastWorkingDate"
                  type="date"
                  placeholder=""
                  onChange={(e) => {
                    const newDate = new Date(e.target.value);
                    // Invalid date object check
                    // eslint-disable-next-line no-self-compare
                    if (newDate.getTime() === newDate.getTime()) {
                      onChange({ [editKey]: newDate.getTime() });
                      onFormValidate(editKey, {
                        [editKey]: newDate.getTime(),
                      });
                    }
                  }}
                  value={
                    new Date(values["lastWorkingDate"] || 0)
                      .toISOString()
                      .split("T")[0]
                  }
                />
                <FormErrorMessage>{errors?.lastWorkingDate}</FormErrorMessage>
              </FormControl>
            );
          }
          if (editKey === "preferredLocations") {
            return (
              <FormControl isInvalid={!!errors?.preferredLocations}>
                <FormLabel htmlFor="preferredLocations">
                  Preferred locations
                </FormLabel>
                <AutoComplete
                  value={values[editKey]}
                  id="preferredLocations"
                  items={[]}
                  isMultiple
                  fetchItems={async (q) => searchEntity("location", q)}
                  selected={values[editKey]}
                  onItemSelect={(
                    selected: AutoCompleteItem | AutoCompleteItem[]
                  ) => {
                    onChange({ preferredLocations: selected });
                    onFormValidate(editKey, {
                      [editKey]: selected,
                    });
                  }}
                />

                <FormErrorMessage>
                  {errors?.preferredLocations}
                </FormErrorMessage>
              </FormControl>
            );
          }
          if (editKey === "noticePeriod") {
            return (
              <FormControl isInvalid={!!errors?.[editKey]}>
                <FormLabel htmlFor="noticePeriod">Notice period</FormLabel>
                <Select
                  value={values[editKey]}
                  id="noticePeriod"
                  onChange={(e) => {
                    onChange({ [editKey]: +e.target.value });
                    onFormValidate(editKey, {
                      [editKey]: +e.target.value,
                    });
                  }}
                >
                  {[
                    0,
                    1,
                    2,
                    3,
                    4,
                    5,
                    6,
                    7,
                    8,
                    9,
                    10,
                    11,
                    12,
                    13,
                    14,
                    15,
                    16,
                  ].map((i) => (
                    <option value={i}>{`${i} weeks`}</option>
                  ))}
                </Select>
                <FormErrorMessage>{errors?.[editKey]}</FormErrorMessage>
              </FormControl>
            );
          }

          if (editKey === "jobOffer") {
            return (
              <FormControl isInvalid={!!errors?.[editKey]}>
                <FormLabel htmlFor="jobOffer">Highest another offer</FormLabel>
                <Salary
                  id={editKey}
                  placeholder={`Enter another offer`}
                  onChange={(value) => {
                    const jobOffer = {
                      ...values.jobOffer,
                      offeredSalary: value,
                    };
                    onChange({ [editKey]: jobOffer });
                    onFormValidate(editKey, {
                      [editKey]: jobOffer,
                    });
                  }}
                  value={values[editKey]?.offeredSalary || 0}
                />
                <FormHelperText>
                  {getCurrencyWords(values[editKey]?.offeredSalary || 0)}
                </FormHelperText>
                <FormErrorMessage>{errors?.[editKey]}</FormErrorMessage>
              </FormControl>
            );
          }
          return null;
        }}
      />
      <Box
        position={{ base: "fixed", md: "static" }}
        bottom={0}
        right={0}
        left={0}
        mt={{ base: 0, md: 8 }}
        p={{ base: 2, md: 0 }}
        background={{ base: "white", md: "inherit" }}
        borderTopColor="gray.100"
        borderTopWidth={{ base: 2, md: 0 }}
      >
        <Button
          colorScheme="green"
          width={{ base: "100%", md: "auto" }}
          size={buttonSize}
          onClick={() => {
            history.push(
              "/candidate/verification/matches?rid=" + referredCandidate.id
            );
          }}
        >{`Confirm & Proceed`}</Button>
      </Box>
    </Box>
  );
};
