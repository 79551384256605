import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import { findUsers } from "../api/admin";
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table";
import FindUser from "../components/FindUser";
import { prettyPrint } from "../util/time";
import RouteLink from "../components/RouteLink";
import { ParagraphSmall, ParagraphXSmall } from "baseui/typography";
import { Block } from "baseui/block";

const UserRow = (props) => (
  <StyledRow key={props.index}>
    <StyledCell>
      <Block>
        <ParagraphSmall>{props.user.email}</ParagraphSmall>
        <ParagraphXSmall>{props.user.phone}</ParagraphXSmall>
        <ParagraphXSmall>{props.user.name}</ParagraphXSmall>
      </Block>
    </StyledCell>
    <StyledCell>{prettyPrint(props.user.createdAt)}</StyledCell>
    <StyledCell>
      <RouteLink to={"/N0VHxB8M/admin/details/user/" + props.user.id}>
        See details
      </RouteLink>
    </StyledCell>
  </StyledRow>
);

class AdminUsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      users: [],
      hasNext: false,
      hasPrev: false,
      by: { name: "by email", id: "email" },
      value: "",
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    try {
      this.setState({
        isLoading: true,
      });
      let { data: users, hasNext } = await findUsers(
        this.state.by.id,
        this.state.value,
        this.state.page
      );
      this.setState({
        users,
        hasNext,
        isLoading: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        isLoading: false,
      });
    }
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  render() {
    return (
      <div>
        <Title>Users</Title>
        <FindUser
          setBy={(by) => {
            this.setState({
              by,
            });
          }}
          setValue={(value) => {
            this.setState({
              value,
            });
          }}
          onSearch={async (by, value) => {
            try {
              let { data: users } = await findUsers(
                by.id,
                value,
                this.state.page
              );
              this.setState({
                users: users,
                hasNext: false,
                hasPrev: false,
              });
            } catch (e) {}
          }}
          onClear={async () => {
            await this.onLoad();
          }}
        />
        {/* <div style={{ width: "900px" }}> */}
        <StyledTable
          isLoading={this.state.isLoading}
          role="grid"
          $gridTemplateColumns="max-content auto auto auto"
        >
          <StyledHead>
            <StyledHeadCell>User</StyledHeadCell>
            <StyledHeadCell>Created At</StyledHeadCell>
            <StyledHeadCell>Details</StyledHeadCell>
          </StyledHead>
          <StyledBody>
            <div style={{ marginBottom: "32px" }}>
              {this.state.users.map((user, index) => (
                <UserRow
                  key={index}
                  user={user}
                  onDetailsClick={(userId) =>
                    this.props.history.push(
                      "/N0VHxB8M/admin/details/user/" + userId
                    )
                  }
                />
              ))}
            </div>
          </StyledBody>
        </StyledTable>
        {/* </div> */}
        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminUsersList));
