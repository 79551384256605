import React from "react";
import Carousel from "./Carousel";
import { styled } from "styletron-react";

const Container = styled("div", {
  minHeight: "250px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex !important",
  flexDirection: "column",
});

const TutorialTalentscout = (props) => (
  <Carousel>
    <Container>
      <img
        alt={""}
        height={"320px"}
        width={"100%"}
        src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/talentscout1.svg"
      ></img>
      <div style={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>
        Upload your candidate for free
      </div>
      <div
        style={{
          color: "#919191",
          fontSize: "14px",
          marginBottom: "16px",
          textAlign: "center",
        }}
      >
        Upload for free and earn money by scheduling interviews for them.
      </div>
    </Container>
    <Container>
      <img
        alt={""}
        height={"320px"}
        width={"100%"}
        src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/talentscout2.svg"
      ></img>
      <div style={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>
        Pick a candidate from our resume database
      </div>
      <div
        style={{
          color: "#919191",
          fontSize: "14px",
          marginBottom: "16px",
          textAlign: "center",
        }}
      >
        Pick candidates for small fees and earn money by scheduling interviews
        for them.
      </div>
    </Container>
    <Container>
      <img
        alt={""}
        height={"320px"}
        width={"100%"}
        src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/talentscout3.svg"
      ></img>
      <div style={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>
        You get paid for every interview
      </div>
      <div
        style={{
          color: "#919191",
          fontSize: "14px",
          marginBottom: "16px",
          textAlign: "center",
        }}
      >
        Interview fee increases per interview
      </div>
    </Container>
    <Container>
      <img
        alt={""}
        height={"320px"}
        width={"100%"}
        src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/talentscout4.svg"
      ></img>
      <div style={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>
        Spread the word about your candidate
      </div>
      <div
        style={{
          color: "#919191",
          fontSize: "14px",
          marginBottom: "16px",
          textAlign: "center",
        }}
      >
        Schedule more interviews and earn more
      </div>
    </Container>
  </Carousel>
);

export default TutorialTalentscout;
