import React from "react";
import { withRouter } from "react-router-dom";
import SidebarItem from "./SidebarItem";

import {
  TALENTSCOUT_PROFILE_DETAILS,
  TALENTSCOUT_PROFILE_REVIEW,
  TALENTSCOUT_TAX_DETAILS,
  TALENTSCOUT_PROFILE_VERIFY,
  TALENTSCOUT_MY_CANDIDATES,
} from "../util/routes";

import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";

class SidebarTalentScoutProfile extends React.Component {
  onPromoteProfileChange() { }

  render() {
    return (
      <div>
        {this.props.user.talentScoutProfile ? (
          <SidebarItem
            title={"Back to home"}
            enabled
            icon={"arrow-left"}
            onClick={() => {
              this.props.history.push(TALENTSCOUT_MY_CANDIDATES);
            }}
          />
        ) : null}
        <SidebarItem
          title={"Your details"}
          icon={"user"}
          selected={this.props.page === "details"}
          enabled={
            (this.props.user.talentscoutOnboarding || []).indexOf("details") !==
            -1
          }
          onClick={() => this.props.history.push(TALENTSCOUT_PROFILE_DETAILS)}
        />
        <SidebarItem
          title={"Tax details"}
          icon={"percent"}
          selected={this.props.page === "taxdetails"}
          enabled={
            (this.props.user.talentscoutOnboarding || []).indexOf(
              "taxDetails"
            ) !== -1
          }
          onClick={() => this.props.history.push(TALENTSCOUT_TAX_DETAILS)}
        />
        {/* <SidebarItem
          title={"Payment details"}
          onClick={() =>
            this.props.history.push(TALENTSCOUT_PROFILE_PAYMENT)
          }
          enabled={this.props.page === "payment"}
        /> */}
        <SidebarItem
          title={"Verify"}
          enabled={
            (this.props.user.talentscoutOnboarding || []).indexOf("verify") !==
            -1
          }
          icon={"user-check"}
          onClick={() => this.props.history.push(TALENTSCOUT_PROFILE_VERIFY)}
          selected={this.props.page === "verify"}
        />
        <SidebarItem
          title={"Review"}
          enabled={
            (this.props.user.talentscoutOnboarding || []).indexOf("review") !==
            -1
          }
          icon={"check-square"}
          onClick={() => this.props.history.push(TALENTSCOUT_PROFILE_REVIEW)}
          selected={this.props.page === "review"}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(SidebarTalentScoutProfile));
