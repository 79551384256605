import {
  Box,
  Input,
  Text,
  useToast,
  Button,
  Flex,
  Heading,
  Link,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import {
  getNextReferCandidateForEntityAnonymise,
  ReferredCandidateProps,
} from "src/api/referred_candidate";
import { Entity } from "src/lib/types/candidate";
import { EntityForm } from "./EntityForm";
import {
  addCollegeApi,
  addCompanyApi,
  getCollegeApi,
  getCompanyApi,
  searchEntityName,
  updateCollegeApi,
  updateCompanyApi,
} from "src/api/entityT";
import { LinkIcon } from "@chakra-ui/icons";

export type EntityAnonymisationProps = {
  candidate: ReferredCandidateProps;
  entity: Entity;
  entityType: "company" | "college";
};

export type EntityName = Entity & {
  isAlias: boolean;
  entityId: string;
};

export const EntityAnonymisation = () => {
  const [referredCandidate, setReferredCandidate] = useState<
    ReferredCandidateProps
  >();
  const [isFetching, setIsFetching] = useState(false);
  const [entity, setEntity] = useState<Entity>();
  const [entityType, setEntityType] = useState<"company" | "college">();
  const [query, setQuery] = useState<string>();
  const [entities, setEntities] = useState<EntityName[]>();
  const [selectedEntityName, setSelectedEntityName] = useState<EntityName>();
  const [selectedEntityValues, setSelectedEntityValues] = useState<Entity>();
  const [isSaving, setIsSaving] = useState(false);
  const toast = useToast();
  const [noMore, setNoMore] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const fetchNextReferredCandidateForGetAnonymisation = useCallback(async () => {
    setIsFetching(true);
    try {
      const {
        referredCandidate,
        creatableFields,
        retry,
      } = await getNextReferCandidateForEntityAnonymise("DATAVERIFIED");
      setReferredCandidate(referredCandidate);
      if (!retry && !creatableFields) {
        setNoMore(true);
        return;
      }
      if (retry) {
        setFetchCount(fetchCount + 1);
        return;
      }
      if (creatableFields.company) {
        setEntityType("company");
        setEntity(creatableFields.company);
      } else if (creatableFields.college) {
        setEntityType("college");
        setEntity(creatableFields.college);
      }
    } catch (e) {
      toast({
        title: "Error while fetching the candidate",
        description: "Please try again",
      });
    }
    setIsFetching(false);
  }, [toast, fetchCount]);
  const fetchEntityList = useCallback(async () => {
    try {
      const { data } = await searchEntityName(
        query || "",
        entityType || "company"
      );
      setEntities(data as EntityName[]);
    } catch (e) {
      toast({
        title: "Error while fetching",
      });
    }
  }, [entityType, query, toast]);
  const fetchEntityValues = useCallback(async () => {
    if (!selectedEntityName?.entityId) return;
    if (entityType === "company") {
      try {
        const data = await getCompanyApi(selectedEntityName.entityId);
        setSelectedEntityValues(data);
      } catch (e) {}
    } else if (entityType === "college") {
      try {
        const data = await getCollegeApi(selectedEntityName.entityId);
        setSelectedEntityValues(data);
      } catch (e) {}
    }
  }, [entityType, selectedEntityName]);
  useEffect(() => {
    if (entityType && query) {
      fetchEntityList();
    }
  }, [entityType, query, fetchEntityList]);
  useEffect(() => {
    if (selectedEntityName) {
      fetchEntityValues();
    }
  }, [selectedEntityName, fetchEntityValues]);
  useEffect(() => {
    fetchNextReferredCandidateForGetAnonymisation();
  }, [fetchCount, fetchNextReferredCandidateForGetAnonymisation]);
  if (noMore) {
    return (
      <Box p={8}>
        <Heading>No more candidates to anonymise</Heading>
      </Box>
    );
  }
  if (!entity || !referredCandidate) {
    return (
      <Box p={8}>
        <Button
          isLoading={isFetching}
          onClick={async () => {
            await fetchNextReferredCandidateForGetAnonymisation();
          }}
        >
          Get Candidate
        </Button>
        {isFetching ? <Heading>{`Fetching next ${fetchCount}`}</Heading> : null}
      </Box>
    );
  }
  return (
    <Box px={4} py={8}>
      <Heading
        size="lg"
        textTransform="capitalize"
        mb={8}
      >{`${entityType} Anonymisation`}</Heading>
      <Box mb={4} borderRadius={"md"} background="gray.50" p={4} boxShadow="sm">
        <SimpleGrid columns={[null, 2]} spacing={6} maxW={700}>
          <Text fontWeight={"semibold"}>Email</Text>
          <Text>{referredCandidate.email}</Text>
          <Text fontWeight={"semibold"}>LinkedIn Url</Text>
          <Link href={referredCandidate.linkedInUrl} isExternal>
            {referredCandidate.linkedInUrl}
          </Link>
          <Text textStyle="h4" letterSpacing={1} textTransform="capitalize">
            {`${entityType} Name`}
          </Text>
          <Text>{entity.name}</Text>
        </SimpleGrid>
      </Box>
      <Flex>
        <Box mr={8} minW="360px">
          <Flex direction={"column"} alignItems={"center"}>
            <Input
              placeholder={`Search ${entityType}`}
              onChange={(e) => setQuery(e.target.value)}
            />
            <Box
              height={"400px"}
              width={"360px"}
              overflow="scroll"
              mt={2}
              p={2}
              borderWidth={1}
            >
              {entities?.map((e) => {
                return (
                  <Flex
                    onClick={() => setSelectedEntityName(e)}
                    borderWidth={1}
                    mb={1}
                    alignItems="center"
                    cursor="pointer"
                    p={2}
                    background={
                      e.id === selectedEntityName?.id ? "orange.50" : "inherit"
                    }
                  >
                    <Text>{e.name}</Text>
                    {e?.isAlias ? <LinkIcon ml={2} color="yellow.500" /> : null}
                  </Flex>
                );
              })}
            </Box>
            <Text>OR</Text>
            <Button
              cursor={"pointer"}
              mt={4}
              onClick={() => {
                setSelectedEntityName(undefined);
                setSelectedEntityValues(undefined);
              }}
            >{`Add a new ${entityType}`}</Button>
          </Flex>
        </Box>
        <Box borderRadius={"md"} borderWidth={1} p={4} minW="360px">
          <Text textStyle={"h4"} mb={4} textTransform="capitalize">
            {`${selectedEntityName ? "Edit" : "Add New"} ${entityType}`}
          </Text>
          <EntityForm
            values={
              selectedEntityValues || {
                id: "",
                name: "",
                tags: [],
                location: undefined,
              }
            }
            onChange={(values) => {
              setSelectedEntityValues(values);
            }}
          />
          <Button
            cursor={"pointer"}
            mt={4}
            isLoading={isSaving}
            onClick={async () => {
              setIsSaving(true);
              try {
                if (selectedEntityValues?.id) {
                  if (entityType === "company") {
                    await updateCompanyApi(selectedEntityValues?.id, {
                      ...selectedEntityValues,
                      entityName: entity.name,
                    });
                  }
                  if (entityType === "college") {
                    await updateCollegeApi(selectedEntityValues?.id, {
                      ...selectedEntityValues,
                      entityName: entity.name,
                    });
                  }
                } else if (selectedEntityValues) {
                  if (entityType === "company") {
                    const created = await addCompanyApi(selectedEntityValues);
                    await updateCompanyApi(created.id, {
                      ...selectedEntityValues,
                      entityName: entity.name,
                    });
                  }
                  if (entityType === "college") {
                    const created = await addCollegeApi(selectedEntityValues);
                    await updateCollegeApi(created.id, {
                      ...selectedEntityValues,
                      entityName: entity.name,
                    });
                  }
                }
                setFetchCount(fetchCount + 1);
              } catch (e) {
                toast({
                  title: "Error while saving",
                  description: "Please try again",
                });
              }
              setIsSaving(false);
              setSelectedEntityName(undefined);
              setSelectedEntityValues(undefined);
            }}
          >
            {selectedEntityName ? "Save and Link" : "Add New & Link"}
          </Button>
        </Box>
      </Flex>
    </Box>
  );
};
