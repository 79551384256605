import { Box, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Skill, Entity } from "src/lib/types/candidate";
import { SkillSection } from "./SkillSection";

export type SkillRow = {
  skill: Skill;
  isNew?: boolean;
};

export type SkillListProps = {
  skills: Entity[];
  onAdd: () => void;
  onSave: (ed: Entity[]) => void;
};

const toRow = (sk: Entity[]) =>
  (sk || []).map((sk) => ({ skill: { value: sk } }));

export const SkillList = ({ skills: saved, onSave, onAdd }: SkillListProps) => {
  const [skillRows, setSkillRows] = useState(toRow(saved || []));

  useEffect(() => {
    setSkillRows(toRow(saved));
  }, [saved]);

  return (
    <Box>
      {skillRows.map((row, idx) => {
        return (
          <SkillSection
            key={idx}
            skillRow={row}
            onDiscard={() => {
              const updatedRows = skillRows
                .slice(0, idx)
                .concat(skillRows.slice(idx + 1));
              setSkillRows(updatedRows);
            }}
            onDelete={async () => {
              const updatedRows = skillRows
                .slice(0, idx)
                .concat(skillRows.slice(idx + 1));
              await onSave(updatedRows.map(({ skill }) => skill?.value));
              setSkillRows(updatedRows);
            }}
            onSave={async (ed) => {
              const updatedRows = skillRows
                .slice(0, idx)
                .concat({ skill: ed })
                .concat(skillRows.slice(idx + 1));
              await onSave(updatedRows.map(({ skill }) => skill?.value));
              setSkillRows(updatedRows);
            }}
          />
        );
      })}
      <Button
        leftIcon={<FaPlus />}
        variant={"ghost"}
        colorScheme={"blue"}
        size="sm"
        onClick={() => {
          const newEx: SkillRow = {
            skill: {},
            isNew: true,
          } as SkillRow;
          setSkillRows(skillRows.concat([newEx]));
        }}
      >
        Add skill
      </Button>
    </Box>
  );
};
