import React from "react";
import Avatar from "../components/Avatar";

const UserImage = (props) =>
  props.user && props.user.photoURL ? (
    <img
      height={props.height || 24}
      width={props.width || 24}
      src={props.user.photoURL}
      alt={"user"}
      style={{ borderRadius: 60 }}
    ></img>
  ) : (
    <Avatar
      name={props.user.email[0] + props.user.email[1]}
      size={props.size ? props.size : "70px"}
    />
  );

export default UserImage;
