import React, { useEffect } from "react";
import {
  useRouteMatch,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router";
import { Link } from "react-router-dom";
import { CandidateDataVerification } from "../tools/CandidateDataVerification";
import { Interviews } from "../tools/Interviews";
import { FaUserCircle, FaFileImport } from "react-icons/fa";

import {
  Box,
  IconButton,
  InputGroup,
  InputLeftElement,
  Input,
  useColorModeValue,
  useDisclosure,
  Text,
  Icon,
  Flex,
  Collapse,
} from "@chakra-ui/react";
import { Sidebar, SidebarNavItem } from "../../lib/components/Sidebar"; //"../lib/components/views/Sidebar";
import {
  FiArrowRight,
  FiCheck,
  FiChevronDown,
  FiMenu,
  FiSearch,
  FiSend,
  FiShare,
  FiUser,
} from "react-icons/fi";
import { PreApproval } from "src/pages/tools/referred-candidates/preapproval";
import { DataVerification } from "src/pages/tools/referred-candidates/data-verification";
import { AllReferredCandidates } from "src/pages/tools/referred-candidates/all-referred";
import { ReviewPriorityConfig } from "src/pages/tools/referred-candidates/review-priority";
import { AllUsers } from "src/pages/tools/users/all-users";
import { ReferredCandidateDetails } from "src/pages/tools/referred-candidates/all-referred/ReferredCandidateDetails";
import { EntityAnonymisation } from "src/pages/tools/referred-candidates/entity-anonymisation";
import { DataAnonymisation } from "src/pages/tools/referred-candidates/data-anonymisation";

export const ToolsRoute = ({ user }: { user: any }) => {
  let { path } = useRouteMatch();
  const { pathname } = useLocation();
  const sidebar = useDisclosure();
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.100", "gray.600");
  if (!user) return null;
  const roles = user?.roles || [];
  const isAdmin = roles.includes("ADMIN");
  const showPreApproval = isAdmin || roles.includes("PREAPPROVER");
  const showDataVerifier = isAdmin || roles.includes("DATAVERIFIER");
  const showApprover = isAdmin || roles.includes("APPROVER");
  const showInterviews = isAdmin;
  const showUsers = isAdmin;
  return (
    <Box as="section" bg={"white"} minH="100vh">
      <Sidebar isOpen={sidebar.isOpen} onClose={sidebar.onClose}>
        <SidebarNavItem icon={FaUserCircle}>Referred Candidates</SidebarNavItem>
        {isAdmin && (
          <Link to={`${path}/candidates/referred/review-priority`}>
            <SidebarNavItem
              isChild
              icon={FiShare}
              isSelected={
                pathname === `${path}/candidates/referred/review-priority`
              }
            >
              <Text>Review Priority</Text>
            </SidebarNavItem>
          </Link>
        )}
        {isAdmin && (
          <Link to={`${path}/candidates/referred/all`}>
            <SidebarNavItem
              isChild
              icon={FiShare}
              isSelected={pathname === `${path}/candidates/referred/all`}
            >
              <Text>All Referred</Text>
            </SidebarNavItem>
          </Link>
        )}
        {showPreApproval && (
          <Link to={`${path}/candidates/referred/preapproval`}>
            <SidebarNavItem
              isChild
              icon={FiShare}
              isSelected={
                pathname === `${path}/candidates/referred/preapproval`
              }
            >
              <Text>Pre-Approval</Text>
            </SidebarNavItem>
          </Link>
        )}
        {showDataVerifier && (
          <Link to={`${path}/candidates/referred/verification`}>
            <SidebarNavItem
              isChild
              icon={FiCheck}
              isSelected={
                pathname === `${path}/candidates/referred/verification`
              }
            >
              <Text>Data Verification</Text>
            </SidebarNavItem>
          </Link>
        )}
        {isAdmin && (
          <Link to={`${path}/candidates/referred/entity-anonymisation`}>
            <SidebarNavItem
              isChild
              icon={FiCheck}
              isSelected={
                pathname === `${path}/candidates/referred/entity-anonymisation`
              }
            >
              <Text>Entity Anonymisation</Text>
            </SidebarNavItem>
          </Link>
        )}
        {isAdmin && (
          <Link to={`${path}/candidates/referred/data-anonymisation`}>
            <SidebarNavItem
              isChild
              icon={FiCheck}
              isSelected={
                pathname === `${path}/candidates/referred/data-anonymisation`
              }
            >
              <Text>Data Anonymisation</Text>
            </SidebarNavItem>
          </Link>
        )}
        {showInterviews && (
          <Link to={`${path}/interviews`}>
            <SidebarNavItem
              icon={FaFileImport}
              isSelected={pathname === `${path}/interviews`}
            >
              <Text>Interviews</Text>
            </SidebarNavItem>
          </Link>
        )}
        <SidebarNavItem icon={FaUserCircle}>Users</SidebarNavItem>
        {showUsers && (
          <Link to={`${path}/all-users`}>
            <SidebarNavItem
              isChild
              icon={FaFileImport}
              isSelected={pathname === `${path}/all-users`}
            >
              <Text>All Users</Text>
            </SidebarNavItem>
          </Link>
        )}
      </Sidebar>
      <Box ml={{ base: 0, md: 60 }} transition=".3s ease">
        {/* <Flex
          as="header"
          align="center"
          justify="space-between"
          w="full"
          px="4"
          bg={bgColor}
          borderBottomWidth="1px"
          borderColor={borderColor}
          h="14"
        >
          <IconButton
            aria-label="Menu"
            display={{ base: "inline-flex", md: "none" }}
            onClick={sidebar.onOpen}
            icon={<FiMenu />}
            size="sm"
          />
          <InputGroup w="96" display={{ base: "none", md: "flex" }}>
            <InputLeftElement color="gray.500" children={<FiSearch />} />
            <Input placeholder="Search..." />
          </InputGroup>
        </Flex> */}
        <Box as="main">
          <Switch>
            <Route
              path={`${path}/candidates/referred/all/:referredCandidateId`}
            >
              <ReferredCandidateDetails userRoles={roles} />
            </Route>
            {isAdmin && (
              <Route path={`${path}/candidates/referred/all`}>
                <AllReferredCandidates />
              </Route>
            )}
            {isAdmin && (
              <Route path={`${path}/candidates/referred/review-priority`}>
                <ReviewPriorityConfig />
              </Route>
            )}
            {showPreApproval && (
              <Route path={`${path}/candidates/referred/preapproval`}>
                <PreApproval />
              </Route>
            )}
            {showDataVerifier && (
              <Route
                path={`${path}/candidates/referred/verification/:verificationId`}
              >
                <CandidateDataVerification />
              </Route>
            )}
            {showDataVerifier && (
              <Route path={`${path}/candidates/referred/verification`}>
                <DataVerification />
              </Route>
            )}
            {showDataVerifier && (
              <Route path={`${path}/candidates/referred/entity-anonymisation`}>
                <EntityAnonymisation />
              </Route>
            )}
            {showDataVerifier && (
              <Route path={`${path}/candidates/referred/data-anonymisation`}>
                <DataAnonymisation />
              </Route>
            )}
            {showInterviews && (
              <Route path={`${path}/interviews`}>
                <Interviews />
              </Route>
            )}
            {showUsers && (
              <Route path={`${path}/all-users`}>
                <AllUsers />
              </Route>
            )}
            <Route
              render={() => (
                <Redirect to={`${path}/candidates/referred/preapproval`} />
              )}
            />
          </Switch>
        </Box>
      </Box>
    </Box>
  );
};
