import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setUser } from "../redux/actions/user";
import Button from "../components/Button";
import { FormControl } from "baseui/form-control";
import Select from "../components/Select";
import Input from "../components/Input";
import Label from "../components/Label";
import { searchEntity } from "../api/entity";
import EmptyCaption from "../components/EmptyCaption";
import TextArea from "../components/TextArea";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { createWorkspace, editWorkspace } from "../api/employer";

const HiringWorkspaceForm = ({
  workspace = { taxData: {} },
  onCreate,
  onEdit,
  editMode,
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [company, setCompany] = useState(workspace.company);
  const [name, setName] = useState(workspace.name);
  const [hasGST, setHasGST] = useState(workspace.taxData.hasGST);
  const [gstNumber, setGstNumber] = useState(workspace.taxData.gstNumber);
  const [insideState, setInsideState] = useState(workspace.taxData.insideState);
  const [billingName, setBillingName] = useState(workspace.taxData.billingName);
  const [billingAddress, setBillingAddress] = useState(
    workspace.taxData.billingAddress
  );

  //   const updateFormErrors = (id, error) => {
  //     setFormErrors({ ...formErrors, ...{ [id]: error } });
  //   };

  //   const validate = (id, validations = {}) => {
  //     if (validations.required && !this.state.values[id]) {
  //       return "Required field";
  //     }
  //     if (
  //       validations.requiredArray &&
  //       (!this.state.values[id] || !this.state.values[id].length)
  //     ) {
  //       return "Required field";
  //     }
  //     if (
  //       validations.requiredSelect &&
  //       (!this.state.values[id] || !this.state.values[id].id)
  //     ) {
  //       return "Required field";
  //     }
  //   };

  //   const getValidations = (id) => {
  //     switch (id) {
  //       case "company":
  //         return { requiredSelect: true };
  //       case "name":
  //       case "billingName":
  //       case "billingAddress":
  //         return { required: true };
  //       default:
  //         return "";
  //     }
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let errors = {};

    if (!company || !company.id) {
      errors["company"] = "Required Field";
      isErrorFound = true;
    }

    if (!name) {
      errors["name"] = "Required Field";
      isErrorFound = true;
    }

    if (!billingName) {
      errors["billingName"] = "Required Field";
      isErrorFound = true;
    }

    if (!billingAddress) {
      errors["billingAddress"] = "Required Field";
      isErrorFound = true;
    }

    if (isErrorFound) {
      setFormErrors(errors);
      return;
    }
    onContinue();
  };

  const onContinue = async () => {
    setIsLoading(true);
    try {
      if (editMode) {
        await editWorkspace(workspace.id, {
          id: workspace.id,
          company,
          name,
          taxData: {
            hasGST,
            gstNumber,
            insideState,
            billingName,
            billingAddress,
          },
        });
        onEdit && onEdit();
      } else {
        await createWorkspace({
          company,
          name,
          taxData: {
            hasGST,
            gstNumber,
            insideState,
            billingName,
            billingAddress,
          },
        });
        onCreate && onCreate();
      }
    } catch (e) {}
    setIsLoading(false);
  };

  return (
    <div style={{ maxWidth: "420px", marginBottom: "32px" }}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <FormControl
          label={<Label>{"Enter workspace name"}</Label>}
          caption={<EmptyCaption />}
          error={formErrors["name"]}
        >
          <Input
            id={"name"}
            onChange={(e) => setName(e.target.value)}
            value={name}
            autoComplete={"no"}
          />
        </FormControl>

        <FormControl
          label={<Label>{"Enter company name"}</Label>}
          error={formErrors["company"]}
        >
          <Select
            placeholder={"Select Company"}
            creatable
            getOptions={async (query) => searchEntity("company", query)}
            onChange={(value) => {
              setCompany(value);
            }}
            value={company}
          />
        </FormControl>

        <FormControl
          label={<Label>{"Do you have GST number ?"}</Label>}
          caption={<EmptyCaption />}
          error={formErrors["hasGST"]}
        >
          <RadioGroup
            value={hasGST ? 1 : 0}
            onChange={(e) => {
              setHasGST(!!+e.target.value);
            }}
            align={ALIGN.vertical}
          >
            <Radio value={0}>
              <div style={{ fontSize: "14px" }}>No</div>
            </Radio>
            <Radio value={1}>
              <div style={{ fontSize: "14px" }}>Yes</div>
            </Radio>
          </RadioGroup>
        </FormControl>
        {hasGST ? (
          <FormControl
            label={<Label>{"Enter GST number"}</Label>}
            caption={<EmptyCaption />}
            error={formErrors["gstNumber"]}
          >
            <Input
              id={"gstNumber"}
              onChange={(e) => setGstNumber(e.target.value)}
              value={gstNumber}
              autoComplete={"off"}
            />
          </FormControl>
        ) : null}
        <FormControl
          label={
            <Label
              appendText={"For GST billing, your registered office is located:"}
            >
              {"Your place of business ?"}
            </Label>
          }
          caption={<EmptyCaption />}
          error={formErrors["insideState"]}
        >
          <RadioGroup
            value={insideState ? 0 : 1}
            onChange={(e) => {
              setInsideState(+e.target.value === 0);
            }}
            align={ALIGN.vertical}
          >
            <Radio value={0}>
              <div style={{ fontSize: "14px" }}>Inside Karnataka, India</div>
            </Radio>
            <Radio value={1}>
              <div style={{ fontSize: "14px" }}>Outside Karnataka, India</div>
            </Radio>
          </RadioGroup>
        </FormControl>
        <FormControl
          label={
            <Label
              appendText={
                "For GST billing Eg. Apple Technologies India Pvt Ltd"
              }
            >
              {"Enter billing name"}
            </Label>
          }
          caption={<EmptyCaption />}
          error={formErrors["billingName"]}
        >
          <Input
            id={"billingName"}
            onChange={(e) => setBillingName(e.target.value)}
            value={billingName}
            autoComplete={"no"}
          />
        </FormControl>
        <FormControl
          label={
            <Label appendText={"For GST billing"}>
              {"Enter billing address"}
            </Label>
          }
          error={formErrors["billingAddress"]}
        >
          <TextArea
            id={"billingAddress"}
            resize
            height={"100px"}
            onChange={(e) => {
              setBillingAddress(e.target.value);
            }}
            value={billingAddress}
            autoComplete={"off"}
          />
        </FormControl>
        <Button isLoading={isLoading} type={"submit"}>
          {editMode ? "Update" : "Create"}
        </Button>
      </form>
    </div>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(HiringWorkspaceForm));
