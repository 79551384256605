import React from "react";

const UnreadCount = (props) => (
  <div
    style={{
      backgroundColor: "#e7f2fb",
      borderRadius: "60px",
      padding: "0 6px",
      fontSize: "0.8rem",
      marginLeft: "8px",
    }}
  >
    {props.children}
  </div>
);

export default UnreadCount;
