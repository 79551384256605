import React from "react";
import { styled, useStyletron } from "baseui";
import { connect } from "react-redux";
import { setWidgetState } from "../redux/actions/widget";
import { Icon } from "./Icon";
import { Block } from "baseui/block";
import { LabelMedium, LabelSmall, ParagraphSmall } from "baseui/typography";

const Wrapper = styled("div", ({ $theme, selected, enabled }) => ({
  display: "flex",
  alignItems: "center",
  height: "auto",
  padding: "12px 16px",
  cursor: "pointer",
  backgroundColor: selected ? "#ffffff" : "transparent",
  boxShadow: selected
    ? "rgb(0 0 0 / 9%) 0px 1px 2px 0px, rgb(0 0 0 / 5%) 0px 4px 8px 0px"
    : "none",
  // marginRight: "32px",
  borderTopRightRadius: "8px",
  borderBottomRightRadius: "8px",
  borderLeft: selected
    ? "4px solid " + $theme.colors.accent700
    : "4px solid transparent",
  // justifyContent: "space-between",
  ":hover": {
    color: selected
      ? $theme.colors.accent
      : enabled
      ? $theme.colors.accent700
      : $theme.colors.accent,
    // backgroundColor: selected ? "#E7F2FB" : enabled ? "#F5F5F5" : "transparent",
  },
}));

// const Icon = styled("img", ({ selected, enabled }) => ({
//   opacity: selected || enabled ? 1 : 0.7,
//   height: "16px",
//   width: "16px",
//   marginRight: "8px",
// }));

// const Title = styled("span", ({ selected, enabled }) => ({
//   // color: enabled ? (selected ? "#0F4C81" : "inherit") : "#999999",
//   color: enabled || selected ? "inherit" : "#999999",
//   textTransform: "capitalize",
// }));

const SidebarItem = (props) => {
  const [, theme] = useStyletron();
  return (
    <Wrapper
      selected={props.selected}
      enabled={props.enabled ? 1 : 0}
      onClick={
        props.enabled
          ? () => {
              props.onClick();
              if (props.isSidebarVisible) {
                props.setWidgetState({ isSidebarVisible: false });
              }
            }
          : () => {}
      }
    >
      <div style={{ alignItems: "center", display: "flex" }}>
        {props.icon ? (
          <Block $marginRight="scale400">
            {props.isCompleted ? (
              <Icon size={16} name={"check"} color={"green"}></Icon>
            ) : (
              <Icon
                size={16}
                name={props.icon}
                color={
                  props.selected
                    ? theme.colors.accent
                    : theme.colors.contentPrimary
                }
              ></Icon>
            )}
          </Block>
        ) : null}
        <LabelMedium
          color={
            props.selected ? theme.colors.accent : theme.colors.contentPrimary
          }
        >
          {props.title}
        </LabelMedium>
        {/* {props.title ? (
          <Title selected={props.selected} enabled={props.enabled ? 1 : 0}>
            {props.title}
          </Title>
        ) : null} */}
      </div>
      {props.children}
    </Wrapper>
  );
};

export default connect(
  (state) => ({
    isSidebarVisible: state.widget.isSidebarVisible,
  }),
  { setWidgetState }
)(SidebarItem);
