import React from "react";
import { Button, Flex, BoxProps, Text } from "@chakra-ui/react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export type PaginationProps = {
  hasNext: boolean;
  page: number;
  setPage: (page: number) => void;
  onPrevClick?: () => void;
  onNextClick?: () => void;
} & BoxProps;
export const Pagination = ({
  page,
  hasNext,
  setPage,
  onPrevClick,
  onNextClick,
  ...rest
}: PaginationProps) => {
  const hasPrev = page > 0;
  const _onPrevClick = () => {
    page > 0 && setPage(page - 1);
    onPrevClick?.();
  };
  const _onNextClick = () => {
    hasNext && setPage(page + 1);
    onNextClick?.();
  };
  return (
    <Flex alignItems={"center"} justifyContent={"center"} {...rest}>
      <Button
        disabled={!hasPrev}
        variant={"ghost"}
        size="sm"
        onClick={_onPrevClick}
        leftIcon={<FaChevronLeft />}
      >
        Prev
      </Button>
      <Text mx={2}>{`${page + 1}`}</Text>
      <Button
        disabled={!hasNext}
        variant="ghost"
        size="sm"
        onClick={_onNextClick}
        rightIcon={<FaChevronRight />}
      >
        Next
      </Button>
    </Flex>
  );
};

export default Pagination;
