import React from "react";
import {
  LabelMedium,
  LabelSmall,
  ParagraphSmall,
  ParagraphXSmall,
} from "baseui/typography";

const Label = ({ appendText, subtitle, children }) => (
  <div>
    <div>
      <span>
        <LabelMedium>{children}</LabelMedium>
        <ParagraphSmall color="#919191">{appendText}</ParagraphSmall>
      </span>
    </div>
    {subtitle && <ParagraphXSmall color="#919191">{subtitle}</ParagraphXSmall>}
  </div>
);

export default Label;
