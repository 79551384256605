import { Box, useToast } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  ReferredCandidateProps,
  updateReferCandidate,
} from "src/api/referred_candidate";
import { ReferCandidateForm } from "src/lib/forms/Talentscout/ReferCandidate/ReferCandidateForm";
export type PreApprovalProps = {
  candidate: ReferredCandidateProps;
  purpose?: string;
  onUpdated: (values: ReferredCandidateProps) => Promise<void>;
};
export const PreApprovalTab = ({
  candidate,
  onUpdated,
  purpose,
}: PreApprovalProps) => {
  const toast = useToast();
  const history = useHistory();
  return (
    <Box maxW={"520px"}>
      <ReferCandidateForm
        values={{
          ...candidate,
          resumeFile: candidate.resumeUrl,
          linkedInResumeFile: candidate.linkedInResumeUrl,
          hasJobOffers: !!candidate.jobOffer,
        }}
        disabledFields={["email", "phone"]}
        showStatus
        onSubmit={async (values) => {
          await updateReferCandidate(values.id, values);
          toast({
            title: "Saved successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          purpose === "PREAPPROVAL" &&
            history.push("/tools/candidates/referred/preapproval");
          await onUpdated?.(values);
        }}
        submitText={"Save Candidate"}
        resetOnSubmit={false}
      />
    </Box>
  );
};
