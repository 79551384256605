import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { Link, withRouter } from "react-router-dom";
import Button from "../components/Button";
import { getMyAssignedJobs } from "../api/talentscout";
import Title from "../components/Title";
import * as Analytics from "../analytics";
import { TALENTSCOUT_UPLOAD_CANDIDATE } from "../util/routes";
import LoadingScreen from "../components/LoadingScreen";
import JobItem from "../components/JobItem";
import { CenterContainer } from "../components/CenterContainer";

class ScoutAssignedJDs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basic: {},
      isFetching: false,
      isLoading: false,
      jobs: [],
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[S] page view assigned JDs");
    await this.onLoad();
  }

  async onLoad() {
    this.setState({
      isFetching: true,
    });
    try {
      let { data: jobs } = (await getMyAssignedJobs(this.props.user.id)) || {};
      this.setState({
        jobs,
        isFetching: false,
      });
    } catch (ex) {
      console.log(ex);
      this.setState({
        isFetching: false,
      });
    }
  }

  render() {
    if (this.state.isFetching) {
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    }
    return (
      <CenterContainer>
        <Title>
          <div>Assigned Job Descriptions</div>
          <div
            style={{
              fontWeight: "400",
              fontSize: "12px",
              verticalAlign: "middle",
              textTransform: "none",
            }}
          >
            Your daily task is to upload candidates matching the following job
            descriptions
          </div>
        </Title>
        {this.state.jobs.map((job) => (
          <Link
            to={"/talentscout/jobs/" + job.id + "/details"}
            style={{ color: "inherit" }}
          >
            <JobItem isClickable={job.status !== "CLOSED"} job={job} />
          </Link>
        ))}
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(ScoutAssignedJDs));
