import React, { useState } from "react";
import Title from "../components/Title";
import Button from "../components/Button";
import Modal from "../components/Modal";
import firebase from "firebase";
import { connect } from "react-redux";
import { signoutUser } from "../redux/actions/user";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import Bold from "../components/Bold";
import { KIND } from "baseui/button";

const NotifyMeModal = ({ isVisible, onClose, onLogout }) => {
  return (
    <Modal animate autoFocus isOpen={isVisible} closeable onClose={onClose}>
      <ModalHeader>
        <Title>{"Notify Me"}</Title>
      </ModalHeader>
      <ModalBody>
        <div>
          <div>
            Thank you for your interest in future services of FindingJo. We will
            keep you posted.
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onLogout}>Logout</Button>
      </ModalFooter>
    </Modal>
  );
};

const NotServiceableModal = ({
  isVisible,
  onClose,
  onContinue,
  signoutUser,
}) => {
  const [isNotifyMeVisible, setIsNotifyMeVisible] = useState(false);
  return (
    <div>
      <Modal
        animate
        autoFocus
        isOpen={isVisible}
        closeable
        onClose={() => {
          onClose();
        }}
      >
        <ModalHeader>
          <Title>{"Please Note"}</Title>
        </ModalHeader>
        <ModalBody>
          <div style={{ marginBottom: "16px" }}>
            FindingJo services are today available only for the following roles
            and in the following cities
          </div>
          <div style={{ marginBottom: "16px" }}>
            <Bold style={{ color: "#232323" }}>Job Postings Available</Bold>
            <ul style={{ paddingLeft: "32px" }}>
              <li>Product Management</li>
              <li>UI/UX Designer</li>
              <li>Full Stack Developer</li>
              <li>Frontend Developer</li>
              <li>Backend Developer</li>
              <li>Mobile Developer(Android & IOS)</li>
              <li>Data Scientist</li>
              <li>Engineering Manager</li>
            </ul>
          </div>
          <div>
            <Bold style={{ color: "#232323" }}>Job Locations in India</Bold>
            <ul style={{ paddingLeft: "32px" }}>
              <li>Bangalore</li>
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button kind={KIND.minimal} onClick={() => setIsNotifyMeVisible(true)}>
            Notify Me
          </Button>
          <Button
            onClick={() => {
              setIsNotifyMeVisible(false);
              onContinue();
            }}
          >
            Continue Creating Profile
          </Button>
        </ModalFooter>
      </Modal>
      <NotifyMeModal
        isVisible={isNotifyMeVisible}
        onClose={() => {
          setIsNotifyMeVisible(false);
        }}
        onLogout={async () => {
          setIsNotifyMeVisible(false);
          signoutUser();
          await firebase.auth().signOut();
        }}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  { signoutUser }
)(NotServiceableModal);
