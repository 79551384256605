import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import {
  getTalentScoutRequests,
  getMyAnonymousCandidate,
} from "../api/candidate";
import LoadingScreen from "../components/LoadingScreen";
import TSRequest from "../components/TSRequest";
import Pagination from "../components/Pagination";
import Modal from "../components/Modal";
import * as Analytics from "../analytics";
import { toaster } from "baseui/toast";
import { ModalHeader, ModalBody } from "baseui/modal";
import Resume from "../components/Resume";
import { SIZE as MODAL_SIZE } from "baseui/modal";
import { isMobile } from "react-device-detect";
import { CenterContainer } from "../components/CenterContainer";

class CandidateTalentscoutRequests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isError: false,
      isApproving: false,
      isRejecting: false,
      tabs: ["Active", "Rejected"],
      selectedTabIndex: 0,
      talentScoutRequests: [],
      page: 0,
      hasNext: false,
      hasPrev: false,
      isAnonymousCandidateLoading: false,
      anonymousCandidate: {},
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[C] page view talentscout requests");
    await this.onLoad();
  }

  onApprove = async () => {
    await this.onLoad();
  };

  onReject = async () => {
    await this.onLoad();
  };

  async onLoad() {
    this.setState({
      isFetching: true,
    });
    try {
      let { data: talentScoutRequests } = await getTalentScoutRequests(
        this.props.user.id,
        this.state.page,
        10
      );
      this.setState({
        isFetching: false,
        talentScoutRequests,
      });
      // let candidateCounts = this.props.user.candidateCounts;
      // candidateCounts.tsRequestsReceivedUnread = 0;
      // this.setUser({
      //   candidateCounts,
      // });
    } catch (e) {
      this.setState({
        isFetching: false,
      });
    }
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  async onViewAnonymousResume() {
    try {
      this.setState({
        isAnonymousCandidateLoading: true,
      });
      let anonymousCandidate = await getMyAnonymousCandidate(
        this.props.user.id
      );
      this.setState({
        isAnonymousCandidateLoading: false,
        anonymousCandidate,
        isAnonymousModalVisible: true,
      });
    } catch (e) {
      this.setState({
        isAnonymousCandidateLoading: false,
      });
      toaster.negative(
        <div>
          Error occured while fetching anonymous resume. Please try again.
        </div>,
        {
          autoHideDuration: 4000,
        }
      );
    }
  }

  render() {
    // if (this.state.isFetching) {
    //   return (
    //     <CenterContainer>

    //     </CenterContainer>
    //   );
    // }
    if (this.state.isError) {
      return (
        <CenterContainer>
          <div>Errored. Please retry.....</div>
        </CenterContainer>
      );
    }
    return (
      <CenterContainer>
        <LoadingScreen show={this.state.isFetching} />
        <Title>Talentscout Requests</Title>
        {this.state.talentScoutRequests.map((tsRequest, index) => (
          <TSRequest
            key={index}
            user={this.props.user}
            setUser={this.props.setUser}
            tsRequest={tsRequest}
            onApprove={this.onApprove}
            onReject={this.onReject}
            openIDProofModal={this.openIDProofModal}
            isAnonymousCandidateLoading={this.state.isAnonymousCandidateLoading}
            onViewAnonymousResume={() => this.onViewAnonymousResume()}
          />
        ))}
        {!this.state.talentScoutRequests.length ? (
          <div>
            <div>
              Your profile is yet to be shortlisted as a premium candidate by a
              talentscout.
            </div>
            <div>
              We are taking your profile to more scouts every day. Have
              patience.
            </div>
          </div>
        ) : (
          <Pagination
            onPrevClick={this.onPrevClick}
            onNextClick={this.onNextClick}
            hasNext={this.state.hasNext}
            hasPrev={this.state.hasPrev}
          />
        )}
        <Modal
          animate
          autoFocus
          isOpen={this.state.isAnonymousModalVisible}
          closeable
          size={isMobile ? MODAL_SIZE.auto : "800px"}
          onClose={() => {
            this.setState({
              isAnonymousModalVisible: false,
            });
          }}
        >
          <ModalHeader>
            <Title>{"Your anonymous resume"}</Title>
          </ModalHeader>
          <ModalBody>
            <Resume
              isAnonymous
              candidate={this.state.anonymousCandidate}
            ></Resume>
          </ModalBody>
        </Modal>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateTalentscoutRequests));
