import api from "./index";
import { baseUrl } from "./endpoints";

export type GetReviewPriorityResponse = {
    data: ReviewPriorityProps[]
    jobs: any[]
}


export type ReviewPriorityProps = {
    id: string
    idType: string;
    predefinedId: string,
    jobId: string
    count: number
    sequenceNo: number
    createdAt: number
    modifiedAt: number
    updatedBy: string
}

export async function getReviewPriorities(): Promise<GetReviewPriorityResponse> {
    const res: GetReviewPriorityResponse = await api({
        baseUrl,
        url: "/api/candidateReviewPriority",
        method: "GET",
        data: undefined,
    });
    return res
}

export async function addReviewPriority(data: ReviewPriorityProps) {
    return api({
        baseUrl,
        url: "/api/candidateReviewPriority",
        method: "POST",
        data
    });
}

export async function updateReviewPriority(id: string, data: Partial<ReviewPriorityProps>) {
    return api({
        baseUrl,
        url: `/api/candidateReviewPriority/${id}`,
        method: "POST",
        data: {
            id,
            ...data
        }
    });
}

export async function removeReviewPriority(id: string) {
    return api({
        baseUrl,
        url: `/api/candidateReviewPriority/${id}/remove`,
        method: "POST",
        data: { id }
    });
}

