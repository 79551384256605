import React from "react";
import { Flex } from "@chakra-ui/react";
import { Box, Heading, Text } from "@chakra-ui/layout";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/button";

export type FunnelHeaderProps = {
  jobTitle: string;
  companyName: string;
  children: any;
  onBack: () => void;
};
export const FunnelHeader = ({
  jobTitle,
  companyName,
  onBack,
  children,
}: FunnelHeaderProps) => {
  return (
    <Flex
      direction="row"
      align="center"
      justifyContent="space-between"
      pr={2}
      pl={2}
      mt={"8px"}
      position="fixed"
      top="54px"
      left={0}
      right={0}
    >
      <Flex direction="row" align="center">
        <IconButton
          aria-label="Search database"
          icon={<ChevronLeftIcon />}
          w={8}
          h={8}
          onClick={onBack}
        />
        <Box ml="4">
          <Box>
            <Heading size="sm">{jobTitle}</Heading>
          </Box>
          <Box>
            <Text>{companyName}</Text>
          </Box>
        </Box>
      </Flex>
      <Box>{children}</Box>
    </Flex>
  );
};
