import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { ModalHeader, ModalBody } from "baseui/modal";
import Modal from "../components/Modal";
import Button from "../components/Button";
import { setWidgetState } from "../redux/actions/widget";
import Title from "../components/Title";
import LoadingScreen from "../components/LoadingScreen";
import { getMyAssignedJobs } from "../api/talentscout";
import Card from "../components/Card";
import Bold from "../components/Bold";
import { SIZE } from "baseui/button";
import { createPickedCandidate } from "../api/picked_candidate";

const JobDetails = ({ job, tsRequestId, isPicked, onPicked }) => {
  const [isPushing, setIsPushing] = useState(false);
  const onHandPick = async () => {
    setIsPushing(true);
    try {
      await createPickedCandidate(job.id, {
        jobId: job.id,
        tsRequestId,
      });
      onPicked && onPicked();
    } catch (e) {}
    setIsPushing(false);
  };
  return (
    <Card style={{ marginBottom: "16px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Bold>{job.company.name}</Bold>
          <div>{job.jobTitle.name}</div>
          <div>{job.minExperience + "-" + job.maxExperience + " Years"}</div>
          <div>{job.minSalary}</div>
        </div>
        <div>
          {!isPicked ? (
            <Button isLoading={isPushing} size={SIZE.mini} onClick={onHandPick}>
              Send
            </Button>
          ) : (
            <div>Sent</div>
          )}
        </div>
      </div>
    </Card>
  );
};
const TSHandPickCandidateModal = ({ user, widgetState, setWidgetState }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [picked, setPicked] = useState([]);

  const fetchAssignedJobs = useCallback(async () => {
    setIsLoading(true);
    try {
      let { data: jobs, assigned: picked } =
        (await getMyAssignedJobs(user.id, widgetState.tsHandPickCandidateId)) ||
        {};
      setJobs(jobs);
      setPicked(picked);
    } catch (e) {}
    setIsLoading(false);
  }, [user.id, widgetState.tsHandPickCandidateId]);

  useEffect(() => {
    fetchAssignedJobs();
  }, [fetchAssignedJobs, widgetState.isTSHandPickCandidateModalVisible]);

  return (
    <Modal
      animate
      autoFocus
      isOpen={widgetState.isTSHandPickCandidateModalVisible}
      closeable
      onClose={() => {
        setWidgetState({
          isTSHandPickCandidateModalVisible: false,
        });
      }}
    >
      <ModalHeader>
        <Title>{"Your Job Descriptions"}</Title>
      </ModalHeader>
      <ModalBody>
        {isLoading && <LoadingScreen />}
        {!isLoading && (
          <div>
            {jobs.map((job, idx) => (
              <JobDetails
                key={idx}
                job={job}
                onPicked={fetchAssignedJobs}
                isPicked={picked.indexOf(job.id) !== -1}
                tsRequestId={widgetState.tsHandPickTSRequestId}
              />
            ))}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default connect(
  (state) => ({
    user: state.user,
    widgetState: state.widget,
  }),
  { setWidgetState }
)(TSHandPickCandidateModal);
