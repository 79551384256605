import React from "react";
import { styled } from "baseui";
import Button from "./components/Button";
import PageHeader from "./components/Header";
import { withRouter } from "react-router-dom";
import { setUser } from "./redux/actions/user";
import { connect } from "react-redux";
import Input from "./components/Input";
import Label from "./components/Label";
import { FormControl } from "baseui/form-control";
import { validateEmail } from "./util/validator";
import EmptyCaption from "./components/EmptyCaption";
import { sendCode } from "./api/otp";
import { checkUserExists } from "./api/user";
import * as Analytics from "./analytics";
import Card from "./components/Card";
import { HeadingSmall } from "baseui/typography";

const Container = styled(Card, {
  maxWidth: "500px",
  margin: "auto",
  marginTop: "80px",
});

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registerSuccess: false,
      isLoading: false,
      email: "",
      emailError: "",
    };
  }

  componentDidMount() {
    Analytics.logEvent("page view forgot password");
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.onContinue({
      email: this.state.email,
    });
  };

  checkEmail = (e) => {
    if (!validateEmail(this.state.email)) {
      this.setState({
        emailError: "Email is not valid",
      });
      return false;
    } else {
      this.setState({
        emailError: "",
      });
      return true;
    }
  };

  async onContinue(values) {
    Analytics.logEvent("click submit forgot password");
    let { email } = values;
    if (!this.checkEmail()) {
      return;
    }
    try {
      this.setState({
        isLoading: true,
      });
      let { exists } = await checkUserExists({
        email: email,
      });
      if (!exists) {
        this.setState({
          isLoading: false,
        });
        return;
      }
      await sendCode({
        sendType: "email",
        sendId: email,
      });
      this.props.history.push({
        pathname: "/resetPassword",
        state: { email },
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    return (
      <div>
        <Container>
          <HeadingSmall>Forgot Password</HeadingSmall>
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <FormControl
              label={<Label>{"Enter your email"}</Label>}
              caption={<EmptyCaption />}
              error={this.state.emailError}
            >
              <Input
                id={"email"}
                onChange={(e) => this.setState({ email: e.target.value })}
                value={this.state.email}
                autoComplete={"off"}
                onBlur={this.checkEmail}
              />
            </FormControl>
            <Button isLoading={this.state.isLoading} type={"submit"}>
              Continue
            </Button>
          </form>
        </Container>
        <PageHeader notLoggedIn />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(ForgotPassword));
