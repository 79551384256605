import * as Yup from "yup";
export const ReferredCandidateSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Required"),
  phone: Yup.string()
    .required("Required")
    .matches(/^\d{10}$/g, "Should be 10 digits"),
  resumeFile: Yup.mixed().required("Required"),
  linkedInUrl: Yup.string().url("Invalid url"),
  linkedInResumeFile: Yup.mixed(),
  currentLocation: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required("Required")
    .nullable(),
  jobFunctions: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .required("Required")
    .min(1, "Minimum 1 job function should be added"),
  preferredLocations: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .required("Required")
    .min(1, "Minimum 1 location should be added"),
  skills: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .min(3, "Minimum 3 skills should be added")
    .required("Required"),
  currentSalary: Yup.number()
    .min(1000, "Seems too less")
    .max(100000000, "Seems too high")
    .truncate(),
  expectedSalary: Yup.number()
    .min(1000, "Seems too less")
    .max(100000000, "Seems too high")
    .truncate(),
  noticePeriod: Yup.number()
    .min(0, "Seems too less")
    .max(16, "Seems too high")
    .truncate()
    .required("Required"),
  isResigned: Yup.boolean().default(false),
  lastWorkingDate: Yup.number().required("Required"),
  isRemoteReady: Yup.boolean().default(false),
  isStartupReady: Yup.boolean().default(false),
  hasJobOffers: Yup.boolean().default(false),
  jobOffer: Yup.object()
    .shape({
      company: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .nullable(),
      jobTitle: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .nullable(),
      offeredSalary: Yup.number().required("Required"),
      // .lessThan(Yup.ref("$parent.expectedSalary"))
    })
    .nullable(),
});
