import * as Sentry from "@sentry/react";
import { envConfig, env } from "../api/endpoints";

export function initialise() {
  if (envConfig["sentryEnabled"]) {
    Sentry.init({
      dsn:
        "https://ebb342395cae427d996444e7489cbd30@o431764.ingest.sentry.io/5383546",
    });
    Sentry.configureScope(function(scope) {
      scope.setTag("environment", env);
    });
  }
}

export function setUser(user) {
  if (envConfig["sentryEnabled"]) {
    Sentry.configureScope(function(scope) {
      scope.setUser({ email: user.email, id: user.uid });
    });
  }
}
