import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import {
  TALENTSCOUT_SEARCH_CANDIDATES,
  TALENTSCOUT_UPLOAD_CANDIDATE,
  TALENTSCOUT_WALLET,
  TALENTSCOUT_MY_CANDIDATES,
  TALENTSCOUT_ASSIGNED_JDS,
  TALENTSCOUT_REFERRALS,
} from "../util/routes";
import Currency from "./Currency";
import { LabelXSmall } from "baseui/typography";
import { Block } from "baseui/block";

class SidebarTalentScoutHome extends React.Component {
  onPromoteProfileChange() {}

  render() {
    return (
      <div>
        {/* <SidebarItem
          enabled
          title={"My Candidates"}
          icon={"users"}
          selected={this.props.page === "mycandidates"}
          onClick={() => {
            this.props.history.push(TALENTSCOUT_MY_CANDIDATES);
          }}
        /> */}
        <SidebarItem
          enabled
          title={"My Referrals"}
          icon={"users"}
          selected={this.props.page === "referrals"}
          onClick={() => {
            this.props.history.push(TALENTSCOUT_REFERRALS);
          }}
        />
        {/* <SidebarItem
          enabled
          title={"Scout Candidates"}
          selected={this.props.page === "search"}
          icon={"search"}
          onClick={() => {
            this.props.history.push(TALENTSCOUT_SEARCH_CANDIDATES);
          }}
        /> */}
        <SidebarItem
          enabled
          title={"Assigned JDs"}
          selected={this.props.page === "jobs"}
          icon={"bookmark"}
          onClick={() => {
            this.props.history.push(TALENTSCOUT_ASSIGNED_JDS);
          }}
        >
          <LabelXSmall marginLeft="scale200">Daily Task</LabelXSmall>
        </SidebarItem>
        {/* <SidebarItem
          enabled
          title={"Upload candidate"}
          icon={"upload"}
          selected={this.props.page === "upload"}
          onClick={() => {
            this.props.history.push(TALENTSCOUT_UPLOAD_CANDIDATE);
          }}
        /> */}
        <SidebarItem
          title={"Wallet"}
          enabled
          icon={"dollar-sign"}
          selected={this.props.page === "wallet"}
          onClick={() => {
            this.props.history.push(TALENTSCOUT_WALLET);
          }}
        >
          <Block display="flex" alignItems="center">
            {this.props.user.walletAmount === 0 && (
              <LabelXSmall
                marginLeft="scale200"
                marginRight="scale200"
                textDecoration="underline"
              >
                Add funds
              </LabelXSmall>
            )}
            <Currency
              currency={this.props.user.walletCurrency}
              value={this.props.user.walletAmount / 100}
            />
          </Block>
        </SidebarItem>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(SidebarTalentScoutHome));
