import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { ChevronDownIcon } from "@chakra-ui/icons";
import React from "react";
import { Checkbox, CheckboxGroup } from "@chakra-ui/checkbox";
import { Flex } from "@chakra-ui/layout";
import { Portal } from "@chakra-ui/portal";
import { Button } from "@chakra-ui/button";

export type FilterMenuOptionProps = {
  value: string;
  label: string;
  isSelected?: boolean;
};

export type FilterMenuProps = {
  headerText: string;
  buttonText: string;
  options: FilterMenuOptionProps[];
  onChange: (val: FilterMenuOptionProps[]) => void;
};

export const SelectButton = ({
  buttonText,
  headerText,
  options,
  onChange,
}: FilterMenuProps) => {
  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button rightIcon={<ChevronDownIcon />} fontSize="s">
          {buttonText}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent width={250}>
          <PopoverCloseButton />
          <PopoverHeader>{headerText}</PopoverHeader>
          <PopoverBody>
            <CheckboxGroup
              colorScheme="green"
              defaultValue={options
                .filter((o) => o.isSelected)
                .map((o) => o.value)}
              value={options.filter((o) => o.isSelected).map((o) => o.value)}
              onChange={(values) => {
                onChange(
                  options.map((o) => ({
                    ...o,
                    isSelected: values.indexOf(o.value) !== -1,
                  }))
                );
              }}
            >
              <Flex direction="column">
                {options.map((opt) => {
                  return <Checkbox value={opt.value}>{opt.label}</Checkbox>;
                })}
              </Flex>
            </CheckboxGroup>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
