import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import Button from "../components/Button";
import Title from "../components/Title";
import LoadingScreen from "../components/LoadingScreen";
import Card from "../components/Card";
import Bold from "../components/Bold";
import Select from "../components/Select";
import { SIZE } from "baseui/button";
import { Block } from "baseui/block";
import { createPickedCandidate, removePickedCandidate } from "../api/admin";
import { getJobsToAssign } from "../api/admin";
import Pagination from "../components/Pagination";
import { searchEntity } from "../api/entity";
import Spacing from "../components/Spacing";

const JobDetails = ({ job, candidateId, isPicked, onPicked }) => {
  const [isPushing, setIsPushing] = useState(false);
  const onHandPick = async () => {
    setIsPushing(true);
    try {
      await createPickedCandidate({
        jobId: job.id,
        candidateId: candidateId,
      });
      setIsPushing(false);
      onPicked && onPicked();
    } catch (e) {
      setIsPushing(false);
    }
  };
  const onRemovePick = async (id) => {
    setIsPushing(true);
    try {
      await removePickedCandidate({
        jobId: job.id,
        candidateId: candidateId,
      });
      setIsPushing(false);
      onPicked && onPicked();
    } catch (e) {
      setIsPushing(false);
    }
  };
  return (
    <Card style={{ marginBottom: "16px" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Bold>{job.company.name}</Bold>
          <div>{job.jobTitle.name}</div>
          <div>{job.minExperience + "-" + job.maxExperience + " Years"}</div>
          <div>{job.minSalary}</div>
        </div>
        <div>
          {!isPicked ? (
            <Button isLoading={isPushing} size={SIZE.mini} onClick={onHandPick}>
              Pick
            </Button>
          ) : (
            <Button
              isLoading={isPushing}
              kind={"tertiary"}
              size={SIZE.mini}
              onClick={onRemovePick}
            >
              UnPick
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};
const AdminHandPickCandidate = ({ candidateId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(0);
  const [company, setCompany] = useState("");
  const limit = 10;
  const [picked, setPicked] = useState([]);

  const fetchAssignedJobs = useCallback(async () => {
    setIsLoading(true);
    try {
      let { data: jobs, assigned: picked, hasNext } =
        (await getJobsToAssign(candidateId, company.name || "", page, limit)) ||
        {};
      setJobs(jobs);
      setPicked(picked);
      setHasNext(hasNext);
    } catch (e) {}
    setIsLoading(false);
  }, [candidateId, company.name, page, hasPrev]);

  const onNextClick = async () => {
    setPage(page + 1);
    setHasPrev(page + 1 > 0);
    // await this.onLoad(page + 1);
  };

  const onPrevClick = async () => {
    setPage(page - 1);
    setHasPrev(page - 1 > 0);
    // await this.onLoad(page - 1);
  };

  useEffect(() => {
    console.log("heheh");
    fetchAssignedJobs();
  }, [page, hasNext]);

  useEffect(() => {
    console.log("heheh");
    fetchAssignedJobs();
  }, []);

  console.log("heheh");
  return (
    <Block>
      <Block>
        <Title>{"Jobs to assign"}</Title>
      </Block>
      <Block>
        {isLoading && <LoadingScreen />}
        {!isLoading && (
          <div>
            <Select
              placeholder={"Select Company"}
              getOptions={async (query) => searchEntity("company", query)}
              onChange={(value) => {
                setCompany(value || "");
              }}
              value={company}
            />
            <Spacing size={16} />
            {jobs.map((job, idx) => (
              <JobDetails
                key={idx}
                job={job}
                onPicked={fetchAssignedJobs}
                isPicked={picked.indexOf(job.id) !== -1}
                candidateId={candidateId}
              />
            ))}
            <Pagination
              onPrevClick={onPrevClick}
              onNextClick={onNextClick}
              hasNext={hasNext}
              hasPrev={hasPrev}
            />
          </div>
        )}
      </Block>
    </Block>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  {}
)(AdminHandPickCandidate);
