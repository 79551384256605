import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

export type SalaryProps = {
  id: string;
  value: number;
  defaultValue?: number;
  placeholder: string;
  onChange: (value: number) => void;
};
export const Salary = ({
  id,
  placeholder,
  value,
  onChange,
  ...rest
}: SalaryProps) => {
  // console.log("value", value, "defaultValue", rest.defaultValue);
  const [val, setVal] = useState(value / 100000 + "");
  useEffect(() => {
    // console.log("default val change");
    if (typeof value !== "undefined") {
      // console.log(
      //   "Setting value from defaultValue",
      //   rest.defaultValue / 100000 + ""
      // );
      setVal(value / 100000 + "");
    }
  }, [value]);
  return (
    <InputGroup>
      <InputLeftAddon children="₹" />
      <Input
        id={id}
        placeholder={placeholder}
        onChange={(e) => {
          if (isNaN(Number(e.target.value))) {
            setVal("0");
            onChange(0);
          } else {
            setVal(e.target.value);
            const val = (+(+e.target.value).toFixed(2).replace(".", "") *
              1000) as number;
            onChange(val);
          }
        }}
        value={val}
      />
      <InputRightAddon children="Lakhs per annum" />
    </InputGroup>
  );
};
