import React from "react";
import { styled, withStyle } from "baseui";
import FlexRow from "./FlexRow";
import Flex from "./Flex";
import Button from "./Button";
import { KIND } from "baseui/button";
import PopConfirm from "./PopConfirm";
import Card from "./Card";
import { Icon } from "./Icon";
import { LabelMedium, LabelSmall } from "baseui/typography";
import { Block } from "baseui/block";

const Subject = styled("div", {
  color: "#999999",
});

const Left = withStyle(Flex, {
  flexDirection: "column",
});

const CourseDuration = styled("div", {
  color: "#999999",
  marginLeft: "8px",
});

const Wrapper = withStyle(Card, ({ isFirst, isLast, $theme }) => ({
  position: "relative",
  borderRadius: 0,
  borderTopLeftRadius: isFirst ? $theme.borders.radius300 : 0,
  borderTopRightRadius: isFirst ? $theme.borders.radius300 : 0,
  borderBottomLeftRadius: isLast ? $theme.borders.radius300 : 0,
  borderBottomRightRadius: isLast ? $theme.borders.radius300 : 0,
  ...$theme.borders.border300,
  ...(!isFirst && { borderTopWidth: 0 }),
}));

const TopLeft = withStyle(FlexRow, {
  marginBottom: "4px",
  justifyContent: "space-between",
});

const EducationSummary = (props) => (
  <Wrapper isLast={props.isLast} isFirst={props.isFirst}>
    <Left>
      <TopLeft>
        <LabelMedium>{props.courseTitle}</LabelMedium>
        <Block
          display="flex"
          top="scale200"
          right="scale200"
          position="absolute"
        >
          <Button
            kind={KIND.minimal}
            onClick={() => {
              props.updateEdit(props.index);
            }}
          >
            <Icon name="edit" size={16} />
          </Button>
          <PopConfirm
            showArrow
            title="Are you sure to delete this education?"
            onConfirm={async () => await props.onRemove(props.index)}
          >
            <Button kind={KIND.minimal}>
              <Icon name="trash" size={16} />
            </Button>
          </PopConfirm>
        </Block>
      </TopLeft>
      <FlexRow>
        <Subject>{props.subject}</Subject>
        <CourseDuration>{props.courseDuration}</CourseDuration>
      </FlexRow>
    </Left>
  </Wrapper>
);

export default EducationSummary;
