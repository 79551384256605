import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Education, College, Entity } from "src/lib/types/candidate";
import { FormLabel, FormErrorMessage } from "src/lib/uikit";
import {
  Box,
  Flex,
  Checkbox,
  FormControl,
  Text,
  Select,
  Textarea,
  Button,
} from "@chakra-ui/react";
import { AutoComplete, AutoCompleteItem } from "src/components/AutoComplete";
import { searchEntity } from "src/api/entity";
import { MONTHS, YEARS } from "src/util/time";
import { FiCheck, FiTrash, FiX } from "react-icons/fi";
import { EducationRow } from "./EducationList";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useToast } from "@chakra-ui/react";

const schema = yup
  .object({
    college: yup
      .object()
      .shape({
        id: yup.string().required("College is required"),
        name: yup.string(),
        isCreatable: yup.bool().optional(),
      })
      .required("Required"),
    course: yup
      .object()
      .shape({
        id: yup.string().required("Course is required"),
        name: yup.string(),
        isCreatable: yup.bool().optional(),
      })
      .required("Required"),
    startYear: yup.number().required("Required"),
    startMonth: yup.number().required("Required"),
    endYear: yup.number().required("Required"),
    endMonth: yup.number().required("Required"),
  })
  .required();

export type EducationFormProps = {
  educationRow: EducationRow;
  onSave: (e: Education) => void;
  onDiscard: () => void;
  onDelete: () => void;
  shouldValidate: boolean;
};

export const EducationForm = ({
  educationRow: { education, isNew },
  onSave,
  onDiscard,
  onDelete,
  shouldValidate,
}: EducationFormProps) => {
  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Education>({
    defaultValues: education,
    ...(shouldValidate && { resolver: yupResolver(schema) }),
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  useEffect(() => reset(education), [education]);
  const toast = useToast();
  const onFormSubmit = async (values: any) => {
    setIsSaving(true);
    await onSave(values as Education);
    setIsSaving(false);
  };
  const onFormError = async () => {
    toast({
      title: "Validation error",
      description: "Please check the fields in the form",
      status: "error",
    });
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
        <Flex mb={6} mt={4} flexDirection="column">
          <Box flex={1}>
            <FormLabel htmlFor={`college`}>College Name</FormLabel>
            <Controller
              name={`college`}
              control={control}
              render={({ field: { value, ...rest } }) => {
                return (
                  <>
                    <AutoComplete
                      {...rest}
                      items={[]}
                      id={`college`}
                      selected={(value as unknown) as AutoCompleteItem[]}
                      onItemSelect={(value) => {
                        setValue(`college`, (value as unknown) as College);
                      }}
                      fetchItems={async (q) => searchEntity("college", q)}
                    />
                    <FormErrorMessage>
                      {errors.college?.id?.message}
                    </FormErrorMessage>
                  </>
                );
              }}
            />
          </Box>
          <Box flex={1}>
            <FormLabel htmlFor="title">Course</FormLabel>
            <Controller
              name={"course"}
              control={control}
              render={({ field: { value, ...rest } }) => {
                return (
                  <>
                    <AutoComplete
                      {...rest}
                      items={[]}
                      id={`course`}
                      selected={(value as unknown) as AutoCompleteItem[]}
                      onItemSelect={(value) => {
                        setValue(`course`, (value as unknown) as Entity);
                      }}
                      fetchItems={async (q) => searchEntity("course", q)}
                    />
                    <FormErrorMessage>
                      {errors.course?.id?.message}
                    </FormErrorMessage>
                  </>
                );
              }}
            />
          </Box>
          <Box flex={1}>
            <FormLabel htmlFor="title">Field of study</FormLabel>
            <Controller
              name={"specializations"}
              control={control}
              render={({ field: { value, ...rest } }) => {
                return (
                  <>
                    <AutoComplete
                      {...rest}
                      items={[]}
                      id={`specializations`}
                      isMultiple
                      selected={(value as unknown) as AutoCompleteItem[]}
                      onItemSelect={(value) => {
                        setValue(
                          `specializations`,
                          (value as unknown) as Entity[]
                        );
                      }}
                      fetchItems={async (q) =>
                        searchEntity("specialization", q)
                      }
                    />
                    <FormErrorMessage>
                      {errors.specializations?.map((sp) => sp.id).join(" ")}
                    </FormErrorMessage>
                  </>
                );
              }}
            />
          </Box>
        </Flex>
        <Flex mb={4}>
          <Flex flex={1} mr={2}>
            <Box flex={1} mr={4}>
              <Controller
                name={`startYear`}
                control={control}
                render={({ field }) => {
                  return (
                    <Box>
                      <FormLabel htmlFor="startYear">Start Year</FormLabel>
                      <Select
                        placeholder=" "
                        fontSize="md"
                        size="lg"
                        {...field}
                        value={field.value}
                        onChange={(event) => {
                          setValue(
                            `startYear`,
                            isNaN(+event.target.value)
                              ? YEARS[0].id
                              : +event.target.value
                          );
                        }}
                        id={`startYear`}
                      >
                        {YEARS.map(({ id, name }, idx) => (
                          <option key={idx} value={id}>
                            {name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {errors.startYear?.message}
                      </FormErrorMessage>
                    </Box>
                  );
                }}
              />
            </Box>
            <Box flex={1} mr={4}>
              <Controller
                name={`startMonth`}
                control={control}
                render={({ field }) => {
                  return (
                    <Box>
                      <FormLabel htmlFor="startMonth">Start Month</FormLabel>
                      <Select
                        placeholder=" "
                        fontSize="md"
                        size="lg"
                        {...field}
                        value={field.value}
                        onChange={(event) => {
                          setValue(
                            `startMonth`,
                            isNaN(+event.target.value)
                              ? MONTHS[0].id
                              : +event.target.value
                          );
                        }}
                        id={`startMonth`}
                      >
                        {MONTHS.map(({ id, name }, idx) => (
                          <option key={idx} value={id}>
                            {name}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {errors.startMonth?.message}
                      </FormErrorMessage>
                    </Box>
                  );
                }}
              />
            </Box>
          </Flex>
          <Flex flex={1}>
            <>
              <Box flex={1} mr={4}>
                <Controller
                  name="endYear"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Box>
                        <FormLabel
                          htmlFor="phone"
                          fontWeight="500"
                          fontSize="sm"
                          color="gray.500"
                        >
                          End Year
                        </FormLabel>
                        <Select
                          placeholder=" "
                          fontSize="md"
                          size="lg"
                          {...field}
                          onChange={(event) => {
                            setValue(
                              `endYear`,
                              isNaN(+event.target.value)
                                ? YEARS[0].id
                                : +event.target.value
                            );
                          }}
                          id={`endYear`}
                        >
                          {YEARS.map(({ id, name }, idx) => (
                            <option key={idx} value={id}>
                              {name}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>
                          {errors.endYear?.message}
                        </FormErrorMessage>
                      </Box>
                    );
                  }}
                />
              </Box>
              <Box flex={1}>
                <Controller
                  name="endMonth"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Box>
                        <FormLabel
                          htmlFor="phone"
                          fontWeight="500"
                          fontSize="sm"
                          color="gray.500"
                        >
                          End Month
                        </FormLabel>
                        <Select
                          placeholder=" "
                          fontSize="md"
                          size="lg"
                          {...field}
                          onChange={(event) => {
                            setValue(
                              `endMonth`,
                              isNaN(+event.target.value)
                                ? MONTHS[0].id
                                : +event.target.value
                            );
                          }}
                          id={`endMonth`}
                        >
                          {MONTHS.map(({ id, name }, idx) => (
                            <option key={idx} value={id}>
                              {name}
                            </option>
                          ))}
                        </Select>
                        <FormErrorMessage>
                          {errors.endMonth?.message}
                        </FormErrorMessage>
                      </Box>
                    );
                  }}
                />
              </Box>
            </>
          </Flex>
        </Flex>
        <Flex mt={4} justifyContent="space-between">
          <Flex>
            <Button
              type="submit"
              mr={2}
              leftIcon={<FiCheck />}
              isLoading={isSaving}
              loadingText="Saving"
            >
              Save Education
            </Button>
            {isNew && (
              <Button
                type="button"
                onClick={onDiscard}
                variant={"ghost"}
                leftIcon={<FiX />}
              >
                Discard
              </Button>
            )}
          </Flex>
          {!isNew && (
            <Button
              type="button"
              onClick={async () => {
                setIsDeleting(true);
                await onDelete();
                setIsDeleting(false);
              }}
              variant={"ghost"}
              isLoading={isDeleting}
              loadingText="Deleting"
              leftIcon={<FiTrash />}
            >
              Delete
            </Button>
          )}
        </Flex>
      </form>
    </Box>
  );
};
