import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import Button from "../components/Button";
import { getAllJobs } from "../api/admin";
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table";
import { prettyPrint } from "../util/time";

const JobRow = (props) => (
  <StyledRow key={props.index}>
    <StyledCell>{props.job.jobTitle.name}</StyledCell>
    <StyledCell>{props.job.company.name}</StyledCell>
    <StyledCell>{props.job.status}</StyledCell>
    <StyledCell>{prettyPrint(props.job.createdAt)}</StyledCell>
    <StyledCell>
      <Button onClick={() => props.onPickCandidates(props.job.id)}>
        Pick Candidates
      </Button>
    </StyledCell>
    <StyledCell>
      <Button onClick={() => props.onDetailsClick(props.job.id)}>
        See details
      </Button>
    </StyledCell>
  </StyledRow>
);

class AdminJobList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      jobs: [],
      hasNext: false,
      hasPrev: false,
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    try {
      let { data: jobs, hasNext } = await getAllJobs(
        this.state.page,
        this.state.limit
      );
      this.setState({
        jobs,
        hasNext,
      });
    } catch (e) {
      console.log(e);
    }
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  render() {
    return (
      <div>
        <Title>Jobs List</Title>
        <div style={{ marginBottom: "32px" }}>
          <StyledTable>
            <StyledHead>
              <StyledHeadCell>Role</StyledHeadCell>
              <StyledHeadCell>Company</StyledHeadCell>
              <StyledHeadCell>Status</StyledHeadCell>
              <StyledHeadCell>CreatedAt</StyledHeadCell>
              <StyledHeadCell>Details</StyledHeadCell>
            </StyledHead>
            <StyledBody>
              {this.state.jobs.map((job, index) => (
                <JobRow
                  key={index}
                  job={job}
                  onPickCandidates={(jobId) =>
                    this.props.history.push(
                      "/N0VHxB8M/admin/details/candidateSearch/" + jobId
                    )
                  }
                  onDetailsClick={(jobId) =>
                    this.props.history.push(
                      "/N0VHxB8M/admin/details/job/" + jobId
                    )
                  }
                />
              ))}
            </StyledBody>
          </StyledTable>
        </div>
        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminJobList));
