import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Title from "../../../components/Title";
import { logEvent } from "../../../analytics/index";
import Pagination from "../../../components/Pagination";
import {
  getShortlistedCandidates,
  removeShortlistCandidate,
} from "../../../api/job";
import { CenterContainer } from "../../../components/CenterContainer";
import LoadingScreen from "../../../components/LoadingScreen";
import { Block } from "baseui/block";
import CandidateCard from "../../../components/employer/CandidateCard";
import { LabelSmall } from "baseui/typography";

const ShortlistedCandidates = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [shortlistedMap, setShortlistedMap] = useState({});
  const [page, setPage] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const { jobId } = props;
  const limit = 10;

  const onLoad = useCallback(
    async (isSilent) => {
      try {
        if (!isSilent) {
          setIsFetching(true);
        }
        let {
          data: candidates,
          shortlistedCandidates,
          hasNext,
        } = await getShortlistedCandidates(jobId, [], page, limit);
        setIsFetching(false);
        setShortlistedMap(shortlistedCandidates);
        setCandidates(candidates);
        setHasNext(hasNext);
      } catch (e) {
        console.log(e);
      }
    },
    [jobId, page]
  );

  const onNextClick = () => {
    setPage(page + 1);
    setHasPrev(page + 1 > 0);
    onLoad();
  };

  const onPrevClick = () => {
    setPage(page - 1);
    setHasPrev(page - 1 > 0);
    onLoad();
  };

  useEffect(() => {
    logEvent("[E] page view job handpicked");
    onLoad();
  }, [onLoad]);

  const onInviteInterview = (candidate) => {
    props.history.push(
      "/employer/create-interview/" +
        candidate.userId +
        "?jobId=" +
        this.state.jobId
    );
  };

  const onNotInterestedClick = async (candidate) => {
    try {
      if (shortlistedMap[candidate.userId]) {
        await removeShortlistCandidate(shortlistedMap[candidate.userId].id);
      }
      await onLoad(true);
    } catch (e) {}
  };

  return (
    <CenterContainer>
      <Block marginBottom="scale600">
        <Title>Shortlisted Candidates</Title>
        <LabelSmall color="primary400">
          Candidates shortlisted by you
        </LabelSmall>
      </Block>
      {candidates.length === 0 && (
        <CenterContainer padding="0px">
          <Block marginTop="70px">
            Shortlisted candidates for this job will appear here.
          </Block>
        </CenterContainer>
      )}
      {candidates.map((candidate, index) => (
        <CandidateCard
          key={index}
          to={
            "/employer/candidate/" +
            candidate.userId +
            "/details?jobId=" +
            jobId
          }
          onShare={() => {}}
          candidate={candidate}
          onInterview={onInviteInterview}
          onNotInterested={onNotInterestedClick}
          interviewLink={"/employer/create-interview/" + candidate.userId}
          isClickable
          isOpenNewWindow
          showRCAmount
        />
      ))}
      <Pagination
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        hasNext={hasNext}
        hasPrev={hasPrev}
      />
      <LoadingScreen show={isFetching} />
    </CenterContainer>
  );
};

export default withRouter(ShortlistedCandidates);
