import React from "react";
import SidebarItem from "./SidebarItem";
import {
  CANDIDATE_DASHBOARD,
  CANDIDATE_FIND_JOBS,
  CANDIDATE_APPLIED_JOBS,
  CANDIDATE_TALENTSCOUT_REQUESTS,
  CANDIDATE_INTERVIEW_REQUESTS,
} from "../util/routes";
import UnreadCount from "./UnreadCount";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";

class SidebarCandidateHome extends React.Component {
  onPromoteProfileChange() {}
  render() {
    return (
      <div>
        <>
          <SidebarItem
            title={"Dashboard"}
            icon={"home"}
            enabled={true}
            selected={this.props.page === "dashboard"}
            onClick={() => {
              this.props.history.push(CANDIDATE_DASHBOARD);
            }}
          ></SidebarItem>
          {!!this.props.user.candidateProfile && (
            <SidebarItem
              enabled={true}
              title={"Talent-Scout Requests"}
              icon={"eye"}
              selected={this.props.page === "talentscoutrequests"}
              onClick={() => {
                this.props.history.push(CANDIDATE_TALENTSCOUT_REQUESTS);
              }}
            >
              {this.props.user.candidateCounts &&
              this.props.user.candidateCounts.tsRequestsReceivedUnread > 0 ? (
                <UnreadCount>
                  {this.props.user.candidateCounts.tsRequestsReceivedUnread}
                </UnreadCount>
              ) : null}
            </SidebarItem>
          )}
          <SidebarItem
            enabled={true}
            title={"Interview Requests"}
            icon={"send"}
            selected={this.props.page === "interviewrequests"}
            onClick={() => {
              this.props.history.push(CANDIDATE_INTERVIEW_REQUESTS);
            }}
          >
            {this.props.user.candidateCounts &&
            this.props.user.candidateCounts.interviewsReceivedUnread > 0 ? (
              <UnreadCount>
                {this.props.user.candidateCounts.interviewsReceivedUnread}
              </UnreadCount>
            ) : null}
          </SidebarItem>
        </>

        <SidebarItem
          enabled={true}
          title={"Find Jobs"}
          icon={"briefcase"}
          selected={this.props.page === "findjobs"}
          onClick={() => {
            this.props.history.push(CANDIDATE_FIND_JOBS);
          }}
        />
        <SidebarItem
          enabled={true}
          title={"Applied Jobs"}
          icon={"bookmark"}
          selected={this.props.page === "appliedjobs"}
          onClick={() => {
            this.props.history.push(CANDIDATE_APPLIED_JOBS);
          }}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(SidebarCandidateHome));
