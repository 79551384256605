import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { AutoComplete, AutoCompleteItem } from "src/components/AutoComplete";
import { searchEntity } from "src/api/entity";
import { Entity } from "src/lib/types/candidate";

export type EntityFormProps = {
  values: Entity;
  onChange: (values: Entity) => void;
};

export const EntityForm = ({ values, onChange }: EntityFormProps) => {
  const {
    control,
    watch,
    reset,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useForm<Entity>({
    defaultValues: values,
  });

  useEffect(() => reset(values), [reset, values]);

  useEffect(() => {
    const subscription = watch(() => {
      const values = getValues();
      onChange({ ...values });
    });
    return () => subscription.unsubscribe();
  }, [watch, getValues, onChange]);

  return (
    <form>
      <Box mr={6} flex={1}>
        <FormControl>
          <FormLabel htmlFor="id">Id</FormLabel>
          <Input disabled {...register("id")} />
          <Box height={"40px"}>
            <FormErrorMessage>{errors.id}</FormErrorMessage>
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="name">name</FormLabel>
          <Input {...register("name")} />
          <Box height={"40px"}>
            <FormErrorMessage>{errors.name}</FormErrorMessage>
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor={`location`}>Location</FormLabel>
          <Controller
            name={`location`}
            control={control}
            render={({ field: { value, ...rest } }) => {
              return (
                <>
                  <AutoComplete
                    {...rest}
                    items={[]}
                    id={`location`}
                    isMultiple={false}
                    isSearchable
                    isCreatable={false}
                    selected={value}
                    onItemSelect={(
                      value: AutoCompleteItem | AutoCompleteItem[]
                    ) => {
                      setValue(`location`, value as AutoCompleteItem);
                    }}
                    fetchItems={async (q) => searchEntity("location", q)}
                  />
                  <Box height={"40px"}>
                    <FormErrorMessage>
                      {errors.location?.id?.message}
                    </FormErrorMessage>
                  </Box>
                </>
              );
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor={`tags`}>Tags</FormLabel>
          <Controller
            name={`tags`}
            control={control}
            render={({ field: { value, ...rest } }) => {
              return (
                <>
                  <AutoComplete
                    {...rest}
                    id={`tags`}
                    items={[]}
                    isMultiple
                    selected={value}
                    onItemSelect={(
                      value: AutoCompleteItem | AutoCompleteItem[]
                    ) => {
                      setValue(`tags`, value as AutoCompleteItem[]);
                    }}
                    fetchItems={async (q) => searchEntity("entityTags", q)}
                  />
                  <Box height={"40px"}>
                    <FormErrorMessage>
                      {errors.tags?.map((r) => r.id?.message).join()}
                    </FormErrorMessage>
                  </Box>
                </>
              );
            }}
          />
        </FormControl>
      </Box>
    </form>
  );
};
