import api from "./index";
import { baseUrl } from "./endpoints";

export async function sendCode(data) {
  return await api({
    baseUrl,
    url: "/api/sendcode",
    method: "POST",
    data: data,
  });
}

export async function verifyCode(data) {
  return await api({
    baseUrl,
    url: "/api/verifycode",
    method: "POST",
    data: data,
  });
}
