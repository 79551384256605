import React from "react";
import { styled, withStyle } from "baseui";
import PageHeader from "../components/Header";
import { setUser } from "../redux/actions/user";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as Analytics from "../analytics";
import { getAnonymousCandidate, getPublicCandidate } from "../api/candidate";
import LoadingScreen from "../components/LoadingScreen";
import Resume from "../components/Resume";
import Title from "../components/Title";
import Button from "../components/Button";
import ErrorPage from "../components/ErrorPage";
import { withRouter } from "react-router-dom";
import Bold from "../components/Bold";
import Card from "../components/Card";
import logoDark from "../icons/logo.svg";

const Container = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "1080px",
  margin: "auto",
  marginTop: "100px",
});

const ImageWrapper = withStyle(Card, {
  marginBottom: "16px",
  background: "#FFFFFF",
  position: "relative",
});

const ImageTitle = withStyle(Bold, {
  fontSize: "16px",
});

const ImageDescription = styled("div", {
  color: "#919191",
});

const HowItWorks = withStyle(Bold, {
  marginTop: "24px",
  marginBottom: "8px",
});

const Note = styled("div", {
  fontSize: "10px",
  marginTop: "8px",
});

const ImageSubTitle = withStyle(Bold, {
  marginTop: "-20px",
  marginBottom: "16px",
  color: "#919191",
});

const Step = styled("div", ({ $theme }) => ({
  background: $theme.colors.primary,
  color: "white",
  borderRadius: "30px",
  width: "24px",
  height: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  left: "8px",
  top: "8px",
}));

const Highlights = styled("div", ({ $theme }) => ({
  color: $theme.colors.green,
  background: $theme.colors.lightGreen,
  borderRadius: "4px",
  padding: "8px 16px",
  marginBottom: "16px",
  fontWeight: "500",
}));

const TitleText = withStyle(Bold, {
  fontSize: "20px",
  marginTop: "8px",
  marginBottom: "8px",
});

const Flex = styled("div", {
  flex: 1,
});

const ActionBar = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "32px",
});

const RightBar = styled("div", {
  width: "300px",
  marginTop: "75px",
  marginLeft: "32px",
});

const LogoImage = styled("img", {
  marginLeft: "-4px",
  marginBottom: "24px",
});

class PublicCandidate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isFetched: false,
      candidate: {},
      isErrored: false,
    };
  }

  async componentDidMount() {
    Analytics.logEvent("page view public candidate");
    try {
      this.setState({
        isLoading: true,
      });
      let { candidateId } = this.props.match.params;
      let candidate = await getPublicCandidate(candidateId);
      this.setState({ candidate, isLoading: false, isFetched: true });
    } catch (e) {
      this.setState({
        isLoading: false,
        isFetched: true,
        isErrored: true,
      });
    }
  }

  render() {
    if (!this.state.isFetched) {
      return <div />;
    }
    if (this.state.isLoading) {
      return <LoadingScreen />;
    }
    if (this.state.isErrored) {
      return <ErrorPage />;
    }
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="noindex" />
          <meta name="AdsBot-Google" content="noindex" />)
          <title>Interview Candidate</title>
        </Helmet>
        <Container>
          <Flex>
            <ActionBar>
              <Title>Candidate Details</Title>
              <Button
                onClick={() => {
                  this.props.history.push(
                    "/login?redirect=" +
                      decodeURIComponent(
                        `/employer/create-interview/${this.state.candidate.userId}`
                      )
                  );
                }}
              >
                Interview candidate
              </Button>
            </ActionBar>
            {this.state.candidate.anonymousStatus === "GREEN" ||
            this.state.candidate.anonymousStatus === "YELLOW" ? (
              <Resume candidate={this.state.candidate} isAnonymous={true} />
            ) : (
              <div>
                <div>Sorry, this candidate has stopped looking for jobs.</div>
                <div>
                  You can browser other premium candidates and schedule
                  interviews
                </div>
                <Button
                  onClick={() => {
                    this.props.history.push("/login");
                  }}
                >
                  Find another candidate
                </Button>
              </div>
            )}
          </Flex>
          <RightBar>
            <div>
              <LogoImage
                src={logoDark}
                alt={"Finding Jo"}
                height={"60px"}
                width={"120px"}
              />
              <ImageSubTitle>
                The fastest way to book an interview with a premium candidate
              </ImageSubTitle>
            </div>
            <Highlights>
              <div>ZERO success fee</div>
              <div>FREE to view all resumes</div>
              <div>FREE to post jobs</div>
            </Highlights>
            <div>Pay only when you book an interview.</div>
            <div>The interview booking fee starts at Rs 2,500.</div>
            <TitleText>Start interviewing now!</TitleText>
            <HowItWorks>How it works?</HowItWorks>
            <ImageWrapper>
              <img
                alt={""}
                width={"100%"}
                height={"200px"}
                src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/recruiter4.svg"
              ></img>
              <ImageTitle>To interview a premium candidate</ImageTitle>
              <ImageDescription>
                <div>1. The employer first makes salary offer</div>
                <div>2. The candidate accepts your offer</div>
                <div>3. Candidate identity revealed</div>
                <div>4. Interview starts</div>
              </ImageDescription>
              <Note>
                Note: FindingJo will not reveal the candidate's identity if the
                candidate rejects the salary offer.
              </Note>
              <Step>1</Step>
            </ImageWrapper>
            <ImageWrapper>
              <img
                alt={""}
                width={"100%"}
                height={"200px"}
                src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/recruiter6.svg"
              ></img>
              <ImageTitle>
                <div>Free to view all resumes.</div>
                <div>Pay to book and interview</div>
              </ImageTitle>
              <ImageDescription>
                Employer can post job description for FREE. Our talentscout will
                shortlist premium candidates for you for FREE. Employers can
                review all anonymous shortlisted resume for FREE
              </ImageDescription>
              <Step>2</Step>
            </ImageWrapper>
            <ImageWrapper>
              <img
                alt={""}
                width={"100%"}
                height={"200px"}
                src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/recruiter5.svg"
              ></img>
              <ImageTitle>100% purchase protection</ImageTitle>
              <ImageDescription>
                <div>
                  Your interview booking fees is refunded, if the candidate
                  rejects your salary offer or interview request.
                </div>
              </ImageDescription>
              <Step>3</Step>
            </ImageWrapper>
          </RightBar>
        </Container>
        <PageHeader headerWidth={"1060px"} notLoggedIn />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(PublicCandidate));
