import React from "react";
import Button from "../components/Button";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import FormControl from "../components/FormControl";
import Input from "../components/Input";
import PhoneInput from "../components/PhoneInput";
import {
  getCandidateProjection,
  updateCandidateProfile,
} from "../api/candidate";
import { validURL } from "../util/common";
import { searchEntity } from "../api/entity";
import Select from "../components/Select";
import Label from "../components/Label";
import FormWrapper from "../components/FormWrapper";
import { CANDIDATE_PROFILE_EXPERIENCE } from "../util/routes";
import LoadingScreen from "../components/LoadingScreen";
import Title from "../components/Title";
import EmptyCaption from "../components/EmptyCaption";
import * as Analytics from "../analytics";
import NotServiceableModal from "./NotServiceableModal";
import { CenterContainer } from "../components/CenterContainer";
import Card from "../components/Card";
import Flex from "../components/Flex";

class CandidateBasic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isLoading: false,
      errors: {},
      values: {
        name: props.user.name || "",
        phoneCountry: {
          label: "India (भारत)",
          id: "IN",
          dialCode: "+91",
        },
        linkedInProfile: props.user.linkedInProfile || "",
        phone: props.user.phone || "",
        jobFunctions: [],
        jobLocations: [],
      },
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[C] page view profile basic");
    this.setState({
      isFetching: true,
    });
    try {
      let basic =
        (await getCandidateProjection(this.props.user.id, "basic")) || {};
      this.setState({
        isFetching: false,
        values: {
          ...this.state.values,
          ...{
            jobFunctions: basic.jobFunctions,
            jobLocations: basic.jobLocations,
          },
        },
      });
    } catch (ex) {
      this.setState({
        isFetching: false,
      });
      console.log(ex);
    }
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && !this.state.values[id]) {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
  }

  getValidations(id) {
    switch (id) {
      case "name":
      case "phone":
      case "phoneCountry":
      case "linkedInProfile":
        return { required: true };
      case "jobFunctions":
      case "jobLocations":
        return { requiredArray: true };
      default:
        return {};
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let errors = {};
    [
      "name",
      "phone",
      "phoneCountry",
      "linkedInProfile",
      "jobFunctions",
      "jobLocations",
    ].forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });

    if (!validURL(this.state.values["linkedInProfile"])) {
      errors["linkedInProfile"] = "Please enter a valid linkedIn profile";
      isErrorFound = true;
    }

    if (isErrorFound) {
      this.setState({
        errors,
      });
    } else {
      await this.onContinue(this.state.values);
    }
  };

  onContinue = async (values) => {
    this.setState({
      isLoading: true,
    });
    try {
      await updateCandidateProfile(this.props.user.id, "basic", {
        name: values.name,
        phone: values.phone,
        phonePrefix: values.phoneCountry,
        linkedInProfile: values.linkedInProfile,
        ...values,
      });
      let candidateOnboarding = this.props.user.candidateOnboarding || [];
      if (candidateOnboarding.indexOf("basic") === -1) {
        candidateOnboarding.push("basic");
      }
      this.props.setUser({
        name: values.name,
        phone: values.phone,
        linkedInProfile: values.linkedInProfile,
        phonePrefix: values.phoneCountry,
        candidateOnboarding,
      });
      this.setState({
        isLoading: false,
      });
      if (candidateOnboarding.indexOf("experience") === -1) {
        this.setState({
          isNotServiceableModalVisible: true,
        });
      } else {
        this.props.history.push(CANDIDATE_PROFILE_EXPERIENCE);
      }
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.log(e);
    }
  };

  render() {
    return (
      <CenterContainer>
        <Title>{"Personal Info"}</Title>
        <Flex>
          <Card>
            <FormWrapper>
              <form noValidate onSubmit={this.handleSubmit} autoComplete="off">
                <FormControl
                  label={
                    <Label subtitle={"First name and last name"}>
                      {"Enter your full name"}
                    </Label>
                  }
                  caption={<EmptyCaption />}
                  error={this.state.errors["name"]}
                >
                  <Input
                    id={"name"}
                    onChange={(e) =>
                      this.updateFormValues("name", e.target.value)
                    }
                    value={this.state.values["name"]}
                    autoComplete={"no"}
                    onBlur={(e) =>
                      this.updateFormErrors(
                        "name",
                        this.validate("name", this.getValidations("name"))
                      )
                    }
                  />
                </FormControl>
                <FormControl
                  label={
                    <Label subtitle={"eg. +91 9999988888"}>
                      {"Enter your phone number"}
                    </Label>
                  }
                  caption={<EmptyCaption />}
                  error={
                    this.state.errors["phone"] ||
                    this.state.errors["phoneCountry"]
                  }
                >
                  <PhoneInput
                    autoComplete={"no"}
                    country={this.state.values["phoneCountry"]}
                    onCountryChange={({ option }) =>
                      this.updateFormValues("phoneCountry", option)
                    }
                    text={this.state.values["phone"]}
                    onTextChange={(e) =>
                      this.updateFormValues("phone", e.target.value)
                    }
                  />
                </FormControl>
                <FormControl
                  label={
                    <Label
                      subtitle={
                        <div>
                          <div>This helps us to verify your details</div>
                          <div>
                            Note: FindingJo will never post anything on LinkedIn
                            without your permission
                          </div>
                        </div>
                      }
                    >
                      {"Enter your LinkedIn profile url"}
                    </Label>
                  }
                  caption={<EmptyCaption />}
                  error={this.state.errors["linkedInProfile"]}
                >
                  <Input
                    id={"linkedInProfile"}
                    onChange={(e) =>
                      this.updateFormValues("linkedInProfile", e.target.value)
                    }
                    value={this.state.values["linkedInProfile"]}
                    autoComplete={"no"}
                  />
                </FormControl>
                <FormControl
                  label={
                    <Label subtitle={"You may choose 1 or more job functions"}>
                      {
                        "In your next job, what job functions do you like to do?"
                      }
                    </Label>
                  }
                  caption={<EmptyCaption />}
                  error={this.state.errors["jobFunctions"]}
                >
                  <Select
                    autoComplete={"no"}
                    multi
                    getOptions={async (query) => {
                      let options = await searchEntity("jobFunction", query);
                      options.sort((o1, o2) => (o1.name > o2.name ? 1 : -1));
                      return options;
                    }}
                    onChange={(value) => {
                      this.updateFormValues("jobFunctions", value);
                    }}
                    value={this.state.values["jobFunctions"]}
                  />
                </FormControl>
                <FormControl
                  label={
                    <Label subtitle={"You may chose 1 or more locations"}>
                      {"For your next job, what are your preferred locations ?"}
                    </Label>
                  }
                  caption={<EmptyCaption />}
                  error={this.state.errors["jobLocations"]}
                >
                  <Select
                    creatable
                    multi
                    type={"search"}
                    searchable
                    getOptions={async (query) =>
                      searchEntity("location", query)
                    }
                    onChange={(value) => {
                      this.updateFormValues("jobLocations", value);
                    }}
                    value={this.state.values["jobLocations"]}
                  />
                </FormControl>
                <Button isLoading={this.state.isLoading} type={"submit"}>
                  Save & Continue
                </Button>
              </form>
              <NotServiceableModal
                isVisible={this.state.isNotServiceableModalVisible}
                onClose={() => {
                  this.setState({
                    isNotServiceableModalVisible: false,
                  });
                }}
                onContinue={() => {
                  this.props.history.push(CANDIDATE_PROFILE_EXPERIENCE);
                }}
              />
            </FormWrapper>
          </Card>
        </Flex>
        <LoadingScreen show={this.state.isFetching} />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateBasic));
