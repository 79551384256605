import React from "react";
import { Block } from "baseui/block";

export const CenterContainer = ({ children, ...rest }) => (
  <Block
    padding="16px"
    maxWidth={["auto", "auto", "900px"]}
    minHeight="100%"
    paddingBottom="40px"
    flex={1}
    {...rest}
  >
    {children}
  </Block>
);
