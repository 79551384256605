import React, { useState, useEffect } from "react";
import Title from "../components/Title";
import { FormControl } from "baseui/form-control";
import Input from "../components/Input";
import Label from "../components/Label";
import Button from "../components/Button";
import { KIND as BUTTON_KIND } from "baseui/button";
import EmptyCaption from "../components/EmptyCaption";
import { createEntity, updateEntity } from "../api/entity";
import Modal from "../components/Modal";
import { searchCandidateFacets } from "../api/candidate";
import { ModalHeader, ModalBody, ModalFooter, ModalButton } from "baseui/modal";

const EntityCreateForm = ({
  entityId,
  entityName,
  anonymousValue,
  entityValue,
  onCreated,
  isVisible,
  onClose,
  isAnonymousRequired,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState(entityValue);
  const [value, setValue] = useState("");
  const [anonymousName, setAnonymousName] = useState(anonymousValue);
  const [formErrors, setFormErrors] = useState({});
  const [facets, setFacets] = useState([]);
  const isEditMode = !!entityId;
  const validateFields = () => {
    let errors = {};
    let isErrorFound = false;
    if (!name || name.length === 0) {
      errors.name = "Name is required";
      isErrorFound = true;
    }
    if (isAnonymousRequired && (!anonymousName || anonymousName.length === 0)) {
      errors.anonymousName = "Anonymous name is required";
      isErrorFound = true;
    }
    setFormErrors(errors);
    return isErrorFound;
  };
  const getExistingAnonymousEntries = async () => {
    let searchEntity = "";
    if (entityName === "college") {
      searchEntity = "education.college.name";
    } else if (entityName === "company") {
      searchEntity = "experience.company.name";
    } else {
      return;
    }
    try {
      let { FacetHits: facetHits = [] } = await searchCandidateFacets(
        searchEntity,
        ""
      );
      setFacets(facetHits);
    } catch (e) {
      console.log(e);
    }
  };
  const onCreateEntity = async () => {
    try {
      if (validateFields()) return;
      setIsSaving(true);
      let entity = isEditMode
        ? await updateEntity(entityName, {
            id: entityId,
            name,
            value,
            anonymousName,
          })
        : await createEntity(entityName, {
            name,
            value,
            anonymousName,
          });
      setName("");
      setValue("");
      setAnonymousName("");
      setIsSaving(false);
      onCreated(entity);
    } catch (e) {
      setIsSaving(false);
      console.log(e);
    }
  };

  useEffect(() => {
    isVisible && getExistingAnonymousEntries();
    setName(entityValue);
    setValue("");
    setAnonymousName(anonymousValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityName, isVisible]);

  if (!isVisible) {
    return <div></div>;
  }

  return (
    <Modal
      animate
      autoFocus
      isOpen={isVisible}
      closeable
      onClose={() => {
        onClose();
      }}
    >
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          await onCreateEntity();
        }}
        autoComplete="off"
      >
        <ModalHeader>
          <Title>{(isEditMode ? "Update " : "Create ") + entityName}</Title>
        </ModalHeader>
        <ModalBody>
          <FormControl
            label={<Label>{"For real resume"}</Label>}
            caption={<EmptyCaption />}
            error={formErrors["name"]}
          >
            <Input
              onChange={(e) => setName(e.target.value)}
              value={name}
              autoComplete={"off"}
            />
          </FormControl>
          {isAnonymousRequired && (
            <FormControl
              label={<Label>{"For anonymous resume"}</Label>}
              caption={<EmptyCaption />}
              error={formErrors["anonymousName"]}
            >
              <Input
                onChange={(e) => setAnonymousName(e.target.value)}
                value={anonymousName}
                autoComplete={"off"}
              />
            </FormControl>
          )}
          <div>Existing anonymous names</div>
          {facets.map((f) => (
            <Button
              type={"button"}
              onClick={() => {
                setAnonymousName(f.value);
              }}
              kind={BUTTON_KIND.tertiary}
            >
              {f.value}
            </Button>
          ))}
        </ModalBody>
        <ModalFooter>
          <ModalButton isLoading={isSaving}>
            {isEditMode ? "Update" : "Create"}
          </ModalButton>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EntityCreateForm;
