import api from "./index";
import { baseUrl } from "./endpoints";

export async function createPickedCandidate(jobId, data) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/handpicked/create",
    method: "POST",
    data,
  });
}

export async function removePickedCandidate(jobId, handPickId, data) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/handpicked/" + handPickId + "/remove",
    method: "POST",
    data,
  });
}

export async function getHandPickedCandidates(jobId, page, limit) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/handpicked",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getHandPickedRemovedCandidates(jobId, page, limit) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/handpicked/removed",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}
