import api from "../index";
import { baseUrl } from "../endpoints";

export async function submitUploadedCandidate(data, action) {
  return await api({
    baseUrl,
    url: "/api/reviewer/uploadedCandidates/" + action,
    method: "POST",
    data,
  });
}

export async function getUploadedCandidate(uploadedCandidateId) {
  return await api({
    baseUrl,
    url: "/api/reviewer/uploadedCandidate/" + uploadedCandidateId,
    method: "GET",
  });
}

export async function getUploadedCandidates(page, limit) {
  return await api({
    baseUrl,
    url: "/api/reviewer/uploadedCandidates",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}
