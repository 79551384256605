import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter, Link } from "react-router-dom";
import Title from "../components/Title";
import { getCandidateJobApplications } from "../api/job";
import { CANDIDATE_FIND_JOBS } from "../util/routes";
import * as Analytics from "../analytics";
import { Block } from "baseui/block";
import { CenterContainer } from "../components/CenterContainer";
import JobCard from "../components/employer/JobCard";

class CandidateAppliedJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      isDisabled: false,
      data: [],
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[C] page view applied jobs");
    try {
      let { data, hasNext, isDisabled } = await getCandidateJobApplications(
        this.props.user.id,
        this.state.page,
        this.state.limit
      );
      this.setState({
        data: data || [],
        isDisabled,
      });
      console.log("==> ", data, hasNext);
    } catch (e) {
      console.log(e);
    }
  }

  convertToSearchJob = (job) => {
    return job;
    // return {
    //   ...job,
    //   company: job.company.name,
    //   jobTitle: job.jobTitle.name,
    //   skills: job.skills.map((sk) => sk.name),
    //   locations: job.locations.map((loc) => loc.name),
    // };
  };

  render() {
    return (
      <CenterContainer>
        <Block marginBottom="scale600">
          <Title>Applied Jobs</Title>
        </Block>
        {this.state.data.length === 0 ? (
          <div
            style={{
              marginTop: "64px",
              marginLeft: "64px",
              width: "420px",
              height: "420px",
            }}
          >
            <div>You are yet to apply for a job</div>
            <div>
              <Link
                to={CANDIDATE_FIND_JOBS}
                style={{ textDecoration: "underline" }}
              >
                {"Search"}
              </Link>
              {" for a job and apply"}
            </div>
          </div>
        ) : (
          this.state.data.map((d) => (
            <JobCard
              job={d}
              isClickable
              onShare={() => {}}
              to={"/candidate/jobs/" + d.id + "/details"}
            />
          ))
        )}
      </CenterContainer>
    );
  }
}

// this.state.isDisabled ? (
//   <div
//     style={{
//       marginTop: "64px",
//       marginLeft: "64px",
//       width: "420px",
//       height: "420px",
//     }}
//   >
//     <Block display="flex" flexDirection="column" alignItems="center">
//       <Icon size={48} name="lock" />
//       <div style={{ textAlign: "center" }}>
//         This page will be enabled once a talent-scout shortlists your
//         profile as a premium candidate.
//       </div>
//     </Block>
//   </div>
// ) :

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateAppliedJobs));

//jobTitle
//jobLocation
//jobCompany
//jobCreatedAt
//employerName
//candidateName
