import React from "react";
import ProgressSteps from "./ProgressSteps";
import Card from "./Card";
import SubTitle from "./SubTitle";
import Button from "./Button";
import { withRouter, Link } from "react-router-dom";
import {
  CANDIDATE_PROFILE_SUMMARY,
  CANDIDATE_TALENTSCOUT_REQUESTS,
} from "../util/routes";
import { withStyle } from "baseui";
import { Icon } from "./Icon";
import { LabelMedium, LabelSmall } from "baseui/typography";

const Wrapper = withStyle(Card, (props) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const getStepsData = (status, anonymousStatus) => {
  const whiteData = [
    {
      title: "Submitted for review",
      text: (
        <div>
          <div>
            We are reviewing your profile. We will keep you updated through your
            email.
          </div>
          <div>TAT: Up to 2-business days</div>
        </div>
      ),
    },
    {
      title: "Submission approved",
      text:
        "We are reviewing your profile. We will keep you updated through your email",
    },
    {
      title: "Submission rejected",
      text:
        "We found some issues with your profile. Please fix those and submit again",
    },
  ];
  const orangeData = [
    {
      title: "Talent-Scouts looking at your profile",
      text:
        "Your anonymous profile is visible to talent-scouts now. You can check for talent-scout requests in the talent-scout requests tab. Premium candidates get shortlisted in 5 business days.",
    },
    {
      title: "Talent-Scout selected by you",
      text: "You have selected your talent-scout",
    },
  ];
  const greenData = [
    {
      title: "Get ready for interviews",
      text:
        "Your anonymous profile is visible to employers now. You can check for interview requests in the interview request tab. Premium candidates start getting interview offers within 10 days.",
    },
    {
      title: "Get ready for interviews",
      text:
        "Your anonymous profile is visible to employers now. You can check for interview requests in the interview request tab. Premium candidates start getting interview offers within 10 days.",
    },
  ];

  const idProofData = [
    {
      title: "ID Proof verification pending",
      text:
        "We are reviewing your ID Proof. Once verified, you will be ready for receiving interview requests.",
    },
    {
      title: "ID Proof verification unsuccessfull",
      text: (
        <div>
          <span style={{ color: "#F8756C", marginRight: "8px" }}>
            We were unable to verify your ID proof.
          </span>
          <Link to={CANDIDATE_TALENTSCOUT_REQUESTS}>See more details</Link>
        </div>
      ),
    },
  ];

  let idProofStatus = "";
  if (status === "USER_VERIFICATION_PENDING") {
    idProofStatus = idProofData[0];
    anonymousStatus = "USER_VERIFICATION";
  } else if (status === "USER_VERIFICATION_REJECTED") {
    idProofStatus = idProofData[1];
    anonymousStatus = "USER_VERIFICATION";
  }

  switch (anonymousStatus) {
    case "WHITE":
      return {
        current: 0,
        currentStatus: status === "REJECTED" ? "Rejected" : "Pending Approval",
        white: status === "REJECTED" ? whiteData[2] : whiteData[0],
        orange: orangeData[0],
        green: greenData[0],
      };
    case "ORANGE":
      return {
        current: 1,
        currentStatus: "Searching Talentscouts",
        white: whiteData[1],
        orange: orangeData[0],
        green: greenData[0],
      };
    case "GREEN":
      return {
        current: 2,
        currentStatus: "Interview Ready",
        white: whiteData[1],
        orange: orangeData[1],
        green: greenData[0],
      };
    case "USER_VERIFICATION":
      return {
        current: 2,
        currentStatus: "ID Proof Verfication",
        white: whiteData[1],
        orange: orangeData[1],
        green: greenData[0],
        idProof: idProofStatus,
      };
    default:
      return {
        current: 0,
        currentStatus: "Pending Approval",
        white: whiteData[0],
        orange: orangeData[0],
        green: greenData[0],
      };
  }
};

const CandidateProgress = (props) => {
  if (props.status === "DISABLED") {
    return (
      <Wrapper>
        <SubTitle>Profile disabled</SubTitle>
        <div
          style={{
            height: "200px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "320px",
              textAlign: "center",
              marginBottom: "16px",
            }}
          >
            <LabelSmall marginTop="scale400">
              Your profile is disabled
            </LabelSmall>
            <div>Update profile and submit for review to enable it.</div>
          </div>
          <Button onClick={() => props.history.push(CANDIDATE_PROFILE_SUMMARY)}>
            Update Profile
          </Button>
        </div>
      </Wrapper>
    );
  }
  if (props.status === "SUSPENDED") {
    return (
      <Wrapper>
        <LabelMedium>Profile suspended</LabelMedium>
        <div
          style={{
            height: "200px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "320px",
              textAlign: "center",
              marginBottom: "16px",
            }}
          >
            <Icon name="alert-triangle" size={32} />
            <LabelSmall marginTop="scale400">
              Your profile is suspended
            </LabelSmall>
            <div>
              Please contact the finding jo support if we have suspended your
              profile by mistake.
            </div>
          </div>
          <a href="mailto:support@findingjo.com">
            Contact support@findingjo.com
          </a>
        </div>
      </Wrapper>
    );
  }

  const stepsData = getStepsData(props.status, props.anonymousStatus);
  return (
    <Wrapper>
      <LabelMedium marginBottom="scale400">Profile Status</LabelMedium>
      <ProgressSteps
        isExpanded
        steps={[
          { title: stepsData.white.title, description: stepsData.white.text },
          { title: stepsData.orange.title, description: stepsData.orange.text },
          stepsData.idProof && {
            title: stepsData.idProof.title,
            description: stepsData.idProof.text,
          },
          {
            title: stepsData.green.title,
            description: stepsData.green.text,
          },
        ].filter(Boolean)}
        currentStep={stepsData.current}
        currentStatus={stepsData.currentStatus}
      />
    </Wrapper>
  );
};

export default withRouter(CandidateProgress);
