import React from "react";
import { styled } from "baseui";
import Button from "../components/Button";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import {
  getCandidateProjection,
  updateCandidateProfile,
} from "../api/candidate";
import { withRouter } from "react-router-dom";
import Spacing from "../components/Spacing";
import { FormControl } from "baseui/form-control";
import { searchEntity } from "../api/entity";
import Label from "../components/Label";
import ScrollToTop from "../components/ScrollToTop";
import Input from "../components/Input";
import Select from "../components/Select";
import Title from "../components/Title";
import PopConfirm from "../components/PopConfirm";
import Modal from "../components/Modal";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import { KIND } from "baseui/button";
import { CANDIDATE_PROFILE_VERIFICATIONS } from "../util/routes";
import LoadingScreen from "../components/LoadingScreen";
import AddSection from "../components/AddSection";
import { getCurrency } from "../util/currency";
import { getShowcaseMap, getShowcase } from "../util/common";
import { Radio, RadioGroup, ALIGN } from "baseui/radio";
import Currency from "../components/Currency";
import Card from "../components/Card";
import * as Analytics from "../analytics";
import SalaryInput from "../components/SalaryInput";
import { Icon } from "../components/Icon";
import { Block } from "baseui/block";
import { LabelSmall } from "baseui/typography";
import { CenterContainer } from "../components/CenterContainer";

const PremiumRow = styled("div", {
  paddingBottom: "64px",
  maxWidth: "980px",
});

const Question = styled("div", {
  marginBottom: "8px",
  fontSize: "18px",
});

const SectionWrapper = styled(Card, ({ isFirst, isLast, align, $theme }) => ({
  position: "relative",
  borderRadius: 0,
  borderTopLeftRadius: isFirst ? $theme.borders.radius300 : 0,
  borderTopRightRadius: isFirst ? $theme.borders.radius300 : 0,
  borderBottomLeftRadius: isLast ? $theme.borders.radius300 : 0,
  borderBottomRightRadius: isLast ? $theme.borders.radius300 : 0,
  ...(!isFirst && { borderTopWidth: 0 }),
}));

class CandidateProfilePremium extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isFetching: true,
      isAdding: false,
      isLoading: false,
      premium: {},
      premiumFeature: "",
      mode: "ADD",
      modalProps: {},
      values: {
        jobOfferCompany: "",
        jobOfferCTC: 0,
        jobOfferCTCCurrency: getCurrency("INR"),
        linkedInReference: "",
        article: "",
        workShowcase: "",
        workShowcaseCategory: getShowcase().id,
        certificateName: "",
        certificateLink: "",
        certificationIssuer: "",
        certificationDescription: "",
        patentTitle: "",
        patentNumber: "",
        patentStatus: "ISSUED",
        patentUrl: "",
        patentDescription: "",
      },
      errors: {},
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[C] page view profile premium");
    this.setState({
      isFetching: true,
    });
    try {
      let { premium } =
        (await getCandidateProjection(this.props.user.id, "premium")) || {};
      premium = premium || {};
      this.setState({
        premium,
      });
      this.setState({
        isFetching: false,
      });
    } catch (ex) {
      this.setState({
        isFetching: false,
      });
      console.log(ex);
    }
  }

  async updateCandidatePremium(premium) {
    try {
      this.setState({
        isAdding: true,
      });
      await updateCandidateProfile(this.props.user.Id, "premium", {
        premium,
      });
      this.setState({
        premium,
        isAdding: false,
      });
      let candidateOnboarding = this.props.user.candidateOnboarding || [];
      if (candidateOnboarding.indexOf("premium") === -1) {
        candidateOnboarding.push("premium");
      }
      this.props.setUser({
        candidateOnboarding,
      });
    } catch (e) {
      this.setState({
        isAdding: false,
      });
      console.log(e);
    }
  }

  async onRemovePatent(index) {
    let updatedPremium = {
      ...this.state.premium,
      ...{
        patents: (this.state.premium.patents || [])
          .slice(0, index)
          .concat((this.state.premium.patents || []).slice(index + 1)),
      },
    };
    await this.updateCandidatePremium(updatedPremium);
  }

  async onRemoveArticle(index) {
    let updatedPremium = {
      ...this.state.premium,
      ...{
        articles: (this.state.premium.articles || [])
          .slice(0, index)
          .concat((this.state.premium.articles || []).slice(index + 1)),
      },
    };
    await this.updateCandidatePremium(updatedPremium);
  }

  async onRemoveWorkShowcases(index) {
    console.log("index", index);
    console.log();
    let updatedPremium = {
      ...this.state.premium,
      ...{
        workShowcases: (this.state.premium.workShowcases || [])
          .slice(0, index)
          .concat((this.state.premium.workShowcases || []).slice(index + 1)),
      },
    };
    await this.updateCandidatePremium(updatedPremium);
  }

  async onRemoveLinkedInReference(index) {
    let updatedPremium = {
      ...this.state.premium,
      ...{
        linkedInReferences: (this.state.premium.linkedInReferences || [])
          .slice(0, index)
          .concat(
            (this.state.premium.linkedInReferences || []).slice(index + 1)
          ),
      },
    };
    await this.updateCandidatePremium(updatedPremium);
  }

  async onRemoveBestCollege(index) {
    let updatedPremium = {
      ...this.state.premium,
      ...{
        colleges: (this.state.premium.colleges || [])
          .slice(0, index)
          .concat((this.state.premium.colleges || []).slice(index + 1)),
      },
    };
    await this.updateCandidatePremium(updatedPremium);
  }
  async onRemoveStartup(index) {
    let updatedPremium = {
      ...this.state.premium,
      ...{
        startups: (this.state.premium.startups || [])
          .slice(0, index)
          .concat((this.state.premium.startups || []).slice(index + 1)),
      },
    };
    await this.updateCandidatePremium(updatedPremium);
  }

  async onRemoveExistingOffers(index) {
    let updatedPremium = {
      ...this.state.premium,
      ...{
        existingOffers: (this.state.premium.existingOffers || [])
          .slice(0, index)
          .concat((this.state.premium.existingOffers || []).slice(index + 1)),
      },
    };
    await this.updateCandidatePremium(updatedPremium);
  }

  async onRemoveCertifications(index) {
    let updatedPremium = {
      ...this.state.premium,
      ...{
        certifications: (this.state.premium.certifications || [])
          .slice(0, index)
          .concat((this.state.premium.certifications || []).slice(index + 1)),
      },
    };
    await this.updateCandidatePremium(updatedPremium);
  }

  async onAddPremium({
    existingOffer,
    patent,
    linkedInReference,
    workShowcase,
    article,
    certification,
  }) {
    let updatedPremium = {
      ...this.state.premium,
      ...{
        existingOffers: existingOffer
          ? (this.state.premium.existingOffers || []).concat(existingOffer)
          : this.state.premium.existingOffers,
        patents: patent
          ? (this.state.premium.patents || []).concat(patent)
          : this.state.premium.patents,
        linkedInReferences: linkedInReference
          ? (this.state.premium.linkedInReferences || []).concat(
              linkedInReference
            )
          : this.state.premium.linkedInReferences,
        workShowcases: workShowcase
          ? (this.state.premium.workShowcases || []).concat(workShowcase)
          : this.state.premium.workShowcases,
        articles: article
          ? (this.state.premium.articles || []).concat(article)
          : this.state.premium.articles,
        certifications: certification
          ? (this.state.premium.certifications || []).concat(certification)
          : this.state.premium.certifications,
      },
    };
    await this.updateCandidatePremium(updatedPremium);
    this.setState({
      premiumModelVisible: false,
    });
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && !this.state.values[id]) {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  getValidations(id) {
    switch (id) {
      case "company":
      case "college":
      case "startup":
      case "jobOfferCompany":
      case "jobOfferCTCCurrency":
        return { requiredSelect: true };
      case "jobOfferCTC":
      case "patentTitle":
      case "patentNumber":
      case "patentUrl":
      case "patentDescription":
      case "linkedInReference":
      case "article":
      case "workShowcase":
      case "certificateName":
      case "certificationDescription":
        return { required: true };
      default:
        return {};
    }
  }

  checkFieldErrors = (fieldsToCheck) => {
    let errors = {};
    let isErrorFound = false;
    fieldsToCheck.forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });
    if (isErrorFound) {
      this.setState({
        errors,
      });
    }
    return isErrorFound;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    switch (this.state.premiumFeature.toUpperCase()) {
      case "JOB OFFERS":
        if (this.state.values["jobOfferCTC"] < 10000) {
          this.setState({
            errors: {
              jobOfferCTC: "Offered Salary cannot be less than 10000",
            },
          });
          return;
        }

        if (
          !this.checkFieldErrors([
            "jobOfferCompany",
            "jobOfferCTCCurrency",
            "jobOfferCTC",
          ])
        ) {
          await this.onAddPremium({
            existingOffer: {
              currency: this.state.values["jobOfferCTCCurrency"].id,
              company: this.state.values["jobOfferCompany"],
              ctc: this.state.values["jobOfferCTC"],
            },
          });
        }
        break;
      case "PATENT":
        if (!this.checkFieldErrors(["patent"])) {
          await this.onAddPremium({
            patent: {
              title: this.state.values["patentTitle"],
              number: this.state.values["patentNumber"],
              status: this.state.values["patentStatus"],
              url: this.state.values["patentUrl"],
              description: this.state.values["patentDescription"],
            },
          });
        }
        break;
      case "LINKEDIN REFERENCE":
        if (!this.checkFieldErrors(["linkedInReference"])) {
          await this.onAddPremium({
            linkedInReference: this.state.values["linkedInReference"],
          });
        }
        break;
      case "WORK SHOWCASE":
        if (!this.checkFieldErrors(["workShowcase"])) {
          await this.onAddPremium({
            workShowcase: {
              url: this.state.values["workShowcase"],
              category: this.state.values["workShowcaseCategory"],
            },
          });
        }
        break;
      case "ARTICLE":
        if (!this.checkFieldErrors(["article"])) {
          await this.onAddPremium({
            article: this.state.values["article"],
          });
        }
        break;
      case "CERTIFICATION":
        if (
          !this.checkFieldErrors([
            "certificationName",
            "certificationLink",
            "certificationDescription",
            "certificationIssuer",
          ])
        ) {
          await this.onAddPremium({
            certification: {
              name: this.state.values["certificationName"],
              link: this.state.values["certificationLink"],
              issuer: this.state.values["certificationIssuer"],
              description: this.state.values["certificationDescription"],
            },
          });
        }
        break;
      default:
    }
    this.setState({
      values: {
        jobOfferCompany: "",
        jobOfferCTC: 0,
        jobOfferCTCCurrency: getCurrency("INR"),
        linkedInReference: "",
        article: "",
        workShowcase: "",
        workShowcaseCategory: getShowcase().id,
        certificateName: "",
        certificateLink: "",
        certificationIssuer: "",
        certificationDescription: "",
        patentTitle: "",
        patentNumber: "",
        patentStatus: "ISSUED",
        patentUrl: "",
        patentDescription: "",
      },
    });
  };

  render() {
    if (this.state.isFetching)
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    return (
      <CenterContainer>
        <ScrollToTop />
        <Title>Premium</Title>
        <PremiumRow>
          <Question>
            Work showcases
            <div style={{ fontSize: "14px", color: "#919191" }}>
              {"Eg. github url, behance url, dribble url etc..."}
            </div>
          </Question>
          {(this.state.premium.workShowcases || []).map((showCase, index) => (
            <SectionWrapper
              key={index}
              isFirst={index === 0 ? 1 : 0}
              isLast={
                index === this.state.premium.workShowcases.length - 1 ? 1 : 0
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <LabelSmall>{showCase.category}</LabelSmall>
                <Block
                  display="flex"
                  top="scale200"
                  right="scale200"
                  position="absolute"
                >
                  <PopConfirm
                    showArrow
                    title={"Are your sure to delete this work showcase?"}
                    onConfirm={async () =>
                      await this.onRemoveWorkShowcases(index)
                    }
                  >
                    <Button kind={KIND.minimal}>
                      <Icon name="trash" size={16} />
                    </Button>
                  </PopConfirm>
                </Block>
              </div>
              <div>{showCase.url}</div>
            </SectionWrapper>
          ))}
          <Block marginTop="scale600">
            <AddSection
              onClick={() =>
                this.setState({
                  premiumModelVisible: true,
                  premiumFeature: "Work Showcase",
                })
              }
              text={"Add Showcase url"}
            ></AddSection>
          </Block>
        </PremiumRow>
        <PremiumRow>
          <Question>Existing job offers</Question>
          {(this.state.premium.existingOffers || []).map(
            (existingOffer, index) => (
              <SectionWrapper
                key={index}
                isFirst={index === 0 ? 1 : 0}
                isLast={
                  index === this.state.premium.existingOffers.length - 1 ? 1 : 0
                }
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <LabelSmall>{existingOffer.company.name}</LabelSmall>
                  <Block
                    display="flex"
                    top="scale200"
                    right="scale200"
                    position="absolute"
                  >
                    <PopConfirm
                      showArrow
                      title={"Are your sure to delete this job offer?"}
                      onConfirm={async () =>
                        await this.onRemoveExistingOffers(index)
                      }
                    >
                      <Button kind={KIND.minimal}>
                        <Icon name="trash" size={16} />
                      </Button>
                    </PopConfirm>
                  </Block>
                </div>
                <div>
                  <Currency
                    currency={existingOffer.currency}
                    value={existingOffer.ctc}
                  />
                </div>
              </SectionWrapper>
            )
          )}
          <Block marginTop="scale600">
            <AddSection
              text={"Add Job Offer"}
              onClick={() =>
                this.setState({
                  premiumModelVisible: true,
                  premiumFeature: "Job Offers",
                })
              }
            />
          </Block>
        </PremiumRow>
        <PremiumRow>
          <Question>Patents</Question>
          {(this.state.premium.patents || []).map((patent, index) => (
            <SectionWrapper
              key={index}
              align={"vertical"}
              isFirst={index === 0 ? 1 : 0}
              isLast={index === this.state.premium.patents.length - 1 ? 1 : 0}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <LabelSmall>{patent.title}</LabelSmall>
                <Block
                  display="flex"
                  top="scale200"
                  right="scale200"
                  position="absolute"
                >
                  <PopConfirm
                    showArrow
                    title={"Are your sure to delete this patent?"}
                    onConfirm={async () => await this.onRemovePatent(index)}
                  >
                    <Button kind={KIND.minimal}>
                      <Icon name="trash" size={16} />
                    </Button>
                  </PopConfirm>
                </Block>
              </div>
              <div>{patent.url}</div>
            </SectionWrapper>
          ))}
          <Block marginTop="scale600">
            <AddSection
              text={"Add Patent Details"}
              onClick={() =>
                this.setState({
                  premiumModelVisible: true,
                  premiumFeature: "Patent",
                })
              }
            />
          </Block>
        </PremiumRow>

        <PremiumRow>
          <Question>Published articles</Question>

          {(this.state.premium.articles || []).map((article, index) => (
            <SectionWrapper
              key={index}
              isFirst={index === 0 ? 1 : 0}
              isLast={index === this.state.premium.articles.length - 1 ? 1 : 0}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <LabelSmall>{article}</LabelSmall>
                <Block
                  display="flex"
                  top="scale200"
                  right="scale200"
                  position="absolute"
                >
                  <PopConfirm
                    showArrow
                    title={"Are your sure to delete this article ?"}
                    onConfirm={async () => await this.onRemoveArticle(index)}
                  >
                    <Button kind={KIND.minimal}>
                      <Icon name="trash" size={16} />
                    </Button>
                  </PopConfirm>
                </Block>
              </div>
            </SectionWrapper>
          ))}
          <Block marginTop="scale600">
            <AddSection
              onClick={() =>
                this.setState({
                  premiumModelVisible: true,
                  premiumFeature: "Article",
                })
              }
              text={"Add article url"}
            />
          </Block>
        </PremiumRow>
        <PremiumRow>
          <Question>Certifications/Awards</Question>

          {(this.state.premium.certifications || []).map(
            (certification, index) => (
              <SectionWrapper
                align={"vertical"}
                key={index}
                isFirst={index === 0 ? 1 : 0}
                isLast={
                  index === this.state.premium.certifications.length - 1 ? 1 : 0
                }
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <LabelSmall>{certification.name}</LabelSmall>
                  <Block
                    display="flex"
                    top="scale200"
                    right="scale200"
                    position="absolute"
                  >
                    <PopConfirm
                      showArrow
                      title={
                        "Are your sure to delete this certification/award ?"
                      }
                      onConfirm={async () =>
                        await this.onRemoveCertifications(index)
                      }
                    >
                      <Button kind={KIND.minimal}>
                        <Icon name="trash" size={16} />
                      </Button>
                    </PopConfirm>
                  </Block>
                </div>
                <div>{certification.link}</div>
                <div>{certification.description}</div>
              </SectionWrapper>
            )
          )}
          <Block marginTop="scale600">
            <AddSection
              onClick={() =>
                this.setState({
                  premiumModelVisible: true,
                  premiumFeature: "Certification",
                })
              }
              text={"Add Certification/Award"}
            />
          </Block>
        </PremiumRow>
        <Modal
          animate
          autoFocus
          isOpen={this.state.premiumModelVisible}
          closeable
          onClose={() =>
            this.setState({
              premiumModelVisible: false,
              jobOfferCompany: "",
              jobOfferCTC: 0,
              jobOfferCTCCurrency: getCurrency("INR"),
              patent: "",
              linkedInReference: "",
              article: "",
              github: "",
              certificateName: "",
              certificateLink: "",
              certificationDescription: "",
            })
          }
        >
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <ModalHeader>
              <Title>{"Add " + this.state.premiumFeature}</Title>
            </ModalHeader>
            <ModalBody>
              {this.state.premiumFeature.toLowerCase() === "job offers" ? (
                <div>
                  <FormControl
                    label={<Label>{"Select the company"}</Label>}
                    error={this.state.errors["jobOfferCompany"]}
                  >
                    <Select
                      creatable
                      getOptions={async (query) =>
                        searchEntity("company", query)
                      }
                      onChange={(value) => {
                        this.updateFormValues("jobOfferCompany", value);
                      }}
                      value={this.state.values["jobOfferCompany"]}
                    />
                  </FormControl>
                  <FormControl
                    label={<Label>{"Enter offered CTC"}</Label>}
                    caption={<div>{"required"}</div>}
                    error={this.state.errors["jobOfferCTC"]}
                  >
                    <SalaryInput
                      name={"jobOfferCTC"}
                      onChange={(val) =>
                        this.updateFormValues("jobOfferCTC", val)
                      }
                      onCurrencyChange={(val) =>
                        this.updateFormValues("jobOfferCTCCurrency", val)
                      }
                      value={this.state.values["jobOfferCTC"]}
                      currencyValue={this.state.values["jobOfferCTCCurrency"]}
                    ></SalaryInput>
                  </FormControl>
                </div>
              ) : null}
              {this.state.premiumFeature.toLowerCase() === "patent" ? (
                <div>
                  <FormControl
                    label={<Label>{"Enter patent title"}</Label>}
                    error={this.state.errors["patentTitle"]}
                  >
                    <Input
                      id={"patentTitle"}
                      onChange={(e) =>
                        this.updateFormValues("patentTitle", e.target.value)
                      }
                      value={this.state.values["patentTitle"]}
                      autoComplete={"off"}
                      onBlur={(e) =>
                        this.updateFormErrors(
                          "patentTitle",
                          this.validate(
                            "patentTitle",
                            this.getValidations("patentTitle")
                          )
                        )
                      }
                    />
                  </FormControl>
                  <FormControl
                    label={<Label>{"Enter patent number"}</Label>}
                    error={this.state.errors["patentNumber"]}
                  >
                    <Input
                      id={"patentNumber"}
                      onChange={(e) =>
                        this.updateFormValues("patentNumber", e.target.value)
                      }
                      value={this.state.values["patentNumber"]}
                      autoComplete={"off"}
                      onBlur={(e) =>
                        this.updateFormErrors(
                          "patentNumber",
                          this.validate(
                            "patentNumber",
                            this.getValidations("patentNumber")
                          )
                        )
                      }
                    />
                  </FormControl>
                  <FormControl
                    label={
                      <Label>{"What is the status of the patent ?"}</Label>
                    }
                    caption={<div>{""}</div>}
                  >
                    <RadioGroup
                      value={this.state.values["patentStatus"]}
                      onChange={(e) => {
                        this.updateFormValues("patentStatus", e.target.value);
                      }}
                      align={ALIGN.vertical}
                    >
                      <Radio value={"ISSUED"}>Issued</Radio>
                      <Radio value={"PENDING"}>Pending</Radio>
                    </RadioGroup>
                  </FormControl>
                  <FormControl
                    label={<Label>{"Enter patent description"}</Label>}
                    error={this.state.errors["patentDescription"]}
                  >
                    <Input
                      id={"patentDescription"}
                      onChange={(e) =>
                        this.updateFormValues(
                          "patentDescription",
                          e.target.value
                        )
                      }
                      value={this.state.values["patentDescription"]}
                      autoComplete={"off"}
                      onBlur={(e) =>
                        this.updateFormErrors(
                          "patentDescription",
                          this.validate(
                            "patentDescription",
                            this.getValidations("patentDescription")
                          )
                        )
                      }
                    />
                  </FormControl>
                  <FormControl
                    label={<Label>{"Enter patent url"}</Label>}
                    error={this.state.errors["patentUrl"]}
                  >
                    <Input
                      id={"patentUrl"}
                      onChange={(e) =>
                        this.updateFormValues("patentUrl", e.target.value)
                      }
                      value={this.state.values["patentUrl"]}
                      autoComplete={"off"}
                      onBlur={(e) =>
                        this.updateFormErrors(
                          "patentUrl",
                          this.validate(
                            "patentUrl",
                            this.getValidations("patentUrl")
                          )
                        )
                      }
                    />
                  </FormControl>
                </div>
              ) : null}
              {this.state.premiumFeature.toLowerCase() ===
              "linkedin reference" ? (
                <FormControl
                  label={<Label>{"Enter linkedin reference url"}</Label>}
                  error={this.state.errors["linkedInReference"]}
                >
                  <Input
                    id={"linkedInReference"}
                    onChange={(e) =>
                      this.updateFormValues("linkedInReference", e.target.value)
                    }
                    value={this.state.values["linkedInReference"]}
                    autoComplete={"off"}
                    onBlur={(e) =>
                      this.updateFormErrors(
                        "linkedInReference",
                        this.validate(
                          "linkedInReference",
                          this.getValidations("linkedInReference")
                        )
                      )
                    }
                  />
                </FormControl>
              ) : null}
              {this.state.premiumFeature.toLowerCase() === "article" ? (
                <FormControl
                  label={<Label>{"Enter article details"}</Label>}
                  error={this.state.errors["article"]}
                >
                  <Input
                    id={"article"}
                    onChange={(e) =>
                      this.updateFormValues("article", e.target.value)
                    }
                    value={this.state.values["article"]}
                    autoComplete={"off"}
                    onBlur={(e) =>
                      this.updateFormErrors(
                        "article",
                        this.validate("article", this.getValidations("article"))
                      )
                    }
                  />
                </FormControl>
              ) : null}
              {this.state.premiumFeature.toLowerCase() === "work showcase" ? (
                <div>
                  <FormControl
                    label={<Label>{"Select category"}</Label>}
                    caption={<div />}
                    error={this.state.errors["workShowcaseCategory"]}
                  >
                    <Select
                      clearable={false}
                      searchable={false}
                      openOnClick={true}
                      defaultOptions={getShowcaseMap()}
                      onChange={(value) => {
                        this.updateFormValues("workShowcaseCategory", value.id);
                      }}
                      value={getShowcase(
                        this.state.values["workShowcaseCategory"]
                      )}
                    />
                  </FormControl>
                  <FormControl
                    label={<Label>{"Enter showcase url"}</Label>}
                    error={this.state.errors["workShowcase"]}
                  >
                    <Input
                      id={"workShowcases"}
                      onChange={(e) =>
                        this.updateFormValues("workShowcase", e.target.value)
                      }
                      value={this.state.values["workShowcase"]}
                      autoComplete={"off"}
                      onBlur={(e) =>
                        this.updateFormErrors(
                          "workShowcase",
                          this.validate(
                            "workShowcase",
                            this.getValidations("workShowcase")
                          )
                        )
                      }
                    />
                  </FormControl>
                </div>
              ) : null}
              {this.state.premiumFeature.toLowerCase() === "certification" ? (
                <div>
                  <FormControl
                    label={<Label>{"Title of the certification/award"}</Label>}
                    error={this.state.errors["certificationName"]}
                  >
                    <Input
                      id={"certificationName"}
                      onChange={(e) =>
                        this.updateFormValues(
                          "certificationName",
                          e.target.value
                        )
                      }
                      value={this.state.values["certificationName"]}
                      autoComplete={"off"}
                      onBlur={(e) =>
                        this.updateFormErrors(
                          "certificationName",
                          this.validate(
                            "certificationName",
                            this.getValidations("certificationName")
                          )
                        )
                      }
                    />
                  </FormControl>
                  <FormControl
                    label={<Label>{"Who issued  certification/award"}</Label>}
                    error={this.state.errors["certificationIssuer"]}
                  >
                    <Input
                      id={"certificationIssuer"}
                      onChange={(e) =>
                        this.updateFormValues(
                          "certificationIssuer",
                          e.target.value
                        )
                      }
                      value={this.state.values["certificationIssuer"]}
                      autoComplete={"off"}
                      onBlur={(e) =>
                        this.updateFormErrors(
                          "certificationIssuer",
                          this.validate(
                            "certificationIssuer",
                            this.getValidations("certificationIssuer")
                          )
                        )
                      }
                    />
                  </FormControl>
                  <FormControl
                    label={<Label>{"Describe the certification/award"}</Label>}
                    error={this.state.errors["certificationDescription"]}
                  >
                    <Input
                      id={"certificationDescription"}
                      onChange={(e) =>
                        this.updateFormValues(
                          "certificationDescription",
                          e.target.value
                        )
                      }
                      value={this.state.values["certificationDescription"]}
                      autoComplete={"off"}
                      onBlur={(e) =>
                        this.updateFormErrors(
                          "certificationDescription",
                          this.validate(
                            "certificationDescription",
                            this.getValidations("certificationDescription")
                          )
                        )
                      }
                    />
                  </FormControl>
                  <FormControl
                    label={<Label>{"Certification/award url"}</Label>}
                    error={this.state.errors["certificationLink"]}
                  >
                    <Input
                      id={"certificationLink"}
                      onChange={(e) =>
                        this.updateFormValues(
                          "certificationLink",
                          e.target.value
                        )
                      }
                      value={this.state.values["certificationLink"]}
                      autoComplete={"off"}
                      onBlur={(e) =>
                        this.updateFormErrors(
                          "certificationLink",
                          this.validate(
                            "certificationLink",
                            this.getValidations("certificationLink")
                          )
                        )
                      }
                    />
                  </FormControl>
                </div>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <Button
                kind={KIND.minimal}
                type={"button"}
                onClick={() => this.setState({ premiumModelVisible: false })}
              >
                Cancel
              </Button>
              <Button type={"submit"} isLoading={this.state.isAdding}>
                {"Add " + this.state.premiumFeature}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
        <Button
          type={"primary"}
          isLoading={this.state.isLoading}
          onClick={async () => {
            try {
              this.setState({
                isLoading: true,
              });
              await updateCandidateProfile(this.props.user.Id, "premium", {
                premium: this.state.premium,
              });
              let candidateOnboarding =
                this.props.user.candidateOnboarding || [];
              if (candidateOnboarding.indexOf("premium") === -1) {
                candidateOnboarding.push("premium");
              }
              this.setState({
                isLoading: false,
              });
              this.props.setUser({
                candidateOnboarding,
              });
              this.props.history.push(CANDIDATE_PROFILE_VERIFICATIONS);
            } catch (e) {
              this.setState({
                isLoading: false,
              });
              console.log(e);
            }
          }}
        >
          Save & Continue
        </Button>
        <Spacing size={4} />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateProfilePremium));
