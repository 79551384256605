import React, { ReactNode } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import type { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { Box, Flex, Text } from '@chakra-ui/react';
import { CandidateFunnelItemList } from './CandidateFunnelItemList';
import { Candidate } from './CandidateFunnelTypes';


export type CandidateFunnelStageProps = {
  title: string,
  candidates: Candidate[],
  index: number,
  renderItem: (candidate: Candidate) => ReactNode;
  renderHeader: (lane: string, index?: number) => ReactNode;
  isScrollable?: boolean,
  isCombineEnabled?: boolean,
  useClone?: boolean,
}

export const CandidateFunnelStage = ({ title, candidates, renderItem, renderHeader, index, isScrollable, isCombineEnabled, useClone }: CandidateFunnelStageProps) => {
  return <Draggable draggableId={title} index={index}>
    {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
      <Flex maxHeight="calc(100vh - 176px)" overflowY="scroll" overflowX="hidden" flexDirection="column" margin={4} ref={provided.innerRef} {...provided.draggableProps}>
        <Box alignItems="center" justifyContent="center" borderTopLeftRadius="4px" borderTopRightRadius="4px" background={snapshot.isDragging ? "gray.400" : "gray.200"} transition="background-color 0.2s ease" _hover={{ backgroundColor: "gray.400" }}  {...provided.dragHandleProps}>
          {renderHeader(title, index)}
        </Box>
        <CandidateFunnelItemList
          listId={title}
          listType="QUOTE"
          style={{
            backgroundColor: snapshot.isDragging ? "green.200" : null,
          }}
          candidates={candidates}
          renderItem={renderItem}
          internalScroll={isScrollable}
          isCombineEnabled={Boolean(isCombineEnabled)}
          useClone={Boolean(useClone)}
        />
      </Flex>
    )}
  </Draggable>
}

{/* <Text
            isDragging={snapshot.isDragging}
           
            aria-label={`${title} quote list`}
            p={2}
            py={4}
            transition="background-color ease 0.2s"
            flexGrow={1}
            userSelect="none"
            position="relative"
            _focus={{
              outline: "2px solid blue",
              outlineOffset: "2px",
            }}
          >
            {title}
          </Text> */}