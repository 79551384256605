import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import thunk from "redux-thunk";
import { loadState, saveState } from "../localstorage";

const persistedState = loadState();
const store = createStore(rootReducer, persistedState, applyMiddleware(thunk));
store.subscribe(() => {
  saveState({
    user: store.getState().user,
    // theme: store.getState().theme
  });
});

export default store;
