import axios from "axios";
import firebase from "firebase";
import { handleApiError } from "../util/common";
import * as Sentry from "@sentry/react";

export default async function api({
  baseUrl,
  url,
  method,
  headers = {},
  params = [],
  data,
  timeout = 15000,
}) {
  const token = firebase.auth().currentUser
    ? await firebase.auth().currentUser.getIdToken()
    : "";
  try {
    headers = {
      ...headers,
      Accept: "application/json",
      "Content-Type": "application/json",
      "id-token": token,
      // tz: moment.tz.guess(),
    };
    let paramList = [];
    for (let p of params) {
      if (typeof p.value !== "undefined") {
        paramList.push(p.name + "=" + p.value);
      }
    }
    if (paramList.length) {
      url = url + "?" + paramList.join("&");
    }
    if (process.env.REACT_APP_ENV === "DEVELOPMENT") {
      console.log(">>> Remote req", baseUrl, url, method, headers, data);
    }
    let res;
    try {
      res = await axios({
        baseURL: baseUrl,
        url: url,
        method,
        headers,
        data,
        timeout: timeout,
      });
    } catch (e) {
      if (process.env.REACT_APP_ENV === "PRODUCTION") {
        Sentry.captureException(e);
      }
      throw e;
    }
    console.log(">>> Remote res", res);
    return res.data;
  } catch (e) {
    let { message, config, code, request, response } = e;
    if (response?.status === 401 && response?.statusText === "Unauthorized") {
      await firebase.auth().signOut();
      localStorage.clear();
      window.location.replace("/login");
    }
    if (process.env.REACT_APP_ENV === "DEVELOPMENT") {
      console.log({ message, config, code, request, response });
    }
    if (code !== "ECONNABORTED") {
      // not showing timeout errors on screen. may have to revisit this logic
      handleApiError(e);
      throw e;
    }
  }
}
