import api from "./index";
import { baseUrl } from "./endpoints";


export type CandidateReviewCommentProps = {
    text: string
    createdBy?: string
    createdByName?: string
    referredCandidateId: string
    createdAt?: number
}

export async function getCandidateReviewCommentsApi({ page, limit, referredCandidateId }: { page: number, limit: number, referredCandidateId: string }) {
    const { data, hasNext }: { data: CandidateReviewCommentProps[], hasNext: boolean } = await api({
        baseUrl,
        url: "/api/candidateReviewComment/",
        method: "GET",
        params: [
            { name: "page", value: page },
            { name: "limit", value: limit },
            { name: "referredCandidateId", value: referredCandidateId }
        ],
        data: {},
    });
    return { comments: data, hasNext }
}


export async function addCandidateReviewCommentApi(candiateReviewComment: CandidateReviewCommentProps) {
    const created: CandidateReviewCommentProps = await api({
        baseUrl,
        url: "/api/candidateReviewComment/",
        method: "POST",
        data: candiateReviewComment,
    });
    return created;
}