import React, { useState } from "react";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { ParagraphSmall, ParagraphXSmall } from "baseui/typography";

const CheckboxGroup = (props) => {
  const [currentVal, setCurrentVal] = useState(props.value || []);
  return (
    <div style={props.columns ? { display: "flex", flexWrap: "wrap" } : {}}>
      {props.options.map((opt, idx) => (
        <div style={props.columns ? { width: "190px" } : {}}>
          <Checkbox
            key={idx}
            checked={currentVal.indexOf(opt.id) !== -1}
            labelPlacement={LABEL_PLACEMENT.right}
            // overrides={{
            //   Label: {
            //     style: ({ $theme }) => {
            //       return {
            //         fontWeight: "normal",
            //         fontSize: "12px",
            //       };
            //     },
            //   },
            //   Checkmark: {
            //     style: ({ $checked, $theme }) => {
            //       return {
            //         borderTopWidth: "2px",
            //         borderBottomWidth: "2px",
            //         borderLeftWidth: "2px",
            //         borderRightWidth: "2px",
            //         borderTopColor: $checked
            //           ? $theme.colors.primary
            //           : "#A1A1A1",
            //         borderLeftColor: $checked
            //           ? $theme.colors.primary
            //           : "#A1A1A1",
            //         borderRightColor: $checked
            //           ? $theme.colors.primary
            //           : "#A1A1A1",
            //         borderBottomColor: $checked
            //           ? $theme.colors.primary
            //           : "#A1A1A1",
            //       };
            //     },
            //   },
            // }}
            onChange={(e) => {
              let newVal = currentVal;
              if (e.target.checked) {
                if (props.single) {
                  newVal = [opt.id];
                } else {
                  newVal.push(opt.id);
                }
              } else {
                let valIndex = currentVal.indexOf(opt.id);
                newVal = currentVal
                  .slice(0, valIndex)
                  .concat(currentVal.slice(valIndex + 1));
              }
              setCurrentVal(newVal);
              props.onChange(newVal);
            }}
          >
            <div>
              <ParagraphSmall>{opt.name}</ParagraphSmall>
              <ParagraphXSmall>{opt.description}</ParagraphXSmall>
            </div>
          </Checkbox>
        </div>
      ))}
    </div>
  );
};

export default CheckboxGroup;

// const CheckboxGroup = (props) => {
//   const [currentVal, setCurrentVal] = useState(props.value || []);
//   return (
//     <div>
//       {props.options.map((opt, i) => (
//         <Checkbox
//           key={i}
//           checked={currentVal.indexOf(opt) !== -1}
//           onChange={(e) => {
//             let newVal = currentVal;
//             if (e.target.checked) {
//               if (props.single) {
//                 newVal = [opt];
//               } else {
//                 newVal.push(opt);
//               }
//             } else {
//               let valIndex = currentVal.indexOf(opt);
//               newVal = currentVal
//                 .slice(0, valIndex)
//                 .concat(currentVal.slice(valIndex + 1));
//             }
//             setCurrentVal(newVal);
//             props.onChange(newVal);
//           }}
//           labelPlacement={LABEL_PLACEMENT.right}
//         >
//           <div>
//             {opt}
//             <div style={{ fontSize: 13, color: "#919191" }}>
//               {props.descriptions && props.descriptions[i]}
//             </div>
//           </div>
//         </Checkbox>
//       ))}
//     </div>
//   );
// };
