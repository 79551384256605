import api from "./index";
import { baseUrl } from "./endpoints";

export async function getUser(userId) {
  return await api({
    baseUrl,
    url: "/api/user/" + userId,
    method: "GET",
  });
}

export async function getUserConfig(userId) {
  return await api({
    baseUrl,
    url: "/api/user/" + userId + "/config",
    method: "GET",
  });
}

export async function getBankDetails(accountId, accountType) {
  return await api({
    baseUrl,
    url: "/api/bank/details/" + accountId + "/" + accountType,
    method: "GET",
  });
}

export async function updateBankDetails(userId, data) {
  return await api({
    baseUrl,
    url: "/api/" + userId + "/bank/details",
    method: "POST",
    data,
  });
}

export async function getWalletPendingWithdrawals(accountId, accountType) {
  return await api({
    baseUrl,
    url: "/api/wallet/withdrawal/pending/" + accountId + "/" + accountType,
    method: "GET",
  });
}

export async function getWalletHoldingAmount(userId) {
  return await api({
    baseUrl,
    url: "/api/" + userId + "/wallet/holding",
    method: "GET",
  });
}

export async function registerUser(data) {
  return await api({
    baseUrl,
    url: "/api/register",
    method: "POST",
    data,
    noIdToken: true,
  });
}

export async function googleSignIn(data) {
  return await api({
    baseUrl,
    url: "/api/user/googleSignIn",
    method: "POST",
    data,
  });
}
export async function resetPasswordUser(data) {
  return await api({
    baseUrl,
    url: "/api/resetPassword",
    method: "POST",
    data,
    noIdToken: true,
  });
}

export async function checkUserExists(data) {
  return await api({
    baseUrl,
    url: "/api/check",
    method: "POST",
    data,
    noIdToken: true,
  });
}

// export async function updateUser(data) {
//   return await api({
//     baseUrl,
//     url: "/api/user",
//     method: "POST",
//     data: data,
//   });
// }

export async function updateUserRole(data) {
  return await api({
    baseUrl,
    url: "/api/user/role",
    method: "POST",
    data: data,
  });
}

export async function updateUserPhotoURL(data) {
  return await api({
    baseUrl,
    url: "/api/user/photourl",
    method: "POST",
    data: data,
  });
}

export async function updateUserOnboarding(data) {
  return await api({
    baseUrl,
    url: "/api/user/onboarding",
    method: "POST",
    data: data,
  });
}

export async function updateUserUniqueId(data) {
  return await api({
    baseUrl,
    url: "/api/user/uniqueid",
    method: "POST",
    data: data,
  });
}

export async function updateUserEmail(data) {
  return await api({
    baseUrl,
    url: "/api/user/email",
    method: "POST",
    data: data,
  });
}

export async function updateUserMobile(data) {
  return await api({
    baseUrl,
    url: "/api/user/mobile",
    method: "POST",
    data: data,
  });
}

export async function createPaymentOrder(userId, data, headers) {
  return await api({
    baseUrl,
    url: "/api/" + userId + "/paymentOrder/create",
    method: "POST",
    data: data,
    headers,
  });
}

export async function verifyPaymentOrder(userId, data, headers) {
  return await api({
    baseUrl,
    url: "/api/" + userId + "/paymentOrder/verify",
    method: "POST",
    data: data,
    headers,
  });
}

export async function sendPaymentLink(userId, data) {
  return await api({
    baseUrl,
    url: "/api/" + userId + "/paymentLink/send",
    method: "POST",
    data: data,
  });
}

export async function getPaymentLink(data) {
  return await api({
    baseUrl,
    url: "/api/paymentLink",
    method: "GET",
    data: data,
    headers: {
      "payment-link-id": data.id,
    },
  });
}

export async function updatePaymentLink(data, headers) {
  return await api({
    baseUrl,
    url: "/api/paymentLink",
    method: "POST",
    data: data,
    headers,
  });
}

export async function getUnreads(userId, category) {
  return await api({
    baseUrl,
    url: "/api/user/" + userId + "/unreads",
    method: "GET",
    params: [{ name: "category", value: category }],
  });
}
