import {
  DisplayMedium,
  DisplaySmall,
  LabelLarge,
  LabelSmall,
} from "baseui/typography";
import React, { useState } from "react";
import { updateUser } from "../api/admin";
import Button from "../components/Button";
import FormControl from "../components/FormControl";
import Label from "../components/Label";
import EmptyCaption from "../components/EmptyCaption";
import Input from "../components/Input";
import PhoneInput from "../components/PhoneInput";

const AdminUserSubmissionForm = ({ user = {}, onUpdate }) => {
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone);
  const [phonePrefix, setPhonePrefix] = useState(user.phonePrefix);
  const [name, setName] = useState(user.name);
  const [linkedInProfile, setLinkedInProfile] = useState(user.linkedInProfile);
  const [errors, setErrors] = useState({});
  return (
    <form
      onSubmit={async (e) => {
        if (!email || email.trim().length == 0) {
          setErrors({
            email: "Please enter email",
          });
        }
        if (!phone || phone.trim().length == 0) {
          setErrors({
            phone: "Please enter phone",
          });
        }
        if (!name || name.trim().length == 0) {
          setErrors({
            name: "Please enter name",
          });
        }
        e.preventDefault();
        e.stopPropagation();
        try {
          await updateUser(user.id, {
            id: user.id,
            email,
            phone,
            phonePrefix,
            linkedInProfile,
            name,
          });
        } catch (e) {}
        await onUpdate();
      }}
    >
      <LabelLarge>User Details</LabelLarge>
      <FormControl
        label={<Label>{"Email"}</Label>}
        caption={<EmptyCaption />}
        error={errors["email"]}
      >
        <Input
          id={"email"}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
      </FormControl>
      <FormControl
        label={<Label>Name</Label>}
        caption={<EmptyCaption />}
        error={errors["name"]}
      >
        <Input
          id={"name"}
          onChange={(e) => setName(e.target.value)}
          value={name}
          autoComplete={"no"}
        />
      </FormControl>
      <FormControl
        label={<Label>Phone number</Label>}
        caption={<EmptyCaption />}
        error={errors["phone"] || errors["phoneCountry"]}
      >
        <PhoneInput
          autoComplete={"no"}
          country={phonePrefix}
          onCountryChange={({ option }) => setPhonePrefix(option)}
          text={phone}
          onTextChange={(e) => setPhone(e.target.value)}
        />
      </FormControl>
      <FormControl
        label={<Label>LinkedIn Profile</Label>}
        caption={<EmptyCaption />}
        error={errors["linkedInProfile"]}
      >
        <Input
          id={"linkedInProfile"}
          onChange={(e) => setLinkedInProfile(e.target.value)}
          value={linkedInProfile}
          autoComplete={"no"}
        />
      </FormControl>
      <Button>Update user</Button>
    </form>
  );
};

export { AdminUserSubmissionForm };
