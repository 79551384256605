import React from "react";
import Button from "../../components/Button";
import { connect } from "react-redux";
import { setUser } from "../../redux/actions/user";
import { withRouter } from "react-router-dom";
import Spacing from "../../components/Spacing";
import AddSection from "../../components/AddSection";
import ProjectSummary from "../../components/ProjectSummary";
import TextArea from "../../components/TextArea";
import Popover from "../../components/Popover";
import {
  getCandidateProjection,
  getCandidate,
  updateCandidateProfile,
} from "../../api/candidate";
import EmptyCaption from "../../components/EmptyCaption";
import Input from "../../components/Input";
import Modal from "../../components/Modal";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import Select from "../../components/Select";
import Title from "../../components/Title";
import { FormControl } from "baseui/form-control";
import Label from "../../components/Label";
import { KIND as BUTTON_KIND } from "baseui/button";
import { CANDIDATE_PROFILE_EXPECTATION } from "../../util/routes";
import LoadingScreen from "../../components/LoadingScreen";
import * as Analytics from "../../analytics";
import { TRIGGER_TYPE } from "baseui/popover";
import Bold from "../../components/Bold";
import { toaster } from "baseui/toast";
import { Block } from "baseui/block";
import { CenterContainer } from "../../components/CenterContainer";
import { searchEntity } from "../../api/entity";

// const PROJECTS_PLACEHOLDER =
//   "Brief Project Intro\n\n\nTeam & Your role\n\n\nProblem  (Challenge/Project Goal/Achievement)\n\n\nSolution (Your contribution & not teams)\n\n\nResults  (the outcome of your effort)\n\n\nSkills used in this project";
class UploadedCandidateProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isFetching: true,
      isAdding: false,
      isSaving: false,
      projectList: props.projectList || [],
      mode: "ADD",
      experienceCompanies: props.experienceCompanies || [],
      modalProps: {},
      editId: "",
      skills: [],
      careerSummary: "",
      idealNextJob: "",
      values: {
        company: "",
        briefIntro: "",
        role: "",
        outcome: "",
        teamSize: 0,
        skills: [],
      },
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      projectList: nextProps.projectList || [],
      experienceCompanies: nextProps.experienceCompanies || [],
    };
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && typeof this.state.values[id] === "undefined") {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let errors = {};
    ["company", "briefIntro"].forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });
    if (isErrorFound) {
      this.setState({
        errors,
      });
    } else {
      console.log(this.state.values);
      await this.onAddProject(this.state.values);
    }
  };

  getValidations(id) {
    switch (id) {
      case "company":
        return { requiredSelect: true };
      case "briefIntro":
      case "idealNextJob":
        return { required: true };
      default:
        return {};
    }
  }

  //   async componentDidMount() {
  //     Analytics.logEvent("[C] page view profile projects");
  //     this.setState({
  //       isFetching: true,
  //     });
  //     try {
  //       let { projects: projectList } =
  //         (await getCandidateProjection(this.props.user.id, "projects")) || {};
  //       this.setState({
  //         projectList: projectList || [],
  //       });
  //       try {
  //         let candidate = await getCandidate(this.props.user.id);
  //         let companies = (candidate.experience || []).map((ex) => ex.company);
  //         let colleges = (candidate.education || []).map((ex) => ex.college);
  //         let experiencePlaces = colleges.concat(companies);
  //         this.setState({
  //           experienceCompanies: experiencePlaces,
  //         });
  //       } catch (e) {
  //         console.log(e);
  //       }
  //       this.setState({
  //         isFetching: false,
  //       });
  //     } catch (ex) {
  //       this.setState({
  //         isFetching: false,
  //       });
  //       console.log(ex);
  //     }
  //   }

  async onAddProject(values) {
    Analytics.logEvent("[C] click add achievement");
    this.setState({
      isModalVisible: false,
      isAdding: true,
    });
    try {
      let projectList = this.state.projectList;
      if (this.state.mode === "ADD") {
        projectList = projectList.concat(values);
      } else {
        projectList[this.state.editIndex] = values;
      }
      this.props.updateProjects({
        projects: projectList,
      });
      this.setState({
        isModalVisible: false,
        modalProps: {},
        projectList,
        isAdding: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        isAdding: false,
      });
    }
  }

  render() {
    let props = this.props;
    return (
      <CenterContainer>
        <Title>Projects</Title>
        <FormControl label={<Label>Add projects</Label>}>
          <div>
            {this.state.projectList.map((project, index) => (
              <ProjectSummary
                key={index}
                id={project.id}
                isLast={index === this.state.projectList.length - 1}
                company={project.company.name}
                briefIntro={project.briefIntro}
                history={props.history}
                isFirst={index === 0}
                index={index}
                updateEdit={(index) => {
                  let editable = this.state.projectList[index];
                  this.setState({
                    isModalVisible: true,
                    mode: "EDIT",
                    editIndex: index,
                    values: {
                      company: editable.company || "",
                      briefIntro: editable.briefIntro || "",
                      role: editable.role || "",
                      outcome: editable.outcome || "",
                      teamSize: editable.teamSize || 0,
                      skills: editable.skills || [],
                    },
                    errors: {},
                  });
                }}
                onRemove={async (index) => {
                  Analytics.logEvent("[C] click remove achievement");
                  try {
                    let projectList = this.state.projectList
                      .slice(0, index)
                      .concat(this.state.projectList.slice(index + 1));
                    this.props.updateProjects({
                      projects: projectList,
                    });
                    // await updateCandidateProfile(
                    //   this.props.user.Id,
                    //   "projects",
                    //   {
                    //     projects: projectList,
                    //   }
                    // );
                    this.setState({
                      projectList,
                    });
                  } catch (e) {
                    console.log("error while deleting");
                  }
                }}
              />
            ))}
          </div>
        </FormControl>

        {this.state.projectList.length < 3
          ? new Array(3 - this.state.projectList.length).fill(1).map((el) => (
              <Block marginBottom="scale600">
                <AddSection
                  text={"Add project"}
                  onClick={() =>
                    this.setState({
                      isModalVisible: true,
                      mode: "ADD",
                      values: {
                        company: "",
                        briefIntro: "",
                      },
                      errors: {},
                    })
                  }
                />
              </Block>
            ))
          : null}
        <Spacing size={32} />
        {/* <Button
          isLoading={this.state.isSaving}
          onClick={async () => {
            if (this.state.projectList.length < 2) {
              toaster.negative(<div>Add minimum 2 projects</div>, {
                autoHideDuration: 3000,
              });
              return;
            }
            try {
              await updateCandidateProfile(this.props.user.Id, "projects", {
                projects: this.state.projectList,
              });
              this.setState({
                isSaving: false,
              });
            } catch (e) {
              this.setState({
                isSaving: false,
              });
              return;
            }
            let candidateOnboarding = this.props.user.candidateOnboarding || [];
            if (candidateOnboarding.indexOf("projects") === -1) {
              candidateOnboarding.push("projects");
            }
            this.props.setUser({
              candidateOnboarding,
            });
            this.props.history.push(CANDIDATE_PROFILE_EXPECTATION);
          }}
        >
          Save & Continue
        </Button> */}
        {/* <Spacing size={32} /> */}
        <Modal
          animate
          autoFocus
          isOpen={this.state.isModalVisible}
          closeable
          onClose={() => this.setState({ isModalVisible: false })}
        >
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <ModalHeader>
              <Title>
                {this.state.mode === "ADD" ? "Add Project" : "Edit Project"}
              </Title>
            </ModalHeader>
            <ModalBody>
              <FormControl
                label={<Label>{"Select the company"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["company"]}
              >
                <Select
                  placeholder={"Select Company"}
                  searchable={false}
                  clearable={false}
                  defaultOptions={this.state.experienceCompanies.map((ex) => {
                    ex.isCreatable = false;
                    return ex;
                  })}
                  onChange={(value) => {
                    this.updateFormValues("company", value);
                  }}
                  value={this.state.values["company"]}
                />
              </FormControl>
              <FormControl label={<Label>{"Description"}</Label>}>
                <TextArea
                  id={"briefIntro"}
                  resize
                  height={"50px"}
                  onChange={(e) => {
                    if (typeof e.target.value !== "undefined") {
                      this.updateFormValues("briefIntro", e.target.value);
                    }
                  }}
                  value={this.state.values["briefIntro"]}
                  autoComplete={"off"}
                />
              </FormControl>
              {/* <FormControl
                label={<Label>{"Your role and contribution"}</Label>}
              >
                <TextArea
                  id={"role"}
                  resize
                  height={"50px"}
                  onChange={(e) => {
                    if (typeof e.target.value !== "undefined") {
                      this.updateFormValues(
                        "role",
                        e.target.value.substring(0, 300)
                      );
                    }
                  }}
                  value={this.state.values["role"]}
                  autoComplete={"off"}
                />
              </FormControl>
              <FormControl
                label={
                  <Label
                    appendText={
                      (this.state.values["outcome"] || "").length + "/300 chars"
                    }
                  >
                    {"Project result"}
                  </Label>
                }
              >
                <TextArea
                  id={"outcome"}
                  resize
                  height={"50px"}
                  onChange={(e) => {
                    if (typeof e.target.value !== "undefined") {
                      this.updateFormValues(
                        "outcome",
                        e.target.value.substring(0, 300)
                      );
                    }
                  }}
                  value={this.state.values["outcome"]}
                  autoComplete={"off"}
                />
              </FormControl>
              <FormControl label={<Label>{"Team size"}</Label>}>
                <Input
                  id={"teamSize"}
                  onChange={(e) => {
                    let ts = +e.target.value;
                    if (isNaN(ts)) {
                      this.updateFormValues("teamSize", 0);
                    } else {
                      this.updateFormValues("teamSize", parseInt(ts));
                    }
                  }}
                  value={this.state.values["teamSize"]}
                  autoComplete={"off"}
                />
              </FormControl>
              <FormControl
                label={
                  <Label
                    appendText={
                      "(" +
                      (this.state.values["skills"] || "").length +
                      "/3 skills)"
                    }
                  >
                    {"Skills used in this project"}
                  </Label>
                }
                caption={<EmptyCaption />}
                error={this.state.skillsError}
              >
                <Select
                  creatable
                  multi
                  getOptions={async (query) => searchEntity("skill", query)}
                  onChange={(value) => {
                    if ((value || []).length > 3) {
                      return;
                    }
                    this.updateFormValues("skills", value);
                  }}
                  value={this.state.values["skills"]}
                />
              </FormControl> */}
            </ModalBody>
            <ModalFooter>
              <Button
                type={"button"}
                kind={BUTTON_KIND.tertiary}
                onClick={() => this.setState({ isModalVisible: false })}
                style={{ marginRight: "16px" }}
              >
                Cancel
              </Button>
              <Button type={"submit"} isLoading={this.state.isAdding}>
                {this.state.mode === "ADD" ? "Add Project" : "Update Project"}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </CenterContainer>
    );
  }
}

export default UploadedCandidateProjects;
