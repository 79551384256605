import React from "react";
import Modal from "../components/Modal";
import Title from "../components/Title";
import Button from "../components/Button";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import FileUploader from "../components/FileUploader";
import { toaster } from "baseui/toast";
import { updateUserPhotoURL } from "../api/user";
import { setWidgetState } from "../redux/actions/widget";
import { setUser } from "../redux/actions/user";
import { connect } from "react-redux";

class ChangePictureModel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.fileUploaderRef = React.createRef();
  }

  render() {
    return (
      <Modal
        animate
        autoFocus
        isOpen={this.props.isChangePictureModelVisible}
        closeable
        onClose={() => {
          this.props.setWidgetState({
            isChangePictureModelVisible: false,
          });
        }}
      >
        <ModalHeader>
          <Title>{"Change picture"}</Title>
        </ModalHeader>
        <ModalBody>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <FileUploader
              ref={this.fileUploaderRef}
              shape={"circle"}
              width={250}
              height={250}
              uploadUrl={
                "profile_pictures/" + this.props.user.id + "_" + Date.now()
              }
              onError={(error) => {
                toaster.negative(<div>{error}</div>, {
                  autoHideDuration: 2000,
                });
              }}
              onSuccess={async (downloadUrl) => {
                await updateUserPhotoURL({
                  id: this.props.user.id,
                  photoURL: downloadUrl,
                });
                this.props.setUser({
                  photoURL: downloadUrl,
                });
                this.props.setWidgetState({
                  isChangePictureModelVisible: false,
                });
                toaster.positive(
                  <div>Profile picture updated successfully.</div>,
                  {
                    autoHideDuration: 3000,
                  }
                );
              }}
            />
          </div>
          <div style={{ textAlign: "center" }}>Max file size: 2MB</div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              this.fileUploaderRef.current.upload();
            }}
            // isLoading={this.state.isChangingPicture}
          >
            Change Picture
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
    isChangePictureModelVisible: state.widget.isChangePictureModelVisible,
  }),
  { setUser, setWidgetState }
)(ChangePictureModel);
