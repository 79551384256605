import React from "react";
import Button from "../components/Button";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Spacing from "../components/Spacing";
import AddSection from "../components/AddSection";
import EducationSummary from "../components/EducationSummary";
import { searchEntity } from "../api/entity";
import {
  getCandidateProjection,
  updateCandidateProfile,
} from "../api/candidate";
import EmptyCaption from "../components/EmptyCaption";
import Modal from "../components/Modal";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import Select from "../components/Select";
import Title from "../components/Title";
import { FormControl } from "baseui/form-control";
import Label from "../components/Label";
import { CANDIDATE_PROFILE_NOTICE_PERIOD } from "../util/routes";
import {
  MONTHS,
  YEARS,
  getMonthValue,
  getYearValue,
  getTimeFromMonthAndYear,
} from "../util/time";
import { KIND as BUTTON_KIND } from "baseui/button";
import LoadingScreen from "../components/LoadingScreen";
import * as Analytics from "../analytics";
import { Block } from "baseui/block";
import { CenterContainer } from "../components/CenterContainer";

const EMPTY_EDUCATION = {
  college: undefined,
  course: undefined,
  courseType: 0,
  startTime: undefined,
  endTime: undefined,
  isHighlight: undefined,
  specializations: undefined,
};

// const getDate = epoch => {
//   let d = new Date(epoch);
//   return /* d.getDate() + "/" + (d.getMonth() + 1) + "/" + */ d.getFullYear();
// };

class CandidateProfileEducation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isFetching: true,
      isAdding: false,
      isConfirmVisible: false,
      educationList: [],
      mode: "ADD",
      modalProps: EMPTY_EDUCATION,
      editId: "",
      values: {
        college: "",
        course: "",
        courseType: 0,
        isHighlight: false,
        specializations: [],
      },
      errors: {},
    };
  }

  toggleConfirm(visible, callback) {
    this.setState(
      {
        isConfirmVisible: visible,
      },
      callback
    );
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && typeof this.state.values[id] === "undefined") {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let errors = {};
    [
      "college",
      "course",
      "startYear",
      "startMonth",
      "endYear",
      "endMonth",
    ].forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });
    if (!this.state.values.isCurrent && !this.state.values.endYear) {
      errors["endYear"] = "Required Field";
      isErrorFound = true;
    }
    if (!this.state.values.isCurrent && !this.state.values.endMonth) {
      errors["endMonth"] = "Required Field";
      isErrorFound = true;
    }
    let startTime = getTimeFromMonthAndYear(
      this.state.values.startMonth,
      this.state.values.startYear
    );
    let endTime = getTimeFromMonthAndYear(
      this.state.values.endMonth,
      this.state.values.endYear
    );
    if (startTime > 0 && endTime > 0 && endTime < startTime) {
      errors["endYear"] =
        "Education end time should be greater than start time";
      isErrorFound = true;
    }
    if (isErrorFound) {
      this.setState({
        errors,
      });
    } else {
      await this.onAddEducation(this.state.values);
    }
  };

  getValidations(id) {
    switch (id) {
      case "college":
      case "course":
        return { requiredSelect: true };
      case "startMonth":
      case "startYear":
      case "endMonth":
      case "endYear":
        return { required: true };
      default:
        return {};
    }
  }

  async componentDidMount() {
    Analytics.logEvent("[C] page view profile education");
    this.setState({
      isFetching: true,
    });
    try {
      let { education: educationList } =
        (await getCandidateProjection(this.props.user.id, "education")) || {};
      this.setState({
        isFetching: false,
        educationList: educationList.length ? educationList : [],
      });
    } catch (ex) {
      this.setState({
        isFetching: false,
      });
      console.log(ex);
    }
  }

  async onAddEducation(values) {
    Analytics.logEvent("[C] click add education");
    this.setState({
      isModalVisible: false,
      isAdding: true,
    });
    try {
      let educationList = this.state.educationList;
      if (this.state.mode === "ADD") {
        educationList = educationList.concat(values);
      } else {
        educationList[this.state.editIndex] = values;
      }
      educationList.sort(
        (a, b) =>
          getTimeFromMonthAndYear(b.startMonth, b.startYear) -
          getTimeFromMonthAndYear(a.startMonth, a.startYear)
      );
      await updateCandidateProfile(this.props.user.Id, "education", {
        education: educationList,
      });
      this.setState({
        educationList,
        modalProps: {},
        isAdding: false,
      });
      let candidateOnboarding = this.props.user.candidateOnboarding || [];
      if (candidateOnboarding.indexOf("education") === -1) {
        candidateOnboarding.push("education");
      }
      this.props.setUser({
        candidateOnboarding,
      });
    } catch (e) {
      this.setState({
        isAdding: false,
      });
      console.log(e);
    }
  }

  render() {
    if (this.state.isFetching)
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    let props = this.props;
    return (
      <CenterContainer>
        <Title>{"Your Education"}</Title>
        {this.state.educationList.map((ed, index) => (
          <EducationSummary
            key={index}
            id={ed.id}
            courseTitle={ed.college.name || "Course Title"}
            courseDuration={ed.startYear + "-" + ed.endYear}
            subject={(ed.course && ed.course.name) || "Degree"}
            history={props.history}
            isFirst={index === 0}
            index={index}
            isLast={index === this.state.educationList.length - 1}
            updateEdit={(index) => {
              let editable = this.state.educationList[index];
              this.setState({
                editIndex: index,
                isModalVisible: true,
                mode: "EDIT",
                values: {
                  college: editable.college,
                  course: editable.course,
                  courseType: editable.courseType,
                  startMonth: editable.startMonth,
                  startYear: editable.startYear,
                  endMonth: editable.endMonth,
                  endYear: editable.endYear,
                  isHighlight: false,
                  specializations: editable.specializations,
                },
                errors: {},
              });
            }}
            onRemove={async (index) => {
              try {
                Analytics.logEvent("[C] click remove education");
                let educationList = this.state.educationList
                  .slice(0, index)
                  .concat(this.state.educationList.slice(index + 1));
                await updateCandidateProfile(this.props.user.Id, "education", {
                  education: educationList,
                });
                this.setState({
                  educationList,
                });
              } catch (e) {
                console.log("error while deleting");
              }
            }}
          />
        ))}
        <Block marginTop="scale600">
          <AddSection
            text={"Add Education"}
            onClick={() =>
              this.setState({
                isModalVisible: true,
                mode: "ADD",
                modalProps: EMPTY_EDUCATION,
                values: {
                  college: "",
                  course: "",
                  courseType: 0,
                  isHighlight: false,
                  specializations: [],
                },
                errors: {},
              })
            }
          />
        </Block>
        <Modal
          animate
          autoFocus
          isOpen={this.state.isModalVisible}
          closeable
          onClose={() => this.toggleConfirm(true)}
        >
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <ModalHeader>
              <Title>
                {this.state.mode === "ADD" ? "Add Education" : "Edit Education"}
              </Title>
            </ModalHeader>
            <ModalBody>
              <FormControl
                label={<Label>{"Enter your college name"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["college"]}
              >
                <Select
                  type={"search"}
                  creatable
                  getOptions={async (query) => searchEntity("college", query)}
                  onChange={(value) => {
                    this.updateFormValues("college", value);
                  }}
                  value={this.state.values["college"]}
                />
              </FormControl>
              <FormControl
                label={
                  <Label subtitle={"Eg. Bachelor of Technology"}>
                    {"Enter your degree name"}
                  </Label>
                }
                caption={<EmptyCaption />}
                error={this.state.errors["course"]}
              >
                <Select
                  type={"search"}
                  creatable
                  getOptions={async (query) => searchEntity("course", query)}
                  onChange={(value) => {
                    this.updateFormValues("course", value);
                  }}
                  value={this.state.values["course"]}
                />
              </FormControl>
              <FormControl
                label={
                  <Label subtitle={"Eg. Computer Science"}>
                    {"Field of study"}
                  </Label>
                }
                caption={<EmptyCaption />}
                error={this.state.errors["specializations"]}
              >
                <Select
                  type={"search"}
                  creatable
                  multi
                  getOptions={async (query) =>
                    searchEntity("specialization", query)
                  }
                  onChange={(value) => {
                    this.updateFormValues("specializations", value);
                  }}
                  value={this.state.values["specializations"]}
                />
              </FormControl>
              <FormControl
                label={<Label>{"Select course type"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["courseType"]}
              >
                <Select
                  clearable={false}
                  defaultOptions={[
                    { id: 0, name: "Full time" },
                    { id: 1, name: "Part time" },
                    { id: 2, name: "Certification" },
                  ]}
                  onChange={(value) => {
                    this.updateFormValues("courseType", value.id);
                  }}
                  value={{ id: this.state.values["courseType"] }}
                />
              </FormControl>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ maxWidth: "120px" }}>
                  <FormControl
                    label={<Label>{"Start month"}</Label>}
                    caption={<EmptyCaption />}
                    error={this.state.errors["startMonth"]}
                  >
                    <Select
                      placeholder={"MM"}
                      width={"120px"}
                      clearable={false}
                      searchable={false}
                      defaultOptions={MONTHS}
                      maxDropdownHeight={"200px"}
                      value={getMonthValue(this.state.values["startMonth"])}
                      onChange={(value) => {
                        this.updateFormValues("startMonth", value.id);
                      }}
                    />
                  </FormControl>
                </div>
                <div style={{ maxWidth: "120px" }}>
                  <FormControl
                    label={<Label>{"Start year"}</Label>}
                    caption={<EmptyCaption />}
                    error={this.state.errors["startYear"]}
                  >
                    <Select
                      placeholder={"YYYY"}
                      clearable={false}
                      searchable={false}
                      width={"80px"}
                      defaultOptions={YEARS}
                      maxDropdownHeight={"200px"}
                      value={getYearValue(this.state.values["startYear"])}
                      onChange={(value) => {
                        this.updateFormValues("startYear", value.id);
                      }}
                    />
                  </FormControl>
                </div>
                <div style={{ maxWidth: "120px" }}>
                  {!this.state.values["isCurrent"] ? (
                    <FormControl
                      label={<Label>{"End month"}</Label>}
                      caption={<EmptyCaption />}
                      error={this.state.errors["endMonth"]}
                    >
                      <Select
                        placeholder={"MM"}
                        clearable={false}
                        width={"120px"}
                        searchable={false}
                        defaultOptions={MONTHS}
                        maxDropdownHeight={"200px"}
                        value={getMonthValue(this.state.values["endMonth"])}
                        onChange={(value) => {
                          this.updateFormValues("endMonth", value.id);
                        }}
                      />
                    </FormControl>
                  ) : (
                    <div style={{ width: "" }} />
                  )}
                </div>
                <div style={{ maxWidth: "120px" }}>
                  {!this.state.values["isCurrent"] ? (
                    <FormControl
                      label={<Label>{"End year"}</Label>}
                      caption={<EmptyCaption />}
                      error={this.state.errors["endYear"]}
                    >
                      <Select
                        placeholder={"YYYY"}
                        clearable={false}
                        searchable={false}
                        width={"80px"}
                        defaultOptions={YEARS}
                        maxDropdownHeight={"200px"}
                        value={getYearValue(this.state.values["endYear"])}
                        onChange={(value) => {
                          this.updateFormValues("endYear", value.id);
                        }}
                      />
                    </FormControl>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                type={"button"}
                buttonStyle={{ marginRight: "16px" }}
                kind={BUTTON_KIND.tertiary}
                onClick={() => this.setState({ isModalVisible: false })}
              >
                Cancel
              </Button>
              <Button type={"submit"} isLoading={this.state.isAdding}>
                {this.state.mode === "ADD"
                  ? "Add Education"
                  : "Update Education"}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
        <Modal
          autoFocus={false}
          onClose={() => this.toggleConfirm(false)}
          isOpen={this.state.isConfirmVisible}
        >
          <ModalHeader>Confirm</ModalHeader>
          <ModalBody>Do you want to close this ?</ModalBody>
          <ModalFooter>
            <Button
              type={"button"}
              kind={BUTTON_KIND.tertiary}
              buttonStyle={{ marginRight: "16px" }}
              onClick={() => {
                this.toggleConfirm(false, () =>
                  this.setState({
                    isModalVisible: false,
                  })
                );
              }}
            >
              Close
            </Button>
            <Button type={"button"} onClick={() => this.toggleConfirm(false)}>
              Don't close
            </Button>
          </ModalFooter>
        </Modal>
        <Spacing size={32} />
        <Button
          type={"primary"}
          onClick={async () => {
            try {
              await updateCandidateProfile(this.props.user.Id, "education", {
                education: this.state.educationList,
              });
              let candidateOnboarding =
                this.props.user.candidateOnboarding || [];
              if (candidateOnboarding.indexOf("education") === -1) {
                candidateOnboarding.push("education");
              }
              this.props.setUser({
                candidateOnboarding,
              });
              this.props.history.push(CANDIDATE_PROFILE_NOTICE_PERIOD);
            } catch (e) {}
          }}
        >
          Save & Continue
        </Button>
        <Spacing size={32} />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateProfileEducation));
