import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Title from "../../../components/Title";
import { logEvent } from "../../../analytics/index";
import Pagination from "../../../components/Pagination";
import { CenterContainer } from "../../../components/CenterContainer";
import LoadingScreen from "../../../components/LoadingScreen";
import Select from "../../../components/Select";
import { Block } from "baseui/block";
import CandidateCard from "../../../components/employer/CandidateCard";
import { getBoughtCandidates } from "../../../api/job";
import { getCurrency, getCurrencyWords } from "../../../util/currency";
import { prettyPrint } from "../../../util/time";
import { LabelSmall } from "baseui/typography";

const BoughtCandidates = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [interviewRequests, setInterviewRequests] = useState({});
  const [filter, setFilter] = useState({ id: "", name: "All" });
  const [page, setPage] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const { jobId } = props;
  const limit = 10;

  const onLoad = useCallback(
    async (page, filter) => {
      try {
        setIsFetching(true);
        let {
          data: candidates,
          interviewRequests,
          hasNext,
        } = await getBoughtCandidates(jobId, filter.id, page, limit);
        setIsFetching(false);
        setCandidates(candidates || []);
        setInterviewRequests(interviewRequests || {});
        setHasNext(hasNext);
      } catch (e) {
        console.log(e);
      }
    },
    [jobId]
  );

  const onNextClick = () => {
    setPage(page + 1);
    setHasPrev(page + 1 > 0);
    onLoad(page + 1, filter);
  };

  const onPrevClick = () => {
    setPage(page - 1);
    setHasPrev(page - 1 > 0);
    onLoad(page - 1, filter);
  };

  useEffect(() => {
    logEvent("[E] page view job bought");
    onLoad(page, filter);
  }, [page, filter, onLoad]);

  const getStatusInfo = (interviewRequest) => {
    if (!interviewRequest) return null;
    const offerText =
      "Offer Made: " +
      getCurrency(interviewRequest.offeredCTCCurrency).name +
      " " +
      getCurrencyWords(interviewRequest.offeredCTC);
    const expireText = "Expiry: " + prettyPrint(interviewRequest.expireAt);

    switch (interviewRequest.status) {
      case "APPROVED":
        return {
          title: "APPROVED",
          messages: [
            offerText,
            "Interview request has been approved by the candidate. Please schedule the interview with in a week",
            expireText,
          ],
        };
      case "REJECTED":
      case "ONHOLD":
        return {
          title: "REJECTED",
          messages: [
            offerText,
            "Interview request has been rejected by the candidate",
            interviewRequest.statusReason ? (
              <LabelSmall>{`Reason: ${interviewRequest.statusReason ||
                ""}`}</LabelSmall>
            ) : null,
            expireText,
          ],
        };
      case "CREATED":
        return {
          title: "PENDING",
          messages: [
            offerText,
            "Interview request is pending for approval from FindingJo",
            expireText,
          ],
        };
      case "REVIEWED":
        return {
          title: "PENDING",
          messages: [
            offerText,
            "Interview request is pending for approval from FindingJo",
            expireText,
          ],
        };
      default:
    }
  };
  const getInterviewRequestInfo = (interviewRequest) => {
    return getStatusInfo(interviewRequest);
  };

  return (
    <CenterContainer>
      <Block
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="scale400"
      >
        <Title>Bought Candidates</Title>
        <Block width="150px">
          <Select
            clearable={false}
            defaultOptions={[
              { id: "", name: "All" },
              { id: "BOUGHT", name: "Active" },
              { id: "PENDING", name: "Pending" },
              { id: "REJECTED", name: "Rejected" },
            ]}
            onChange={(value) => {
              setFilter(value);
              setPage(0);
              onLoad(page, value);
            }}
            value={filter}
          />
        </Block>
      </Block>
      {Object.values(interviewRequests).length === 0 && (
        <CenterContainer padding="0px">
          <Block marginTop="70px">
            Bought candidates for this job will appear here.
          </Block>
        </CenterContainer>
      )}
      {Object.values(interviewRequests).map((interviewRequest, index) => {
        return (
          candidates[interviewRequest.anonymousId] && (
            <CandidateCard
              key={index}
              to={
                "/employer/mycandidate/" +
                interviewRequest.id +
                "/details?jobId=" +
                jobId
              }
              onShare={() => {}}
              candidate={candidates[interviewRequest.anonymousId]}
              isClickable
              isOpenNewWindow
              status={getInterviewRequestInfo(interviewRequest)}
            />
          )
        );
      })}
      <Pagination
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        hasNext={hasNext}
        hasPrev={hasPrev}
      />
      <LoadingScreen show={isFetching} />
    </CenterContainer>
  );
};

export default withRouter(BoughtCandidates);
