import React from "react";
import { PhoneInput, SIZE } from "baseui/phone-input";

const StyledPhoneInput = (props) => (
  <PhoneInput
    {...props}
    overrides={{
      Input: {
        props: {
          overrides: {
            InputContainer: {
              style: ({ $theme }) => {
                return {
                  borderRadius: $theme.borders.radius200,
                  background: $theme.colors.background,
                };
              },
            },
            Root: {
              style: ({ $theme, $isFocused }) => {
                return {
                  borderWidth: "1px",
                  borderColor: $isFocused ? "rgba(44, 110, 203, 1)" : "#dadedf",
                  borderRadius: $theme.borders.radius200,
                  // borderTopWidth: "1px",
                  // borderBottomWidth: "1px",
                  // borderLeftWidth: "1px",
                  // borderRightWidth: "1px",
                  // backgroundColor: "#ffffff",
                  // borderTopLeftRadius: "4px",
                  // borderBottomLeftRadius: "4px",
                  // borderTopRightRadius: "4px",
                  // borderBottomRightRadius: "4px",
                };
              },
            },
          },
        },
      },
    }}
  >
    {props.children}
  </PhoneInput>
);

export default StyledPhoneInput;
