import React from "react";
import Button from "../../components/Button";
import Spacing from "../../components/Spacing";
import AddSection from "../../components/AddSection";
import ItemSummary from "../../components/ItemSummary";
import { FormControl } from "baseui/form-control";
import Label from "../../components/Label";
import Input from "../../components/Input";
import ExperienceSummary from "../../components/ExperienceSummary";
import { searchEntity } from "../../api/entity";
import Select from "../../components/Select";
import Title from "../../components/Title";
import EmptyCaption from "../../components/EmptyCaption";
import { Checkbox } from "baseui/checkbox";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { KIND as BUTTON_KIND } from "baseui/button";
import Modal from "../../components/Modal";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import { CANDIDATE_PROFILE_EDUCATION } from "../../util/routes";
import {
  MONTHS,
  YEARS,
  getMonthValue,
  getYearValue,
  getDays,
  getCareerGaps,
  displayDuration,
  printMonthYear,
  getTimeFromMonthAndYear,
  getMonthDuration,
} from "../../util/time";
import LoadingScreen from "../../components/LoadingScreen";
import * as Analytics from "../../analytics";
import Bold from "../../components/Bold";
import { Block } from "baseui/block";
import { CenterContainer } from "../../components/CenterContainer";
import Textarea from "../../components/TextArea";
import Card from "../../components/Card";

class UploadedCandidateExperience extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      isExperienceError: false,
      isModalVisible: false,
      isConfirmVisible: false,
      isAdding: false,
      isSaving: false,
      isFetching: false,
      isExperienced: false,
      isFresher: false,
      experienceList: props.experienceList || [],
      careerGaps: [],
      mode: "ADD",
      modalProps: {},
      errors: {},
      editId: "",
      values: {
        isCurrent: false,
        jobFunctions: [],
        isIndividualContributor: false,
        isManager: true,
        numberOfReportees: 0,
        description: "",
      },
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return { experienceList: nextProps.experienceList || [] };
  }

  toggleConfirm(visible, callback) {
    this.setState(
      {
        isConfirmVisible: visible,
      },
      callback
    );
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && typeof this.state.values[id] === "undefined") {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  getValidations(id) {
    switch (id) {
      case "title":
      case "company":
      case "location":
        return { requiredSelect: true };
      case "startMonth":
      case "startYear":
        return { required: true };
      case "jobFunctions":
        return { requiredArray: true };
      default:
        return {};
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    console.log(this.state);
    let errors = {};
    [
      "title",
      "company",
      "location",
      "startMonth",
      "startYear",
      "jobFunctions",
    ].forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });
    if (
      !this.state.values.isCurrent &&
      typeof this.state.values.endYear === "undefined"
    ) {
      errors["endYear"] = "Required Field";
      isErrorFound = true;
    }
    if (
      !this.state.values.isCurrent &&
      typeof this.state.values.endMonth === "undefined"
    ) {
      errors["endMonth"] = "Required Field";
      isErrorFound = true;
    }
    let startTime = getTimeFromMonthAndYear(
      this.state.values.startMonth,
      this.state.values.startYear
    );
    let endTime = getTimeFromMonthAndYear(
      this.state.values.endMonth,
      this.state.values.endYear
    );
    if (startTime > 0 && endTime > 0 && endTime < startTime) {
      errors["endYear"] =
        "Experience end time should be greater than start time";
      isErrorFound = true;
    }
    if (isErrorFound) {
      this.setState({
        errors,
      });
    } else {
      await this.onAddExperience(this.state.values);
    }
  };

  async updateExperience(experienceList) {
    experienceList.sort(
      (a, b) =>
        getTimeFromMonthAndYear(b.startMonth, b.startYear) -
        getTimeFromMonthAndYear(a.startMonth, a.startYear)
    );
    let updatedCareerGaps = getCareerGaps(
      experienceList.map((ex) => ({
        startTime: getTimeFromMonthAndYear(ex.startMonth, ex.startYear),
        endTime: getTimeFromMonthAndYear(ex.endMonth, ex.endYear),
      }))
    );
    updatedCareerGaps.forEach(
      (cg, idx) => (cg.reason = (this.state.careerGaps[idx] || {}).reason)
    );
    this.props.updateExperience({
      totalExperience: parseInt(
        getDays(
          experienceList.map((ex) => ({
            startTime: getTimeFromMonthAndYear(ex.startMonth, ex.startYear),
            endTime: getTimeFromMonthAndYear(ex.endMonth, ex.endYear),
          }))
        )
      ),
      lastExperienceTitle:
        experienceList && experienceList.length
          ? experienceList[0].title.name
          : "Fresher",
      lastExperienceCompany: "",
      careerGaps: updatedCareerGaps,
      experience: experienceList,
    });
  }

  async onAddExperience(values) {
    Analytics.logEvent("[C] click add experience");
    this.setState({
      isAdding: true,
      isModalVisible: false,
    });
    try {
      let experienceList = this.state.experienceList;
      if (this.state.mode === "ADD") {
        experienceList = experienceList.concat(values);
      } else {
        experienceList[this.state.editIndex] = values;
      }
      this.updateExperience(experienceList);
      this.setState({
        isModalVisible: false,
        modalProps: {},
      });
    } catch (e) {
      console.log(e);
    }
    this.setState({
      isAdding: false,
    });
  }

  render() {
    let props = this.props;
    return (
      <CenterContainer>
        <Title>{"Experience"}</Title>
        <ItemSummary
          title={displayDuration(
            getDays(
              this.state.experienceList.map((ex) => ({
                startTime: getTimeFromMonthAndYear(ex.startMonth, ex.startYear),
                endTime: getTimeFromMonthAndYear(ex.endMonth, ex.endYear),
              }))
            )
          )}
          description={"Total Work Experience"}
        />

        {this.state.experienceList.map((exp, index) => (
          <ExperienceSummary
            key={index}
            id={exp.id}
            startYear={exp.startYear}
            startMonth={exp.startMonth}
            endYear={exp.endYear}
            endMonth={exp.endMonth}
            jobTitle={exp.title.name}
            companyName={exp.company.name}
            location={exp.location.name}
            history={props.history}
            index={index}
            isFirst={index === 0}
            isLast={index === this.state.experienceList.length - 1}
            updateEdit={(index) => {
              let editable = this.state.experienceList[index];
              this.setState({
                editIndex: index,
                isModalVisible: true,
                mode: "EDIT",
                values: {
                  title: editable.title || [],
                  company: editable.company || [],
                  location: editable.location || [],
                  startMonth: editable.startMonth,
                  startYear: editable.startYear,
                  endMonth: editable.endMonth,
                  endYear: editable.endYear,
                  isCurrent: editable.isCurrent || false,
                  jobFunctions: editable.jobFunctions || [],
                  isIndividualContributor: editable.isManager ? false : true,
                  isManager: editable.isManager ? true : false,
                  numberOfReportees: 0,
                  description: editable.description || "",
                },
                errors: {},
              });
            }}
            onRemove={async (index) => {
              Analytics.logEvent("[C] click remove experience");
              try {
                let experienceList = this.state.experienceList
                  .slice(0, index)
                  .concat(this.state.experienceList.slice(index + 1));
                await this.updateExperience(experienceList);
              } catch (e) {
                console.log("error while deleting");
              }
            }}
          />
        ))}
        <Block marginTop="scale600">
          <AddSection
            text={"Add Experience"}
            onClick={() =>
              this.setState({
                isModalVisible: true,
                mode: "ADD",
                values: {
                  isCurrent: false,
                  jobFunctions: [],
                  isIndividualContributor: false,
                  isManager: false,
                  numberOfReportees: 0,
                },
                errors: {},
              })
            }
          />
        </Block>
        {this.state.careerGaps.length ? (
          <div style={{ marginTop: "32px" }}>
            <Card>
              <Bold style={{ fontSize: "24px" }}>Career gaps</Bold>
              {this.state.careerGaps.map((cg, idx) => (
                <FormControl
                  key={idx}
                  label={
                    <Label
                      appendText={
                        <div>
                          {printMonthYear(cg.startTime) +
                            " - " +
                            printMonthYear(cg.endTime)}
                          <span style={{ fontSize: "12px", color: "#919191" }}>
                            {" (" +
                              getMonthDuration(cg.startTime, cg.endTime) +
                              ")"}
                          </span>
                        </div>
                      }
                      subtitle={"Give a short explanation for this career gap"}
                    />
                  }
                  caption={<div></div>}
                >
                  <Input
                    onChange={(e) => {
                      let careerGaps = this.state.careerGaps;
                      careerGaps[idx].reason = e.target.value;
                      this.setState({
                        careerGaps,
                      });
                    }}
                    value={this.state.careerGaps[idx].reason || ""}
                    autoComplete={"no"}
                  />
                </FormControl>
              ))}
            </Card>
          </div>
        ) : null}
        <Modal
          animate
          autoFocus
          unstable_ModalBackdropScroll={true}
          isOpen={this.state.isModalVisible}
          closeable
          onClose={() => this.toggleConfirm(true)}
        >
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <ModalHeader>
              <Title>
                {this.state.mode === "ADD"
                  ? "Add Experience"
                  : "Edit Experience"}
              </Title>
            </ModalHeader>
            <ModalBody>
              <FormControl
                label={<Label>{"Enter your job title"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["title"]}
              >
                <Select
                  type={"search"}
                  creatable
                  getOptions={async (query) => searchEntity("jobTitle", query)}
                  onChange={(value) => {
                    this.updateFormValues("title", value);
                  }}
                  value={this.state.values["title"]}
                />
              </FormControl>
              <FormControl
                label={<Label>{"Enter your company name"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["company"]}
              >
                <Select
                  creatable
                  type={"search"}
                  getOptions={async (query) => searchEntity("company", query)}
                  onChange={(value) => {
                    this.updateFormValues("company", value);
                  }}
                  value={this.state.values["company"]}
                />
              </FormControl>
              <FormControl
                label={<Label>{"Enter your company location"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["location"]}
              >
                <Select
                  creatable
                  type={"search"}
                  getOptions={async (query) => searchEntity("location", query)}
                  onChange={(value) => {
                    this.updateFormValues("location", value);
                  }}
                  value={this.state.values["location"]}
                />
              </FormControl>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div style={{ maxWidth: "120px" }}>
                  <FormControl
                    label={<Label>{"Start month"}</Label>}
                    error={this.state.errors["startMonth"]}
                  >
                    <Select
                      placeholder={"MM"}
                      clearable={false}
                      searchable={false}
                      defaultOptions={MONTHS}
                      width={"120px"}
                      maxDropdownHeight={"200px"}
                      value={getMonthValue(this.state.values["startMonth"])}
                      onChange={(value) => {
                        this.updateFormValues("startMonth", value.id);
                      }}
                    />
                  </FormControl>
                </div>
                <div style={{ maxWidth: "120px" }}>
                  <FormControl
                    label={<Label>{"Start year"}</Label>}
                    error={this.state.errors["startYear"]}
                  >
                    <Select
                      placeholder={"YYYY"}
                      clearable={false}
                      searchable={false}
                      width={"80px"}
                      defaultOptions={YEARS}
                      maxDropdownHeight={"200px"}
                      value={getYearValue(this.state.values["startYear"])}
                      onChange={(value) => {
                        this.updateFormValues("startYear", value.id);
                      }}
                    />
                  </FormControl>
                </div>
                <div style={{ maxWidth: "120px" }}>
                  {!this.state.values["isCurrent"] ? (
                    <FormControl
                      label={<Label>{"End month"}</Label>}
                      error={this.state.errors["endMonth"]}
                    >
                      <Select
                        placeholder={"MM"}
                        clearable={false}
                        width={"120px"}
                        searchable={false}
                        defaultOptions={MONTHS}
                        maxDropdownHeight={"200px"}
                        value={getMonthValue(this.state.values["endMonth"])}
                        onChange={(value) => {
                          this.updateFormValues("endMonth", value.id);
                        }}
                      />
                    </FormControl>
                  ) : (
                    <div style={{ width: "" }} />
                  )}
                </div>
                <div style={{ maxWidth: "120px" }}>
                  {!this.state.values["isCurrent"] ? (
                    <FormControl
                      label={<Label>{"End year"}</Label>}
                      error={this.state.errors["endYear"]}
                    >
                      <Select
                        placeholder={"YYYY"}
                        clearable={false}
                        width={"80px"}
                        searchable={false}
                        defaultOptions={YEARS}
                        maxDropdownHeight={"200px"}
                        value={getYearValue(this.state.values["endYear"])}
                        onChange={(value) => {
                          this.updateFormValues("endYear", value.id);
                        }}
                      />
                    </FormControl>
                  ) : (
                    <div />
                  )}
                </div>
              </div>
              <FormControl
                caption={<EmptyCaption />}
                error={this.state.errors["isCurrent"]}
              >
                <Checkbox
                  checked={this.state.values["isCurrent"]}
                  onChange={() =>
                    this.updateFormValues(
                      "isCurrent",
                      !this.state.values["isCurrent"]
                    )
                  }
                >
                  This is my current job
                </Checkbox>
              </FormControl>
              <FormControl
                label={
                  <Label subtitle={"Choose 1 or more job functions"}>
                    {"In this role, what were your job functions?"}
                  </Label>
                }
                caption={<EmptyCaption />}
                error={this.state.errors["jobFunctions"]}
              >
                <Select
                  multi
                  getOptions={async (query) =>
                    searchEntity("jobFunction", query)
                  }
                  onChange={(value) => {
                    this.updateFormValues("jobFunctions", value);
                  }}
                  value={this.state.values["jobFunctions"]}
                />
              </FormControl>
              <FormControl
                label={<Label>{"Your were "}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["isManager"]}
              >
                <RadioGroup
                  value={this.state.values["isManager"] ? "1" : "2"}
                  onChange={(e) =>
                    this.updateFormValues("isManager", e.target.value === "1")
                  }
                  align={ALIGN.vertical}
                >
                  <Radio value="1">A Manager</Radio>
                  <Radio value="2">An Individual Contributor</Radio>
                </RadioGroup>
              </FormControl>
              <FormControl label={<Label>{"Describe"}</Label>}>
                <Textarea
                  id={"description"}
                  resize
                  height={"150px"}
                  onChange={(e) => {
                    if (typeof e.target.value !== "undefined") {
                      this.updateFormValues(
                        "description",
                        e.target.value.substring(0, 300)
                      );
                    }
                  }}
                  value={this.state.values["description"]}
                  autoComplete={"off"}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                buttonStyle={{ marginRight: "16px" }}
                type={"button"}
                kind={BUTTON_KIND.minimal}
                onClick={() => this.setState({ isModalVisible: false })}
              >
                Cancel
              </Button>
              <Button type={"submit"} isLoading={this.state.isAdding}>
                {this.state.mode === "ADD"
                  ? "Add Experience"
                  : "Update Experience"}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
        <Modal
          autoFocus={false}
          onClose={() => this.toggleConfirm(false)}
          isOpen={this.state.isConfirmVisible}
        >
          <ModalHeader>Confirm</ModalHeader>
          <ModalBody>Do you want to close this ?</ModalBody>
          <ModalFooter>
            <Button
              type={"button"}
              kind={BUTTON_KIND.minimal}
              onClick={() => {
                this.toggleConfirm(false, () =>
                  this.setState({
                    isModalVisible: false,
                  })
                );
              }}
              buttonStyle={{ marginRight: "16px" }}
            >
              Close
            </Button>
            <Button type={"button"} onClick={() => this.toggleConfirm(false)}>
              Don't close
            </Button>
          </ModalFooter>
        </Modal>
        {/* <Spacing size={32} /> */}
        {/* <Button
          isLoading={this.state.isSaving}
          type={"primary"}
          onClick={async () => {
            this.setState({
              isSaving: true,
            });
            try {
              await this.updateExperience(this.state.experienceList);
              this.setState({
                isSaving: false,
              });
              props.history.push(CANDIDATE_PROFILE_EDUCATION);
            } catch (e) {
              this.setState({
                isSaving: false,
              });
              console.log(e);
            }
          }}
        >
          Save & Continue
        </Button> */}
        <Spacing size={32} />
      </CenterContainer>
    );
  }
}

export default UploadedCandidateExperience;
