import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import { getAllInvoices } from "../api/admin";
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table";
import { DatePicker } from "baseui/datepicker";
import LoadingScreen from "../components/LoadingScreen";
import { Button } from "baseui/button";
import { toaster } from "baseui/toast";
import { prettyPrint } from "../util/time";
import { exportCSVFile } from "../util/csv";

const AdminTranscationsList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [dateRange, setDateRange] = useState([
    new Date(Date.now() - 5 * 24 * 60 * 60 * 1000), // last 5 days
    new Date(),
  ]);
  const [page, setPage] = useState(0);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const processInvoices = (invoices) => {
    return invoices.map((inv) => {
      let lines = inv.lines;
      let mainLines = lines.filter((line) => !line.charge);
      let charges = lines.filter((line) => line.charge);
      let mainAmount = 0;
      const DIR = inv.direction === "OUT" ? -1 : 1;
      for (let line of mainLines) {
        mainAmount += line.amount;
      }
      mainAmount = (DIR * mainAmount) / 100;
      let mainDescription = mainLines[0].description;
      let IGST = 0;
      let SGST = 0;
      let CGST = 0;

      for (let charge of charges) {
        switch (charge.charge.id) {
          case "CGST":
            CGST = (DIR * charge.amount) / 100;
            break;
          case "SGST":
            SGST = (DIR * charge.amount) / 100;
            break;
          case "IGST":
            IGST = (DIR * charge.amount) / 100;
            break;
          default:
            break;
        }
      }

      return {
        id: inv.transactionId,
        createdAt: prettyPrint(inv.createdAt),
        from: inv.fromName,
        fromGST: inv.fromGST,
        to: inv.toName,
        toGST: inv.toGST,
        amount: inv.amount / 100,
        currency: inv.currency,
        receiptId: inv.receiptId,
        mainAmount,
        mainDescription,
        IGST: IGST,
        SGST: SGST,
        CGST: CGST,
      };
    });
  };

  const onLoad = async (currPage) => {
    if (!dateRange[0] || !dateRange[1]) {
      toaster.negative(<div>Please select a date range</div>, {
        autoHideDuration: "3000",
      });
      return;
    }
    setIsLoading(true);
    try {
      let { data: invoices, hasNext } = await getAllInvoices(
        dateRange[0].getTime(),
        dateRange[1].getTime(),
        typeof currPage === "undefined" ? page : currPage,
        10
      );
      setTransactions(processInvoices(invoices));
      setHasNext(hasNext);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNextClick = async () => {
    setPage(page + 1);
    setHasPrev(page + 1 > 0);
    await onLoad(page + 1);
  };

  const onPrevClick = async () => {
    setPage(page - 1);
    setHasPrev(page - 1 > 0);
    await onLoad(page - 1);
  };

  const onDownloadClick = async () => {
    const headers = [
      "Transaction Id",
      "Created At",
      "From",
      "FromGST",
      "To",
      "ToGST",
      "Total",
      "Currency",
      "ReceiptId",
      "Amount",
      "Description",
      "IGST",
      "SGST",
      "CGST",
    ];
    setIsDownloading(true);
    let downloadedInvoices = [];
    let isCompleted = false;
    let startPage = 0;
    while (!isCompleted) {
      try {
        let { data: invoice, hasNext } = await getAllInvoices(
          dateRange[0].getTime(),
          dateRange[1].getTime(),
          startPage,
          10
        );
        downloadedInvoices = downloadedInvoices.concat(invoice);
        isCompleted = !hasNext;
        startPage = startPage + 1;
      } catch (e) {
        isCompleted = true;
        setIsDownloading(false);
        toaster.negative(<div>Error while fetching invoices</div>, {
          autoHideDuration: 3000,
        });
        return;
      }
    }
    setIsDownloading(false);
    exportCSVFile(
      headers,
      processInvoices(downloadedInvoices),
      "fj_invoices " + dateRange[0] + "_" + dateRange[1]
    );
  };

  if (isLoading) return <LoadingScreen />;

  return (
    <div>
      <Title>Invoices List</Title>
      <div style={{ display: "flex", alignItems: "center" }}>
        <DatePicker
          value={dateRange}
          onChange={({ date }) =>
            setDateRange(Array.isArray(date) ? date : [date])
          }
          range
        />
        <Button
          onClick={() => {
            onLoad();
          }}
        >
          Search
        </Button>
        <Button isLoading={isDownloading} onClick={onDownloadClick}>
          Download
        </Button>
      </div>
      <div style={{ overflow: "scroll" }}>
        <div style={{ marginBottom: "32px", width: "2400px" }}>
          <StyledTable
            role="grid"
            $gridTemplateColumns="max-content auto auto auto"
          >
            <StyledHead>
              <StyledHeadCell>Transaction Id</StyledHeadCell>
              <StyledHeadCell>Created At</StyledHeadCell>
              <StyledHeadCell>From</StyledHeadCell>
              <StyledHeadCell>FromGST</StyledHeadCell>
              <StyledHeadCell>To</StyledHeadCell>
              <StyledHeadCell>ToGST</StyledHeadCell>
              <StyledHeadCell>Total</StyledHeadCell>
              <StyledHeadCell>ReceiptId</StyledHeadCell>
              <StyledHeadCell>Description</StyledHeadCell>
              <StyledHeadCell>Amount</StyledHeadCell>
              <StyledHeadCell>IGST</StyledHeadCell>
              <StyledHeadCell>SGST</StyledHeadCell>
              <StyledHeadCell>CGST</StyledHeadCell>
            </StyledHead>
            <StyledBody>
              {transactions.map((transaction, index) => (
                <StyledRow key={index}>
                  <StyledCell>{transaction.id}</StyledCell>
                  <StyledCell>{transaction.createdAt}</StyledCell>
                  <StyledCell>{transaction.from}</StyledCell>
                  <StyledCell>{transaction.fromGST}</StyledCell>
                  <StyledCell>{transaction.to}</StyledCell>
                  <StyledCell>{transaction.toGST}</StyledCell>
                  <StyledCell>{transaction.amount}</StyledCell>
                  <StyledCell>{transaction.receiptId}</StyledCell>
                  <StyledCell>{transaction.mainDescription}</StyledCell>
                  <StyledCell>{transaction.mainAmount}</StyledCell>
                  <StyledCell>{transaction.IGST}</StyledCell>
                  <StyledCell>{transaction.SGST}</StyledCell>
                  <StyledCell>{transaction.CGST}</StyledCell>
                </StyledRow>
              ))}
            </StyledBody>
          </StyledTable>
        </div>
      </div>
      <Pagination
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        hasNext={hasNext}
        hasPrev={hasPrev}
      />
    </div>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminTranscationsList));
