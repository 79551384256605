import { Block } from "baseui/block";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import PageWrapper from "../../components/PageWrapper";
import { SidebarWrapper } from "../../components/SidebarWrapper";
import RCDashboardSearch from "../RCDashboardSearch";
import PageHeader from "../../components/Header";
import { setUser } from "../../redux/actions/user";
import Card from "../../components/Card";
import Title from "../../components/Title";
import { LabelLarge, LabelMedium, ParagraphSmall } from "baseui/typography";
import Button from "../../components/Button";
import ShareCandidateModel from "../ShareCandidateModel";
import { Icon } from "../../components/Icon";

const CandidatesPage = (props) => {
  const history = useHistory();
  return (
    <PageWrapper>
      <SidebarWrapper>
        <Block position="relative">
          <Card
            marginLeft="scale400"
            width={"300px"}
            background={"transparent"}
            disableShadow
          >
            <LabelMedium marginBottom="scale400">Hire for ₹ 2500</LabelMedium>
            <ParagraphSmall marginBottom="scale200">
              Pay only for interviews. Full refund if interview gets cancelled.
            </ParagraphSmall>
            <Block marginBottom="scale800">
              <Block display="flex">
                <Icon name="check" color="green"></Icon>
                <ParagraphSmall marginLeft="scale100">
                  No Success Fee
                </ParagraphSmall>
              </Block>
              <Block display="flex">
                <Icon name="check" color="green"></Icon>
                <ParagraphSmall marginLeft="scale100">
                  Free Job Posting
                </ParagraphSmall>
              </Block>
              <Block display="flex">
                <Icon name="check" color="green"></Icon>
                <ParagraphSmall marginLeft="scale100">
                  Full Refund
                </ParagraphSmall>
              </Block>
            </Block>
            <Button
              onClick={() => {
                history.push("/register");
              }}
            >
              Post Job for Free
            </Button>
          </Card>
        </Block>
      </SidebarWrapper>
      <RCDashboardSearch isEmployer isPublicPage />
      <PageHeader notLoggedIn={!props.user.email} showRegister />
      <ShareCandidateModel />
    </PageWrapper>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(CandidatesPage);
