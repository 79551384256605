import React from "react";
import { withRouter } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import {
  CANDIDATE_PROFILE_BASIC,
  CANDIDATE_PROFILE_EXPECTATION,
  CANDIDATE_PROFILE_EXPERIENCE,
  CANDIDATE_PROFILE_EDUCATION,
  CANDIDATE_PROFILE_PREMIUM,
  CANDIDATE_PROFILE_PROFILE,
  CANDIDATE_PROFILE_PROJECT,
  CANDIDATE_PROFILE_SUMMARY,
  CANDIDATE_PROFILE_NOTICE_PERIOD,
  CANDIDATE_DASHBOARD,
  CANDIDATE_PROFILE_VERIFICATIONS,
} from "../util/routes";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";

class SidebarCandidateProfile extends React.Component {
  constructor(props) {
    super(props);
    this.stages = [
      {
        id: "basic",
        title: "Personal Info",
        link: CANDIDATE_PROFILE_BASIC,
        icon: "info",
      },
      {
        id: "experience",
        title: "Experience",
        link: CANDIDATE_PROFILE_EXPERIENCE,
        icon: "monitor",
      },
      {
        id: "education",
        title: "Education",
        link: CANDIDATE_PROFILE_EDUCATION,
        icon: "book",
      },
      {
        id: "notice-period",
        title: "Notice Period",
        link: CANDIDATE_PROFILE_NOTICE_PERIOD,
        icon: "briefcase",
      },
      {
        id: "profile",
        title: "Profile",
        link: CANDIDATE_PROFILE_PROFILE,
        icon: "star",
      },
      {
        id: "projects",
        title: "Projects",
        link: CANDIDATE_PROFILE_PROJECT,
        icon: "server",
      },
      {
        id: "salary",
        title: "Your Expectation",
        link: CANDIDATE_PROFILE_EXPECTATION,
        icon: "dollar-sign",
      },
      {
        id: "premium",
        title: "Premium",
        link: CANDIDATE_PROFILE_PREMIUM,
        icon: "tag",
      },
    ];
  }

  onPromoteProfileChange() {}

  render() {
    return (
      <div>
        <SidebarItem
          enabled={true}
          title={"Back to home"}
          icon={"arrow-left"}
          onClick={() => {
            this.props.history.push(CANDIDATE_DASHBOARD);
          }}
        />
        {this.stages.map((st, idx) => (
          <SidebarItem
            key={idx}
            title={st.title}
            icon={st.icon}
            selected={st.id === this.props.page}
            enabled={true}
            isCompleted={
              (this.props.user.candidateOnboarding || []).indexOf(st.id) > -1
            }
            onClick={() => this.props.history.push(this.stages[idx].link)}
          ></SidebarItem>
        ))}
        <SidebarItem
          key={"verifications"}
          title={"Verifications"}
          icon={"user-check"}
          isCompleted={
            (this.props.user.candidateOnboarding || []).indexOf(
              "verifications"
            ) > -1
          }
          selected={"verifications" === this.props.page}
          enabled={true}
          onClick={() =>
            this.props.history.push(CANDIDATE_PROFILE_VERIFICATIONS)
          }
        />
        <SidebarItem
          key={"summary"}
          title={"Submit for Review"}
          icon={"columns"}
          isCompleted={!!this.props.user.candidateProfile}
          selected={"summary" === this.props.page}
          enabled={true}
          onClick={() => this.props.history.push(CANDIDATE_PROFILE_SUMMARY)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(SidebarCandidateProfile));
