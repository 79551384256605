import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import { submitTalentScout } from "../api/talentscout";
import Button from "../components/Button";
import { getUser } from "../api/user";
import { toaster } from "baseui/toast";
import { TALENTSCOUT_MY_CANDIDATES } from "../util/routes";
import Title from "../components/Title";
import { Checkbox } from "baseui/checkbox";
import * as Analytics from "../analytics";
import Bold from "../components/Bold";
import { CenterContainer } from "../components/CenterContainer";

class ScoutDetailsReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      scoutAgreement: this.props.user.talentScoutProfile,
      scoutAgreementError: false,
      employer: {},
      isLoading: false,
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[S] page view profile review");
    this.setState({
      isFetching: true,
    });
    try {
    } catch (ex) {
      console.log(ex);
    }
    this.setState({
      isFetching: false,
    });
  }

  goToDashboard = async () => {
    if (!this.state.scoutAgreement) {
      this.setState({
        scoutAgreementError: true,
      });
      return;
    }

    try {
      this.setState({ isLoading: true });
      await submitTalentScout({
        userId: this.props.user.id,
      });
      Analytics.logEvent("[S] submit scout profile", {
        isFirstSubmission: !this.props.user.talentScoutProfile,
      });
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        isLoading: false,
      });
      return;
    }
    let user = await getUser(this.props.user.id);
    let talentscoutOnboarding = this.props.user.talentscoutOnboarding || [];
    if (talentscoutOnboarding.indexOf("review") === -1) {
      talentscoutOnboarding.push("review");
    }
    this.props.setUser({
      talentScoutProfile: user.talentScoutProfile,
      talentscoutOnboarding,
    });
    toaster.positive(<div>Going to dashboard</div>, {
      autoHideDuration: 3000,
      onClose: () => console.log("Toast closed."),
      overrides: {
        InnerContainer: {
          style: { width: "100%" },
        },
      },
    });
    this.props.history.push(TALENTSCOUT_MY_CANDIDATES);
  };

  buttonEnabled = () => {
    return this.state.scoutAgreement;
  };

  render() {
    if (this.state.isFetching) {
      return (
        <CenterContainer>
          <div>loading...</div>
        </CenterContainer>
      );
    }
    return (
      <CenterContainer>
        <Title>Review</Title>
        <div style={{ marginBottom: 16 }}>
          <div>How does the Talent Scout earn ?</div>
          <Bold style={{ fontSize: "16px", fontWeight: "600" }}>
            Scout Wallet Statement
          </Bold>
          <div
            style={{
              alignItems: "flex-end",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", marginBottom: "12px" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right",
                }}
              >
                <Bold>Commission the scout earns</Bold>
                <div style={{ fontSize: "12px" }}>
                  For each interview accepted by your candidate
                </div>
              </div>
              <div style={{ width: "100px", textAlign: "right" }}>A</div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right",
                }}
              >
                <Bold>
                  <span style={{ fontSize: "12px", fontWeight: "400" }}>
                    (Deduction)
                  </span>
                  FindingJo usage fees
                </Bold>
              </div>
              <div style={{ width: "100px", textAlign: "right" }}>B</div>
            </div>
            <div
              style={{
                height: "1px",
                backgroundColor: "#e8e8e8",
                width: "320px",
                marginTop: "8px",
                marginBottom: "8px",
              }}
            ></div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right",
                }}
              >
                <Bold>Net revenue earned by scout</Bold>
                <div style={{ fontSize: "12px" }}>(A) Minus (B)</div>
              </div>
              <div style={{ width: "100px", textAlign: "right" }}>A - B</div>
            </div>
          </div>
          <div>Your can withdraw your wallet balance anytime</div>
          <div style={{ marginBottom: "8px", marginTop: "16px" }}>
            <Checkbox
              checked={this.state.scoutAgreement}
              onChange={() =>
                this.setState({
                  scoutAgreement: !this.state.scoutAgreement,
                  scoutAgreementError: false,
                })
              }
            >
              <div style={{ fontSize: "14px" }}>
                {"I agree to the "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"https://www.findingjo.com/terms"}
                  style={{ color: "#0F4C81", textDecoration: "underline" }}
                >
                  FindingJo terms
                </a>
              </div>
            </Checkbox>

            <div
              style={{
                color: "#F8756C",
                fontSize: "12px",
                opacity: this.state.scoutAgreementError ? 1 : 0,
              }}
            >
              You need to accept the agreement to proceed
            </div>
          </div>
          <Button
            buttonStyle={{ opacity: this.buttonEnabled() ? 1 : 0.3 }}
            onClick={this.goToDashboard}
          >
            Save and Continue
          </Button>
        </div>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(ScoutDetailsReview));
