import { styled, withStyle } from "baseui";
import React from "react";
import {
  displayDuration,
  getTimeFromMonthAndYear,
  prettyPrint,
} from "../util/time";
import Currency from "./Currency";
import {
  getTravelText,
  getStartupReadiness,
  getSortedExperience,
  getLastExperienceTitle,
} from "../util/common";
import Bold from "./Bold";
import Linkify from "react-linkify";
import Card from "./Card";
import { LabelLarge, ParagraphSmall } from "baseui/typography";
import { Block } from "baseui/block";
import { Box, Flex, Tag, Text } from "@chakra-ui/react";

const ResumeRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginBottom: "16px",
});

const ResumeTitle = withStyle(Bold, (props) => ({
  fontSize: "16px",
  color: props.isAnonymous ? "#ffffff" : "#333333",
  marginBottom: "8px",
  marginTop: "16px",
  paddingTop: "16px",
  borderTop: props.isAnonymous ? "1px solid #414141" : "1px solid #f1f1f1",
}));

const ExperienceRowWrapper = styled("div", {
  display: "flex",
  position: "relative",
  flexDirection: "row",
  marginBottom: "16px",
});

const Line = styled("div", {
  width: "1px",
  position: "absolute",
  left: "3px",
  top: "12px",
  bottom: "-24px",
  // background: "#e0dada",
  background:
    "linear-gradient(180deg, #e0dada 0%, rgba(255, 255, 255, 1) 100%)",
});

const CircleWrapper = styled("div", {
  paddingTop: "4px",
  marginTop: "4px",
});

const Circle = styled("div", {
  height: "7px",
  width: "7px",
  background: "#e0dada",
  borderRadius: "4px",
});

const Year = styled("div", {
  marginLeft: "16px",
  color: "#919191",
});

const Duration = styled("div", {
  marginLeft: "16px",
  color: "#919191",
});

const ExperienceDetails = styled("div");

const ProjectRowWrapper = styled("div", {
  marginBottom: "16px",
});

const ProjectTitleRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  marginBottom: "8px",
});

const ProjectCompany = styled("div", (props) => ({
  color: props.isAnonymous ? "#a1a1a1" : "#919191",
}));

const CareerGapShade = styled("div", (props) => ({
  background: props.isAnonymous
    ? "repeating-linear-gradient( 45deg, #6a6a6a, #6a6a6a 10px, #4a4a4a 10px, #4a4a4a 20px)"
    : "repeating-linear-gradient( 45deg, #fafafa, #fafafa 10px, #eaeaea 10px, #eaeaea 20px)",
  marginLeft: "136px",
  paddingLeft: "8px",
  paddingRight: "8px",
}));

const ExperienceRow = (props) => (
  <ExperienceRowWrapper>
    <div style={{ width: "158px", display: "flex" }}>
      {props.isLast ? null : <Line isAnonymous={props.isAnonymous} />}
      <CircleWrapper>
        <Circle isAnonymous={props.isAnonymous} />
      </CircleWrapper>
      <div>
        <Year isAnonymous={props.isAnonymous}>{props.year}</Year>
        <Duration isAnonymous={props.isAnonymous}>{props.duration}</Duration>
      </div>
    </div>
    <ExperienceDetails>
      <div>{props.company}</div>
      <div>{props.jobTitle}</div>
      <Flex>
        {(props.tags || []).filter(Boolean).map(({ name }) => (
          <Tag variant="outline" colorScheme={"gray"} mr={2}>
            {name}
          </Tag>
        ))}
      </Flex>
      <Flex>
        <Text fontStyle={"italic"} fontSize="sm" color="#919191">
          {(props.specializations || [])
            .filter(Boolean)
            .map((sp) => sp.name)
            .join(", ")}
        </Text>
      </Flex>
    </ExperienceDetails>
  </ExperienceRowWrapper>
);

const CareerGap = (props) => (
  <ExperienceRowWrapper>
    {props.isLast ? null : <Line isAnonymous={props.isAnonymous} />}
    <CircleWrapper>
      <Circle isAnonymous={props.isAnonymous} />
    </CircleWrapper>
    <Year isAnonymous={props.isAnonymous}>{""}</Year>
    <CareerGapShade isAnonymous={props.isAnonymous}>
      <div>{props.title}</div>
      <div>{props.reason}</div>
    </CareerGapShade>
  </ExperienceRowWrapper>
);

const ProjectRow = (props) => (
  <ProjectRowWrapper>
    {props.tags.length === 0 ? (
      <ProjectTitleRow>
        <ProjectCompany isAnonymous={props.isAnonymous}>
          {props.jobTitle
            ? props.jobTitle + " | " + props.company
            : props.company}
        </ProjectCompany>
      </ProjectTitleRow>
    ) : null}
    <Flex>
      {props.jobTitle && (props.tags || []).length ? (
        <Text fontWeight={"bold"}>{props.jobTitle + " - "}</Text>
      ) : null}
      {(props.tags || []).filter(Boolean).map(({ name }) => (
        <Tag variant="outline" colorScheme={"gray"} mr={2}>
          {name}
        </Tag>
      ))}
    </Flex>
    {props.children}
  </ProjectRowWrapper>
);

const Entry = (props) => (
  <Block alignItems="flex-start" display="flex">
    {props.isLabelTitle ? (
      <LabelLarge
        width="158px"
        paddingRight="scale400"
        color={props.isAnonymous ? "background" : "primary"}
      >
        {props.label}
      </LabelLarge>
    ) : (
      <ParagraphSmall
        color={props.isAnonymous ? "mono600" : "primary500"}
        width="158px"
        paddingRight="scale400"
      >
        {props.label}
      </ParagraphSmall>
    )}
    <ParagraphSmall
      display="flex"
      flex={1}
      whiteSpace="pre-line"
      color={props.isAnonymous ? "background" : "primary"}
    >
      {props.value}
    </ParagraphSmall>
  </Block>
);

function renderDuration(startYear, startMonth, endYear, endMonth) {
  const startTime = getTimeFromMonthAndYear(startMonth, startYear);
  const endTime = endYear
    ? getTimeFromMonthAndYear(endMonth, endYear)
    : Date.now();
  const days = (endTime - startTime) / (1000 * 60 * 60 * 24);
  let years = parseInt(days / 365);
  let months = parseInt((days % 365) / 30);
  return (
    (years > 0 ? years + " yrs " : "") + (months > 0 ? months + " mos" : "")
  );
}

const getProjectJobTitle = (candidate, refId) => {
  if (!refId) return "";
  const experience = candidate.experience || [];
  for (const exp of experience) {
    if (exp?.id === refId) return exp?.title?.name || "";
  }
  return "";
};
function getExperienceDuration(duration) {
  if (duration) {
    switch (duration) {
      case 1:
        return "< 1 Year";
      case 3:
        return "1-3 Years";
      case 5:
        return "3-5 Years";
      case 10:
        return "5-10 Years";
      case 100:
        return "10+ Years";
      default:
        return "";
    }
  }
}

function getEducationDuration(duration) {
  if (duration) {
    return duration + " years";
  }
}

function renderYear(startYear, endYear, duration) {
  if (duration) {
    return duration;
  }

  let result = "";
  if (startYear === -1) {
    result += "YYYY";
  } else {
    result += startYear;
  }
  result += " - ";
  if (endYear === -1) {
    result += "YYYY";
  } else if (endYear === 0) {
    result += "Current";
  } else {
    result += endYear;
  }
  return result;
}
const Resume = (props) => (
  <div>
    <Card
      style={{
        padding: typeof props.padding === "undefined" ? 24 : 0,
        backgroundColor: props.isAnonymous ? "#333333" : "transparent",
        color: props.isAnonymous ? "#f1f1f1" : "#333333",
        ...(props.hideShadow && { boxShadow: "none" }),
      }}
    >
      {props.user && (
        <div
          style={{ fontSize: "18px", fontWeight: "600", marginBottom: "16px" }}
        >
          {props.user.name || ""}
        </div>
      )}
      <ResumeRow>
        <div>
          {(props.candidate.experience || []).length ? (
            <Entry
              isAnonymous={props.isAnonymous}
              label={
                getLastExperienceTitle(props.candidate.experience) ||
                props.candidate.experience[0].title.name
              }
              title={"true"}
              isLabelTitle
              value={
                props.candidate.experience[0].company?.name ||
                props.candidate.experience[0].company?.anonymousName
              }
            />
          ) : (
            <Entry
              isAnonymous={props.isAnonymous}
              label={"Fresher"}
              title={"true"}
              value={""}
            />
          )}
          {/* <div>
            {props.isAnonymous &&
              (props.candidate.experience || [])
                .map((ex) => ex && ex.company && ex.company.name)
                .filter(unique)
                .filter(Boolean)
                .join(",")}
          </div> */}
        </div>
      </ResumeRow>
      <ResumeTitle isAnonymous={props.isAnonymous}>About</ResumeTitle>
      <ResumeRow>
        <Entry
          isAnonymous={props.isAnonymous}
          label={"Career Summary"}
          value={props.candidate.careerSummary}
        />
      </ResumeRow>
      <ResumeRow>
        <Entry
          isAnonymous={props.isAnonymous}
          label={"Experience"}
          value={displayDuration(props.candidate.totalExperience)}
        />
      </ResumeRow>
      <ResumeRow>
        <Entry
          isAnonymous={props.isAnonymous}
          label={"Desired Salary"}
          value={
            <Currency
              inWords
              currency={props.candidate.expectedCTCCurrency}
              value={props.candidate.expectedCTC}
            />
          }
        />
      </ResumeRow>
      {props.showCurrentCTC ? (
        <ResumeRow>
          <Entry
            isAnonymous={props.isAnonymous}
            label={"Current Salary"}
            value={
              <Currency
                inWords
                currency={props.candidate.totalCTCCurrency}
                value={props.candidate.totalCTC}
              />
            }
          />
        </ResumeRow>
      ) : null}
      <ResumeRow>
        <Entry
          isAnonymous={props.isAnonymous}
          label={"Already Resigned"}
          value={props.candidate.alreadyResigned ? "Yes" : "No"}
        />
      </ResumeRow>
      {props.candidate.alreadyResigned &&
      props.candidate.lastWorkingDate > 0 &&
      props.candidate.lastWorkingDate > Date.now() ? (
        <ResumeRow>
          <Entry
            isAnonymous={props.isAnonymous}
            label={"Last working date"}
            value={prettyPrint(props.candidate.lastWorkingDate, true)}
          />
        </ResumeRow>
      ) : null}
      <ResumeRow>
        {props.candidate.alreadyResigned &&
        props.candidate.lastWorkingDate &&
        props.candidate.lastWorkingDate > 0 &&
        props.candidate.lastWorkingDate < Date.now() ? (
          <Entry
            isAnonymous={props.isAnonymous}
            label={"Notice Period"}
            value={"0 weeks - Immediate joinee"}
          />
        ) : null}
      </ResumeRow>
      {!props.candidate.alreadyResigned ? (
        <Entry
          isAnonymous={props.isAnonymous}
          label={"Notice Period"}
          value={
            props.candidate.noticePeriod === 0
              ? props.candidate.noticePeriod + " weeks - Immediate joinee"
              : props.candidate.noticePeriod + " weeks"
          }
        />
      ) : null}
      {props.candidate.premium &&
      props.candidate.premium.existingOffers &&
      props.candidate.premium.existingOffers.length ? (
        <ResumeRow>
          <Entry
            isAnonymous={props.isAnonymous}
            label={"Another offer"}
            value={
              <Flex alignItems={"center"}>
                <Currency
                  inWords
                  currency={props.candidate.premium.existingOffers[0].currency}
                  value={props.candidate.premium.existingOffers[0].ctc}
                />
                <Box marginLeft={3}>
                  {
                    props.candidate.premium.existingOffers[0].company
                      ?.anonymousName
                  }
                </Box>
              </Flex>
            }
          />
        </ResumeRow>
      ) : null}

      <ResumeRow>
        <Entry
          isAnonymous={props.isAnonymous}
          label={"Skills"}
          value={
            <div>
              {(props.candidate.skills || [])
                .filter(Boolean)
                .map((sk, index) => sk.name)
                .join(", ")}
            </div>
          }
        />
      </ResumeRow>
      {props.candidate.premium &&
      props.candidate.premium.workShowcases &&
      props.candidate.premium.workShowcases.length ? (
        <ResumeRow>
          <Entry
            isAnonymous={props.isAnonymous}
            label={"Work Samples"}
            value={
              props.isAnonymous ? (
                <div>
                  {props.candidate.premium.workShowcases.length +
                    " work sample links available"}
                </div>
              ) : (
                <Linkify>
                  <div>
                    {props.candidate.premium.workShowcases
                      .filter(Boolean)
                      .map((showcase, idx) => (
                        <div key={idx} style={{ wordBreak: "break-word" }}>
                          {showcase.url}
                        </div>
                      ))}
                  </div>
                </Linkify>
              )
            }
          />
        </ResumeRow>
      ) : null}
      <ResumeTitle isAnonymous={props.isAnonymous}>Work Experience</ResumeTitle>
      {getSortedExperience(props.candidate.experience)
        .filter(Boolean)
        .map((ex, idx) => (
          <React.Fragment key={idx}>
            <ExperienceRow
              isAnonymous={props.isAnonymous}
              key={idx}
              year={renderYear(
                ex.startYear,
                ex.endYear,
                getExperienceDuration(ex.duration)
              )}
              duration={renderDuration(
                ex.startYear,
                ex.startMonth,
                ex.endYear,
                ex.endMonth
              )}
              tags={ex.company?.tags}
              company={ex.company?.name || ex.company?.anonymousName}
              jobTitle={ex.title?.name}
              isLast={idx === props.candidate.experience.length - 1}
            />
            {(props.candidate.careerGaps || [])
              .filter(Boolean)
              .filter((cg) => cg.position - 1 === idx)
              .map((cg, idx) => (
                <CareerGap
                  isAnonymous={props.isAnonymous}
                  key={idx}
                  title={"Career Gap - " + cg.duration + " months"}
                  reason={cg.reason}
                />
              ))}
          </React.Fragment>
        ))}
      <ResumeTitle isAnonymous={props.isAnonymous}>Education</ResumeTitle>
      {props.candidate.education?.filter(Boolean).map((ex, idx) => (
        <ExperienceRow
          isAnonymous={props.isAnonymous}
          key={idx}
          year={renderYear(
            ex.startYear,
            ex.endYear,
            getEducationDuration(ex.duration)
          )}
          duration={renderDuration(
            ex.startYear,
            ex.startMonth,
            ex.endYear,
            ex.endMonth
          )}
          company={ex.college.name || ex.college.anonymousName}
          tags={ex.college?.tags}
          specializations={ex.specializations || []}
          jobTitle={ex.course && ex.course.name}
          isLast={idx === props.candidate.education.length - 1}
        />
      ))}
      <ResumeTitle isAnonymous={props.isAnonymous}>Expectations</ResumeTitle>
      <ResumeRow>
        <Entry
          isAnonymous={props.isAnonymous}
          label={"Remote work"}
          value={props.candidate.isRemoteReady === 0 ? "No" : "Yes"}
        />
      </ResumeRow>
      <ResumeRow>
        <Entry
          isAnonymous={props.isAnonymous}
          label={"Startups"}
          value={getStartupReadiness(props.candidate.startupTypes || [])}
        />
      </ResumeRow>
      <ResumeRow>
        <Entry
          isAnonymous={props.isAnonymous}
          label={"Ready to travel"}
          value={getTravelText(props.candidate.isTravelReady)}
        />
      </ResumeRow>
      <ResumeRow>
        <Entry
          isAnonymous={props.isAnonymous}
          label={"6 day weeks"}
          value={props.candidate.isSixDaysWilling === 0 ? "No" : "Yes"}
        />
      </ResumeRow>
      <ResumeRow>
        <Entry
          isAnonymous={props.isAnonymous}
          label={"About new role"}
          value={props.candidate.idealNextJob}
        />
      </ResumeRow>
      {(props.candidate.projects || []).length ? (
        <ResumeTitle isAnonymous={props.isAnonymous}>
          Top 3 Achievements
        </ResumeTitle>
      ) : null}
      {(props.candidate.projects || []).map((pr, idx) => (
        <ProjectRow
          isAnonymous={props.isAnonymous}
          key={idx}
          jobTitle={getProjectJobTitle(props.candidate, pr.refId)}
          company={pr.company.name || pr.company.anonymousName}
          tags={pr.company.tags || []}
        >
          <div>
            <div style={{ whiteSpace: "pre-line" }}>{pr.briefIntro}</div>
            {pr.role && pr.role.length > 0 && (
              <div style={{ whiteSpace: "pre-line" }}>{pr.role}</div>
            )}
            {pr.outcome && pr.outcome.length > 0 && (
              <div style={{ whiteSpace: "pre-line" }}>{pr.outcome}</div>
            )}
          </div>
        </ProjectRow>
      ))}
      {props.candidate.premium &&
      props.candidate.premium.patents &&
      props.candidate.premium.patents.length ? (
        <div>
          <ResumeTitle isAnonymous={props.isAnonymous}>Patents</ResumeTitle>
          {props.isAnonymous ? (
            <div>
              {props.candidate.premium.patents.length + " patents available"}
            </div>
          ) : (
            props.candidate.premium.patents
              .filter(Boolean)
              .map((pt, idx) => <div key={idx}>{pt.title}</div>)
          )}
        </div>
      ) : null}
      {props.candidate.premium &&
      props.candidate.premium.articles &&
      props.candidate.premium.articles.length ? (
        <div>
          <ResumeTitle isAnonymous={props.isAnonymous}>Articles</ResumeTitle>
          {props.isAnonymous ? (
            <div>
              {props.candidate.premium.articles.length + " articles available"}
            </div>
          ) : (
            props.candidate.premium.articles.filter(Boolean).map((ar, idx) => (
              <Linkify>
                <div key={idx}>{ar}</div>
              </Linkify>
            ))
          )}
        </div>
      ) : null}
      {props.candidate.premium &&
      props.candidate.premium.certifications &&
      props.candidate.premium.certifications.length ? (
        <div>
          <ResumeTitle isAnonymous={props.isAnonymous}>
            Certifications
          </ResumeTitle>
          {props.isAnonymous ? (
            <div>
              {props.candidate.premium.certifications.length +
                " certifications available"}
            </div>
          ) : (
            props.candidate.premium.certifications
              .filter(Boolean)
              .map((cert, idx) => (
                <div key={idx}>
                  <div
                    style={{
                      marginRight: "16px",
                    }}
                  >
                    {cert.name}
                  </div>
                  <div style={{ marginRight: "16px" }}>{cert.description}</div>
                  <Linkify>
                    <div>{cert.link}</div>
                  </Linkify>
                </div>
              ))
          )}
        </div>
      ) : null}
      {props.candidate.premium &&
      props.candidate.premium.linkedInReferences &&
      props.candidate.premium.linkedInReferences.length ? (
        <div>
          <ResumeTitle isAnonymous={props.isAnonymous}>References</ResumeTitle>
          {props.isAnonymous ? (
            <div>
              {props.candidate.premium.linkedInReferences.length +
                " linkedin references available"}
            </div>
          ) : (
            props.candidate.premium.linkedInReferences.map((refer, idx) => (
              <Linkify>
                <div key={idx}>{refer}</div>
              </Linkify>
            ))
          )}
        </div>
      ) : null}
    </Card>
  </div>
);

export default Resume;
