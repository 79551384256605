import React from "react";
import {
  useColorModeValue,
  Flex,
  Icon,
  As,
  Box,
  Drawer,
  DrawerContent,
  BoxProps,
  DrawerOverlay,
} from "@chakra-ui/react";
import logo from "../../icons/logo.svg";

export type SidebarNavItem = {
  icon: As;
  children: React.ReactNode;
  isSelected?: boolean;
  isChild?: boolean;
  onClick?: () => void;
};

export const SidebarNavItem = ({
  icon,
  isSelected = false,
  children,
  isChild,
  onClick,
  ...rest
}: SidebarNavItem) => {
  const iconColor = useColorModeValue("gray.600", "gray.300");
  const isSelectedColor = useColorModeValue("blue.500", "gray.400");
  return (
    <Flex
      align="center"
      px="2"
      py={"2"}
      mx={"2"}
      cursor="pointer"
      background={isSelected ? "gray.100" : "inherit"}
      borderRadius={"md"}
      // color={isSelected ? isSelectedColor : "inherit"}
      // textDecoration={isSelected ? "underline" : "inherit"}
      _hover={{
        bg: useColorModeValue("gray.50", "gray.900"),
        textDecoration: "underline",
        // color: useColorModeValue("gray.900", "gray.200"),
      }}
      role="group"
      // fontWeight={isSelected ? "semibold" : "regular"}
      transition=".15s ease"
      onClick={onClick}
      {...rest}
    >
      {icon && (
        <Icon
          mx="2"
          mr="3"
          boxSize="4"
          opacity={isChild ? 0 : 1}
          _groupHover={{
            color: isSelected ? isSelectedColor : iconColor,
          }}
          color={isSelected ? isSelectedColor : iconColor}
          as={icon}
        />
      )}
      {children}
    </Flex>
  );
};

const SidebarContent = ({
  children,
  ...rest
}: {
  children: React.ReactNode;
} & BoxProps) => (
  <Box
    as="nav"
    pos="fixed"
    top="0"
    left="0"
    h="full"
    pb="10"
    overflowX="hidden"
    overflowY="auto"
    borderColor={useColorModeValue("gray.100", "gray.700")}
    borderRightWidth="1px"
    // bg="blackAlpha.50"
    w="60"
    {...rest}
  >
    <Flex px="5" py="1" align="center" mb="5">
      <img src={logo} alt="FindingJo" height="30" width="120" />
    </Flex>
    <Flex
      direction="column"
      as="nav"
      fontSize="sm"
      aria-label="Side Navigation"
    >
      {children}
    </Flex>
  </Box>
);

export const Sidebar = ({
  children,
  isOpen,
  onClose,
  ...rest
}: {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <>
      <SidebarContent display={{ base: "none", md: "unset" }} {...rest}>
        {children}
      </SidebarContent>
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent>
          <SidebarContent w="full" borderRight="none" {...rest}>
            {children}
          </SidebarContent>
        </DrawerContent>
      </Drawer>
    </>
  );
};
