import { styled } from "baseui";
import { Block } from "baseui/block";

const Card = styled(
  Block,
  ({ $theme, isClickable, isSelected, background, disableShadow }) => ({
    overflow: "hidden",
    ...(isSelected && {
      border: "1px solid",
      borderColor: $theme.colors.accent,
    }),
    borderRadius: $theme.borders.radius200,
    padding: $theme.sizing.scale800,
    cursor: isClickable ? "pointer" : "default",
    // boxShadow: "0 0 0 1px rgba(63,63,68,.05),0 1px 3px 0 rgba(63,63,68,.15)",
    boxShadow: disableShadow
      ? "none"
      : "0 -1px 5px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 10%), 0 10px 15px #f3ece8",
    // "0 -1px 10px rgb(0 0 0 / 5%), 0 1px 4px rgb(0 0 0 / 10%), 0 10px 30px #f3ece8",
    ":hover": isClickable && {
      boxShadow: $theme.lighting.shadow400,
    },
    background: background || "#FFFFFF",
    transition: "all .2s linear",
  })
);

export default Card;
