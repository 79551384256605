import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "../Button";
import { LabelMedium, ParagraphSmall } from "baseui/typography";
import { Block } from "baseui/block";
import { Icon } from "../Icon";
import Card from "../Card";

const JobSection = ({ job, history }) => {
  const { id, company, jobTitle } = job;
  return (
    <Card marginLeft="scale600" marginBottom="scale600">
      <Block marginBottom="scale600" maxWidth={"280px"}>
        <LabelMedium>{jobTitle.name}</LabelMedium>
        <ParagraphSmall>{company.name}</ParagraphSmall>
      </Block>
      <Button
        kind={"tertiary"}
        size={"mini"}
        startEnhancer={<Icon name="edit" size={16} />}
        onClick={() => history.push("/employer/postedjob/" + id + "/details")}
      >
        Edit Job
      </Button>
    </Card>
  );
};

export default connect(
  (state) => ({
    user: state.user,
    employer: state.employer,
    workspace: state.workspace,
    workspaceMember: state.workspaceMember,
  }),
  {}
)(withRouter(JobSection));
