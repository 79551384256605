import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  FormControl,
  FormLabel,
  Select,
  RadioGroup,
  Stack,
  Radio,
  Input,
  useToast,
  Spacer,
  Center,
  Text,
} from "@chakra-ui/react";
import { getJobsByWorkspace } from "src/api/job";
import { useSelector } from "react-redux";
import {
  getFunnelPositions,
  moveCandidatesToAnotherFunnel,
} from "src/api/funnel";
import { Job } from "src/screens/talentscout/UploadCandidate";

export type AddCandidatesToJobModalProps = {
  isOpen: boolean;
  onClose: () => void;
  candidates: { userId: string }[];
  jobId: string;
};

export const AddCandidatesToJobModal = ({
  isOpen,
  onClose,
  candidates,
  jobId,
}: AddCandidatesToJobModalProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [lanes, setLanes] = useState<{ id: string; name: string }[]>([]);
  const workspace = useSelector((state: any) => state.workspace);
  const [selectedJobId, setSelectedJobId] = useState<string>("");
  const [selectedLane, setSelectedLane] = useState<string>("");
  const [newLaneName, setNewLaneName] = useState("");
  const [stageMode, setStageMode] = useState<string>("select");
  const [isAdding, setIsAdding] = useState(false);
  const toast = useToast();
  const fetchJobs = useCallback(async () => {
    if (!workspace) return;
    setIsFetching(true);
    let { data: jobs } = (await getJobsByWorkspace(workspace.id, 0, 100)) || {};
    setJobs(
      jobs.filter(
        (job: { id: string; status: string }) =>
          job.id !== jobId && job.status !== "CLOSED"
      )
    );
    if (jobs.length > 0) {
      setSelectedJobId(jobs[0].id);
    }
    setIsFetching(false);
  }, [workspace, jobId]);

  const fetchLanes = useCallback(async () => {
    let positions = (await getFunnelPositions(selectedJobId)) || {};
    const lanes = positions.map((p: { laneId: string; laneName: string }) => ({
      id: p.laneId,
      name: p.laneName,
    }));
    setLanes(lanes);
    if (lanes.length > 0) {
      setSelectedLane(lanes[0].id);
    }
  }, [selectedJobId]);

  useEffect(() => {
    if (selectedJobId) {
      fetchLanes();
    }
  }, [fetchLanes, selectedJobId]);

  useEffect(() => {
    if (isOpen) {
      fetchJobs();
    }
  }, [isOpen, fetchJobs]);

  const onAddClick = async () => {
    setIsAdding(true);
    try {
      let result = await moveCandidatesToAnotherFunnel(selectedJobId, {
        jobId: selectedJobId,
        candidateIds: candidates.map((c) => c.userId),
        newLaneName: stageMode === "create" ? newLaneName : "",
        laneId: stageMode === "select" ? selectedLane : "",
      });

      const success = result.filter((res: any) => res.status).length;
      const failed = result.filter((res: any) => !res.status).length;
      if (success === 0) {
        toast({
          title: `Candidate(s) were not added since they already exists in that job tracker`,
        });
      } else if (failed > 0) {
        toast({
          title: `${success} candidate(s) got added. ${failed} candidate(s) not added since they already exists in that job tracker`,
        });
      } else {
        toast({ title: "Candidates are added successfully" });
        onClose();
      }
    } catch (e) {}
    setIsAdding(false);
  };

  const renderForm = () => {
    return jobs.length === 0 ? (
      <Box height="200px">
        <Box background="orange.50" p={4}>
          <Text fontWeight={"medium"} fontSize="xl">
            No other active job description available
          </Text>
          <Text>
            Post more jobs. Then add candidates from one JD to another
          </Text>
        </Box>
      </Box>
    ) : (
      <Box>
        <Box>
          <FormControl>
            <FormLabel>Select job to add the candidates</FormLabel>
            <Select
              onChange={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                setSelectedJobId(e.target.value);
              }}
            >
              {jobs.map((job) => (
                <option value={job.id}>{job.jobTitle?.name}</option>
              ))}
            </Select>
          </FormControl>
          <Spacer height={8} />
          <RadioGroup onChange={setStageMode} value={stageMode}>
            <Stack spacing={2} direction="column">
              <Radio value="select" fontWeight={"medium"}>
                Select existing stage
              </Radio>
              {stageMode === "select" ? (
                <FormControl>
                  <Select
                    onChange={(e: { target: { value: string } }) => {
                      setSelectedLane(e.target.value);
                    }}
                  >
                    {lanes.map((lane) => (
                      <option value={lane.id}>{lane.name}</option>
                    ))}
                  </Select>
                </FormControl>
              ) : null}

              <Spacer height={4} />
              <Radio value="create" fontWeight={"medium"}>
                Create a new stage
              </Radio>
              {stageMode === "create" ? (
                <FormControl>
                  <Input
                    onChange={(e: { target: { value: string } }) => {
                      setNewLaneName(e.target.value);
                    }}
                    value={newLaneName}
                  ></Input>
                </FormControl>
              ) : null}
            </Stack>
          </RadioGroup>
          <Spacer height={2} />
        </Box>
      </Box>
    );
  };

  return (
    <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Add ${candidates.length} ${
          candidates.length > 1 ? "candiates" : "candiate"
        } to job`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isFetching ? (
            <Center height="200px">
              <Spinner />
            </Center>
          ) : (
            renderForm()
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          {jobs.length > 0 ? (
            <Button
              isLoading={isAdding}
              onClick={onAddClick}
              colorScheme="orange"
            >
              Add candidates
            </Button>
          ) : null}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
