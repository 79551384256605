import React from "react";
import Title from "../components/Title";
import { FormControl } from "baseui/form-control";
import Input from "../components/Input";
import Label from "../components/Label";
import EmptyCaption from "../components/EmptyCaption";
import { updateUserUniqueId } from "../api/user";
import Modal from "../components/Modal";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import Button from "../components/Button";
import FileUploader from "./FileUploader";

class UniqueIdModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      modalVisible: props.modalVisible,
      idProofId: "",
      idProofImageUrl: "",
      idProofName: "",
      idProofError: "",
      idProofImageError: "",
      isIdProofAlternate: false,
    };
    this.fileUploaderRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.modalVisible !== this.props.modalVisible) {
      this.setState({
        isUploading: false,
        modalVisible: this.props.modalVisible,
        idProofId: "",
        idProofName: "",
        idProofError: "",
        isAlternate: false,
      });
    }
  }

  async updateUniqueId() {
    if (!this.state.idProofId) {
      this.setState({
        idProofError:
          "Please enter your " +
          (this.props.user.config && this.props.user.config.idProofName),
      });
      return;
    }
    this.setState({
      isUploading: false,
    });
    try {
      await updateUserUniqueId({
        name:
          this.state.idProofName ||
          (this.props.user.config && this.props.user.config.idProofName),
        id: this.state.idProofId,
        photoUrl: this.state.idProofImageUrl,
        isAlternate: this.state.isIdProofAlternate,
      });

      this.props.setUser({
        idProof: {
          name:
            this.state.idProofName ||
            (this.props.user.config && this.props.user.config.idProofName),
          id: this.state.idProofId,
          photoUrl: this.state.idProofImageUrl,
          isAlternate: this.state.isIdProofAlternate,
          isVerified: false,
        },
      });

      this.setState({
        isUploading: false,
        idProofError: "",
      });
      this.props.onSubmit && this.props.onSubmit();
      this.props.onClose && this.props.onClose();
    } catch (e) {
      console.log(e);
    }
    this.setState({
      isUploading: false,
    });
  }

  handleSubmit = async () => {
    await this.updateUniqueId();
  };

  render() {
    return (
      <Modal
        animate
        autoFocus
        isOpen={this.state.modalVisible}
        closeable
        onClose={() => {
          this.setState({
            modalVisible: false,
            idProofId: "",
            idProofName: "",
            idProofError: "",
            isAlternate: false,
          });
          this.props.onClose();
        }}
      >
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            this.fileUploaderRef.current.upload();
          }}
        >
          <ModalHeader>
            <Title>{"ID Proof"}</Title>
          </ModalHeader>
          <ModalBody>
            {this.state.isIdProofAlternate ? (
              <div>
                <FormControl
                  width={250}
                  height={50}
                  label={<Label>{"Enter your alternate id proof type"}</Label>}
                  caption={<EmptyCaption />}
                  error={this.state.idProofError}
                >
                  <Input
                    onChange={(e) =>
                      this.setState({
                        idProofName: e.target.value,
                      })
                    }
                    value={this.state.idProofName}
                    autoComplete={"off"}
                  />
                </FormControl>
              </div>
            ) : null}
            <FormControl
              label={
                <Label
                  subtitle={
                    "You will be required to carry your id proof when you go for the interview. You will not be able to attend interviews without this id proof"
                  }
                >
                  {this.state.isIdProofAlternate
                    ? "Enter your alternate id proof id"
                    : "Enter your " + this.props.user.config.idProofName}
                </Label>
              }
              caption={
                <div>
                  Identity verification is important to comply with laws and
                  prevent fraud, so you know people are who they say they are.
                  We remove your id proof details from our systems after the
                  verification.
                </div>
              }
              error={this.state.idProofError}
            >
              <Input
                onChange={(e) =>
                  this.setState({
                    idProofId: e.target.value,
                  })
                }
                value={this.state.idProofId}
                autoComplete={"off"}
              />
            </FormControl>
            <FormControl
              label={
                <Label subtitle={"Max 2 mb size"}>
                  {"Picture of your " + this.props.user.config.idProofName}
                </Label>
              }
              caption={<div>Required</div>}
              error={this.state.idProofImageError}
            >
              <FileUploader
                ref={this.fileUploaderRef}
                uploadUrl={"id_proofs/" + this.props.user.id + "_" + Date.now()}
                onError={(error) => {
                  this.setState({
                    idProofImageError: error,
                  });
                }}
                onSuccess={async (downloadUrl) => {
                  this.setState(
                    {
                      idProofImageUrl: downloadUrl,
                    },
                    async () => {
                      await this.handleSubmit();
                    }
                  );
                }}
              />
            </FormControl>
            {/* <FormControl label={<Label>{""}</Label>}>
              <Checkbox
                checked={this.state.isIdProofAlternate}
                onChange={(e) => {
                  this.setState({
                    isIdProofAlternate: e.target.checked,
                  });
                  if (e.target.checked) {
                    this.setState({
                      idProofName: "",
                    });
                  }
                }}
              >
                {"I dont have a " + this.props.user.config.idProofName}
              </Checkbox>
            </FormControl> */}
          </ModalBody>
          <ModalFooter>
            <Button isLoading={this.state.isUploading}>Update</Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

export default UniqueIdModalForm;
