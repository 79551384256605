import { Box, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Education } from "src/lib/types/candidate";
import { EducationSection } from "./EducationSection";

export type EducationRow = {
  education: Education;
  isNew?: boolean;
};

export type EducationListProps = {
  education: Education[];
  onAdd: () => void;
  onSave: (ed: Education[]) => void;
  shouldValidate: boolean;
};

const edToRow = (ed: Education[]) => (ed || []).map((e) => ({ education: e }));

export const EducationList = ({
  education: saved,
  onSave,
  onAdd,
  shouldValidate,
}: EducationListProps) => {
  const [educationRows, setEducationRows] = useState(edToRow(saved || []));

  useEffect(() => {
    setEducationRows(edToRow(saved));
  }, [saved]);

  return (
    <Box>
      {educationRows.map((row, idx) => {
        return (
          <EducationSection
            shouldValidate={shouldValidate}
            key={idx}
            educationRow={row}
            onDiscard={() => {
              const updatedRows = educationRows
                .slice(0, idx)
                .concat(educationRows.slice(idx + 1));
              setEducationRows(updatedRows);
            }}
            onDelete={async () => {
              const updatedRows = educationRows
                .slice(0, idx)
                .concat(educationRows.slice(idx + 1));
              await onSave(updatedRows.map(({ education }) => education));
              setEducationRows(updatedRows);
            }}
            onSave={async (ed) => {
              const updatedRows = educationRows
                .slice(0, idx)
                .concat({ education: ed })
                .concat(educationRows.slice(idx + 1));
              await onSave(updatedRows.map(({ education }) => education));
              setEducationRows(updatedRows);
            }}
          />
        );
      })}
      <Button
        leftIcon={<FaPlus />}
        variant={"ghost"}
        colorScheme={"blue"}
        size="sm"
        onClick={() => {
          const newEx: EducationRow = {
            education: {},
            isNew: true,
          } as EducationRow;
          setEducationRows(educationRows.concat([newEx]));
        }}
      >
        Add education
      </Button>
    </Box>
  );
};
