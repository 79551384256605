import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import Title from "../../../components/Title";
import { logEvent } from "../../../analytics/index";
import {
  removeCandidateFunnelItem,
  removePickedCandidate as removePickedCandidateAdmin,
} from "../../../api/admin";
import LoadingScreen from "../../../components/LoadingScreen";
import { LabelSmall, ParagraphSmall } from "baseui/typography";
import { displayDuration, relativeTime } from "../../../util/time";
import { Container, Draggable } from "react-smooth-dnd";
import { getExperienceDuration } from "../../../util/time";
import { AddFunnelList } from "../../../components/AddFunnelList";
import {
  createCandidateFunnelLane,
  deleteCandidateFunnelLane,
  getCandidateFunnel,
  renameCandidateFunnelLane,
  updateCandidateFunnelItem,
  updateCandidateFunnelLanes,
  updateCandidateFunnelPosition,
  updateCandidateFunnelPositions,
} from "../../../api/funnel";
import { Box, Divider, Flex, Heading, Text } from "@chakra-ui/layout";
import { SelectButton } from "../../../lib/components/SelectButton";
import { FunnelHeader } from "../../../lib/views/FunnelHeader";
import { CandidateFunnelModal } from "../../../lib/views/CandidateFunnelModal";
import Currency from "../../../components/Currency";
import { Tag } from "@chakra-ui/tag";
import { CandidateLabels } from "../../../lib/views/CandidateLabels";
import { FunnelLaneHeader } from "../../../lib/views/FunnelLaneHeader";
import ScrollToTop from "../../../components/ScrollToTop";
import { Button, IconButton } from "@chakra-ui/button";
import { DeleteIcon } from "@chakra-ui/icons";
import {
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { Portal } from "@chakra-ui/portal";
import { useToast } from "@chakra-ui/toast";
import { FiSend } from "react-icons/fi";
import { CandidateTags } from "src/components/employer/CandidateTags";
import { CandidateFunnelBoard } from "./CandidateFunnelBoard";
import { getLastExperienceTitle, unique } from "src/util/common";
import { reorderCandidateMap } from "src/util/board";
import { appUrl } from "src/api/endpoints";

export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const generateItems = (count, creator) => {
  const result = [];
  for (let i = 0; i < count; i++) {
    result.push(creator(i));
  }
  return result;
};

const lorem = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`;

const columnNames = ["Handpicked", "Shortlisted", "Interview"];

const cardColors = [
  "azure",
  "beige",
  "bisque",
  "blanchedalmond",
  "burlywood",
  "cornsilk",
  "gainsboro",
  "ghostwhite",
  "ivory",
  "khaki",
];

const pickColor = () => {
  let rand = Math.floor(Math.random() * 10);
  return cardColors[rand];
};

const getCardBackground = (interviewRequest) => {
  if (!interviewRequest) {
    return "white";
  }
  switch (interviewRequest.status) {
    case "CREATED":
    case "REVIEWED":
      return "orange.50";
    case "APPROVED":
    case "ACCEPTED":
      return "green.50";
    case "ONHOLD":
    case "REJECTED":
      return "red.50";
  }
  return "white";
};

const getStatusTagColorScheme = (status) => {
  switch (status) {
    case "CREATED":
    case "REVIEWED":
      return "orange";
    case "APPROVED":
    case "ACCEPTED":
      return "green";
    case "ONHOLD":
    case "REJECTED":
      return "red";
  }
  return "gray";
};

const CandidateFunnel = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  // const [candidates, setCandidates] = useState([]);
  const [lanes, setLanes] = useState([]);
  const [funnelItems, setFunnelItems] = useState([]);
  const [positions, setPositions] = useState([]);
  const [positionsMap, setPositionsMap] = useState({});
  const [handPickedMap, setHandPickedMap] = useState({});
  const [acceptedCandidates, setAcceptedCandidates] = useState({});
  const [interviewRequests, setInterviewRequests] = useState({});
  const [page, setPage] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [hiddenLanes, setHiddenLanes] = useState([]);
  const [hiddenLabels, setHiddenLabels] = useState([]);
  const toast = useToast();
  const [lastSelectedItemId, setLastSelectedItemId] = useState("");
  const onLabelsChange = async (itemId, labels) => {
    console.log({ candidateModalProps, onLabelsChange: true });
    await updateCandidateFunnelItem(jobId, itemId, { id: itemId, labels });
    await onLoad({ isSilent: true, modalItemId: itemId });
  };

  const getLabels = () => {
    let labels = [];
    for (let item of funnelItems) {
      for (let l of item.labels) {
        if (labels.indexOf(l) < 0) {
          labels = labels.concat(l);
        }
      }
    }
    return labels;
  };

  const onCandidateModalClose = () => {
    setCandidateModalProps({
      ...candidateModalProps,
      isModalOpen: false,
    });
  };

  const [candidateModalProps, setCandidateModalProps] = useState({
    isModalOpen: false,
    title: "",
    funnelItemId: "",
    labels: [],
    onModalClose: onCandidateModalClose,
    onLabelsChange,
    activities: [],
  });

  const { jobId } = props;
  const limit = 10;

  const getPositionsMap = (funnelItems, positions) => {
    const funnelItemsMap = funnelItems.reduce((result, item) => {
      result[item.id] = item;
      return result;
    }, {});
    return positions.reduce((result, item) => {
      result[item.laneId] = item.laneItems
        .filter(unique)
        .map((laneItem) => {
          if (funnelItemsMap[laneItem]?.laneId === item.laneId)
            return funnelItemsMap[laneItem];
        })
        .filter(Boolean);
      return result;
    }, {});
  };

  // const getCandidateModalProps = () => {
  //   return candidateModalProps;
  // };

  const onLaneChange = async (itemId, fromLaneId, toLaneId, toPosition) => {
    const fromPosition = positionsMap[fromLaneId]
      .map((p) => p.id)
      .indexOf(itemId);
    const data = reorderCandidateMap({
      candidateMap: positionsMap,
      source: { index: fromPosition, droppableId: fromLaneId },
      destination: { index: toPosition, droppableId: toLaneId },
    });
    setPositionsMap(data.candidateMap);
    try {
      setLastSelectedItemId(itemId);
      await updateCandidateFunnelPositions(jobId, itemId, {
        jobId,
        itemId,
        toLaneId,
        fromLaneId,
        toPositions: data.candidateMap[toLaneId].map((t) => t.id),
        fromPositions: data.candidateMap[fromLaneId].map((t) => t.id),
        toLaneName: positions.find((p) => p.laneId === toLaneId).laneName,
        fromLaneName: positions.find((p) => p.laneId === fromLaneId).laneName,
      });
    } catch (e) {}
    await onLoad({ isSilent: true, modalItemId: itemId });
  };

  // const moveBetweenColumn = async (
  //   itemId,
  //   fromLaneId,
  //   toLaneId,
  //   toPosition
  // ) => {
  //   if (toPosition < 0) toPosition = 0;
  //   let itemIdx = funnelItems.findIndex((ca) => ca.id === itemId);
  //   let newFunnelItem = { ...funnelItems[itemIdx], laneId: toLaneId };
  //   setFunnelItems(
  //     funnelItems
  //       .slice(0, itemIdx)
  //       .concat([newFunnelItem])
  //       .concat(funnelItems.slice(itemIdx + 1))
  //   );
  //   const newPositions = updateItemPosition(
  //     newFunnelItem.id,
  //     fromLaneId,
  //     toLaneId,
  //     toPosition
  //   );
  //   setPositions([...newPositions]);
  //   await updateCandidateFunnelPosition(jobId, newFunnelItem.id, {
  //     jobId,
  //     itemId: newFunnelItem.id,
  //     toLaneId,
  //     toPosition,
  //   });
  // };

  // const updateItemPosition = (itemId, fromLaneId, toLaneId, toPosition) => {
  //   if (fromLaneId === toLaneId) {
  //     const toIdx = positions.findIndex((pos) => pos.laneId === toLaneId);
  //     const toLane = positions[toIdx];
  //     const idx = toLane.laneItems.indexOf(itemId);
  //     const newItems = toLane.laneItems
  //       .slice(0, idx)
  //       .concat(toLane.laneItems.slice(idx + 1));
  //     positions[toIdx].laneItems = newItems
  //       .slice(0, toPosition)
  //       .concat([itemId])
  //       .concat(newItems.slice(toPosition));
  //     return positions;
  //   } else {
  //     const fromIdx = positions.findIndex((pos) => pos.laneId === fromLaneId);
  //     const toIdx = positions.findIndex((pos) => pos.laneId === toLaneId);
  //     const fromLane = positions[fromIdx];
  //     const itemIdx = fromLane.laneItems.indexOf(itemId);
  //     const newItems = fromLane.laneItems
  //       .slice(0, itemIdx)
  //       .concat(fromLane.laneItems.slice(itemIdx + 1));
  //     positions[fromIdx].laneItems = newItems;
  //     if (toIdx === -1) {
  //       positions.push({ laneId: toLaneId, laneItems: [itemId] });
  //       return positions;
  //     }
  //     positions[toIdx].laneItems = positions[toIdx].laneItems
  //       .slice(0, toPosition)
  //       .concat([itemId])
  //       .concat(positions[toIdx].laneItems.slice(toPosition));
  //     return positions;
  //   }
  // };

  const onLoad = useCallback(
    async ({ isSilent, modalItemId } = {}) => {
      try {
        if (!isSilent) {
          setIsFetching(true);
        }
        let {
          data,
          candidates,
          interviewRequests,
          acceptedCandidates,
          hasNext,
          positions,
          lanes = [],
        } = await getCandidateFunnel(jobId, page, limit);
        const funnelItems = data.map((d) => {
          return {
            ...d,
            candidateData: candidates.find((c) => d.anonymousId === c.userId),
          };
        });
        setIsFetching(false);
        setLanes(lanes);
        setPositions(positions);
        const newPositionsMap = getPositionsMap(funnelItems, positions);
        console.log({ positions, newPositionsMap });
        setPositionsMap(newPositionsMap);
        setInterviewRequests(interviewRequests);
        setAcceptedCandidates(acceptedCandidates);
        setFunnelItems(funnelItems);
        setHasNext(hasNext);
        if (modalItemId) {
          const modalItem = funnelItems.find((fi) => fi.id === modalItemId);
          if (modalItem) {
            setCandidateModalProps({
              ...candidateModalProps,
              funnelItemId: modalItem.id,
              anonymousId: modalItem.candidateData.userId,
              jobId: modalItem.jobId,
              isModalOpen: true,
              positions,
              lanes,
              laneId: modalItem.laneId,
              interviewRequest:
                interviewRequests[modalItem.candidateData.userId],
              title:
                getLastExperienceTitle(modalItem.candidateData?.experience) ||
                modalItem.candidateData.lastExperienceTitle ||
                "Fresher",
              candidate: modalItem.candidateData,
              labels: modalItem.labels || [],
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [jobId, page]
  );

  const getCandidateSummary = (candidate) => {
    const result = {};
    result.experienceTitle =
      getLastExperienceTitle(candidate.experience) ||
      candidate.lastExperienceTitle ||
      "Fresher";
    result.experienceDuration = displayDuration(candidate.totalExperience);
    result.experienceDuration =
      result.experienceDuration === "--" ? "" : result.experienceDuration;
    result.hasAnotherOffer = !!candidate?.premium?.existingOffers?.length;
    result.url = `${appUrl}/public/candidate/${candidate.userId}`;
    const alreadyResigned = candidate.alreadyResigned;
    const lastWorkingDate = candidate.lastWorkingDate;
    if (
      lastWorkingDate > 0 &&
      alreadyResigned &&
      Date.now() < lastWorkingDate
    ) {
      result.inNoticePeriod = true;
    }

    result.summary = [
      result.experienceTitle,
      result.experienceDuration,
      result.hasAnotherOffer ? "Has another offer" : "",
      result.inNoticePeriod ? "In notice period" : "",
    ]
      .filter(Boolean)
      .join(" | ");

    result.summary = result.summary + "\n" + result.url;
    result.userId = candidate.userId;
    return result;
  };

  const renderHeader = (lane, index) => {
    return (
      <FunnelLaneHeader
        id={(positions.find((pos) => pos.laneId === lane) || "").laneId}
        value={(positions.find((pos) => pos.laneId === lane) || "").laneName}
        candidateUrls={(positionsMap[lane] || []).map((item) =>
          getCandidateSummary(item.candidateData)
        )}
        jobId={jobId}
        isAdmin={props.isAdmin}
        onFunnelDelete={async (laneId) => {
          try {
            await deleteCandidateFunnelLane(jobId, laneId);
            await onLoad({ isSilent: true });
          } catch (e) {}
        }}
        onFunnelHide={(laneId) => {
          setHiddenLanes(hiddenLanes.concat([laneId]));
        }}
        onFunnelRename={async (laneId, newName) => {
          try {
            await renameCandidateFunnelLane(jobId, laneId, newName);
            await onLoad({ isSilent: true });
          } catch (e) {}
        }}
        onStageAdd={async (laneName, dir) => {
          if (lanes.find((l) => l === laneName)) {
            return "";
          }
          try {
            const newPosition = await createCandidateFunnelLane(
              jobId,
              laneName
            );
            const insertPos = dir === "left" ? index : index + 1;
            const newLanes = lanes
              .slice(0, insertPos)
              .concat([newPosition.laneId])
              .concat(lanes.slice(insertPos));
            await updateCandidateFunnelLanes(jobId, newLanes);
            const newPositions = positions.concat([newPosition]);
            setPositions(newPositions);
            setPositionsMap(getPositionsMap(funnelItems, newPositions));
            setLanes(newLanes);
          } catch (e) {}
        }}
        candidates={(positionsMap[lane] || []).map(
          (item) => item.candidateData
        )}
        interviewRequests={interviewRequests}
        onInterviewCreation={async () => {
          try {
            await onLoad();
          } catch (e) {}
        }}
      />
    );
  };
  const renderItem = (card) => {
    return (
      <Box
        key={card.id}
        style={{
          // marginBottom: "8px",
          // borderRadius: "4px",
          border:
            lastSelectedItemId === card.id
              ? "3px solid rgb(27 108 189)"
              : "1px solid transparent",
          // boxShadow: "rgb(140 149 159 / 15%) 0px 3px 6px",
          cursor: "pointer",
        }}
        onClick={() => {
          setLastSelectedItemId(card.id);
          setCandidateModalProps({
            ...candidateModalProps,
            funnelItemId: card.id,
            anonymousId: card.candidateData.userId,
            jobId: card.jobId,
            isModalOpen: true,
            laneName: (
              positions.find((pos) => pos.laneId === card.laneId) || ""
            ).laneName,
            laneId: card.laneId,
            positions,
            onLaneChange,
            lanes,
            interviewRequest: interviewRequests[card.candidateData.userId],
            title:
              getLastExperienceTitle(card.candidateData.experience) ||
              card.candidateData.lastExperienceTitle ||
              "Fresher",
            candidate: card.candidateData,
            labels: card.labels || [],
          });
        }}
      >
        <Box
          p={4}
          background={getCardBackground(
            interviewRequests[card.candidateData.userId]
          )}
        >
          <Text fontWeight={"medium"}>
            {getLastExperienceTitle(card.candidateData.experience) ||
              card.candidateData.lastExperienceTitle ||
              "Fresher"}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {getExperienceDuration(card.candidateData.totalExperience)}
          </Text>
          <CandidateLabels labels={card.labels} />
          <CandidateTags candidate={card.candidateData} />
          {interviewRequests[card.candidateData.userId] && (
            <Flex flexDirection="column">
              <Divider mt={1} mb={2} />
              <Flex alignItems="center">
                <Text mr={2}>Interview status</Text>
                <Tag
                  size="sm"
                  colorScheme={getStatusTagColorScheme(
                    interviewRequests[card.candidateData.userId].status
                  )}
                  letterSpacing={"1.2px"}
                >
                  {interviewRequests[card.candidateData.userId].status}
                </Tag>
              </Flex>
              {interviewRequests[card.candidateData.userId].status ===
                "APPROVED" && (
                <Box mt={2}>
                  <Text>
                    {
                      acceptedCandidates?.[
                        interviewRequests?.[card?.candidateData?.userId]
                          ?.anonymousId
                      ]?.email
                    }
                  </Text>
                  <Text>
                    {
                      acceptedCandidates?.[
                        interviewRequests?.[card?.candidateData?.userId]
                          ?.anonymousId
                      ]?.name
                    }
                  </Text>
                  <Text>
                    {
                      acceptedCandidates?.[
                        interviewRequests?.[card?.candidateData?.userId]
                          ?.anonymousId
                      ]?.phone
                    }
                  </Text>
                </Box>
              )}

              <Flex>
                <Text mr={2}>{"Offered"}</Text>
                <Currency
                  currency={
                    interviewRequests[card.candidateData.userId]
                      .offeredCTCCurrency
                  }
                  value={
                    interviewRequests[card.candidateData.userId].offeredCTC
                  }
                />
              </Flex>
              <Text fontSize="sm" color="gray.500">
                {relativeTime(
                  Date.now(),
                  interviewRequests[card.candidateData.userId].createdAt
                )}
              </Text>
              {(interviewRequests[card.candidateData.userId].status ===
                "REJECTED" ||
                interviewRequests[card.candidateData.userId].status ===
                  "ONHOLD") && (
                <>
                  <Flex direction="row" alignItems="center">
                    <Text fontSize="sm" mr={2} fontWeight="500">
                      Reason:
                    </Text>
                    <Text fontSize="sm">
                      {interviewRequests[card.candidateData.userId]
                        .statusReason || "Rejected by the candidate"}
                    </Text>
                  </Flex>
                  <Link
                    target="_blank"
                    to={
                      "/employer/create-interview/" +
                      card.candidateData.userId +
                      (jobId ? "?jobId=" + jobId : "")
                    }
                  >
                    <Button
                      justifyContent="flex-start"
                      colorScheme="orange"
                      size="sm"
                      leftIcon={<FiSend />}
                    >
                      <Text mr={2}>{"Request Interview"}</Text>
                      <Currency
                        currency={card.candidateData.rcAmountCurrency}
                        value={card.candidateData.rcAmount / 100}
                      ></Currency>
                    </Button>
                  </Link>
                </>
              )}
            </Flex>
          )}
          {props.isAdmin && card.laneId === "handpicked" && (
            <Popover
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              closeOnBlur={false}
              placement="left"
            >
              {({ isOpen, onClose }) => (
                <>
                  <PopoverTrigger>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      icon={<DeleteIcon />}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverCloseButton
                        onClick={(e) => {
                          e.stopPropagation();
                          onClose(e);
                        }}
                      />
                      <PopoverBody>
                        <Box>Confirm removing this ?</Box>
                        <Button
                          mt={4}
                          onClick={async (e) => {
                            e.stopPropagation();
                            try {
                              await removeCandidateFunnelItem(jobId, card.id, {
                                id: card.id,
                              });
                              toast({
                                title: "Removed successfully",
                                description: e.message,
                                status: "success",
                                duration: 2000,
                                isClosable: true,
                              });
                              await onLoad({
                                isSilent: true,
                              });
                              onClose(e);
                            } catch (e) {
                              toast({
                                title: "Failed to remove",
                                description: e.message,
                                status: "error",
                                duration: 5000,
                                isClosable: true,
                              });
                            }
                          }}
                        >
                          Remove card
                        </Button>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </>
              )}
            </Popover>
          )}
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    logEvent("[E] page view job candidate funel");
    onLoad();
  }, [onLoad]);

  return (
    <Box pt={2}>
      <ScrollToTop />
      <FunnelHeader
        onBack={() => {
          if (props.history.length > 0) {
            props.history.goBack();
          } else {
            props.history.push("/employer/jobapplication/" + jobId + "/search");
          }
        }}
        jobTitle={props.job.jobTitle.name}
        companyName={props.job.company.name}
      >
        <Flex direction="row">
          <SelectButton
            options={lanes.map((l) => ({
              value: l,
              label: (positions.find((pos) => pos.laneId === l) || "").laneName,
              isSelected: !hiddenLanes.includes(l),
            }))}
            headerText="Stages"
            buttonText="Hide/Unhide Stages"
            onChange={(options) => {
              setHiddenLanes(
                options.filter((opt) => !opt.isSelected).map((opt) => opt.value)
              );
            }}
          />
          <Box w={2} />
          <SelectButton
            options={getLabels().map((l) => ({
              value: l,
              label: l,
              isSelected: !hiddenLabels.includes(l),
            }))}
            headerText="Choose label"
            buttonText="Filter by"
            onChange={(options) => {
              setHiddenLabels(
                options.filter((opt) => !opt.isSelected).map((opt) => opt.value)
              );
            }}
          />
        </Flex>
      </FunnelHeader>
      <div
        style={{
          // flex: "1 1",
          // overflowY: "hidden",
          // overflowX: "scroll",
          // minWidth: 0,
          // height: "100vh",
          padding: 16,
          paddingTop: 32,
          // background: "#FFFFFF",
        }}
      >
        {/* {!isFetching && (
          <CenterContainer padding="0px">
            <Block marginTop="70px">
              We have shared your job description with our recruiters. Our
              recruiters will start sending shortlisted candidates to you in 3
              to 5 days. Meanwhile, you can do your candidate search.
            </Block>
          </CenterContainer>
        )} */}
        <LoadingScreen show={isFetching} />

        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginTop: "16px",
          }}
        >
          <div className="card-scene">
            <CandidateFunnelBoard
              initial={positionsMap}
              setColumns={(m) => {
                setPositionsMap(m);
              }}
              initialOrder={lanes.filter((l) => !hiddenLanes.includes(l))}
              setOrdered={(lanes) => {
                setLanes(lanes);
              }}
              renderItem={renderItem}
              renderHeader={renderHeader}
              containerHeight="calc(100vh - 176px)"
              onColumnsUpdate={async (newLanes) => {
                try {
                  await updateCandidateFunnelLanes(jobId, newLanes);
                } catch (e) {
                  await onLoad({ isSilent: true });
                }
              }}
              onItemUpdate={async (
                itemId,
                fromLaneId,
                fromPositions,
                toLaneId,
                toPositions
              ) => {
                try {
                  setLastSelectedItemId(itemId);
                  await updateCandidateFunnelPositions(jobId, itemId, {
                    jobId,
                    itemId,
                    toLaneId,
                    fromLaneId,
                    toPositions,
                    fromPositions,
                    toLaneName: positions.find((p) => p.laneId === toLaneId)
                      .laneName,
                    fromLaneName: positions.find((p) => p.laneId === fromLaneId)
                      .laneName,
                  });
                } catch (e) {
                  await onLoad({ isSilent: true });
                }
              }}
            />
          </div>

          {!isFetching && (
            <Box
              background="gray.200"
              margin={4}
              style={{
                minWidth: "240px",
                minHeight: "100px",
                display: "flex",
                padding: "12px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
              }}
            >
              <AddFunnelList
                onCreate={async (val) => {
                  if (lanes.find((lane) => lane === val)) {
                    return { error: "Name already exists" };
                  }
                  const newPosition = await createCandidateFunnelLane(
                    jobId,
                    val
                  );
                  const newLanes = lanes.concat([newPosition.laneId]);
                  const newPositions = positions.concat([newPosition]);
                  setPositions(newPositions);
                  setPositionsMap(getPositionsMap(funnelItems, newPositions));
                  setLanes(newLanes);
                  return { isCreated: true };
                }}
              />
            </Box>
          )}
        </div>
      </div>
      <CandidateFunnelModal {...candidateModalProps} />
    </Box>
  );
};

// </div>

// <div>
{
  /* <Block marginBottom="scale600">
        <Title>Candidate Funnel</Title>
      </Block>
      {candidates.length === 0 && (
        <CenterContainer padding="0px">
          <Block marginTop="70px">
            We have shared your job description with our recruiters. Our
            recruiters will start sending shortlisted candidates to you in 3 to
            5 days. Meanwhile, you can do your candidate search.
          </Block>
        </CenterContainer>
      )}
      <LoadingScreen show={isFetching} /> */
}
export default withRouter(CandidateFunnel);
