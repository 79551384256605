import { Block } from "baseui/block";
import { LabelMedium, ParagraphMedium } from "baseui/typography";
import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router";
import { getTalentscoutUploadedCandidates } from "../api/admin";
import Card from "../components/Card";
import Pagination from "../components/Pagination";
import Title from "../components/Title";

export const AdminTalentscoutUploaded = (props) => {
  const { talentscoutId } = props;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const jobId = params.get("jobId");
  const [candidates, setCandidates] = useState([]);
  const [tsRequests, setTSRequests] = useState({});
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(0);
  const limit = 10;
  const fetchTalentscoutUploaded = async () => {
    try {
      const { data, tsRequests } = await getTalentscoutUploadedCandidates(
        talentscoutId,
        jobId,
        page,
        limit
      );
      setCandidates(data);
      setTSRequests(tsRequests);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchTalentscoutUploaded();
  }, []);

  const onNextClick = async () => {
    setPage(page + 1);
  };

  const onPrevClick = async () => {
    setPage(page - 1);
  };

  useEffect(() => {
    setHasPrev(page - 1 >= 0);
    fetchTalentscoutUploaded();
  }, [page]);

  return (
    <Block>
      <Title marginBottom="scale600">
        <>Uploaded profiles</>
      </Title>
      {Object.values(tsRequests).map((tsRequest) => {
        return (
          <Card marginBottom="scale400">
            <LabelMedium>{tsRequest.candidateEmail}</LabelMedium>
            <LabelMedium>{tsRequest.candidatePhone}</LabelMedium>
            <ParagraphMedium>{tsRequest.candidateName}</ParagraphMedium>
            <ParagraphMedium>{tsRequest.status}</ParagraphMedium>
          </Card>
        );
      })}
      <Pagination
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        hasNext={hasNext}
        hasPrev={hasPrev}
      />
    </Block>
  );
};
