import amplitude from "amplitude-js";
import { envConfig } from "../api/endpoints";

export function initialise() {
  amplitude.getInstance().init(envConfig["amplitude"]);
}

export function setUserId(userId) {
  amplitude.getInstance().setUserId(userId);
}

export function setUserProperties(properties) {
  amplitude.getInstance().setUserProperties(properties);
}

export function setUser(user) {
  setUserId(user.uid);
  setUserProperties({
    email: user.email,
    role: user.role,
    candidateProfile: user.candidateProfile,
    employerProfile: user.employerProfile,
    talentscoutProfile: user.talentScoutProfile,
  });
}

export function logEvent(event, data) {
  amplitude.getInstance().logEvent(event, data);
}
