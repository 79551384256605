import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { ReviewPriorityProps } from "src/api/review_priority";
import { AutoComplete, AutoCompleteItem } from "src/components/AutoComplete";
import { searchEntity } from "src/api/entity";
import { FormErrorMessage } from "src/lib/uikit";

export type PriorityFormProps = ReviewPriorityProps & {
  job?: AutoCompleteItem;
};

export const PriorityForm = ({
  values,
  onChange,
}: {
  values: PriorityFormProps;
  onChange: (values: ReviewPriorityProps) => void;
}) => {
  const {
    control,
    watch,
    setValue,
    getValues,
    register,
    formState: { errors },
  } = useForm<PriorityFormProps>({
    defaultValues: values,
  });
  useEffect(() => {
    const subscription = watch(() => {
      const values = getValues();
      onChange({ ...values, jobId: values.job?.id || "" });
    });
    return () => subscription.unsubscribe();
  }, [watch, getValues, onChange]);

  return (
    <form>
      <Box mr={6} flex={1}>
        <FormControl>
          <FormLabel htmlFor={`idType`}>Select Type</FormLabel>
          <Controller
            name="idType"
            control={control}
            render={({ field: { value, ...rest } }) => {
              return (
                <RadioGroup
                  {...rest}
                  name="idType"
                  isInline
                  spacing={4}
                  value={value}
                  defaultValue="JOBID"
                  onChange={(value) => {
                    setValue("idType", value);
                  }}
                >
                  <Radio value="JOBID" mr={8}>
                    Job Id
                  </Radio>
                  <Radio value="PREDEFINED">Predefined</Radio>
                </RadioGroup>
              );
            }}
          />
          <FormErrorMessage />
        </FormControl>
        <FormControl>
          <Controller
            name={`predefinedId`}
            control={control}
            render={({ field: { value, ...rest } }) => {
              if (values.idType !== "PREDEFINED") return <></>;
              return (
                <>
                  <FormLabel htmlFor={`predefinedId`}>Predefined Id</FormLabel>
                  <AutoComplete
                    {...rest}
                    items={[{ id: "NOTICEPERIOD", name: "NOTICEPERIOD" }]}
                    id={`predefinedId`}
                    isMultiple={false}
                    isSearchable={false}
                    isCreatable={false}
                    placeholder="Select predefined"
                    selected={{
                      id: value,
                      name: value,
                    }}
                    onItemSelect={(
                      value: AutoCompleteItem | AutoCompleteItem[]
                    ) => {
                      console.log("ontItemselect", value);
                      setValue("predefinedId", (value as AutoCompleteItem)?.id);
                    }}
                    fetchItems={async (q) => searchEntity("job", q)}
                  />
                  <FormErrorMessage />
                </>
              );
            }}
          />
        </FormControl>
        <FormControl>
          <Controller
            name={`job`}
            control={control}
            render={({ field: { value, ...rest } }) => {
              if (values.idType !== "JOBID") return <></>;
              return (
                <>
                  <FormLabel htmlFor={`job`}>Job</FormLabel>
                  <AutoComplete
                    {...rest}
                    items={[]}
                    id={`job`}
                    isCreatable={false}
                    selected={value}
                    onItemSelect={(
                      value: AutoCompleteItem | AutoCompleteItem[]
                    ) => {
                      setValue(`job`, value as AutoCompleteItem);
                    }}
                    fetchItems={async (q) => searchEntity("job", q)}
                  />
                  <FormErrorMessage>{errors.jobId?.message}</FormErrorMessage>
                </>
              );
            }}
          />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="count">No of Items</FormLabel>
          <NumberInput
            {...register("count", {
              valueAsNumber: true,
            })}
            onChange={(valueString) => setValue("count", +valueString)}
            step={1}
            min={1}
            max={10000}
          >
            <NumberInputField name="count" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <FormErrorMessage>{errors.count?.message}</FormErrorMessage>
        </FormControl>
      </Box>
    </form>
  );
};
