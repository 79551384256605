import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import { getAllCandidateSubmissions, getUserDetails } from "../api/admin";
import Pagination from "../components/Pagination";
import Button from "../components/Button";
import { prettyPrint } from "../util/time";
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table";
import LoadingScreen from "../components/LoadingScreen";

const SubmissionRow = (props) => (
  <StyledRow key={props.index}>
    <StyledCell>{prettyPrint(props.submission.modifiedAt)}</StyledCell>
    <StyledCell>{props.submission.status}</StyledCell>
    <StyledCell>
      <Button onClick={() => props.onDetailsClick(props.submission.id)}>
        See details
      </Button>
    </StyledCell>
  </StyledRow>
);

class AdminCandidateDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      submissions: [],
      hasNext: false,
      hasPrev: false,
      user: {},
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    this.setState({
      isFetching: true,
    });
    try {
      let { data: submissions, hasNext } = await getAllCandidateSubmissions(
        this.props.candidateId,
        this.state.page,
        this.state.limit
      );
      let { user } = await getUserDetails(this.props.candidateId);
      this.setState({
        submissions,
        hasNext,
        user,
        isFetching: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        isFetching: false,
      });
    }
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  render() {
    return (
      <div>
        <Title>{this.state.user.name}</Title>
        {this.state.submissions.length ? (
          <React.Fragment>
            <div style={{ marginBottom: "32px" }}>
              <StyledTable>
                <StyledHead>
                  <StyledHeadCell>Date</StyledHeadCell>
                  <StyledHeadCell>Status</StyledHeadCell>
                  <StyledHeadCell>Details</StyledHeadCell>
                </StyledHead>
                <StyledBody>
                  {this.state.submissions.map((submission, index) => (
                    <SubmissionRow
                      key={index}
                      submission={submission}
                      onDetailsClick={(submissionId) =>
                        this.props.history.push(
                          "/N0VHxB8M/admin/details/submission/" + submissionId
                        )
                      }
                    />
                  ))}
                </StyledBody>
              </StyledTable>
            </div>
            <Pagination
              onPrevClick={this.onPrevClick}
              onNextClick={this.onNextClick}
              hasNext={this.state.hasNext}
              hasPrev={this.state.hasPrev}
            />
          </React.Fragment>
        ) : (
          <div>{"No submissions"}</div>
        )}
        <LoadingScreen show={this.state.isFetching} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminCandidateDetails));
