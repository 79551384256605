import { ThemeTypings } from "@chakra-ui/react";

export const getStatusColorScheme = (
    status?: string
): ThemeTypings["colorSchemes"] => {
    switch (status) {
        case "PREAPPROVED":
            return "yellow";
        case "DATAVERIFIED":
            return "orange";
        case "APPROVED":
            return "green";
        case "REJECTED":
            return "red";
    }
    return "gray";
};
