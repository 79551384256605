import api from "./index";
import { baseUrl } from "./endpoints";

export async function getCandidateFunnel(jobId, page, limit) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getCandidateFunnelActivity(jobId, itemId, page, limit) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/item/" + itemId + "/funnel/activity",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getCandidateFunnelDetails(
  jobId,
  candidateId,
  page,
  limit
) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/candidate/" + candidateId + "/details",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function updateCandidateFunnelItem(jobId, itemId, item) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/item/" + itemId + "/update",
    method: "POST",
    data: item,
  });
}

export async function updateCandidateFunnelPosition(jobId, itemId, data) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/item/" + itemId + "/position",
    method: "POST",
    data,
  });
}

export async function updateCandidateFunnelCandidateNote(jobId, data) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/note",
    method: "POST",
    data,
  });
}

export async function removeCandidateFunnelCandidateNote(jobId, itemId, data) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/item/" + itemId + "/activity/note",
    method: "POST",
    data,
  });
}

export async function updateCandidateFunnelLanes(jobId, lanes) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/lane",
    method: "POST",
    data: lanes,
  });
}

export async function createCandidateFunnelLane(jobId, laneName) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/lane/create",
    method: "POST",
    data: { laneName, jobId },
  });
}

export async function renameCandidateFunnelLane(jobId, laneId, laneName) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/lane/rename",
    method: "POST",
    data: { laneName, laneId, jobId },
  });
}

export async function deleteCandidateFunnelLane(jobId, laneId) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/lane/delete",
    method: "POST",
    data: { laneId, jobId },
  });
}

export async function updateCandidateFunnelPositions(jobId, itemId, data) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/item/" + itemId + "/positions",
    method: "POST",
    data,
  });
}

export async function getFunnelLanes(jobId) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/lanes",
    method: "GET",
    params: [],
  });
}

export async function getFunnelPositions(jobId) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/positions",
    method: "GET",
    params: [],
  });
}

export async function moveCandidatesToAnotherFunnel(jobId, data) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/funnel/move",
    method: "POST",
    data,
  });
}
