import { useState } from "react"
import firebase from "firebase";

export type useFirebaseStorageReturnProps = {
    progress: number
    uploadFile: (uploadUrl: string, file: File) => Promise<string>
}

export const useFirebaseStorage = (): useFirebaseStorageReturnProps => {
    const [progress, setProgress] = useState(0)

    const uploadFile = async (uploadUrl: string, file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();
            const uploadTask = storageRef.child(uploadUrl).put(file);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    setProgress(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                },
                (err) => {
                    console.error(err)
                    setProgress(0);
                    reject("Upload failed. Please try again.")
                },
                async () => {
                    setProgress(0);
                    try {
                        resolve(await uploadTask.snapshot.ref.getDownloadURL())
                    } catch (e) {
                        reject(e)
                    }
                }
            );
        })
    }
    return { progress, uploadFile }
}