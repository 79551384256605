import React from "react";
import { Select, SIZE } from "baseui/select";
import Button from "./Button";
import { KIND } from "baseui/button";

class StyledSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      results: this.props.defaultOptions || [],
    };
  }

  async componentDidMount() {
    try {
      let results = await this.props.getOptions("");
      this.setState({
        results,
      });
    } catch (e) {}
  }

  getOptions = async (query) => {
    if (!this.props.getOptions) return;
    this.setState({
      isLoading: true,
      query,
    });
    try {
      let results = await this.props.getOptions(query);
      this.setState({
        results,
      });
    } catch (e) {}
    this.setState({
      isLoading: false,
    });
  };

  render() {
    return (
      <Select
        {...this.props}
        labelKey="name"
        valueKey="id"
        value={
          this.props.multi
            ? this.props.value || []
            : this.props.value
            ? [this.props.value]
            : []
        }
        onChange={({ value }) => {
          if (this.props.multi) {
            this.props.onChange(value);
          } else {
            this.props.onChange(value[0]);
          }
        }}
        getOptionLabel={({ option }) => {
          if (option.isCreatable) {
            return (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: "16px" }}>{option.name}</div>
                <Button kind={KIND.tertiary} size={SIZE.mini}>
                  Create
                </Button>
              </div>
            );
          }
          return option.name;
        }}
        isLoading={this.state.isLoading}
        options={this.state.results}
        onInputChange={async (e) => {
          await this.getOptions(e.target.value);
        }}
        overrides={{
          ControlContainer: {
            style: ({ $theme, $isFocused }) => {
              return {
                borderRadius: $theme.borders.radius200,
                background: $theme.colors.background,
                borderWidth: "1px",
                borderColor: $isFocused ? "rgba(44, 110, 203, 1)" : "#dadedf",
                // borderTopWidth: "1px",
                // borderBottomWidth: "1px",
                // borderLeftWidth: "1px",
                // borderRightWidth: "1px",
                // backgroundColor: this.props.backgroundColor || "#ffffff",
                // borderTopLeftRadius: "4px",
                // borderBottomLeftRadius: "4px",
                // borderTopRightRadius: "4px",
                // borderBottomRightRadius: "4px",
                // width: this.props.width ? this.props.width : "auto",
              };
            },
          },
          MultiValue: {
            props: {
              overrides: {
                Root: {
                  style: ({ $theme }) => ({
                    // borderRadius: $theme.borders.radius200,
                    // borderTopLeftRadius: "3px",
                    // borderTopRightRadius: "3px",
                    // borderBottomRightRadius: "3px",
                    // borderBottomLeftRadius: "3px",
                    backgroundColor: "#A1AEAF",
                    // color: "white",
                  }),
                },
                Action: {
                  style: {
                    // borderRadius: $theme.borders.radius200,
                    // borderTopLeftRadius: "3px",
                    // borderTopRightRadius: "3px",
                    // borderBottomRightRadius: "3px",
                    // borderBottomLeftRadius: "3px",
                    // ":hover": {
                    //   backgroundColor: "#E5E5E5",
                    // },
                    // ":focus": {
                    //   backgroundColor: "#C5C5C5",
                    // },
                  },
                },
                // Text: {
                //   style: {
                //     color: "black",
                //   },
                // },
                // ActionIcon: {
                //   props: {
                //     color: "black",
                //   },
                // },
              },
            },
          },
        }}
      >
        {this.props.children}
      </Select>
    );
  }
}

export default StyledSelect;
