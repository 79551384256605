import React from "react";
import { Link } from "react-router-dom";
import ProgressSteps from "../components/ProgressSteps";
import Card from "./Card";
import Currency from "./Currency";
import Bold from "./Bold";
import { withStyle } from "baseui";
import { Block } from "baseui/block";
import { Icon } from "./Icon";

const Wrapper = withStyle(Card, ({ isClickable }) => ({
  marginBottom: "16px",
  ":hover": isClickable
    ? {
      boxShadow: "1px 2px 20px 0px rgba(0, 0, 0, 0.1)",
    }
    : {},
}));

function getExperienceDuration(days) {
  return (
    <span style={{ marginRight: "8px" }}>
      {parseInt(days / 365) + " yrs " + parseInt((days % 365) / 30) + " mos"}
    </span>
  );
}

const Field = (props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      width: props.isStat ? "auto" : "300px",
      marginRight: "16px",
    }}
  >
    {props.img ? (
      <Block $marginRight="scale200">
        <Icon name={props.img} size={12} />
      </Block>
    ) : null}
    {props.name ? <div style={{ marginRight: "4px" }}>{props.name}</div> : null}
    <div style={{}}>{props.value}</div>
  </div>
);

function getProgressSteps(interviewStatus, interviewRequestId, isClickable) {
  switch (interviewStatus) {
    case "CREATED":
      return {
        steps: [
          { title: "Interview request created", description: "" },
          {
            title: "Pending review from FindingJo",
            description:
              "We are reviewing your interview request. We will complete the review in 24 hours",
          },
          {
            title: "Pending approval from Candidate",
            description:
              "Your interview request is sent to the candidate. Once candidate approves, we will notify you.",
          },
          {
            title: "Interview request accepted/rejected",
            description: "Your interview request has been accepted/rejected",
          },
        ],
        currentStep: 1,
        currentStatus: "PENDING REVIEW",
        currentStepStatus: "PENDING",
      };
    case "REVIEWED":
      return {
        steps: [
          { title: "Interview request created", description: "" },
          {
            title: "FindingJo review complete",
            description:
              "We have reviewed your interview request. We have sent this request to the candidate.",
          },
          {
            title: "Pending approval from Candidate",
            description:
              "Your interview request is sent to the candidate. Once candidate approves, we will notify you.",
          },
          {
            title: "Interview request accepted/rejected",
            description: "Your interview request has been accepted/rejected",
          },
        ],
        currentStep: 2,
        currentStatus: "PENDING FROM CANDIDATE",
        currentStepStatus: "PENDING",
      };
    case "APPROVED":
      return {
        steps: [
          { title: "Interview request created", description: "" },
          {
            title: "Pending review from FindingJo",
            description:
              "We are reviewing your interview request. We will complete the review in 24 hours",
          },
          {
            title: "Pending approval from Candidate",
            description:
              "Your interview request is sent to the candidate. Once candidate approves, we will notify you.",
          },
          {
            title: "Interview request accepted",
            description:
              "Your interview request has been accepted by the candidate",
          },
        ],
        currentStep: 3,
        currentStatus: "APPROVED",
        currentStepStatus: "SUCCESS",
      };
    case "REJECTED":
      return {
        steps: [
          { title: "Interview request created", description: "" },
          {
            title: "Pending review from FindingJo",
            description:
              "We are reviewing your interview request. We will complete the review in 24 hours",
          },
          {
            title: "Pending approval from Candidate",
            description:
              "Your interview request is sent to the candidate. Once candidate approves, we will notify you.",
          },
          {
            title: "Interview request rejected",
            description:
              "Your interview request has been rejected by the candidate",
          },
        ],
        currentStep: 3,
        currentStatus: "REJECTED",
        currentStepStatus: "FAILURE",
      };
    case "ONHOLD":
      return {
        steps: [
          { title: "Interview request created", description: "" },
          {
            title: "FindingJo rejected your Interview Request",
            description: (
              <div>
                {
                  "We have found issues with this interview request. We have refunded your Interview Booking Fees. "
                }
                {isClickable && (
                  <Link
                    to={
                      "/employer/mycandidate/" + interviewRequestId + "/details"
                    }
                  >
                    Learn more
                  </Link>
                )}
              </div>
            ),
          },
          {
            title: "Pending approval from Candidate",
            description:
              "Your interview request is sent to the candidate. Once candidate approves, we will notify you.",
          },
          {
            title: "Interview request accepted/rejected",
            description: "Your interview request has been accepted/rejected",
          },
        ],
        currentStep: 1,
        currentStatus: "REJECTED BY FINDINGJO",
        currentStepStatus: "FAILURE",
      };
    default:
      return {
        steps: [
          { title: "Interview request created", description: "" },
          {
            title: "Pending review from FindingJo",
            description:
              "We are reviewing your interview request. We will complete the review in 24 hours",
          },
          {
            title: "Pending approval from Candidate",
            description:
              "Your interview request is sent to the candidate. Once candidate approves, we will notify you.",
          },
          {
            title: "Interview request accepted/rejected",
            description: "Your interview request has been accepted/rejected",
          },
        ],
        currentStep: 1,
        currentStatus: "PENDING REVIEW",
        currentStepStatus: "PENDING",
      };
  }
}

const InterviewRequestEmployer = ({
  interviewRequest,
  candidate,
  isExpandedStatus,
  isClickable,
}) => {
  const progressSteps = getProgressSteps(
    interviewRequest.status,
    interviewRequest.id,
    isClickable
  );
  return (
    <Link
      style={{ color: "inherit" }}
      to={"/employer/mycandidate/" + interviewRequest.id + "/details"}
    >
      <Wrapper
        isClickable={isClickable}
        className={isClickable ? "clickable" : ""}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Bold
            style={{
              fontSize: "16px",
            }}
            className={"linkText"}
          >
            {candidate.lastExperienceTitle
              ? candidate.lastExperienceTitle
              : "Fresher"}
          </Bold>
          {interviewRequest.status === "APPROVED" && (
            <div
              style={{
                border: "1px solid #e2f0fd",
                color: "#0F4C81",
                paddingLeft: "10px",
                paddingRight: "10px",
                borderRadius: "16px",
                fontSize: "12px",
                marginLeft: "8px",
              }}
            >
              View Contact Details
            </div>
          )}
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {(candidate.experience || []).length ? (
            <Field
              img={
                "file-text"
              }
              name={"Exp"}
              value={getExperienceDuration(candidate.totalExperience)}
            />
          ) : null}
          {(candidate.experience || []).length ? (
            <Field
              img={
                "archive"
              }
              name={"Work at"}
              value={candidate.experience[0].company.name}
            />
          ) : null}
          {(candidate.education || []).length ? (
            <Field
              img={
                "book"
              }
              name={"Studied at"}
              value={candidate.education[0].college.name}
            />
          ) : null}
          <Field
            name={"Desired Salary"}
            img={
              "m2"
            }
            value={
              <Currency
                inWords
                currency={candidate.expectedCTCCurrency}
                value={candidate.expectedCTC}
              ></Currency>
            }
          />
          <Field
            name={"Notice"}
            img={
              "log-out"
            }
            value={candidate.noticePeriod + " weeks"}
          />
        </div>
        <div
          style={{
            marginTop: "16px",
            paddingTop: "16px",
            borderTop: "1px solid #f1f1f1",
          }}
        >
          <ProgressSteps
            isExpanded={isExpandedStatus}
            steps={progressSteps.steps}
            currentStep={progressSteps.currentStep}
            currentStatus={progressSteps.currentStatus}
            currentStepStatus={progressSteps.currentStepStatus}
          />
        </div>
      </Wrapper>
    </Link>
  );
};
export default InterviewRequestEmployer;
