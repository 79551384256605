import React, { useState } from "react";
import Button from "./Button";
import { SIZE } from "baseui/button";
import PopConfirm from "./PopConfirm";
import { connect } from "react-redux";
import Currency from "./Currency";
import Card from "./Card";
import { setWidgetState } from "../redux/actions/widget";
import { addMyCandidates } from "../api/talentscout";
import { toaster } from "baseui/toast";
import { TALENTSCOUT_MY_CANDIDATES } from "../util/routes";
import { handleApiErrorWithAction } from "../util/common";
import * as Analytics from "../analytics";
import Bold from "./Bold";
import { styled } from "baseui";
import { addCurrencyDecimals } from "../util/formatter";
import { Icon } from "./Icon";
import { Link, withRouter } from "react-router-dom";
import { Block } from "baseui/block";

const Wrapper = styled(Card, {
  marginBottom: "16px",
});

function getExperienceDuration(days) {
  return (
    <span>
      {parseInt(days / 365) + " yrs " + parseInt((days % 365) / 30) + " mos"}
    </span>
  );
}

const Field = (props) =>
  props.value ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        // width: props.isStat ? "auto" : "300px",
        marginRight: "16px",
      }}
    >
      {props.img ? (
        <Block $marginRight="scale200">
          <Icon name={props.img} size={12} />
        </Block>
      ) : null}
      {props.name ? (
        <div style={{ marginRight: "4px" }}>{props.name + ": "}</div>
      ) : null}
      <div>{props.value}</div>
    </div>
  ) : null;

const CandidateItemEmployer = ({
  candidate,
  isEmployer,
  history,
  user,
  isClickable,
  disableActions,
  setWidgetState,
  jobId,
}) => {
  const [isScouting, setIsScouting] = useState(false);
  const onInviteInterview = () => {
    Analytics.logEvent("[E] click interview candidate search");
    // history.push(
    //   "/employer/create-interview/" + (candidate.objectID || candidate.userId)
    // );
  };
  const interviewTarget = isClickable ? { target: "_blank" } : {};

  const onScoutClick = async () => {
    Analytics.logEvent("[S] click scout candidate search");
    try {
      setIsScouting(true);
      await addMyCandidates({
        talentscoutId: user.id,
        anonymousId: candidate.userId,
        candidateIdType: "platform",
      });
      toaster.show(<div>Added to your my candidates</div>, {
        autoHideDuration: 3000,
      });
      setIsScouting(false);
      history.push(TALENTSCOUT_MY_CANDIDATES);
    } catch (e) {
      handleApiErrorWithAction(e, history);
      setIsScouting(false);
      console.log(e);
    }
  };

  return (
    <Wrapper
      isClickable={isClickable}
      className={isClickable ? "clickable" : ""}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Bold
          className={"linkText"}
          style={{
            fontSize: "16px",
          }}
        >
          {candidate.lastExperienceTitle
            ? candidate.lastExperienceTitle
            : "Fresher"}
        </Bold>
        {isEmployer ? (
          <div style={{ display: "flex" }}>
            <Icon
              size={18}
              name={"share-2"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setWidgetState({
                  isShareCandidateModelVisible: true,
                  shareCandidate: candidate,
                  shareCandidateImageUrl: candidate.shareImageUrl,
                });
              }}
            />
          </div>
        ) : (
          <div style={{ display: "flex" }}></div>
        )}
      </div>

      <div /*style={{ display: "flex", flexWrap: "wrap" }}*/>
        {(candidate.experience || []).length ? (
          <Field
            img={"chevrons-up"}
            name={""}
            value={candidate.experience[0].company.name}
          />
        ) : null}
        <Field
          name={"Desired Salary"}
          img={"heart"}
          value={
            <Currency
              inWords
              currency={candidate.expectedCTCCurrency}
              value={candidate.expectedCTC}
            ></Currency>
          }
        />
        {(candidate.education || []).length ? (
          <Field
            img={"award"}
            name={""}
            value={candidate.education[0].college.name}
          />
        ) : null}
        {(candidate.experience || []).length ? (
          <Field
            img={"calendar"}
            name={"Experience"}
            value={getExperienceDuration(candidate.totalExperience)}
          />
        ) : null}
        <Field
          name={"Notice"}
          img={"log-out"}
          value={candidate.noticePeriod + " weeks"}
        />
      </div>
      <div style={{ display: "flex", marginTop: "16px" }}>
        <div style={{ marginRight: "4px" }}>{"Skills:"}</div>
        <div>
          {(candidate.skills || [])
            .map((sk) => sk.name)
            .slice(0, 5)
            .join(", ")}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ fontSize: "12px", color: "#919191" }}></div>
        {!disableActions ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Bold
              style={{
                marginRight: "16px",
              }}
            >
              {isEmployer
                ? "₹ " + addCurrencyDecimals(candidate.rcAmount / 100)
                : "₹ " + addCurrencyDecimals(candidate.tsAmount / 100)}
            </Bold>
            {isEmployer ? (
              <Link
                {...interviewTarget}
                to={
                  "/employer/create-interview/" +
                  (candidate.objectID || candidate.userId) +
                  (jobId ? "?jobId=" + jobId : "")
                }
              >
                <Button
                  size={SIZE.compact}
                  type={"primary"}
                  onClick={async (e) => {
                    // e.preventDefault();
                    // e.stopPropagation();
                    await onInviteInterview(candidate);
                  }}
                >
                  Interview
                </Button>
              </Link>
            ) : (
              <PopConfirm
                showArrow
                title="Confirm and pay?"
                onConfirm={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  await onScoutClick(candidate);
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <Button
                  isLoading={isScouting}
                  size={SIZE.compact}
                  type={"primary"}
                >
                  Scout
                </Button>
              </PopConfirm>
            )}
          </div>
        ) : null}
      </div>
    </Wrapper>

    //   {tsRequest ? (
    //     <div>
    //       {tsRequest.status === "CREATED" ? (
    //         <div style={{ color: "#0079D4" }}>
    //           You already sent a scout request for this candidate
    //         </div>
    //       ) : null}
    //       {tsRequest.status === "APPROVED" ? (
    //         <div style={{ color: "#0079D4" }}>
    //           Your scout request has been approved by this candidate
    //         </div>
    //       ) : null}
    //       {tsRequest.status === "REJECTED" ? (
    //         <div style={{ color: "#0079D4" }}>
    //           Your scout request has been rejected by this candidate
    //         </div>
    //       ) : null}
    //     </div>
    //   ) : null}
    //   {interviewRequest ? (
    //     <div>
    //       {interviewRequest.status === "CREATED" ||
    //       interviewRequest.status === "REVIEWED" ? (
    //         <div style={{ color: "#0079D4" }}>
    //           You already sent a interview request for this candidate
    //         </div>
    //       ) : null}
    //       {interviewRequest.status === "APPROVED" ? (
    //         <div style={{ color: "#0079D4" }}>
    //           Your interview request has been approved by this candidate
    //         </div>
    //       ) : null}
    //       {interviewRequest.status === "REJECTED" ? (
    //         <div style={{ color: "#0079D4" }}>
    //           Your interview request request has been rejected by this candidate
    //         </div>
    //       ) : null}
    //     </div>
    //   ) : null}
    // </Wrapper>
  );
};

export default withRouter(
  connect((state) => ({}), { setWidgetState })(CandidateItemEmployer)
);
