import React from "react";
import Card from "./Card";
import { Block } from "baseui/block";
import { LabelMedium } from "baseui/typography";
import { Icon } from "./Icon";

const AddSection = (props) => (
  <Card
    display="flex"
    isClickable
    props={props}
    padding="scale800"
    flexDirection="row"
    alignItems="center"
    onClick={props.onClick}
    backgroundColor="light"
    borderWidth={1}
    borderColor={"grey"}
  >
    <Block marginRight="scale400">
      <Icon size={20} name="plus" />
    </Block>
    <LabelMedium>{props.text}</LabelMedium>
    {props.children}
  </Card>
);

export default AddSection;
