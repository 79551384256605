import { SET_WORKSPACE_MEMBER } from "../actionTypes";

const initialState = {};

export default function theme(state = initialState, action) {
    switch (action.type) {
        case SET_WORKSPACE_MEMBER:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
