import api from "./index";
import { baseUrl } from "./endpoints";

export async function otpAuth({
  sendId,
  code,
  sendType,
}: {
  sendId: string;
  code: string;
  sendType?: string;
}) {
  return api({
    baseUrl,
    url: "/api/auth/otp",
    method: "POST",
    data: {
      sendType,
      sendId,
      code,
    },
  });
}

export async function tokenAuth({ signInToken }: { signInToken: string }) {
  return api({
    baseUrl,
    url: "/api/auth/signin-token",
    method: "POST",
    data: {
      signInToken,
    },
  });
}
