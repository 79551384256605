import { Box, Flex, useToast } from "@chakra-ui/react";
import React from "react";
import { ReferredCandidateProps } from "src/api/referred_candidate";
import { PDFViewer } from "src/lib/components/PDFViewer";

export type ResumesTabProps = {
  candidate: ReferredCandidateProps;
  onUpdated: (values: ReferredCandidateProps) => Promise<void>;
};

export const ResumesTab = ({ candidate, onUpdated }: ResumesTabProps) => {
  return (
    <Box pb={10}>
      <Flex>
        <Box width="100%">
          {candidate?.linkedInResumeUrl && (
            <PDFViewer source={candidate?.linkedInResumeUrl} showDownloadLink />
          )}
        </Box>
        <Box>
          {candidate?.resumeUrl && (
            <PDFViewer source={candidate?.resumeUrl} showDownloadLink />
          )}
        </Box>
      </Flex>
    </Box>
  );
};
