import React, { useEffect, useState } from "react";
import {
  getWalletHoldingAmount,
  getBankDetails,
  getWalletPendingWithdrawals,
} from "../api/user";
import { KIND as BUTTON_KIND, SIZE as BUTTON_SIZE } from "baseui/button";
import Currency from "../components/Currency";
import SubTitle from "../components/SubTitle";
import { addCurrencyDecimals } from "../util/formatter";
import FormControl from "../components/FormControl";
import Label from "../components/Label";
import EmptyCaption from "../components/EmptyCaption";
import Input from "../components/Input";
import Button from "../components/Button";
import { setWidgetState } from "../redux/actions/widget";
import { connect } from "react-redux";
import Title from "../components/Title";
import Card from "../components/Card";
import BankDetailsModal from "./BankDetailsModal";
import { createWalletWithdrawal, getWallet } from "../api/wallet";
import LoadingScreen from "../components/LoadingScreen";
import Entry from "../components/Entry";
import { prettyPrint } from "../util/time";
import { CenterContainer } from "../components/CenterContainer";

const UserWalletWithdraw = ({ user, workspace, setWidgetState }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [isFetchingWalletAmount, setIsFetchingWalletAmount] = useState(false);
  const [holdingAmount, setHoldingAmount] = useState(0);
  const [walletAmount, setWalletAmount] = useState(0);
  const [isFetchingBankDetails, setIsFetchingBankDetails] = useState(false);
  const [bankValues, setBankValues] = useState({});
  const [withdrawAmount, setWithdrawAmount] = useState({});
  const [isCheckingPending, setIsCheckingPending] = useState(true);
  const [pendingRequest, setPendingRequest] = useState({});
  console.log(isFetchingWalletAmount, isFetchingBankDetails, workspace);

  const getBankDetailsRemote = async () => {
    setIsFetchingBankDetails(true);
    try {
      if (user.role === "EMPLOYER") {
        let { bankName, accountNumber, ifscCode } = await getBankDetails(
          workspace.id,
          "WORKSPACE"
        );
        setBankValues({
          bankName,
          accountNumber,
          ifscCode,
        });
      } else {
        let { bankName, accountNumber, ifscCode } = await getBankDetails(
          user.id,
          "USER"
        );
        setBankValues({
          bankName,
          accountNumber,
          ifscCode,
        });
      }
      setIsFetchingBankDetails(false);
    } catch (e) {
      setIsFetchingBankDetails(false);
    }
  };

  const getHoldingAmount = async () => {
    if (user.role === "EMPLOYER") {
      setIsFetchingWalletAmount(true);
      try {
        let wallet = await getWallet(workspace.id, "WORKSPACE");
        setHoldingAmount(0);
        setWalletAmount(wallet.amount / 100);
        setIsFetchingWalletAmount(false);
      } catch (e) {
        setIsFetchingWalletAmount(false);
      }
    } else {
      try {
        setIsFetchingWalletAmount(true);
        let { walletAmount, holdingAmount } = await getWalletHoldingAmount(
          user.id
        );
        setHoldingAmount(holdingAmount / 100);
        setWalletAmount(walletAmount / 100);
        setIsFetchingWalletAmount(false);
      } catch (e) {
        setIsFetchingWalletAmount(false);
      }
    }
  };

  const getPendingRequest = async () => {
    try {
      let accountId, accountType;
      if (user.role === "EMPLOYER") {
        accountId = workspace.id;
        accountType = "WORKSPACE";
      } else {
        accountId = user.id;
        accountType = "USER";
      }
      let pendingRequest = await getWalletPendingWithdrawals(
        accountId,
        accountType
      );
      setPendingRequest(pendingRequest);
    } catch (e) {}
    setIsCheckingPending(false);
  };

  const onBankDetailsUpdated = async (bankValues) => {
    setBankValues(bankValues);
  };

  useEffect(() => {
    getBankDetailsRemote();
    getHoldingAmount();
    getPendingRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onWithdrawBalance = async () => {
    try {
      setIsCreating(true);
      let accountId, accountType;
      if (user.role === "EMPLOYER") {
        accountId = workspace.id;
        accountType = "WORKSPACE";
      } else {
        accountId = user.id;
        accountType = "USER";
      }
      await createWalletWithdrawal({
        accountId,
        accountType,
        amount: withdrawAmount * 100,
      });
      setIsCreating(false);
      await getPendingRequest();
    } catch (e) {
      setIsCreating(false);
    }
  };

  if (isCheckingPending) {
    return <LoadingScreen />;
  }

  if (pendingRequest && pendingRequest.id) {
    return (
      <div>
        <Title>Withdraw Balance</Title>
        <Card>
          <SubTitle>Your wallet withdrawal request</SubTitle>
          <Entry
            label={"Amount"}
            value={
              <Currency currency={"INR"} value={pendingRequest.amount / 100} />
            }
          />
          <Entry
            label={"Created At"}
            value={prettyPrint(pendingRequest.createdAt)}
          />
          <div style={{ marginTop: "16px" }}>
            <div>Please wait while we review the existing pending request.</div>
            <div>
              You will be able to create next withdrawal request once existing
              request is completed.
            </div>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <CenterContainer>
      <Title>Withdraw Balance</Title>
      <Card style={{ marginBottom: "32px", maxWidth: "420px" }}>
        <div style={{ marginBottom: "32px" }}>
          {user.role !== "EMPLOYER" && (
            <div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    width: "200px",
                  }}
                >
                  Wallet balance
                </div>
                <Currency currency={"INR"} value={walletAmount} />
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontWeight: "500",
                    fontSize: "16px",
                    width: "200px",
                  }}
                >
                  Money on hold
                </div>
                <Currency currency={"INR"} value={holdingAmount} />
              </div>
              <div style={{ fontSize: "10px" }}>
                Employer & Candidate have 7 days to complete first Interview
              </div>
              <div style={{ fontSize: "10px" }}>
                Talent-Scout can withdraw the earned revenue anytime after 8th
                day.
              </div>
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#e1e1e1",
                  marginTop: "8px",
                  marginBottom: "4px",
                }}
              />
            </div>
          )}
          <div style={{ display: "flex" }}>
            <div
              style={{ fontWeight: "500", fontSize: "16px", width: "200px" }}
            >
              Withdrawal balance
            </div>
            <Currency currency={"INR"} value={walletAmount - holdingAmount} />
          </div>
        </div>
        <div style={{ marginBottom: "16px" }}>
          <FormControl
            label={
              <Label subtitle={"The amount you would like to withdraw"}>
                {"Amount to withdraw"}
              </Label>
            }
            caption={<EmptyCaption />}
          >
            <Input
              onChange={(e) =>
                setWithdrawAmount(
                  e.target.value.split(",").join("")
                    ? +e.target.value.split(",").join("")
                    : 0
                )
              }
              value={
                addCurrencyDecimals(withdrawAmount)
                  ? addCurrencyDecimals(withdrawAmount)
                  : ""
              }
              autoComplete={"off"}
            />
          </FormControl>
        </div>

        <div style={{ maxWidth: "450px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <SubTitle>Bank Account Details</SubTitle>
            <Button
              kind={BUTTON_KIND.tertiary}
              size={BUTTON_SIZE.mini}
              onClick={() => {
                setWidgetState({
                  isBankModalVisible: true,
                  bankValues: {
                    ...bankValues,
                    accountNumber: "",
                    ifscCode: "",
                  },
                });
              }}
            >
              {bankValues.accountNumber ? "Edit Account" : "Add Account"}
            </Button>
          </div>
          {bankValues.accountNumber ? (
            <div
              style={{
                border: "1px solid #e1e1e1",
                padding: "16px",
                borderRadius: "6px",
              }}
            >
              <div>{bankValues.bankName}</div>
              <div>{bankValues.accountNumber}</div>
              <div>{bankValues.ifscCode}</div>
            </div>
          ) : null}
        </div>
      </Card>
      <Button isLoading={isCreating} onClick={onWithdrawBalance}>
        Withdraw Balance
      </Button>
      <BankDetailsModal onAdded={onBankDetailsUpdated} />
    </CenterContainer>
  );
};

export default connect(
  (state) => ({
    user: state.user,
    workspace: state.workspace,
    widgetState: state.widget,
  }),
  { setWidgetState }
)(UserWalletWithdraw);
