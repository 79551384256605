import React from "react";
import PageHeader from "../components/Header";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router";
import PageWrapper from "../components/PageWrapper";
import SidebarWrapper from "../components/SidebarWrapper";
import AdminCandidateList from "./AdminCandidateList";
import SidebarAdmin from "../components/SidebarAdmin";
import AdminInterviewRequestList from "./AdminInterviewRequestList";
import Container from "../components/Container";
import AdminJobList from "./AdminJobList";
import AdminUsersList from "./AdminUsersList";
import AdminTSRequestReviewList from "./AdminTSRequestReviewList";
import AdminTSRequestList from "./AdminTSRequestList";
import AdminTalentscoutReviewList from "./AdminTalentscoutReviewList";
import AdminEmployerReviewList from "./AdminEmployerReviewList";
import AdminTalentscoutWithdrawalList from "./AdminTalentscoutWithdrawalList";
import AdminEmployerWithdrawalList from "./AdminEmployerWithdrawalList";
import AdminTransactionsList from "./AdminTransactionsList";
import AdminCandidateSubmissionList from "./AdminCandidateSubmissionList";
import AdminWallet from "./AdminWallet";
import { CenterContainer } from "../components/CenterContainer";
import JobApplications from "./admin/JobApplications";

class Admin extends React.Component {
  renderSidebar() {
    let { page } = this.props.match.params;
    return <SidebarAdmin user={this.props.user || {}} page={page} />;
  }

  renderPage() {
    let { page } = this.props.match.params;
    return this.getAdminPage(page);
  }

  getAdminPage(page) {
    switch (page) {
      case "submissions":
        return (
          <AdminCandidateSubmissionList user={this.props.user} page={page} />
        );
      case "candidates":
        return <AdminCandidateList user={this.props.user} page={page} />;
      case "employers":
        return <AdminEmployerReviewList user={this.props.user} page={page} />;
      case "talentscouts":
        return (
          <AdminTalentscoutReviewList user={this.props.user} page={page} />
        );
      case "tsrequests":
        return <AdminTSRequestList user={this.props.user} page={page} />;
      case "idproofs":
        return <AdminTSRequestReviewList user={this.props.user} page={page} />;
      case "users":
        return <AdminUsersList user={this.props.user} page={page} />;
      case "interview-requests":
        return <AdminInterviewRequestList user={this.props.user} page={page} />;
      case "jobs":
        return <AdminJobList user={this.props.user} page={page} />;
      case "job-applications":
        return <JobApplications user={this.props.user} page={page} />;
      case "talentscout-withdrawals":
        return (
          <AdminTalentscoutWithdrawalList user={this.props.user} page={page} />
        );
      case "employer-withdrawals":
        return (
          <AdminEmployerWithdrawalList user={this.props.user} page={page} />
        );
      case "transactions":
        return <AdminTransactionsList user={this.props.user} page={page} />;
      case "wallet":
        return <AdminWallet user={this.props.user} page={page} />;
      default:
        return <div>Page not found</div>;
    }
  }

  render() {
    let props = this.props;
    let { role } = this.props.user;
    let { page } = this.props.match.params;
    return (
      <Container>
        <PageWrapper>
          <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
          <CenterContainer>{this.renderPage()}</CenterContainer>
        </PageWrapper>
        <PageHeader
          role={role}
          page={page}
          user={props.user}
          setUser={props.setUser}
        />
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(Admin));
