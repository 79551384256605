import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import { doWalletPayment, findUsers, verifyPaymentOrder } from "../api/admin";
import { styled } from "baseui";
import { createPaymentOrder } from "../api/user";
import WalletTransaction from "../components/WalletTransaction";
import Button from "../components/Button";
import Select from "../components/Select";
import Input from "../components/Input";
import Label from "../components/Label";
import { FormControl } from "baseui/form-control";
import { getCurrency, getCurrencyMap } from "../util/currency";
import Currency from "../components/Currency";
import Bold from "../components/Bold";
import { addCurrencyDecimals } from "../util/formatter";
import { withRouter } from "react-router-dom";
import { envConfig } from "../api/endpoints";
import {
  getWalletTransactions,
  getWorkspacesByUserEmail,
  getWalletAccount,
} from "../api/admin";
import { ALIGN, Radio, RadioGroup } from "baseui/radio";
import { toaster } from "baseui/toast";

const Wrapper = styled("div");

const BalanceSection = styled("div", {
  backgroundColor: "#ffffff",
  padding: "16px",
  borderRadius: "4px",
  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.2)",
});

const Section = styled("div", {
  marginBottom: "32px",
});

const EmptyTransaction = styled("div", {
  margin: "32px 0px",
  minHeight: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const YourBalance = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
});

const AddBalance = styled("div", {
  marginTop: "16px",
  borderTop: "1px solid #d8d8d8",
  padding: "16px 0px",
});

class AdminWallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      userId: "",
      user: null,
      hasNext: false,
      hasPrev: false,
      accountType: "WORKSPACE",
      workspaces: [],
      by: { name: "by email", id: "email" },
      transactions: [],
      value: "",
      selectedWorkspace: "",
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async fetchWorkspaces() {
    try {
      this.setState({
        showAddPaymentSection: false,
        selectedOrderId: "",
        selectedPaymentId: "",
      });
      let { data: users } = await findUsers(this.state.userEmail, "email", 0);
      if (users.length === 0) {
        toaster.show(<div>User not found</div>, {
          autoHideDuration: 3000,
        });
        return;
      }
      this.setState({ user: users[0] });
      let { data: workspaces } = await getWorkspacesByUserEmail(
        this.state.userEmail
      );
      this.setState({
        workspaces,
      });
    } catch (e) {}
  }

  async onLoad() {}

  async getUserWallet() {
    try {
      let { data: users } = await findUsers(this.state.userEmail, "email", 0);
      if (users.length === 0) {
        toaster.show(<div>User not found</div>, {
          autoHideDuration: 3000,
        });
        return;
      }
      let user = users[0];
      this.setState({
        user,
        walletFound: true,
        walletAmount: user.walletAmount || 0,
        amount: 0,
        currency: getCurrency(user.walletCurrency),
        userCurrency: getCurrency(user.walletCurrency),
        hasNext: false,
        hasPrev: false,
      });
      await this.getTransactionHistory();
    } catch (e) {}
  }

  async reloadPage() {
    await this.getWalletDetails();
  }

  getTransactionHistory = async () => {
    let { data: transactions, hasNext } = await getWalletTransactions(
      this.state.accountType === "USER"
        ? this.state.user.id
        : this.state.selectedWorkspace,
      this.state.accountType,
      this.state.page
    );
    this.setState({
      isFetching: false,
      hasNext,
      transactions: transactions,
    });
  };

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.getTransactionHistory();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.getTransactionHistory();
      }
    );
  };

  onAddWalletMoneyClick = async ({ amount, currency }) => {
    this.setState({ isLoading: true });
    try {
      let orderResponse = await createPaymentOrder(this.state.user.id, {
        amount: amount * 100,
        currency: currency.id,
      });
      this.setState({
        isLoading: false,
      });
      let razorpayOptions = {
        key: envConfig["razorpay"],
        amount: amount * 100,
        currency: currency.id,
        name: "FindingJo",
        description: "Adding money to FindingJo wallet",
        image:
          "https://storage.googleapis.com/quickhire-stage/assets/favicon.png",
        order_id: orderResponse.id,
        handler: async (response) => {
          console.log("razorpay response", response);
          const accountId =
            this.state.accountType === "WORKSPACE"
              ? this.state.selectedWorkspace
              : this.state.user.id;
          const accountType = this.state.accountType;
          try {
            await verifyPaymentOrder(this.state.user.id, {
              userId: this.state.user.id,
              paymentId: response.razorpay_payment_id,
              orderId: response.razorpay_order_id,
              signature: response.razorpay_signature,
              accountId,
              accountType,
            });
            this.setState({
              isLoading: false,
            });
            await this.reloadPage();
          } catch (e) {
            console.log(e);
          }
        },
        prefill: {
          name: this.props.user.name,
          email: this.props.user.email,
          contact: this.props.user.phone,
        },
        notes: {
          address: "Adding money to wallet",
        },
        theme: {
          color: "#0F4C81",
        },
      };
      var rzp1 = new window.Razorpay(razorpayOptions);
      rzp1.open();
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.log(e);
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.state.amount) {
      this.setState({
        amountError: "Please enter an amount",
      });
      return;
    }
    this.onAddWalletMoneyClick(this.state);
  };

  async getWalletDetails() {
    this.setState({
      showAddPaymentSection: false,
      selectedOrderId: "",
      selectedPaymentId: "",
    });
    if (this.state.accountType === "WORKSPACE") {
      await this.getWorkspaceWallet();
    } else {
      await this.getUserWallet();
    }
    await this.getTransactionHistory();
  }

  async doWalletPaymentManually() {
    if (!this.state.selectedPaymentId || !this.state.selectedOrderId) {
      toaster.show(<div>Both payment id and order id is required</div>, {
        autoHideDuration: 3000,
      });
      return;
    }
    try {
      await doWalletPayment({
        userId: this.state.user.id,
        paymentId: this.state.selectedPaymentId,
        orderId: this.state.selectedOrderId,
        accountId: this.state.selectedWorkspace,
        accountType: this.state.accountType,
      });
      this.setState({
        showAddPaymentSection: false,
        selectedOrderId: "",
        selectedPaymentId: "",
      });
      toaster.show(<div>Manual payment successful</div>, {
        autoHideDuration: 3000,
      });
    } catch (e) {
      toaster.show(<div>Manual payment failed</div>, {
        autoHideDuration: 3000,
      });
    }
  }

  async getWorkspaceWallet() {
    try {
      let wallet = await getWalletAccount(
        this.state.selectedWorkspace,
        "WORKSPACE"
      );
      this.setState({
        walletFound: true,
        walletAmount: wallet.amount || 0,
        amount: 0,
        currency: getCurrency(wallet.currency),
        userCurrency: getCurrency(wallet.currency),
      });
    } catch (e) {}
  }

  // async getUserWallet() {
  //     try {
  //         let { data: users } = await findUsers(
  //             ,
  //         "email",
  //             this.state.page
  //         );
  //         let user = users[0]
  //         this.setState({
  //             user,
  //             walletAmount: user.walletAmount || 0,
  //             amount: 0,
  //             currency: getCurrency(user.walletCurrency),
  //             userCurrency: getCurrency(user.walletCurrency),
  //             hasNext: false,
  //             hasPrev: false,
  //         });
  //     } catch (e) { }
  // }

  render() {
    return (
      <div>
        <Title>Wallet</Title>
        <FormControl label={<Label>{"Select Account Type ?"}</Label>}>
          <RadioGroup
            value={this.state.accountType}
            onChange={(e) => {
              this.setState({
                accountType: e.target.value,
                user: null,
                workspaces: [],
                selectedWorkspace: "",
                walletFound: false,
              });
            }}
            align={ALIGN.horizontal}
          >
            <Radio value={"WORKSPACE"}>Workspace</Radio>
            <Radio value={"USER"}>User</Radio>
          </RadioGroup>
        </FormControl>
        <FormControl label={<Label>{"Enter user email"}</Label>}>
          <Input
            id={"name"}
            onChange={(e) => {
              this.setState({
                user: null,
                workspaces: [],
                selectedWorkspace: "",
                walletFound: false,
                userEmail: e.target.value,
              });
            }}
            value={this.state.userEmail}
            autoComplete={"no"}
          />
        </FormControl>
        {this.state.accountType === "WORKSPACE" && (
          <Button onClick={() => this.fetchWorkspaces()}>Get Workspaces</Button>
        )}
        {this.state.accountType === "WORKSPACE" &&
        this.state.workspaces.length ? (
          <FormControl label={<Label>{"Select user workspace"}</Label>}>
            <Select
              clearable={false}
              defaultOptions={this.state.workspaces.map((w) => ({
                id: w.id,
                name: w.name,
              }))}
              onChange={(value) => {
                this.setState({
                  selectedWorkspace: value.id,
                });
              }}
              value={{ id: this.state.selectedWorkspace }}
            />
          </FormControl>
        ) : null}
        {(this.state.accountType === "WORKSPACE" &&
          this.state.selectedWorkspace) ||
        (this.state.accountType === "USER" && this.state.userEmail) ? (
          <Button
            onClick={() => this.getWalletDetails()}
            style={{ marginRight: 6 }}
          >
            Get Wallet Details
          </Button>
        ) : null}
        {this.state.accountType === "WORKSPACE" &&
        this.state.selectedWorkspace ? (
          <Button
            onClick={() => this.setState({ showAddPaymentSection: true })}
          >
            Add payment
          </Button>
        ) : null}
        {this.state.showAddPaymentSection ? (
          <div>
            <FormControl label={<Label>{"Enter order id"}</Label>}>
              <Input
                id={"order id"}
                onChange={(e) => {
                  this.setState({
                    selectedOrderId: e.target.value,
                  });
                }}
                value={this.state.selectedOrderId}
                autoComplete={"no"}
              />
            </FormControl>
            <FormControl label={<Label>{"Enter payment id"}</Label>}>
              <Input
                id={"payment id"}
                onChange={(e) => {
                  this.setState({
                    selectedPaymentId: e.target.value,
                  });
                }}
                value={this.state.selectedPaymentId}
                autoComplete={"no"}
              />
            </FormControl>
            <Button
              onClick={async () => {
                await this.doWalletPaymentManually();
              }}
            >
              Complete payment
            </Button>
          </div>
        ) : null}
        {!this.state.showAddPaymentSection && this.state.walletFound ? (
          <Wrapper>
            <Section>
              <BalanceSection>
                <div style={{ fontWeight: "600", fontSize: "16px" }}>
                  Wallet balance
                </div>
                <YourBalance></YourBalance>
                <div style={{ justifyContent: "flex-end", display: "flex" }}>
                  <div style={{ marginRight: "16px" }}>Total Balance</div>
                  <div
                    style={{
                      display: "flex",
                      width: "100px",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div>
                      <Currency
                        currency={this.state.currency}
                        value={parseFloat(this.state.walletAmount / 100)}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                ></div>
                <AddBalance>
                  <Bold
                    style={{
                      marginBottom: "32px",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    Add money to wallet
                  </Bold>
                  <form onSubmit={this.handleSubmit} autoComplete="off">
                    <FormControl
                      label={<Label>{"Enter amount"}</Label>}
                      caption={
                        <div style={{ paddingLeft: "84px" }}>
                          {"*2.36% payment gateway charges applicable"}
                        </div>
                      }
                      error={this.state.amountError}
                    >
                      <Input
                        id={"amount"}
                        onChange={(e) =>
                          this.setState({
                            amount: e.target.value.split(",").join("")
                              ? +e.target.value.split(",").join("")
                              : "",
                          })
                        }
                        value={
                          addCurrencyDecimals(this.state.amount)
                            ? addCurrencyDecimals(this.state.amount)
                            : ""
                        }
                        autoComplete={"off"}
                        startEnhancer={
                          <Select
                            openOnClick={true}
                            clearable={false}
                            defaultOptions={getCurrencyMap()}
                            onChange={(value) => {
                              this.setState({
                                currency: value,
                              });
                            }}
                            value={this.state.currency}
                          />
                        }
                      />
                    </FormControl>
                    <Button isLoading={this.state.isLoading} type={"submit"}>
                      Add to wallet
                    </Button>
                  </form>
                </AddBalance>
              </BalanceSection>
            </Section>
            <Section>
              <Bold style={{ fontSize: "18px", marginBottom: "8px" }}>
                Your transaction history
              </Bold>
              <div
                style={{
                  marginBottom: "16px",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.2)",
                }}
              >
                {this.state.transactions.map((tnx, index) => (
                  <WalletTransaction walletTransaction={tnx} key={index} />
                ))}
              </div>
              {this.state.transactions.length === 0 ? (
                <EmptyTransaction>
                  You dont have any transactions
                </EmptyTransaction>
              ) : null}
              <Pagination
                onPrevClick={this.onPrevClick}
                onNextClick={this.onNextClick}
                hasNext={this.state.hasNext}
                hasPrev={this.state.hasPrev}
              />
            </Section>
          </Wrapper>
        ) : null}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminWallet));
