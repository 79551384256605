import React, { useEffect, useRef, useState } from "react";
import { useStyletron } from "baseui";
import LoadingBar from "react-top-loading-bar";

const LoadingScreen = ({ show }) => {
  const loaderRef = useRef(null);
  const [, theme] = useStyletron();
  useEffect(() => {
    if (show) {
      loaderRef.current.continuousStart();
    } else {
      loaderRef.current.complete();
    }
  }, [show]);
  return <LoadingBar ref={loaderRef} color={theme.colors.accent} height={3} />;
  // <div
  //   style={{
  //     display: "flex",
  //     flex: 1,
  //     minHeight: "100vh",
  //     alignItems: "center",
  //     justifyContent: "center",
  //   }}
  // >
  //   <StyledSpinnerNext />
  // </div>
};

export default LoadingScreen;
