import React from "react";
import { connect } from "react-redux";
import { Block } from "baseui/block";
import { styled, useStyletron } from "baseui";

const Wrapper = styled(Block, ({ $theme, isSidebarVisible }) => ({
  "@media screen and (max-width: 880px)": {
    marginLeft: "0",
    position: "fixed",
    top: "50px",
    left: 0,
    margin: 0,
    width: "100%",
    transition: "0.5s all",
    overflow: "hidden",
    zIndex: 2,
    background: "#f9f9f9",
    paddingRight: "20px",
    transform: isSidebarVisible
      ? "translate(0px, 0px)"
      : "translate(-100%, 0px)",
    height: "calc(100vh - 50px)",
  },
}));

export const SidebarWrapper = ({ children, ...rest }) => {
  const [css] = useStyletron();
  return (
    <Wrapper
      minWidth={["0px", "320px", "320px"]}
      width={["0px", "auto", "auto"]}
      left="0px"
      top="50px"
      position="sticky"
      height="calc(100vh - 50px)"
      overflow="hidden"
      paddingTop="16px"
      paddingRight={["0px", "20px", "20px"]}
      // backgroundColor="#FBFAFA"
      // className={css({
      //   borderRight: "1px solid #eaeaea",
      // })}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

// const SidebarWrapper = styled(Block, ({ $theme, isSidebarVisible }) => ({
// padding: "0px",
// "@media screen and (max-width: 1360px)": {
//   marginLeft: "0",
// },
// "@media screen and (max-width: 780px)": {
//   marginLeft: "0",
//   position: "absolute",
//   top: "50px",
//   left: 0,
//   margin: 0,
//   width: "100%",
//   transition: "0.5s all",
//   overflow: "hidden",
//   transform: isSidebarVisible
//     ? "translate(0px, 0px)"
//     : "translate(-100%, 0px)",
//   height: "100%",
// },
// }));

export default connect(
  (state) => ({
    isSidebarVisible: state.widget.isSidebarVisible,
  }),
  {}
)(SidebarWrapper);
