import React from "react";
import { styled } from "baseui";
import Card from "./Card";
import FlexRow from "./FlexRow";
import Bold from "./Bold";
import Spacing from "./Spacing";
import { Icon } from "./Icon";

const TextSection = styled("div", {
  marginBottom: "8px",
});

const SubTextSection = styled("div", {
  fontSize: "13px",
});

const ImageWrapper = styled("div", {
  marginLeft: "16px",
  marginRight: "32px",
  alignSelf: "flex-start",
  marginTop: "32px",
});
const TSTarget = () => {
  return (
    <Card>
      <FlexRow>
        <ImageWrapper>
          <Icon name="star" size={50} />
        </ImageWrapper>
        <div>
          <TextSection>
            <Bold>Reach Your First Target</Bold>
            <SubTextSection>
              <Spacing size={4} />
              <div>1. Upload Resume. Set up interviews. Earn Money</div>
              <div>2. Earn Rs 10,000 for yourself on FindingJo</div>
              <div>3. Power up to upload 15 resumes at a time</div>
              <Spacing size={4} />
              <div style={{ color: "#919191", fontSize: "12px" }}>
                When you upload more resumes, you can setup more interviews.
              </div>
              <div style={{ color: "#919191", fontSize: "12px" }}>
                Your potential to earn money on FindingJo increases with every
                resume you upload.
              </div>
            </SubTextSection>
          </TextSection>
        </div>
      </FlexRow>
    </Card>
  );
};

export default TSTarget;
