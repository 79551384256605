import {
  Box,
  Text,
  Flex,
  SimpleGrid,
  Link,
  Heading,
  FormControl,
  FormHelperText,
  Textarea,
  Button,
  FormLabel,
  toast,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  CandidateProps,
  getAnonymousCandidateApi,
  updateAnonymousCandidateApi,
} from "src/api/anonymous_candidate";
import {
  getNextReferCandidateForReview,
  ReferredCandidateProps,
  updateReferCandidate,
} from "src/api/referred_candidate";
import Resume from "src/components/Resume";
import { EducationList } from "src/lib/forms/Candidate/Education/EducationList";
import { ExperienceList } from "src/lib/forms/Candidate/Experience/ExperienceList";
import { ProjectList } from "src/lib/forms/Candidate/Projects/ProjectList";
import { SkillList } from "src/lib/forms/Candidate/Skills/SkillList";
import { WorkShowcaseList } from "src/lib/forms/Candidate/WorkShowcases/WorkShowcaseList";
import { Entity } from "src/lib/types/candidate";
import { FormErrorMessage } from "src/lib/uikit";

export type DataAnonymisationProps = {
  candidate: ReferredCandidateProps;
  entity: Entity;
  entityType: "company" | "college";
};

export type EntityName = Entity & {
  isAlias: boolean;
  entityId: string;
};

export const DataAnonymisation = () => {
  const [careerSummary, setCareerSummary] = useState("");
  const [anonymousCandidate, setAnonymousCandidate] = useState<
    CandidateProps
  >();
  const [candidate, setCandidate] = useState<CandidateProps>();
  const [referredCandidate, setReferredCandidate] = useState<
    ReferredCandidateProps
  >();
  const [isApproving, setIsApproving] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const toast = useToast();
  const getAnonymousCandidate = async (isSilent?: boolean) => {
    try {
      !isSilent && setIsFetching(true);
      const {
        fullCandidate,
        anonymousCandidate,
        referredCandidate,
      } = await getNextReferCandidateForReview("ENTITYANONYMISATION");
      if (anonymousCandidate) {
        setAnonymousCandidate(anonymousCandidate);
        setCandidate(fullCandidate);
        setReferredCandidate(referredCandidate);
        setCareerSummary(anonymousCandidate.careerSummary);
      } else {
        setReferredCandidate(undefined);
        toast({
          title: "No candidate found",
          description: "Please refresh to check again",
        });
      }

      !isSilent && setIsFetching(false);
    } catch (e) {
      !isSilent && setIsFetching(false);
    }
  };

  useEffect(() => {
    getAnonymousCandidate();
  }, []);

  const onUpdate = () => {
    getAnonymousCandidate(true);
  };

  if (isFetching) {
    return (
      <Flex
        width="100vw"
        height="100vh"
        alignItems={"center"}
        justifyContent="center"
      >
        <Spinner />
      </Flex>
    );
  }
  if (!candidate || !referredCandidate) return null;
  return (
    <Box p={4}>
      <Flex mb={4} alignItems="center">
        <Heading size="lg">Data Anonymisation</Heading>
        <Flex ml={8}>
          <Button
            isLoading={isApproving}
            onClick={async () => {
              try {
                if (referredCandidate?.id) {
                  setIsApproving(true);
                  await updateReferCandidate(referredCandidate.id, {
                    status: "DATAANONYMISATION",
                  });
                  setIsApproving(false);
                  getAnonymousCandidate(false);
                }
              } catch (e) {
                setIsApproving(false);
                toast({
                  title: "Error while saving",
                  description: "Please try again",
                });
              }
            }}
            colorScheme="green"
          >
            Approve
          </Button>
        </Flex>
      </Flex>
      <Box mb={4} borderRadius={"md"} background="gray.50" p={4} boxShadow="sm">
        <SimpleGrid columns={[null, 2]} spacing={6} maxW={700}>
          <Text fontWeight={"semibold"}>Email</Text>
          <Text>{referredCandidate?.email}</Text>
          <Text fontWeight={"semibold"}>LinkedIn Url</Text>
          <Link href={referredCandidate?.linkedInUrl} isExternal>
            {referredCandidate?.linkedInUrl}
          </Link>
        </SimpleGrid>
      </Box>

      <Flex>
        <Box flex={1}>
          <Box mt={4} mb={4}>
            <FormControl>
              <FormLabel>Career Summary</FormLabel>
              <FormHelperText mb={2}>
                Write 2-4 short & energetic sentences to interest the reader!
                Mention your role, experience & most importantly - your biggest
                achievements, best qualities and skills.
              </FormHelperText>
              <Textarea
                value={careerSummary}
                id={`careerSummary`}
                size={"lg"}
                fontSize={"md"}
                onChange={(e) => {
                  setCareerSummary(e.target.value);
                }}
              />
            </FormControl>
            <Button
              mt={4}
              onClick={async () => {
                const data = await updateAnonymousCandidateApi(
                  candidate.userId,
                  {
                    careerSummary,
                  }
                );
                onUpdate();
              }}
            >
              Save
            </Button>
          </Box>
          <Box mb={6}>
            <Text textStyle="h3" mb={2}>
              Experience
            </Text>
            <ExperienceList
              experience={candidate.experience}
              onAdd={() => {}}
              shouldValidate={false}
              onSave={async (experience) => {
                const data = await updateAnonymousCandidateApi(
                  candidate.userId,
                  {
                    experience,
                  }
                );
                onUpdate();
              }}
            />
          </Box>
          <Box mb={6}>
            <Text textStyle="h3" mb={2}>
              Education
            </Text>
            <EducationList
              education={candidate.education}
              shouldValidate={false}
              onAdd={() => {}}
              onSave={async (education) => {
                const data = await updateAnonymousCandidateApi(
                  candidate.userId,
                  {
                    education,
                  }
                );
                onUpdate();
              }}
            />
          </Box>
          <Box mb={6}>
            <Text mb={2} textStyle="h3">
              Projects
            </Text>
            <ProjectList
              projects={candidate.projects}
              shouldValidate={false}
              projectCompanies={(candidate.experience || [])
                .filter((ex) => ex?.company?.id && ex?.company?.name)
                .map((ex) => ({
                  id: ex.company.id,
                  name: ex.company.name,
                }))
                .concat(
                  (candidate.education || [])
                    .filter((ex) => ex?.college?.id && ex?.college?.name)
                    .map((ex) => ({
                      id: ex.college.id,
                      name: ex.college.name,
                    }))
                )}
              onAdd={() => {}}
              onSave={async (projects) => {
                const data = await updateAnonymousCandidateApi(
                  candidate.userId,
                  {
                    projects,
                  }
                );
                onUpdate();
              }}
            />
          </Box>
          {candidate?.premium?.workShowcases ? (
            <Box mb={6}>
              <Text textStyle="h3" mb={2}>
                Work Showcase Links
              </Text>
              <WorkShowcaseList
                workShowcases={candidate?.premium?.workShowcases}
                onAdd={() => {}}
                onSave={async (workShowcases) => {
                  const data = await updateAnonymousCandidateApi(
                    candidate.userId,
                    {
                      premium: { workShowcases },
                    }
                  );
                  onUpdate();
                }}
              />
            </Box>
          ) : null}
          <Box mb={6}>
            <Text textStyle="h3" mb={2}>
              Skills
            </Text>
            <SkillList
              skills={candidate.skills}
              onAdd={() => {}}
              onSave={async (skills) => {
                const data = await updateAnonymousCandidateApi(
                  candidate.userId,
                  {
                    skills,
                  }
                );
                onUpdate();
              }}
            />
          </Box>
        </Box>
        <Box flex={1} p={4}>
          <Resume candidate={anonymousCandidate} isAnonymous></Resume>
        </Box>
      </Flex>
    </Box>
  );
};
