import React, { useState } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import { getWithdrawalRequests, markWithdrawalPaid } from "../api/admin";
import Button from "../components/Button";
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table";
import * as Analytics from "../analytics";

const WithdrawalRow = (props) => {
  const [isPaidLoading, setIsPaidLoading] = useState(false);
  const markPaid = async () => {
    try {
      setIsPaidLoading(true);
      await markWithdrawalPaid({
        id: props.withdrawal.id,
      });
      Analytics.logEvent("[A] mark paid", {
        userId: props.withdrawal.userId,
      });
      setIsPaidLoading(false);
      props.onUpdated && props.onUpdated();
    } catch (e) {
      console.log(e);
      setIsPaidLoading(false);
    }
  };
  return (
    <StyledRow key={props.index}>
      <StyledCell>{props.withdrawal.userName}</StyledCell>
      <StyledCell>{props.withdrawal.bankName}</StyledCell>
      <StyledCell>{props.withdrawal.accountNumber}</StyledCell>
      <StyledCell>{props.withdrawal.ifscCode}</StyledCell>
      <StyledCell>{props.withdrawal.amount / 100}</StyledCell>
      <StyledCell>
        <Button isLoading={isPaidLoading} onClick={markPaid}>
          Mark Paid
        </Button>
      </StyledCell>
    </StyledRow>
  );
};

class AdminEmployerWithdrawalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      withdrawals: [],
      hasNext: false,
      hasPrev: false,
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    try {
      let { data: withdrawals, hasNext } = await getWithdrawalRequests(
        "EMPLOYER",
        "CREATED",
        this.state.page,
        this.state.limit
      );
      console.log("withdrawals", withdrawals);
      this.setState({
        withdrawals,
        hasNext,
      });
    } catch (e) {
      console.log(e);
    }
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  render() {
    return (
      <div>
        <Title>Employer Withdrawals</Title>
        <StyledTable>
          <StyledHead>
            <StyledHeadCell>Name</StyledHeadCell>
            <StyledHeadCell>Bank name</StyledHeadCell>
            <StyledHeadCell>Account No</StyledHeadCell>
            <StyledHeadCell>IFSC Code</StyledHeadCell>
            <StyledHeadCell>Amount</StyledHeadCell>
          </StyledHead>
          <StyledBody>
            <div style={{ marginBottom: "32px" }}>
              {this.state.withdrawals.map((withdrawal, index) => (
                <WithdrawalRow
                  key={index}
                  withdrawal={withdrawal}
                  onUpdated={() => {
                    this.onLoad();
                  }}
                />
              ))}
            </div>
          </StyledBody>
        </StyledTable>
        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminEmployerWithdrawalList));
