import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import { getUserDetails, reviewEmployer } from "../api/admin";
import Button from "../components/Button";
import LoadingScreen from "../components/LoadingScreen";
import Entry from "../components/Entry";
import * as Analytics from "../analytics";

class AdminEmployerReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isLoading: false,
      employer: {},
      user: {},
      errors: {},
    };
  }

  async componentDidMount() {
    try {
      this.setState({ isFetching: true });
      let { employer, user } = await getUserDetails(this.props.employerId);
      this.setState({ employer, user, isFetching: false });
    } catch (e) {
      this.setState({
        isFetching: false,
      });
      console.log(e);
    }
  }

  updateEmployerStatus = async (status) => {
    try {
      await reviewEmployer({
        id: this.state.employer.id,
        status,
      });
      if (status === "APPROVED") {
        Analytics.logEvent("[A] click approve employer", {
          employerId: this.state.employer.id,
        });
      } else if (status === "REJECTED") {
        Analytics.logEvent("[A] click reject employer", {
          employerId: this.state.employer.id,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    if (this.state.isFetching) {
      return <LoadingScreen />;
    }
    return (
      <div>
        <Title>Employer Detail</Title>
        <div style={{ display: "flex", marginBottom: "32px" }}>
          <div style={{ paddingRight: "32px" }}>
            <Button
              onClick={() => {
                this.updateEmployerStatus("APPROVED");
              }}
            >
              Reject
            </Button>
          </div>
          <Button
            onClick={() => {
              this.updateEmployerStatus("REJECTED");
            }}
          >
            Accept
          </Button>
        </div>
        <div style={{ maxWidth: 420 }}>
          <Entry
            label={"Job Title"}
            value={
              this.state.employer.jobTitle && this.state.employer.jobTitle.name
            }
          ></Entry>
          <Entry
            label={"Work Email"}
            value={this.state.employer.workEmail}
          ></Entry>
          <Entry
            label={"Company"}
            value={
              this.state.employer.company && this.state.employer.company.name
            }
          ></Entry>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminEmployerReview));
