import React from "react";
import BaseRichTextEditor, { EditorValue, ToolbarConfig } from "react-rte";

const toolbarConfig: ToolbarConfig = {
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS" /*"LINK_BUTTONS"*/],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export type RichTextEditorProps = {
  value?: EditorValue;
  onChange: (val: EditorValue) => void;
  format: "html" | "markdown";
  id?: string;
};

export const RichTextEditor = ({
  value,
  format = "html",
  onChange,
  id = "",
  ...rest
}: RichTextEditorProps) => {
  if (!value) return null;
  return (
    <BaseRichTextEditor
      toolbarConfig={toolbarConfig}
      value={value}
      className={"react-rte"}
      rootStyle={{ borderRadius: 8 }}
      toolbarStyle={{}}
      onChange={onChange}
      {...rest}
    />
  );
};

export const RichTextViewer = ({ value }: { value: string }) => {
  return (
    <BaseRichTextEditor
      className={"richtext-viewer"}
      value={BaseRichTextEditor.createValueFromString(value, "html")}
      readOnly
    />
  );
};

export const createEditorValue = (
  val: string,
  format = "html"
): EditorValue => {
  return val ? createValueFromString(val, format) : createEmptyValue();
};

export const createEmptyValue = BaseRichTextEditor.createEmptyValue;
export const createValueFromString = BaseRichTextEditor.createValueFromString;
