import React from "react";
import { styled } from "baseui";
import { getUser, getWalletHoldingAmount } from "../api/user";
import {
  getWalletTransactions,
  createWalletWithdrawal,
  getWallet,
} from "../api/wallet";
import { createPaymentOrder, verifyPaymentOrder } from "../api/user";
import WalletTransaction from "../components/WalletTransaction";
import Button from "../components/Button";
import Select from "../components/Select";
import Input from "../components/Input";
import Label from "../components/Label";
import { FormControl } from "baseui/form-control";
import { getCurrency, getCurrencyMap } from "../util/currency";
import LoadingScreen from "../components/LoadingScreen";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import { setUser } from "../redux/actions/user";
import Currency from "../components/Currency";
import { connect } from "react-redux";
import { toaster } from "baseui/toast";
import * as Analytics from "../analytics";
import { addCurrencyDecimals } from "../util/formatter";
import { withRouter } from "react-router-dom";
import Popover from "../components/Popover";
import { envConfig } from "../api/endpoints";
import { KIND, SIZE } from "baseui/button";
import { Icon } from "../components/Icon";
import { Block } from "baseui/block";
import Card from "../components/Card";
import {
  LabelLarge,
  LabelMedium,
  LabelSmall,
  ParagraphSmall,
} from "baseui/typography";
import { CenterContainer } from "../components/CenterContainer";
import {
  Button as CButton,
  Box as CBox,
  Text as CText,
} from "@chakra-ui/react";

const EmptyTransaction = styled("div", {
  margin: "32px 0px",
  minHeight: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const AddBalance = styled(Block, {
  marginTop: "16px",
  borderTop: "1px solid #d8d8d8",
  padding: "16px 0px",
});

class UserWallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isLoading: false,
      fetchingBankDetails: false,
      savingWithdrawRequest: false,
      balance: 0,
      bankModalVisible: false,
      currency: getCurrency("INR"),
      userCurrency: "",
      transactions: [],
      walletAmount: 0,
      holdingAmount: 0,
      isFetchingWallet: false,
      amount: 0,
      page: 0,
      hasNext: false,
      hasPrev: false,
      bankValues: {},
      bankErrors: {},
    };
  }
  async componentDidMount() {
    Analytics.logEvent(
      (this.props.user.role === "EMPLOYER" ? "[E] " : "[S] ") +
        "page view user wallet"
    );
    await this.reloadPage();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.workspace.id !== prevProps.workspace.id) {
      await this.reloadPage();
    }
  }

  async reloadPage() {
    this.setState({
      isFetching: true,
    });
    if (this.props.user.role === "EMPLOYER") {
      try {
        let wallet = await getWallet(this.props.workspace.id, "WORKSPACE");
        this.setState({
          walletAmount: wallet.amount || 0,
          amount: 0,
          currency: getCurrency(wallet.currency),
          userCurrency: getCurrency(wallet.currency),
        });
        await this.getTransactionHistory();
      } catch (e) {}
      this.setState({
        isFetching: false,
      });
    } else {
      try {
        let user = await getUser(this.props.user.id);
        this.setState({
          balance: user.walletAmount || 0,
          amount: 0,
          currency: getCurrency(user.walletCurrency),
          userCurrency: getCurrency(user.walletCurrency),
        });
        this.props.setUser({
          walletAmount: user.walletAmount,
          currency: user.walletCurrency,
        });
        await this.getTransactionHistory();
        this.setState({
          isFetching: false,
        });
      } catch (e) {
        this.setState({
          isFetching: false,
        });
        console.log(e);
      }

      this.setState({
        isFetchingWallet: true,
      });
      try {
        let { walletAmount, holdingAmount } = await getWalletHoldingAmount(
          this.props.user.id
        );
        this.setState({
          isFetchingWallet: false,
          holdingAmount: holdingAmount,
          walletAmount: walletAmount,
        });
      } catch (e) {
        this.setState({
          isFetchingWallet: false,
        });
      }
    }
  }

  getTransactionHistory = async () => {
    const isWorkspace = this.props.user.role === "EMPLOYER";
    let { data: transactions, hasNext } = await getWalletTransactions(
      isWorkspace ? this.props.workspace.id : this.props.user.id,
      isWorkspace ? "WORKSPACE" : "USER",
      this.state.page
    );
    this.setState({
      isFetching: false,
      hasNext,
      transactions: transactions,
    });
  };

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.getTransactionHistory();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.getTransactionHistory();
      }
    );
  };

  onAddWalletMoneyClick = async ({ amount, currency }) => {
    Analytics.logEvent(
      (this.props.user.role === "EMPLOYER" ? "[E] " : "[S] ") +
        "click add balance user wallet"
    );
    this.setState({ isLoading: true });
    try {
      let orderResponse = await createPaymentOrder(this.props.user.id, {
        amount: amount * 100,
        currency: currency.id,
      });
      this.setState({
        isLoading: false,
      });
      let razorpayOptions = {
        key: envConfig["razorpay"],
        amount: amount * 100,
        currency: currency.id,
        name: "FindingJo",
        description: "Adding money to FindingJo wallet",
        image:
          "https://storage.googleapis.com/quickhire-stage/assets/favicon.png",
        order_id: orderResponse.id,
        handler: async (response) => {
          console.log("razorpay response", response);
          const isEmployer = this.props.user.role === "EMPLOYER";
          const accountId = isEmployer
            ? this.props.workspace.id
            : this.props.user.id;
          const accountType = isEmployer ? "WORKSPACE" : "USER";
          try {
            await verifyPaymentOrder(this.props.user.id, {
              paymentId: response.razorpay_payment_id,
              orderId: response.razorpay_order_id,
              signature: response.razorpay_signature,
              accountId,
              accountType,
            });
            this.setState({
              isLoading: false,
            });
            await this.reloadPage();
          } catch (e) {
            console.log(e);
          }
        },
        prefill: {
          name: this.props.user.name,
          email: this.props.user.email,
          contact: this.props.user.phone,
        },
        notes: {
          address: "Adding money to wallet",
        },
        theme: {
          color: "black",
        },
      };
      var rzp1 = new window.Razorpay(razorpayOptions);
      rzp1.open();
      // await addWalletMoney({ amount: amount * 100, currency });
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.log(e);
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.state.amount) {
      this.setState({
        amountError: "Please enter an amount",
      });
      return;
    }
    this.onAddWalletMoneyClick(this.state);
  };

  async saveBankDetails() {
    this.setState({
      savingWithdrawRequest: true,
    });
    try {
      let { bankName, accountNumber, ifscCode } = this.state.bankValues;
      await createWalletWithdrawal({
        bankName,
        accountNumber,
        ifscCode,
      });
      this.setState({
        savingWithdrawRequest: false,
        bankModalVisible: false,
      });
      toaster.positive(
        <div>
          We have received your withdrawal request. We will transfer your amount
          to your bank account within 7 working days
        </div>,
        {
          autoHideDuration: 6000,
        }
      );
    } catch (e) {}
    this.setState({
      savingWithdrawRequest: false,
    });
  }

  render() {
    const isBalanceEnabled =
      this.props.user.role !== "EMPLOYER" ||
      (this.props.user.role === "EMPLOYER" &&
        this.props.workspaceMember &&
        this.props.workspaceMember.role === "ADMIN");
    if (this.state.isFetching)
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    return (
      <CenterContainer>
        <Title marginBottom="scale400">{"Your Wallet"}</Title>
        <Card>
          <LabelMedium>Your balance</LabelMedium>
          <div style={{ justifyContent: "flex-end", display: "flex" }}>
            <div style={{ marginRight: "16px" }}>Total Balance</div>
            <div
              style={{
                display: "flex",
                width: "100px",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Currency
                  currency={this.state.currency}
                  value={parseFloat(this.state.walletAmount / 100)}
                />
              </div>
            </div>
          </div>

          {this.props.user.role !== "EMPLOYER" ? (
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <div style={{ marginRight: "16px" }}>
                Money on hold
                <Popover
                  content={
                    <Block padding="scale400" width="300px">
                      <ParagraphSmall>
                        Employer & Candidate have 7 business days to complete
                        first Interview.Talent-Scout can withdraw the earned
                        revenue anytime after 8th business day.
                      </ParagraphSmall>
                    </Block>
                  }
                >
                  <Block as="span" $marginLeft="scale200">
                    <Icon size={16} name="help-circle" wrapper="span" />
                  </Block>
                </Popover>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100px",
                  justifyContent: "flex-end",
                }}
              >
                <Currency
                  currency={this.state.currency}
                  value={parseFloat(this.state.holdingAmount / 100)}
                />
              </div>
            </div>
          ) : null}
          {this.props.user.role !== "EMPLOYER" ? (
            <div style={{ justifyContent: "flex-end", display: "flex" }}>
              <div style={{ marginRight: "16px" }}>Available balance</div>
              <div
                style={{
                  display: "flex",
                  width: "100px",
                  justifyContent: "flex-end",
                }}
              >
                <LabelSmall color="accent">
                  <Currency
                    currency={this.state.currency}
                    value={parseFloat(
                      (this.state.walletAmount - this.state.holdingAmount) / 100
                    )}
                  />
                </LabelSmall>
              </div>
            </div>
          ) : null}

          {isBalanceEnabled && this.props.user.role !== "EMPLOYER" && (
            <div
              style={{
                justifyContent: "flex-end",
                display: "flex",
                marginTop: "16px",
              }}
            >
              <Button
                kind={KIND.tertiary}
                size={SIZE.mini}
                isLoading={this.state.fetchingBankDetails}
                onClick={async () => {
                  this.props.history.push(
                    "/" +
                      (this.props.user.role === "TALENTSCOUT"
                        ? "talentscout"
                        : "employer") +
                      "/wallet/withdraw"
                  );
                }}
              >
                {"Withdraw"}
              </Button>
            </div>
          )}
          <div style={{ display: "flex", justifyContent: "flex-end" }}></div>
          {isBalanceEnabled && (
            <AddBalance>
              <LabelLarge marginBottom="scale800">
                Add money to your wallet
              </LabelLarge>
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <FormControl
                  label={<Label>{"Enter amount"}</Label>}
                  caption={
                    <div style={{ paddingLeft: "84px" }}>
                      {"*2.36% payment gateway charges applicable"}
                    </div>
                  }
                  error={this.state.amountError}
                >
                  <Input
                    id={"amount"}
                    onChange={(e) =>
                      this.setState({
                        amount: e.target.value.split(",").join("")
                          ? +e.target.value.split(",").join("")
                          : "",
                      })
                    }
                    value={
                      addCurrencyDecimals(this.state.amount)
                        ? addCurrencyDecimals(this.state.amount)
                        : ""
                    }
                    autoComplete={"off"}
                    startEnhancer={
                      <Select
                        openOnClick={true}
                        clearable={false}
                        defaultOptions={getCurrencyMap()}
                        onChange={(value) => {
                          this.setState({
                            currency: value,
                          });
                        }}
                        value={this.state.currency}
                      />
                    }
                  />
                </FormControl>
                <CBox display="flex">
                  <CButton
                    mr={4}
                    colorScheme={"orange"}
                    isLoading={this.state.isLoading}
                    type={"submit"}
                  >
                    Add to wallet
                  </CButton>
                  {this.props.user.role === "EMPLOYER" ? (
                    <CBox>
                      <CButton
                        onClick={() => {
                          this.props.history.push("/employer/wallet/sendlink");
                        }}
                      >
                        Send payment link
                      </CButton>
                      <CText color="gray.500" fontSize="s" textAlign="center">
                        with the finance team
                      </CText>
                    </CBox>
                  ) : null}
                </CBox>
              </form>
            </AddBalance>
          )}
        </Card>
        <LabelMedium marginTop="scale800" marginBottom="scale400">
          Your transaction history
        </LabelMedium>
        <Block>
          {this.state.transactions.map((tnx, index) => (
            <WalletTransaction walletTransaction={tnx} key={index} />
          ))}
        </Block>
        {this.state.transactions.length === 0 ? (
          <EmptyTransaction>You dont have any transactions</EmptyTransaction>
        ) : null}
        <Block height="scale600" />
        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
    workspace: state.workspace,
    workspaceMember: state.workspaceMember,
  }),
  { setUser }
)(withRouter(UserWallet));
