import api from "./index";
import { baseUrl } from "./endpoints";

export async function searchEntity(entityName, query) {
  return await api({
    baseUrl,
    url: "/api/entity/" + entityName + "/search",
    method: "GET",
    params: [{ name: "q", value: query }],
  });
}

export async function createEntity(entityName, data) {
  return await api({
    baseUrl,
    url: "/api/entity/" + entityName + "/create",
    method: "POST",
    data,
  });
}

export async function updateEntity(entityName, data) {
  return await api({
    baseUrl,
    url: "/api/entity/" + entityName,
    method: "POST",
    data,
  });
}

export async function updateCompanyLogoURL(data) {
  return await api({
    baseUrl,
    url: "/api/entity/company/logo",
    method: "POST",
    data,
  });
}
