import React from "react";
import { Block } from "baseui/block";

export const RightContainer = ({ children, ...rest }) => (
  <Block
    height="calc(100vh - 50px)"
    overflow="auto"
    right="0px"
    top="50px"
    width={["0px", "0px", "300px"]}
    position="sticky"
    marginLeft={["0px", "0px", "32px"]}
    marginRight={["0px", "0px", "32px"]}
    {...rest}
  >
    {children}
  </Block>
);
