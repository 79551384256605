import { Box, Button } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { Experience } from "src/lib/types/candidate";
import { ExperienceSection } from "./ExperienceSection";

export type ExperienceRow = {
  experience: Experience;
  isNew?: boolean;
};

export type ExperienceListProps = {
  experience: Experience[];
  onAdd: () => void;
  onSave: (ex: Experience[]) => void;
  shouldValidate: boolean;
};

const exToRow = (ex: Experience[]) =>
  (ex || []).map((e) => ({ experience: e }));

export const ExperienceList = ({
  experience: saved,
  onSave,
  onAdd,
  shouldValidate,
}: ExperienceListProps) => {
  const [experienceRows, setExperienceRows] = useState(exToRow(saved || []));

  useEffect(() => {
    setExperienceRows(exToRow(saved));
  }, [saved]);

  return (
    <Box>
      {experienceRows.map((row, idx) => {
        return (
          <ExperienceSection
            key={idx}
            shouldValidate={shouldValidate}
            experienceRow={row}
            onDiscard={() => {
              const updatedRows = experienceRows
                .slice(0, idx)
                .concat(experienceRows.slice(idx + 1));
              setExperienceRows(updatedRows);
            }}
            onDelete={async () => {
              const updatedRows = experienceRows
                .slice(0, idx)
                .concat(experienceRows.slice(idx + 1));
              await onSave(updatedRows.map(({ experience }) => experience));
              setExperienceRows(updatedRows);
            }}
            onSave={async (ex) => {
              const updatedRows = experienceRows
                .slice(0, idx)
                .concat({ experience: ex })
                .concat(experienceRows.slice(idx + 1));
              await onSave(updatedRows.map(({ experience }) => experience));
              setExperienceRows(updatedRows);
            }}
          />
        );
      })}
      <Button
        leftIcon={<FaPlus />}
        variant={"ghost"}
        colorScheme={"blue"}
        size="sm"
        onClick={() => {
          const newEx: ExperienceRow = {
            experience: {},
            isNew: true,
          } as ExperienceRow;
          setExperienceRows(experienceRows.concat([newEx]));
        }}
      >
        Add employment
      </Button>
    </Box>
  );
};
