export const CHANGE_THEME = "CHANGE_THEME";
export const SET_USER = "SET_USER";
export const SET_EMPLOYER = "SET_EMPLOYER";
export const SET_CANDIDATE = "SET_CANDIDATE";
export const SET_TALENTSCOUT = "SET_TALENTSCOUT";
export const SET_WORKSPACE = "SET_WORKSPACE";
export const SET_WALLET = "SET_WALLET";
export const SET_WORKSPACE_MEMBER = "SET_WORKSPACE_MEMBER";
export const SET_WIDGET_STATE = "SET_WIDGET_STATE";
export const SIGNOUT_USER = "SIGNOUT_USER";
