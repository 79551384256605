import React from "react";
import Carousel from "./Carousel";
import { styled } from "styletron-react";

const Container = styled("div", {
  minHeight: "250px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex !important",
  flexDirection: "column",
});

const TutorialCandidate = (props) => (
  <Carousel>
    <Container>
      <img
        alt={""}
        width={"100%"}
        height={"320px"}
        style={{ maxHeight: "320px" }}
        src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/candidate1.svg"
      ></img>
      <div style={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>
        We anonymise your profile
      </div>
      <div
        style={{
          color: "#919191",
          fontSize: "14px",
          marginBottom: "16px",
          textAlign: "center",
        }}
      >
        Anonymised profiles are shown to employers and talent-scouts
      </div>
    </Container>
    <Container>
      <img
        alt={""}
        width={"100%"}
        height={"320px"}
        style={{ maxHeight: "320px" }}
        src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/candidate2.svg"
      ></img>
      <div style={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>
        Get salary offers while staying anonymous
      </div>
      <div
        style={{
          color: "#919191",
          fontSize: "14px",
          marginBottom: "16px",
          textAlign: "center",
        }}
      >
        Employers will send you interview offers based on your anonymous profile
      </div>
    </Container>
    <Container>
      <img
        alt={""}
        width={"100%"}
        height={"320px"}
        src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/candidate3.svg"
      ></img>
      <div style={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>
        Reveal identity only if you want to accept the offer
      </div>
      <div
        style={{
          color: "#919191",
          fontSize: "14px",
          marginBottom: "16px",
          textAlign: "center",
        }}
      >
        Reject the requests and stay anonymous
      </div>
    </Container>
  </Carousel>
);

export default TutorialCandidate;
