import { Link } from "react-router-dom";
import { Button, IconButton } from "@chakra-ui/button";
import { Badge, Box, Divider, Flex, Heading, Text } from "@chakra-ui/layout";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";

import { Textarea } from "@chakra-ui/textarea";
import React, { useEffect, useState } from "react";
import {
  getCandidateFunnelActivity,
  getCandidateFunnelDetails,
  updateCandidateFunnelCandidateNote,
} from "../../api/funnel";
import {
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
  Spinner,
} from "@chakra-ui/react";
import Resume from "../../components/Resume";
import { relativeTime } from "../../util/time";
import { SelectLabel } from "./SelectLabel";
import { AddIcon, EmailIcon } from "@chakra-ui/icons";
import { CandidateLabels } from "./CandidateLabels";
import {
  FiCheck,
  FiGitPullRequest,
  FiHeart,
  FiMoreVertical,
  FiPlusCircle,
  FiSend,
  FiShare,
  FiShare2,
} from "react-icons/fi";
import Currency from "../../components/Currency";
import { addShortlistCandidate, removeShortlistCandidate } from "../../api/job";
import { shareEndpoint } from "../../api/endpoints";
import { CandidateTags } from "src/components/employer/CandidateTags";
import { FaChevronDown } from "react-icons/fa";
import { AddCandidatesToJobModal } from "./AddCandidatesToJobModal";

export type CandidateFunnelActivity = {
  text: string;
  createdByName: string;
  modifiedAt: number;
};

export type CandidateFunnelNote = {
  text: string;
  createdByName: string;
  modifiedAt: number;
};

export type CandidateFunnelModalProps = {
  jobId: string;
  anonymousId: string;
  title: string;
  isModalOpen: boolean;
  onModalClose: () => void;
  labels?: string[];
  laneName?: string;
  lanes?: string[];
  positions?: any;
  laneId?: string;
  onLabelsChange?: (itemId: string, labels: string[]) => void;
  onLaneChange?: (
    itemId: string,
    fromLaneId: string,
    toLaneId: string,
    toPosition: number
  ) => void;
  candidate?: any;
  // funnelItemId?: string;
};

const getStatusTagColorScheme = (status: string) => {
  switch (status) {
    case "CREATED":
    case "REVIEWED":
      return "orange";
    case "APPROVED":
    case "ACCEPTED":
      return "green";
    case "ONHOLD":
    case "REJECTED":
      return "red";
  }
  return "gray";
};

export const CandidateFunnelModal = ({
  jobId,
  title,
  // funnelItemId,
  anonymousId,
  isModalOpen,
  onModalClose,
  onLabelsChange,
  onLaneChange,
  laneName,
  candidate,
  lanes = [],
  laneId,
  labels = [],
  positions = [],
}: CandidateFunnelModalProps) => {
  const [activities, setActivities] = useState<CandidateFunnelActivity[]>([]);
  const [notes, setNotes] = useState<CandidateFunnelNote[]>([]);
  const [isAddingNote, setIsAddingNote] = useState<boolean>(false);
  const [isLaneChanging, setIsLaneChanging] = useState<boolean>(false);
  const [interviewRequests, setInterviewRequests] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isShortlisting, setIsShortlisting] = useState(false);
  const [funnelItem, setFunnelItem] = useState<any>();
  const [candidateUser, setCandidateUser] = useState<any>();
  const [fullCandidate, setFullCandidate] = useState<any>();
  const [activityText, setActivityText] = useState<string>("");
  const [anonymousCandidate, setAnonymousCandidate] = useState<any>(candidate);
  const [isAddToAnotherJobModalOpen, setIsAddToAnotherJobModalOpen] = useState(
    false
  );
  // const fetchActivities = async () => {
  //   try {
  //     const { data } = await getCandidateFunnelActivity(
  //       jobId,
  //       funnelItem.itemId,
  //       0,
  //       1000
  //     );
  //     // setActivities(data);
  //   } catch (e) { }
  // };
  const fetchDetails = async (isSilent?: boolean) => {
    try {
      !isSilent && setIsLoading(true);
      const {
        funnelItem,
        interviewRequests,
        activities,
        anonymousCandidate,
        fullCandidate,
        candidateUser,
        notes,
      } = await getCandidateFunnelDetails(jobId, anonymousId, 0, 1000);
      setAnonymousCandidate(anonymousCandidate);
      setActivities(activities);
      setInterviewRequests(interviewRequests);
      setFullCandidate(fullCandidate);
      setFunnelItem(funnelItem);
      setCandidateUser(candidateUser);
      setNotes(notes);
    } catch (e) {}
    !isSilent && setIsLoading(false);
  };
  const addNote = async () => {
    setIsAddingNote(true);
    try {
      await updateCandidateFunnelCandidateNote(jobId, {
        itemId: funnelItem.id,
        text: activityText,
      });
      setActivityText("");
      await fetchDetails(true);
      // await fetchActivities();
    } catch (e) {}
    setIsAddingNote(false);
  };
  useEffect(() => {
    // if (funnelItemId && jobId) fetchActivities();
    if (anonymousId && jobId) fetchDetails();
  }, [anonymousId, jobId, isModalOpen]);
  return (
    <Modal isOpen={isModalOpen} onClose={onModalClose} size={"6xl"}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex>{title}</Flex>
          {funnelItem && laneName && (
            <Text fontSize="sm" fontWeight="400">
              {`in ${laneName}`}
            </Text>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!isLoading ? (
            <>
              {funnelItem && onLabelsChange && (
                <CandidateLabels
                  isAddEnabled
                  labels={funnelItem.labels || labels}
                  onRemove={(label: string) => {
                    const labels = funnelItem.labels;
                    const idx = labels.indexOf(label);
                    const newLabels = labels
                      .slice(0, idx)
                      .concat(labels.slice(idx + 1));
                    try {
                      onLabelsChange?.(funnelItem.id, newLabels);
                      fetchDetails(true);
                    } catch (e) {}
                  }}
                  onLabelSelect={(label: string) => {
                    if (labels.indexOf(label) < 0) {
                      try {
                        onLabelsChange?.(funnelItem.id, labels.concat([label]));
                        fetchDetails(true);
                      } catch (e) {}
                    }
                  }}
                />
              )}
              <Box mb={2}>
                <CandidateTags candidate={anonymousCandidate} />
              </Box>
              <Flex direction="row">
                <Flex
                  flex={1}
                  padding={3}
                  borderWidth={1}
                  direction="column"
                  background="gray.50"
                >
                  {candidateUser && (
                    <Box
                      borderColor="green.400"
                      borderWidth={1}
                      p={3}
                      mb={3}
                      borderRadius={8}
                    >
                      <Flex direction="row" alignItems="center">
                        <Text fontWeight={500} mr={2}>
                          Name
                        </Text>
                        <Text>{candidateUser.name}</Text>
                      </Flex>
                      <Flex direction="row" alignItems="center">
                        <Text fontWeight={500} mr={2}>
                          Email
                        </Text>
                        <Text>{candidateUser.email}</Text>
                      </Flex>
                      <Flex direction="row" alignItems="center">
                        <Text fontWeight={500} mr={2}>
                          Phone
                        </Text>
                        <Text>{candidateUser.phone}</Text>
                      </Flex>
                    </Box>
                  )}
                  <Resume
                    padding={0}
                    candidate={
                      fullCandidate ? fullCandidate : anonymousCandidate
                    }
                    showCurrentCTC={!!fullCandidate}
                    hideShadow
                  />
                </Flex>
                <Divider orientation="vertical" height="100%" />
                <Flex direction="column" width={280} ml={4}>
                  <Flex mb={4} flexDirection="column">
                    <Flex>
                      {laneId ? (
                        <Menu>
                          {({ isOpen }) => (
                            <>
                              <MenuButton
                                width="100%"
                                mb={2}
                                variant="outline"
                                isActive={isOpen}
                                as={Button}
                                isLoading={isLaneChanging}
                                rightIcon={<FaChevronDown />}
                              >
                                {
                                  positions.find(
                                    (p: any) => p.laneId === laneId
                                  )?.laneName
                                }
                              </MenuButton>
                              <MenuList>
                                {lanes.map((l, idx) => (
                                  <MenuItem
                                    key={idx}
                                    onClick={async () => {
                                      if (laneId) {
                                        setIsLaneChanging(true);
                                        try {
                                          await onLaneChange?.(
                                            funnelItem.id,
                                            laneId,
                                            l,
                                            0
                                          );
                                        } catch (e) {}
                                        setIsLaneChanging(false);
                                      }
                                    }}
                                  >
                                    {
                                      positions.find((p: any) => p.laneId === l)
                                        ?.laneName
                                    }
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </>
                          )}
                        </Menu>
                      ) : (
                        <Button
                          justifyContent="flex-start"
                          leftIcon={
                            funnelItem && funnelItem.laneId !== "bin" ? (
                              <FiCheck color="green" />
                            ) : (
                              <FiHeart />
                            )
                          }
                          width="100%"
                          colorScheme={
                            funnelItem && funnelItem.laneId !== "bin"
                              ? "green"
                              : "gray"
                          }
                          variant={"outline"}
                          mb={2}
                          isLoading={isShortlisting}
                          onClick={async () => {
                            if (funnelItem && funnelItem.laneId !== "bin") {
                              setIsShortlisting(true);
                              try {
                                await removeShortlistCandidate(
                                  jobId,
                                  anonymousCandidate.userId
                                );
                                await fetchDetails(true);
                              } catch (e) {}
                              setIsShortlisting(false);
                              return;
                            } else {
                              setIsShortlisting(true);
                              try {
                                await addShortlistCandidate(jobId, {
                                  jobId,
                                  anonymousId: anonymousCandidate.userId,
                                });
                                await fetchDetails(true);
                              } catch (e) {}
                              setIsShortlisting(false);
                            }
                          }}
                        >
                          {funnelItem && funnelItem.laneId !== "bin"
                            ? "Shortlisted"
                            : "Shortlist"}
                        </Button>
                      )}
                      {/* <a
                        target="_blank"
                        href={
                          shareEndpoint +
                          "/api/share/candidate/" +
                          anonymousCandidate.userId
                        }
                      >
                        <IconButton
                          ml={2}
                          aria-label="Share"
                          icon={<FiShare2 />}
                          variant="outline"
                        />
                      </a> */}
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Options"
                          icon={<FiMoreVertical />}
                          borderRadius="full"
                          variant="ghost"
                        />
                        <MenuList>
                          <MenuItem
                            icon={<FiShare2 />}
                            onClick={() => {
                              const url =
                                shareEndpoint +
                                "/api/share/candidate/" +
                                anonymousCandidate.userId;
                              window?.open(url, "_blank")?.focus();
                            }}
                          >
                            Share
                          </MenuItem>
                          <MenuItem
                            icon={<FiPlusCircle />}
                            onClick={() => {
                              setIsAddToAnotherJobModalOpen(true);
                            }}
                          >
                            Add to another job
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Flex>
                    <Link
                      target="_blank"
                      to={
                        "/employer/create-interview/" +
                        anonymousCandidate.userId +
                        (jobId ? "?jobId=" + jobId : "")
                      }
                    >
                      <Button
                        justifyContent="flex-start"
                        colorScheme="orange"
                        leftIcon={<FiSend />}
                        width="100%"
                      >
                        <Text mr={2}>{"Request Interview"}</Text>
                        <Currency
                          currency={anonymousCandidate.rcAmountCurrency}
                          value={anonymousCandidate.rcAmount / 100}
                        ></Currency>
                      </Button>
                    </Link>
                  </Flex>
                  {/* <Popover placement="bottom-end">
                <PopoverTrigger>
                  <Button mb={4}>Add Label</Button>
                </PopoverTrigger>
                <PopoverContent width={250}>
                  <PopoverCloseButton />
                  <PopoverBody>
                    <SelectLabel
                      labels={labels}
                      onLabelSelect={(label: string) => {
                        if (labels.indexOf(label) < 0) {
                          onLabelsChange(funnelItemId, labels.concat([label]));
                        }
                      }}
                    />
                  </PopoverBody>
                </PopoverContent>
              </Popover> */}
                  {/* <Divider /> */}

                  <Tabs variant="enclosed" colorScheme="orange">
                    <TabList>
                      <Tab fontSize="sm">Notes</Tab>
                      <Tab fontSize="sm">
                        <Text>Interviews</Text>
                        <Badge colorScheme="orange" ml={2}>
                          {interviewRequests.length}
                        </Badge>
                      </Tab>
                      <Tab fontSize="sm">Activity</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        {funnelItem && (
                          <Flex
                            direction="column"
                            p={2}
                            borderWidth={1}
                            mb={2}
                            borderRadius={8}
                            borderColor="gray.200"
                            _focusWithin={{ borderColor: "orange.200" }}
                          >
                            <Textarea
                              placeholder="Write your note here"
                              value={activityText}
                              fontSize="sm"
                              height="50px"
                              minHeight="50px"
                              borderWidth={0}
                              p={0}
                              _focus={{ borderWidth: 0, outline: 0 }}
                              onChange={(e: any) =>
                                setActivityText(e.target.value)
                              }
                            ></Textarea>
                            <Button
                              size={"xs"}
                              alignSelf="start"
                              mt={2}
                              isLoading={isAddingNote}
                              onClick={async () => {
                                await addNote();
                              }}
                            >
                              Add Note
                            </Button>
                          </Flex>
                        )}
                        {notes.map((note, i) => (
                          <>
                            <Flex direction="column" pt={1} pb={1}>
                              <Text fontSize="sm">{note.text}</Text>
                              <Text fontSize="xs" color="gray.400">
                                {note.createdByName}
                              </Text>
                              <Text fontSize="xs" color="gray.400">
                                {relativeTime(Date.now(), note.modifiedAt)}
                              </Text>
                            </Flex>
                            {i <= notes.length - 1 && <Divider />}
                          </>
                        ))}
                      </TabPanel>
                      <TabPanel>
                        {interviewRequests.map((interview: any, i: number) => (
                          <>
                            <Flex direction="column" pt={1} pb={1}>
                              <Flex direction="row" alignItems="center">
                                <Text fontSize="sm" mr={2} fontWeight="500">
                                  Status
                                </Text>
                                <Tag
                                  size="sm"
                                  colorScheme={getStatusTagColorScheme(
                                    interview.status
                                  )}
                                >
                                  <TagLabel letterSpacing={"1.2px"}>
                                    {interview.status}
                                  </TagLabel>
                                </Tag>
                              </Flex>
                              <Flex direction="row" alignItems="center">
                                <Text fontSize="sm" fontWeight="500">
                                  Offered CTC:{" "}
                                </Text>
                                <Currency
                                  currency={interview.offeredCTCCurrency}
                                  value={interview.offeredCTC}
                                />
                              </Flex>
                              {(interview.status === "REJECTED" ||
                                interview.status === "ONHOLD") && (
                                <Flex direction="row" alignItems="center">
                                  <Text fontSize="sm" mr={2} fontWeight="500">
                                    Reason:
                                  </Text>
                                  <Text fontSize="sm">
                                    {interview.statusReason ||
                                      "Rejected by the candidate"}
                                  </Text>
                                </Flex>
                              )}
                              <Text fontSize="xs" color="gray.400">
                                {relativeTime(Date.now(), interview.createdAt)}
                              </Text>
                            </Flex>
                            {i <= interviewRequests.length - 1 && <Divider />}
                          </>
                        ))}
                      </TabPanel>
                      <TabPanel>
                        {activities.map((act, i) => (
                          <>
                            <Flex direction="column" pt={1} pb={1}>
                              <Text fontSize="sm">{act.text}</Text>
                              <Text fontSize="xs" color="gray.400">
                                {act.createdByName}
                              </Text>
                              <Text fontSize="xs" color="gray.400">
                                {relativeTime(Date.now(), act.modifiedAt)}
                              </Text>
                            </Flex>
                            {i <= activities.length - 1 && (
                              <Divider background="gray.50" />
                            )}
                          </>
                        ))}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex alignItems="center" justifyContent="center" minH="100vh">
              <Spinner />
            </Flex>
          )}
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" onClick={onModalClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
      {anonymousCandidate?.userId && (
        <AddCandidatesToJobModal
          isOpen={isAddToAnotherJobModalOpen}
          onClose={() => {
            setIsAddToAnotherJobModalOpen(false);
          }}
          candidates={[{ userId: anonymousCandidate?.userId }]}
          jobId={jobId}
        />
      )}
    </Modal>
  );
};
