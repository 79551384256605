import React from "react";
import { styled } from "baseui";

const Wrapper = styled("div", ({ height, width }) => ({
  borderRadius: height,
  background: "white",
  height: height,
  width: width,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#232323",
  textTransform: "uppercase",
  fontWeight: "500",
}));

const Avatar = ({ name, size }) => {
  return (
    <Wrapper height={size} width={size}>
      <div>{name}</div>
    </Wrapper>
  );
};

export default Avatar;
