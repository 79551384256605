import React from "react";
import PageHeader from "../components/Header";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router";
import SidebarDetails from "../components/SidebarDetails";
import PageWrapper from "../components/PageWrapper";
import SidebarWrapper from "../components/SidebarWrapper";
import AdminCandidateSubmission from "./AdminCandidateSubmission";
import AdminCandidateDetails from "./AdminCandidateDetails";
import AdminInterviewRequestDetails from "./AdminInterviewRequestDetails";
import Container from "../components/Container";
import AdminJobDetails from "./AdminJobDetails";
import AdminUserDetails from "./AdminUserDetails";
import AdminHandPickCandidate from "./AdminHandPickCandidate";
import AdminTSRequestReview from "./AdminTSRequestReview";
import AdminTalentscoutReview from "./AdminTalentscoutReview";
import AdminEmployerReview from "./AdminEmployerReview";
import ShareJobModel from "./ShareJobModel";
import ShareCandidateModel from "./ShareCandidateModel";
import { CenterContainer } from "../components/CenterContainer";
import RCDashboardSearch from "./RCDashboardSearch";
import HandPickedCandidates from "./employer/jobs/HandpickedCandidates";
import { getJobById } from "../api/job";
import { ADMIN_JOBS_LIST } from "../util/routes";
import SidebarAdminCandidateSearch from "../components/SidebarAdminCandidateSearch";
import { AdminTalentscoutJobs } from "./AdminTalentscoutJobs";
import { AdminTalentscoutHandPicked } from "./AdminTalentscoutHandPicked";
import { AdminTalentscoutUploaded } from "./AdminTalentscoutUploaded";
import BinCandidates from "./employer/jobs/BinCandidates";
import CandidateFunnel from "./employer/jobs/CandidateFunnel";

class AdminDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { job: {} };
  }
  renderSidebar() {
    return this.getAdminSidebar();
  }

  async componentDidMount() {
    let { item, itemId } = this.props.match.params;
    if (item === "candidateSearch" || item === "candidateFunnel") {
      const { job } = await getJobById(itemId);
      this.setState({ job });
    }
  }

  getAdminSidebar() {
    let { item, itemId } = this.props.match.params;
    switch (item) {
      case "submission":
        return <SidebarDetails user={this.props.user || {}} />;
      case "candidate":
        return <SidebarDetails user={this.props.user || {}} />;
      case "user":
        return <SidebarDetails user={this.props.user || {}} />;
      case "interview-request":
        return <SidebarDetails user={this.props.user || {}} />;
      case "job":
      case "idproof":
      case "talentscout-review":
      case "employer-review":
        return <SidebarDetails user={this.props.user || {}} />;
      case "candidateSearch":
      case "candidateFunnel":
      case "handpicked":
        return (
          <SidebarAdminCandidateSearch
            user={this.props.user || {}}
            pageId={item}
            jobId={itemId}
            onBack={() => {
              this.props.history.push(ADMIN_JOBS_LIST);
            }}
          />
        );
      case "handpicked-bin":
        return (
          <SidebarAdminCandidateSearch
            user={this.props.user || {}}
            pageId={item}
            jobId={itemId}
            onBack={() => {
              this.props.history.push(ADMIN_JOBS_LIST);
            }}
          />
        );
      default:
        return <div></div>;
    }
  }

  renderPage() {
    return this.getAdminPage();
  }

  getAdminPage() {
    let { item, itemId } = this.props.match.params;
    switch (item) {
      case "submission":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <CenterContainer>
              <AdminCandidateSubmission
                user={this.props.user}
                page={item}
                submissionId={itemId}
              />
            </CenterContainer>
          </PageWrapper>
        );
      case "candidate":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <CenterContainer>
              <AdminCandidateDetails
                user={this.props.user}
                page={item}
                candidateId={itemId}
              />
            </CenterContainer>
          </PageWrapper>
        );
      case "candidateSearch":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <RCDashboardSearch
              user={this.props.user}
              page={item}
              jobId={itemId}
              isAdmin
              job={this.state.job}
            />
          </PageWrapper>
        );
      case "candidateFunnel":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            {this.state.job && this.state.job.company && (
              <CandidateFunnel
                user={this.props.user}
                page={item}
                jobId={itemId}
                isAdmin
                job={this.state.job}
              />
            )}
          </PageWrapper>
        );
      case "handpicked":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <HandPickedCandidates
              user={this.props.user}
              page={item}
              jobId={itemId}
              isAdmin
              job={this.state.job}
            />
          </PageWrapper>
        );
      case "handpicked-bin":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <BinCandidates
              user={this.props.user}
              page={item}
              jobId={itemId}
              isAdmin
              job={this.state.job}
            />
          </PageWrapper>
        );
      case "pick-jobs":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <AdminHandPickCandidate
              user={this.props.user}
              page={item}
              candidateId={itemId}
              isAdmin
            />
          </PageWrapper>
        );
      case "user":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <CenterContainer>
              <AdminUserDetails
                user={this.props.user}
                page={item}
                userId={itemId}
              />
            </CenterContainer>
          </PageWrapper>
        );
      case "interview-request":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <CenterContainer>
              <AdminInterviewRequestDetails
                user={this.props.user}
                page={item}
                interviewRequestId={itemId}
              />
            </CenterContainer>
          </PageWrapper>
        );
      case "job":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <CenterContainer>
              <AdminJobDetails
                user={this.props.user}
                page={item}
                jobId={itemId}
              />
            </CenterContainer>
          </PageWrapper>
        );
      case "idproof":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <CenterContainer>
              <AdminTSRequestReview
                user={this.props.user}
                page={item}
                userId={itemId}
              />
            </CenterContainer>
          </PageWrapper>
        );
      case "talentscout-review":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <CenterContainer>
              <AdminTalentscoutReview
                user={this.props.user}
                page={item}
                talentscoutId={itemId}
              />
            </CenterContainer>
          </PageWrapper>
        );
      case "talentscout-jobs":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <CenterContainer>
              <AdminTalentscoutJobs
                user={this.props.user}
                page={item}
                talentscoutId={itemId}
              />
            </CenterContainer>
          </PageWrapper>
        );
      case "talentscout-uploaded":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <CenterContainer>
              <AdminTalentscoutUploaded
                user={this.props.user}
                page={item}
                talentscoutId={itemId}
              />
            </CenterContainer>
          </PageWrapper>
        );
      case "talentscout-handpicked":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <CenterContainer>
              <AdminTalentscoutHandPicked
                user={this.props.user}
                page={item}
                talentscoutId={itemId}
              />
            </CenterContainer>
          </PageWrapper>
        );
      case "employer-review":
        return (
          <PageWrapper>
            <SidebarWrapper>{this.renderSidebar()}</SidebarWrapper>
            <CenterContainer>
              <AdminEmployerReview
                user={this.props.user}
                page={item}
                employerId={itemId}
              />
            </CenterContainer>
          </PageWrapper>
        );

      default:
        return <div></div>;
    }
  }

  render() {
    let props = this.props;
    let { role } = this.props.user;
    let { page } = this.props.match.params;
    return (
      <Container>
        {this.renderPage()}

        <PageHeader
          role={role}
          page={page}
          user={props.user}
          setUser={props.setUser}
        />
        <ShareCandidateModel />
        <ShareJobModel />
      </Container>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminDetails));
