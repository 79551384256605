import React, {
  Suspense,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import firebase from "firebase";

const LazyFileUploader = React.lazy(() => import("./FilePond"));

const FileUploader = forwardRef(
  ({ uploadUrl, isImmediate, onSuccess, onError, ...rest }, ref) => {
    const [uploadFile, setUploadFile] = useState();
    const [uploadProgress, setUploadProgress] = useState(-1);
    const [uploadError, setUploadError] = useState("");
    const [fileExtension, setFileExtension] = useState("");
    const getUploadFileName = () => {
      if (fileExtension) {
        return uploadUrl + "." + fileExtension;
      }
      return uploadUrl;
    };
    const upload = () => {
      if (!uploadFile) {
        onError && onError("Please attach the file");
        return;
      }
      const storageRef = firebase.storage().ref();
      const uploadTask = storageRef.child(getUploadFileName()).put(uploadFile);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          setUploadProgress(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              break;
            case firebase.storage.TaskState.RUNNING:
              break;
            default:
          }
        },
        (error) => {
          setUploadProgress(-1);
          setUploadError("Upload failed. Please try again.");
        },
        async () => {
          setUploadProgress(-1);
          setUploadError();
          try {
            await onSuccess(await uploadTask.snapshot.ref.getDownloadURL());
            setUploadFile(null);
          } catch (e) {
            onError && onError(e);
          }
        }
      );
    };

    useEffect(() => {
      if (!(uploadFile && fileExtension)) {
        return;
      }
      if (isImmediate) {
        upload(uploadFile);
      }
    }, [uploadFile, fileExtension]);

    useImperativeHandle(ref, () => ({
      upload,
    }));

    return (
      <div>
        {uploadProgress >= 0 && (
          <div style={{ textAlign: "center", color: "#0F4C81" }}>
            {"Uploading... " + uploadProgress}
          </div>
        )}
        {uploadError && (
          <div>Error occured during upload. Please try uploading again.</div>
        )}
        <Suspense fallback={<div>Loading...</div>}>
          <LazyFileUploader
            files={uploadFile ? [uploadFile] : []}
            placeholder={"Drag n drop here"}
            onError={(error) => {
              setUploadError(error.main);
              onError && onError(error.main);
            }}
            onUpdated={(files, file) => {
              setUploadFile(files[0]);
              setFileExtension(file.fileExtension);
            }}
            {...rest}
          />
        </Suspense>
      </div>
    );
  }
);

export default FileUploader;
