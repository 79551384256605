import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Button from "../components/Button";
import Modal from "../components/Modal";
import { EMPLOYER_PROFILE_REVIEW } from "../util/routes";
import Title from "../components/Title";
import { getWorkspaces } from "../api/employer";
import HiringWorkspaceForm from "./HiringWorkspaceForm";
import { ModalBody, ModalHeader } from "baseui/modal";
import AddSection from "../components/AddSection";
import Popover from "../components/Popover";
import Spacing from "../components/Spacing";
import WorkspaceSummary from "../components/WorkspaceSummary";
import { TRIGGER_TYPE } from "baseui/popover";
import { Block } from "baseui/block";
import { CenterContainer } from "../components/CenterContainer";
import { toaster } from "baseui/toast";

const CreateWorkspace = (props) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  return (
    <div>
      <AddSection
        onClick={() => {
          setIsFormVisible(true);
        }}
        text={"Create Hiring Workspace"}
      />
      <Modal
        animate
        autoFocus
        isOpen={isFormVisible}
        closeable
        onClose={() => {
          setIsFormVisible(false);
        }}
      >
        <ModalHeader>
          <Title>Create Hiring Workspace</Title>
        </ModalHeader>
        <ModalBody>
          <HiringWorkspaceForm
            onCreate={async () => {
              setIsFormVisible(false);
              await props.onCreate();
            }}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

const EmployerHiringWorkspace = (props) => {
  const [workspaces, setWorkspaces] = useState([]);
  const [memberships, setMemberships] = useState({});
  const getEmployerWorkspaces = async () => {
    try {
      const { data, memberships } = await getWorkspaces();
      setWorkspaces(data);
      setMemberships(memberships);
    } catch (e) {}
  };

  useEffect(() => {
    getEmployerWorkspaces();
  }, []);

  return (
    <CenterContainer>
      <Title>
        <div>Hiring Workspaces</div>
        <div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                fontSize: "12px",
                color: "#515151",
              }}
            >
              <span>
                Add your recruitment team members to your Hiring Workspace.
              </span>
              <Popover
                triggerType={TRIGGER_TYPE.hover}
                content={
                  <div
                    style={{
                      maxWidth: 420,
                      padding: "16px",
                      fontSize: ".8rem",
                    }}
                  >
                    <strong>Hiring Workspace</strong>
                    <div>
                      Add your recruitment team members to your Hiring
                      Workspace.{" "}
                    </div>
                    <br />
                    <div>
                      Your Hiring Workspace can have any number of admins and
                      members
                    </div>
                    <br />
                    <div>
                      <strong>Admin</strong> can add money and edit members.
                    </div>
                    <br />
                    <div>
                      <strong>Add Money</strong> - Admin can add money to the
                      Workspace wallet.
                    </div>
                    <div>
                      <strong>Add/Edit Members</strong> - Admin can
                      add/edit/delete members from a Workspace
                    </div>
                    <br />
                    <div>
                      Tip: Add a finance team member as one of the admins, who
                      can add money whenever required
                    </div>
                    <br />
                    <div>
                      <strong>Admin & Members</strong> can post jobs & book an
                      interview
                    </div>
                    <br />
                    <div>
                      <strong>Job posting (Free)</strong>- Jobs posted by any
                      Workspace member are visible to every other Workspace
                      member
                    </div>
                    <br />
                    <div>
                      <strong>Book an interview</strong> - any Workspace member
                      can book an interview using the money in the Workspace
                      wallet
                    </div>
                  </div>
                }
              >
                <div
                  style={{
                    cursor: "pointer",
                  }}
                >
                  More details
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </Title>
      {workspaces.map((workspace, idx) => (
        <WorkspaceSummary
          workspace={workspace}
          membership={memberships[workspace.id]}
          isFirst={idx === 0}
          isLast={idx === workspaces.length - 1}
          onEdit={async () => await getEmployerWorkspaces()}
        />
      ))}
      <Block marginTop="scale400">
        <CreateWorkspace onCreate={async () => await getEmployerWorkspaces()} />
      </Block>
      <Spacing size={32} />
      <Button
        onClick={() => {
          if (workspaces.length === 0) {
            toaster.negative(
              <div>Please create a hiring workspace to proceed</div>,
              {
                autoHideDuration: 3000,
              }
            );
            return;
          }
          let employerOnboarding = props.user.employerOnboarding || [];
          if (employerOnboarding.indexOf("workspace") === -1) {
            employerOnboarding.push("workspace");
          }
          props.setUser({
            employerOnboarding,
          });
          props.history.push(EMPLOYER_PROFILE_REVIEW);
        }}
      >
        Continue
      </Button>
    </CenterContainer>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(EmployerHiringWorkspace));
