import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import { Column, useTable } from "react-table";
import { getUserList, updateUser, UserProps } from "src/api/userT";
import Pagination from "src/lib/components/Pagination";
import { UpdateUserModal, UpdateUserModalProps } from "./UpdateUserModal";
export const AllUsers = () => {
  const [users, setUsers] = useState<UserProps[]>([]);
  const [email, setEmail] = useState("");
  const [page, setPage] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const limit = 10;
  const onUpdateModalClose = () => {
    setUpdateUserModalProps({
      ...updateUserModalProps,
      isOpen: false,
    });
  };
  const toast = useToast();
  const onUpdateModalAction = async (values: UserProps) => {
    try {
      if (values.id) {
        await updateUser(values.id, { roles: values.roles });
        await fetchAllUsers();
      }
    } catch (e) {
      toast({
        title: "Error while saving user",
      });
    }
  };
  const [updateUserModalProps, setUpdateUserModalProps] = useState<
    UpdateUserModalProps
  >({
    isOpen: false,
    onClose: onUpdateModalClose,
    onActionClick: onUpdateModalAction,
  });
  useEffect(() => {
    fetchAllUsers();
  }, [page]);
  const fetchAllUsers = async () => {
    setIsFetching(true);
    try {
      const { data, hasNext } = await getUserList({ email, page, limit });
      setUsers(data);
      setHasNext(hasNext);
    } catch (e) {}
    setIsFetching(false);
  };
  const data = useMemo(() => users, [users]);
  const columns = useMemo<Column<UserProps>[]>(
    () => [
      {
        Header: "Email",
        accessor: ({ email }) => {
          return <Text textStyle="h4">{email}</Text>;
        },
      },
      { Header: "Phone", accessor: "phone" },
      {
        Header: "Roles",
        accessor: ({ roles }) => {
          return <Text color={"gray"}>{(roles || []).join(",")}</Text>;
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: () => <FiArrowRight />,
      },
    ],
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });
  return (
    <Box py={10}>
      <Text textStyle="h1" ml={4} mb={6}>
        All Users
      </Text>
      <Flex mt={4} ml={4}>
        <InputGroup width={"300px"}>
          <InputLeftElement
            pointerEvents="none"
            children={<SearchIcon color="gray.300" />}
          />
          <Input
            placeholder="Enter email"
            mr={2}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setPage(0);
                fetchAllUsers();
              }
            }}
          ></Input>
        </InputGroup>
      </Flex>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} borderBottomWidth={2}>
              {headerGroup.headers.map((column) => (
                <Th textTransform={"none"} {...column.getHeaderProps()}>
                  <Text textStyle={"label"}>{column.render("Header")}</Text>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {!isFetching &&
            rows.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  _hover={{ background: "gray.50" }}
                  cursor="pointer"
                  onClick={() => {
                    setUpdateUserModalProps({
                      ...updateUserModalProps,
                      isOpen: true,
                      selectedUser: row.original,
                    });
                  }}
                >
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      {isFetching && (
        <Flex height="200px" alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      )}
      {!isFetching && (
        <Box mt={4}>
          <Pagination page={page} setPage={setPage} hasNext={hasNext} />
        </Box>
      )}
      <UpdateUserModal {...updateUserModalProps} />
    </Box>
  );
};
