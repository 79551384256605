import React, { useState, useRef } from "react";
import Modal from "../components/Modal";
import Title from "../components/Title";
import Button from "../components/Button";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import FileUploader from "../components/FileUploader";
import { toaster } from "baseui/toast";
import { setUser } from "../redux/actions/user";
import { connect } from "react-redux";
import { updateCompanyLogoURL } from "../api/entity";
import Bold from "../components/Bold";

const UploadLogoModal = ({
  isUploadLogoModalVisible,
  company,
  onClose,
  onSuccess,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const fileUploaderRef = useRef();
  return (
    <Modal
      animate
      autoFocus
      isOpen={isUploadLogoModalVisible}
      closeable
      onClose={() => {
        onClose && onClose();
      }}
    >
      <ModalHeader>
        <Title>{"Upload Logo"}</Title>
      </ModalHeader>
      <ModalBody>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <FileUploader
            ref={fileUploaderRef}
            shape={"circle"}
            width={250}
            height={250}
            uploadUrl={"company_logos/" + company.id + "_" + Date.now()}
            onError={(error) => {
              setIsUploading(false);
              toaster.negative(<div>{error}</div>, {
                autoHideDuration: 2000,
              });
            }}
            onSuccess={async (downloadUrl) => {
              if (!company.isCreatable) {
                await updateCompanyLogoURL({
                  companyId: company.id,
                  url: downloadUrl,
                });
              }
              setIsUploading(false);
              onSuccess && onSuccess(downloadUrl);
            }}
          />
        </div>
        <div
          style={{
            background: "#F1F1F1",
            padding: "16px",
            borderRadius: "4px",
            marginTop: "16px",
          }}
        >
          <Bold>Please note:</Bold>
          <ul style={{ paddingLeft: "16px", marginTop: "8px" }}>
            <li>
              <div>Upload transparent PNG image of equal height and width</div>
            </li>
            <li>
              <div>Sample image</div>
            </li>
            <div
              style={{
                height: "64px",
                width: "64px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#FFFFFF",
              }}
            >
              <div style={{ fontSize: "75px", fontWeight: "600" }}>O</div>
            </div>
            <li>
              <div>Max file size: 2MB</div>
            </li>
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            fileUploaderRef.current.upload();
            setIsUploading(true);
          }}
          isLoading={isUploading}
        >
          Upload Logo
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(UploadLogoModal);
