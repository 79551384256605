import React from "react";
import Input from "./Input";
import Select from "./Select";
import { addCurrencyDecimals } from "../util/formatter";
import { getCurrencyWords, getCurrencyMap } from "../util/currency";

const SalaryInput = ({
  name,
  value,
  currencyValue,
  onChange,
  onCurrencyChange,
}) => {
  return (
    <>
      <Input
        id={name}
        onChange={(e) => {
          onChange(
            e.target.value.split(",").join("")
              ? +e.target.value.split(",").join("")
              : 0
          );
        }}
        value={addCurrencyDecimals(value)}
        autoComplete={"off"}
        startEnhancer={
          <Select
            searchable={false}
            clearable={false}
            openOnClick={true}
            defaultOptions={getCurrencyMap()}
            onChange={(value) => {
              onCurrencyChange(value);
            }}
            value={currencyValue}
          />
        }
        endEnhancer={
          <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
            {getCurrencyWords(value) + " per year"}
          </span>
        }
      />
    </>
  );
};

export default SalaryInput;
