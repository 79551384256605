import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import {
  reviewInterviewRequest,
  getInterviewRequestDetail,
} from "../api/admin";
import Button from "../components/Button";
import Job from "../components/Job";
import LoadingScreen from "../components/LoadingScreen";
import { toaster } from "baseui/toast";
import FormControl from "../components/FormControl";
import Label from "../components/Label";
import EmptyCaption from "../components/EmptyCaption";
import TextArea from "../components/TextArea";
import Card from "../components/Card";
import Currency from "../components/Currency";
import Entry from "../components/Entry";
import * as Analytics from "../analytics";
import { LabelLarge, ParagraphSmall } from "baseui/typography";
import { updateInterviewRequestNew } from "src/api/interview_request";
import { onLoad } from "@sentry/react";

class AdminInterviewRequestDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      interviewRequest: "",
      job: "",
      errors: {},
    };
  }

  async onLoad() {
    try {
      let {
        interviewRequest,
        job,
        candidateUser,
        employerUser,
        talentscoutUser,
        candidate,
      } = await getInterviewRequestDetail(this.props.interviewRequestId);
      this.setState({
        interviewRequest,
        job,
        candidateUser,
        employerUser,
        talentscoutUser,
        candidate,
      });
      this.setState({ interviewRequest });
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    await this.onLoad();
  }

  onReviewInterviewRequest = async (status) => {
    try {
      await reviewInterviewRequest(this.state.interviewRequest.id, status, {
        statusReason: this.state.statusReason || "",
      });
      toaster.positive(<div>{"updated"}</div>, { autoHideDuration: 3000 });
      this.props.history.goBack();
    } catch (e) {
      console.log(e);
    }
  };

  onApproveClick = async () => {
    await this.onReviewInterviewRequest("REVIEWED");
    Analytics.logEvent("[A] approve interview request", {
      interviewRequestId: this.state.interviewRequest.id,
      candidateId: this.state.candidateUser.id,
      employerId: this.state.employerUser?.id,
      companyName: this.state.job.company.name,
      jobId: this.state.job.id,
      offerCTC: this.state.interviewRequest.offeredCTC,
      offerCTCCurrency: this.state.interviewRequest.offeredCTCCurrency,
    });
  };

  onRejectClick = async () => {
    if (!this.state.statusReason) {
      this.setState({
        statusReasonError: "Please add some comments if you are rejecting",
      });
      return;
    } else {
      this.setState({
        statusReasonError: "",
      });
    }
    await this.onReviewInterviewRequest("ONHOLD");
    Analytics.logEvent("[A] reject interview request", {
      interviewRequestId: this.state.interviewRequest.id,
      candidateId: this.state.candidateUser.id,
      employerId: this.state.employerUser?.id,
      companyName: this.state.job.company.name,
      jobId: this.state.job.id,
      offerCTC: this.state.interviewRequest.offeredCTC,
      offerCTCCurrency: this.state.interviewRequest.offeredCTCCurrency,
    });
  };

  onApproveNewClick = async () => {
    try {
      await updateInterviewRequestNew(this.state.interviewRequest.id, {
        status: "APPROVED",
        statusMessage: this.state.statusReason,
      });
      toaster.positive(<div>{"updated"}</div>, { autoHideDuration: 3000 });
      await this.onLoad();
    } catch (e) {
      console.log(e);
    }
  };

  onRejectNewClick = async () => {
    if (!this.state.statusReason) {
      this.setState({
        statusReasonError: "Please add some comments if you are rejecting",
      });
      return;
    }
    try {
      await updateInterviewRequestNew(this.state.interviewRequest.id, {
        status: "REJECTED",
        statusMessage: this.state.statusReason,
      });
      toaster.positive(<div>{"updated"}</div>, { autoHideDuration: 3000 });
      await this.onLoad();
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    if (!this.state.job) {
      return <LoadingScreen />;
    }
    return (
      <div>
        <Title>Interview Request Detail</Title>
        <div>
          {this.state.interviewRequest &&
            this.state.interviewRequest.status === "CREATED" && (
              <div>
                <div style={{ display: "flex", marginBottom: "32px" }}>
                  <div style={{ paddingRight: "32px" }}>
                    <Button
                      isLoading={this.state.isLoading}
                      onClick={this.onRejectClick}
                    >
                      Reject
                    </Button>
                  </div>
                  <Button
                    isLoading={this.state.isLoading}
                    onClick={this.onApproveClick}
                  >
                    Accept
                  </Button>
                </div>
                {/* <div style={{ display: "flex", marginBottom: "32px" }}>
                  <div style={{ paddingRight: "32px" }}>
                    <Button
                      isLoading={this.state.isLoading}
                      onClick={this.onRejectNewClick}
                    >
                      Reject New
                    </Button>
                  </div>
                  <Button
                    isLoading={this.state.isLoading}
                    onClick={this.onApproveNewClick}
                  >
                    Accept New
                  </Button>
                </div> */}
                <FormControl
                  label={<Label>{"Any comments"}</Label>}
                  caption={<EmptyCaption />}
                  error={this.state.statusReasonError}
                >
                  <TextArea
                    placeholder={""}
                    onChange={(e) => {
                      if (typeof e.target.value !== "undefined") {
                        this.setState({
                          statusReason: e.target.value,
                        });
                      }
                    }}
                    value={this.state.statusReason}
                    autoComplete={"off"}
                  />
                </FormControl>
              </div>
            )}
          <Card>
            <Entry
              label={"Employer Name"}
              value={this.state.employerUser?.name}
            />
            <Entry label={"Company Name"} value={this.state.job.company.name} />
            <Entry
              label={"Candidate Name"}
              value={this.state.candidateUser.name}
            />
            <Entry
              label={"Expected CTC"}
              value={
                <Currency
                  currency={this.state.candidate.expectedCTCCurrency}
                  value={this.state.candidate.expectedCTC}
                />
              }
            />
            <Entry
              label={"Offered CTC"}
              value={
                <Currency
                  currency={this.state.interviewRequest.offeredCTCCurrency}
                  value={this.state.interviewRequest.offeredCTC}
                />
              }
            />
          </Card>
          {this.state.talentscoutUser && (
            <Card marginBottom="scale600" marginTop="scale600">
              <LabelLarge marginBottom={"scale400"}>
                Talentscout Details
              </LabelLarge>
              <ParagraphSmall>{`Name: ${this.state.talentscoutUser.name}`}</ParagraphSmall>
              <ParagraphSmall>{`Email: ${this.state.talentscoutUser.email}`}</ParagraphSmall>
              <ParagraphSmall>{`Phone: ${this.state.talentscoutUser.phone}`}</ParagraphSmall>
              <ParagraphSmall>{`LinkedIn: ${this.state.talentscoutUser.linkedInProfile}`}</ParagraphSmall>
            </Card>
          )}
          <Title>Job Details</Title>
          <Job job={this.state.job}>{this.state.job.id}</Job>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminInterviewRequestDetails));
