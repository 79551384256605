import {
  Box,
  FormErrorMessage as CFormErrorMessage,
  FormErrorMessageProps as CFormErrorMessageProps,
} from "@chakra-ui/react";
import React from "react";

export type FormErrorMessageProps = CFormErrorMessageProps;

export const FormErrorMessage = (props: FormErrorMessageProps) => {
  return (
    <Box minH="34px" pt={1}>
      <CFormErrorMessage {...props} />
    </Box>
  );
};
