import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import { getEmployer, updateEmployerWork } from "../api/employer";
import EmptyCaption from "../components/EmptyCaption";
import Select from "../components/Select";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import Button from "../components/Button";
import { searchEntity } from "../api/entity";
import { FormControl } from "baseui/form-control";
import Label from "../components/Label";
import { EMPLOYER_PROFILE_WORKSPACE } from "../util/routes";
import Title from "../components/Title";
import * as Analytics from "../analytics";
import { CenterContainer } from "../components/CenterContainer";

class UserHMDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      values: {
        jobTitle: "",
        hiringFor: 1,
        workEmail: "",
      },
      errors: {},
    };
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && !this.state.values[id]) {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let errors = {};
    ["jobTitle"].forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });
    // if (!isErrorFound && this.state.values.hiringFor === 1) {
    //   ["workEmail", "company"].forEach((id) => {
    //     let validationError = this.validate(id, this.getValidations(id));
    //     if (validationError) {
    //       errors[id] = validationError;
    //       isErrorFound = true;
    //     }
    //   });
    // }
    if (isErrorFound) {
      this.setState({
        errors,
      });
    } else {
      console.log(this.state.values);
      await this.onContinue(this.state.values);
    }
  };

  getValidations(id) {
    switch (id) {
      case "jobTitle":
      case "company":
        return { requiredSelect: true };
      case "linkedInProfile":
      case "workEmail":
      case "phone":
        return { required: true };
      default:
        return {};
    }
  }

  async componentDidMount() {
    Analytics.logEvent("[E] page view profile details");
    this.setState({
      isFetching: false,
    });
    try {
      let employer = (await getEmployer(this.props.user.id)) || {};
      this.setState({
        values: {
          jobTitle: employer.jobTitle || "",
          workEmail: employer.workEmail || this.props.user.email || "",
          hiringFor: employer.hiringFor || 1,
          company: employer.company,
        },
      });
    } catch (ex) {
      console.log(ex);
    }
    this.setState({
      isFetching: false,
    });
  }

  onContinue = async (values) => {
    try {
      this.setState({
        isLoading: true,
      });
      await updateEmployerWork({
        ...values,
      });
      this.setState({
        isLoading: false,
      });
      let employerOnboarding = this.props.user.employerOnboarding || [];
      if (employerOnboarding.indexOf("work") === -1) {
        employerOnboarding.push("work");
      }
      this.props.setUser({
        employerOnboarding,
      });
      this.props.history.push(EMPLOYER_PROFILE_WORKSPACE);
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.log(e);
      alert("Error while saving. Please retry");
    }
  };

  render() {
    return (
      <CenterContainer>
        <Title>Tell us about you</Title>
        <div>
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <FormControl
              label={<Label>{"You are hiring for:"}</Label>}
              caption={<EmptyCaption />}
              error={this.state.errors["hiringFor"]}
            >
              <RadioGroup
                value={this.state.values.hiringFor}
                onChange={(e) => {
                  this.updateFormValues("hiringFor", +e.target.value);
                }}
                align={ALIGN.vertical}
              >
                <Radio value={1}>
                  <div>A Company</div>
                  <div>
                    <div style={{ color: "#919191" }}>
                      For example, HR/HiringManger/Company recruiter
                    </div>
                  </div>
                </Radio>
                <Radio value={2}>
                  <div>Client Companies</div>
                  <div>
                    <div style={{ color: "#919191" }}>
                      Recruitment Agencies/Freelance recruiter
                    </div>
                  </div>
                </Radio>
              </RadioGroup>
            </FormControl>
            <FormControl
              label={<Label>{"Enter your job title"}</Label>}
              caption={<EmptyCaption />}
              error={this.state.errors["jobTitle"]}
            >
              <Select
                creatable
                type={"search"}
                getOptions={async (query) => searchEntity("jobTitle", query)}
                onChange={(value) => {
                  this.updateFormValues("jobTitle", value);
                }}
                value={this.state.values["jobTitle"]}
              />
            </FormControl>
            {/* {this.state.values.hiringFor === 1 ? (
              <FormControl
                label={<Label>{"Enter your company name"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["company"]}
              >
                <Select
                  creatable
                  type={"search"}
                  getOptions={async (query) => searchEntity("company", query)}
                  onChange={(value) => {
                    this.updateFormValues("company", value);
                  }}
                  value={this.state.values["company"]}
                />
              </FormControl>
            ) : null} */}
            {/* {this.state.values.hiringFor === 1 ? (
              <FormControl
                label={
                  <Label
                    subtitle={
                      "You will only be able to post jobs if your work email matches the company."
                    }
                  >
                    {"Enter work email"}
                  </Label>
                }
                caption={<EmptyCaption />}
                error={this.state.errors["workEmail"]}
              >
                <Input
                  id={"workEmail"}
                  onChange={(e) =>
                    this.updateFormValues("workEmail", e.target.value)
                  }
                  value={this.state.values["workEmail"]}
                  autoComplete={"off"}
                  onBlur={(e) =>
                    this.updateFormErrors(
                      "workEmail",
                      this.validate(
                        "workEmail",
                        this.getValidations("workEmail")
                      )
                    )
                  }
                />
              </FormControl>
            ) : null} */}

            <Button isLoading={this.state.isLoading} type={"submit"}>
              Save and Continue
            </Button>
          </form>
        </div>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(UserHMDetails));
