import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setUser } from "../redux/actions/user";
import Button from "../components/Button";
import FormControl from "../components/FormControl";
import Select from "../components/Select";
import { RichTextEditor } from "../components/RichTextEditor";
import Input from "../components/Input";
import Label from "../components/Label";
import { searchEntity } from "../api/entity";
import SalaryInput from "./SalaryInput";
import UploadLogoModal from "../screens/UploadLogoModal";
import FlexRow from "./FlexRow";
import { KIND as BUTTON_KIND } from "baseui/button";
import { LabelMedium, ParagraphSmall } from "baseui/typography";
import { Block } from "baseui/block";
import { createValueFromString } from "src/lib/components/RichTextEditor";

const PostJobForm = (props) => {
  const setJobData = () => {
    props.job.jobDescriptionEditor = createValueFromString(
      props.job.jobDescription || "",
      "html"
    );
    props.job.recruiterNoteEditor = createValueFromString(
      props.job.recruiterNote || "",
      "html"
    );
    return props.job;
  };
  const [job, setJob] = useState(setJobData());
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isUploadLogoModalVisible, setIsUploadLogoModalVisible] = useState(
    false
  );

  useEffect(() => {
    setJob(setJobData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.job.company)]);

  const updateFormErrors = (id, error) => {
    setFormErrors({ ...formErrors, ...{ [id]: error } });
  };

  const updateFormValues = (id, value) => {
    setJob({ ...job, ...{ [id]: value } });
  };

  const validate = (id, validations = {}) => {
    if (validations.required && !job[id]) {
      return "Required field";
    }
    if (validations.requiredArray && (!job[id] || !job[id].length)) {
      return "Required field";
    }
  };

  const getValidations = (id) => {
    switch (id) {
      case "jobDescription":
        return { required: true };
      case "company":
      case "jobTitle":
        return { requiredSelect: true };
      case "locations":
      case "skills":
      case "functionalAreas":
        return { requiredArray: true };
      default:
        return "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let errors = {};
    ["company", "jobTitle", "functionalAreas", "locations"].forEach((id) => {
      let validationError = validate(id, getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });

    if (!isErrorFound) {
      if (
        (job.targetCompanies || []).length > 0 &&
        (job.targetCompanies || []).length < 3
      ) {
        errors["targetCompanies"] = "Minimum 3 required";
        isErrorFound = true;
      }
    }

    if (isErrorFound) {
      setFormErrors(errors);
    } else {
      job.jobDescription = job.jobDescriptionEditor.toString("html");
      job.recruiterNote = job.recruiterNoteEditor.toString("html");
      await onContinue(job);
    }
  };

  const onContinue = async (job) => {
    setIsLoading(true);
    await props.onSubmit(job);
    setIsLoading(false);
  };

  return (
    <div style={{ maxWidth: "420px", marginBottom: "32px" }}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <FormControl
          label={<Label>{"Enter company name"}</Label>}
          error={formErrors["company"]}
        >
          <div>
            <Select
              placeholder={"Select Company"}
              creatable
              getOptions={async (query) => searchEntity("company", query)}
              onChange={(value) => {
                updateFormValues("company", value);
              }}
              value={job["company"]}
            />
            {job.company &&
              (job.company.icon ? (
                <div style={{ marginTop: "8px" }}>
                  <FlexRow>
                    <img
                      height={"32px"}
                      width={"32px"}
                      alt={""}
                      src={job.company.icon}
                    />
                    <Button
                      kind={BUTTON_KIND.tertiary}
                      type={"button"}
                      onClick={(e) => {
                        setIsUploadLogoModalVisible(true);
                      }}
                    >
                      Change Logo
                    </Button>
                  </FlexRow>
                </div>
              ) : (
                <div style={{ marginTop: "8px" }}>
                  <Button
                    kind={BUTTON_KIND.tertiary}
                    type={"button"}
                    onClick={(e) => {
                      setIsUploadLogoModalVisible(true);
                    }}
                  >
                    Upload Logo
                  </Button>
                </div>
              ))}
          </div>
        </FormControl>

        <FormControl
          label={<Label>{"Enter job title"}</Label>}
          error={formErrors["jobTitle"]}
        >
          <Select
            placeholder={"Select Job Title"}
            creatable
            getOptions={async (query) => searchEntity("jobTitle", query)}
            onChange={(value) => {
              updateFormValues("jobTitle", value);
            }}
            value={job["jobTitle"]}
          />
        </FormControl>
        <FormControl
          label={<Label>{"Enter functional areas for this job?"}</Label>}
          error={formErrors["functionalAreas"]}
        >
          <Select
            placeholder={"Select Functional Area"}
            multi
            getOptions={async (query) => searchEntity("jobFunction", query)}
            onChange={(value) => {
              updateFormValues("functionalAreas", value);
            }}
            value={job["functionalAreas"]}
          />
        </FormControl>
        <FormControl
          label={<Label>{"Enter locations  for this job?"}</Label>}
          error={formErrors["locations"]}
        >
          <Select
            placeholder={"Select Locations"}
            creatable
            multi
            getOptions={async (query) => searchEntity("location", query)}
            onChange={(value) => {
              updateFormValues("locations", value);
            }}
            value={job["locations"]}
          />
        </FormControl>
        <FormControl
          label={<Label>{"Enter minimum experience required"}</Label>}
        >
          <Input
            placeholder={"Enter Min Exp"}
            id={"minExperience"}
            onChange={(e) =>
              updateFormValues(
                "minExperience",
                !isNaN(+e.target.value) ? +e.target.value : ""
              )
            }
            value={job["minExperience"]}
            autoComplete={"off"}
            endEnhancer={
              <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                Years
              </span>
            }
          />
        </FormControl>
        <FormControl
          label={<Label>{"Enter maximum experience required"}</Label>}
        >
          <Input
            placeholder={"Enter Max Exp"}
            id={"maxExperience"}
            onChange={(e) =>
              updateFormValues(
                "maxExperience",
                !isNaN(+e.target.value) ? +e.target.value : ""
              )
            }
            value={job["maxExperience"]}
            autoComplete={"off"}
            endEnhancer={
              <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                Years
              </span>
            }
          />
        </FormControl>
        <FormControl label={<Label>{"Enter the job description"}</Label>}>
          <RichTextEditor
            value={job.jobDescriptionEditor}
            className={"react-rte"}
            onChange={(value) =>
              updateFormValues("jobDescriptionEditor", value)
            }
          />
        </FormControl>
        <FormControl
          label={<Label>{"Skills required for this job?"}</Label>}
          error={formErrors["skills"]}
        >
          <Select
            placeholder={"Select Skills"}
            creatable
            multi
            getOptions={async (query) => searchEntity("skill", query)}
            onChange={(value) => {
              updateFormValues("skills", value);
            }}
            value={job["skills"]}
          />
        </FormControl>

        <FormControl
          label={<Label>{"Enter the job id"}</Label>}
          caption={
            <div>
              If you use a job id for internal tracking, use the same ID here
            </div>
          }
          error={formErrors["jobInternalID"]}
        >
          <Input
            id={"jobInternalID"}
            onChange={(e) => updateFormValues("jobInternalID", e.target.value)}
            value={job["jobInternalID"]}
            autoComplete={"off"}
            onBlur={(e) =>
              updateFormErrors(
                "jobInternalID",
                validate("jobInternalID", getValidations("jobInternalID"))
              )
            }
          />
        </FormControl>
        <Block marginBottom="scale400">
          <Block marginBottom="scale400">
            <LabelMedium>Note to recruiters(Optional)</LabelMedium>
            <ParagraphSmall>
              The notes to the recruiter are not visible to Job Applicants or
              shown in public. These instructions are visible only to recruiters
              working on this Job Description. he goal is to help recruiters
              source candidates that meet your expectations.
            </ParagraphSmall>
          </Block>

          <FormControl label={<Label>{"Enter minimum salary"}</Label>}>
            <SalaryInput
              name={"minimumSalary"}
              onChange={(val) => updateFormValues("minimumSalary", val)}
              onCurrencyChange={(val) =>
                updateFormValues("salaryCurrency", val)
              }
              value={job["minimumSalary"]}
              currencyValue={job["salaryCurrency"]}
            ></SalaryInput>
          </FormControl>
          <FormControl label={<Label>{"Enter maximum salary"}</Label>}>
            <SalaryInput
              name={"maximumSalary"}
              onChange={(val) => updateFormValues("maximumSalary", val)}
              onCurrencyChange={(val) =>
                updateFormValues("salaryCurrency", val)
              }
              value={job["maximumSalary"]}
              currencyValue={job["salaryCurrency"]}
            ></SalaryInput>
          </FormControl>

          <FormControl
            label={
              <Label subtitle="Would you like our recruiters to find candidates currently working in specific companies (e.g. say in Google or Amazon)? Mention a minimum of 3 or more companies our recruiters should target.">
                {"Enter target companies"}
              </Label>
            }
            error={formErrors["targetCompanies"]}
          >
            <Select
              placeholder={"Enter target companies"}
              creatable
              multi
              getOptions={async (query) => searchEntity("company", query)}
              onChange={(value) => {
                updateFormValues("targetCompanies", value);
              }}
              value={job["targetCompanies"]}
            />
          </FormControl>

          <FormControl
            label={
              <Label>
                {"What is the cool-off period for candidates? (Optional)"}
              </Label>
            }
            caption={
              <div>
                "Cool off period" is the duration that a candidate is barred
                from re-applying at your company where she/he was rejected.
              </div>
            }
            error={formErrors["coolDownPeriod"]}
          >
            <Select
              clearable={false}
              searchable={false}
              defaultOptions={[
                { id: 0, name: "0 months" },
                { id: 1, name: "1 months" },
                { id: 2, name: "2 months" },
                { id: 3, name: "3 months" },
                { id: 4, name: "4 months" },
                { id: 5, name: "5 months" },
                { id: 6, name: "6 months" },
                { id: 7, name: "7 months" },
                { id: 8, name: "8 months" },
                { id: 9, name: "9 months" },
                { id: 10, name: "10 months" },
                { id: 11, name: "11 months" },
                { id: 12, name: "12 months" },
              ]}
              onChange={(value) => {
                updateFormValues("coolDownPeriod", value.id);
              }}
              value={{
                id: job["coolDownPeriod"],
                name: job["coolDownPeriod"] + " months",
              }}
            />
          </FormControl>
          <FormControl
            label={
              <Label>
                {
                  "Feel free to specify any other sourcing brief that you want to communicate with recruiters"
                }
              </Label>
            }
          >
            <RichTextEditor
              value={job.recruiterNoteEditor}
              className={"react-rte"}
              onChange={(value) =>
                updateFormValues("recruiterNoteEditor", value)
              }
            />
          </FormControl>
        </Block>
        <Button isLoading={isLoading} type={"submit"}>
          {props.buttonLabel}
        </Button>
      </form>
      {job.company && (
        <UploadLogoModal
          isUploadLogoModalVisible={isUploadLogoModalVisible}
          onClose={() => {
            setIsUploadLogoModalVisible(false);
          }}
          onSuccess={(iconUrl) => {
            setIsUploadLogoModalVisible(false);
            job.company.icon = iconUrl;
            updateFormValues("company", job.company);
          }}
          company={job.company}
        />
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(PostJobForm));
