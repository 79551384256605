import React, { useState } from "react";
import { Box, Divider, Flex } from "@chakra-ui/layout";
import {
  Heading,
  Input,
  Button,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { ChevronLeftIcon, SearchIcon } from "@chakra-ui/icons";

export type SelectLabelProps = {
  labels: string[];
  onLabelSelect: (label: string) => void;
  onClose: () => void;
};

export const SelectLabel = ({
  labels,
  onLabelSelect,
  onClose,
}: SelectLabelProps) => {
  const [isCreateMode, setIsCreateMode] = useState(labels.length === 0);
  const [newLabelVal, setNewLabelVal] = useState("");
  const [searchLabelVal, setSearchLabelVal] = useState("");
  const onNewLabelChange = (e: any) => {
    setNewLabelVal(e.target.value);
  };
  const onSearchLabelChange = (e: any) => {
    setSearchLabelVal(e.target.value);
  };
  return (
    <Flex>
      {isCreateMode ? (
        <Flex direction="column">
          <Flex direction="row" alignItems="center" mb={2}>
            <ChevronLeftIcon onClick={() => setIsCreateMode(false)} />
            <Heading size="sm">Create Label</Heading>
          </Flex>
          <Input value={newLabelVal} onChange={onNewLabelChange} mb={2} />
          <Button
            onClick={() => {
              setNewLabelVal("");
              onLabelSelect(newLabelVal);
              onClose?.();
            }}
          >
            Add
          </Button>
        </Flex>
      ) : (
        <Flex direction="column">
          <Heading size="sm" mb={2}>
            Search Label
          </Heading>
          <InputGroup>
            <InputLeftElement children={<SearchIcon color="gray.500" />} />
            <Input
              value={searchLabelVal}
              onChange={onSearchLabelChange}
              placeholder="type here"
            />
          </InputGroup>
          <Flex mt={2} direction="column">
            {labels
              .filter((l) => {
                if (!searchLabelVal) return true;
                return l.indexOf(searchLabelVal) != -1;
              })
              .map((l) => (
                <Button
                  variant={"ghost"}
                  onClick={() => {
                    onLabelSelect(l);
                    onClose?.();
                  }}
                  justifyContent="flex-start"
                >
                  {l}
                </Button>
              ))}
          </Flex>
          <Divider />
          <Button
            variant="link"
            onClick={() => {
              setIsCreateMode(true);
              setNewLabelVal(searchLabelVal);
            }}
            mt={2}
            mb={4}
          >
            Create Label
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
