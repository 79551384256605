import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import { getCandidatesForReview } from "../api/admin";
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table";
import RouteLink from "../components/RouteLink";
import { prettyPrint } from "../util/time";

const getSubmissionUrl = ({ submissionId, isNew }) => {
  if (!isNew) {
    return "/N0VHxB8M/admin/details/submission/" + submissionId;
  }
  return `/P0GIbA9C/reviewer/details/uploadedCandidates/${submissionId}/details`;
};

const CandidateRow = (props) => (
  <StyledRow key={props.index}>
    <StyledCell>
      <div
        style={{ width: "150px", textOverflow: "ellipsis", overflow: "hidden" }}
      >
        {!props.isNew ? (
          <RouteLink to={"/N0VHxB8M/admin/details/candidate/" + props.user.id}>
            {props.user.email}
          </RouteLink>
        ) : (
          <div>{props.user.email}</div>
        )}
      </div>
    </StyledCell>
    <StyledCell>{props.user.name}</StyledCell>
    <StyledCell>
      {props.submission ? (
        <RouteLink
          to={getSubmissionUrl({
            submissionId: props.submission.id,
            isNew: props.isNew,
          })}
        >
          See Submission
        </RouteLink>
      ) : (
        <div>NA</div>
      )}
    </StyledCell>
    <StyledCell>
      {((props.submission || {}).approvers || [])
        .map((approver) => approver.email)
        .join(",")}
    </StyledCell>
    <StyledCell>{prettyPrint(props.user.modifiedAt)}</StyledCell>
  </StyledRow>
);

class AdminCandidateSubmissionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      users: [],
      submissions: [],
      candidates: [],
      hasNext: false,
      hasPrev: false,
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    try {
      let {
        data: { users, submissions },
        hasNext,
      } = await getCandidatesForReview(this.state.page, this.state.limit);
      this.setState({
        users,
        submissions,
        hasNext,
      });
    } catch (e) {
      console.log(e);
    }
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  render() {
    return (
      <div>
        <Title>Candidate Submissions</Title>
        <div style={{ marginBottom: "32px", width: "1000px" }}>
          <StyledTable>
            <StyledHead>
              <StyledHeadCell>Email</StyledHeadCell>
              <StyledHeadCell>Name</StyledHeadCell>
              <StyledHeadCell>Details</StyledHeadCell>
              <StyledHeadCell>Approvers</StyledHeadCell>
              <StyledHeadCell>Updated</StyledHeadCell>
            </StyledHead>
            <StyledBody>
              {this.state.users.map((user, index) => (
                <CandidateRow
                  isNew={this.props.isNew}
                  key={index}
                  isLast={index === this.state.users.length - 1}
                  user={user}
                  submission={this.state.submissions[index]}
                />
              ))}
            </StyledBody>
          </StyledTable>
        </div>
        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminCandidateSubmissionList));
