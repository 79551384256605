import React, { useState } from "react";
import { FormControl } from "baseui/form-control";
import Label from "../components/Label";
import TextArea from "../components/TextArea";
import EmptyCaption from "../components/EmptyCaption";
import { searchEntity } from "../api/entity";
import MultiSelect from "../components/MultiSelect";
import Button from "../components/Button";
import SalaryInput from "../components/SalaryInput";
import { get as at } from "../util/object-path";
import { withStyle } from "baseui";
import Bold from "./Bold";
import { getCurrency, getCurrencyWords } from "../util/currency";
import FlexRow from "./FlexRow";
import Card from "./Card";
import { SIZE, KIND as BUTTON_KIND } from "baseui/button";
import Modal from "../components/Modal";
import Select from "../components/Select";
import {
  SIZE as MODAL_SIZE,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from "baseui/modal";
import Title from "./Title";
import { Icon } from "./Icon";
import { Block } from "baseui/block";
import { Radio, RadioGroup, ALIGN } from "baseui/radio";
import {
  getDays,
  getMonthValue,
  getTimeFromMonthAndYear,
  getYearValue,
  MONTHS,
  YEARS,
} from "../util/time";
import { Checkbox } from "baseui/checkbox";
import { getNoticePeriodOptions } from "../util/noticePeriod";

const SectionTitle = withStyle(Bold, {
  marginTop: "8px",
  marginBottom: "16px",
  fontSize: "24px",
});

const Section = withStyle(Card, {
  marginBottom: "16px",
});

const MultiSelectEditable = ({
  candidateValue,
  anonymousValue,
  isMultiple,
  entityName,
  getOptions,
  onChange,
}) => {
  const [editableValue, setEditableValue] = useState(candidateValue);
  const [editableAnonymousValue, setEditableAnonymousValue] = useState(
    anonymousValue
  );
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  return (
    <div>
      <FlexRow>
        <div
          style={{
            marginLeft: "16px",
            borderRadius: "4px",
            padding: "8px",
            width: "300px",
          }}
        >
          {((isMultiple ? candidateValue : [candidateValue]) || []).map(
            (val) =>
              val && (
                <div style={val.isCreatable ? { background: "orange" } : {}}>
                  {val.name}
                </div>
              )
          )}
        </div>
        <div
          style={{
            marginRight: "16px",
            color: "#919191",
            borderRadius: "4px",
            padding: "8px",
            width: "300px",
          }}
        >
          {((isMultiple ? anonymousValue : [anonymousValue]) || []).map(
            (val) => val && <div>{val.name}</div>
          )}
        </div>
        <Button
          type={"button"}
          size={SIZE.mini}
          kind={BUTTON_KIND.minimal}
          buttonStyle={{ padding: "8px" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setEditableValue(candidateValue);
            setEditableAnonymousValue(anonymousValue);
            setIsEditModalVisible(true);
          }}
        >
          <Icon size={16} name="edit" />
        </Button>
      </FlexRow>

      <Modal
        animate
        autoFocus
        isOpen={isEditModalVisible}
        closeable
        size={MODAL_SIZE.auto}
        onClose={() => {
          setIsEditModalVisible(false);
        }}
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            e.stopPropagation();
            await onChange(editableValue, editableAnonymousValue);
            setIsEditModalVisible(false);
          }}
          autoComplete="off"
        >
          <ModalHeader>
            <Title>{"Update " + entityName}</Title>
          </ModalHeader>
          <ModalBody>
            <FlexRow>
              <MultiSelect
                debug
                multi={isMultiple}
                getOptions={getOptions}
                entityName={entityName}
                isAnonymousRequired
                onChange={(val, isNew) => {
                  if (val) {
                    setEditableValue(val);
                    setEditableAnonymousValue(val);
                  }
                }}
                value={editableValue}
              />
              <MultiSelect
                debug
                multi={isMultiple}
                kind={"anonymous"}
                getOptions={getOptions}
                entityName={entityName}
                isAnonymousRequired
                onChange={(val, isNew) => {
                  if (val) {
                    setEditableAnonymousValue(val);
                  }
                }}
                value={editableAnonymousValue}
              />
            </FlexRow>
          </ModalBody>
          <ModalFooter>
            <ModalButton>{"Update"}</ModalButton>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

const CurrencyEditable = ({
  candidateValue,
  candidateValueCurrency,
  anonymousValue,
  anonymousValueCurrency,
  entityName,
  onChange,
}) => {
  const [editableValue, setEditableValue] = useState(candidateValue);
  const [editableAnonymousValue, setEditableAnonymousValue] = useState(
    anonymousValue
  );
  const [editableValueCurrency, setEditableValueCurrency] = useState(
    candidateValueCurrency
  );
  const [
    editableAnonymousValueCurrency,
    setEditableAnonymousValueCurrency,
  ] = useState(anonymousValueCurrency);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  return (
    <div>
      <FlexRow>
        <div
          style={{
            marginLeft: "8px",
            borderRadius: "4px",
            padding: "8px",
            flex: 1,
            wordBreak: "break-word",
            whiteSpace: "pre-line",
          }}
        >
          <div>{candidateValueCurrency + " " + candidateValue}</div>
        </div>
        <div
          style={{
            marginRight: "16px",
            color: "#919191",
            borderRadius: "4px",
            padding: "8px",
            flex: 1,
            wordBreak: "break-word",
            whiteSpace: "pre-line",
          }}
        >
          <div>{anonymousValueCurrency + " " + anonymousValue}</div>
        </div>
        <Block width="40px">
          <Button
            type={"button"}
            size={SIZE.mini}
            kind={BUTTON_KIND.minimal}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setEditableValue(candidateValue);
              setEditableAnonymousValue(anonymousValue);
              setIsEditModalVisible(true);
            }}
          >
            <Icon name="edit" size={16} />
          </Button>
        </Block>
      </FlexRow>
      <Modal
        animate
        autoFocus
        isOpen={isEditModalVisible}
        closeable
        size={MODAL_SIZE.auto}
        onClose={() => {
          setIsEditModalVisible(false);
        }}
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onChange(
              editableValue,
              editableAnonymousValue,
              editableValueCurrency,
              editableAnonymousValueCurrency
            );
            setIsEditModalVisible(false);
          }}
          autoComplete="off"
        >
          <ModalHeader>
            <Title>{"Update " + entityName}</Title>
          </ModalHeader>
          <ModalBody>
            <FlexRow>
              <SalaryInput
                name={""}
                onChange={(val) => {
                  setEditableValue(val);
                  setEditableAnonymousValue(val);
                }}
                onCurrencyChange={(val) => {
                  setEditableValueCurrency(val.id);
                  setEditableAnonymousValueCurrency(val.id);
                }}
                value={editableValue}
                currencyValue={getCurrency(editableValueCurrency)}
              ></SalaryInput>
              <SalaryInput
                name={""}
                onChange={(val) => {
                  setEditableAnonymousValue(val);
                }}
                onCurrencyChange={(val) => {
                  setEditableAnonymousValueCurrency(val.id);
                }}
                value={editableAnonymousValue}
                currencyValue={getCurrency(editableAnonymousValueCurrency)}
              ></SalaryInput>
            </FlexRow>
          </ModalBody>
          <ModalFooter>
            <ModalButton>{"Update"}</ModalButton>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

const TextAreaEditable = ({
  candidateValue,
  anonymousValue,
  entityName,
  onChange,
}) => {
  const [editableValue, setEditableValue] = useState(candidateValue);
  const [editableAnonymousValue, setEditableAnonymousValue] = useState(
    anonymousValue
  );
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  return (
    <div>
      <FlexRow>
        <div
          style={{
            marginLeft: "8px",
            borderRadius: "4px",
            padding: "8px",
            flex: 1,
            wordBreak: "break-word",
            whiteSpace: "pre-line",
          }}
        >
          <div>{candidateValue}</div>
        </div>
        <div
          style={{
            marginRight: "16px",
            color: "#919191",
            borderRadius: "4px",
            padding: "8px",
            flex: 1,
            wordBreak: "break-word",
            whiteSpace: "pre-line",
          }}
        >
          <div>{anonymousValue}</div>
        </div>
        <Block width="40px">
          <Button
            type={"button"}
            size={SIZE.mini}
            kind={BUTTON_KIND.minimal}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setEditableValue(candidateValue);
              setEditableAnonymousValue(anonymousValue);
              setIsEditModalVisible(true);
            }}
          >
            <Icon name="edit" size={16} />
          </Button>
        </Block>
      </FlexRow>
      <Modal
        animate
        autoFocus
        isOpen={isEditModalVisible}
        closeable
        size={MODAL_SIZE.auto}
        onClose={() => {
          setIsEditModalVisible(false);
        }}
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onChange(editableValue, editableAnonymousValue);
            setIsEditModalVisible(false);
          }}
          autoComplete="off"
        >
          <ModalHeader>
            <Title>{"Update " + entityName}</Title>
          </ModalHeader>
          <ModalBody>
            <FlexRow>
              <TextArea
                resize
                onChange={(e) => {
                  setEditableValue(e.target.value);
                  setEditableAnonymousValue(e.target.value);
                }}
                value={editableValue}
                autoComplete={"off"}
              />
              <TextArea
                resize
                onChange={(e) => {
                  setEditableAnonymousValue(e.target.value);
                }}
                value={editableAnonymousValue}
                autoComplete={"off"}
              />
            </FlexRow>
          </ModalBody>
          <ModalFooter>
            <ModalButton>{"Update"}</ModalButton>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

const EducationControl = ({
  candidate,
  anonymousCandidate,
  onCandidateChange,
  onAnonymousCandidateChange,
}) => {
  let education = at(candidate, "education") || [];
  let anonymousEducation = at(anonymousCandidate, "education") || [];
  const updateCandidateEducation = (val, idx, isNew) => {
    education[idx] = val;
    anonymousEducation[idx] = val;
    onCandidateChange({ education }, isNew);
    onAnonymousCandidateChange({ education: anonymousEducation }, isNew);
  };
  const updateAnonymousCandidateEducation = (val, idx, isNew) => {
    anonymousEducation[idx] = val;
    onAnonymousCandidateChange({ education: anonymousEducation }, isNew);
  };

  const onEducationAdd = () => {
    const newEducation = { college: {}, course: {}, specializations: [] };
    education = education.concat(newEducation);
    anonymousEducation = anonymousEducation.concat(newEducation);
    onCandidateChange({ education }, false);
    onAnonymousCandidateChange({ education: anonymousEducation }, false);
  };

  const onEducationDelete = (idx) => {
    education = education.slice(0, idx).concat(education.slice(idx + 1));
    anonymousEducation = anonymousEducation
      .slice(0, idx)
      .concat(anonymousEducation.slice(idx + 1));
    onCandidateChange({ education }, false);
    onAnonymousCandidateChange({ education: anonymousEducation }, false);
  };

  return (
    <Block>
      <Button type="button" onClick={onEducationAdd}>
        Add Education
      </Button>
      {education.map((ed, idx) => {
        let anonymousEd = anonymousEducation[idx];
        return (
          <Section>
            <Button type="button" onClick={() => onEducationDelete(idx)}>
              Delete Education
            </Button>
            <FormControl label={<Label>{"Start month"}</Label>}>
              <Select
                placeholder={"MM"}
                clearable={false}
                searchable={false}
                defaultOptions={MONTHS}
                width={"120px"}
                maxDropdownHeight={"200px"}
                value={getMonthValue(ed.startMonth)}
                onChange={(value) => {
                  ed.startMonth = value.id;
                  updateCandidateEducation(ed, idx, false);
                  anonymousEd.startMonth = value.id;
                  updateAnonymousCandidateEducation(anonymousEd, idx, false);
                }}
              />
            </FormControl>

            <FormControl label={<Label>{"Start year"}</Label>}>
              <Select
                placeholder={"YYYY"}
                clearable={false}
                searchable={false}
                width={"80px"}
                defaultOptions={YEARS}
                maxDropdownHeight={"200px"}
                value={getYearValue(ed.startYear)}
                onChange={(value) => {
                  ed.startYear = value.id;
                  updateCandidateEducation(ed, idx, false);
                  anonymousEd.startYear = value.id;
                  updateAnonymousCandidateEducation(anonymousEd, idx, false);
                }}
              />
            </FormControl>

            <FormControl label={<Label>{"End month"}</Label>}>
              <Select
                placeholder={"MM"}
                clearable={false}
                searchable={false}
                defaultOptions={MONTHS}
                width={"120px"}
                maxDropdownHeight={"200px"}
                value={getMonthValue(ed.endMonth)}
                onChange={(value) => {
                  ed.endMonth = value.id;
                  updateCandidateEducation(ed, idx, false);
                  anonymousEd.endMonth = value.id;
                  updateAnonymousCandidateEducation(anonymousEd, idx, false);
                }}
              />
            </FormControl>

            <FormControl label={<Label>{"End year"}</Label>}>
              <Select
                placeholder={"YYYY"}
                clearable={false}
                searchable={false}
                width={"80px"}
                defaultOptions={YEARS}
                maxDropdownHeight={"200px"}
                value={getYearValue(ed.endYear)}
                onChange={(value) => {
                  ed.endYear = value.id;
                  updateCandidateEducation(ed, idx, false);
                  anonymousEd.endYear = value.id;
                  updateAnonymousCandidateEducation(anonymousEd, idx, false);
                }}
              />
            </FormControl>

            <FormControl
              label={<Label>{"College ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <MultiSelectEditable
                candidateValue={ed.college}
                anonymousValue={anonymousEd.college}
                entityName={"college"}
                getOptions={async (query) => searchEntity("college", query)}
                onChange={(orig, anon, isNew) => {
                  ed.college = orig;
                  updateCandidateEducation(ed, idx, isNew);
                  anonymousEd.college = anon;
                  updateAnonymousCandidateEducation(anonymousEd, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"Course ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <MultiSelectEditable
                candidateValue={ed.course}
                anonymousValue={anonymousEd.course}
                entityName={"course"}
                getOptions={async (query) => searchEntity("course", query)}
                onChange={(orig, anon, isNew) => {
                  ed.course = orig;
                  updateCandidateEducation(ed, idx, isNew);
                  anonymousEd.course = anon;
                  updateAnonymousCandidateEducation(anonymousEd, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"Specializations ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <MultiSelectEditable
                candidateValue={ed.specializations}
                anonymousValue={anonymousEd.specializations}
                isMultiple
                entityName={"specialization"}
                getOptions={async (query) =>
                  searchEntity("specialization", query)
                }
                onChange={(orig, anon, isNew) => {
                  ed.specializations = orig;
                  updateCandidateEducation(ed, idx, isNew);
                  anonymousEd.specializations = anon;
                  updateAnonymousCandidateEducation(anonymousEd, idx, isNew);
                }}
              />
            </FormControl>
          </Section>
        );
      })}
    </Block>
  );
};

const ProjectControl = ({
  candidate,
  anonymousCandidate,
  onCandidateChange,
  onAnonymousCandidateChange,
}) => {
  let projects = at(candidate, "projects") || [];
  let anonymousProjects = at(anonymousCandidate, "projects") || [];
  const updateCandidateProjects = (val, idx, isNew) => {
    projects[idx] = val;
    anonymousProjects[idx] = val;
    onCandidateChange({ projects }, isNew);
    onAnonymousCandidateChange({ projects: anonymousProjects }, isNew);
  };
  const updateAnonymousCandidateProjects = (val, idx, isNew) => {
    anonymousProjects[idx] = val;
    onAnonymousCandidateChange({ projects: anonymousProjects }, isNew);
  };
  let companies = (candidate.experience || []).map((ex) => ex.company);
  let colleges = (candidate.education || []).map((ed) => ed.college);
  const projectOptions = companies.concat(colleges).map((opt) => {
    opt.isCreatable = false;
    return opt;
  });

  const onProjectAdd = () => {
    const newProject = { company: {} };
    projects = projects.concat(newProject);
    anonymousProjects = anonymousProjects.concat(newProject);
    onCandidateChange({ projects }, false);
    onAnonymousCandidateChange({ projects: anonymousProjects }, false);
  };
  const onProjectDelete = (idx) => {
    projects = projects.slice(0, idx).concat(projects.slice(idx + 1));
    anonymousProjects = anonymousProjects
      .slice(0, idx)
      .concat(anonymousProjects.slice(idx + 1));
    onCandidateChange({ projects }, false);
    onAnonymousCandidateChange({ projects: anonymousProjects }, false);
  };
  return (
    <Block>
      <Button type="button" onClick={onProjectAdd}>
        Add Project
      </Button>
      {projects.map((pr, idx) => {
        let anonymousPr = anonymousProjects[idx];
        return (
          <Section>
            <Button type="button" onClick={() => onProjectDelete(idx)}>
              Delete Project
            </Button>
            <FormControl
              label={<Label>{"Company ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <MultiSelectEditable
                candidateValue={pr.company}
                anonymousValue={anonymousPr.company}
                entityName={"company"}
                getOptions={async (query) => projectOptions}
                onChange={(orig, anon, isNew) => {
                  pr.company = orig;
                  updateCandidateProjects(pr, idx, isNew);
                  anonymousPr.company = anon;
                  updateAnonymousCandidateProjects(anonymousPr, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"Brief details about the project"}</Label>}
            >
              <TextAreaEditable
                candidateValue={pr.briefIntro}
                anonymousValue={anonymousPr.briefIntro}
                entityName={"briefIntro"}
                onChange={(orig, anon, isNew) => {
                  pr.briefIntro = orig;
                  updateCandidateProjects(pr, idx, isNew);
                  anonymousPr.briefIntro = anon;
                  updateAnonymousCandidateProjects(anonymousPr, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"Candidate role and contribution"}</Label>}
            >
              <TextAreaEditable
                candidateValue={pr.role}
                anonymousValue={anonymousPr.role}
                entityName={"role"}
                onChange={(orig, anon, isNew) => {
                  pr.role = orig;
                  updateCandidateProjects(pr, idx, isNew);
                  anonymousPr.role = anon;
                  updateAnonymousCandidateProjects(anonymousPr, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl label={<Label>{"Project Outcome"}</Label>}>
              <TextAreaEditable
                candidateValue={pr.outcome}
                anonymousValue={anonymousPr.outcome}
                entityName={"outcome"}
                onChange={(orig, anon, isNew) => {
                  pr.outcome = orig;
                  updateCandidateProjects(pr, idx, isNew);
                  anonymousPr.outcome = anon;
                  updateAnonymousCandidateProjects(anonymousPr, idx, isNew);
                }}
              />
            </FormControl>
          </Section>
        );
      })}
    </Block>
  );
};

const ExperienceControl = ({
  candidate,
  anonymousCandidate,
  onCandidateChange,
  onAnonymousCandidateChange,
}) => {
  let experience = at(candidate, "experience") || [];
  let anonymousExperience = at(anonymousCandidate, "experience") || [];
  const updateCandidateExperience = (val, idx, isNew) => {
    experience[idx] = val;
    const totalExperience = getTotalExperience();
    const lastExperienceTitle = getExperienceTitle();
    anonymousExperience[idx] = val;
    onCandidateChange(
      { experience, totalExperience, lastExperienceTitle },
      isNew
    );
    onAnonymousCandidateChange(
      { experience: anonymousExperience, totalExperience, lastExperienceTitle },
      isNew
    );
  };
  const updateAnonymousCandidateExperience = (val, idx, isNew) => {
    anonymousExperience[idx] = val;
    const totalExperience = getTotalExperience();
    const lastExperienceTitle = getExperienceTitle();
    onAnonymousCandidateChange(
      { experience: anonymousExperience, totalExperience, lastExperienceTitle },
      isNew
    );
  };

  const getExperienceTitle = () =>
    experience && experience.length ? experience[0].title.name : "Fresher";
  const getTotalExperience = () => {
    var sortedEx = JSON.parse(JSON.stringify(experience)).sort(
      (a, b) =>
        getTimeFromMonthAndYear(b.startMonth, b.startYear) -
        getTimeFromMonthAndYear(a.startMonth, a.startYear)
    );
    return parseInt(
      getDays(
        sortedEx.map((ex) => ({
          startTime: getTimeFromMonthAndYear(ex.startMonth, ex.startYear),
          endTime: getTimeFromMonthAndYear(ex.endMonth, ex.endYear),
        }))
      )
    );
  };

  const onExperienceAdd = () => {
    const newExperience = {
      company: {},
      title: {},
      location: {},
      jobFunctions: [],
    };
    experience = experience.concat(newExperience);
    anonymousExperience = anonymousExperience.concat(newExperience);
    onCandidateChange({ experience }, false);
    onAnonymousCandidateChange({ experience: anonymousExperience }, false);
  };
  const onExperienceDelete = (idx) => {
    experience = experience.slice(0, idx).concat(experience.slice(idx + 1));
    anonymousExperience = anonymousExperience
      .slice(0, idx)
      .concat(anonymousExperience.slice(idx + 1));
    onCandidateChange({ experience }, false);
    onAnonymousCandidateChange({ experience: anonymousExperience }, false);
  };
  return (
    <Block>
      <Button type="button" onClick={onExperienceAdd}>
        Add Experience
      </Button>
      {experience.map((ex, idx) => {
        let anonymousEx = anonymousExperience[idx];
        return (
          <Section>
            <Button type="button" onClick={() => onExperienceDelete(idx)}>
              Delete Experience
            </Button>
            <FormControl label={<Label>{"Start month"}</Label>}>
              <Select
                placeholder={"MM"}
                clearable={false}
                searchable={false}
                defaultOptions={MONTHS}
                width={"120px"}
                maxDropdownHeight={"200px"}
                value={getMonthValue(ex.startMonth)}
                onChange={(value) => {
                  ex.startMonth = value.id;
                  updateCandidateExperience(ex, idx, false);
                  anonymousEx.startMonth = value.id;
                  updateAnonymousCandidateExperience(anonymousEx, idx, false);
                }}
              />
            </FormControl>

            <FormControl label={<Label>{"Start year"}</Label>}>
              <Select
                placeholder={"YYYY"}
                clearable={false}
                searchable={false}
                width={"80px"}
                defaultOptions={YEARS}
                maxDropdownHeight={"200px"}
                value={getYearValue(ex.startYear)}
                onChange={(value) => {
                  ex.startYear = value.id;
                  updateCandidateExperience(ex, idx, false);
                  anonymousEx.startYear = value.id;
                  updateAnonymousCandidateExperience(anonymousEx, idx, false);
                }}
              />
            </FormControl>

            <FormControl caption={<EmptyCaption />}>
              <Checkbox
                checked={ex.isCurrent}
                onChange={() => {
                  ex.isCurrent = !ex.isCurrent;
                  updateCandidateExperience(ex, idx, false);
                  anonymousEx.isCurrent = !ex.isCurrent;
                  updateAnonymousCandidateExperience(anonymousEx, idx, false);
                }}
              >
                Current job
              </Checkbox>
            </FormControl>
            {!ex.isCurrent && (
              <>
                <FormControl label={<Label>{"End month"}</Label>}>
                  <Select
                    placeholder={"MM"}
                    clearable={false}
                    searchable={false}
                    defaultOptions={MONTHS}
                    width={"120px"}
                    maxDropdownHeight={"200px"}
                    value={getMonthValue(ex.endMonth)}
                    onChange={(value) => {
                      ex.endMonth = value.id;
                      updateCandidateExperience(ex, idx, false);
                      anonymousEx.endMonth = value.id;
                      updateAnonymousCandidateExperience(
                        anonymousEx,
                        idx,
                        false
                      );
                    }}
                  />
                </FormControl>

                <FormControl label={<Label>{"End year"}</Label>}>
                  <Select
                    placeholder={"YYYY"}
                    clearable={false}
                    searchable={false}
                    width={"80px"}
                    defaultOptions={YEARS}
                    maxDropdownHeight={"200px"}
                    value={getYearValue(ex.endYear)}
                    onChange={(value) => {
                      ex.endYear = value.id;
                      updateCandidateExperience(ex, idx, false);
                      anonymousEx.endYear = value.id;
                      updateAnonymousCandidateExperience(
                        anonymousEx,
                        idx,
                        false
                      );
                    }}
                  />
                </FormControl>
              </>
            )}
            <FormControl
              label={<Label>{"Company ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <MultiSelectEditable
                candidateValue={ex.company}
                anonymousValue={anonymousEx.company}
                entityName={"company"}
                getOptions={async (query) => searchEntity("company", query)}
                onChange={(orig, anon, isNew) => {
                  ex.company = orig;
                  updateCandidateExperience(ex, idx, isNew);
                  anonymousEx.company = anon;
                  updateAnonymousCandidateExperience(anonymousEx, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"Job title ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <MultiSelectEditable
                candidateValue={ex.title}
                anonymousValue={anonymousEx.title}
                entityName={"jobTitle"}
                getOptions={async (query) => searchEntity("jobTitle", query)}
                onChange={(orig, anon, isNew) => {
                  ex.title = orig;
                  updateCandidateExperience(ex, idx, isNew);
                  anonymousEx.title = anon;
                  updateAnonymousCandidateExperience(anonymousEx, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"Location ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <MultiSelectEditable
                candidateValue={ex.location}
                anonymousValue={anonymousEx.location}
                entityName={"location"}
                getOptions={async (query) => searchEntity("location", query)}
                onChange={(orig, anon, isNew) => {
                  ex.location = orig;
                  updateCandidateExperience(ex, idx, isNew);
                  anonymousEx.location = anon;
                  updateAnonymousCandidateExperience(anonymousEx, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"job functions of this experience ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <MultiSelectEditable
                candidateValue={ex.jobFunctions}
                anonymousValue={anonymousEx.jobFunctions}
                isMultiple
                entityName={"jobFunction"}
                getOptions={async (query) => searchEntity("jobFunction", query)}
                onChange={(orig, anon, isNew) => {
                  ex.jobFunctions = orig;
                  updateCandidateExperience(ex, idx, isNew);
                  anonymousEx.jobFunctions = anon;
                  updateAnonymousCandidateExperience(anonymousEx, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl label={<Label>{"Description"}</Label>}>
              <TextAreaEditable
                candidateValue={ex.description}
                anonymousValue={anonymousEx.description}
                entityName={"description"}
                onChange={(orig, anon, isNew) => {
                  ex.description = orig;
                  updateCandidateExperience(ex, idx, isNew);
                  anonymousEx.description = anon;
                  updateAnonymousCandidateExperience(anonymousEx, idx, isNew);
                }}
              />
            </FormControl>
          </Section>
        );
      })}
    </Block>
  );
};

const ExistingOfferControl = ({
  candidate,
  anonymousCandidate,
  onCandidateChange,
  onAnonymousCandidateChange,
}) => {
  let existingOffers = at(candidate, "premium.existingOffers") || [];
  let anonymousExistingOffers =
    at(anonymousCandidate, "premium.existingOffers") || [];

  const updateExistingOffers = (val, idx, isNew) => {
    existingOffers[idx] = val;
    candidate.premium.existingOffers = existingOffers;
    anonymousCandidate.premium.existingOffers = existingOffers;
    onCandidateChange({ premium: candidate.premium }, isNew);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, isNew);
  };

  const updateAnonymousExistingOffers = (val, idx, isNew) => {
    anonymousExistingOffers[idx] = val;
    anonymousCandidate.premium.existingOffers = anonymousExistingOffers;
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, isNew);
  };

  return (
    <Block>
      {existingOffers.map((eo, idx) => {
        let anonymousEo = anonymousExistingOffers[idx];
        return (
          <Section>
            <FormControl
              label={<Label>{"ctc ?"}</Label>}
              caption={<div>{getCurrencyWords(eo.ctc) + " per year"}</div>}
            >
              <TextAreaEditable
                candidateValue={eo.ctc}
                anonymousValue={anonymousEo.ctc}
                entityName={"ctc"}
                onChange={(orig, anon, isNew) => {
                  eo.ctc = orig;
                  updateExistingOffers(eo, idx, isNew);
                  anonymousEo.ctc = anon;
                  updateAnonymousExistingOffers(anonymousEo, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"Which company ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <MultiSelectEditable
                candidateValue={eo.company}
                anonymousValue={anonymousEo.company}
                entityName={"company"}
                getOptions={async (query) => searchEntity("company", query)}
                onChange={(orig, anon, isNew) => {
                  eo.company = orig;
                  updateExistingOffers(eo, idx, isNew);
                  anonymousEo.company = anon;
                  updateAnonymousExistingOffers(anonymousEo, idx, isNew);
                }}
              />
            </FormControl>
          </Section>
        );
      })}
    </Block>
  );
};

const WorkshowcasesControl = ({
  candidate,
  anonymousCandidate,
  onCandidateChange,
  onAnonymousCandidateChange,
}) => {
  let workShowCases = at(candidate, "premium.workShowcases") || [];
  let anonymousWorkShowCases =
    at(anonymousCandidate, "premium.workShowcases") || [];

  const updateWorkshowCases = (val, idx, isNew) => {
    workShowCases[idx] = val;
    candidate.premium.workShowcases = workShowCases;
    anonymousCandidate.premium.workShowcases = workShowCases;
    onCandidateChange({ premium: candidate.premium }, isNew);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, isNew);
  };

  const updateAnonymousWorkshowCases = (val, idx, isNew) => {
    anonymousWorkShowCases[idx] = val;
    anonymousCandidate.premium.workShowcases = anonymousWorkShowCases;
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, isNew);
  };

  const onWorkshowCasesAdd = () => {
    const newWorkShowcase = {};
    workShowCases = workShowCases.concat(newWorkShowcase);
    anonymousWorkShowCases = anonymousWorkShowCases.concat(newWorkShowcase);
    if (!candidate.premium) {
      candidate.premium = {};
      anonymousCandidate.premium = {};
    }
    candidate.premium.workShowcases = workShowCases;
    anonymousCandidate.premium.workShowcases = workShowCases;
    onCandidateChange({ premium: candidate.premium }, false);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, false);
  };
  const onWorkshowCasesDelete = (idx) => {
    workShowCases = workShowCases
      .slice(0, idx)
      .concat(workShowCases.slice(idx + 1));
    if (!candidate.premium) {
      candidate.premium = {};
      anonymousCandidate.premium = {};
    }
    candidate.premium.workShowcases = workShowCases;
    anonymousCandidate.premium.workShowcases = workShowCases;
    onCandidateChange({ premium: candidate.premium }, false);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, false);
  };

  return (
    <Block>
      <Button type="button" onClick={onWorkshowCasesAdd}>
        Add WorkshowCase
      </Button>
      {workShowCases.map((ws, idx) => {
        let anonymousWs = anonymousWorkShowCases[idx];
        return (
          <Section>
            <Button type="button" onClick={() => onWorkshowCasesDelete(idx)}>
              Delete Education
            </Button>
            <FormControl
              label={<Label>{"showcase url ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <TextAreaEditable
                candidateValue={ws.url}
                anonymousValue={anonymousWs.url}
                entityName={"url"}
                onChange={(orig, anon, isNew) => {
                  ws.url = orig;
                  updateWorkshowCases(ws, idx, isNew);
                  anonymousWs.url = anon;
                  updateAnonymousWorkshowCases(anonymousWs, idx, isNew);
                }}
              />
            </FormControl>
          </Section>
        );
      })}
    </Block>
  );
};

const ArticlesControl = ({
  candidate,
  anonymousCandidate,
  onCandidateChange,
  onAnonymousCandidateChange,
}) => {
  let articles = at(candidate, "premium.articles") || [];
  let anonymousArticles = at(anonymousCandidate, "premium.articles") || [];

  const updateArticles = (val, idx, isNew) => {
    articles[idx] = val;
    candidate.premium.articles = articles;
    anonymousCandidate.premium.articles = articles;
    onCandidateChange({ premium: candidate.premium }, isNew);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, isNew);
  };

  const updateAnonymousArticles = (val, idx, isNew) => {
    anonymousArticles[idx] = val;
    anonymousCandidate.premium.articles = anonymousArticles;
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, isNew);
  };

  const onArticlesAdd = () => {
    const newArticle = "";
    articles = articles.concat(newArticle);
    anonymousArticles = anonymousArticles.concat(newArticle);
    if (!candidate.premium) {
      candidate.premium = {};
      anonymousCandidate.premium = {};
    }
    candidate.premium.articles = articles;
    anonymousCandidate.premium.articles = articles;
    onCandidateChange({ premium: candidate.premium }, false);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, false);
  };

  const onArticlesDelete = (idx) => {
    articles = articles.slice(0, idx).concat(articles.slice(idx + 1));
    if (!candidate.premium) {
      candidate.premium = {};
      anonymousCandidate.premium = {};
    }
    candidate.premium.articles = articles;
    anonymousCandidate.premium.articles = articles;
    onCandidateChange({ premium: candidate.premium }, false);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, false);
  };

  return (
    <Block>
      <Button type="button" onClick={onArticlesAdd}>
        Add Article
      </Button>
      {articles.map((ar, idx) => {
        let anonymousAr = anonymousArticles[idx];
        return (
          <Section>
            <Button type="button" onClick={() => onArticlesDelete(idx)}>
              Delete Article
            </Button>
            <FormControl
              label={<Label>{"article details ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <TextAreaEditable
                candidateValue={ar}
                anonymousValue={anonymousAr}
                entityName={"url"}
                onChange={(orig, anon, isNew) => {
                  ar = orig;
                  updateArticles(ar, idx, isNew);
                  anonymousAr = anon;
                  updateAnonymousArticles(anonymousAr, idx, isNew);
                }}
              />
            </FormControl>
          </Section>
        );
      })}
    </Block>
  );
};

const CareerGapControl = ({
  candidate,
  anonymousCandidate,
  onCandidateChange,
  onAnonymousCandidateChange,
}) => {
  let careerGaps = at(candidate, "careerGaps") || [];
  let anonymousCareerGaps = at(anonymousCandidate, "careerGaps") || [];
  const updateCandidateCareerGaps = (val, idx, isNew) => {
    careerGaps[idx] = val;
    anonymousCareerGaps[idx] = val;
    onCandidateChange({ careerGaps }, isNew);
    onAnonymousCandidateChange({ careerGaps: anonymousCareerGaps }, isNew);
  };
  const updateAnonymousCandidateCareerGaps = (val, idx, isNew) => {
    anonymousCareerGaps[idx] = val;
    onAnonymousCandidateChange({ careerGaps: anonymousCareerGaps }, isNew);
  };
  return careerGaps.map((cg, idx) => {
    let anonymousCg = anonymousCareerGaps[idx];
    return (
      <Section>
        <FormControl
          label={<Label>{"career gap details ?"}</Label>}
          caption={<EmptyCaption />}
        >
          <TextAreaEditable
            candidateValue={cg.reason}
            anonymousValue={anonymousCg.reason}
            entityName={"careerGap"}
            onChange={(orig, anon, isNew) => {
              cg.reason = orig;
              updateCandidateCareerGaps(cg, idx, isNew);
              anonymousCg.reason = anon;
              updateAnonymousCandidateCareerGaps(anonymousCg, idx, isNew);
            }}
          />
        </FormControl>
      </Section>
    );
  });
};

const PatentsControl = ({
  candidate,
  anonymousCandidate,
  onCandidateChange,
  onAnonymousCandidateChange,
}) => {
  let patents = at(candidate, "premium.patents") || [];
  let anonymousPatents = at(anonymousCandidate, "premium.patents") || [];

  const updatePatents = (val, idx, isNew) => {
    patents[idx] = val;
    candidate.premium.patents = patents;
    anonymousCandidate.premium.patents = patents;
    onCandidateChange({ premium: candidate.premium }, isNew);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, isNew);
  };

  const updateAnonymousPatents = (val, idx, isNew) => {
    anonymousPatents[idx] = val;
    anonymousCandidate.premium.patents = anonymousPatents;
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, isNew);
  };

  const onPatentsAdd = () => {
    const newPatent = {};
    patents = patents.concat(newPatent);
    anonymousPatents = anonymousPatents.concat(newPatent);
    if (!candidate.premium) {
      candidate.premium = {};
      anonymousCandidate.premium = {};
    }
    candidate.premium.patents = patents;
    anonymousCandidate.premium.patents = patents;
    onCandidateChange({ premium: candidate.premium }, false);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, false);
  };

  const onPatentsDetete = (idx) => {
    patents = patents.slice(0, idx).concat(patents.slice(idx + 1));
    if (!candidate.premium) {
      candidate.premium = {};
      anonymousCandidate.premium = {};
    }
    candidate.premium.patents = patents;
    anonymousCandidate.premium.patents = patents;
    onCandidateChange({ premium: candidate.premium }, false);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, false);
  };

  return (
    <Block>
      <Button type="button" onClick={onPatentsAdd}>
        Add Patent
      </Button>
      {patents.map((pt, idx) => {
        let anonymousPt = anonymousPatents[idx];
        return (
          <Section>
            <Button type="button" onClick={() => onPatentsDetete(idx)}>
              Delete Patent
            </Button>
            <FormControl
              label={<Label>{"patent title ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <TextAreaEditable
                candidateValue={pt.title}
                anonymousValue={anonymousPt.title}
                entityName={"title"}
                onChange={(orig, anon, isNew) => {
                  pt.title = orig;
                  updatePatents(pt, idx, isNew);
                  anonymousPt.title = anon;
                  updateAnonymousPatents(anonymousPt, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"patent number ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <TextAreaEditable
                candidateValue={pt.number}
                anonymousValue={anonymousPt.number}
                entityName={"number"}
                onChange={(orig, anon, isNew) => {
                  pt.number = orig;
                  updatePatents(pt, idx, isNew);
                  anonymousPt.number = anon;
                  updateAnonymousPatents(anonymousPt, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"patent url ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <TextAreaEditable
                candidateValue={pt.url}
                anonymousValue={anonymousPt.url}
                entityName={"url"}
                onChange={(orig, anon, isNew) => {
                  pt.url = orig;
                  updatePatents(pt, idx, isNew);
                  anonymousPt.url = anon;
                  updateAnonymousPatents(anonymousPt, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"patent description ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <TextAreaEditable
                candidateValue={pt.description}
                anonymousValue={anonymousPt.description}
                entityName={"description"}
                onChange={(orig, anon, isNew) => {
                  pt.description = orig;
                  updatePatents(pt, idx, isNew);
                  anonymousPt.description = anon;
                  updateAnonymousPatents(anonymousPt, idx, isNew);
                }}
              />
            </FormControl>
          </Section>
        );
      })}
    </Block>
  );
};

const CertificationsControl = ({
  candidate,
  anonymousCandidate,
  onCandidateChange,
  onAnonymousCandidateChange,
}) => {
  let certifications = at(candidate, "premium.certifications") || [];
  let anonymousCertifications =
    at(anonymousCandidate, "premium.certifications") || [];

  const updateCertifications = (val, idx, isNew) => {
    certifications[idx] = val;
    candidate.premium.certifications = certifications;
    anonymousCandidate.premium.certifications = certifications;
    onCandidateChange({ premium: candidate.premium }, isNew);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, isNew);
  };

  const updateAnonymousCertifications = (val, idx, isNew) => {
    anonymousCertifications[idx] = val;
    anonymousCandidate.premium.certifications = anonymousCertifications;
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, isNew);
  };

  const onCertificationsAdd = () => {
    const newCertification = {};
    certifications = certifications.concat(newCertification);
    anonymousCertifications = anonymousCertifications.concat(newCertification);
    if (!candidate.premium) {
      candidate.premium = {};
      anonymousCandidate.premium = {};
    }
    candidate.premium.certifications = certifications;
    anonymousCandidate.premium.certifications = certifications;
    onCandidateChange({ premium: candidate.premium }, false);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, false);
  };

  const onCertificationsDelete = (idx) => {
    certifications = certifications
      .slice(0, idx)
      .concat(certifications.slice(idx + 1));
    if (!candidate.premium) {
      candidate.premium = {};
      anonymousCandidate.premium = {};
    }
    candidate.premium.certifications = certifications;
    anonymousCandidate.premium.certifications = certifications;
    onCandidateChange({ premium: candidate.premium }, false);
    onAnonymousCandidateChange({ premium: anonymousCandidate.premium }, false);
  };

  return (
    <Block>
      <Button type="button" onClick={onCertificationsAdd}>
        Add Certification
      </Button>
      {certifications.map((cert, idx) => {
        let anonymousCert = anonymousCertifications[idx];
        return (
          <Section>
            <Button type="button" onClick={() => onCertificationsDelete(idx)}>
              Delete Certification
            </Button>
            <FormControl
              label={<Label>{"certification name ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <TextAreaEditable
                candidateValue={cert.name}
                anonymousValue={anonymousCert.name}
                entityName={"name"}
                onChange={(orig, anon, isNew) => {
                  cert.name = orig;
                  updateCertifications(cert, idx, isNew);
                  anonymousCert.name = anon;
                  updateAnonymousCertifications(anonymousCert, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"certification issuer ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <TextAreaEditable
                candidateValue={cert.issuer}
                anonymousValue={anonymousCert.issuer}
                entityName={"issuer"}
                onChange={(orig, anon, isNew) => {
                  cert.issuer = orig;
                  updateCertifications(cert, idx, isNew);
                  anonymousCert.issuer = anon;
                  updateAnonymousCertifications(anonymousCert, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"certification description ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <TextAreaEditable
                candidateValue={cert.description}
                anonymousValue={anonymousCert.description}
                entityName={"description"}
                onChange={(orig, anon, isNew) => {
                  cert.description = orig;
                  updateCertifications(cert, idx, isNew);
                  anonymousCert.description = anon;
                  updateAnonymousCertifications(anonymousCert, idx, isNew);
                }}
              />
            </FormControl>
            <FormControl
              label={<Label>{"certification link ?"}</Label>}
              caption={<EmptyCaption />}
            >
              <TextAreaEditable
                candidateValue={cert.link}
                anonymousValue={anonymousCert.link}
                entityName={"link"}
                onChange={(orig, anon, isNew) => {
                  cert.link = orig;
                  updateCertifications(cert, idx, isNew);
                  anonymousCert.link = anon;
                  updateAnonymousCertifications(anonymousCert, idx, isNew);
                }}
              />
            </FormControl>
          </Section>
        );
      })}
    </Block>
  );
};

const CandidateSubmissionForm = ({
  candidate,
  anonymousCandidate,
  errors,
  onCandidateChange,
  onAnonymousCandidateChange,
}) => {
  if (!candidate) {
    return <div>No information</div>;
  }
  return (
    <form autoComplete="off" noValidate>
      <FormControl
        label={<Label>{"Are you willing to work for a startup ?"}</Label>}
      >
        <RadioGroup
          value={
            (candidate["startupTypes"] || []).length
              ? "YES_INTERESTED"
              : "NOT_INTERESTED"
          }
          onChange={(e) => {
            if (e.target.value === "NOT_INTERESTED") {
              onCandidateChange({ startupTypes: [] });
              onAnonymousCandidateChange({ startupTypes: [] });
              return;
            }
            onCandidateChange({ startupTypes: [e.target.value] });
            onAnonymousCandidateChange({ startupTypes: [e.target.value] });
          }}
          align={ALIGN.vertical}
        >
          <Radio value={"NOT_INTERESTED"}>
            {"No, I am not interested in startups"}
          </Radio>
          <Radio value={"YES_INTERESTED"}>
            {"Yes, I am interested in startups"}
          </Radio>
        </RadioGroup>
      </FormControl>
      <FormControl
        label={<Label>{"Select your notice period"}</Label>}
        caption={<EmptyCaption />}
      >
        <Select
          clearable={false}
          searchable={false}
          defaultOptions={getNoticePeriodOptions()}
          onChange={(value) => {
            onCandidateChange({ noticePeriod: value.id });
            onAnonymousCandidateChange({ noticePeriod: value.id });
          }}
          value={{ id: candidate["noticePeriod"] }}
        />
      </FormControl>
      <FormControl
        label={<Label>{"Current CTC ?"}</Label>}
        caption={<EmptyCaption />}
      >
        <CurrencyEditable
          candidateValue={candidate["totalCTC"]}
          candidateValueCurrency={candidate["totalCTCCurrency"]}
          anonymousValue={anonymousCandidate["totalCTC"]}
          anonymousValueCurrency={anonymousCandidate["totalCTCCurrency"]}
          entityName={"totalCTC"}
          onChange={(orig, anon, origCurrency, anonCurrency) => {
            onCandidateChange({
              totalCTC: orig,
              totalCTCCurrency: origCurrency,
            });
            onAnonymousCandidateChange({
              totalCTC: anon,
              totalCTCCurrency: anonCurrency,
            });
          }}
        />
      </FormControl>
      <FormControl
        label={<Label>{"Expected CTC ?"}</Label>}
        caption={<EmptyCaption />}
      >
        <CurrencyEditable
          candidateValue={candidate["expectedCTC"]}
          candidateValueCurrency={candidate["expectedCTCCurrency"]}
          anonymousValue={anonymousCandidate["expectedCTC"]}
          anonymousValueCurrency={anonymousCandidate["expectedCTCCurrency"]}
          entityName={"expectedCTC"}
          onChange={(orig, anon, origCurrency, anonCurrency) => {
            onCandidateChange({
              expectedCTC: orig,
              expectedCTCCurrency: origCurrency,
            });
            onAnonymousCandidateChange({
              expectedCTC: anon,
              expectedCTCCurrency: anonCurrency,
            });
          }}
        />
      </FormControl>
      <SectionTitle>Basic</SectionTitle>
      <Section>
        <FormControl
          label={
            <Label>
              {"What type of job functions do you wish to have in next role ?"}
            </Label>
          }
          caption={<EmptyCaption />}
        >
          <MultiSelectEditable
            candidateValue={candidate["jobFunctions"]}
            anonymousValue={anonymousCandidate["jobFunctions"]}
            isMultiple
            entityName={"jobFunction"}
            getOptions={async (query) => searchEntity("jobFunction", query)}
            onChange={(orig, anon, isNew) => {
              onCandidateChange({ jobFunctions: orig }, isNew);
              onAnonymousCandidateChange({ jobFunctions: anon }, isNew);
            }}
          />
        </FormControl>
        <FormControl
          label={<Label>{"Which is your preferred job location ?"}</Label>}
          caption={<EmptyCaption />}
        >
          <MultiSelectEditable
            candidateValue={candidate["jobLocations"]}
            anonymousValue={anonymousCandidate["jobLocations"]}
            isMultiple
            entityName={"location"}
            getOptions={async (query) => searchEntity("location", query)}
            onChange={(orig, anon, isNew) => {
              onCandidateChange({ jobLocations: orig }, isNew);
              onAnonymousCandidateChange({ jobLocations: anon }, isNew);
            }}
          />
        </FormControl>
      </Section>
      <SectionTitle>Experience</SectionTitle>
      <ExperienceControl
        candidate={candidate}
        anonymousCandidate={anonymousCandidate}
        onCandidateChange={onCandidateChange}
        onAnonymousCandidateChange={onAnonymousCandidateChange}
      />
      <SectionTitle>Career Gaps</SectionTitle>
      <CareerGapControl
        candidate={candidate}
        anonymousCandidate={anonymousCandidate}
        onCandidateChange={onCandidateChange}
        onAnonymousCandidateChange={onAnonymousCandidateChange}
      />
      <SectionTitle>Education</SectionTitle>
      <EducationControl
        candidate={candidate}
        anonymousCandidate={anonymousCandidate}
        onCandidateChange={onCandidateChange}
        onAnonymousCandidateChange={onAnonymousCandidateChange}
      />
      <SectionTitle>Projects</SectionTitle>
      <ProjectControl
        candidate={candidate}
        anonymousCandidate={anonymousCandidate}
        onCandidateChange={onCandidateChange}
        onAnonymousCandidateChange={onAnonymousCandidateChange}
      />
      <FormControl label={<Label>{"Skills"}</Label>} caption={<EmptyCaption />}>
        <MultiSelectEditable
          candidateValue={candidate["skills"]}
          anonymousValue={anonymousCandidate["skills"]}
          isMultiple
          entityName={"skill"}
          getOptions={async (query) => searchEntity("skill", query)}
          onChange={(orig, anon, isNew) => {
            onCandidateChange({ skills: orig }, isNew);
            onAnonymousCandidateChange({ skills: anon }, isNew);
          }}
        />
      </FormControl>
      <FormControl
        label={<Label>{"Career profile summary ?"}</Label>}
        caption={<EmptyCaption />}
      >
        <TextAreaEditable
          candidateValue={candidate["careerSummary"]}
          anonymousValue={anonymousCandidate["careerSummary"]}
          entityName={"careerSummary"}
          onChange={(orig, anon, isNew) => {
            onCandidateChange({ careerSummary: orig });
            onAnonymousCandidateChange({ careerSummary: anon });
          }}
        />
      </FormControl>
      <FormControl
        label={<Label>{"About new role ?"}</Label>}
        caption={<EmptyCaption />}
      >
        <TextAreaEditable
          candidateValue={candidate["idealNextJob"]}
          anonymousValue={anonymousCandidate["idealNextJob"]}
          entityName={"idealNextJob"}
          onChange={(orig, anon, isNew) => {
            onCandidateChange({ idealNextJob: orig });
            onAnonymousCandidateChange({ idealNextJob: anon });
          }}
        />
      </FormControl>
      <SectionTitle>Premium Features</SectionTitle>
      <SectionTitle>Existing job offers</SectionTitle>
      <ExistingOfferControl
        candidate={candidate}
        anonymousCandidate={anonymousCandidate}
        onCandidateChange={onCandidateChange}
        onAnonymousCandidateChange={onAnonymousCandidateChange}
      />
      <SectionTitle>Work showcases</SectionTitle>
      <WorkshowcasesControl
        candidate={candidate}
        anonymousCandidate={anonymousCandidate}
        onCandidateChange={onCandidateChange}
        onAnonymousCandidateChange={onAnonymousCandidateChange}
      />
      <SectionTitle>Articles</SectionTitle>
      <ArticlesControl
        candidate={candidate}
        anonymousCandidate={anonymousCandidate}
        onCandidateChange={onCandidateChange}
        onAnonymousCandidateChange={onAnonymousCandidateChange}
      />

      <SectionTitle>Patents</SectionTitle>
      <PatentsControl
        candidate={candidate}
        anonymousCandidate={anonymousCandidate}
        onCandidateChange={onCandidateChange}
        onAnonymousCandidateChange={onAnonymousCandidateChange}
      />

      <SectionTitle>Certifications</SectionTitle>
      <CertificationsControl
        candidate={candidate}
        anonymousCandidate={anonymousCandidate}
        onCandidateChange={onCandidateChange}
        onAnonymousCandidateChange={onAnonymousCandidateChange}
      />
    </form>
  );
};

export default CandidateSubmissionForm;
