import React, { useEffect, useState } from "react";
import { Route, useRouteMatch, Switch, Redirect, Link } from "react-router-dom";
import PageHeader from "../components/Header";
import ChangePictureModel from "./ChangePictureModel";
import TutorialModel from "./TutorialModel";
import ShareCandidateModel from "./ShareCandidateModel";
import SidebarWrapper from "../components/SidebarWrapper";
import PageWrapper from "../components/PageWrapper";
import Container from "../components/Container";
import SidebarTalentScoutHome from "../components/SidebarTalentScoutHome";
import SidebarTalentScoutProfile from "../components/SidebarTalentScoutProfile";
import RCDashboardSearch from "./RCDashboardSearch";
import ScoutMyCandidates from "./ScoutMyCandidates";
import ScoutUpload from "./ScoutUpload";
import UserWalletWithdraw from "./UserWalletWithdraw";
import UserWallet from "./UserWallet";
import TalentscoutGST from "./TalentscoutGST";
import ScoutDetailsReview from "./ScoutDetailsReview";
import ScoutDetails from "./ScoutDetails";
import CandidateDetails from "./CandidateDetails";
import TSMyCandidateDetails from "./TSMyCandidateDetails";
import SidebarDetails from "../components/SidebarDetails";
import { getTalentscout } from "../api/talentscout";
import Title from "../components/Title";
import Button from "../components/Button";
import { TALENTSCOUT_PROFILE_DETAILS } from "../util/routes";
import LoadingScreen from "../components/LoadingScreen";
import ScoutVerify from "./ScoutVerify";
import { connect } from "react-redux";
import { setWidgetState } from "../redux/actions/widget";
import ScoutAssignedJDs from "./ScoutAssignedJDs";
import ShareJobModel from "./ShareJobModel";
import ScoutJobDetails from "./ScoutJobDetails";
import TSHandPickCandidateModal from "./TSHandPickCandidateModal";
import { getHomeRedirect, getProfileRedirect } from "../util/common";
import { UploadCandidate } from "./talentscout/UploadCandidate";
import { ScoutReferrals } from "./talentscout/ScoutReferrals";
import { EditReferral } from "./talentscout/EditReferral";
import { Box } from "@chakra-ui/react";

const SidebarHome = ({ page }) => {
  return (
    <SidebarWrapper>
      <SidebarTalentScoutHome page={page} />
    </SidebarWrapper>
  );
};

const SidebarProfile = ({ page }) => {
  return (
    <SidebarWrapper>
      <SidebarTalentScoutProfile page={page} />
    </SidebarWrapper>
  );
};

const SidebarWithBackButton = () => {
  return (
    <SidebarWrapper>
      <SidebarDetails />
    </SidebarWrapper>
  );
};

const TalentscoutSuspended = ({ user }) => {
  const [status, setStatus] = useState("");
  const [statusReason, setStatusReason] = useState("");
  const getTalentscoutData = async () => {
    try {
      let { status, statusReason } = await getTalentscout(user.id);
      setStatus(status);
      setStatusReason(statusReason);
    } catch (e) {}
  };

  useEffect(() => {
    getTalentscoutData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!status) {
    return <LoadingScreen />;
  }

  if (status !== "SUSPENDED" && status !== "PENDING_REVIEW") {
    return <Redirect to={"/talentscout/home/search"} />;
  }

  return (
    <PageWrapper>
      {status === "SUSPENDED" ? (
        <div>
          <Title>Account Suspended</Title>
          <div>Your account has been suspended due to following reasons</div>
          <div>{statusReason}</div>
          <div>
            Please update your profile. Account will be enabled once we verify
            your profile.
          </div>
        </div>
      ) : (
        <div>
          <Title>Account Under Review</Title>
          <div>
            We are verifying your account. Your account will be enabled once the
            verification is done.
          </div>
          <div>Verfication should be completed with in 24-48 hrs</div>
        </div>
      )}
      <Link to={TALENTSCOUT_PROFILE_DETAILS}>
        <Button onClick={() => {}}>Update Profile</Button>
      </Link>
    </PageWrapper>
  );
};

const TalentscoutHomeRoute = connect(
  (state) => ({
    user: state.user,
  }),
  { setWidgetState }
)(({ user, setWidgetState }) => {
  const [status, setStatus] = useState("");
  let { path } = useRouteMatch();

  const checkTutorialToBeShown = () => {
    let isTutorialShown = localStorage.getItem(
      user.id + "." + user.role + ".tutorialShown"
    );
    if (!isTutorialShown) {
      setWidgetState({ isTutorialModelVisible: true });
      localStorage.setItem(user.id + "." + user.role + ".tutorialShown", true);
    }
  };

  const getTalentscoutData = async () => {
    try {
      let { status } = await getTalentscout(user.id);
      setStatus(status);
    } catch (e) {}
  };
  useEffect(() => {
    getTalentscoutData();
    checkTutorialToBeShown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!user.talentScoutProfile) {
    return <Redirect to={getProfileRedirect(user)} />;
  }

  if (status === "SUSPENDED" || status === "PENDING_REVIEW") {
    return <Redirect to={"/talentscout/suspended"} />;
  }

  return (
    <div>
      <Switch>
        <Route path={`${path}/search`}>
          <PageWrapper>
            <SidebarHome page={"search"} />
            <RCDashboardSearch job={{}} />
          </PageWrapper>
        </Route>
        <Route path={`${path}/mycandidates`}>
          <PageWrapper>
            <SidebarHome page={"mycandidates"} />
            <ScoutMyCandidates />
          </PageWrapper>
        </Route>
        <Route path={`${path}/referrals/:referredCandidateId/edit`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <EditReferral />
          </PageWrapper>
        </Route>
        <Route path={`${path}/referrals`}>
          <PageWrapper>
            <SidebarHome page={"referrals"} />
            <ScoutReferrals />
          </PageWrapper>
        </Route>
        <Route path={`${path}/jobs`}>
          <PageWrapper>
            <SidebarHome page={"jobs"} />
            <ScoutAssignedJDs />
          </PageWrapper>
        </Route>
        <Route path={`${path}/upload`}>
          <PageWrapper>
            <SidebarHome page={"upload"} />
            <ScoutUpload />
          </PageWrapper>
        </Route>
        <Route path={`${path}/wallet`}>
          <PageWrapper>
            <SidebarHome page={"wallet"} />
            <UserWallet />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
});

const TalentscoutProfileRoute = (props) => {
  let { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${path}/details`}>
          <PageWrapper>
            <SidebarProfile page={"details"} />
            <ScoutDetails />
          </PageWrapper>
        </Route>
        <Route path={`${path}/taxdetails`}>
          <PageWrapper>
            <SidebarProfile page={"taxdetails"} />
            <TalentscoutGST />
          </PageWrapper>
        </Route>
        <Route path={`${path}/verify`}>
          <PageWrapper>
            <SidebarProfile page={"verify"} />
            <ScoutVerify />
          </PageWrapper>
        </Route>
        <Route path={`${path}/review`}>
          <PageWrapper>
            <SidebarProfile page={"review"} />
            <ScoutDetailsReview />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const TalentscoutWalletRoute = ({ user }) => {
  let { path } = useRouteMatch();
  if (!user.talentScoutProfile) {
    return <Redirect to={getProfileRedirect(user)} />;
  }
  return (
    <div>
      <Switch>
        <Route path={`${path}/withdraw`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <UserWalletWithdraw />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const TalentscoutCandidateRoute = ({ user }) => {
  let { path } = useRouteMatch();
  console.log("user", user);
  if (!user.talentScoutProfile) {
    return <Redirect to={getProfileRedirect(user)} />;
  }
  return (
    <div>
      <Switch>
        <Route path={`${path}/:candidateId/details`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <CandidateDetails />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const TalentscoutMyCandidateRoute = ({ user }) => {
  let { path } = useRouteMatch();
  if (!user.talentScoutProfile) {
    return <Redirect to={getProfileRedirect(user)} />;
  }
  return (
    <div>
      <Switch>
        <Route path={`${path}/:myCandidateId/details`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <TSMyCandidateDetails />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const TalentscoutJobRoute = ({ user }) => {
  let { path } = useRouteMatch();
  if (!user.talentScoutProfile) {
    return <Redirect to={getProfileRedirect(user)} />;
  }
  return (
    <div>
      <Switch>
        <Route path={`${path}/:jobId/details`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <ScoutJobDetails />
          </PageWrapper>
        </Route>
        <Route path={`${path}/:jobId/upload`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <UploadCandidate />
          </PageWrapper>
        </Route>
        {/* <Route path={`${path}/:jobId/upload-candidate`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <UploadCandidate />
          </PageWrapper>
        </Route> */}
      </Switch>
    </div>
  );
};

// async function wait(ms) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, ms);
//   });
// }

const TalentscoutRoute = ({ user }) => {
  let { path } = useRouteMatch();
  if (user && user.role !== "TALENTSCOUT") {
    return <Redirect to={getHomeRedirect(user)} />;
  }
  return (
    <Container>
      <Switch>
        <Route path={`${path}/home`}>
          <TalentscoutHomeRoute user={user} />
        </Route>
        <Route path={`${path}/profile`}>
          <TalentscoutProfileRoute user={user} />
        </Route>
        <Route path={`${path}/candidate`}>
          <TalentscoutCandidateRoute user={user} />
        </Route>
        <Route path={`${path}/mycandidate`}>
          <TalentscoutMyCandidateRoute user={user} />
        </Route>
        <Route path={`${path}/jobs`}>
          <TalentscoutJobRoute user={user} />
        </Route>
        <Route path={`${path}/suspended`}>
          <TalentscoutSuspended user={user} />
        </Route>
        <Route path={`${path}/wallet`}>
          <TalentscoutWalletRoute user={user} />
        </Route>
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      <PageHeader showTutorial />
      <ChangePictureModel />
      <TutorialModel />
      <ShareCandidateModel />
      <ShareJobModel />
      <TSHandPickCandidateModal />
    </Container>
  );
};

export default TalentscoutRoute;
