import React from "react";
import { withRouter } from "react-router-dom";
import SidebarItem from "./SidebarItem";
import {
  ADMIN_CANDIDATE_LIST,
  ADMIN_JOBS_LIST,
  ADMIN_INTERVIEW_REQUESTS,
  ADMIN_USERS_LIST,
  ADMIN_TALENTSCOUT_LIST,
  ADMIN_EMPLOYER_LIST,
  ADMIN_TSREQUEST_LIST,
  ADMIN_TALENTSCOUT_WITHDRAWAL_LIST,
  ADMIN_EMPLOYER_WITHDRAWAL_LIST,
  ADMIN_TRANSACTION_LIST,
  ADMIN_IDPROOFS_LIST,
  ADMIN_CANDIDATE_SUBMISSION_LIST,
  ADMIN_WALLET,
  ADMIN_JOB_APPLICATIONS,
} from "../util/routes";

class SidebarAdmin extends React.Component {
  onPromoteProfileChange() {}

  render() {
    return (
      <div style={{ paddingTop: "32px" }}>
        <SidebarItem
          enabled
          title={"Candidate Submissions"}
          selected={this.props.page === "submissions"}
          onClick={() => {
            this.props.history.push(ADMIN_CANDIDATE_SUBMISSION_LIST);
          }}
        />
        <SidebarItem
          enabled
          title={"Candidates"}
          selected={this.props.page === "candidates"}
          onClick={() => {
            this.props.history.push(ADMIN_CANDIDATE_LIST);
          }}
        />
        <SidebarItem
          title={"Talentscouts"}
          enabled
          selected={this.props.page === "talentscouts"}
          onClick={() => {
            this.props.history.push(ADMIN_TALENTSCOUT_LIST);
          }}
        />
        <SidebarItem
          title={"Employers"}
          enabled
          selected={this.props.page === "employers"}
          onClick={() => {
            this.props.history.push(ADMIN_EMPLOYER_LIST);
          }}
        />
        <SidebarItem
          title={"TS Requests"}
          enabled
          selected={this.props.page === "tsrequests"}
          onClick={() => {
            this.props.history.push(ADMIN_TSREQUEST_LIST);
          }}
        />
        <SidebarItem
          title={"Candidate ID Proofs"}
          enabled
          selected={this.props.page === "idproofs"}
          onClick={() => {
            this.props.history.push(ADMIN_IDPROOFS_LIST);
          }}
        />
        <SidebarItem
          enabled
          title={"Users"}
          selected={this.props.page === "users"}
          onClick={() => {
            this.props.history.push(ADMIN_USERS_LIST);
          }}
        />
        <SidebarItem
          title={"Interview Requests"}
          enabled
          selected={this.props.page === "interview-requests"}
          onClick={() => {
            this.props.history.push(ADMIN_INTERVIEW_REQUESTS);
          }}
        />
        <SidebarItem
          title={"Jobs"}
          enabled
          selected={this.props.page === "jobs"}
          onClick={() => {
            this.props.history.push(ADMIN_JOBS_LIST);
          }}
        />
        <SidebarItem
          title={"Job Applications"}
          enabled
          selected={this.props.page === "job-applications"}
          onClick={() => {
            this.props.history.push(ADMIN_JOB_APPLICATIONS);
          }}
        />
        <SidebarItem
          title={"Scout Withdrawals"}
          enabled
          selected={this.props.page === "talentscout-withdrawals"}
          onClick={() => {
            this.props.history.push(ADMIN_TALENTSCOUT_WITHDRAWAL_LIST);
          }}
        />
        <SidebarItem
          title={"Employer Withdrawals"}
          enabled
          selected={this.props.page === "employer-withdrawals"}
          onClick={() => {
            this.props.history.push(ADMIN_EMPLOYER_WITHDRAWAL_LIST);
          }}
        />
        <SidebarItem
          title={"Transactions"}
          enabled
          selected={this.props.page === "transactions"}
          onClick={() => {
            this.props.history.push(ADMIN_TRANSACTION_LIST);
          }}
        />
        <SidebarItem
          title={"Wallet"}
          enabled
          selected={this.props.page === "wallet"}
          onClick={() => {
            this.props.history.push(ADMIN_WALLET);
          }}
        />
      </div>
    );
  }
}

export default withRouter(SidebarAdmin);
