// const envHostNames = {
//   localhost: "dev",
//   "stage.app.findingjo.com": "stage",
//   "quickhire-beta.web.app": "stage",
//   "app.findingjo.com": "prod",
//   "findingjo-app.web.app": "prod",
// };

var betaConfig = {
  apiKey: "AIzaSyAGTC6wKuRMkXBYjE-Qx0Z_Y0w2zjaXr_M",
  authDomain: "quickhire-beta.firebaseapp.com",
  databaseURL: "https://quickhire-beta.firebaseio.com",
  projectId: "quickhire-beta",
  storageBucket: "quickhire-beta.appspot.com",
  messagingSenderId: "1080353348302",
  appId: "1:1080353348302:web:9174df0e0686b9417fce9f",
  measurementId: "G-0FZS5DCTB3",
};

var productionConfig = {
  apiKey: "AIzaSyDtWgexNHahKqZAoSvT1Ofz90naQft5vt4",
  authDomain: "findingjo-app.firebaseapp.com",
  databaseURL: "https://findingjo-app.firebaseio.com",
  projectId: "findingjo-app",
  storageBucket: "findingjo-app.appspot.com",
  messagingSenderId: "697293233385",
  appId: "1:697293233385:web:2fd1156976a26f69332478",
  measurementId: "G-WPY2NFZH81",
};

const config = {
  development: {
    baseUrl: "http://localhost:5555",
    newBaseUrl: "http://localhost:8080",
    appUrl: "http://localhost:3000",
    shareEndpoint: "http://localhost:5555",
    amplitude: "51aa778bb1209dbe9c969a453edca3fe",
    firebase: betaConfig,
    razorpay: "rzp_test_v8EbMuVNxNnmkK",
    hotjarEnabled: false,
    sentryEnabled: true,
  },
  stage: {
    baseUrl: "https://stage.api.findingjo.com",
    newBaseUrl: "https://node-api-l6fwx2y4mq-el.a.run.app",
    appUrl: "https://quickhire-beta.firebaseapp.com",
    shareEndpoint: "https://stage.share.findingjo.com",
    amplitude: "51aa778bb1209dbe9c969a453edca3fe",
    firebase: betaConfig,
    razorpay: "rzp_test_v8EbMuVNxNnmkK",
    hotjarEnabled: false,
    sentryEnabled: false,
  },
  production: {
    baseUrl: "https://api.findingjo.com",
    newBaseUrl: "https://node-api-l6fwx2y4mq-el.a.run.app",
    appUrl: "https://app.findingjo.com",
    shareEndpoint: "https://share.findingjo.com",
    amplitude: "16b98612f774e8cfa0a8a61ba2bc2e38",
    firebase: productionConfig,
    razorpay: "rzp_live_T1PnJpSwbWw7Z3",
    hotjarEnabled: true,
    sentryEnabled: true,
  },
};

export const env = process.env.REACT_APP_ENV;
export const envConfig = config[env];
export const baseUrl = envConfig["baseUrl"];
export const newBaseUrl = envConfig["newBaseUrl"];
export const shareEndpoint = envConfig["shareEndpoint"];
export const amplitude = envConfig["amplitude"];
export const appUrl = envConfig["appUrl"];
