import {
  SET_USER,
  SET_EMPLOYER,
  SET_CANDIDATE,
  SET_TALENTSCOUT,
  SET_WALLET,
  SET_WORKSPACE,
  SIGNOUT_USER,
  SET_WORKSPACE_MEMBER,
} from "../actionTypes";
import { getUser, getUserConfig } from "../../api/user";
import firebase from "firebase";
export const setUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER,
      payload: {
        ...user,
      },
    });
  };
};

export const setWorkspace = (workspace) => {
  return (dispatch) => {
    dispatch({
      type: SET_WORKSPACE,
      payload: {
        ...workspace,
      },
    });
  };
};

export const setWorkspaceMember = (workspaceMember) => {
  return (dispatch) => {
    dispatch({
      type: SET_WORKSPACE_MEMBER,
      payload: {
        ...workspaceMember,
      },
    });
  };
};

export const setWallet = (wallet) => {
  return (dispatch) => {
    dispatch({
      type: SET_WALLET,
      payload: {
        ...wallet,
      },
    });
  };
};

export const setCandidate = (candidate) => {
  return (dispatch) => {
    dispatch({
      type: SET_CANDIDATE,
      payload: {
        ...candidate,
      },
    });
  };
};

export const setEmployer = (employer) => {
  return (dispatch) => {
    dispatch({
      type: SET_EMPLOYER,
      payload: {
        ...employer,
      },
    });
  };
};

export const setTalentscout = (talentscout) => {
  return (dispatch) => {
    dispatch({
      type: SET_TALENTSCOUT,
      payload: {
        ...talentscout,
      },
    });
  };
};

export const signoutUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: SIGNOUT_USER,
    });
  };
};

export function fetchUser() {
  return async (dispatch, getState) => {
    try {
      let state = getState();
      let user = await getUser(state.user.id);
      dispatch(setUser(user));
    } catch (e) {}
  };
}

export function fetchUserConfig(userId) {
  return async (dispatch, getState) => {
    try {
      if (!firebase.auth().currentUser) return;
      let state = getState();
      let {
        user,
        employer,
        workspace,
        wallet,
        workspaceMember,
        candidate,
        talentscout,
      } = await getUserConfig(userId || state.user.id);
      dispatch(setUser(user));
      dispatch(setEmployer(employer));
      dispatch(setWorkspace(workspace));
      dispatch(setWorkspaceMember(workspaceMember));
      dispatch(setWallet(wallet));
      dispatch(setCandidate(candidate));
      dispatch(setTalentscout(talentscout));
    } catch (e) {}
  };
}
