import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Icon } from "./Icon";

const NextArrow = (props) => (
  <Icon className={props.className} name="arrow-right" size={20} onClick={props.onClick} />
);
const PrevArrow = (props) => (
  <Icon className={props.className} name="arrow-left" size={20} onClick={props.onClick} />
);

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const Carousel = (props) => (
  <div>
    <Slider {...settings}>{props.children}</Slider>
  </div>
);

export default Carousel;
