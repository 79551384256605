import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { Link, withRouter } from "react-router-dom";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import { getTalentscoutsForReview } from "../api/admin";
import Button from "../components/Button";
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table";
import { prettyPrint } from "../util/time";

const EmployerRow = (props) => (
  <StyledRow key={props.index}>
    <StyledCell>
      <div
        style={{
          width: "150px",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        <Link
          to={"/N0VHxB8M/admin/details/talentscout-review/" + props.employer.id}
        >
          {props.employer.email}
        </Link>
      </div>
    </StyledCell>
    <StyledCell>{props.employer.phone}</StyledCell>
    <StyledCell>{props.employer.name}</StyledCell>
    <StyledCell>{prettyPrint(props.employer.modifiedAt)}</StyledCell>
    <StyledCell>
      <Button onClick={() => props.onDetailsClick(props.employer.id)}>
        See Jobs
      </Button>
    </StyledCell>
  </StyledRow>
);

class AdminTalentscoutReviewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      employers: [],
      hasNext: false,
      hasPrev: false,
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    try {
      let { data: employers, hasNext } = await getTalentscoutsForReview(
        this.state.page,
        this.state.limit
      );
      this.setState({
        employers,
        hasNext,
      });
    } catch (e) {
      console.log(e);
    }
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  render() {
    return (
      <div>
        <Title>Talentscouts</Title>
        {/* <FindUser
          onSearch={async (by, value) => {
            try {
              let { data: tsRequests, hasNext } = await getTSRequestsForReview(
                by.id,
                value
              );
              this.setState({
                tsRequests: tsRequests,
                hasNext: hasNext,
              });
            } catch (e) {}
          }}
          onClear={async () => {
            await this.onLoad();
          }}
        /> */}
        <div style={{ width: "1000px" }}>
          <StyledTable>
            <StyledHead>
              <StyledHeadCell>Email</StyledHeadCell>
              <StyledHeadCell>Phone</StyledHeadCell>
              <StyledHeadCell>Name</StyledHeadCell>
              <StyledHeadCell>Updated At</StyledHeadCell>
              <StyledHeadCell>Details</StyledHeadCell>
            </StyledHead>
            <StyledBody>
              <div style={{ marginBottom: "32px" }}>
                {this.state.employers.map((employer, index) => (
                  <EmployerRow
                    key={index}
                    employer={employer}
                    onDetailsClick={(employerId) =>
                      this.props.history.push(
                        "/N0VHxB8M/admin/details/talentscout-jobs/" + employerId
                      )
                    }
                  />
                ))}
              </div>
            </StyledBody>
          </StyledTable>
        </div>
        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminTalentscoutReviewList));
