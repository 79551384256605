import React from "react";
import Button from "../components/Button";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Spacing from "../components/Spacing";
import AddSection from "../components/AddSection";
import ProjectSummary from "../components/ProjectSummary";
import TextArea from "../components/TextArea";
import Popover from "../components/Popover";
import {
  getCandidateProjection,
  getCandidate,
  updateCandidateProfile,
} from "../api/candidate";
import EmptyCaption from "../components/EmptyCaption";
import Input from "../components/Input";
import Modal from "../components/Modal";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import Select from "../components/Select";
import Title from "../components/Title";
import { FormControl } from "baseui/form-control";
import Label from "../components/Label";
import { KIND as BUTTON_KIND } from "baseui/button";
import { CANDIDATE_PROFILE_EXPECTATION } from "../util/routes";
import LoadingScreen from "../components/LoadingScreen";
import * as Analytics from "../analytics";
import { TRIGGER_TYPE } from "baseui/popover";
import Bold from "../components/Bold";
import { toaster } from "baseui/toast";
import { Block } from "baseui/block";
import { CenterContainer } from "../components/CenterContainer";
import { searchEntity } from "../api/entity";

// const PROJECTS_PLACEHOLDER =
//   "Brief Project Intro\n\n\nTeam & Your role\n\n\nProblem  (Challenge/Project Goal/Achievement)\n\n\nSolution (Your contribution & not teams)\n\n\nResults  (the outcome of your effort)\n\n\nSkills used in this project";
class CandidateProfileProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isFetching: true,
      isAdding: false,
      isSaving: false,
      projectList: [],
      mode: "ADD",
      experienceCompanies: [],
      modalProps: {},
      editId: "",
      skills: [],
      careerSummary: "",
      idealNextJob: "",
      values: {
        company: "",
        briefIntro: "",
        role: "",
        outcome: "",
        teamSize: 0,
        skills: [],
      },
      errors: {},
    };
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && typeof this.state.values[id] === "undefined") {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let errors = {};
    ["company", "briefIntro"].forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });
    if (isErrorFound) {
      this.setState({
        errors,
      });
    } else {
      console.log(this.state.values);
      await this.onAddProject(this.state.values);
    }
  };

  getValidations(id) {
    switch (id) {
      case "company":
        return { requiredSelect: true };
      case "briefIntro":
      case "idealNextJob":
        return { required: true };
      default:
        return {};
    }
  }

  async componentDidMount() {
    Analytics.logEvent("[C] page view profile projects");
    this.setState({
      isFetching: true,
    });
    try {
      let { projects: projectList } =
        (await getCandidateProjection(this.props.user.id, "projects")) || {};
      this.setState({
        projectList: projectList || [],
      });
      try {
        let candidate = await getCandidate(this.props.user.id);
        let companies = (candidate.experience || []).map((ex) => ex.company);
        let colleges = (candidate.education || []).map((ex) => ex.college);
        let experiencePlaces = colleges.concat(companies);
        this.setState({
          experienceCompanies: experiencePlaces,
        });
      } catch (e) {
        console.log(e);
      }
      this.setState({
        isFetching: false,
      });
    } catch (ex) {
      this.setState({
        isFetching: false,
      });
      console.log(ex);
    }
  }

  async onAddProject(values) {
    Analytics.logEvent("[C] click add achievement");
    this.setState({
      isModalVisible: false,
      isAdding: true,
    });
    try {
      let projectList = this.state.projectList;
      if (this.state.mode === "ADD") {
        projectList = projectList.concat(values);
      } else {
        projectList[this.state.editIndex] = values;
      }
      await updateCandidateProfile(this.props.user.Id, "projects", {
        projects: projectList,
      });
      this.setState({
        isModalVisible: false,
        modalProps: {},
        projectList,
        isAdding: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        isAdding: false,
      });
    }
  }

  render() {
    if (this.state.isFetching)
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    let props = this.props;
    return (
      <CenterContainer>
        <Title>Projects</Title>
        <FormControl
          label={
            <Label
              appendText={"(minimum 2)"}
              subtitle={
                <div>
                  <div>
                    Highlighting your best projects is going to takes some time.
                    Putting in that extra effort is what's going to distinguish
                    you from other candidates Edit. Edit again. Edit again till
                    you perfect it.
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        fontSize: "12px",
                        color: "#515151",
                      }}
                    >
                      <Popover
                        triggerType={TRIGGER_TYPE.hover}
                        content={
                          <div
                            style={{
                              maxWidth: 420,
                              padding: "16px",
                              fontSize: ".8rem",
                            }}
                          >
                            <div>
                              <div style={{ marginBottom: "16px" }}>
                                <Bold>About the Project</Bold>
                                <div style={{ color: "#919191" }}>
                                  1mg is an online pharmacy. Patients can search
                                  for Medicines and we provided search results
                                  with an exact string match, generic medicines,
                                  and possible alternatives. The medicine search
                                  database contained 50+million records
                                </div>
                              </div>
                              <div style={{ marginBottom: "16px" }}>
                                <Bold>
                                  Domain: Healthcare | Funded Product Startup |
                                  B2C | Web & Mobile app
                                </Bold>
                                <div style={{ color: "#919191" }}>
                                  Project Duration 3 months
                                </div>
                              </div>
                              <div style={{ marginBottom: "16px" }}>
                                <Bold>Team & Your role</Bold>
                                <div style={{ color: "#919191" }}>
                                  I am part of the search team consisting of 5
                                  members | 1 Product Manager | 2 Full-Stack
                                  developers (Including me) | 1 designer | 1
                                  Backend developer
                                </div>
                              </div>
                              <div style={{ marginBottom: "16px" }}>
                                <Bold>Team & Your role</Bold>
                                <div style={{ color: "#919191" }}>
                                  The challenge/Problem/Project Goal
                                </div>
                                <div style={{ color: "#919191" }}>
                                  We were observing that the search result page
                                  users were bouncing off 89% of the time and
                                  the root cause was due to long result-page
                                  loading times. As a team, we had identified
                                  and implemented many solutions to solve this
                                  challenge, including search query
                                  optimization, search API optimization, bloated
                                  database cleanup to remove old and stale
                                  information, etc.
                                </div>
                              </div>
                            </div>
                            <div style={{ marginBottom: "16px" }}>
                              <Bold>
                                The solution & Your contribution (not teams)
                              </Bold>
                              <div style={{ color: "#919191" }}>
                                My role was to optimize search API performance
                              </div>
                              <div style={{ color: "#919191" }}>
                                1) By caching the search results. When the user
                                ends up entering a search (or part of it) that
                                has already been entered previously, the results
                                will be retrieved from the cache, instead of
                                requesting them from the search database, making
                                the application much faster. The key challenges
                                solved where when to do cache refresh and cache
                                clearing
                              </div>
                              <div style={{ color: "#919191" }}>
                                2) Reduce the Server API load by Debouncing
                              </div>
                              <div style={{ color: "#919191" }}>
                                By delaying the processing of the search string
                                until the user has stopped typing for a
                                predetermined amount of time. This drastically
                                reduced the number of API calls sent to the
                                backend server.
                              </div>
                              <div style={{ color: "#919191" }}>
                                3) Keeping the user engaged with relevant
                                loading indicator and pagination
                              </div>
                            </div>
                            <div style={{ marginBottom: "16px" }}>
                              <Bold>Results (the outcome of your effort)</Bold>
                              <div style={{ color: "#919191" }}>
                                The search server query response time reduced
                                from 53% to 37% and we could bring down the
                                bounce rate from 89% to 45%
                              </div>
                            </div>
                            <div style={{ marginBottom: "16px" }}>
                              <Bold>
                                Skills/frameworks used in this project
                              </Bold>
                              <div style={{ color: "#919191" }}>
                                ElasticSearch, JSON/REST, Java
                              </div>
                            </div>
                          </div>
                        }
                      >
                        <div
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          Example
                        </div>
                      </Popover>
                    </div>
                  </div>
                </div>
              }
            >
              Add your best projects
            </Label>
          }
        >
          <div>
            {this.state.projectList.map((project, index) => (
              <ProjectSummary
                key={index}
                id={project.id}
                isLast={index === this.state.projectList.length - 1}
                company={project.company.name}
                briefIntro={project.briefIntro}
                history={props.history}
                isFirst={index === 0}
                index={index}
                updateEdit={(index) => {
                  let editable = this.state.projectList[index];
                  this.setState({
                    isModalVisible: true,
                    mode: "EDIT",
                    editIndex: index,
                    values: {
                      company: editable.company || "",
                      briefIntro: editable.briefIntro || "",
                      role: editable.role || "",
                      outcome: editable.outcome || "",
                      teamSize: editable.teamSize || 0,
                      skills: editable.skills || [],
                    },
                    errors: {},
                  });
                }}
                onRemove={async (index) => {
                  Analytics.logEvent("[C] click remove achievement");
                  try {
                    let projectList = this.state.projectList
                      .slice(0, index)
                      .concat(this.state.projectList.slice(index + 1));
                    await updateCandidateProfile(
                      this.props.user.Id,
                      "projects",
                      {
                        projects: projectList,
                      }
                    );
                    this.setState({
                      projectList,
                    });
                  } catch (e) {
                    console.log("error while deleting");
                  }
                }}
              />
            ))}
          </div>
        </FormControl>

        {this.state.projectList.length < 3
          ? new Array(3 - this.state.projectList.length).fill(1).map((el) => (
              <Block marginBottom="scale600">
                <AddSection
                  text={"Add project"}
                  onClick={() =>
                    this.setState({
                      isModalVisible: true,
                      mode: "ADD",
                      values: {
                        company: "",
                        briefIntro: "",
                      },
                      errors: {},
                    })
                  }
                />
              </Block>
            ))
          : null}
        <Spacing size={32} />
        <Button
          isLoading={this.state.isSaving}
          onClick={async () => {
            if (this.state.projectList.length < 2) {
              toaster.negative(<div>Add minimum 2 projects</div>, {
                autoHideDuration: 3000,
              });
              return;
            }
            try {
              await updateCandidateProfile(this.props.user.Id, "projects", {
                projects: this.state.projectList,
              });
              this.setState({
                isSaving: false,
              });
            } catch (e) {
              this.setState({
                isSaving: false,
              });
              return;
            }
            let candidateOnboarding = this.props.user.candidateOnboarding || [];
            if (candidateOnboarding.indexOf("projects") === -1) {
              candidateOnboarding.push("projects");
            }
            this.props.setUser({
              candidateOnboarding,
            });
            this.props.history.push(CANDIDATE_PROFILE_EXPECTATION);
          }}
        >
          Save & Continue
        </Button>
        <Spacing size={32} />
        <Modal
          animate
          autoFocus
          isOpen={this.state.isModalVisible}
          closeable
          onClose={() => this.setState({ isModalVisible: false })}
        >
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <ModalHeader>
              <Title>
                {this.state.mode === "ADD" ? "Add Project" : "Edit Project"}
              </Title>
            </ModalHeader>
            <ModalBody>
              <FormControl
                label={<Label>{"Select the company"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["company"]}
              >
                <Select
                  placeholder={"Select Company"}
                  searchable={false}
                  clearable={false}
                  defaultOptions={this.state.experienceCompanies.map((ex) => {
                    ex.isCreatable = false;
                    return ex;
                  })}
                  onChange={(value) => {
                    this.updateFormValues("company", value);
                  }}
                  value={this.state.values["company"]}
                />
              </FormControl>
              <FormControl
                label={
                  <Label
                    appendText={
                      (this.state.values["briefIntro"] || "").length +
                      "/300 chars"
                    }
                  >
                    {"Description"}
                  </Label>
                }
              >
                <TextArea
                  id={"briefIntro"}
                  resize
                  height={"50px"}
                  onChange={(e) => {
                    if (typeof e.target.value !== "undefined") {
                      this.updateFormValues(
                        "briefIntro",
                        e.target.value.substring(0, 300)
                      );
                    }
                  }}
                  value={this.state.values["briefIntro"]}
                  autoComplete={"off"}
                />
              </FormControl>
              <FormControl
                label={
                  <Label
                    appendText={
                      (this.state.values["role"] || "").length + "/300 chars"
                    }
                  >
                    {"Your role and contribution"}
                  </Label>
                }
              >
                <TextArea
                  id={"role"}
                  resize
                  height={"50px"}
                  onChange={(e) => {
                    if (typeof e.target.value !== "undefined") {
                      this.updateFormValues(
                        "role",
                        e.target.value.substring(0, 300)
                      );
                    }
                  }}
                  value={this.state.values["role"]}
                  autoComplete={"off"}
                />
              </FormControl>
              <FormControl
                label={
                  <Label
                    appendText={
                      (this.state.values["outcome"] || "").length + "/300 chars"
                    }
                  >
                    {"Project result"}
                  </Label>
                }
              >
                <TextArea
                  id={"outcome"}
                  resize
                  height={"50px"}
                  onChange={(e) => {
                    if (typeof e.target.value !== "undefined") {
                      this.updateFormValues(
                        "outcome",
                        e.target.value.substring(0, 300)
                      );
                    }
                  }}
                  value={this.state.values["outcome"]}
                  autoComplete={"off"}
                />
              </FormControl>
              <FormControl label={<Label>{"Team size"}</Label>}>
                <Input
                  id={"teamSize"}
                  onChange={(e) => {
                    let ts = +e.target.value;
                    if (isNaN(ts)) {
                      this.updateFormValues("teamSize", 0);
                    } else {
                      this.updateFormValues("teamSize", parseInt(ts));
                    }
                  }}
                  value={this.state.values["teamSize"]}
                  autoComplete={"off"}
                />
              </FormControl>
              <FormControl
                label={
                  <Label
                    appendText={
                      "(" +
                      (this.state.values["skills"] || "").length +
                      "/3 skills)"
                    }
                  >
                    {"Skills used in this project"}
                  </Label>
                }
                caption={<EmptyCaption />}
                error={this.state.skillsError}
              >
                <Select
                  creatable
                  multi
                  getOptions={async (query) => searchEntity("skill", query)}
                  onChange={(value) => {
                    if ((value || []).length > 3) {
                      return;
                    }
                    this.updateFormValues("skills", value);
                  }}
                  value={this.state.values["skills"]}
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button
                type={"button"}
                kind={BUTTON_KIND.tertiary}
                onClick={() => this.setState({ isModalVisible: false })}
                style={{ marginRight: "16px" }}
              >
                Cancel
              </Button>
              <Button type={"submit"} isLoading={this.state.isAdding}>
                {this.state.mode === "ADD" ? "Add Project" : "Update Project"}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateProfileProjects));
