import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import { getJobById, updateJob, renewJob, closeJob } from "../api/job";
import Job from "../components/Job";
import Bold from "../components/Bold";
import Title from "../components/Title";
import Button from "../components/Button";
import LoadingScreen from "../components/LoadingScreen";
import PostJobForm from "../components/PostJobForm";
import Modal from "../components/Modal";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import { KIND as BUTTON_KIND, KIND } from "baseui/button";
import Card from "../components/Card";
import { prettyPrint } from "../util/time";
import PopConfirm from "../components/PopConfirm";
import Popover from "../components/Popover";
import { Icon } from "../components/Icon";
import { Block } from "baseui/block";
import { CenterContainer } from "../components/CenterContainer";
import { LabelSmall, ParagraphSmall } from "baseui/typography";
import { RichTextViewer } from "../components/RichTextEditor";

class EmployerJobDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
      isFetching: true,
      isModalVisible: false,
      isConfirmVisible: false,
      editJob: {},
    };
  }

  toggleConfirm(visible, callback) {
    this.setState(
      {
        isConfirmVisible: visible,
      },
      callback
    );
  }

  async componentDidMount() {
    this.onLoad();
  }

  onLoad = async () => {
    this.setState({
      isFetching: true,
    });
    let { jobId } = this.props.match.params;
    try {
      let { job, jobApplication } = await getJobById(jobId);
      this.setState({
        job,
        jobApplication,
      });
    } catch (e) {}
    this.setState({
      isFetching: false,
    });
  };

  onUpdateJob = async (job) => {
    try {
      let newValues = JSON.parse(JSON.stringify(job));
      newValues.salaryCurrency = job.salaryCurrency.id;
      newValues.jobDescription = job.jobDescription.toString("html");
      await updateJob(newValues.id, newValues);
      this.setState(
        {
          isModalVisible: false,
        },
        async () => {
          await this.onLoad();
        }
      );
    } catch (ex) {
      console.log(ex);
    }
  };

  onCloseJob = async () => {
    try {
      await closeJob(this.state.job.id);
      await this.onLoad();
    } catch (ex) {
      console.log(ex);
    }
  };

  onRenewJob = async () => {
    try {
      await renewJob(this.state.job.id);
      await this.onLoad();
    } catch (ex) {
      console.log(ex);
    }
  };

  renderCloseButton = () => {
    return this.state.job.status !== "CLOSED" ? (
      <PopConfirm
        showArrow
        title={
          <div>
            <Bold>Are you sure to close this Job Description?</Bold>
            <div style={{ marginBottom: "16px" }}>
              After closing, you will not be able to interview more candidates
              for this job.
            </div>
            <div>
              Candidates will still be able to approve or reject any pending
              interview requests that you have already shared, based on this job
              description.
            </div>
          </div>
        }
        onConfirm={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          await this.onCloseJob();
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <Button kind={KIND.minimal}>Close</Button>
      </PopConfirm>
    ) : null;
  };

  renderEditButton = () => {
    return this.state.job.status !== "CLOSED" ? (
      <Block marginRight="scale400">
        <Button
          kind="tertiary"
          onClick={() => {
            this.setState({
              editJob: {
                ...this.state.job,
                salaryCurrency: { id: "INR", name: "₹" },
              },
              isModalVisible: true,
            });
          }}
        >
          Edit
        </Button>
      </Block>
    ) : null;
  };

  renderViewCandidates = () => {
    return (
      <Button
        onClick={() =>
          this.props.history.push(
            `/employer/jobapplication/${this.state.job.id}/search`
          )
        }
      >
        View Candidates
      </Button>
    );
  };

  renderDuplicateButton = () => {
    return (
      <Button
        kind={KIND.minimal}
        onClick={() => {
          this.props.history.push(
            "/employer/home/postjob?fromId=" + this.state.job.id
          );
        }}
      >
        Duplicate
      </Button>
    );
  };

  renderRenewButton = () => {
    return this.state.job.status === "REVIEWED" ? (
      <Button kind={KIND.minimal} onClick={this.onRenewJob}>
        Renew
      </Button>
    ) : null;
  };

  getActionButtons = () => {
    const moreButtons = [
      this.renderRenewButton(),
      this.renderDuplicateButton(),
      this.renderCloseButton(),
    ].filter(Boolean);
    return (
      <>
        {this.renderEditButton()}
        {this.renderViewCandidates()}
        {moreButtons.length > 0 && (
          <Popover
            content={
              <div>
                {moreButtons.map((moreButton) => (
                  <div>{moreButton}</div>
                ))}
              </div>
            }
          >
            <Block $cursor="pointer">
              <Icon size={24} name="more-vertical" />
            </Block>
          </Popover>
        )}
      </>
    );
  };

  render() {
    if (this.state.isFetching) return <LoadingScreen />;
    let targetText = "";
    const targetCompanies = this.state.job.targetCompanies || [];
    if (targetCompanies.length) {
      const targetComapaniesLen = targetCompanies.length;
      targetText = targetCompanies
        .slice(0, targetComapaniesLen - 1)
        .map((company) => company.name)
        .join(",");
      targetText += " and " + targetCompanies[targetComapaniesLen - 1].name;
    }
    return (
      <CenterContainer>
        <Title>Job Posting</Title>
        {this.state.job.status === "ONHOLD" ? (
          <Card
            style={{
              border: "1px solid #F8756C",
              marginBottom: "16px",
            }}
          >
            <div>{"Latest update to this job posting is ON HOLD"}</div>
            <div>{this.state.job.statusReason}</div>
            <div>{"Please update your job posting and submit again"}</div>
          </Card>
        ) : null}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "16px",
            alignItems: "center",
          }}
        >
          {this.getActionButtons()}
        </div>
        {this.state.job.status !== "CLOSED" && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingBottom: "16px",
            }}
          >
            <div>{"Expire on " + prettyPrint(this.state.job.expireAt)}</div>
          </div>
        )}
        <Job
          job={this.state.job}
          jobApplication={this.state.jobApplication}
          onApplyJob={this.onLoad}
          role={"EMPLOYER"}
        />
        {targetText.length && (
          <Block>
            <LabelSmall>Note to the Recruiter:</LabelSmall>
            <ParagraphSmall>
              {"When possible find candidates from " + targetText}
            </ParagraphSmall>
            <Button
              kind="tertiary"
              onClick={() => {
                this.setState({
                  editJob: {
                    ...this.state.job,
                    salaryCurrency: { id: "INR", name: "₹" },
                  },
                  isModalVisible: true,
                });
              }}
            >
              Edit target companies
            </Button>
          </Block>
        )}
        <Block marginTop="scale400">
          <LabelSmall>Recruiter Note</LabelSmall>
          <RichTextViewer value={this.state.job.recruiterNote} />
        </Block>
        <Modal
          animate
          autoFocus
          isOpen={this.state.isModalVisible}
          closeable
          onClose={() => this.toggleConfirm(true)}
        >
          <ModalHeader>
            <Title>Edit Job Posting</Title>
          </ModalHeader>
          <ModalBody>
            <PostJobForm
              buttonLabel={"Update Job"}
              job={this.state.editJob}
              onSubmit={this.onUpdateJob}
            />
          </ModalBody>
        </Modal>
        <Modal
          autoFocus={false}
          onClose={() => this.toggleConfirm(false)}
          isOpen={this.state.isConfirmVisible}
        >
          <ModalHeader>Confirm</ModalHeader>
          <ModalBody>Do you want to close this ?</ModalBody>
          <ModalFooter>
            <Button
              type={"button"}
              kind={BUTTON_KIND.tertiary}
              buttonStyle={{ marginRight: "16px" }}
              onClick={() => {
                this.toggleConfirm(false, () =>
                  this.setState({
                    isModalVisible: false,
                  })
                );
              }}
            >
              Close
            </Button>
            <Button type={"button"} onClick={() => this.toggleConfirm(false)}>
              Don't close
            </Button>
          </ModalFooter>
        </Modal>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(EmployerJobDetails));
