import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Project, Entity } from "src/lib/types/candidate";
import { FormLabel, FormErrorMessage } from "src/lib/uikit";
import { Box, Flex, Select, Textarea, Button } from "@chakra-ui/react";
import { FiCheck, FiTrash, FiX } from "react-icons/fi";
import { ProjectRow } from "./ProjectList";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useToast } from "@chakra-ui/react";
import { AutoComplete, AutoCompleteItem } from "src/components/AutoComplete";
import { searchEntity } from "src/api/entity";

const schema = yup
  .object({
    company: yup
      .object()
      .shape({
        id: yup.string().required("Company/College is required"),
        name: yup.string(),
        isCreatable: yup.bool().optional(),
      })
      .required("Required"),
    briefIntro: yup.string().required("Required"),
    refId: yup.string(),
    refType: yup.string(),
  })
  .required();

export type ProjectFormProps = {
  projectRow: ProjectRow;
  projectCompanies: Entity[];
  onSave: (e: Project) => void;
  onDiscard: () => void;
  onDelete: () => void;
  shouldValidate: boolean;
};

export const ProjectForm = ({
  projectRow: { project, isNew },
  projectCompanies,
  onSave,
  onDiscard,
  onDelete,
  shouldValidate,
}: ProjectFormProps) => {
  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Project>({
    defaultValues: {
      ...project,
      refId: "",
      refType: "",
    },
    ...(shouldValidate && { resolver: yupResolver(schema) }),
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  useEffect(() => reset(project), [project]);
  const toast = useToast();
  const onFormSubmit = async (values: any) => {
    setIsSaving(true);
    values.company = {
      ...values.company,
      name: values.company?.originalName,
    };
    await onSave(values as Project);
    setIsSaving(false);
  };
  const onFormError = async () => {
    toast({
      title: "Validation error",
      description: "Please check the fields in the form",
      status: "error",
    });
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
        <Box mb={6} mt={4}>
          <FormLabel htmlFor={`college`}>Company/College Name</FormLabel>
          {/* <Controller
            name={`company`}
            control={control}
            render={({ field: { value, ...rest } }) => {
              return (
                <>
                  <Select
                    placeholder=" "
                    fontSize="md"
                    size="lg"
                    {...rest}
                    value={value?.id}
                    onChange={(event) => {
                      setValue(
                        `company`,
                        projectCompanies.find(
                          (c) => c.id === event.target.value
                        ) as Entity
                      );
                    }}
                    id={`company`}
                  >
                    {projectCompanies.map(({ id, name }, idx) => (
                      <option key={idx} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {errors.company?.id?.message}
                  </FormErrorMessage>
                </>
              );
            }}
          /> */}
          <Controller
            name={`company`}
            control={control}
            render={({ field: { value, ...rest } }) => {
              return (
                <>
                  <AutoComplete
                    {...rest}
                    items={projectCompanies}
                    id={`roles`}
                    isMultiple={false}
                    isSearchable={false}
                    selected={value}
                    onItemSelect={(
                      value: AutoCompleteItem | AutoCompleteItem[]
                    ) => {
                      setValue(`company`, value as AutoCompleteItem);
                      setValue(`refId`, (value as any).refId);
                      setValue(`refType`, (value as any).refType);
                    }}
                    fetchItems={async (q) => searchEntity("company", q)}
                  />
                  <Box height={"40px"}>
                    <FormErrorMessage>
                      {errors.company?.id?.message}
                    </FormErrorMessage>
                  </Box>
                </>
              );
            }}
          />
        </Box>

        <FormLabel
          htmlFor="phone"
          fontWeight="500"
          fontSize="sm"
          color="gray.500"
          mt={2}
        >
          Description
        </FormLabel>
        <Controller
          name={`briefIntro`}
          control={control}
          render={({ field }) => (
            <Textarea
              {...field}
              id={`briefIntro`}
              size={"lg"}
              fontSize={"md"}
              onChange={(e) => {
                setValue(`briefIntro`, e.target.value);
              }}
            />
          )}
        />
        <FormErrorMessage>{errors.briefIntro?.message}</FormErrorMessage>
        <Flex mt={4} justifyContent="space-between">
          <Flex>
            <Button
              type="submit"
              mr={2}
              leftIcon={<FiCheck />}
              isLoading={isSaving}
              loadingText="Saving"
            >
              Save Project
            </Button>
            {isNew && (
              <Button
                type="button"
                onClick={onDiscard}
                variant={"ghost"}
                leftIcon={<FiX />}
              >
                Discard
              </Button>
            )}
          </Flex>
          {!isNew && (
            <Button
              type="button"
              onClick={async () => {
                setIsDeleting(true);
                await onDelete();
                setIsDeleting(false);
              }}
              variant={"ghost"}
              isLoading={isDeleting}
              loadingText="Deleting"
              leftIcon={<FiTrash />}
            >
              Delete
            </Button>
          )}
        </Flex>
      </form>
    </Box>
  );
};
