import React, { useEffect, useState, useMemo } from "react";
import {
  Heading,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Button,
  useDisclosure,
  Flex,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
  ThemeTypings,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import {
  getNextReferCandidateForReview,
  getReferCandidates,
  ReferredCandidateProps,
  updateReferCandidate,
} from "src/api/referred_candidate";
import { Column, useTable } from "react-table";
import { FiArrowRight } from "react-icons/fi";
import { prettyPrint } from "src/util/time";
import { PDFViewer } from "src/lib/components/PDFViewer";
import { Pagination } from "src/lib/components/Pagination";
import { JobPicker } from "src/screens/tools/JobPicker";
import { getJobById } from "src/api/job";
import { FaChevronDown } from "react-icons/fa";
import { getFileExtension } from "src/util/file";
import { useToast } from "@chakra-ui/react";
import { useFirebaseStorage } from "src/lib/hooks/useFirebaseStorage";
import { keyBy } from "lodash";
import { ReferCandidateForm } from "src/lib/forms/Talentscout/ReferCandidate/ReferCandidateForm";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

const FileUploadForm = ({
  userId,
  onSave,
}: {
  userId: string;
  onSave: (url: string) => void;
}) => {
  const toast = useToast();
  const { uploadFile } = useFirebaseStorage();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, touchedFields, isSubmitting },
  } = useForm({});
  const onSubmit = async ({ selectedFile: files }: any) => {
    if (!files.length) return;
    const f = files[0];
    const fileToUpload = (f as unknown) as File;
    const fileToUploadExt = getFileExtension(fileToUpload);
    try {
      const fileUrl = await uploadFile(
        `/resumes/${userId}_${Date.now()}.${fileToUploadExt}`,
        fileToUpload
      );
      await onSave(fileUrl);
    } catch (e) {
      toast({
        title: "Error while uploading",
        description: "Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
  };

  return (
    <Box background={"gray.50"} borderRadius={"md"} p={2} mb={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={!!(touchedFields.url && errors.url)}>
          <FormLabel htmlFor="selectedFile">
            <Text fontWeight={"600"}>Update File</Text>
          </FormLabel>
          <Input
            {...register("selectedFile")}
            id="selectedFile"
            type="file"
            pt={"5px"}
            color="gray.400"
          />
          {touchedFields.selectedFile && errors.selectedFile ? (
            <FormErrorMessage>{errors.selectedFile}</FormErrorMessage>
          ) : null}
        </FormControl>
        <Button
          mt={2}
          mb={4}
          type="submit"
          isLoading={isSubmitting}
          colorScheme={"blue"}
        >
          Save
        </Button>
      </form>
    </Box>
  );
};

const CandidateDrawer = ({
  candidate,
  isOpen,
  onOpen,
  onClose,
  onUpdated,
}: any) => {
  const [job, setJob] = useState<any>(null);
  const { status, statusMessage, jobId, email } = candidate;
  const [statusMessageVal, setStatusMessageVal] = useState(statusMessage);
  const toast = useToast();
  const fetchJob = async (id: string) => {
    try {
      const { job: data } = await getJobById(id);
      setJob(data);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (jobId) fetchJob(jobId);
  }, [jobId]);
  useEffect(() => {
    setStatusMessageVal(statusMessage);
  }, [statusMessage]);
  if (!email) return null;
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{email}</DrawerHeader>

        <DrawerBody>
          <Tabs variant="enclosed">
            <TabList>
              <Tab>Details</Tab>
              <Tab>Job</Tab>
              <Tab>LinkedIn</Tab>
              <Tab>Resume</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <ReferCandidateForm
                  values={{
                    ...candidate,
                    resumeFile: candidate.resumeUrl,
                    linkedInResumeFile: candidate.linkedInResumeUrl,
                    hasJobOffers: !!candidate.jobOffer,
                  }}
                  disabledFields={["email", "phone"]}
                  showStatus
                  onSubmit={async (values) => {
                    console.log(values);
                    await updateReferCandidate(values.id, values);
                    toast({
                      title: "Saved successfully",
                      status: "success",
                      duration: 4000,
                      isClosable: true,
                    });
                    await onUpdated?.();
                    await onClose();
                  }}
                  submitText={"Save Candidate"}
                  resetOnSubmit={false}
                />
              </TabPanel>
              <TabPanel>
                {job && (
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>Label</Th>
                        <Th>Value</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>Job Title</Td>
                        <Td>{job.jobTitle.name}</Td>
                      </Tr>
                      <Tr>
                        <Td>Company</Td>
                        <Td>{job.company.name}</Td>
                      </Tr>
                    </Tbody>
                  </Table>
                )}
              </TabPanel>
              <TabPanel>
                <FileUploadForm
                  userId={candidate.talentscoutId}
                  onSave={async (url) => {
                    await updateReferCandidate(candidate.id, {
                      linkedInResumeUrl: url,
                    });
                    await onUpdated();
                  }}
                />
                <PDFViewer source={candidate.linkedInResumeUrl} />
              </TabPanel>
              <TabPanel>
                <FileUploadForm
                  userId={candidate.talentscoutId}
                  onSave={async (url) => {
                    await updateReferCandidate(candidate.id, {
                      resumeUrl: url,
                    });
                    await onUpdated();
                  }}
                />
                <PDFViewer source={candidate.resumeUrl} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export const getStatusColorScheme = (
  status?: string
): ThemeTypings["colorSchemes"] => {
  switch (status) {
    case "PREAPPROVED":
      return "yellow";
    case "DATAVERIFIED":
      return "orange";
    case "APPROVED":
      return "green";
    case "REJECTED":
      return "red";
  }
  return "gray";
};

export const PreApproval = () => {
  const [page, setPage] = useState<number>(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [referredCandidates, setReferredCandidates] = useState<
    ReferredCandidateProps[]
  >([]);
  const [selectedCandidate, setSelectedCandidate] = useState<
    ReferredCandidateProps | undefined
  >();
  const [talentscouts, setTalentscouts] = useState<any>();
  const [selectedJob, setSelectedJob] = useState<any>(null);
  const drawerDisclosure = useDisclosure();
  const jobPickerDisclosure = useDisclosure();
  const history = useHistory();
  const limit = 10;
  const fetchReferredCandidates = async (
    jobId: string,
    _page: number,
    _limit: number
  ) => {
    setIsFetching(true);
    try {
      const {
        data: _data,
        hasNext: _hasNext,
        talentscouts,
      } = await getReferCandidates(
        { jobId, status: "CREATED", sortField: "createdAt", sortOrder: "ASC" },
        _page,
        _limit
      );
      setReferredCandidates(_data);
      setTalentscouts(keyBy(talentscouts, "id"));
      setHasNext(_hasNext);
      if (drawerDisclosure.isOpen && selectedCandidate?.id) {
        const updated = _data.find(
          (c: ReferredCandidateProps) => c.id === selectedCandidate.id
        );
        if (updated) setSelectedCandidate(updated);
      }
    } catch (e) {}
    setIsFetching(false);
  };
  // useEffect(() => {
  //   fetchReferredCandidates(selectedJob?.id, page, limit);
  // }, [selectedJob?.id, page, limit]);
  const data = useMemo(() => referredCandidates, [referredCandidates]);
  const columns = useMemo<Column<ReferredCandidateProps>[]>(
    () => [
      { Header: "Email", accessor: "email" },
      { Header: "Phone", accessor: "phone" },
      {
        Header: "Talentscout",
        accessor: ({ talentscoutId }) => {
          return (
            <Text color={"gray"}>{talentscouts?.[talentscoutId]?.name}</Text>
          );
        },
      },
      {
        Header: "Created At",
        accessor: ({ createdAt }) => (
          <Text color={"gray"}>{prettyPrint(createdAt, true)}</Text>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <Badge colorScheme={getStatusColorScheme(value)}>{value}</Badge>
        ),
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ value }) => <FiArrowRight />,
      },
    ],
    [talentscouts]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });
  return (
    <Box p={4}>
      <Text textStyle="h2" mr={4} mb={10}>
        Pre-Approval
      </Text>
      <Button
        isLoading={isFetching}
        onClick={async () => {
          setIsFetching(true);
          try {
            const { referredCandidate } = await getNextReferCandidateForReview(
              "CREATED"
            );
            setIsFetching(false);
            history.push(
              "/tools/candidates/referred/all/" +
                referredCandidate.id +
                "?purpose=PREAPPROVAL"
            );
          } catch (e) {
            setIsFetching(false);
          }
        }}
      >
        Get Candidate
      </Button>
      {/* <Box ml={8}>
          <Button
            onClick={() => jobPickerDisclosure.onOpen()}
            rightIcon={<FaChevronDown />}
          >
            {selectedJob ? (
              <Box fontWeight={"400"} textAlign="left" fontSize="xs">
                <Text>{selectedJob.jobTitle?.name}</Text>
                <Text color="gray">{selectedJob.company?.name}</Text>
              </Box>
            ) : (
              <Box fontWeight="400" textAlign="left" fontSize="xs">
                <Text>All Jobs</Text>
                <Text color="gray">No job selected</Text>
              </Box>
            )}
          </Button>
        </Box> */}
      {/* <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} borderBottomWidth={2}>
              {headerGroup.headers.map((column) => (
                <Th textTransform={"none"} {...column.getHeaderProps()}>
                  <Text textStyle={"label"}>{column.render("Header")}</Text>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                _hover={{ background: "gray.50" }}
                cursor="pointer"
                onClick={() => {
                  setSelectedCandidate(row.original);
                  drawerDisclosure.onOpen();
                }}
              >
                {row.cells.map((cell) => (
                  <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table> */}
      {/* {!isFetching && (
        <Box mt={4}>
          <Pagination page={page} setPage={setPage} hasNext={hasNext} />
        </Box>
      )} */}
      {/* {selectedCandidate && (
        <CandidateDrawer
          candidate={selectedCandidate}
          {...drawerDisclosure}
          onUpdated={() => {
            fetchReferredCandidates(selectedJob?.id, page, limit);
          }}
        />
      )}
      <JobPicker
        {...jobPickerDisclosure}
        onChange={(job) => setSelectedJob(job)}
      /> */}
    </Box>
  );
};
