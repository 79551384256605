import React from "react";
import { styled } from "baseui";
import Button from "../../components/Button";
import Spacing from "../../components/Spacing";
import { FormControl } from "baseui/form-control";
import { searchEntity } from "../../api/entity";
import Label from "../../components/Label";
import ScrollToTop from "../../components/ScrollToTop";
import Input from "../../components/Input";
import Select from "../../components/Select";
import Title from "../../components/Title";
import PopConfirm from "../../components/PopConfirm";
import Modal from "../../components/Modal";
import { ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import { KIND } from "baseui/button";
import AddSection from "../../components/AddSection";
import { getCurrency } from "../../util/currency";
import { getShowcaseMap, getShowcase } from "../../util/common";
import Card from "../../components/Card";
import { Icon } from "../../components/Icon";
import { Block } from "baseui/block";
import { LabelSmall } from "baseui/typography";
import { CenterContainer } from "../../components/CenterContainer";

const PremiumRow = styled("div", {
  paddingBottom: "64px",
  maxWidth: "980px",
});

const Question = styled("div", {
  marginBottom: "8px",
  fontSize: "18px",
});

const SectionWrapper = styled(Card, ({ isFirst, isLast, align, $theme }) => ({
  position: "relative",
  borderRadius: 0,
  borderTopLeftRadius: isFirst ? $theme.borders.radius300 : 0,
  borderTopRightRadius: isFirst ? $theme.borders.radius300 : 0,
  borderBottomLeftRadius: isLast ? $theme.borders.radius300 : 0,
  borderBottomRightRadius: isLast ? $theme.borders.radius300 : 0,
  ...(!isFirst && { borderTopWidth: 0 }),
}));

class UploadedCandidatePremium extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isFetching: true,
      isAdding: false,
      isLoading: false,
      premiumFeature: "",
      premium: {
        workShowcases: props.workShowcases || [],
      },
      values: {
        workShowcases: "",
        workShowcaseCategory: getShowcase().id,
      },
      mode: "ADD",
      modalProps: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      premium: {
        workShowcases: nextProps.workShowcases || [],
      },
    };
  }

  async updateCandidatePremium(premium) {
    try {
      this.setState({
        isAdding: true,
      });
      this.props.updateWorkShowcases(premium);
      this.setState({
        premium,
        isAdding: false,
      });
    } catch (e) {
      this.setState({
        isAdding: false,
      });
      console.log(e);
    }
  }

  async onRemoveWorkShowcases(index) {
    console.log("index", index);
    console.log();
    let updatedPremium = {
      ...this.state.premium,
      ...{
        workShowcases: (this.state.premium.workShowcases || [])
          .slice(0, index)
          .concat((this.state.premium.workShowcases || []).slice(index + 1)),
      },
    };
    await this.updateCandidatePremium(updatedPremium);
  }

  async onAddPremium({ workShowcase }) {
    let updatedPremium = {
      ...this.state.premium,
      ...{
        workShowcases: workShowcase
          ? (this.state.premium.workShowcases || []).concat(workShowcase)
          : this.state.premium.workShowcases,
      },
    };
    await this.updateCandidatePremium(updatedPremium);
    this.setState({
      premiumModelVisible: false,
    });
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && !this.state.values[id]) {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  getValidations(id) {
    switch (id) {
      case "company":
      case "college":
      case "startup":
      case "jobOfferCompany":
      case "jobOfferCTCCurrency":
        return { requiredSelect: true };
      case "jobOfferCTC":
      case "patentTitle":
      case "patentNumber":
      case "patentUrl":
      case "patentDescription":
      case "linkedInReference":
      case "article":
      case "workShowcase":
      case "certificateName":
      case "certificationDescription":
        return { required: true };
      default:
        return {};
    }
  }

  checkFieldErrors = (fieldsToCheck) => {
    let errors = {};
    let isErrorFound = false;
    fieldsToCheck.forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });
    if (isErrorFound) {
      this.setState({
        errors,
      });
    }
    return isErrorFound;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    switch (this.state.premiumFeature.toUpperCase()) {
      case "WORK SHOWCASE":
        if (!this.checkFieldErrors(["workShowcase"])) {
          await this.onAddPremium({
            workShowcase: {
              url: this.state.values["workShowcase"],
              category: this.state.values["workShowcaseCategory"],
            },
          });
        }
        break;
      default:
    }
    this.setState({
      values: {
        workShowcase: "",
        workShowcaseCategory: getShowcase().id,
      },
    });
  };

  render() {
    return (
      <CenterContainer>
        <PremiumRow>
          <Question>
            Work showcases
            <div style={{ fontSize: "14px", color: "#919191" }}>
              {"Eg. github url, behance url, dribble url etc..."}
            </div>
          </Question>
          {(this.state.premium.workShowcases || []).map((showCase, index) => (
            <SectionWrapper
              key={index}
              isFirst={index === 0 ? 1 : 0}
              isLast={
                index === this.state.premium.workShowcases.length - 1 ? 1 : 0
              }
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <LabelSmall>{showCase.category}</LabelSmall>
                <Block
                  display="flex"
                  top="scale200"
                  right="scale200"
                  position="absolute"
                >
                  <PopConfirm
                    showArrow
                    title={"Are your sure to delete this work showcase?"}
                    onConfirm={async () =>
                      await this.onRemoveWorkShowcases(index)
                    }
                  >
                    <Button kind={KIND.minimal}>
                      <Icon name="trash" size={16} />
                    </Button>
                  </PopConfirm>
                </Block>
              </div>
              <div>{showCase.url}</div>
            </SectionWrapper>
          ))}
          <Block marginTop="scale600">
            <AddSection
              onClick={() =>
                this.setState({
                  premiumModelVisible: true,
                  premiumFeature: "Work Showcase",
                })
              }
              text={"Add Showcase url"}
            ></AddSection>
          </Block>
        </PremiumRow>

        <Modal
          animate
          autoFocus
          isOpen={this.state.premiumModelVisible}
          closeable
          onClose={() =>
            this.setState({
              premiumModelVisible: false,
              jobOfferCompany: "",
              jobOfferCTC: 0,
              jobOfferCTCCurrency: getCurrency("INR"),
              patent: "",
              linkedInReference: "",
              article: "",
              github: "",
              certificateName: "",
              certificateLink: "",
              certificationDescription: "",
            })
          }
        >
          <form onSubmit={this.handleSubmit} autoComplete="off">
            <ModalHeader>
              <Title>{"Add " + this.state.premiumFeature}</Title>
            </ModalHeader>
            <ModalBody>
              {this.state.premiumFeature.toLowerCase() === "work showcase" ? (
                <div>
                  <FormControl
                    label={<Label>{"Select category"}</Label>}
                    caption={<div />}
                    error={this.state.errors["workShowcaseCategory"]}
                  >
                    <Select
                      clearable={false}
                      searchable={false}
                      openOnClick={true}
                      defaultOptions={getShowcaseMap()}
                      onChange={(value) => {
                        this.updateFormValues("workShowcaseCategory", value.id);
                      }}
                      value={getShowcase(
                        this.state.values["workShowcaseCategory"]
                      )}
                    />
                  </FormControl>
                  <FormControl
                    label={<Label>{"Enter showcase url"}</Label>}
                    error={this.state.errors["workShowcase"]}
                  >
                    <Input
                      id={"workShowcases"}
                      onChange={(e) =>
                        this.updateFormValues("workShowcase", e.target.value)
                      }
                      value={this.state.values["workShowcase"]}
                      autoComplete={"off"}
                      onBlur={(e) =>
                        this.updateFormErrors(
                          "workShowcase",
                          this.validate(
                            "workShowcase",
                            this.getValidations("workShowcase")
                          )
                        )
                      }
                    />
                  </FormControl>
                </div>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <Button
                kind={KIND.minimal}
                type={"button"}
                onClick={() => this.setState({ premiumModelVisible: false })}
              >
                Cancel
              </Button>
              <Button type={"submit"} isLoading={this.state.isAdding}>
                {"Add " + this.state.premiumFeature}
              </Button>
            </ModalFooter>
          </form>
        </Modal>
        <Spacing size={4} />
      </CenterContainer>
    );
  }
}

export default UploadedCandidatePremium;
