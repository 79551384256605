import React from "react";
import Carousel from "./Carousel";
import { styled } from "styletron-react";

const Container = styled("div", {
  minHeight: "250px",
  alignItems: "center",
  justifyContent: "center",
  display: "flex !important",
  flexDirection: "column",
});

const TutorialEmployer = (props) => (
  <Carousel>
    <Container>
      <img
        alt={""}
        height={"320px"}
        width={"100%"}
        src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/recruiter1.svg"
      ></img>
      <div style={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>
        Free job postings
      </div>
      <div
        style={{
          color: "#919191",
          fontSize: "14px",
          marginBottom: "16px",
          textAlign: "center",
        }}
      >
        Unlimited job postings
      </div>
    </Container>
    <Container>
      <img
        alt={""}
        height={"320px"}
        width={"100%"}
        src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/recruiter2.svg"
      ></img>
      <div style={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>
        Free to browse anonymous profiles
      </div>
      <div
        style={{
          color: "#919191",
          fontSize: "14px",
          marginBottom: "16px",
          textAlign: "center",
        }}
      >
        All premium candidates
      </div>
    </Container>
    <Container>
      <img
        alt={""}
        width={"100%"}
        height={"320px"}
        src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/recruiter3.svg"
      ></img>
      <div style={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>
        View all offers candidate received
      </div>
      <div
        style={{
          color: "#919191",
          fontSize: "14px",
          marginBottom: "16px",
          textAlign: "center",
        }}
      >
        Similarly your offer will be visible to others
      </div>
    </Container>
    <Container>
      <img
        alt={""}
        height={"320px"}
        width={"100%"}
        src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/recruiter4.svg"
      ></img>
      <div style={{ fontWeight: "500", fontSize: "16px", textAlign: "center" }}>
        First make the interview offer
      </div>
      <div
        style={{
          color: "#919191",
          fontSize: "14px",
          marginBottom: "16px",
          textAlign: "center",
        }}
      >
        Candidate's real identity will be visible once candidate accept the
        offer
      </div>
    </Container>
  </Carousel>
);

export default TutorialEmployer;
