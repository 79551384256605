import React, { useState } from "react";
import Button from "./Button";
import { withStyle } from "baseui";
import { KIND, SIZE } from "baseui/button";
import { Link } from "react-router-dom";
import Card from "./Card";
import Currency from "./Currency";
import Bold from "./Bold";
import StatusPill from "./StatusPill";
import {
  rejectInterviewRequest,
  acceptInterviewRequest,
} from "../api/candidate";
import { toaster } from "baseui/toast";
import PopConfirm from "./PopConfirm";
import FlexRow from "./FlexRow";
import { Block } from "baseui/block";
import { Icon } from "./Icon";
import { LabelMedium } from "baseui/typography";
import Modal from "./Modal";
import { ModalBody, ModalFooter, ModalHeader } from "baseui/modal";

const Wrapper = withStyle(Card, ({ isClickable, isDisabled }) => ({
  marginBottom: "16px",
  opacity: isDisabled ? 0.6 : 1,
  ":hover": isClickable
    ? {
        boxShadow: "1px 2px 20px 0px rgba(0, 0, 0, 0.1)",
      }
    : {},
}));

const Field = (props) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      width: props.isStat ? "auto" : "300px",
      marginRight: "16px",
    }}
  >
    {props.img ? (
      <Block $marginRight="scale200">
        <Icon name={props.img} size={12} />
      </Block>
    ) : null}
    {props.name ? <div style={{ marginRight: "4px" }}>{props.name}</div> : null}
    <div style={{}}>{props.value}</div>
  </div>
);

const InterviewRequestCandidate = ({
  interviewRequest,
  onUpdated,
  isClickable,
  isDisabled,
  onDisabledClick,
}) => {
  const [jobCoolDownModalVisible, setJobCoolDownModalVisible] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const onInterviewAccept = async () => {
    setIsApproving(true);
    try {
      await acceptInterviewRequest(interviewRequest);
      setIsApproving(false);
      toaster.show("Interview request accepted", {
        autoHideDuration: 3000,
      });
      onUpdated();
    } catch (e) {
      setIsApproving(false);
    }
  };

  const onInterviewReject = async (statusReason) => {
    setIsRejecting(true);
    try {
      interviewRequest.statusReason = statusReason;
      await rejectInterviewRequest(interviewRequest);
      setIsRejecting(false);
      toaster.show("Interview request rejected");
      onUpdated();
    } catch (e) {
      setIsRejecting(true);
    }
  };

  const renderContent = () => {
    return (
      <Wrapper
        isClickable={isClickable}
        isDisabled={isDisabled}
        className={isClickable ? "clickable" : ""}
      >
        <FlexRow>
          <Bold className={"linkText"}>{interviewRequest.jobCompany.name}</Bold>
          {isClickable && (
            <div
              style={{
                border: "1px solid #e2f0fd",
                color: "#0F4C81",
                paddingLeft: "10px",
                paddingRight: "10px",
                borderRadius: "16px",
                fontSize: "12px",
                marginLeft: "8px",
              }}
            >
              View Job Description
            </div>
          )}
        </FlexRow>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Field
            img={"user"}
            name={"Role"}
            value={interviewRequest.jobTitle.name}
          />
          <Field
            img={"map-pin"}
            name={"Locations"}
            value={interviewRequest.jobLocations
              .map((loc) => loc.name)
              .join(", ")}
          />
          <Field
            img={"tag"}
            name={"Skills"}
            value={(interviewRequest.jobSkills || [])
              .map((sk) => sk.name)
              .slice(0, 5)
              .join(", ")}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "16px",
          }}
        >
          <div style={{ marginRight: "8px" }}>Salary Offered: </div>
          <Bold>
            <Currency
              inWords
              currency={interviewRequest.jobCTCCurrency}
              value={interviewRequest.jobCTC}
            />
          </Bold>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "8px" }}>Status: </div>
          <StatusPill>
            {interviewRequest.status === "REVIEWED"
              ? "PENDING"
              : interviewRequest.status}
          </StatusPill>
        </div>
        {interviewRequest.status === "REVIEWED" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Field name={"Expires in"} value={"20 hrs"} />
            <div style={{ display: "flex" }}>
              <div>
                <PopConfirm
                  showArrow
                  title="Are you sure to reject this offer?"
                  onConfirm={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (isDisabled) {
                      onDisabledClick && onDisabledClick();
                      return;
                    }
                    await onInterviewReject();
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Button
                    size={SIZE.compact}
                    kind={KIND.tertiary}
                    isLoading={isRejecting}
                    buttonStyle={{ width: "100%" }}
                    style={{ marginRight: 8 }}
                  >
                    Reject
                  </Button>
                </PopConfirm>
              </div>
              <div>
                {interviewRequest.jobCoolDownPeriod > 0 ? (
                  <PopConfirm
                    showArrow
                    width={"420px"}
                    confirmText={"Yes, I have interviewed"}
                    cancelText={"No, I have not interviewed"}
                    title={
                      <div>
                        <div style={{ margin: "16px" }}>
                          <LabelMedium>
                            {"Are you currently interviewing with this company or have you interviewed with this company in the past " +
                              interviewRequest.jobCoolDownPeriod +
                              " months?"}
                          </LabelMedium>
                        </div>
                      </div>
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onConfirm={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (isDisabled) {
                        onDisabledClick && onDisabledClick();
                        return;
                      }
                      await onInterviewReject("COOLDOWN_PERIOD");
                      setJobCoolDownModalVisible(true);
                    }}
                    onCancel={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (isDisabled) {
                        onDisabledClick && onDisabledClick();
                        return;
                      }
                      await onInterviewAccept();
                    }}
                  >
                    <Button
                      size={SIZE.compact}
                      type={"primary"}
                      isLoading={isApproving}
                      buttonStyle={{ width: "100%" }}
                    >
                      Approve
                    </Button>
                  </PopConfirm>
                ) : (
                  <Button
                    size={SIZE.compact}
                    type={"primary"}
                    isLoading={isApproving}
                    buttonStyle={{ width: "100%" }}
                    onClick={async (e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (isDisabled) {
                        onDisabledClick && onDisabledClick();
                        return;
                      }
                      await onInterviewAccept();
                    }}
                  >
                    Approve
                  </Button>
                )}
              </div>
            </div>
          </div>
        ) : null}
        <Modal
          animate
          autoFocus
          isOpen={jobCoolDownModalVisible}
          onClose={() => {
            setJobCoolDownModalVisible(false);
          }}
        >
          <ModalHeader>
            <Block />
          </ModalHeader>
          <ModalBody>
            <Block>
              {`Sorry, we have to cancel this interview request. This company has a “cool off period” of ${interviewRequest.jobCoolDownPeriod} months where you cannot re-apply or re-interview.`}
            </Block>
            <Block>
              Finding Jo will try to arrange another interview for you
            </Block>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setJobCoolDownModalVisible(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Wrapper>
    );
  };

  if (isDisabled) {
    return <div>{renderContent()}</div>;
  } else {
    return (
      <Link
        style={{ color: "inherit" }}
        to={"/candidate/interviews/" + interviewRequest.id + "/details"}
      >
        {renderContent()}
      </Link>
    );
  }
};

export default InterviewRequestCandidate;
