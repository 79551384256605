import React from "react";
import { withStyle } from "baseui";
import FlexRow from "./FlexRow";
import Flex from "./Flex";
import Button from "./Button";
import { KIND } from "baseui/button";
import PopConfirm from "./PopConfirm";
import Card from "./Card";
import { Icon } from "./Icon";
import { LabelSmall } from "baseui/typography";
import { Block } from "baseui/block";

const Left = withStyle(Flex, {
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
});

const Wrapper = withStyle(Card, ({ isFirst, isLast, $theme }) => ({
  position: "relative",
  borderRadius: 0,
  borderTopLeftRadius: isFirst ? $theme.borders.radius300 : 0,
  borderTopRightRadius: isFirst ? $theme.borders.radius300 : 0,
  borderBottomLeftRadius: isLast ? $theme.borders.radius300 : 0,
  borderBottomRightRadius: isLast ? $theme.borders.radius300 : 0,
  ...(!isFirst && { borderTopWidth: 0 }),
}));

const TopWrapper = withStyle(Flex, {
  flexDirection: "coloum",
  justifyContent: "space-between",
});

const SkillRow = withStyle(FlexRow, {
  whiteSpace: "pre-line",
});

const ProjectSummary = (props) => (
  <Wrapper isLast={props.isLast} isFirst={props.isFirst}>
    <TopWrapper>
      <Left>
        <LabelSmall>{props.company}</LabelSmall>
        <Block
          display="flex"
          top="scale200"
          right="scale200"
          position="absolute"
        >
          <Button
            kind={KIND.minimal}
            onClick={() => {
              props.updateEdit(props.index);
            }}
          >
            <Icon name="edit" size={16} />
          </Button>
          <PopConfirm
            showArrow
            title="Are you sure to delete this project?"
            onConfirm={async () => await props.onRemove(props.index)}
          >
            <Button kind={KIND.minimal}>
              <Icon name="trash" size={16} />
            </Button>
          </PopConfirm>
        </Block>
      </Left>
    </TopWrapper>
    <SkillRow>{props.briefIntro}</SkillRow>
  </Wrapper>
);

export default ProjectSummary;
