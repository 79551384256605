import React from "react";
import { Button as BaseButton, SIZE, KIND } from "baseui/button";

const Button = React.forwardRef((props, ref) => {
  return (
    <BaseButton
      ref={ref}
      size={SIZE.compact}
      {...props}
      overrides={{
        BaseButton: {
          style: ({ $theme, $kind, $size }) => {
            return {
              borderRadius: $theme.borders.radius200,
              paddingLeft: $size == "mini" ? "12px" : "24px",
              paddingRight: $size == "mini" ? "12px" : "24px",
              // ...($kind === KIND.tertiary && $theme.borders.border400),
              // ...(($kind === KIND.tertiary || $kind === KIND.primary) && {
              //   boxShadow: "0 1px 0 rgba(0, 0, 0, 0.05)",
              // }),
              fontSize: $size == "mini" ? "14px" : "14px",
              fontWeight: 500,
            };
          },
        },
      }}
    >
      {props.children}
    </BaseButton>
  );
});

export default Button;
