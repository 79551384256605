import Bold from "./Bold";
import { withStyle } from "baseui";

const SubTitle = withStyle(Bold, {
  fontSize: "18px",
  marginBottom: "8px",
  marginTop: "8px",
});

export default SubTitle;
