import React, { useState } from "react";
import Button from "../Button";
import {
  Button as CButton,
  Box,
  Divider,
  Flex,
  Heading,
  Text,
  Tag,
} from "@chakra-ui/react";
import { SIZE } from "baseui/button";
import PopConfirm from "../PopConfirm";
import { connect } from "react-redux";
import Currency from "../Currency";
import Card from "../Card";
import { setWidgetState } from "../../redux/actions/widget";
import { useStyletron } from "baseui";
import { Icon } from "../Icon";
import { Link, withRouter } from "react-router-dom";
import { Block } from "baseui/block";
import {
  HeadingSmall,
  LabelLarge,
  LabelMedium,
  LabelSmall,
  LabelXSmall,
  ParagraphMedium,
  ParagraphSmall,
} from "baseui/typography";
import { chakra } from "@chakra-ui/system";
import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import { CandidateTags } from "./CandidateTags";

function getExperienceDuration(days) {
  return (
    <span>
      {parseInt(days / 365) + " yrs " + parseInt((days % 365) / 30) + " mos"}
    </span>
  );
}

const CandidateCard = ({
  to,
  onScout,
  onCardPress,
  onShare,
  candidate,
  isDisabled,
  onInterview,
  isClickable,
  onShortlist,
  interviewLink,
  setWidgetState,
  funnels,
  isOpenNewWindow,
  isShortlisted,
  isInterviewed,
  onRemoveShortlist,
  onNotInterested,
  status,
  showRCAmount,
  isSelected,
}) => {
  const [isScouting, setIsScouting] = useState(false);
  const [isFunnelDetailsOpened, setIsFunnelDetailsOpened] = useState(false);
  const [isShortlisting, setIsShortlisting] = useState(false);
  const [isNotInterestedLoading, setIsNotInterestedLoading] = useState(false);
  const target = isOpenNewWindow ? { target: "_blank" } : {};
  const [css] = useStyletron();
  const onInterviewClick = async () => {
    await onInterview(candidate);
  };

  const onScoutClick = async () => {
    setIsScouting(true);
    await onScout(candidate);
    setIsScouting(false);
  };

  const onShortlistClick = async () => {
    setIsShortlisting(true);
    await onShortlist(candidate);
    setIsShortlisting(false);
  };

  const onRemoveShortlistClick = async () => {
    setIsShortlisting(true);
    await onRemoveShortlist(candidate);
    setIsShortlisting(false);
  };

  const onNotInterestedClick = async () => {
    setIsNotInterestedLoading(true);
    await onNotInterested(candidate);
    setIsNotInterestedLoading(false);
  };

  const onShareClick = async () => {
    setIsScouting(true);
    await onShare();
    setWidgetState({
      isShareCandidateModelVisible: true,
      shareCandidate: candidate,
      shareCandidateImageUrl: candidate.shareImageUrl,
    });
    setIsScouting(false);
  };

  const renderScoutButton = () => (
    <PopConfirm
      showArrow
      title="Confirm and pay?"
      onConfirm={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await onScoutClick(candidate);
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Button isLoading={isScouting} size={SIZE.compact} type={"primary"}>
        Scout
      </Button>
    </PopConfirm>
  );

  const renderShortlistButton = () => (
    // <PopConfirm
    //   showArrow
    //   title={
    //     isShortlisted
    //       ? "Remove this candidate from shortlist?"
    //       : "Shortlist this candidate for this job?"
    //   }
    //   onConfirm={async (e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //     if (isShortlisted) {
    //       await onRemoveShortlistClick(candidate);
    //     } else {
    //       await onShortlistClick(candidate);
    //     }
    //   }}
    //   onClick={(e) => {
    //     e.preventDefault();
    //     e.stopPropagation();
    //   }}
    // >
    <Button
      isLoading={isShortlisting}
      size={SIZE.compact}
      kind={"tertiary"}
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isShortlisted) {
          await onRemoveShortlistClick(candidate);
        } else {
          await onShortlistClick(candidate);
        }
      }}
      startEnhancer={
        <Icon
          size={12}
          name={isShortlisted ? "check" : "heart"}
          color="primary"
        />
      }
    >
      {isShortlisted ? "Shortlisted" : "Shortlist"}
    </Button>
    // </PopConfirm>
  );

  const renderNotInterestedButton = (candidate) => (
    <PopConfirm
      showArrow
      title={"Remove this candidate from your list?"}
      onConfirm={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await onNotInterestedClick(candidate);
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Button
        isLoading={isNotInterestedLoading}
        size={SIZE.compact}
        kind={"tertiary"}
        startEnhancer={<Icon size={12} name={"slash"} color="primary" />}
      >
        Not Interested
      </Button>
    </PopConfirm>
  );

  const renderInterviewButton = () => {
    return (
      <Link {...target} to={interviewLink}>
        <Button
          size={SIZE.compact}
          type={"primary"}
          onClick={async (e) => {
            if (!isInterviewed) {
              await onInterviewClick(candidate);
            }
          }}
          startEnhancer={
            <Icon
              size={12}
              name={isInterviewed ? "check" : "users"}
              color="background"
            />
          }
        >
          {isInterviewed ? "Interview Sent" : "Interview"}
        </Button>
      </Link>
    );
  };

  const renderShareButton = () => {
    return (
      <Button
        size={SIZE.compact}
        kind={"minimal"}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          await onShareClick(candidate);
        }}
        startEnhancer={<Icon size={12} name="share-2" color="primary" />}
      >
        Share
      </Button>
    );
  };

  const renderContent = () => {
    const {
      lastExperienceTitle,
      experience = [],
      education,
      expectedCTCCurrency,
      totalExperience,
      expectedCTC,
      noticePeriod,
      skills,
      isRemoteReady,
      careerSummary = "",
      startupTypes,
      premium,
      rcAmount,
      rcAmountCurrency,
    } = candidate;
    const isFundedStartups =
      (startupTypes || []).indexOf("FUNDED_STARTUPS") > -1;
    const isEarlyStageStartups =
      (startupTypes || []).indexOf("EARLY_STAGE") > -1;
    const { workShowcases } = premium || {};
    return (
      <Card
        marginBottom="scale400"
        isClickable={isClickable}
        backgroundColor={isDisabled ? "light" : "background"}
        isSelected={isSelected}
      >
        <Block
          display={["block", "flex", "flex"]}
          marginBottom="scale400"
          flexWrap="wrap"
        >
          <LabelLarge
            marginRight="scale400"
            width={["auto", "200px", "200px"]}
            color="accentBlue"
          >
            {lastExperienceTitle || "Fresher"}
          </LabelLarge>
          {experience.length > 0 && (
            <ParagraphMedium>{experience[0].company.name}</ParagraphMedium>
          )}
          <Flex>
            {experience.length > 0 &&
              experience[0].tags &&
              experience[0].tags.length > 0 &&
              (experience[0].tags || []).map(({ name }) => (
                <Tag variant="outline" colorScheme={"gray"} mr={1}>
                  {name}
                </Tag>
              ))}
          </Flex>
          <Block display="flex">
            <CandidateTags candidate={candidate} />
          </Block>
        </Block>
        {careerSummary.length > 0 && (
          <Block display={["block", "flex", "flex"]} marginBottom="scale100">
            <LabelMedium
              marginRight="scale400"
              width={["auto", "200px", "200px"]}
            >
              Career Summary
            </LabelMedium>
            <ParagraphMedium flex={1}>{careerSummary}</ParagraphMedium>
          </Block>
        )}
        {experience.length > 0 && (
          <Block display={["block", "flex", "flex"]} marginBottom="scale100">
            <LabelMedium
              marginRight="scale400"
              width={["auto", "200px", "200px"]}
            >
              Experience
            </LabelMedium>
            <ParagraphMedium flex={1}>
              {getExperienceDuration(totalExperience)}
            </ParagraphMedium>
          </Block>
        )}

        <Block display={["block", "flex", "flex"]} marginBottom="scale100">
          <LabelMedium
            marginRight="scale400"
            width={["auto", "200px", "200px"]}
          >
            Notice Period
          </LabelMedium>
          <ParagraphMedium flex={1}>{`${noticePeriod} weeks`}</ParagraphMedium>
        </Block>

        <Block display={["block", "flex", "flex"]} marginBottom="scale100">
          <LabelMedium
            marginRight="scale400"
            width={["auto", "200px", "200px"]}
          >
            Expected CTC
          </LabelMedium>
          <ParagraphMedium flex={1}>
            <Currency
              inWords
              currency={expectedCTCCurrency}
              value={expectedCTC}
            ></Currency>
          </ParagraphMedium>
        </Block>
        <Block display={["block", "flex", "flex"]}>
          <LabelMedium
            marginRight="scale400"
            width={["auto", "200px", "200px"]}
          >
            Skills
          </LabelMedium>
          <ParagraphMedium flex={1}>
            {(skills || [])
              .map((sk) => sk.name)
              .slice(0, 3)
              .join(", ")}
          </ParagraphMedium>
        </Block>

        {/* <Block display="flex" flexWrap="wrap">
          <Block>
            <Block width="250px">
              {!!isRemoteReady ? (
                <Block display="flex" alignItems="center">
                  <Icon name={"check"} size={16} color={"positive"} />
                  <ParagraphSmall marginLeft="scale200" color="primary500">
                    {"Ready for remote"}
                  </ParagraphSmall>
                </Block>
              ) : (
                <Block display="flex" alignItems="center">
                  <Icon name={"x"} size={16} color={"negative"} />
                  <ParagraphSmall marginLeft="scale200" color="primary500">
                    {"Not ready for remote"}
                  </ParagraphSmall>
                </Block>
              )}
              {isFundedStartups && (
                <Block display="flex" alignItems="center">
                  <Icon name="check" size={16} color="positive" />
                  <ParagraphSmall marginLeft="scale200" color="primary500">
                    {"Funded startups"}
                  </ParagraphSmall>
                </Block>
              )}
              {isEarlyStageStartups && (
                <Block display="flex" alignItems="center">
                  <Icon name="check" size={16} color="positive" />
                  <ParagraphSmall marginLeft="scale200" color="primary500">
                    {"Early stage startups"}
                  </ParagraphSmall>
                </Block>
              )}
              {workShowcases && workShowcases.length > 0 && (
                <Block display="flex" alignItems="center">
                  <Icon name="check" size={16} color="positive" />
                  <ParagraphSmall marginLeft="scale200" color="primary500">
                    {`${workShowcases.length} work links`}
                  </ParagraphSmall>
                </Block>
              )}
            </Block>
          </Block>
        </Block> */}
        {status && (
          <Block marginTop="scale400">
            <LabelSmall
              color="background"
              backgroundColor="accent"
              as="span"
              className={css({ padding: "0 4px", letterSpacing: "1px" })}
            >
              {status.title}
            </LabelSmall>
            {status.messages.map((message) => (
              <ParagraphSmall>{message}</ParagraphSmall>
            ))}
          </Block>
        )}
        <Block
          display="flex"
          alignItems="center"
          marginTop="scale800"
          flexWrap="wrap"
        >
          {showRCAmount && (
            <Block marginRight="scale200">
              <LabelSmall>
                <Currency
                  inWords
                  currency={rcAmountCurrency}
                  value={rcAmount / 100}
                ></Currency>
              </LabelSmall>
            </Block>
          )}
          <Block marginRight="scale200">
            {onInterview && renderInterviewButton()}
          </Block>
          <Block marginRight="scale200">{onScout && renderScoutButton()}</Block>
          <Block marginRight="scale200">
            {onShortlist && renderShortlistButton()}
          </Block>
          <Block marginRight="scale200">
            {onNotInterested && renderNotInterestedButton()}
          </Block>
          {(funnels || []).length > 0 && (
            <CButton
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsFunnelDetailsOpened(!isFunnelDetailsOpened);
              }}
              rightIcon={
                isFunnelDetailsOpened ? <ChevronUpIcon /> : <ChevronDownIcon />
              }
            >
              {`In ${funnels.length} Pipeline(s)`}
            </CButton>
          )}
          <Block flex={1} />
          <Block marginRight="scale200">{onShare && renderShareButton()}</Block>
        </Block>
        {isFunnelDetailsOpened && (
          <Flex flexDirection="column" mt="4">
            <Divider></Divider>
            <Heading as="h6" size="xs" mb="2" mt="4">
              {`In ${funnels.length} Pipeline(s)`}
            </Heading>
            {funnels.map((f) => (
              <Flex flexDirection="column">
                <Link
                  to={`/employer/jobapplication/${f.jobId}/funnel`}
                  target="_external"
                >
                  <Heading as="h6" size="s" color="blue.500">
                    {f.jobTitle.name}
                  </Heading>
                </Link>
                <Text>{`In ${f.laneId} by ${f.updatedBy}`}</Text>
              </Flex>
            ))}
          </Flex>
        )}
      </Card>
    );
  };
  return to ? (
    // <Link {...target} to={to}>
    <Box onClick={onCardPress}>{renderContent()}</Box>
  ) : (
    // </Link>
    renderContent()
  );
};

export default withRouter(
  connect(() => ({}), { setWidgetState })(CandidateCard)
);

//   return (
//     <Wrapper
//       isClickable={isClickable}
//       className={isClickable ? "clickable" : ""}
//     >
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <Bold
//           className={"linkText"}
//           style={{
//             fontSize: "16px",
//           }}
//         >
//           {candidate.lastExperienceTitle
//             ? candidate.lastExperienceTitle
//             : "Fresher"}
//         </Bold>
//         {isEmployer ? (
//           <div style={{ display: "flex" }}>
//             <Icon
//               size={18}
//               name={"share-2"}
//               onClick={(e) => {
//                 e.preventDefault();
//                 e.stopPropagation();
//                 setWidgetState({
//                   isShareCandidateModelVisible: true,
//                   shareCandidate: candidate,
//                   shareCandidateImageUrl: candidate.shareImageUrl,
//                 });
//               }}
//             />
//           </div>
//         ) : (
//           <div style={{ display: "flex" }}></div>
//         )}
//       </div>

//       <div /*style={{ display: "flex", flexWrap: "wrap" }}*/>
//         {(candidate.experience || []).length ? (
//           <Field
//             img={"chevrons-up"}
//             name={""}
//             value={candidate.experience[0].company.name}
//           />
//         ) : null}
//         <Field
//           name={"Desired Salary"}
//           img={"heart"}
//           value={
//             <Currency
//               inWords
//               currency={candidate.expectedCTCCurrency}
//               value={candidate.expectedCTC}
//             ></Currency>
//           }
//         />
//         {(candidate.education || []).length ? (
//           <Field
//             img={"award"}
//             name={""}
//             value={candidate.education[0].college.name}
//           />
//         ) : null}
//         {(candidate.experience || []).length ? (
//           <Field
//             img={"calendar"}
//             name={"Experience"}
//             value={getExperienceDuration(candidate.totalExperience)}
//           />
//         ) : null}
//         <Field
//           name={"Notice"}
//           img={"log-out"}
//           value={candidate.noticePeriod + " weeks"}
//         />
//       </div>
//       <div style={{ display: "flex", marginTop: "16px" }}>
//         <div style={{ marginRight: "4px" }}>{"Skills:"}</div>
//         <div>
//           {(candidate.skills || [])
//             .map((sk) => sk.name)
//             .slice(0, 5)
//             .join(", ")}
//         </div>
//       </div>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <div style={{ fontSize: "12px", color: "#919191" }}></div>
//         {!disableActions ? (
//           <div style={{ display: "flex", alignItems: "center" }}>
//             <Bold
//               style={{
//                 marginRight: "16px",
//               }}
//             >
//               {isEmployer
//                 ? "₹ " + formatCurrency(candidate.rcAmount / 100)
//                 : "₹ " + formatCurrency(candidate.tsAmount / 100)}
//             </Bold>
//             {isEmployer ? (
//               <Link
//                 {...interviewTarget}
//                 to={
//                   "/employer/create-interview/" +
//                   (candidate.objectID || candidate.userId)
//                 }
//               >
//                 <Button
//                   size={SIZE.compact}
//                   type={"primary"}
//                   onClick={async (e) => {
//                     // e.preventDefault();
//                     // e.stopPropagation();
//                     await onInviteInterview(candidate);
//                   }}
//                 >
//                   Interview
//                 </Button>
//               </Link>
//             ) : (
//               <PopConfirm
//                 showArrow
//                 title="Confirm and pay?"
//                 onConfirm={async (e) => {
//                   e.preventDefault();
//                   e.stopPropagation();
//                   await onScoutClick(candidate);
//                 }}
//                 onClick={(e) => {
//                   e.preventDefault();
//                   e.stopPropagation();
//                 }}
//               >
//                 <Button
//                   isLoading={isScouting}
//                   size={SIZE.compact}
//                   type={"primary"}
//                 >
//                   Scout
//                 </Button>
//               </PopConfirm>
//             )}
//           </div>
//         ) : null}
//       </div>
//     </Wrapper>
//   );
