import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Box, IconButton, Text, Flex, Link } from "@chakra-ui/react";
import { FiChevronLeft, FiChevronRight, FiExternalLink } from "react-icons/fi";

export const PDFViewer = ({
  source,
  showDownloadLink,
}: {
  source: string;
  showDownloadLink?: boolean;
}) => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = () => {
    setError(true);
  };

  if (error) {
    return (
      <Box>
        <Link href={source} isExternal color={"blue"}>
          <Flex alignItems="center">
            <Text>{"Click to open the document"}</Text>
            <FiExternalLink />
          </Flex>
        </Link>
      </Box>
    );
  }

  return (
    <Box height="100px">
      <Box>
        {showDownloadLink && (
          <Box>
            <Link href={source} isExternal>
              <Flex alignItems="center">
                <Text>{"Click to Download"}</Text>
                <FiExternalLink />
              </Flex>
            </Link>
          </Box>
        )}
        <Document
          file={source}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </Box>
      <Flex>
        <IconButton
          isDisabled={pageNumber <= 1}
          icon={<FiChevronLeft />}
          onClick={() => {
            setPageNumber(pageNumber - 1);
          }}
          aria-label="previous"
        />
        <Text>
          Page {pageNumber} of {numPages}
        </Text>
        <IconButton
          isDisabled={pageNumber >= numPages}
          icon={<FiChevronRight />}
          onClick={() => {
            setPageNumber(pageNumber + 1);
          }}
          aria-label="next"
        />
      </Flex>
    </Box>
  );
};
