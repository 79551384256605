import React, { useEffect, useState } from "react";
import {
  Route,
  useRouteMatch,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import PageHeader from "../components/Header";
import ChangePictureModel from "./ChangePictureModel";
import TutorialModel from "./TutorialModel";
import ShareJobModel from "./ShareJobModel";
import SidebarWrapper from "../components/SidebarWrapper";
import PageWrapper from "../components/PageWrapper";
import Container from "../components/Container";
import RCDashboardSearch from "./RCDashboardSearch";
import PostJob from "./PostJob";
import RCPostedJobs from "./RCPostedJobs";
import UserWallet from "./UserWallet";
import UserCompany from "./UserCompany";
import UserHMDetails from "./UserHMDetails";
import EmployerGST from "./EmployerGST";
import EmployerProfileWorkspace from "./EmployerProfileWorkspace";
import UserReviewDetails from "./UserReviewDetails";
import ShareCandidateModel from "./ShareCandidateModel";
import CandidateDetails from "./CandidateDetails";
import EmployerInterviewDetails from "./EmployerInterviewDetails";
import UserWalletWithdraw from "./UserWalletWithdraw";
import SidebarEmployerHome from "../components/SidebarEmployerHome";
import SidebarEmployerProfile from "../components/SidebarEmployerProfile";
import CreateInterviewRequest from "./CreateInterviewRequest";
import SidebarJobApplications from "../components/SidebarJobApplications";
import EmployerJobDetails from "./EmployerJobDetails";
import SidebarDetails from "../components/SidebarDetails";
import { connect, useDispatch } from "react-redux";
import { setWidgetState } from "../redux/actions/widget";
import { fetchUserConfig } from "../redux/actions/user";
import LoadingScreen from "../components/LoadingScreen";
import HandpickedCandidates from "./employer/jobs/HandpickedCandidates";
import BoughtCandidates from "./employer/jobs/BoughtCandidates";
import InterviewHistory from "./employer/jobs/InterviewHistory";
import ShortlistedCandidates from "./employer/jobs/ShortlistedCandidates";
import { getJobById } from "../api/job";
import { toaster } from "baseui/toast";
import { getHomeRedirect, getProfileRedirect } from "../util/common";
import BinCandidates from "./employer/jobs/BinCandidates";
import CandidateFunnel from "./employer/jobs/CandidateFunnel";
import { Box } from "@chakra-ui/layout";
import { EmployerSendPaymentLink } from "src/lib/views/EmployerSendPaymentLink";

const SidebarHome = ({ page }) => {
  return (
    <SidebarWrapper>
      <SidebarEmployerHome page={page} />
    </SidebarWrapper>
  );
};

const SidebarProfile = ({ page }) => {
  return (
    <SidebarWrapper>
      <SidebarEmployerProfile page={page} />
    </SidebarWrapper>
  );
};

const SidebarWithBackButton = (props) => {
  return (
    <SidebarWrapper>
      <SidebarDetails {...props} />
    </SidebarWrapper>
  );
};

const EmployerHomeRoute = connect(
  (state) => ({
    user: state.user,
  }),
  { setWidgetState }
)(({ user, setWidgetState }) => {
  const [isFetchingConfig, setIsFetchingConfig] = useState(true);
  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  const checkTutorialToBeShown = () => {
    let isTutorialShown = localStorage.getItem(
      user.id + "." + user.role + ".tutorialShown"
    );
    if (!isTutorialShown) {
      setWidgetState({ isTutorialModelVisible: true });
      localStorage.setItem(user.id + "." + user.role + ".tutorialShown", true);
    }
  };

  useEffect(() => {
    checkTutorialToBeShown();
    dispatch(fetchUserConfig()).then(() => setIsFetchingConfig(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user.employerProfile) {
    return <Redirect to={getProfileRedirect(user)} />;
  }

  if (isFetchingConfig) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Switch>
        {/* <Route path={`${path}/search`}>
          <PageWrapper>
            <SidebarHome page={"search"} />
            <RCDashboardSearch isEmployer />
          </PageWrapper>
        </Route>
        <Route path={`${path}/mycandidates`}>
          <PageWrapper>
            <SidebarHome page={"mycandidates"} />
            <RCMyCandidates />
          </PageWrapper>
        </Route> */}
        <Route path={`${path}/postjob`}>
          <PageWrapper>
            <SidebarHome page={"postjob"} />
            <PostJob />
          </PageWrapper>
        </Route>
        <Route path={`${path}/postedjobs`}>
          <PageWrapper>
            <SidebarHome page={"postedjobs"} />
            <RCPostedJobs />
          </PageWrapper>
        </Route>
        <Route path={`${path}/wallet`}>
          <PageWrapper>
            <SidebarHome page={"wallet"} />
            <UserWallet />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
});

const EmployerProfileRoute = (props) => {
  let { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route path={`${path}/company`}>
          <PageWrapper>
            <SidebarProfile page={"company"} />
            <UserCompany />
          </PageWrapper>
        </Route>
        <Route path={`${path}/details`}>
          <PageWrapper>
            <SidebarProfile page={"details"} />
            <UserHMDetails />
          </PageWrapper>
        </Route>
        <Route path={`${path}/taxdetails`}>
          <PageWrapper>
            <SidebarProfile page={"taxdetails"} />
            <EmployerGST />
          </PageWrapper>
        </Route>
        <Route path={`${path}/workspace`}>
          <PageWrapper>
            <SidebarProfile page={"workspace"} />
            <EmployerProfileWorkspace />
          </PageWrapper>
        </Route>
        <Route path={`${path}/review`}>
          <PageWrapper>
            <SidebarProfile page={"review"} />
            <UserReviewDetails />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const EmployerWalletRoute = ({ user }) => {
  const [isFetchingConfig, setIsFetchingConfig] = useState(true);
  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserConfig()).then(() => setIsFetchingConfig(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user.employerProfile) {
    return <Redirect to={getProfileRedirect(user)} />;
  }

  if (isFetchingConfig) {
    return <LoadingScreen />;
  }
  return (
    <div>
      <Switch>
        <Route path={`${path}/withdraw`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <UserWalletWithdraw />
          </PageWrapper>
        </Route>
        <Route path={`${path}/sendlink`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <EmployerSendPaymentLink />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const EmployerCandidateRoute = withRouter((props) => {
  let { path } = useRouteMatch();
  const params = new URLSearchParams(props.location.search);
  const jobId = params.get("jobId");
  const backUrl = `/employer/jobapplication/${jobId}/search`;
  return (
    <div>
      <Switch>
        <Route path={`${path}/:candidateId/details`}>
          <PageWrapper>
            <SidebarWithBackButton backUrl={backUrl} />
            <CandidateDetails />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
});

const EmployerMyCandidateRoute = ({ user }) => {
  let { path } = useRouteMatch();
  if (!user.employerProfile) {
    return <Redirect to={getProfileRedirect(user)} />;
  }
  return (
    <div>
      <Switch>
        <Route path={`${path}/:myCandidateId/details`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <EmployerInterviewDetails />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const EmployerPostedJobRoute = ({ user }) => {
  let { path } = useRouteMatch();
  if (!user.employerProfile) {
    return <Redirect to={getProfileRedirect(user)} />;
  }
  return (
    <div>
      <Switch>
        <Route path={`${path}/:jobId/details`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <EmployerJobDetails />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const EmployerJobApplicationRoute = ({ user }) => {
  let { path, params } = useRouteMatch();
  const { jobId } = params;
  const [isFetchingConfig, setIsFetchingConfig] = useState(true);
  const [job, setJob] = useState({});
  const dispatch = useDispatch();

  const getJobDetails = async () => {
    try {
      const { job } = await getJobById(jobId);
      setJob(job);
      setIsFetchingConfig(false);
    } catch (e) {
      toaster.negative(
        <div>Error while fetching job details. Please reload the page.</div>,
        { autoHideDuration: 3000 }
      );
    }
  };
  useEffect(() => {
    dispatch(fetchUserConfig()).then(async () => await getJobDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user.employerProfile) {
    return <Redirect to={getProfileRedirect(user)} />;
  }

  if (isFetchingConfig) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Switch>
        <Route path={`${path}/search`}>
          <PageWrapper>
            <SidebarWrapper>
              <SidebarJobApplications
                job={job}
                jobId={jobId}
                pageId={"search"}
              />
            </SidebarWrapper>
            <RCDashboardSearch job={job} jobId={jobId} isEmployer />
          </PageWrapper>
        </Route>
        <Route path={`${path}/handpicked`}>
          <PageWrapper>
            <SidebarWrapper>
              <SidebarJobApplications
                job={job}
                jobId={jobId}
                pageId={"handpicked"}
              />
            </SidebarWrapper>
            <HandpickedCandidates job={job} jobId={jobId} />
          </PageWrapper>
        </Route>
        <Route path={`${path}/bin`}>
          <PageWrapper>
            <SidebarWrapper>
              <SidebarJobApplications job={job} jobId={jobId} pageId={"bin"} />
            </SidebarWrapper>
            <BinCandidates job={job} jobId={jobId} />
          </PageWrapper>
        </Route>
        <Route path={`${path}/shortlist`}>
          <PageWrapper>
            <SidebarWrapper>
              <SidebarJobApplications
                job={job}
                jobId={jobId}
                pageId={"shortlist"}
              />
            </SidebarWrapper>
            <ShortlistedCandidates job={job} jobId={jobId} />
          </PageWrapper>
        </Route>
        <Route path={`${path}/bought`}>
          <PageWrapper>
            <SidebarWrapper>
              <SidebarJobApplications
                job={job}
                jobId={jobId}
                pageId={"bought"}
              />
            </SidebarWrapper>
            <BoughtCandidates job={job} jobId={jobId} />
          </PageWrapper>
        </Route>
        <Route path={`${path}/funnel`}>
          <Box mt={"50px"}>
            <CandidateFunnel job={job} jobId={jobId} />
          </Box>
        </Route>
        <Route path={`${path}/candidate/:anonymousId/history`}>
          <PageWrapper>
            <SidebarWrapper>
              <SidebarWithBackButton />
            </SidebarWrapper>
            <InterviewHistory job={job} jobId={jobId} />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const EmployerCreateInterviewRoute = ({ user }) => {
  let { path } = useRouteMatch();
  const [isFetchingConfig, setIsFetchingConfig] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserConfig()).then(() => setIsFetchingConfig(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user.employerProfile) {
    return <Redirect to={getProfileRedirect(user)} />;
  }

  if (isFetchingConfig) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Switch>
        <Route path={`${path}/:candidateId`}>
          <PageWrapper>
            <SidebarWithBackButton />
            <CreateInterviewRequest />
          </PageWrapper>
        </Route>
      </Switch>
    </div>
  );
};

const EmployerRoute = ({ user }) => {
  let { path } = useRouteMatch();
  if (user && user.role !== "EMPLOYER") {
    return <Redirect to={getHomeRedirect(user)} />;
  }
  return (
    <Container>
      <Switch>
        <Route path={`${path}/profile`}>
          <EmployerProfileRoute user={user} />
        </Route>
        <Route path={`${path}/home`}>
          <EmployerHomeRoute user={user} />
        </Route>
        <Route path={`${path}/candidate`}>
          <EmployerCandidateRoute user={user} />
        </Route>
        <Route path={`${path}/mycandidate`}>
          <EmployerMyCandidateRoute user={user} />
        </Route>
        <Route path={`${path}/jobapplication/:jobId`}>
          <EmployerJobApplicationRoute user={user} />
        </Route>
        <Route path={`${path}/postedjob`}>
          <EmployerPostedJobRoute user={user} />
        </Route>
        <Route path={`${path}/create-interview`}>
          <EmployerCreateInterviewRoute user={user} />
        </Route>
        <Route path={`${path}/wallet`}>
          <EmployerWalletRoute user={user} />
        </Route>
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      <PageHeader showTutorial />
      <ChangePictureModel />
      <TutorialModel />
      <ShareCandidateModel />
      <ShareJobModel />
    </Container>
  );
};

export default EmployerRoute;
