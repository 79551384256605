import { Input } from "@chakra-ui/react";
import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import BaseRichTextEditor, { getTextAlignClassName } from "react-rte";
var debounce = require("lodash.debounce");

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS" /*"LINK_BUTTONS"*/],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export const RichTextEditor = ({
  value,
  format = "html",
  onChange: onValChange,
  id = "",
  ...rest
}) => {
  // console.log("rte", val);
  // const [val, setVal] = useState(
  //   BaseRichTextEditor.createValueFromString(value || "", format)
  // );
  // useEffect(() => {
  //   setVal(BaseRichTextEditor.createValueFromString(value || "", format));
  // }, [value]);

  // const debounced = useCallback(
  //   debounce((val) => {
  //     onValChange(val.toString(format));
  //   }, 500),
  //   [debounce]
  // );

  // console.log("rte", val);
  return (
    <BaseRichTextEditor
      blockStyleFn={getTextAlignClassName}
      toolbarConfig={toolbarConfig}
      value={value}
      className={"react-rte"}
      rootStyle={{ borderRadius: 8 }}
      toolbarStyle={{}}
      onChange={(val) => {
        onValChange(val);
        // setVal(val);
      }}
      {...rest}
    />
  );
};

export const RichTextViewer = ({ value }) => {
  return (
    <BaseRichTextEditor
      blockStyleFn={getTextAlignClassName}
      className={"richtext-viewer"}
      value={BaseRichTextEditor.createValueFromString(value, "html")}
      readOnly
    />
  );
};

export const createEmptyValue = BaseRichTextEditor.createEmptyValue;
export const createValueFromString = BaseRichTextEditor.createValueFromString;
