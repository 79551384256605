import React from "react";
import { Datepicker } from "baseui/datepicker";

const DateSelector = (props) => (
  <Datepicker
    {...props}
    maxDate={new Date(Date.now())}
    minDate={new Date("1970-10-18T07:00:00.000Z")}
    overrides={{
      Input: {
        props: {
          overrides: {
            InputContainer: {
              style: ({ $theme }) => {
                return {
                  backgroundColor: "#fff",
                  borderColor: "#c1c1c1",
                  borderTopWidth: "1px",
                  borderBottomWidth: "1px",
                  borderLeftWidth: "1px",
                  borderRightWidth: "1px",
                };
              },
            },
          },
        },
      },
    }}
  >
    {props.children}
  </Datepicker>
);

export default DateSelector;
