import { LabelLarge, ParagraphMedium } from "baseui/typography";
import React, { useEffect, useState } from "react";
import { getCountInterviewRequestsPerCandidate } from "../api/employer";
import Button from "./Button";
import Card from "./Card";
import { Block } from "baseui/block";
import { withRouter } from "react-router-dom";

export const CandidateInterviewHistory = withRouter(
  ({ jobId, anonymousId, history }) => {
    const [count, setCount] = useState(0);
    const getInterviewHistoryCount = async () => {
      try {
        const { count } = await getCountInterviewRequestsPerCandidate(
          anonymousId,
          jobId
        );
        setCount(count);
      } catch (e) {}
    };
    useEffect(() => {
      if (!jobId || !anonymousId) return null;
      getInterviewHistoryCount();
    }, [jobId, anonymousId]);
    if (count === 0) return null;
    return (
      <Card
        marginTop="scale800"
        marginBottom="scale800"
        backgroundColor="accent50"
      >
        <Block
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <ParagraphMedium>{`You have sent ${count} interview requests to this candidate in the past.`}</ParagraphMedium>
          <Button
            kind="tertiary"
            onClick={() => {
              history.push(
                `/employer/jobapplication/${jobId}/candidate/${anonymousId}/history`
              );
            }}
          >
            See Details
          </Button>
        </Block>
      </Card>
    );
  }
);
