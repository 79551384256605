import React, { useState } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { Link, withRouter } from "react-router-dom";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import { relativeTime } from "../util/time";
import { getAllInterviewRequests, rejectInterviewRequest } from "../api/admin";
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table";
import Button from "../components/Button";
import Currency from "../components/Currency";
import { Block } from "baseui/block";
import { LabelSmall } from "baseui/typography";
import { toaster } from "baseui/toast";
import PopConfirm from "../components/PopConfirm";
import { KIND, SIZE } from "baseui/button";

const InterviewRequestRow = (props) => {
  const [isRejecting, setIsRejecting] = useState(false);
  const onInterviewReject = async () => {
    setIsRejecting(true);
    try {
      await rejectInterviewRequest(props.interviewRequest.interviewRequest);
      props.onReject && props.onReject();
    } catch (e) {
      toaster.show(<div>Error while rejecting. please try again</div>, {
        autoHideDuration: 30000,
      });
    }
    setIsRejecting(false);
  };
  return (
    <StyledRow key={props.index}>
      <StyledCell>
        <Block>
          <LabelSmall>{props.interviewRequest.employerUser?.name}</LabelSmall>
          <Block>{props.interviewRequest.job?.company.name}</Block>
        </Block>
      </StyledCell>
      <StyledCell>
        <Block>
          <LabelSmall>{props.interviewRequest.candidateUser.name}</LabelSmall>
          <Block>{props.interviewRequest.candidateUser.email}</Block>
          <Block>{props.interviewRequest.candidateUser.phone}</Block>
        </Block>
      </StyledCell>
      <StyledCell>
        <Block>
          <Block>
            <Currency
              currency={
                props.interviewRequest.interviewRequest.offeredCTCCurrency
              }
              value={props.interviewRequest.interviewRequest.offeredCTC}
            />
          </Block>
          <Block>
            <Link
              to={
                "/N0VHxB8M/admin/details/interview-request/" +
                props.interviewRequest.interviewRequest.id
              }
            >
              {props.interviewRequest.interviewRequest.status}
            </Link>
          </Block>
          <Block>
            {relativeTime(
              Date.now(),
              props.interviewRequest.interviewRequest.createdAt
            )}
          </Block>
        </Block>
      </StyledCell>
      <StyledCell>
        {props.interviewRequest.interviewRequest.status === "REVIEWED" ? (
          <PopConfirm
            showArrow
            title="Are you sure to reject this interview?"
            onConfirm={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              await onInterviewReject();
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Button
              size={SIZE.compact}
              kind={KIND.tertiary}
              isLoading={isRejecting}
              buttonStyle={{ width: "100%" }}
              style={{ marginRight: 8 }}
            >
              Reject
            </Button>
          </PopConfirm>
        ) : null}
      </StyledCell>
    </StyledRow>
  );
};

class AdminInterviewRequestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      interviewRequests: [],
      hasNext: false,
      hasPrev: false,
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    try {
      let { data: interviewRequests, hasNext } = await getAllInterviewRequests(
        this.state.page,
        this.state.limit
      );
      this.setState({
        interviewRequests,
        hasNext,
      });
    } catch (e) {
      console.log(e);
    }
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  render() {
    return (
      <div>
        <Title>Interview Request List</Title>
        <StyledTable>
          <StyledHead>
            <StyledHeadCell>Employer</StyledHeadCell>
            <StyledHeadCell>Candidate</StyledHeadCell>
            <StyledHeadCell>Offered CTC</StyledHeadCell>
            <StyledHeadCell>Reject</StyledHeadCell>
          </StyledHead>
          <StyledBody>
            {/* <div style={{ marginBottom: "32px" }}> */}
            {this.state.interviewRequests.map(
              (interviewRequest, index) => (
                // interviewRequest.employerUser &&
                // interviewRequest.candidateUser && (
                <InterviewRequestRow
                  key={index}
                  onReject={() => this.onLoad()}
                  interviewRequest={interviewRequest}
                />
              )
              // )
            )}
            {/* </div> */}
          </StyledBody>
        </StyledTable>
        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminInterviewRequestList));
