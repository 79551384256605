import { WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Spacer,
  Spinner,
  Text,
  useBreakpointValue,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { FiCheck, FiX } from "react-icons/fi";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getJobById } from "src/api/job";
import {
  getReferCandidate,
  ReferredCandidateProps,
  updateReferCandidate,
} from "src/api/referred_candidate";
import Currency from "src/components/Currency";
import { RichTextViewer } from "src/components/RichTextEditor";
import { Job } from "src/screens/talentscout/UploadCandidate";

export const JobDescription = () => {
  const location = useLocation();
  const [isInvalidPage, setIsInvalidPage] = useState(false);
  const [referredCandidate, setReferredCandidate] = useState<
    ReferredCandidateProps
  >();
  const [isFetching, setIsFetching] = useState(false);
  const [job, setJob] = useState<Job>();
  const toast = useToast();
  const history = useHistory();
  const user = useSelector((state: any) => state.user);
  const buttonSize = useBreakpointValue({ base: "lg", md: "md" });
  const fetchReferredCandidate = useCallback(
    async (id: string) => {
      setIsFetching(true);
      try {
        const referredCandidate = await getReferCandidate(id);
        setReferredCandidate(referredCandidate);
        const { job } = await getJobById(referredCandidate.jobId);
        setJob(job);
        setIsFetching(false);
      } catch (e) {
        toast({
          title: "Error while fetching details",
          description: "Please try again",
        });
        setIsFetching(false);
      }
    },
    [toast]
  );
  useEffect(() => {
    let params = new URLSearchParams(location.search);
    if (!params.get("rid")) {
      setIsInvalidPage(true);
      return;
    }
    fetchReferredCandidate(params.get("rid") ?? "");
  }, [setIsInvalidPage, fetchReferredCandidate, location.search]);
  if (isInvalidPage) {
    return (
      <Center height="100vh">
        <Box>
          <Center mb={16}>
            <WarningIcon fontSize="5xl"></WarningIcon>
          </Center>
          <Heading fontWeight="semibold" fontSize="3xl" textAlign={"center"}>
            This link is not correct. Please check again.
          </Heading>
        </Box>
      </Center>
    );
  }

  if (isFetching) {
    return (
      <Center height="100vh">
        <Box textAlign={"center"}>
          <Spinner color="green.500" />
        </Box>
      </Center>
    );
  }

  if (!referredCandidate) return null;

  if (user.email?.toLowerCase() !== referredCandidate.email?.toLowerCase()) {
    return (
      <Center height="100vh">
        <Box>
          <Center mb={16}>
            <WarningIcon fontSize="5xl"></WarningIcon>
          </Center>
          <Heading fontWeight="semibold" fontSize="3xl" textAlign={"center"}>
            Email does not match with the submitted data.
          </Heading>
        </Box>
      </Center>
    );
  }

  if (referredCandidate.isVerified) {
    history.push(
      "/candidate/verification/confirmation?rid=" +
        referredCandidate.id +
        "&confirm=true"
    );
    return null;
  }
  if (referredCandidate.status === "REJECTED") {
    history.push(
      "/candidate/verification/confirmation?rid=" +
        referredCandidate.id +
        "&confirm=false"
    );
    return null;
  }

  return (
    <Box py={8} px={4} mb={"60px"}>
      <Heading fontWeight="semibold" fontSize="xl">
        {job?.company?.name}
      </Heading>
      <Spacer height={4} />
      <VStack spacing={1} alignItems="flex-start" justifyContent={"center"}>
        <Flex>
          <Text fontWeight="semibold">Functional Area: </Text>
          <Text ml={2}>
            {job?.functionalAreas?.map((loc) => loc.name).join(",")}
          </Text>
        </Flex>
        <Flex>
          <Text fontWeight="semibold">Skills: </Text>
          <Text ml={2}>{job?.skills?.map((loc) => loc.name).join(",")}</Text>
        </Flex>
        <Flex>
          <Text fontWeight="semibold">Locations: </Text>
          <Text ml={2}>{job?.locations?.map((loc) => loc.name).join(",")}</Text>
        </Flex>
        <Spacer height={4} />
        <Box>
          <RichTextViewer value={job?.jobDescription} />
        </Box>
      </VStack>
      <Flex
        position={{ base: "fixed", md: "static" }}
        bottom={0}
        right={0}
        left={0}
        mt={{ base: 0, md: 8 }}
        p={{ base: 2, md: 0 }}
        background={{ base: "white", md: "inherit" }}
        borderTopColor="gray.100"
        borderTopWidth={{ base: 2, md: 0 }}
      >
        <Button
          variant="ghost"
          width={{ base: "100%", md: "auto" }}
          size={buttonSize}
          leftIcon={<FiX />}
          onClick={async () => {
            if (!referredCandidate || !referredCandidate?.id) return;
            try {
              await updateReferCandidate(referredCandidate?.id, {
                ...referredCandidate,
                status: "REJECTED",
                isVerified: true,
                statusMessage: "Candidate not interested in JD",
              });
              history.push(
                "/candidate/verification/confirmation?rid=" +
                  referredCandidate.id +
                  "&confirm=false"
              );
            } catch (e) {
              toast({
                title: "Error while proceeding",
                description: "Please try again",
              });
            }
          }}
        >{`Not Interested`}</Button>
        <Button
          colorScheme="green"
          width={{ base: "100%", md: "auto" }}
          size={buttonSize}
          leftIcon={<FiCheck />}
          onClick={async () => {
            if (!referredCandidate || !referredCandidate?.id) return;
            try {
              await updateReferCandidate(referredCandidate?.id, {
                ...referredCandidate,
                isVerified: true,
              });
              history.push(
                "/candidate/verification/confirmation?rid=" +
                  referredCandidate.id +
                  "&confirm=true"
              );
            } catch (e) {
              toast({
                title: "Error while proceeding",
                description: "Please try again",
              });
            }
          }}
        >{`Get Interview`}</Button>
      </Flex>
    </Box>
  );
};
