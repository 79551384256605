import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";
import React from "react";
import logo from "src/icons/logo.svg";

export const InterviewRejectConfirmation = () => {
  return (
    <Container
      maxW="lg"
      py={{ base: "2", md: "4" }}
      px={{ base: "4", sm: "4" }}
      bg="white"
      minH="100vh"
      boxShadow={"base"}
    >
      <Flex justifyContent={"space-between"} mb={4} alignItems="center">
        <Heading size="lg" letterSpacing={"tight"}>
          Rejected
        </Heading>
        <img src={logo} alt={"Finding Jo"} height="100px" width="120px" />
      </Flex>
      <Box>
        <Text>We will try to get more interviews for you.</Text>
        <Text>You can close this tab now.</Text>
      </Box>
    </Container>
  );
};
