export function relativeTime(current, previous) {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerMonth) {
    return Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return Math.round(elapsed / msPerYear) + " years ago";
  }
}

export function prettyPrint(epoch, dateOnly) {
  let dt = new Date(epoch);
  let res =
    [
      "Jan.",
      "Feb.",
      "Mar.",
      "Apr.",
      "May",
      "Jun.",
      "Jul.",
      "Aug.",
      "Sep.",
      "Oct.",
      "Nov.",
      "Dec.",
    ][dt.getMonth()] +
    " " +
    printDate(dt.getDate()) +
    ", " +
    dt.getFullYear();

  if (!dateOnly) {
    res = res + " " + dt.getHours() + ":" + ("0" + dt.getMinutes()).slice(-2);
  }

  return res;
}

export function printMonthYear(epoch) {
  let dt = new Date(epoch);
  return (
    [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ][dt.getMonth()] +
    " " +
    dt.getFullYear()
  );
}

function printDate(d) {
  var s = d.toString(),
    l = s[s.length - 1];
  return s + (["st", "nd", "rd"][l - 1] || "th");
}

export function getTimeFromMonthAndYear(month, year) {
  if (typeof month === "undefined" || typeof year === "undefined") return 0;
  if (year === 0) return 0;
  let d = new Date();
  d.setFullYear(year);
  d.setMonth(month);
  d.setDate(1);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return d.getTime();
}

function mergeIntervals(intervals) {
  if (intervals.length <= 0) {
    return [];
  }
  let merged = [];
  intervals.sort((a, b) => a.startTime - b.startTime);
  merged.push(intervals[0]);
  for (let i = 1; i < intervals.length; i++) {
    let last = merged[merged.length - 1];
    if (last.endTime < intervals[i].startTime) {
      merged.push(intervals[i]);
    } else if (last.endTime < intervals[i].endTime) {
      last.endTime = intervals[i].endTime;
      merged.pop();
      merged.push(last);
    }
  }
  merged.reverse();
  return merged;
}

export function getCareerGaps(durationList) {
  let currentTime = Date.now();
  for (let i = 0; i < durationList.length; i++) {
    if (!durationList[i].endTime) {
      durationList[i].endTime = currentTime;
    }
  }
  // let merged = mergeIntervals(durationList);
  let merged = durationList;
  let result = [];
  for (let i = 0; i < merged.length - 1; i++) {
    if (
      merged[i].startTime - merged[i + 1].endTime >
      1000 * 60 * 60 * 24 * 30 * 6
    ) {
      result.push({
        position: i + 1,
        duration: parseInt(
          (merged[i].startTime - merged[i + 1].endTime) /
            (1000 * 60 * 60 * 24 * 30)
        ),
        startTime: merged[i + 1].endTime,
        endTime: merged[i].startTime,
      });
    }
  }
  return result;
}

export function getDays(durationList) {
  let currentTime = Date.now();
  for (let i = 0; i < durationList.length; i++) {
    if (!durationList[i].endTime) {
      durationList[i].endTime = currentTime;
    }
  }
  let merged = mergeIntervals(durationList);
  let totalDuration = 0;
  for (let i = 0; i < merged.length; i++) {
    totalDuration += merged[i].endTime - merged[i].startTime;
  }
  return totalDuration / (1000 * 60 * 60 * 24);
}

export function displayDuration(days) {
  if (days === 0) return "--";
  if (days > 0 && days < 30) days = 30;
  let years = parseInt(days / 365);
  let months = parseInt((days % 365) / 30);
  let res = "";
  if (years > 0) {
    res = years + " yrs ";
  }
  return res + months + " mos";
}

let monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function getMonthList() {
  let list = [];
  for (let i = 0; i < monthArray.length; i++) {
    list.push({ id: i, name: monthArray[i] });
  }
  return list;
}

function getYearList() {
  let list = [];
  for (let i = 2022; i > 1960; i--) {
    list.push({ id: i, name: i });
  }
  return list;
}

export const MONTHS = getMonthList();

export let YEARS = getYearList();

export function getMonthValue(month) {
  if (typeof month === "undefined") return "";
  return {
    id: month,
    name: monthArray[month],
  };
}

export function getYearValue(year) {
  if (typeof year === "undefined") return "";
  return {
    id: year,
    name: year,
  };
}

export function getExperienceDuration(days) {
  return (
    parseInt(days / 365) + " Years " + parseInt((days % 365) / 30) + " Months"
  );
}

export function getMonthDuration(startTime, endTime) {
  let days = (endTime - startTime) / (60 * 1000 * 60 * 24);
  return parseInt(days / 30) + " Months";
}
