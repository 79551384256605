import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import SidebarItem from "./SidebarItem";
import {
  EMPLOYER_POSTED_JOBS,
  EMPLOYER_POST_JOB,
  EMPLOYER_WALLET,
} from "../util/routes";
import Currency from "./Currency";
import WorkspaceSelector from "./WorkspaceSelector";
import { Block } from "baseui/block";
import { LabelSmall } from "baseui/typography";

class SidebarEmployerHome extends React.Component {
  onPromoteProfileChange() {}

  render() {
    console.log("this.props.user.role", this.props.user.role);
    const walletAmount =
      this.props.user.role === "EMPLOYER"
        ? this.props.wallet.amount
        : this.props.user.walletAmount;
    const walletCurrency =
      this.props.user.role === "EMPLOYER"
        ? this.props.wallet.currency
        : this.props.user.walletCurrency;
    return (
      <div>
        <WorkspaceSelector />
        {/* <SidebarItem
          title={"Search Candidates"}
          enabled
          icon={"search"}
          selected={this.props.page === "search"}
          onClick={() => {
            this.props.history.push(EMPLOYER_SEARCH_CANDIDATES);
          }}
        />
        <SidebarItem
          title={"My Candidates"}
          enabled
          icon={"users"}
          selected={this.props.page === "mycandidates"}
          onClick={() => {
            this.props.history.push(EMPLOYER_MY_CANDIDATES);
          }}
        /> */}
        <SidebarItem
          title={"Post Job"}
          enabled
          icon={"plus-square"}
          selected={this.props.page === "postjob"}
          onClick={() => {
            this.props.history.push(EMPLOYER_POST_JOB);
          }}
        >
          <Block display="flex" alignItems="center" marginLeft="scale400">
            <LabelSmall>Free</LabelSmall>
          </Block>
        </SidebarItem>
        <SidebarItem
          title={"Posted Jobs"}
          enabled
          icon={"paperclip"}
          selected={this.props.page === "postedjobs"}
          onClick={() => {
            this.props.history.push(EMPLOYER_POSTED_JOBS);
          }}
        />
        <SidebarItem
          title={"Wallet"}
          enabled
          selected={this.props.page === "wallet"}
          onClick={() => {
            this.props.history.push(EMPLOYER_WALLET);
          }}
          icon={"dollar-sign"}
        >
          <Block display="flex" alignItems="center" marginLeft="scale400">
            {walletAmount === 0 && (
              <span style={{ marginRight: "16px" }}>Add funds</span>
            )}
            <Currency currency={walletCurrency} value={walletAmount / 100} />
          </Block>
        </SidebarItem>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
    wallet: state.wallet,
  }),
  { setUser }
)(withRouter(SidebarEmployerHome));
