import React from "react";
import { Flex, Tag } from "@chakra-ui/react";

export const CandidateTags = ({ candidate }: any) => {
  const {
    isRemoteReady,
    startupTypes,
    premium,
    alreadyResigned,
    lastWorkingDate,
  } = candidate;
  const { workShowcases, existingOffers } = premium || {};
  const isFundedStartups = (startupTypes || []).indexOf("FUNDED_STARTUPS") > -1;
  const isEarlyStageStartups = (startupTypes || []).indexOf("EARLY_STAGE") > -1;
  const inNoticePeriod = alreadyResigned && Date.now() < lastWorkingDate;
  const hasOffers = existingOffers && existingOffers.length > 0;
  const pastLastWorkingDay =
    alreadyResigned &&
    Date.now() > lastWorkingDate &&
    lastWorkingDate < Date.now() + 1000 * 60 * 60 * 24 * 30 * 3;
  const isImmediateJoinee = !hasOffers && pastLastWorkingDay;
  const isPassive = hasOffers && pastLastWorkingDay;
  return (
    <Flex alignItems={"center"} flexWrap="wrap">
      {!!isRemoteReady ? (
        <Tag variant="solid" colorScheme="gray" mr={2} mb={1}>
          Remote
        </Tag>
      ) : null}
      {!!hasOffers ? (
        <Tag variant="solid" colorScheme="green" mr={2} mb={1}>
          Has Another Offer
        </Tag>
      ) : null}
      {!!inNoticePeriod ? (
        <Tag variant="solid" colorScheme="green" mr={2} mb={1}>
          In Notice Period
        </Tag>
      ) : null}
      {!!isImmediateJoinee ? (
        <Tag variant="solid" colorScheme="green" mr={2} mb={1}>
          Immediate Joinee
        </Tag>
      ) : null}
      {!!isPassive ? (
        <Tag variant="solid" colorScheme="gray" mr={2} mb={1}>
          Passive
        </Tag>
      ) : null}
      {isFundedStartups || isEarlyStageStartups ? (
        <Tag variant="solid" colorScheme="gray" mr={2} mb={1}>
          Startups
        </Tag>
      ) : null}
      {workShowcases && workShowcases.length > 0 && (
        <Tag variant="solid" colorScheme="gray" mr={2} mb={1}>
          {workShowcases[0].category}
        </Tag>
      )}
    </Flex>
  );
};
