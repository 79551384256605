import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  toast,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  getReferCandidate,
  handpickReferCandidateApi,
  ReferredCandidateProps,
} from "src/api/referred_candidate";
import { CommentsTab } from "./CommentsTab";
import { DataVerificationTab } from "./DataVerificationTab";
import { JobDetailsTab } from "./JobDetailsTab";
import { PreApprovalTab } from "./PreApprovalTab";
import { ResumesTab } from "./ResumesTab";

export type ReferredCandidateDetailsProps = {
  userRoles: string[]; //("PREAPPROVER" | "ADMIN" | "DATAVERIFIER")
};

export const ReferredCandidateDetails = ({
  userRoles,
}: ReferredCandidateDetailsProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [referredCandidate, setReferredCandidate] = useState<
    ReferredCandidateProps
  >();
  const [isHandpicking, setIsHandpicking] = useState(false);
  const { referredCandidateId }: any = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const purpose = params.get("purpose");
  const history = useHistory();
  const toast = useToast();
  const fetchReferredCandidate = useCallback(
    async (isSilent?: boolean) => {
      !isSilent && setIsFetching(true);
      try {
        const referredCandidate = await getReferCandidate(referredCandidateId);
        setReferredCandidate(referredCandidate);
      } catch (e) {}
      !isSilent && setIsFetching(false);
    },
    [referredCandidateId]
  );
  useEffect(() => {
    fetchReferredCandidate();
  }, [fetchReferredCandidate]);
  if (isFetching) {
    return (
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        height="100vh"
        width="100%"
      >
        <Spinner />
      </Flex>
    );
  }
  if (!referredCandidate) {
    return (
      <Box>
        <Text>Candidate not found</Text>
      </Box>
    );
  }

  const isAdmin = userRoles.includes("ADMIN");
  const isDataVerifier = userRoles.includes("DATAVERIFIER") || isAdmin;
  const isPreApprover = userRoles.includes("PREAPPROVER") || isAdmin;
  if (!purpose && !isAdmin) {
    return null;
  }
  const showAll = !purpose && isAdmin;
  if (purpose === "PREAPPROVAL" && isPreApprover) {
    return (
      <Box p={4}>
        <Text textStyle="h3">{referredCandidate?.email}</Text>
        <Tabs variant="enclosed">
          <TabList>
            <Tab>Uploaded Details</Tab>
            <Tab>Resumes</Tab>
            <Tab>Job Details</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <PreApprovalTab
                purpose={purpose}
                candidate={referredCandidate}
                onUpdated={async () => {}}
              />
            </TabPanel>
            <TabPanel>
              <ResumesTab
                candidate={referredCandidate}
                onUpdated={async () => {}}
              />
            </TabPanel>
            <TabPanel>
              <JobDetailsTab
                candidate={referredCandidate}
                onUpdated={async () => {}}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    );
  }
  if (purpose === "DATAVERIFICATION" && isDataVerifier) {
    return (
      <Box p={4}>
        <Text textStyle="h3">{referredCandidate?.email}</Text>
        <Tabs variant="enclosed">
          <TabList>
            <Tab>Data Verification</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <DataVerificationTab
                candidate={referredCandidate}
                onUpdated={async (referredCandidate) => {
                  setReferredCandidate(referredCandidate);
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    );
  }
  if (showAll) {
    return (
      <Box p={4}>
        <Flex mb={8} alignItems="center">
          <Heading size="lg" fontWeight="semibold">
            {referredCandidate?.email}
          </Heading>
          {/* {referredCandidate.status === "DATAANONYMISATION" && (
            <Button
              ml={4}
              colorScheme="green"
              isLoading={isHandpicking}
              onClick={async () => {
                if (!referredCandidate.id) return;
                try {
                  setIsHandpicking(true);
                  await handpickReferCandidateApi(referredCandidate.id);
                  await fetchReferredCandidate();
                  setIsHandpicking(false);
                } catch (e) {
                  setIsHandpicking(false);
                  toast({
                    title: "Error while handpicking",
                    description: "Please try again",
                  });
                }
              }}
            >
              Handpick
            </Button>
          )} */}
        </Flex>
        <Tabs variant="enclosed">
          <TabList>
            <Tab>Uploaded Details</Tab>
            <Tab>Data Verification</Tab>
            <Tab>Resumes</Tab>
            <Tab>Job Details</Tab>
            <Tab>Comments</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <PreApprovalTab
                candidate={referredCandidate}
                onUpdated={async (referredCandidate) => {
                  setReferredCandidate(referredCandidate);
                }}
              />
            </TabPanel>
            <TabPanel>
              <DataVerificationTab
                candidate={referredCandidate}
                onUpdated={async (referredCandidate) => {
                  setReferredCandidate(referredCandidate);
                }}
              />
            </TabPanel>
            <TabPanel>
              <ResumesTab
                candidate={referredCandidate}
                onUpdated={async () => {}}
              />
            </TabPanel>
            <TabPanel>
              <JobDetailsTab
                candidate={referredCandidate}
                onUpdated={async () => {}}
              />
            </TabPanel>
            <TabPanel>
              <CommentsTab referredCandidate={referredCandidate} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    );
  }
  return null;
};
