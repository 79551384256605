import { styled } from "baseui";
import React from "react";

const EntryLabel = styled("div", {
  color: "#999999",
  width: "158px",
  paddingRight: "8px",
});

const EntryValue = styled("div", {
  display: "flex",
  flex: 1,
});

const Entry = (props) => (
  <div style={{ display: "flex" }}>
    <EntryLabel isAnonymous={props.isAnonymous} title={props.title}>
      {props.label}
    </EntryLabel>
    <EntryValue isAnonymous={props.isAnonymous} title={props.title}>
      {props.value}
    </EntryValue>
  </div>
);

export default Entry;
