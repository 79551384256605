import React, { useState } from "react";
import Select from "./Select";
import Button from "./Button";
import { SIZE, KIND as BUTTON_KIND, KIND } from "baseui/button";
import EntityCreateForm from "./EntityCreateForm";
import { Icon } from "./Icon";

const EMPTY_VAL = { id: "", name: "" };

const MultiSelect = (props) => {
  const [createModalValue, setCreateModalValue] = useState("");
  const [editModalValue, setEditModalValue] = useState("");
  if (props.kind === "anonymous") {
    return (
      <div>
        {((props.multi ? props.value : [props.value]) || []).map((val, idx) => (
          <div style={{ height: "34px", marginBottom: "10px" }}>
            {val.anonymousName}
          </div>
        ))}
      </div>
    );
  }

  if (!props.value) {
    return <div>Value not available</div>;
  }

  return (
    <div>
      {((props.multi ? props.value : [props.value]) || []).map((val, idx) => (
        <div style={{ marginBottom: 10, display: "flex" }} key={idx}>
          <div
            style={{
              marginRight: 8,
              border: val && val.isCreatable ? "2px solid orange" : "none",
              borderRadius: "2px",
              width: "250px",
            }}
          >
            <Select
              {...props}
              multi={false}
              clearable={false}
              creatable={false}
              onChange={(value) => {
                if (!value) {
                  value = EMPTY_VAL;
                }
                if (props.multi) {
                  let newVal = props.value
                    .slice(0, idx)
                    .concat([value])
                    .concat(props.value.slice(idx + 1));
                  props.onChange(newVal);
                } else {
                  props.onChange(value);
                }
              }}
              value={val}
              options={props.getOptions}
            ></Select>
          </div>
          {
            <div style={{ marginRight: "10px" }}>
              <Button
                type={"button"}
                size={SIZE.mini}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setCreateModalValue(val.name);
                }}
                buttonStyle={{ padding: "8px" }}
              >
                <Icon size={16} name="plus" color="white"></Icon>
              </Button>
              <EntityCreateForm
                isVisible={val && val.name && createModalValue === val.name}
                entityName={props.entityName}
                entityValue={val.name}
                isAnonymousRequired={props.isAnonymousRequired}
                onClose={() => {
                  setCreateModalValue("");
                }}
                onCreated={(entity) => {
                  if (props.multi) {
                    let newVal = props.value
                      .slice(0, idx)
                      .concat([entity])
                      .concat(props.value.slice(idx + 1));
                    props.onChange(newVal, true);
                  } else {
                    props.onChange(entity, true);
                  }
                  // props.onNewEntityCreated && props.onNewEntityCreated(entity);
                  setCreateModalValue("");
                }}
              />
            </div>
          }
          {
            <div>
              <Button
                type={"button"}
                size={SIZE.mini}
                kind={BUTTON_KIND.minimal}
                buttonStyle={{ padding: "8px" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEditModalValue(val.name);
                }}
              >
                <Icon name="edit" size={16} />
              </Button>
              <EntityCreateForm
                entityId={val.id}
                isVisible={val && val.name && editModalValue === val.name}
                entityName={props.entityName}
                entityValue={val.name}
                anonymousValue={val.anonymousName}
                isAnonymousRequired={props.isAnonymousRequired}
                onClose={() => {
                  setEditModalValue("");
                }}
                onCreated={(entity) => {
                  if (props.multi) {
                    let newVal = props.value
                      .slice(0, idx)
                      .concat([entity])
                      .concat(props.value.slice(idx + 1));
                    props.onChange(newVal, true);
                  } else {
                    props.onChange(entity, true);
                  }
                  setEditModalValue("");
                }}
              />
            </div> /*: null*/
          }
          {props.multi ? (
            <div>
              <Button
                type={"button"}
                size={SIZE.mini}
                kind={BUTTON_KIND.minimal}
                buttonStyle={{ padding: "8px" }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (props.multi) {
                    let newVal = props.value
                      .slice(0, idx)
                      .concat(props.value.slice(idx + 1));
                    props.onChange(newVal, false);
                  }
                }}
              >
                <Icon name="trash" size={16} />
              </Button>
            </div>
          ) : null}
        </div>
      ))}
      {props.multi && (
        <Button
          kind={KIND.tertiary}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            let newVal = props.value.concat([
              { id: "", name: "--", isCreatable: true },
            ]);
            props.onChange(newVal, false);
          }}
        >
          Add
        </Button>
      )}
    </div>
  );
};

export default MultiSelect;
