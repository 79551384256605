import React, { useCallback, useEffect, useState } from "react";
import { Center, Container, Spinner, Text } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { tokenAuth } from "src/api/auth";
import { setUser } from "src/redux/actions/user";
import { getUser } from "src/api/user";
import firebase from "firebase";
import * as Analytics from "src/analytics";
import * as Sentry from "src/util/sentry";

export const SignInWithToken = () => {
  const location = useLocation();
  const history = useHistory();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const stateUser = useSelector((state: any) => state.user);
  const doTokenAuth = useCallback(
    async (t: string) => {
      setLoading(true);
      try {
        const { token, url } = await tokenAuth({ signInToken: t });
        if (stateUser?.id) {
          setLoading(false);
          history.push(url);
          return;
        }
        let { user = {} } = (await firebase
          .auth()
          .signInWithCustomToken(token)) as any;
        if (user && user.uid) {
          let savedUser = await getUser(user.uid);
          Analytics.setUser(savedUser);
          Sentry.setUser(savedUser);
          dispatch(
            setUser({
              displayName: user.displayName,
              email: user.email,
              emailVerified: true,
              isAnonymous: user.isAnonymous,
              id: user.uid,
              providerData: user.providerData,
              ...savedUser,
            })
          );
          history.push(url);
          setLoading(false);
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setError(true);
      }
    },
    [dispatch, history, stateUser]
  );

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    const t = params.get("t");
    if (!t) {
      setError(true);
      return;
    }
    doTokenAuth(t);
  }, [location.search, doTokenAuth]);

  if (error) {
    return (
      <Container
        maxW="lg"
        py={{ base: "2", md: "4" }}
        px={{ base: "4", sm: "4" }}
        bg="white"
        minH="100vh"
        boxShadow={"base"}
        position="relative"
      >
        <Center minH={"100vh"}>
          <Text>This link seems invalid</Text>
        </Center>
      </Container>
    );
  }

  return (
    <Container
      maxW="lg"
      py={{ base: "2", md: "4" }}
      px={{ base: "4", sm: "4" }}
      bg="white"
      minH="100vh"
      boxShadow={"base"}
      position="relative"
    >
      <Center minH={"100vh"}>{loading ? <Spinner /> : null}</Center>
    </Container>
  );
};
