import { useState, useRef, useCallback, useEffect } from "react";

export type useOTPVerificationProps = {
  onOTPSend: () => Promise<void>;
  onOTPVerify: () => Promise<void>;
  onOTPError: (errorMessage: string) => void;
  onOTPSuccess: () => void;
};

export const useOTPVerification = ({
  onOTPSend,
  onOTPVerify,
  onOTPError,
  onOTPSuccess,
}: useOTPVerificationProps) => {
  const [isSending, setIsSending] = useState(false);
  const [isOTPVerifying, setIsOTPVerifying] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [isOTPSend, setIsOTPSend] = useState(false);
  const [otpTimeRemaining, setOTPTimeRemaining] = useState(0);
  const timer = useRef<number>();

  const updateOTPTimeRemaining = useCallback(() => {
    if (!timer) return;
    clearTimeout(timer?.current);
    timer.current = window.setTimeout(() => {
      if (otpTimeRemaining > 0) {
        setOTPTimeRemaining(otpTimeRemaining - 1);
      }
    }, 1000);
  }, [otpTimeRemaining]);

  useEffect(() => {
    updateOTPTimeRemaining();
    return () => {
      clearTimeout(timer?.current);
    };
  }, [otpTimeRemaining, updateOTPTimeRemaining]);

  const onOTPSendClick = async () => {
    setIsSending(true);
    try {
      await onOTPSend();
      setIsOTPSend(true);
      setOTPTimeRemaining(60);
    } catch (e) {
      const err = e as Error;
      onOTPError(err.message ?? "Error while sending otp. Please retry");
    }
    setIsSending(false);
  };

  const onOTPResendClick = async () => {
    setIsResending(true);
    try {
      await onOTPSend();
      setIsOTPSend(true);
    } catch (e) {
      const err = e as Error;
      onOTPError(err.message ?? "Error while resending otp. Please retry");
    }
    setIsResending(false);
  };

  const onOTPVerifyClick = async () => {
    setIsOTPVerifying(true);
    try {
      await onOTPVerify();
      await onOTPSuccess();
    } catch (e) {
      const err = e as Error;
      onOTPError(
        err.message ?? "Error while verifying otp. Please check again"
      );
    }
    setIsOTPVerifying(false);
  };

  const onOTPResetClick = async () => {
    setIsOTPSend(false);
    setIsSending(false);
    setIsOTPVerifying(false);
    setOTPTimeRemaining(0);
    clearTimeout(timer?.current);
  };
  return {
    isSending,
    isResending,
    isOTPSend,
    isOTPVerifying,
    otpTimeRemaining,
    onOTPSendClick,
    onOTPVerifyClick,
    onOTPResendClick,
    onOTPResetClick,
  };
};
