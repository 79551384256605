import React from "react";
import { toaster } from "baseui/toast";
import * as routes from "./routes";
import Button from "../components/Button";
import { KIND as BUTTON_KIND } from "baseui/button";

let showcaseMap = {
  GITHUB: { id: "GITHUB", name: "github" },
  BEHANCE: { id: "BEHANCE", name: "behance" },
  DRIBBLE: { id: "DRIBBLE", name: "dribble" },
  OTHER: { id: "OTHER", name: "other" },
};

export function getShowcase(id) {
  return showcaseMap[id] || { id: "GITHUB", name: "github" };
}

export function getShowcaseMap() {
  return Object.values(showcaseMap);
}

export function unique(value, index, self) {
  return self.indexOf(value) === index;
}

export function getTravelText(option) {
  switch (option) {
    case 0:
      return "No";
    case 10:
      return "10% of work";
    case 25:
      return "25% of work";
    case 50:
      return "50% of work";
    case 75:
      return "75% of work";
    default:
      return "";
  }
}

export function getStartupReadiness(startupTypes) {
  let readiness = [];
  if (startupTypes.indexOf("FUNDED_STARTUPS") !== -1) {
    readiness.push("Well Funded");
  }
  if (startupTypes.indexOf("EARLY_STAGE") !== -1) {
    readiness.push("Early stage");
  }
  return readiness.length
    ? "Interested in " + readiness.join(" & ") + " startups"
    : "Not interested";
}

export function handleApiError(ex) {
  let { response } = ex;
  if (response && response.data && response.data.isSilent) {
    return;
  }
  if (!(response && response.data && response.data.message)) {
    toaster.negative(<div>{"Some error occured. Please try again"}</div>, {
      autoHideDuration: 3000,
    });
    return;
  }
  if (!(response.data.actionUrl && response.data.actionText)) {
    toaster.negative(<div>{response.data.message}</div>, {
      autoHideDuration: 3000,
    });
  }
}

export function handleApiErrorWithAction(ex, history) {
  let { response } = ex;
  if (response && response.data && response.data.isSilent) {
    return;
  }

  if (!(response && response.data && response.data.message)) {
    return;
  }

  if (response.data.actionUrl && response.data.actionText && history) {
    toaster.negative(
      <div>
        <div>{response.data.message}</div>
        <Button
          buttonStyle={{
            marginTop: "32px",
            border: "1px solid #ffffff",
            color: "#ffffff",
            ":hover": {
              background: "#6d180f",
            },
          }}
          kind={BUTTON_KIND.minimal}
          onClick={() => {
            history.push(routes[response.data.actionUrl]);
          }}
        >
          {response.data.actionText}
        </Button>
      </div>,
      {
        autoHideDuration: 3000,
      }
    );
  }
}

export function convertToCandidateSearch(candidate) {
  return candidate;
}

function fallbackCopyTextToClipboard(text) {
  let textArea = document.createElement("textarea");
  textArea.value = text;

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  let isSuccessful = false;
  try {
    isSuccessful = document.execCommand("copy");
  } catch (err) {}
  document.body.removeChild(textArea);
  return isSuccessful ? true : false;
}

export async function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text);
  }
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (e) {
    return false;
  }
}
const HomeUrls = {
  CANDIDATE: routes.CANDIDATE_DASHBOARD,
  EMPLOYER: routes.EMPLOYER_POST_JOB,
  TALENTSCOUT: routes.TALENTSCOUT_MY_CANDIDATES,
  SELECT_ROLE: "/user/role",
};
const ProfileUrls = {
  CANDIDATE: routes.CANDIDATE_PROFILE_BASIC,
  EMPLOYER: routes.EMPLOYER_PROFILE_COMPANY,
  TALENTSCOUT: routes.TALENTSCOUT_PROFILE_DETAILS,
  SELECT_ROLE: "/user/role",
};

export function getHomeRedirect(user) {
  return HomeUrls[user.role || "SELECT_ROLE"];
}

export function getProfileRedirect(user) {
  return ProfileUrls[user.role || "SELECT_ROLE"];
}

export function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export function getLastExperienceTitle(experience) {
  if (!experience.length) return "";
  const sorted = getSortedExperience(experience);
  return sorted[0]?.title?.name;
}

export function getSortedExperience(experience) {
  if (!experience || !experience.length) return [];
  const experiences = experience.map((ex) => ex);
  experiences.sort((ex1, ex2) => {
    if (ex1.endYear <= 0 && ex2.endYear <= 0) {
      if (ex1.startYear < ex2.startYear) return 1;
      if (ex1.startYear > ex2.startYear) return -1;
      return 0;
    }
    if (ex1.endYear <= 0) {
      return -1;
    }
    if (ex2.endYear <= 0) {
      return 1;
    }
    if (ex1.endYear < ex2.endYear) {
      return 1;
    }
    if (ex1.endYear > ex2.endYear) {
      return -1;
    }
    return 0;
  });
  return experiences;
}
