import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setUser } from "../redux/actions/user";
import Input from "../components/Input";
import PhoneInput from "../components/PhoneInput";
import Button from "../components/Button";
import NotServiceableModal from "./NotServiceableModal";
import { FormControl } from "baseui/form-control";
import Label from "../components/Label";
import { getTalentscout, updateTalentscoutProfile } from "../api/talentscout";
import Title from "../components/Title";
import LoadingScreen from "../components/LoadingScreen";
import { TALENTSCOUT_TAX_DETAILS } from "../util/routes";
import EmptyCaption from "../components/EmptyCaption";
import * as Analytics from "../analytics";
import Spacing from "../components/Spacing";
import { CenterContainer } from "../components/CenterContainer";

class ScoutDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isSaving: false,
      isNotServiceableModalVisible: false,
      values: {
        industries: [],
        functionalAreas: [],
        phone: "",
        phonePrefix: {
          label: "India (भारत)",
          id: "IN",
          dialCode: "+91",
        },
        linkedInProfile: "",
      },
      errors: {},
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[S] page view profile details");
    this.setState({
      isLoading: true,
    });
    try {
      let talentscout = (await getTalentscout(this.props.user.id)) || {};
      this.setState({
        values: {
          name: this.props.user.name || "",
          industries: talentscout.industries || [],
          functionalAreas: talentscout.functionalAreas || [],
          linkedInProfile: this.props.user.linkedInProfile || "",
          phone: this.props.user.phone || "",
          phonePrefix:
            this.props.user.phonePrefix && this.props.user.phonePrefix.id
              ? this.props.user.phonePrefix
              : this.state.values.phonePrefix,
        },
        isLoading: false,
      });
    } catch (ex) {
      console.log(ex);
      this.setState({
        isLoading: false,
      });
    }
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && !this.state.values[id]) {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let errors = {};
    [
      "name",
      // "industries",
      // "functionalAreas",
      "linkedInProfile",
      "phone",
    ].forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });
    if (isErrorFound) {
      this.setState({
        errors,
      });
    } else {
      await this.onContinue(this.state.values);
    }
  };

  getValidations(id) {
    switch (id) {
      // case "industries":
      // case "functionalAreas":
      //   return { requiredArray: true };
      case "name":
      case "linkedInProfile":
      case "phone":
        return { required: true };
      default:
        return "";
    }
  }

  async onContinue(values) {
    this.setState({
      isSaving: true,
    });
    try {
      await updateTalentscoutProfile({
        ...values,
      });
      let talentscoutOnboarding = this.props.user.talentscoutOnboarding || [];
      if (talentscoutOnboarding.indexOf("details") === -1) {
        talentscoutOnboarding.push("details");
      }
      this.props.setUser({
        name: values.name,
        phone: values.phone,
        phonePrefix: values.phonePrefix,
        talentscoutOnboarding,
      });
      this.setState({
        isSaving: false,
      });
      if (talentscoutOnboarding.indexOf("taxDetails") === -1) {
        this.setState({
          isNotServiceableModalVisible: true,
        });
      } else {
        this.props.history.push(TALENTSCOUT_TAX_DETAILS);
      }
    } catch (e) {
      this.setState({
        isSaving: false,
      });
      console.log(e);
      alert("Error while saving. Please retry");
    }
  }

  render() {
    return (
      <CenterContainer>
        <Title>Your Details</Title>
        {this.state.isLoading ? (
          <LoadingScreen />
        ) : (
          <div>
            <div>
              <form onSubmit={this.handleSubmit} autoComplete="off">
                <FormControl
                  label={
                    <Label subtitle={"first name & last name"}>
                      {"Enter your full name"}
                    </Label>
                  }
                  caption={<EmptyCaption />}
                  error={this.state.errors["name"]}
                >
                  <Input
                    id={"name"}
                    onChange={(e) =>
                      this.updateFormValues("name", e.target.value)
                    }
                    value={this.state.values["name"]}
                    autoComplete={"off"}
                    onBlur={(e) =>
                      this.updateFormErrors(
                        "name",
                        this.validate("name", this.getValidations("name"))
                      )
                    }
                  />
                </FormControl>
                <FormControl
                  label={
                    <Label subtitle={"eg. +91 9xxxx xxxxx"}>
                      {"Enter your phone number"}
                    </Label>
                  }
                  caption={<EmptyCaption />}
                  error={
                    this.state.errors["phone"] ||
                    this.state.errors["phonePrefix"]
                  }
                >
                  <PhoneInput
                    country={this.state.values["phonePrefix"]}
                    onCountryChange={({ option }) => {
                      this.updateFormValues("phonePrefix", option);
                    }}
                    text={this.state.values["phone"]}
                    autoComplete={"off"}
                    onTextChange={(e) => {
                      this.updateFormValues("phone", e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl
                  label={
                    <Label
                      subtitle={
                        <div>
                          <div>This helps us to verify your details</div>
                          <div>
                            Note: FindingJo will never post anything on LinkedIn
                            without your permission
                          </div>
                        </div>
                      }
                    >
                      {"Enter LinkedIn profile"}
                    </Label>
                  }
                  caption={<EmptyCaption />}
                  error={this.state.errors["linkedInProfile"]}
                >
                  <Input
                    id={"linkedInProfile"}
                    onChange={(e) =>
                      this.updateFormValues("linkedInProfile", e.target.value)
                    }
                    value={this.state.values["linkedInProfile"]}
                    autoComplete={"off"}
                    onBlur={(e) =>
                      this.updateFormErrors(
                        "linkedInProfile",
                        this.validate(
                          "linkedInProfile",
                          this.getValidations("linkedInProfile")
                        )
                      )
                    }
                  />
                </FormControl>
                {/* <FormControl
                  label={
                    <Label
                      subtitle={"You know premium candidates in this industry"}
                    >
                      {"In which all industries, do you specialise in ?"}
                    </Label>
                  }
                  caption={<EmptyCaption />}
                  error={this.state.errors["industries"]}
                >
                  <CheckboxGroup
                    columns
                    id={"industries"}
                    options={[
                      {
                        id: "AI/ML",
                        name: "Artificial Intelligence & Machine Learning",
                      },
                      { id: "BIOTECHNOLOGY", name: "Biotechnology" },
                      { id: "BLOCKCHAIN", name: "Blockchain" },
                      {
                        id: "CLOUDENABLEDSERVICES",
                        name: "Cloud-enabled Services",
                      },
                      { id: "CONSUMERINTERNET", name: "Consumer Internet" },
                      { id: "CONTENTANDMEDIA", name: "Content & Media" },
                      { id: "DATAINFRASTRUCTURE", name: "Data Infrastructure" },
                      { id: "DIGITALHEALTH", name: "Digital health" },
                      { id: "ECOMMERCE", name: "E-Commerce" },
                      { id: "EDTECH", name: "Edtech" },
                      {
                        id: "ELECTRICVEHICLES",
                        name: "Electric Vehicles (EVs)",
                      },
                      { id: "ENTERPRISESOFTWARE", name: "Enterprise Software" },
                      { id: "FASHIONTECH", name: "Fashion Tech" },
                      { id: "FINANCETECH", name: "Finance Tech" },
                      { id: "FOODTECH", name: "Food Tech" },
                      { id: "GAMESMOBILEWEB", name: "Games Mobile/Web" },
                      {
                        id: "HYPERLOCALONDEMAND",
                        name: "Hyperlocal On-Demand Delivery",
                      },
                      { id: "IOT", name: "IoT Internet Of Things" },
                      { id: "LIFESCIENCES", name: "Life Sciences" },
                      { id: "MOBILEAPPS", name: "Mobile Apps" },
                      { id: "LOGISTICS", name: "Logistics" },
                      { id: "SOCIALMEDIA", name: "Social Media " },
                      { id: "SOFTWARESAAS", name: "Software & SaaS" },
                      { id: "TELECOMMUNICATIONS", name: "Telecommunications" },
                      { id: "TRANSPORTATION", name: "Transportation" },
                    ]}
                    onChange={(values) =>
                      this.updateFormValues("industries", values)
                    }
                    value={this.state.values["industries"]}
                    autoComplete={"off"}
                  />
                </FormControl>
                <FormControl
                  label={
                    <Label
                      subtitle={
                        "You know premium candidates in this functional area"
                      }
                    >
                      {"In which all functional areas do you specialise in ?"}
                    </Label>
                  }
                  error={this.state.errors["functionalAreas"]}
                >
                  <CheckboxGroup
                    columns
                    id={"functionalAreas"}
                    options={[
                      {
                        id: "ACCOUNTING",
                        name: "Accounting",
                      },
                      {
                        id: "ADVERTISEMENT",
                        name: "Advertisement",
                      },
                      {
                        id: "ANALYTICS",
                        name: "Analytics / Data Science",
                      },
                      {
                        id: "COMMUNICATIONS",
                        name: "Communications",
                      },
                      {
                        id: "FINANCE",
                        name: "Finance",
                      },
                      {
                        id: "HRFUNCTIONS",
                        name: "HR Functions",
                      },
                      {
                        id: "MANAGETEAMS",
                        name: "Manage Teams",
                      },
                      {
                        id: "MARKETING",
                        name: "Marketing",
                      },
                      {
                        id: "OPERATIONS",
                        name: "Operations",
                      },
                      {
                        id: "PRODUCTMANAGEMENT",
                        name: "Product Management",
                      },
                      {
                        id: "PROGRAMMANAGEMENT",
                        name: "Program Management",
                      },
                      {
                        id: "PROJECTMANAGEMENT",
                        name: "Project Management",
                      },
                      {
                        id: "PUBLICRELATIONS",
                        name: "Public Relations",
                      },
                      {
                        id: "R&D",
                        name: "R&D (Research & Development)",
                      },
                      {
                        id: "SALES",
                        name: "Sales",
                      },
                      {
                        id: "SOFTWAREPROGRAMMING",
                        name: "Software Programming",
                      },
                      {
                        id: "QA",
                        name: "Software Testing / QA",
                      },
                      {
                        id: "STRATEGY",
                        name: "Strategy",
                      },
                      {
                        id: "SUPPORTCENTRE",
                        name: "Support / Call Centre",
                      },
                      {
                        id: "TRAINING",
                        name: "Training",
                      },
                      {
                        id: "TECHNOLOGYARCHITECT",
                        name: "Technology Architect",
                      },
                      {
                        id: "UIUX",
                        name: "UI/UX Design",
                      },
                    ]}
                    onChange={(values) =>
                      this.updateFormValues("functionalAreas", values)
                    }
                    value={this.state.values["functionalAreas"]}
                    autoComplete={"off"}
                  />
                </FormControl> */}
                <Button isLoading={this.state.isSaving} type={"submit"}>
                  Save and Continue
                </Button>
              </form>
            </div>
          </div>
        )}
        <Spacing />
        <NotServiceableModal
          isVisible={this.state.isNotServiceableModalVisible}
          onClose={() => {
            this.setState({
              isNotServiceableModalVisible: false,
            });
          }}
          onContinue={() => {
            this.props.history.push(TALENTSCOUT_TAX_DETAILS);
          }}
        />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(ScoutDetails));
