import React from "react";
import {
  Panel as BasePanel,
  Accordion as BaseAccordion,
} from "baseui/accordion";

export const Accordion = ({ children, ...rest }) => (
  <BaseAccordion
    overrides={{
      Header: {
        style: { borderWidth: 0, height: "32px" },
      },
    }}
    {...rest}
  >
    {children}
  </BaseAccordion>
);

export const Panel = ({ children, ...rest }) => (
  <BasePanel {...rest}>{children}</BasePanel>
);
