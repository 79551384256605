import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import {
  getMyCandidates,
  getUploadedCandidatesStats,
} from "../api/talentscout";
import TSMyCandidate from "../components/TSMyCandidate";
import TSMyCandidateUpload from "../components/TSMyCandidateUpload";
import Title from "../components/Title";
import LoadingScreen from "../components/LoadingScreen";
import {
  TALENTSCOUT_UPLOAD_CANDIDATE,
  TALENTSCOUT_SEARCH_CANDIDATES,
} from "../util/routes";
import AddSection from "../components/AddSection";
import * as Analytics from "../analytics";
import TSLinkedInShare from "../components/TSLinkedInShare";
import Spacing from "../components/Spacing";
import TSTarget from "../components/TSTarget";
import Pagination from "../components/Pagination";
import Bold from "../components/Bold";
import Select from "../components/Select";
import { styled } from "baseui";
import Button from "../components/Button";
import { KIND, SIZE } from "baseui/button";
import { Block } from "baseui/block";
import { CenterContainer } from "../components/CenterContainer";
import Card from "../components/Card";
import {
  LabelLarge,
  LabelSmall,
  DisplayLarge,
  DisplayXSmall,
} from "baseui/typography";

const Seperator = styled("div", {
  height: "1px",
  background: "#E1E1E1",
});

class ScoutMyCandidates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      slotCount: 0,
      lockedCount: 0,
      totalCount: 0,
      linkedInShareStatus: "",
      myCandidates: [],
      tabs: ["Active", "Rejected"],
      selectedFilter: { id: "", name: "All" },
      selectedTabIndex: 0,
      page: 0,
      hasNext: false,
      hasPrev: false,
    };
  }

  async onLoad() {
    this.setState({
      isFetching: true,
    });
    try {
      let {
        list: myCandidates,
        slotCount,
        hasNext,
        lockedCount,
        totalCount,
        linkedInShareStatus,
        linkedInShareImage,
        linkedInShareUrl,
      } = await getMyCandidates(
        this.props.user.id,
        this.state.selectedFilter.id,
        this.state.page,
        10
      );
      this.setState({
        myCandidates,
        slotCount,
        lockedCount,
        totalCount,
        hasNext,
        linkedInShareStatus,
        isFetching: false,
        linkedInShareImage,
        linkedInShareUrl,
      });
    } catch (e) {
      this.setState({
        isFetching: false,
      });
      console.log(e);
    }

    try {
      const {
        currentMonthUploaded,
        lastMonthUploaded,
        currentMonthApproved,
        lastMonthApproved,
      } = await getUploadedCandidatesStats();
      this.setState({
        currentMonthUploaded,
        lastMonthUploaded,
        currentMonthApproved,
        lastMonthApproved,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async componentDidMount() {
    Analytics.logEvent("[S] page view my candidate");
    await this.onLoad();
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  render() {
    if (this.state.isFetching) {
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    }
    return (
      <CenterContainer>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title>
            <div>My Candidates</div>
            <div style={{ fontSize: "12px", fontWeight: "400" }}>
              {this.state.slotCount -
                this.state.totalCount +
                " slots remaining"}
            </div>
          </Title>
          <div>
            <Select
              width={"200px"}
              clearable={false}
              defaultOptions={[
                { id: "", name: "All" },
                { id: "PROFILE_CREATED", name: "Profile Created" },
                { id: "PROFILE_SUBMITTED", name: "Profile Submitted" },
                { id: "PROFILE_APPROVED", name: "Profile Approved" },
                { id: "PROFILE_REJECTED", name: "Profile Rejected" },
              ]}
              onChange={(value) => {
                this.setState(
                  {
                    selectedFilter: value,
                    page: 0,
                  },
                  async () => {
                    await this.onLoad();
                  }
                );
              }}
              value={this.state.selectedFilter}
            />
          </div>
        </div>
        <div>
          <Card marginBottom="scale400">
            <Block
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Block>
                <LabelLarge>Uploaded</LabelLarge>
                <LabelSmall>this month</LabelSmall>
                <DisplayXSmall>{this.state.currentMonthUploaded}</DisplayXSmall>
              </Block>
              <Block>
                <LabelLarge>Approved</LabelLarge>
                <LabelSmall>this month</LabelSmall>
                <DisplayXSmall>{this.state.currentMonthApproved}</DisplayXSmall>
              </Block>
              <Block>
                <LabelLarge>Uploaded</LabelLarge>
                <LabelSmall>last month</LabelSmall>
                <DisplayXSmall>{this.state.lastMonthUploaded}</DisplayXSmall>
              </Block>
              <Block>
                <LabelLarge>Approved</LabelLarge>
                <LabelSmall>last month</LabelSmall>
                <DisplayXSmall>{this.state.lastMonthApproved}</DisplayXSmall>
              </Block>
            </Block>
          </Card>
        </div>
        {this.state.myCandidates.map((myCandidate, index) =>
          myCandidate.candidate ? (
            <TSMyCandidate
              isClickable
              key={index}
              isExpandedStatus={false}
              candidate={myCandidate.candidate}
              myCandidate={myCandidate}
              onRemove={async () => {
                Analytics.logEvent("[S] click remove candidate");
                await this.onLoad();
              }}
            />
          ) : (
            <TSMyCandidateUpload
              key={index}
              myCandidate={myCandidate}
              onRemove={async () => {
                Analytics.logEvent("[S] click remove candidate");
                await this.onLoad();
              }}
            />
          )
        )}

        {/* {Array(this.state.slotCount - this.state.myCandidates.length)
          .fill(4)
          .map((i, idx) => (
            
          ))} */}
        <Spacing size={16} />
        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
        <Spacing size={32} />
        <Seperator />
        <Spacing size={16} />
        <Bold>How to get more slots ?</Bold>
        <Spacing size={8} />
        {this.state.linkedInShareStatus !== "CONFIRMED" && (
          <>
            <TSLinkedInShare
              onLinkedInShare={async () => await this.onLoad()}
              linkedInShareStatus={this.state.linkedInShareStatus}
              linkedInShareImage={this.state.linkedInShareImage}
              linkedInShareUrl={this.state.linkedInShareUrl}
            />
            <Spacing size={16} />
          </>
        )}
        <TSTarget />
        {/* {Array(this.state.lockedCount)
          .fill(4)
          .map((i, idx) => (
            <LockedSection
              key={idx}
              text={"Locked slot"}
              more={
                "When your candidates get more interviews, you will be able to list more candidates and earn more"
              }
            />
          ))} */}
        {/* <Button
          onClick={() => this.props.history.push(TALENTSCOUT_UPLOAD_CANDIDATE)}
          kind={KIND.secondary}
          buttonStyle={{
            width: "100%",
            paddingLeft: "28px",
            paddingRight: "28px",
            paddingTop: "28px",
            paddingBottom: "28px",
            marginTop: "16px",
            border: "1px solid #7EC4FB",
          }}
        >
          Upload New Candidate
        </Button> */}
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(ScoutMyCandidates));
