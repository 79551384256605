import React, { useState } from "react";
import Button from "./Button";
import Input from "./Input";
import Select from "./Select";

const FindUser = (props) => {
  const [value, setValue] = useState("");
  const [by, setBy] = useState({ name: "by email", id: "email" });
  return (
    <div
      style={{
        display: "flex",
        paddingBottom: "32px",
        alignItems: "center",
      }}
    >
      <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          props.setValue && props.setValue(e.target.value);
        }}
        placeholder="Search"
      />
      <div style={{ width: "32px" }}></div>
      <Select
        clearable={false}
        defaultOptions={[
          { name: "by email", id: "email" },
          { name: "by phone", id: "phone" },
        ]}
        value={by}
        onChange={(params) => {
          setBy(params);
          props.setBy && props.setBy(params);
        }}
      />
      <div style={{ width: "32px" }}></div>
      <Button onClick={() => props.onSearch(by, value)}>Search</Button>
      <div style={{ width: "8px" }}></div>
      <Button
        onClick={() => {
          setValue("");
          setBy({ name: "by email", id: "email" });
          props.onClear();
        }}
      >
        Clear
      </Button>
    </div>
  );
};

export default FindUser;
