import React from "react";
import Button from "../components/Button";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Spacing from "../components/Spacing";
import Label from "../components/Label";
import SalaryInput from "../components/SalaryInput";
import { FormControl } from "baseui/form-control";
import {
  getCandidateProjection,
  updateCandidateProfile,
} from "../api/candidate";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { CANDIDATE_PROFILE_PREMIUM } from "../util/routes";
import { getCurrency } from "../util/currency";
import LoadingScreen from "../components/LoadingScreen";
import Title from "../components/Title";
import CheckboxGroup from "../components/CheckboxGroup";
import EmptyCaption from "../components/EmptyCaption";
import * as Analytics from "../analytics";
import { CenterContainer } from "../components/CenterContainer";

class CandidateProfileExpectations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isLoading: false,
      values: {
        startupTypes: [],
        isRemoteReady: 0,
        isTravelReady: 0,
        isSixDaysWilling: 0,
        expectedCTC: 0,
        expectedCTCCurrency: getCurrency("INR"),
      },
      errors: {},
    };
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (id === "startupTypes") {
      if (!this.state.values["startupTypes"].length) {
        return "Please select at least one option";
      }
      if (
        this.state.values["startupTypes"].indexOf("YES_INTERESTED") !== -1 &&
        this.state.values["startupTypes"].length === 1
      ) {
        return "Please select at least one option";
      }
    }
    if (validations.required && !this.state.values[id]) {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  getValidations(id) {
    switch (id) {
      case "totalCTC":
      case "expectedCTC":
        return { required: true };
      case "startupTypes":
        return { requiredArray: true };
      default:
        return {};
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    let isErrorFound = false;
    let errors = {};
    let fieldsToValidate = ["expectedCTC"];
    if (this.state.values["startupTypes"].length) {
      fieldsToValidate.push("startupTypes");
    }
    if (this.state.values["expectedCTC"] < 10000) {
      errors["expectedCTC"] =
        "Desired salary per year cannot be less than 10,000.";
      window.scrollTo(0, 0);
      this.setState({ errors });
      return;
    }
    fieldsToValidate.forEach((id) => {
      let validationError = this.validate(id, this.getValidations(id));
      if (validationError) {
        errors[id] = validationError;
        isErrorFound = true;
      }
    });

    if (isErrorFound) {
      this.setState({
        errors,
      });
    } else {
      console.log(this.state.values);
      await this.onContinue(this.state.values);
    }
  };

  async componentDidMount() {
    Analytics.logEvent("[C] page view profile expectations");
    this.setState({
      isFetching: true,
    });
    try {
      let expectation =
        (await getCandidateProjection(this.props.user.id, "expectation")) || {};
      this.setState({
        values: {
          startupTypes: expectation.startupTypes || [],
          isRemoteReady: expectation.isRemoteReady,
          isTravelReady: expectation.isTravelReady,
          isSixDaysWilling: expectation.isSixDaysWilling,
          expectedCTC: expectation.expectedCTC,
          expectedCTCCurrency: getCurrency(
            expectation.expectedCTCCurrency || "INR"
          ),
        },
        isFetching: false,
      });
    } catch (ex) {
      console.log(ex);
      this.setState({
        isFetching: false,
      });
    }
  }

  onContinue = async (values) => {
    this.setState({
      isLoading: true,
    });
    try {
      let expectation = this.state.values;
      await updateCandidateProfile(this.props.user.id, "salary", {
        startupTypes: expectation.startupTypes || [],
        isRemoteReady: expectation.isRemoteReady,
        isTravelReady: expectation.isTravelReady,
        isSixDaysWilling: expectation.isSixDaysWilling,
        expectedCTC: expectation.expectedCTC,
        expectedCTCCurrency: expectation.expectedCTCCurrency.id || "INR",
      });
      this.setState({
        isLoading: false,
      });
      let candidateOnboarding = this.props.user.candidateOnboarding || [];
      if (candidateOnboarding.indexOf("salary") === -1) {
        candidateOnboarding.push("salary");
      }
      this.props.setUser({
        candidateOnboarding,
      });
      this.props.history.push(CANDIDATE_PROFILE_PREMIUM);
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.log(e);
    }
  };

  render() {
    if (this.state.isFetching)
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    return (
      <CenterContainer>
        <Title>{"Your Expectation"}</Title>
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <FormControl
            label={
              <Label
                subtitle={
                  "This is the minimum salary, below which you will not accept the offer."
                }
              >
                {"What is your desired salary ?"}
              </Label>
            }
            caption={<EmptyCaption />}
            error={this.state.errors["expectedCTC"]}
          >
            <SalaryInput
              name={"expectedCTC"}
              onChange={(val) => this.updateFormValues("expectedCTC", val)}
              onCurrencyChange={(val) =>
                this.updateFormValues("expectedCTCCurrency", val)
              }
              value={this.state.values["expectedCTC"]}
              currencyValue={this.state.values["expectedCTCCurrency"]}
            ></SalaryInput>
          </FormControl>
          <FormControl
            label={<Label>{"Are you willing to work 6 days a week ?"}</Label>}
            caption={<EmptyCaption />}
            error={this.state.errors["isSixDaysWilling"]}
          >
            <RadioGroup
              value={this.state.values["isSixDaysWilling"]}
              onChange={(e) =>
                this.updateFormValues("isSixDaysWilling", +e.target.value)
              }
              align={ALIGN.vertical}
            >
              <Radio value={0}>No</Radio>
              <Radio value={1}>Yes</Radio>
            </RadioGroup>
          </FormControl>
          <FormControl
            label={<Label>{"Willing to travel ?"}</Label>}
            caption={<EmptyCaption />}
            error={this.state.errors["isTravelReady"]}
          >
            <RadioGroup
              value={this.state.values["isTravelReady"]}
              onChange={(e) => {
                this.updateFormValues("isTravelReady", +e.target.value);
              }}
              align={ALIGN.vertical}
            >
              <Radio value={0}>No</Radio>
              <Radio value={10}>Yes - 10%</Radio>
              <Radio value={25}>Yes - 25%</Radio>
              <Radio value={50}>Yes - 50%</Radio>
              <Radio value={75}>Yes - 75%</Radio>
            </RadioGroup>
          </FormControl>
          <FormControl
            label={<Label>{"Are you willing to work remotely ?"}</Label>}
            caption={<EmptyCaption />}
            error={this.state.errors["isRemoteReady"]}
          >
            <RadioGroup
              value={this.state.values["isRemoteReady"]}
              onChange={(e) => {
                this.updateFormValues("isRemoteReady", +e.target.value);
              }}
              align={ALIGN.vertical}
            >
              <Radio value={0}>No</Radio>
              <Radio value={1}>Yes</Radio>
            </RadioGroup>
          </FormControl>
          <FormControl
            label={<Label>{"Are you willing to work for a startup ?"}</Label>}
          >
            <RadioGroup
              value={
                (this.state.values["startupTypes"] || []).length
                  ? "YES_INTERESTED"
                  : "NOT_INTERESTED"
              }
              onChange={(e) => {
                if (e.target.value === "NOT_INTERESTED") {
                  this.updateFormValues("startupTypes", []);
                  return;
                }
                this.updateFormValues("startupTypes", [e.target.value]);
              }}
              align={ALIGN.vertical}
            >
              <Radio value={"NOT_INTERESTED"}>
                {"No, I am not interested in startups"}
              </Radio>
              <Radio value={"YES_INTERESTED"}>
                {"Yes, I am interested in startups"}
              </Radio>
            </RadioGroup>
          </FormControl>
          {this.state.values["startupTypes"].indexOf("YES_INTERESTED") !==
          -1 ? (
            <FormControl
              label={
                <Label>
                  {"If yes, so what kind of startup are you interested in"}
                </Label>
              }
              caption={<EmptyCaption />}
              error={this.state.errors["startupTypes"]}
            >
              <CheckboxGroup
                options={[
                  {
                    id: "EARLY_STAGE",
                    name: "Non funded startups",
                    description:
                      "Early stage. Great idea, small team. An opportunity to be part of the founding team. Small salary + Large stock option",
                  },
                  {
                    id: "FUNDED_STARTUPS",
                    name: "Funded startups",
                    description:
                      "Growing team, Near market rate salary + Small Stock options",
                  },
                ]}
                onChange={(values) => {
                  this.updateFormValues("startupTypes", values);
                }}
                value={this.state.values["startupTypes"]}
                autoComplete={"off"}
              />
            </FormControl>
          ) : null}

          <Button isLoading={this.state.isLoading} type={"submit"}>
            Save and Continue
          </Button>
        </form>
        <Spacing size={4} />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateProfileExpectations));
