import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Input from "../components/Input";
import EmptyCaption from "../components/EmptyCaption";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import Button from "../components/Button";
import Card from "../components/Card";
import { FormControl } from "baseui/form-control";
import Label from "../components/Label";
import { TALENTSCOUT_PROFILE_VERIFY } from "../util/routes";
import Title from "../components/Title";
import TextArea from "../components/TextArea";
import { updateTalentscoutTaxData, getTalentscout } from "../api/talentscout";
import * as Analytics from "../analytics";
import FileUploader from "../components/FileUploader";
import Spacing from "../components/Spacing";
import { CenterContainer } from "../components/CenterContainer";

class TalentscoutGST extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      values: {
        billingAddress: "",
        billingName: "",
        hasGST: false,
        gstNumber: "",
        insideState: false,
        idProofImageUrl: "",
        idProofId: "",
      },
      errors: {},
    };
    this.fileUploaderRef = React.createRef();
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value, callback) {
    this.setState(
      {
        values: { ...this.state.values, ...{ [id]: value } },
      },
      callback
    );
  }

  validate(id, validations = {}) {
    if (validations.required && !this.state.values[id]) {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  handleSubmit = async (e) => {
    let isErrorFound = false;
    let errors = {};
    if (this.state.values.hasGST) {
      let validationError = this.validate(
        "gstNumber",
        this.getValidations("gstNumber")
      );
      if (validationError) {
        errors["gstNumber"] = validationError;
        isErrorFound = true;
      }
    }

    if (!isErrorFound) {
      ["billingName", "billingAddress"].forEach((id) => {
        let validationError = this.validate(id, this.getValidations(id));
        if (validationError) {
          errors[id] = validationError;
          isErrorFound = true;
        }
      });
    }

    if (
      !isErrorFound &&
      (!this.props.user.idProof || !this.props.user.idProof.isVerified)
    ) {
      ["idProofId"].forEach((id) => {
        let validationError = this.validate(id, this.getValidations(id));
        if (validationError) {
          errors[id] = validationError;
          isErrorFound = true;
        }
      });
    }

    if (isErrorFound) {
      this.setState({
        errors,
      });
    } else {
      await this.onContinue(this.state.values);
    }
  };

  getValidations(id) {
    switch (id) {
      case "gstNumber":
      case "billingAddress":
      case "billingName":
      case "idProofId":
        return { required: true };
      default:
        return {};
    }
  }

  async componentDidMount() {
    Analytics.logEvent("[S] page view profile gst talentscout");
    this.setState({
      isFetching: false,
    });
    try {
      let { taxData } = (await getTalentscout(this.props.user.id)) || {};
      taxData = taxData || {};
      this.setState({
        values: {
          hasGST: taxData.hasGST || false,
          gstNumber: taxData.gstNumber || "",
          insideState: taxData.insideState || false,
          billingAddress: taxData.billingAddress || "",
          billingName: taxData.billingName || this.props.user.name || "",
          idProofId: this.props.user.idProof && this.props.user.idProof.id,
          idProofImageUrl:
            this.props.user.idProof && this.props.user.idProof.photoUrl,
        },
      });
    } catch (ex) {
      console.log(ex);
    }
    this.setState({
      isFetching: false,
    });
  }

  onContinue = async (values) => {
    try {
      this.setState({
        isLoading: true,
      });
      await updateTalentscoutTaxData({
        taxData: {
          billingAddress: values.billingAddress,
          billingName: values.billingName,
          hasGST: values.hasGST,
          gstNumber: values.gstNumber,
          insideState: values.insideState,
        },
        idProof: {
          name: this.props.user.config && this.props.user.config.idProofName,
          id: values.idProofId || this.props.user.idProof.id,
          photoUrl: values.idProofImageUrl || this.props.user.idProof.photoUrl,
          isAlternate: false,
        },
      });
      this.setState({
        isLoading: false,
      });
      let talentscoutOnboarding = this.props.user.talentscoutOnboarding || [];
      if (talentscoutOnboarding.indexOf("taxDetails") === -1) {
        talentscoutOnboarding.push("taxDetails");
      }
      this.props.setUser({
        talentscoutOnboarding,
        idProof: {
          name: this.props.user.config && this.props.user.config.idProofName,
          id:
            values.idProofId ||
            (this.props.user.idProof && this.props.user.idProof.id),
          photoUrl:
            values.idProofImageUrl ||
            (this.props.user.idProof && this.props.user.idProof.photoUrl),
          isAlternate: false,
          isVerified: false,
        },
      });
      this.props.history.push(TALENTSCOUT_PROFILE_VERIFY);
    } catch (e) {
      this.setState({
        isLoading: false,
      });
      console.log(e);
      alert("Error while saving. Please retry");
    }
  };

  render() {
    return (
      <CenterContainer>
        <Title>Tax Details</Title>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (
                this.props.user.idProof &&
                this.props.user.idProof.id &&
                this.props.user.idProof.photoUrl
              ) {
                this.handleSubmit();
              } else {
                this.fileUploaderRef.current.upload();
              }
            }}
            autoComplete="off"
          >
            <Card
              style={{
                marginBottom: 16,
              }}
            >
              <div
                style={{
                  fontSize: "0.8rem",
                  padding: "8px",
                  borderRadius: "4px",
                  marginBottom: "16px",
                  backgroundColor: "#ffefe9",
                  color: "#843a16",
                }}
              >
                <div>GST is not required for individuals.</div>
              </div>
              <FormControl
                label={<Label>{"Do you have GST number ?"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["hasGST"]}
                s
              >
                <RadioGroup
                  value={this.state.values.hasGST ? 1 : 0}
                  onChange={(e) => {
                    this.updateFormValues("hasGST", !!+e.target.value);
                  }}
                  align={ALIGN.vertical}
                >
                  <Radio value={0}>
                    <div>No</div>
                  </Radio>
                  <Radio value={1}>
                    <div>Yes</div>
                  </Radio>
                </RadioGroup>
              </FormControl>
              {this.state.values.hasGST ? (
                <FormControl
                  label={<Label>{"Enter GST number"}</Label>}
                  caption={<EmptyCaption />}
                  error={this.state.errors["gstNumber"]}
                >
                  <Input
                    id={"gstNumber"}
                    onChange={(e) =>
                      this.updateFormValues("gstNumber", e.target.value)
                    }
                    value={this.state.values["gstNumber"]}
                    autoComplete={"off"}
                  />
                </FormControl>
              ) : null}
            </Card>
            <Card
              style={{
                marginBottom: 16,
              }}
            >
              <div
                style={{
                  fontSize: "0.8rem",
                  padding: "8px",
                  borderRadius: "4px",
                  marginBottom: "16px",
                  backgroundColor: "#f5f5f5",
                  color: "#232323",
                }}
              >
                <div>Required for invoice generation</div>
              </div>
              <FormControl
                label={<Label appendText={""}>{"Enter billing name"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["billingName"]}
              >
                <Input
                  id={"billingName"}
                  onChange={(e) =>
                    this.updateFormValues("billingName", e.target.value)
                  }
                  value={this.state.values["billingName"]}
                  autoComplete={"no"}
                />
              </FormControl>
              <FormControl
                label={<Label appendText={""}>{"Enter billing address"}</Label>}
                caption={<EmptyCaption />}
                error={this.state.errors["billingAddress"]}
              >
                <TextArea
                  id={"billingAddress"}
                  resize
                  height={"100px"}
                  onChange={(e) => {
                    if (typeof e.target.value !== "undefined") {
                      this.updateFormValues(
                        "billingAddress",
                        e.target.value.substring(0, 400)
                      );
                    }
                  }}
                  value={this.state.values["billingAddress"]}
                  autoComplete={"off"}
                />
              </FormControl>

              <FormControl
                label={
                  <Label subtitle={"Your office is located:"}>
                    {"Your place of business ?"}
                  </Label>
                }
                caption={<EmptyCaption />}
                error={this.state.errors["insideState"]}
              >
                <RadioGroup
                  value={this.state.values.insideState ? 0 : 1}
                  onChange={(e) => {
                    this.updateFormValues("insideState", +e.target.value === 0);
                  }}
                  align={ALIGN.vertical}
                >
                  <Radio value={0}>
                    <div>Inside Karnataka, India</div>
                  </Radio>
                  <Radio value={1}>
                    <div>Outside Karnataka, India</div>
                  </Radio>
                </RadioGroup>
              </FormControl>
            </Card>
            <Card
              style={{
                marginBottom: 16,
              }}
            >
              <div
                style={{
                  fontSize: "0.8rem",
                  padding: "8px",
                  borderRadius: "4px",
                  marginBottom: "16px",
                  backgroundColor: "#f5f5f5",
                  color: "#232323",
                }}
              >
                <div>Required for ID Verification</div>
              </div>
              {!this.props.user.idProof ||
              !this.props.user.idProof.isVerified ? (
                <>
                  <FormControl
                    label={
                      <Label
                        subtitle={
                          "Identity verification is important to comply with laws and prevent fraud, so you know people are who they say they are. We remove your id proof details from our systems after the verification."
                        }
                      >
                        {"Enter your " +
                          (this.props.user.config &&
                            this.props.user.config.idProofName)}
                      </Label>
                    }
                    caption={<EmptyCaption />}
                    error={this.state.errors.idProofId}
                  >
                    <Input
                      onChange={(e) =>
                        this.updateFormValues("idProofId", e.target.value)
                      }
                      value={this.state.values.idProofId}
                      autoComplete={"off"}
                    />
                  </FormControl>
                  <FormControl
                    label={
                      <Label subtitle={"Max 2 mb size"}>
                        {"Picture of your " +
                          (this.props.user.config &&
                            this.props.user.config.idProofName)}
                      </Label>
                    }
                    caption={<div>Required</div>}
                    error={this.state.errors.idProofImageUrl}
                  >
                    <FileUploader
                      files={
                        this.props.user.idProof &&
                        this.props.user.idProof.photoUrl && [
                          {
                            source: this.props.user.idProof.photoUrl,
                            options: {
                              type: "local",
                              file: {
                                name: "uploaded-proof",
                                size: 74001,
                                type: "image/jpeg",
                              },
                            },
                          },
                        ]
                      }
                      ref={this.fileUploaderRef}
                      uploadUrl={
                        "id_proofs/" + this.props.user.id + "_" + Date.now()
                      }
                      onError={(error) => {
                        this.updateFormErrors("idProofImageUrl", error);
                      }}
                      onSuccess={async (downloadUrl) => {
                        this.updateFormValues(
                          "idProofImageUrl",
                          downloadUrl,
                          async () => {
                            await this.handleSubmit();
                          }
                        );
                      }}
                    />
                  </FormControl>
                </>
              ) : (
                <div>Your id proof is verified</div>
              )}
            </Card>
            <Button isLoading={this.state.isLoading} type={"submit"}>
              Save and Continue
            </Button>
          </form>
        </div>
        <Spacing />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(TalentscoutGST));
