import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Skill, College, Entity } from "src/lib/types/candidate";
import { FormLabel, FormErrorMessage } from "src/lib/uikit";
import {
  Box,
  Flex,
  Checkbox,
  FormControl,
  Text,
  Select,
  Textarea,
  Button,
} from "@chakra-ui/react";
import { AutoComplete, AutoCompleteItem } from "src/components/AutoComplete";
import { searchEntity } from "src/api/entity";
import { MONTHS, YEARS } from "src/util/time";
import { FiCheck, FiTrash, FiX } from "react-icons/fi";
import { SkillRow } from "./SkillList";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useToast } from "@chakra-ui/react";

const schema = yup
  .object({
    value: yup
      .object()
      .shape({
        id: yup.string().required("Skill is required"),
        name: yup.string(),
        isCreatable: yup.bool().optional(),
      })
      .required("Required"),
    expertise: yup.string(),
  })
  .required();

export type SkillFormProps = {
  skillRow: SkillRow;
  onSave: (e: Skill) => void;
  onDiscard: () => void;
  onDelete: () => void;
};

export const SkillForm = ({
  skillRow: { skill, isNew },
  onSave,
  onDiscard,
  onDelete,
}: SkillFormProps) => {
  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Skill>({
    defaultValues: skill,
    resolver: yupResolver(schema),
  });
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  useEffect(() => reset(skill), [skill]);
  const toast = useToast();
  const onFormSubmit = async (values: any) => {
    setIsSaving(true);
    await onSave(values as Skill);
    setIsSaving(false);
  };
  const onFormError = async () => {
    toast({
      title: "Validation error",
      description: "Please check the fields in the form",
      status: "error",
    });
  };
  return (
    <Box>
      <form onSubmit={handleSubmit(onFormSubmit, onFormError)}>
        <Flex mb={6} mt={4}>
          <Box mr={6} flex={1}>
            <FormLabel htmlFor={`skill`}>Skill Name</FormLabel>
            <Controller
              name="value"
              control={control}
              render={({ field: { value, ...rest } }) => {
                return (
                  <>
                    <AutoComplete
                      {...rest}
                      items={[]}
                      id={`value`}
                      selected={(value as unknown) as AutoCompleteItem[]}
                      onItemSelect={(value) => {
                        setValue(`value`, (value as unknown) as Entity);
                      }}
                      fetchItems={async (q) => searchEntity("skill", q)}
                    />
                    <FormErrorMessage>
                      {errors.value?.id?.message}
                    </FormErrorMessage>
                  </>
                );
              }}
            />
          </Box>
        </Flex>
        <Flex mt={4} justifyContent="space-between">
          <Flex>
            <Button
              type="submit"
              mr={2}
              leftIcon={<FiCheck />}
              isLoading={isSaving}
              loadingText="Saving"
            >
              Save Skill
            </Button>
            {isNew && (
              <Button
                type="button"
                onClick={onDiscard}
                variant={"ghost"}
                leftIcon={<FiX />}
              >
                Discard
              </Button>
            )}
          </Flex>
          {!isNew && (
            <Button
              type="button"
              onClick={async () => {
                setIsDeleting(true);
                await onDelete();
                setIsDeleting(false);
              }}
              variant={"ghost"}
              isLoading={isDeleting}
              loadingText="Deleting"
              leftIcon={<FiTrash />}
            >
              Delete
            </Button>
          )}
        </Flex>
      </form>
    </Box>
  );
};
