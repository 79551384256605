import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Title from "../../../components/Title";
import { logEvent } from "../../../analytics/index";
import Pagination from "../../../components/Pagination";
import {
  getHandPickedRemovedCandidates,
  removePickedCandidate,
} from "../../../api/picked_candidate";
import { CenterContainer } from "../../../components/CenterContainer";
import LoadingScreen from "../../../components/LoadingScreen";
import { Block } from "baseui/block";
import CandidateCard from "../../../components/employer/CandidateCard";
import { LabelSmall } from "baseui/typography";

const BinCandidates = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [handPickedMap, setHandPickedMap] = useState({});
  const [interviewRequests, setInterviewRequests] = useState({});
  const [page, setPage] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const { jobId } = props;
  const limit = 10;

  const onLoad = useCallback(
    async (isSilent) => {
      try {
        if (!isSilent) {
          setIsFetching(true);
        }
        let {
          data: candidates,
          handPickedCandidates,
          interviewRequests,
          hasNext,
        } = await getHandPickedRemovedCandidates(jobId, page, limit);
        setIsFetching(false);
        setHandPickedMap(handPickedCandidates);
        setInterviewRequests(interviewRequests);
        setCandidates(candidates);
        setHasNext(hasNext);
      } catch (e) {
        console.log(e);
      }
    },
    [jobId, page]
  );

  const onNextClick = () => {
    setPage(page + 1);
    setHasPrev(page + 1 > 0);
    onLoad();
  };

  const onPrevClick = () => {
    setPage(page - 1);
    setHasPrev(page - 1 > 0);
    onLoad();
  };

  const onNotInterestedClick = async (candidate) => {
    try {
      if (handPickedMap[candidate.userId]) {
        await removePickedCandidate(
          handPickedMap[candidate.userId].jobId,
          handPickedMap[candidate.userId].id
        );
      }
      await onLoad(true);
    } catch (e) {}
  };

  useEffect(() => {
    logEvent("[E] page view job handpicked");
    onLoad();
  }, [onLoad]);

  return (
    <CenterContainer>
      <Block marginBottom="scale600">
        <Title>Bin</Title>
        <LabelSmall color="primary400">
          Candidates removed from handpicked
        </LabelSmall>
      </Block>
      {candidates.length === 0 && (
        <CenterContainer padding="0px">
          <Block marginTop="70px">You have not rejected any candidates.</Block>
        </CenterContainer>
      )}

      {candidates.map((candidate, index) =>
        props.isAdmin ? (
          <CandidateCard
            key={index}
            candidate={candidate}
            // onNotInterested={onNotInterestedClick}
            to={"/public/candidate/" + candidate.userId}
          />
        ) : (
          <CandidateCard
            key={index}
            to={
              "/employer/candidate/" +
              candidate.userId +
              "/details?jobId=" +
              jobId
            }
            onShare={() => {}}
            candidate={candidate}
            onInterview={
              !!interviewRequests[candidate.userId] ? null : () => {}
            }
            interviewLink={"/employer/create-interview/" + candidate.userId}
            isClickable
            isOpenNewWindow
            showRCAmount
            isSelected={!!interviewRequests[candidate.userId]}
          />
        )
      )}
      <Pagination
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        hasNext={hasNext}
        hasPrev={hasPrev}
      />
      <LoadingScreen show={isFetching} />
    </CenterContainer>
  );
};

export default withRouter(BinCandidates);
