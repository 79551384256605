import React from "react";
import {
  HeadingSmall,
  HeadingMedium,
  LabelLarge,
  HeadingXSmall,
} from "baseui/typography";
import { useStyletron } from "baseui";

const Title = ({ children, ...rest }) => {
  const [css] = useStyletron();
  return (
    <>
      <HeadingSmall display={["none", "block", "block"]} {...rest}>
        {children}
      </HeadingSmall>
      <HeadingXSmall display={["block", "none", "none"]} {...rest}>
        {children}
      </HeadingXSmall>
    </>
  );
};
export default Title;
