import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import { UserProps } from "src/api/userT";
import { UpdateUserForm, UpdateUserFormProps } from "./UpdateUserForm";

export type UpdateUserModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onActionClick: (values: UserProps) => void;
  selectedUser?: UserProps;
};

export const UpdateUserModal = ({
  selectedUser,
  isOpen,
  onClose,
  onActionClick,
}: UpdateUserModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const setUserValue = () => {
    return selectedUser || { id: "", email: "", phone: "", roles: [] };
  };
  const [values, setValues] = useState<UpdateUserFormProps>(setUserValue);
  useEffect(() => {
    setValues(setUserValue());
  }, [selectedUser]);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update User</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <UpdateUserForm
            values={values}
            onChange={(values: UserProps) => {
              setValues(values);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button
            isLoading={isLoading}
            onClick={async () => {
              setIsLoading(true);
              await onActionClick(values);
              setIsLoading(false);
              await onClose();
            }}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
