import { lightThemePrimitives } from "baseui";
import { CHANGE_THEME } from "../actionTypes";

const initialState = {
  ...lightThemePrimitives,
  primaryFontFamily: "InterVariable",

  accent: "#E16359",
  accent50: "#FCEFEE",
  accent100: "#F6CECB",
  accent200: "#EFAEA9",
  accent300: "#E98D86",
  accent400: "#E16359",
  accent500: "#DC4C41",
  accent600: "#CF3126",
  accent700: "#AC2920",

  primary: "#283c46",
  primary50: "#909dab",
  primary100: "#768390",
  primary200: "#636e7b",
  primary300: "#545d68",
  primary400: "#283c46",
  primary500: "#373e47",
  primary600: "#2d333b",
  primary700: "#22272e",

  // accent: "#DE6F3F",
  // accent50: "#fcf2ee",
  // accent100: "#f6d7cb",
  // accent200: "#F0BDA8",
  // accent300: "#EAA385",
  // accent400: "#E48962",
  // accent500: "#DE6F3F",
  // accent600: "#D15823",
  // accent700: "#AE491E",
  // mono300: "#dedede",

  accentGreen: "#41B86C",
  accentRed: "#F04372",
  accentViolet: "#8958C6",
  accentBlue: "#1b6ac9",

  accentYellow: "#D49911",
  green: "#027469",
  lightGreen: "#cde2d8",
  black: "#323942",
  light: "#F8F9FB",
  background: "#F6F6F7",
  backgroundGrey: "#E9E9E9",
};

export default function theme(state = initialState, action) {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
