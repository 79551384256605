import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter, useParams } from "react-router-dom";
import Title from "../components/Title";
import Job from "../components/Job";
import { getCandidateInterviewRequest } from "../api/candidate";
import LoadingScreen from "../components/LoadingScreen";
import SubTitle from "../components/SubTitle";
import * as Analytics from "../analytics";
import InterviewRequestCandidate from "../components/InterviewRequestCandidate";
import { CenterContainer } from "../components/CenterContainer";

const CandidateInterviewDetails = (props) => {
  const [isFetching, setIsFetching] = useState(true);
  const [job, setJob] = useState({});
  const [interviewRequest, setInterviewRequest] = useState({});
  let { interviewRequestId } = useParams();
  const onLoad = async () => {
    setIsFetching(true);
    try {
      let { interviewRequest, job } = await getCandidateInterviewRequest(
        interviewRequestId
      );
      setJob(job);
      setInterviewRequest(interviewRequest);
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
    }
  };
  useEffect(() => {
    onLoad();
    Analytics.logEvent("[C] page view interview details");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isFetching) {
    return (
      <CenterContainer>
        <LoadingScreen />
      </CenterContainer>
    );
  }
  console.log("interview request", interviewRequest);
  return (
    <CenterContainer>
      <Title>Interview Request</Title>
      {interviewRequest.status === "APPROVED" ? (
        <div
          style={{
            background: "#FFEEE8",
            color: "#843a16",
            padding: "16px",
            borderRadius: "16px",
            marginTop: "16px",
            marginBottom: "16px",
          }}
        >
          You have approved this interview request. We have shared your details
          with the employer. Employer will contact you through phone/email for
          further proceedings.
        </div>
      ) : null}
      <InterviewRequestCandidate
        interviewRequest={{
          ...interviewRequest,
          ...{
            jobCompany: job.company,
            jobTitle: job.jobTitle,
            jobLocations: job.locations,
            jobSkills: job.skills,
            jobCTC: interviewRequest.offeredCTC,
            jobCTCCurrency: interviewRequest.offeredCTCCurrency,
            jobCoolDownPeriod: job.coolDownPeriod,
            jobCoolDownPeriodQuestion: job.coolDownPeriodQuestion,
          },
        }}
        onUpdated={async () => {
          await onLoad();
        }}
      />
      <SubTitle>Job Posting</SubTitle>
      <Job job={job} />
    </CenterContainer>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateInterviewDetails));
