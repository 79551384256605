import api from "./index";
import { baseUrl } from "./endpoints";
import {
  Education,
  Experience,
  Project,
  Showcase,
  Certification,
  Entity,
  Article,
} from "src/lib/types/candidate";
import { StringMappingType } from "typescript";

export type BasicUserProps = {
  id: string;
  name: string;
  email: string;
  phone: string;
};

export type ReferredCandidateProps = {
  id?: string;
  jobId: string;
  phone: string;
  countryCode: string;
  talentscoutId: string;
  candidateId: string;
  firstName: string;
  lastName: string;
  twitterUrl: string;
  facebookUrl: string;
  githubUrl: string;
  email: string;
  resumeUrl: string;
  careerSummary: string;
  linkedInUrl: string;
  linkedInResumeUrl: string;
  currentLocation: any;
  preferredLocations: any;
  jobFunctions: any;
  currentSalary: number;
  currentSalaryCurrency: string;
  expectedSalaryCurrency: string;
  expectedSalary: number;
  noticePeriod: number;
  lastWorkingDate: number;
  modifiedAt: number;
  createdAt: number;
  isResigned: boolean;
  isStartupReady: boolean;
  isRemoteReady: boolean;
  isVerified: boolean;
  reviewPriority: number;
  reviewPriorityReason: string;
  status?: string;
  statusMessage?: string;
  experience: Experience[];
  education: Education[];
  skills: Entity[];
  projects: Project[];
  certifications: Certification[];
  articles: Article[];
  workShowcases: Showcase[];
  jobOffer: {
    company?: {
      id: string;
      name: string;
      isCreatable?: boolean;
    };
    jobTitle?: {
      id: string;
      name: string;
      isCreatable?: boolean;
    };
    offeredSalary: number;
  };
};

export type ReferredCandidateUpdateProps = Partial<ReferredCandidateProps>;

export async function addReferCandidate(data: ReferredCandidateProps) {
  return api({
    baseUrl,
    url: "/api/referredCandidate",
    method: "POST",
    data,
  });
}

export async function updateReferCandidate(
  id: string,
  data: ReferredCandidateUpdateProps
) {
  return api({
    baseUrl,
    url: `/api/referredCandidate/${id}`,
    method: "POST",
    data,
  });
}

export async function handpickReferCandidateApi(id: string) {
  return api({
    baseUrl,
    url: `/api/referredCandidate/${id}/handpick`,
    method: "POST",
    data: {},
  });
}

type GetReferredCandidateResponse = {
  data: ReferredCandidateProps[];
  talentscouts: BasicUserProps[];
  handpickedCandidateIds: string[];
  hasNext: boolean;
  page: number;
  limit: number;
};

export async function getReferCandidates(
  {
    email,
    withLastWorkingDate,
    jobId,
    status,
    sortField,
    sortOrder,
    linkedInParseStatus,
    reviewTags,
  }: {
    email?: string;
    jobId?: string;
    status?: string;
    sortField?: string;
    sortOrder?: string;
    linkedInParseStatus?: string;
    withLastWorkingDate?: boolean;
    reviewTags?: string;
  },
  page: number,
  limit: number
): Promise<GetReferredCandidateResponse> {
  const res: GetReferredCandidateResponse = await api({
    baseUrl,
    url: "/api/referredCandidate",
    method: "GET",
    data: undefined,
    params: [
      { name: "jobId", value: jobId },
      { name: "status", value: status },
      { name: "sortField", value: sortField },
      { name: "sortOrder", value: sortOrder },
      { name: "page", value: page },
      { name: "limit", value: limit },
      { name: "linkedInParseStatus", value: linkedInParseStatus },
      { name: "email", value: email },
      { name: "withLastWorkingDate", value: withLastWorkingDate },
      { name: "reviewTags", value: reviewTags },
    ],
  });
  return res;
}

export async function getReferCandidatesMy(
  {
    status,
    sortField,
    sortOrder,
    isVerified,
  }: {
    status?: string;
    sortField?: string;
    sortOrder?: string;
    isVerified?: string;
  },
  page: number,
  limit: number
) {
  return api({
    baseUrl,
    url: "/api/referredCandidate/my",
    method: "GET",
    data: undefined,
    params: [
      { name: "status", value: status },
      { name: "sortField", value: sortField },
      { name: "sortOrder", value: sortOrder },
      { name: "isVerified", value: isVerified },
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getReferCandidatesAdmin(
  {
    status,
    talentscoutId,
    sortField,
    sortOrder,
  }: {
    status?: string;
    talentscoutId?: string;
    sortField?: string;
    sortOrder?: string;
  },
  page: number,
  limit: number
) {
  return api({
    baseUrl,
    url: "/api/admin/talentscout/referred",
    method: "GET",
    data: undefined,
    params: [
      { name: "status", value: status },
      { name: "talentscoutId", value: talentscoutId },
      { name: "sortField", value: sortField },
      { name: "sortOrder", value: sortOrder },
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

///adming/talentscout/referred

export async function getReferCandidate(id: string) {
  return api({
    baseUrl,
    url: "/api/referredCandidate/" + id,
    method: "GET",
    data: undefined,
  });
}

export async function getReferCandidatePublic(id: string) {
  return api({
    baseUrl,
    url: "/api/public/referredCandidate/" + id,
    method: "GET",
    data: undefined,
  });
}

export async function getInterviewRequestPublic(id: string) {
  return api({
    baseUrl,
    url: "/api/public/interviewRequest/" + id,
    method: "GET",
    data: undefined,
  });
}

export async function getNextReferCandidateForReview(status: string) {
  return api({
    baseUrl,
    url: "/api/referredCandidate/review/next",
    method: "GET",
    data: undefined,
    params: [{ name: "status", value: status }],
  });
}

export type GetNextReferCandidateForEntityAnonymiseResponse = {
  referredCandidate: ReferredCandidateProps;
  creatableFields: {
    company?: Entity;
    college?: Entity;
    course?: Entity;
    skill?: Entity;
  };
  retry: boolean;
};

export async function getNextReferCandidateForEntityAnonymise(
  status: string
): Promise<GetNextReferCandidateForEntityAnonymiseResponse> {
  const res: GetNextReferCandidateForEntityAnonymiseResponse = await api({
    baseUrl,
    url: "/api/referredCandidate/review/entityAnonymise",
    method: "GET",
    data: undefined,
    params: [{ name: "status", value: status }],
  });
  return res;
}

export async function resetReferCandidatePriorities() {
  return api({
    baseUrl,
    url: "/api/referredCandidate/priority/reset",
    method: "POST",
    data: {},
  });
}

export async function getReferCandidateParsed(id: string) {
  return api({
    baseUrl,
    url: `/api/referredCandidate/${id}/parsed`,
    method: "GET",
    data: undefined,
    timeout: 60000,
  });
}
