import React, { useEffect, useState } from "react";
import { Block } from "baseui/block";
import { CenterContainer } from "../../components/CenterContainer";
import Title from "../../components/Title";
import Button from "../../components/Button";
import UploadedCandidateExperience from "./UploadedCandidateExperience";
import UploadedCandidateEducation from "./UploadedCandidateEducation";
import UploadedCandidateProjects from "./UploadedCandidateProjects";
import UploadedCandidateSkills from "./UploadedCandidateSkills";
import UploadedCandidatePremium from "./UploadedCandidatePremium";
import {
  getUploadedCandidate,
  submitUploadedCandidate,
} from "../../api/reviewer/uploaded_candidates";
import { useHistory, useRouteMatch } from "react-router";
import Card from "../../components/Card";
import Entry from "../../components/Entry";
import { LabelMedium } from "baseui/typography";
import { toaster } from "baseui/toast";

export const UploadedCandidateDetails = (props) => {
  const routeParams = useRouteMatch();
  const history = useHistory();
  const { uploadedCandidateId } = routeParams.params;
  const [uploadedCandidateData, setUploadedCandidateData] = useState({});
  const {
    experience,
    education,
    candidateEmail,
    candidateResumeURL,
  } = uploadedCandidateData;
  const companies = (experience || []).map((ex) => ex.company);
  const colleges = (education || []).map((ex) => ex.college);
  const experiencePlaces = colleges.concat(companies);

  const fetchUploadedCandidate = async () => {
    try {
      const uploadedCandidate = await getUploadedCandidate(uploadedCandidateId);
      setUploadedCandidateData(uploadedCandidate);
    } catch (e) {}
  };

  useEffect(() => {
    fetchUploadedCandidate();
  }, []);

  const onSaveClick = async () => {
    try {
      await submitUploadedCandidate(uploadedCandidateData, "SAVE");
      toaster.show(<div>Saved successfully</div>, { autoHideDuration: 4000 });
    } catch (e) {
      toaster.negative(<div>Error while saving</div>, {
        autoHideDuration: 4000,
      });
    }
  };

  const onSubmitClick = async () => {
    try {
      await submitUploadedCandidate(uploadedCandidateData, "SUBMIT");
      toaster.show(<div>Submitted successfully</div>, {
        autoHideDuration: 4000,
      });
      history.push("/R0VHxB8M/reviewer/home/uploadedCandidates");
    } catch (e) {
      toaster.negative(<div>Error while submitting</div>, {
        autoHideDuration: 4000,
      });
    }
  };

  return (
    <CenterContainer>
      <Title>{candidateEmail || "Candidate Details"}</Title>
      <Block
        display="flex"
        alignItems="center"
        marginTop="scale800"
        marginBottom="scale400"
      >
        <Block marginRight="scale400">
          <Button kind="tertiary" onClick={onSaveClick}>
            Save
          </Button>
        </Block>
        <Button onClick={onSubmitClick}>Submit</Button>
      </Block>

      <Card marginTop="scale800" margin="scale600">
        <LabelMedium>Resume URL</LabelMedium>
        <a target="_blank" rel="noopener noreferrer" href={candidateResumeURL}>
          {candidateResumeURL}
        </a>
      </Card>

      <Block marginTop="scale800">
        <UploadedCandidateExperience
          experienceList={uploadedCandidateData.experience}
          updateExperience={({
            experience,
            totalExperience,
            lastExperienceTitle,
            lastExperienceCompany,
          }) => {
            setUploadedCandidateData({
              ...uploadedCandidateData,
              experience,
              totalExperience,
              lastExperienceTitle,
              lastExperienceCompany,
            });
          }}
        />
      </Block>
      <Block marginTop="scale800">
        <UploadedCandidateEducation
          educationList={uploadedCandidateData.education}
          updateEducation={({ education }) => {
            setUploadedCandidateData({
              ...uploadedCandidateData,
              education,
            });
          }}
        />
      </Block>
      <Block marginTop="scale800">
        <UploadedCandidateProjects
          projectList={uploadedCandidateData.projects}
          experienceCompanies={experiencePlaces}
          updateProjects={({ projects }) => {
            setUploadedCandidateData({
              ...uploadedCandidateData,
              projects,
            });
          }}
        />
      </Block>
      <Block marginTop="scale800">
        <UploadedCandidateSkills
          skills={uploadedCandidateData.skills}
          careerSummary={uploadedCandidateData.careerSummary}
          updateCareerSummary={(careerSummary) => {
            setUploadedCandidateData({
              ...uploadedCandidateData,
              careerSummary,
            });
          }}
          updateSkills={(skills) => {
            setUploadedCandidateData({
              ...uploadedCandidateData,
              skills,
            });
          }}
        />
      </Block>
      <Block marginTop="scale800">
        <UploadedCandidatePremium
          workShowcases={uploadedCandidateData.workShowcases}
          updateWorkShowcases={({ workShowcases }) => {
            setUploadedCandidateData({
              ...uploadedCandidateData,
              workShowcases,
            });
          }}
        />
      </Block>
    </CenterContainer>
  );
};
