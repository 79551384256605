import React, { useState } from "react";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Heading,
  useDisclosure,
  ButtonProps,
  PopoverArrow,
  ButtonGroup,
  Box,
  PopoverCloseButton,
  Textarea,
} from "@chakra-ui/react";

export type ButtonWithConfirmProps = ButtonProps & {
  header?: string;
  body?: string;
  onClick?: () => void;
  onReasonSubmit?: (reason: string) => void;
  confirmText?: string;
  askForReason?: boolean;
};

export const ButtonWithConfirm = ({
  header,
  body,
  confirmText,
  onClick,
  onReasonSubmit,
  colorScheme,
  children,
  askForReason,
  ...rest
}: ButtonWithConfirmProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [reason, setReason] = useState("");
  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button colorScheme={colorScheme} {...rest}>
          {children}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Box p={2}>
            <Box mb={4}>
              <Heading size="md">{header ?? "Confirmation!"}</Heading>
            </Box>
            <Box mb={4}>{body ?? "Are you sure about this?"}</Box>
            {askForReason ? (
              <Textarea
                mb={2}
                value={reason}
                placeholder="Enter reason"
                onChange={(e) => setReason(e.target.value)}
              ></Textarea>
            ) : null}
            <ButtonGroup d="flex" justifyContent="flex-end">
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                colorScheme={colorScheme}
                isLoading={isLoading}
                onClick={async () => {
                  try {
                    setIsLoading(true);
                    await onReasonSubmit?.(reason);
                    await onClick?.();
                    setIsLoading(false);
                    onClose();
                  } catch (e) {}
                }}
              >
                {confirmText || "Confirm"}
              </Button>
            </ButtonGroup>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
