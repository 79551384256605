import React from "react";
import { ThemeTypings } from "@chakra-ui/styled-system";
import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/tag";
import { Flex } from "@chakra-ui/layout";
import { TagRightIcon } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { SelectLabel } from "./SelectLabel";
import {
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { useDisclosure } from "@chakra-ui/hooks";

const alphabets = "abcdefghijklmnopqrstuvwxyz"
  .split("")
  .reduce((prev: { [key: string]: number }, curr: string, idx: number): {
    [key: string]: number;
  } => {
    prev[curr] = idx;
    return prev;
  }, {});

const getLabelColorScheme = (label: string): ThemeTypings["colorSchemes"] => {
  const wordSum = label
    .toLowerCase()
    .split("")
    .reduce((sum, letter) => {
      if (!alphabets[letter]) return sum;
      sum += alphabets[letter];
      return sum;
    }, 0);
  return "blackAlpha"
  switch (wordSum % 5) {
    case 0:
      return "red";
    case 1:
      return "yellow";
    case 2:
      return "green";
    case 3:
      return "teal";
    case 4:
      return "blue";
  }
  return "orange";
};


export type AddLabelPopOverProps = {
  labels: string[];
  onLabelSelect: (label: string) => void;
};

const AddLabelPopOver = ({ labels, onLabelSelect }: AddLabelPopOverProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  return (
    <Popover
      placement="bottom-end"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Tag
          mr={1}
          mb={1}
          borderRadius="full"
          onClick={() => onOpen?.()}
          cursor={"pointer"}
          _hover={{ opacity: 0.8 }}
        >
          <TagLabel fontWeight="normal">{"Add Label"}</TagLabel>
          <TagRightIcon as={AddIcon} height={3} width={3} />
        </Tag>
      </PopoverTrigger>
      <PopoverContent width={250}>
        <PopoverCloseButton />
        <PopoverBody>
          <SelectLabel
            onClose={onClose}
            labels={labels}
            onLabelSelect={(label: string) => {
              onLabelSelect(label);
            }}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};


export type CandidateLabelsProps = {
  isAddEnabled?: boolean;
  labels: string[];
  onRemove?: (label: string) => void;
  onLabelSelect: (label: string) => void;
};
export const CandidateLabels = ({ isAddEnabled, labels, onRemove, onLabelSelect }: CandidateLabelsProps) => {
  return (
    <Flex mb={2} flexWrap="wrap">
      {labels.map((l) => (
        <Tag
          mr={1}
          mb={1}
          colorScheme={getLabelColorScheme(l)}
          borderRadius="full"
        >
          <TagLabel fontWeight="normal">{l}</TagLabel>
          {onRemove && (
            <TagCloseButton
              onClick={() => {
                onRemove(l);
              }}
            />
          )}
        </Tag>
      ))}
      {isAddEnabled && <AddLabelPopOver labels={labels} onLabelSelect={onLabelSelect} />}
    </Flex>
  );
};
