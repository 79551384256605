import React from "react";
import { setUser } from "./redux/actions/user";
import { connect } from "react-redux";
import { styled } from "baseui";
import { sendCode } from "./api/otp";
import PageHeader from "./components/Header";
import Title from "./components/Title";
import Button from "./components/Button";
import { withRouter, Link } from "react-router-dom";
import { registerUser } from "./api/user";
import { PinCode } from "baseui/pin-code";
import { toaster } from "baseui/toast";
import * as Analytics from "./analytics";
import { KIND, SIZE } from "baseui/button";

const ResendText = styled("div", {
  fontSize: "12px",
  color: "#919191",
});

const Wrapper = styled("div", {
  width: "480px",
  margin: "auto",
  marginTop: "100px",
  backgroundColor: "white",
  borderRadius: "4px",
  padding: "32px",
  border: "1px solid #d8d8d8",
});

const ButtonWrapper = styled("div", {
  marginTop: "20px",
  display: "flex",
  justifyContent: "space-between",
});

const SuccessText = styled("div", {
  marginBottom: "32px",
});

const OTPText = styled("div", {
  marginBottom: "24px",
});

class EmailVerify extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isRegistering: false,
      registerError: false,
      isSending: false,
      timeRemaining: 60,
      registerSuccess: false,
      values: ["", "", "", "", "", ""],
    };
  }

  updateTimeRemaining() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      let timeRemaining = this.state.timeRemaining;
      if (timeRemaining > 0) {
        this.setState({
          timeRemaining: this.state.timeRemaining - 1,
        });
        this.updateTimeRemaining();
      }
    }, 1000);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  async componentDidMount() {
    Analytics.logEvent("page view email verification");
    let { email, password } = this.props.location.state || {};
    if (!email || !password) {
      // this.props.history.push("/register");
      return;
    }
    this.updateTimeRemaining();
  }

  async sendOTP(isResend) {
    if (isResend) {
      Analytics.logEvent("click resend otp email verification");
    }
    let { email } = this.props.location.state || {};
    this.setState({
      isLoading: true,
      isSending: true,
      sendCodeError: false,
    });
    try {
      await sendCode({
        sendType: "email",
        sendId: email,
      });
      if (isResend) {
        toaster.show(
          "We have resend the OTP to your email id. Please check now.",
          {
            autoHideDuration: 3000,
          }
        );
      }
    } catch (e) {
      this.setState({
        sendCodeError: true,
        isSending: false,
      });
    }
    this.setState({
      isLoading: false,
      isSending: false,
      code: "",
    });
  }

  async verifyOTP() {
    Analytics.logEvent("click verify otp email verification");
    let code = this.state.values.join("");
    let { email, password } = this.props.location.state || {};
    this.setState({
      isRegistering: true,
    });
    try {
      await registerUser({
        email,
        password,
        otp: code,
      });
      this.setState({
        registerSuccess: true,
        isRegistering: false,
      });
    } catch (e) {
      this.setState({
        registerError: true,
        isRegistering: false,
      });
    }
  }

  render() {
    let { email } = this.props.location.state || {};
    return (
      <Wrapper>
        {this.state.registerSuccess ? (
          <div>
            <Title>Successfully Registered</Title>
            <SuccessText>
              Registration success. You can sign in now.
            </SuccessText>
            <Button onClick={() => this.props.history.push("/login")}>
              Go to Sign In
            </Button>
          </div>
        ) : (
          <div>
            <Title>Verify your Email</Title>
            <OTPText>
              {"Please enter the 6 digit code that we sent to your email id " +
                email}
            </OTPText>
            <PinCode
              values={this.state.values}
              onChange={({ values }) => this.setState({ values })}
            />
            {this.state.timeRemaining > 0 ? (
              <ResendText>
                {"Not received? Resend code in " +
                  this.state.timeRemaining +
                  " seconds"}
              </ResendText>
            ) : (
              <React.Fragment>
                <Button
                  kind={KIND.minimal}
                  size={SIZE.mini}
                  isLoading={this.state.sending}
                  onClick={async () => {
                    await this.sendOTP(true);
                  }}
                >
                  Resend Code
                </Button>
                <ResendText>
                  {this.state.isResending ? "Sending code. Please wait..." : ""}
                </ResendText>
              </React.Fragment>
            )}
            <ButtonWrapper>
              <Button
                isLoading={this.state.isRegistering}
                onClick={() => this.verifyOTP()}
              >
                {"Complete Registration"}
              </Button>
              <Link to={"/register"}>
                <Button kind={KIND.minimal} size={SIZE.mini}>
                  {"Edit " + email}
                </Button>
              </Link>
            </ButtonWrapper>
          </div>
        )}
        <PageHeader notLoggedIn />
      </Wrapper>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(EmailVerify));
