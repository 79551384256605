import React from "react";
import { styled, withStyle } from "baseui";
import Flex from "./Flex";
import { KIND } from "baseui/button";
import Button from "./Button";
import PopConfirm from "./PopConfirm";
import Card from "./Card";
import { Icon } from "./Icon";
import { LabelMedium, LabelSmall } from "baseui/typography";
import { Block } from "baseui/block";

const Location = styled("div", {
  color: "#999999",
});

const Left = withStyle(Flex, {
  flexDirection: "column",
});

const CompanyName = styled("div", {
  color: "#999999",
  marginRight: "8px",
});

const Wrapper = withStyle(Card, ({ isFirst, isLast, $theme }) => ({
  position: "relative",
  borderRadius: 0,
  borderTopLeftRadius: isFirst ? $theme.borders.radius300 : 0,
  borderTopRightRadius: isFirst ? $theme.borders.radius300 : 0,
  borderBottomLeftRadius: isLast ? $theme.borders.radius300 : 0,
  borderBottomRightRadius: isLast ? $theme.borders.radius300 : 0,
  ...$theme.borders.border300,
  ...(!isFirst && { borderTopWidth: 0 }),
}));

const Top = styled("div", {
  display: "flex",
  justifyContent: "space-between",
});

const ExperienceSummary = ({ isFirst, isLast, ...props }) => {
  return (
    <Wrapper isFirst={isFirst} isLast={isLast}>
      <Left>
        <Top>
          <LabelMedium>{props.jobTitle}</LabelMedium>
          <Block
            display="flex"
            top="scale200"
            right="scale200"
            position="absolute"
          >
            <Button
              kind={KIND.minimal}
              onClick={() => {
                props.updateEdit(props.index);
              }}
            >
              <Icon name="edit" size={16} />
            </Button>
            <PopConfirm
              showArrow
              title="Are you sure to delete this experience?"
              onConfirm={async () => await props.onRemove(props.index)}
            >
              <Button kind={KIND.minimal}>
                <Icon name="trash" size={16} />
              </Button>
            </PopConfirm>
          </Block>
        </Top>
        <div style={{ display: "flex" }}>
          <CompanyName>{props.companyName}</CompanyName>
          <Location>{props.location}</Location>
        </div>
        <div>{props.startYear + " - " + (props.endYear || "Current")}</div>
      </Left>
    </Wrapper>
  );
};
export default ExperienceSummary;
