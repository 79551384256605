import React from "react";
import { addCurrencyDecimals } from "../util/formatter";
import { getCurrency, getCurrencyWords } from "../util/currency";

const Currency = (props) => {
  const currencySymbol = getCurrency(props.currency).name;
  const currencyValue = props.inWords
    ? [getCurrencyWords(props.value)]
    : addCurrencyDecimals(props.value, props.decimals ? 2 : 0).split(".");

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {props.currency ? (
        <div style={{ marginRight: "4px" }}>{currencySymbol}</div>
      ) : null}
      <div>
        <span>{currencyValue[0]}</span>
        {currencyValue[1] && (
          <>
            <span>{"."}</span>
            <span style={{ fontSize: "10px" }}>{currencyValue[1]}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default Currency;
