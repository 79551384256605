import React from "react";
import { Input, SIZE } from "baseui/input";

const StyledInput = (props) => (
  <Input
    {...props}
    overrides={{
      InputContainer: {
        style: ({ $theme }) => {
          return {
            borderRadius: $theme.borders.radius200,
            background: $theme.colors.background,
          };
        },
      },
      Root: {
        style: ({ $theme, $isFocused }) => {
          return {
            borderWidth: "1px",
            borderColor: $isFocused ? "rgba(44, 110, 203, 1)" : "#dadedf",
            borderRadius: $theme.borders.radius200,
          };
        },
      },
      StartEnhancer: {
        style: ({ $theme }) => {
          return {
            // color: "#333",
            // backgroundColor: "inherit",
            // marginRight: "8px",
            // padding: "0px",
            // borderTopLeftRadius: "4px",
            // borderTopRightRadius: "4px",
            // borderBottomRightRadius: "4px",
            // borderBottomLeftRadius: "4px",
          };
        },
      },
      EndEnhancer: {
        style: ({ $theme }) => {
          return {
            // color: "#333",
            // backgroundColor: "inherit",
            // marginLeft: "8px",
            // padding: "0px",
            // borderTopLeftRadius: "4px",
            // borderTopRightRadius: "4px",
            // borderBottomRightRadius: "4px",
            // borderBottomLeftRadius: "4px",
          };
        },
      },
      ...props.overrides,
    }}
  >
    {props.children}
  </Input>
);

export default StyledInput;
