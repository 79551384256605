import React from "react";
import { StatefulPopover } from "baseui/popover";
import Button from "./Button";
import { KIND } from "baseui/button";
import { Block } from "baseui/block";
import { ParagraphSmall } from "baseui/typography";

const PopConfirm = (props) => (
  <StatefulPopover
    content={({ close }) => (
      <Block padding={"scale400"}>
        <ParagraphSmall marginBottom="scale800">{props.title}</ParagraphSmall>
        <Block display="flex" justifyContent="flex-end">
          <Button
            kind={KIND.minimal}
            onClick={(e) => {
              props.onConfirm(e);
              close();
            }}
            buttonStyle={{ marginRight: "16px" }}
          >
            {props.confirmText ? props.confirmText : "Confirm"}
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.onCancel && props.onCancel(e);
              close();
            }}
          >
            {props.cancelText ? props.cancelText : "Cancel"}
          </Button>
        </Block>
      </Block>
    )}
    returnFocus
    autoFocus
    {...props}
    overrides={{
      Inner: {
        style: ({ $theme }) => {
          return {
            backgroundColor: "#fff",
            borderColor: "#dedede",
            borderTopWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            borderRightWidth: "1px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            maxWidth: props.width ? props.width : "320px",
          };
        },
      },
    }}
  >
    {props.children}
  </StatefulPopover>
);

export default PopConfirm;
