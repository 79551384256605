import React, { Suspense } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import Resume from "../components/Resume";
import {
  getCandidate,
  getAnonymousCandidate,
  updateCandidateStat,
} from "../api/candidate";
import LoadingScreen from "../components/LoadingScreen";
import { CandidateInterviewHistory } from "../components/CandidateInterviewHistory";
import Button from "../components/Button";
import CandidateItemEmployer from "../components/CandidateItemEmployer";
import * as Analytics from "../analytics";
import { convertToCandidateSearch } from "../util/common";
import { KIND, SIZE } from "baseui/button";
import { Icon } from "../components/Icon";
import { CenterContainer } from "../components/CenterContainer";

const LazyResumePDFDownload = React.lazy(() =>
  import("../components/ResumePDFDownload")
);

class CandidateDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basic: {},
      isAnonymous: !props.fullProfile,
      isFetching: true,
      isError: false,
    };
  }

  async componentDidMount() {
    Analytics.logEvent(
      (this.props.user.role === "EMPLOYER" ? "[E] " : "[S] ") +
        "page view candidate details "
    );
    this.setState({
      isFetching: true,
    });
    let { candidateId } = this.props.match.params;
    try {
      let candidate = this.state.isAnonymous
        ? await getAnonymousCandidate(candidateId)
        : await getCandidate(candidateId);
      this.setState({
        isFetching: false,
        candidate,
      });
      updateCandidateStat(
        candidateId,
        this.props.user.role === "EMPLOYER" ? "view" : "viewY"
      ).then(() => {});
    } catch (e) {
      this.setState({
        isFetching: false,
        isError: true,
      });
      console.log(e);
    }
    try {
      const params = new URLSearchParams(this.props.location.search);
      const jobId = params.get("jobId");
      this.setState({
        jobId: jobId,
      });
    } catch (e) {}
  }

  render() {
    if (this.state.isFetching) {
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    }
    if (this.state.isError) {
      return (
        <CenterContainer>
          {" "}
          <div>Errored. Please retry.....</div>
        </CenterContainer>
      );
    }
    return (
      <CenterContainer>
        <Title>Candidate Details</Title>
        {this.props.user.role === "EMPLOYER" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "8px 0px",
              marginBottom: "8px",
            }}
          >
            <div style={{ display: "flex", flex: 1 }}></div>
            {/* <Button
              kind={KIND.tertiary}
              size={SIZE.mini}
              startEnhancer={<Icon name="download" size={16} />}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <LazyResumePDFDownload
                  isAnonymous={true}
                  textColor={"#333333"}
                  candidate={this.state.candidate}
                />
              </Suspense>
            </Button> */}
          </div>
        ) : null}
        {this.props.user.role === "TALENTSCOUT" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              padding: "8px 0px",
              marginBottom: "8px",
            }}
          >
            <div style={{ display: "flex", flex: 1 }}></div>
            {/* <Button
              kind={KIND.tertiary}
              startEnhancer={<Icon name="download" size={16} />}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <LazyResumePDFDownload
                  isAnonymous={true}
                  textColor={"#333333"}
                  candidate={this.state.candidate}
                />
              </Suspense>
            </Button> */}
          </div>
        ) : null}
        {this.state.jobId && (
          <CandidateInterviewHistory
            jobId={this.state.jobId}
            anonymousId={this.state.candidate.userId}
          />
        )}
        <CandidateItemEmployer
          candidate={convertToCandidateSearch(this.state.candidate)}
          isEmployer={this.props.user.role === "EMPLOYER"}
          user={this.props.user}
          jobId={this.state.jobId}
        />
        <Resume
          isAnonymous={this.state.isAnonymous}
          candidate={this.state.candidate}
        />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateDetails));
