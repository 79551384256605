import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Spacer,
  Text,
  Button,
  useToast,
  Spinner,
  Center,
  toast,
  Container,
} from "@chakra-ui/react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  createPaymentOrder,
  getPaymentLink,
  updatePaymentLink,
  verifyPaymentOrder,
} from "src/api/user";
import PageHeader from "src/components/Header";
import Currency from "src/components/Currency";
import { envConfig } from "src/api/endpoints";

// export type EmployerViewPaymentLinkProps = {};
export const EmployerViewPaymentLink = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [isAdding, setIsAdding] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const id = params.get("id");
  const [paymentLink, setPaymentLink] = useState<
    | {
        amount: number;
        userId: string;
        currency: string;
        workspaceId: string;
        firstName: string;
        lastName: string;
        emailToSend: string;
        status: string;
      }
    | undefined
  >();
  const [amount, setAmount] = useState("0");
  const [amountError, setAmountError] = useState("");
  const toast = useToast();
  console.log("id", id);
  const getPaymentLinkRemote = useCallback(async () => {
    if (!id) return;
    setLoading(true);
    try {
      const paymentLink = await getPaymentLink({ id });
      setPaymentLink(paymentLink);
    } catch (e) {
      toast({ title: "Error while fetching details" });
    }
    setLoading(false);
  }, [id, toast]);

  const onPayPress = async () => {
    setAmountError("");
    if (isNaN(+amount)) {
      setAmountError("Invalid amount");
      return;
    }
    setIsAdding(true);
    if (!paymentLink) return;
    try {
      let orderResponse = await createPaymentOrder(
        paymentLink.userId,
        {
          amount: +amount * 100,
          currency: paymentLink.currency,
        },
        { "payment-link-id": id }
      );

      let razorpayOptions = {
        key: envConfig["razorpay"],
        amount: +amount * 100,
        currency: paymentLink.currency,
        name: "FindingJo",
        description: "Adding money to FindingJo wallet",
        image:
          "https://storage.googleapis.com/quickhire-stage/assets/favicon.png",
        order_id: orderResponse.id,
        handler: async (response: any) => {
          setIsAdding(true);
          const accountId = paymentLink.workspaceId;
          const accountType = "WORKSPACE";
          try {
            await verifyPaymentOrder(
              paymentLink.userId,
              {
                paymentId: response.razorpay_payment_id,
                orderId: response.razorpay_order_id,
                signature: response.razorpay_signature,
                accountId,
                accountType,
              },
              { "payment-link-id": id }
            );
            setIsComplete(true);
          } catch (e) {
            toast({
              title:
                "Error during verifying the payment. If money deducted we will refund you.",
            });
            console.log(e);
          }
          try {
            await updatePaymentLink(
              {
                paymentSignature: response.razorpay_signature,
                paymentLinkId: id,
                paymentId: response.razorpay_payment_id,
                orderId: response.razorpay_order_id,
              },
              { "payment-link-id": id }
            );
          } catch (e) {
            console.log(e);
          }
          setIsAdding(false);
        },
        prefill: {
          name: paymentLink.firstName + " " + paymentLink.lastName,
          email: paymentLink.emailToSend,
          contact: "",
        },
        notes: {
          address: "Adding money to wallet",
        },
        theme: {
          color: "black",
        },
      };
      var rzp1 = new (window as any).Razorpay(razorpayOptions);
      rzp1.open();
      setIsAdding(false);
    } catch (e) {
      setIsAdding(false);
      toast({ title: "Error during the payment. Please retry again." });
      console.log(e);
    }
  };

  useEffect(() => {
    getPaymentLinkRemote();
  }, [getPaymentLinkRemote]);

  useEffect(() => {
    if (paymentLink) {
      setAmount(paymentLink.amount + "");
    }
  }, [paymentLink]);

  if (loading)
    return (
      <Center>
        <Spinner />
      </Center>
    );

  if (!paymentLink) return null;

  if (paymentLink.status === "INACTIVE") {
    return (
      <Box>
        <Container mt={"100px"}>
          <Box background="white" borderRadius={"md"} p={8} shadow="md">
            <Heading fontWeight="semibold" fontSize="2xl">
              Payment Link Inactive
            </Heading>
            <Spacer height={8} />
            <Text>
              This payment link is not active now. Please ask the FindingJo user
              to send the payment link again.
            </Text>
            <Spacer height={8} />
          </Box>
        </Container>
        <PageHeader notLoggedIn />
      </Box>
    );
  }
  if (isComplete) {
    return (
      <Box>
        <Container mt={"100px"}>
          <Box background="white" borderRadius={"md"} p={8} shadow="md">
            <Heading fontWeight="semibold" fontSize="2xl">
              Payment Successful
            </Heading>
            <Spacer height={8} />
            <Text>
              Wallet has been loaded successfully. You can close this window
              now.
            </Text>
            <Spacer height={8} />
          </Box>
        </Container>
        <PageHeader notLoggedIn />
      </Box>
    );
  }
  return (
    <Box>
      <Container mt={"100px"}>
        <Box background="white" borderRadius={"md"} p={8} shadow="md">
          <Heading fontWeight="semibold" fontSize="2xl">
            Complete payment
          </Heading>
          <Spacer height={8} />
          <FormControl>
            <FormLabel>Enter amount</FormLabel>
            <InputGroup>
              <InputLeftAddon>₹</InputLeftAddon>
              <Input
                width="376px"
                placeholder="amount"
                value={amount + ""}
                onChange={(e: any) => setAmount(e.target.value)}
              ></Input>
            </InputGroup>
            <Currency inWords currency={"INR"} value={amount}></Currency>
            <Text color="gray.400" fontSize="sm">
              *2.36% payment gateway charges applicable
            </Text>
            {amountError ? <Text color="red.500">{[amountError]}</Text> : null}
          </FormControl>
          <Spacer height={8} />
          <Button
            colorScheme="orange"
            onClick={onPayPress}
            isLoading={isAdding}
          >
            Add to wallet
          </Button>
        </Box>
      </Container>
      <PageHeader notLoggedIn />
    </Box>
  );
};
