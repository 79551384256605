import React, { useEffect, useCallback, useMemo, useState } from "react";
import {
  Badge,
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useClipboard,
} from "@chakra-ui/react";
import {
  BasicUserProps,
  getReferCandidates,
  handpickReferCandidateApi,
  ReferredCandidateProps,
} from "src/api/referred_candidate";
import { Column, useTable } from "react-table";
import { keyBy } from "lodash";
import { prettyPrint } from "src/util/time";
import { getStatusColorScheme } from "./util";
import { FiArrowRight } from "react-icons/fi";
import Pagination from "src/lib/components/Pagination";
import { SearchIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { AutoComplete, AutoCompleteItem } from "src/components/AutoComplete";
import { searchEntity } from "src/api/entity";
import { appUrl } from "src/api/endpoints";

const HandpickButton = ({
  referredCandidateId,
  onHandpick,
}: {
  referredCandidateId: string;
  onHandpick?: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Button
      colorScheme={"blue"}
      isLoading={isLoading}
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("handpick");
        setIsLoading(true);
        try {
          await handpickReferCandidateApi(referredCandidateId);
          await onHandpick?.();
        } catch (e) {}
        setIsLoading(false);
      }}
    >
      Handpick
    </Button>
  );
};

const CopyLink = ({ link }: { link: string }) => {
  const { onCopy, hasCopied } = useClipboard(link);
  return (
    <Button
      variant="link"
      onClick={(e) => {
        e.stopPropagation();
        onCopy();
      }}
    >
      {hasCopied ? "Copied" : "Copy link"}
    </Button>
  );
};

export const AllReferredCandidates = () => {
  const [referredCandidates, setReferredCandidates] = useState<
    ReferredCandidateProps[]
  >([]);
  const [talentscoutUsers, setTalentscoutUsers] = useState<{
    [key: string]: BasicUserProps;
  }>({});
  const [handpickedCandidateIds, setHandpickedCandidateIds] = useState<
    string[]
  >([]);
  const [email, setEmail] = useState<string>("");
  const [page, setPage] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [filterByJob, setFilterByJob] = useState<
    AutoCompleteItem | undefined
  >();
  const [filterByReviewTags, setFilterByReviewTags] = useState<
    AutoCompleteItem[]
  >([]);
  const [filterByStatus, setFilterByStatus] = useState<
    AutoCompleteItem | undefined
  >();

  const [sortBy, setSortBy] = useState<AutoCompleteItem | undefined>();
  const history = useHistory();
  const limit = 10;
  const fetchReferredCandidates = useCallback(async () => {
    setIsFetching(true);
    try {
      const {
        data,
        talentscouts,
        hasNext,
        handpickedCandidateIds,
      } = await getReferCandidates(
        {
          email,
          jobId: filterByJob?.id,
          sortField: sortBy?.id,
          sortOrder: sortBy?.id === "reviewPriority" ? "ASC" : undefined,
          status: filterByStatus?.id,
          reviewTags: filterByReviewTags.map((tag) => tag.id).join(","),
        },
        page,
        limit
      );
      setReferredCandidates(data);
      setHandpickedCandidateIds(handpickedCandidateIds || []);
      setTalentscoutUsers(keyBy(talentscouts, "id"));
      setHasNext(hasNext);
    } catch (e) {}
    setIsFetching(false);
  }, [email, filterByJob, filterByStatus, filterByReviewTags, sortBy, page]);
  useEffect(() => {
    fetchReferredCandidates();
  }, [fetchReferredCandidates, page, filterByJob, filterByStatus, sortBy]);
  const data = useMemo(() => referredCandidates, [referredCandidates]);
  const columns = useMemo<Column<ReferredCandidateProps>[]>(
    () => [
      {
        Header: "Email",
        accessor: ({ email, phone, createdAt }) => {
          return (
            <Text>
              <Text textStyle={"h4"}>{email}</Text>
              <Text>{phone}</Text>
              <Text>{prettyPrint(createdAt, true)}</Text>
            </Text>
          );
        },
      },
      {
        Header: "Talentscout",
        accessor: ({ talentscoutId }) => {
          return <Text>{talentscoutUsers?.[talentscoutId]?.name}</Text>;
        },
      },
      {
        Header: "Is Verfied",
        accessor: ({ isVerified, email, id }) => (
          <Text>
            <Text>{isVerified ? "Yes" : "No"}</Text>
            {!isVerified && (
              <CopyLink
                link={
                  appUrl + "/public/referredCandidate/verification?rid=" + id
                }
              />
            )}
          </Text>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value }) => (
          <Badge colorScheme={getStatusColorScheme(value)}>{value}</Badge>
        ),
      },
      {
        Header: "Last Working Date",
        accessor: ({ isResigned, lastWorkingDate }) => (
          <Text>{isResigned ? prettyPrint(lastWorkingDate, true) : "-"}</Text>
        ),
      },
      {
        Header: "Notice Period",
        accessor: ({ noticePeriod }) => <Text>{`${noticePeriod} Weeks`}</Text>,
      },
      {
        Header: "Review Priority",
        accessor: "reviewPriority",
      },
      {
        Header: "Review Priority Reason",
        accessor: "reviewPriorityReason",
      },
      {
        Header: "",
        accessor: "id",
        Cell: ({ value }) => <FiArrowRight />,
      },
      {
        Header: "Handpicked",
        accessor: ({ candidateId, id, status }) => {
          if (!id || !filterByJob?.id) return null;
          if (handpickedCandidateIds.includes(candidateId)) {
            return <Button colorScheme={"green"}>Handpicked</Button>;
          }
          if (status === "DATAANONYMISATION") {
            return (
              <HandpickButton
                referredCandidateId={id}
                onHandpick={async () => {
                  await fetchReferredCandidates();
                }}
              />
            );
          }
          return null;
        },
      },
    ],
    [
      talentscoutUsers,
      filterByJob,
      handpickedCandidateIds,
      fetchReferredCandidates,
    ]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });
  return (
    <Box>
      <Box p={4}>
        <Text textStyle="h2">All Referred Candidates</Text>
        <Flex mt={4}>
          <InputGroup width={"300px"}>
            <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
            <Input
              placeholder="Enter email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  fetchReferredCandidates();
                }
              }}
            ></Input>
          </InputGroup>
          <Box w={4} />
          <AutoComplete
            maxWidth="100px"
            items={[]}
            id={`filterByJob`}
            placeholder="Search Job"
            isCreatable={false}
            selected={filterByJob}
            onItemSelect={(value: AutoCompleteItem | AutoCompleteItem[]) => {
              setFilterByJob(value as AutoCompleteItem);
            }}
            fetchItems={async (q) => searchEntity("job", q)}
          />
          <Box w={4} />
          <AutoComplete
            maxWidth="100px"
            items={[]}
            id={`filterByReviewTags`}
            placeholder="Search Tags"
            isCreatable={false}
            isMultiple
            selected={filterByReviewTags}
            onItemSelect={(value: AutoCompleteItem | AutoCompleteItem[]) => {
              setFilterByReviewTags(value as AutoCompleteItem[]);
            }}
            fetchItems={async (q) => searchEntity("candidateReviewTags", q)}
          />
          <Box w={4} />
          <AutoComplete
            items={[
              { id: "reviewPriority", name: "Priority" },
              { id: "lastWorkingDate", name: "Last Working Date" },
              { id: "createdDate", name: "Created Date" },
            ]}
            id={`sortBy`}
            isSearchable={false}
            placeholder="Sort by"
            selected={sortBy}
            onItemSelect={(value: AutoCompleteItem | AutoCompleteItem[]) => {
              setSortBy(value as AutoCompleteItem);
            }}
            fetchItems={async (q) => searchEntity("job", q)}
          />
          <Box w={4} />
          <AutoComplete
            items={[
              { id: "CREATED", name: "Created" },
              { id: "PREAPPROVED", name: "Pre Approved" },
              { id: "DATAVERIFIED", name: "Data Verified" },
              { id: "DATAINCOMPLETE", name: "Data Incomplete" },
              { id: "ENTITYANONYMISATION", name: "Entity Anonymisation" },
              { id: "DATAANONYMISATION", name: "Data Anonymisation" },
              { id: "HANDPICKED", name: "Hand Picked" },
            ]}
            id={`filterByStatus`}
            isSearchable={false}
            placeholder="Select status"
            selected={filterByStatus}
            onItemSelect={(value: AutoCompleteItem | AutoCompleteItem[]) => {
              setFilterByStatus(value as AutoCompleteItem);
            }}
            fetchItems={async (q) => searchEntity("job", q)}
          />
        </Flex>
      </Box>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps({
                    style: {
                      minWidth: column.minWidth,
                      width: column.width,
                      textTransform: "none",
                    },
                  })}
                >
                  <Text textStyle="h4" fontSize="14px">
                    {column.render("Header", {
                      textTransform: "none",
                    })}
                  </Text>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {!isFetching &&
            rows.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  {...row.getRowProps()}
                  _hover={{ background: "gray.50" }}
                  cursor="pointer"
                  onClick={() => {
                    history.push(
                      "/tools/candidates/referred/all/" + row.original.id
                    );
                  }}
                >
                  {row.cells.map((cell) => (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  ))}
                </Tr>
              );
            })}
        </Tbody>
      </Table>
      {isFetching && (
        <Flex height="200px" alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      )}
      {!isFetching && (
        <Box mt={4}>
          <Pagination page={page} setPage={setPage} hasNext={hasNext} />
        </Box>
      )}
    </Box>
  );
};
