import api from "./index";
import { baseUrl } from "./endpoints";

export async function addWalletMoney(data) {
  return await api({
    baseUrl,
    url: "/api/wallet/add",
    method: "POST",
    data,
  });
}


export async function getWallet(accountId, accountType) {
  return await api({
    baseUrl,
    url: "/api/wallet",
    method: "GET",
    params: [{ name: "accountId", value: accountId }, { name: "accountType", value: accountType }],
  });
}

export async function getWalletTransactions(accountId, accountType, page) {
  return await api({
    baseUrl,
    url: "/api/wallet/transactions",
    method: "GET",
    params: [{ name: "accountId", value: accountId }, { name: "accountType", value: accountType }, { name: "page", value: page }],
  });
}

export async function getWalletTransactionDetails(accountId, accountType, transactionId) {
  return await api({
    baseUrl,
    url: "/api/wallet/transactions/" + transactionId,
    method: "GET",
  });
}

export async function createWalletWithdrawal(data) {
  return await api({
    baseUrl,
    url: "/api/wallet/withdraw",
    method: "POST",
    data,
  });
}
