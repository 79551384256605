import React from "react";
import Button from "./Button";
import { SIZE } from "baseui/button";
import Card from "./Card";
import { withStyle } from "baseui";
import Currency from "./Currency";
import Bold from "./Bold";
import { setWidgetState } from "../redux/actions/widget";
import { connect } from "react-redux";
import { KIND } from "baseui/button";
import { Block } from "baseui/block";
import { Icon } from "./Icon";

const Wrapper = withStyle(Card, ({ isClickable }) => ({
  marginBottom: "16px",
  opacity: isClickable ? 1 : 0.5,
  ":hover": isClickable
    ? {
        boxShadow: "1px 2px 20px 0px rgba(0, 0, 0, 0.1)",
      }
    : {},
}));

const Field = (props) => (
  <div
    style={{
      display: "flex",
      width: props.isStat ? "auto" : "300px",
      marginRight: "16px",
    }}
  >
    {props.img ? (
      <Block $marginRight="scale200" $marginTop="scale200">
        <Icon name={props.img} size={16}></Icon>
      </Block>
    ) : null}
    {props.name ? <div style={{ marginRight: "4px" }}>{props.name}</div> : null}

    <div style={{}}>{props.value}</div>
  </div>
);

const Job = (props) => {
  return (
    <Wrapper
      isClickable={props.isClickable}
      className={props.isClickable ? "clickable" : ""}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Bold
          className={"linkText"}
          style={{
            fontSize: "16px",
          }}
        >
          {props.job.company.name}
        </Bold>
        {props.job.status === "REVIEWED" ? (
          <Icon
            name="share-2"
            size={20}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.setWidgetState({
                isShareJobModelVisible: true,
                shareJob: props.job,
                shareJobImageUrl: props.job.shareImageUrl,
              });
            }}
          />
        ) : null}
        {props.job.status === "CLOSED" && (
          <div style={{ color: "#F8756C", fontSize: "12px" }}>CLOSED</div>
        )}
        {props.job.status === "CREATED" && (
          <div style={{ color: "green", fontSize: "12px" }}>UNDER REVIEW</div>
        )}
        {props.job.status === "ONHOLD" && (
          <div style={{ color: "#F8756C", fontSize: "12px" }}>ON HOLD</div>
        )}
      </div>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Field img={"user"} name={"Role"} value={props.job.jobTitle.name} />
        <Field
          img={"m2"}
          name={"CTC"}
          value={
            <Currency
              inWords
              currency={props.job.salaryCurrency}
              value={props.job.maximumSalary}
            />
          }
        />
        <Field
          img={"file-text"}
          name={"Experience"}
          value={
            props.job.minExperience + "-" + props.job.maxExperience + " Yrs"
          }
        />
        <Field
          img={"map-pin"}
          name={"Locations"}
          value={props.job.locations.map((l) => l.name).join(", ")}
        />
      </div>
      <Field
        img={"tag"}
        isStat
        name={"Skills"}
        value={props.job.skills.map((s) => s.name).join(", ")}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Block $marginRight="scale200">
          <Button kind={KIND.tertiary}>View Details</Button>
        </Block>
        {props.isJobApplication ? (
          <Button
            size={SIZE.compact}
            type={"primary"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              props.onViewCandidatesClick(props.job.id);
            }}
          >
            View Candidates
          </Button>
        ) : null}
      </div>
    </Wrapper>
  );
};

// const JobItem = ({
//   job,
//   isEdit,
//   isApply,
//   isJobApplication,
//   onViewCandidatesClick,
//   onEditJobClick,
//   onApplyJobClick,
// }) => (
//   <Box
//     style={{ backgroundColor: "#ffffff", padding: "16px", borderRadius: "4px" }}
//   >
//     <div style={{ display: "flex" }}>
//       <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
//         <div style={{ color: "#0F4C81" }}>{job.jobTitle.name}</div>
//         <div style={{ fontSize: "14px" }}>
//           <div style={{ display: "flex" }}>
//             <div style={{ marginRight: "16px" }}>{job.company.name}</div>
//             <div style={{ color: "#919191" }}>
//               {(job.locations || []).map((loc) => loc.name).join(",")}
//             </div>
//           </div>
//           <div style={{ display: "flex", flexDirection: "row" }}>
//             {(job.skills || [])
//               .map((skill) => skill.name)
//               .map((sk) => (
//                 <div
//                   style={{
//                     padding: "0px 6px",
//                     backgroundColor: "#A4D0EE",
//                     fontSize: "13px",
//                     color: "white",
//                     borderRadius: "4px",
//                     marginRight: "8px",
//                   }}
//                 >
//                   {sk}
//                 </div>
//               ))}
//           </div>
//           <div style={{ marginTop: "16px" }}>{prettyPrint(job.createdAt)}</div>
//         </div>
//       </div>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "flex-start",
//           width: "180px",
//         }}
//       >
//         {isApply ? (
//           <Button
//             size={SIZE.compact}
//             type={"primary"}
//             onClick={() => {
//               onApplyJobClick(job.id);
//             }}
//           >
//             Apply
//           </Button>
//         ) : null}
//       </div>
//     </div>
//   </Box>
// );

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setWidgetState }
)(Job);

// export default Job;
