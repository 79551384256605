import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import { getJobById } from "../api/job";
import Job from "../components/Job";
import Title from "../components/Title";
import Button from "../components/Button";
import LoadingScreen from "../components/LoadingScreen";
import { TALENTSCOUT_UPLOAD_CANDIDATE } from "../util/routes";
import { CenterContainer } from "../components/CenterContainer";

class ScoutJobDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
      isFetching: true,
    };
  }

  async componentDidMount() {
    this.onLoad();
  }

  onLoad = async () => {
    this.setState({
      isFetching: true,
    });
    let { jobId } = this.props.match.params;
    try {
      let { job } = await getJobById(jobId);
      this.setState({
        job,
      });
    } catch (e) {}
    this.setState({
      isFetching: false,
    });
  };

  render() {
    if (this.state.isFetching)
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    return (
      <CenterContainer>
        <Title>Job Description</Title>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "8px",
          }}
        >
          <Button
            onClick={() => {
              this.props.history.push(
                "/talentscout/jobs/" + this.state.job.id + "/upload"
              );
            }}
          >
            Upload Candidate
          </Button>
        </div>
        <Job job={this.state.job} />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(ScoutJobDetails));
