import React from "react";
import { Heading, Box } from "@chakra-ui/react";

export const Interviews = () => {
  return (
    <Box>
      <Heading size="md">Interviews</Heading>
    </Box>
  );
};
