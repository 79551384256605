import { styled } from "baseui";

const Pill = styled("div", ({ $theme }) => ({
  background: $theme.colors.accentBlue,
  padding: $theme.sizing.scale100,
  borderRadius: $theme.borders.radius300,
  color: "white",
}));

export default Pill;
