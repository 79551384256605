import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter, useParams } from "react-router-dom";
import { getJobById, applyJob } from "../api/job";
import Job from "../components/Job";
import Title from "../components/Title";
import Button from "../components/Button";
import LoadingScreen from "../components/LoadingScreen";
import { prettyPrint } from "../util/time";
import * as Analytics from "../analytics";
import { toaster } from "baseui/toast";
import { CenterContainer } from "../components/CenterContainer";
import { getUser } from "../api/user";
import { CANDIDATE_PROFILE_BASIC } from "../util/routes";

const CandidateJobDetails = (props) => {
  const [isFetching, setIsFetching] = useState(true);
  const [job, setJob] = useState({});
  const [jobApplication, setJobApplication] = useState({});
  const [isApplying, setIsApplying] = useState(false);
  let { jobId } = useParams();

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoad = async () => {
    setIsFetching(true);
    try {
      let { job, jobApplication } = await getJobById(jobId);
      setJob(job);
      setJobApplication(jobApplication);
      setIsFetching(false);
    } catch (e) {
      setIsFetching(false);
    }
  };

  const onApplyJob = async () => {
    Analytics.logEvent("[C] click apply job");
    try {
      setIsApplying(true);
      const user = await getUser(props.user.id);
      if (!user.candidateProfile) {
        props.history.push(CANDIDATE_PROFILE_BASIC);
        return;
      }

      await applyJob({
        employerId: job.createdBy,
        employerName: job.employerName,
        candidateId: props.user.id,
        jobId: job.id,
      });
      toaster.show(<div>Job Application Sent</div>, {
        autoHideDuration: 3000,
      });
      onLoad();
      setIsApplying(false);
    } catch (e) {
      setIsApplying(false);
      console.log(e);
    }
  };

  if (isFetching)
    return (
      <CenterContainer>
        <LoadingScreen />
      </CenterContainer>
    );

  return (
    <CenterContainer>
      <Title>Job Posting</Title>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingBottom: "16px",
        }}
      >
        {jobApplication ? (
          <div>{"Applied on " + prettyPrint(jobApplication.createdAt)}</div>
        ) : (
          <Button isLoading={isApplying} onClick={onApplyJob}>
            Apply
          </Button>
        )}
      </div>
      <Job job={job} jobApplication={jobApplication} />
    </CenterContainer>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateJobDetails));
