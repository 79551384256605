import React, { useEffect, useState } from "react";
import {
  Heading,
  Box,
  Flex,
  Text,
  FormControl,
  Input,
  FormHelperText,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spinner,
  Textarea,
  useToast,
  Button,
} from "@chakra-ui/react";
import { FaCheck, FaPlus, FaSave, FaTimes } from "react-icons/fa";
import { EditableInput } from "../../lib/components/EditableInput";
import { ButtonWithConfirm } from "../../lib/components/ButtonWithConfirm";
import { ExperienceList } from "src/lib/forms/Candidate/Experience/ExperienceList";
import { EducationList } from "src/lib/forms/Candidate/Education/EducationList";
import { ProjectList } from "src/lib/forms/Candidate/Projects/ProjectList";
import { WorkShowcaseList } from "src/lib/forms/Candidate/WorkShowcases/WorkShowcaseList";
import { SkillList } from "src/lib/forms/Candidate/Skills/SkillList";
import { FormLabel, FormErrorMessage } from "src/lib/uikit";
import {
  getReferCandidate,
  updateReferCandidate,
  ReferredCandidateProps,
} from "../../api/referred_candidate";
import { useHistory, useParams } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { PDFViewer } from "src/lib/components/PDFViewer";
import { ResumeProps } from "src/lib/types/candidate";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { addCandidateReviewCommentApi } from "src/api/candidate_review_comment";
import { FiCheck, FiX } from "react-icons/fi";

export type ResumeViewProps = {
  id: string;
  status?: string;
  resume: ResumeProps;
  onUpdate: (referredCandidate: any) => void;
};

const resume: ResumeProps = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  countryCode: "",
  linkedInUrl: "",
  twitterUrl: "",
  facebookUrl: "",
  githubUrl: "",
  location: undefined,
  careerSummary: "",
  experience: [],
  education: [],
  skills: [],
  projects: [],
  certifications: [],
  articles: [],
  workShowcases: [],
  // sections: [
  //   {
  //     name: "email",
  //     title: "Personal Information",
  //     defaultTitle: "Personal Information",
  //   },
  //   {
  //     name: "experience",
  //     title: "Experience",
  //     defaultTitle: "Experience",
  //   },
  //   {
  //     name: "education",
  //     title: "Education",
  //     defaultTitle: "Education",
  //   },
  //   {
  //     name: "skills",
  //     title: "Skills",
  //     defaultTitle: "Skills",
  //   },
  // ],
  // customSections: [],
};

const schema = yup
  .object({
    email: yup.string().required("Required"),
    phone: yup.string().required("Required"),
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    careerSummary: yup.string().required("Required"),
  })
  .required();

export const CandidateView = ({
  resume,
  id,
  status,
  onUpdate,
}: ResumeViewProps) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const toast = useToast();
  useEffect(() => reset(resume), [reset, resume]);
  const onFormError = () => {
    toast({
      title: "Form has some error",
      description: "Please correct and retry",
      status: "error",
    });
  };
  const onSubmit = () => {};
  const onApprove = async (data: any) => {
    try {
      await updateReferCandidate(id, {
        ...getValues(),
        status: "DATAVERIFIED",
      });
      history.push("/tools/candidates/referred/verification");
    } catch (e) {
      console.log(e);
    }
  };

  const onSave = async () => {
    try {
      await updateReferCandidate(id, {
        ...getValues(),
      });
      toast({
        title: "Form saved",
        status: "success",
      });
    } catch (e) {
      console.log(e);
    }
  };
  const onReject = async (data: any) => {
    try {
      await updateReferCandidate(id, { status: "DATAINCOMPLETE" });
      history.push("/tools/candidates/referred/verification");
    } catch (e) {
      console.log(e);
    }
  };
  const history = useHistory();
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Box mb={6}>
          <Text textStyle="h3" size="md" mb={2}>
            <EditableInput
              defaultValue="Personal Details"
              isHeading
            ></EditableInput>
          </Text>
          <Flex>
            <FormControl
              isInvalid={!!(touchedFields.firstName && errors.firstName)}
              mr={6}
            >
              <FormLabel
                htmlFor="firstName"
                fontWeight="500"
                fontSize="sm"
                color="gray.500"
              >
                First Name
              </FormLabel>
              <Input {...register("firstName")} id="firstName" size={"lg"} />
              <FormErrorMessage>{errors.firstName?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!(touchedFields.lastName && errors.lastName)}
            >
              <FormLabel
                htmlFor="lastName"
                fontWeight="500"
                fontSize="sm"
                color="gray.500"
              >
                Last Name
              </FormLabel>
              <Input
                {...register("lastName")}
                id="lastName"
                size={"lg"}
                fontSize={"md"}
              />
              <FormErrorMessage>{errors.lastName?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Flex>
            <FormControl
              isInvalid={!!(touchedFields.email && errors.email)}
              mr={6}
            >
              <FormLabel
                htmlFor="email"
                fontWeight="500"
                fontSize="sm"
                color="gray.500"
              >
                Email
              </FormLabel>
              <Input
                {...register("email")}
                defaultValue={resume.email}
                id="email"
                size={"lg"}
                fontSize={"md"}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!(touchedFields.phone && errors.phone)}>
              <FormLabel
                htmlFor="phone"
                fontWeight="500"
                fontSize="sm"
                color="gray.500"
              >
                Phone
              </FormLabel>
              <Input
                {...register("phone")}
                id="phone"
                size={"lg"}
                fontSize="md"
              />
              <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
            </FormControl>
          </Flex>
          <Button
            type="button"
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              await onSave();
            }}
          >
            Save
          </Button>
        </Box>

        <Box mb={6}>
          <Text textStyle="h3" size="md">
            <EditableInput defaultValue="Objective" isHeading></EditableInput>
          </Text>
          <FormControl
            isInvalid={!!(touchedFields.careerSummary && errors.careerSummary)}
          >
            <FormHelperText mb={2}>
              Write 2-4 short & energetic sentences to interest the reader!
              Mention your role, experience & most importantly - your biggest
              achievements, best qualities and skills.
            </FormHelperText>
            <Controller
              name={`careerSummary`}
              control={control}
              render={({ field }) => (
                <Textarea
                  {...field}
                  id={`careerSummary`}
                  size={"lg"}
                  fontSize={"md"}
                  onChange={(e) => {
                    setValue(`careerSummary`, e.target.value);
                  }}
                />
              )}
            />
            <FormErrorMessage>{errors.careerSummary?.message}</FormErrorMessage>
          </FormControl>
          <Button
            type="button"
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              await onSave();
            }}
          >
            Save
          </Button>
        </Box>
        {id && (
          <Flex
            position="fixed"
            top="0"
            right={60}
            padding={4}
            background="white"
            borderTopWidth="1px"
            borderColor="gray.200"
            zIndex={1}
          >
            {status !== "DATAVERIFIED" && (
              <ButtonWithConfirm
                borderWidth={1}
                colorScheme={"green"}
                type="button"
                leftIcon={<FiCheck />}
                mr={2}
                confirmText="Approve"
                onClick={handleSubmit(onApprove, onFormError)}
              >
                Approve
              </ButtonWithConfirm>
            )}

            {status !== "DATAINCOMPLETE" && (
              <ButtonWithConfirm
                borderWidth={1}
                colorScheme={"red"}
                type="button"
                askForReason
                leftIcon={<FiX />}
                confirmText="Reject"
                onReasonSubmit={async (text: string) => {
                  try {
                    await addCandidateReviewCommentApi({
                      text,
                      referredCandidateId: id,
                    });
                  } catch (e) {
                    toast({
                      title: "Error while saving",
                      description: "Please try again",
                    });
                  }
                }}
                onClick={handleSubmit(onReject, onFormError)}
              >
                Incomplete
              </ButtonWithConfirm>
            )}
          </Flex>
        )}
      </form>

      <Box mb={6}>
        <Text textStyle="h3" mb={2}>
          <EditableInput
            defaultValue="Experience"
            isHeading
            value="Experience"
            onChange={(value) => {}}
          ></EditableInput>
        </Text>
        <ExperienceList
          shouldValidate={true}
          experience={resume.experience}
          onAdd={() => {}}
          onSave={async (experience) => {
            const data = await updateReferCandidate(id, {
              experience,
            });
            onUpdate(data);
          }}
        />
      </Box>
      <Box mb={6}>
        <Text textStyle="h3" mb={2}>
          <EditableInput
            defaultValue="Education"
            isHeading
            value="Education"
            onChange={(value) => {}}
          ></EditableInput>
        </Text>
        <EducationList
          shouldValidate={true}
          education={resume.education}
          onAdd={() => {}}
          onSave={async (education) => {
            const data = await updateReferCandidate(id, {
              education,
            });
            onUpdate(data);
          }}
        />
      </Box>
      <Box mb={6}>
        <Text mb={2} textStyle="h3">
          <EditableInput
            defaultValue="Projects"
            isHeading
            value="Projects"
            onChange={(value) => {}}
          ></EditableInput>
        </Text>
        <ProjectList
          shouldValidate={true}
          projects={resume.projects}
          projectCompanies={(resume.experience || [])
            .filter((ex) => ex?.company?.id && ex?.company?.name && ex?.id)
            .map((ex) => ({
              refId: ex.id,
              refType: "company",
              id: ex.company.id,
              originalName: ex.company.name,
              name: ex.company.name + "-" + ex?.title?.name,
              isCreatable: ex.company.isCreatable,
            }))
            .concat(
              (resume.education || [])
                .filter((ex) => ex?.college?.id && ex?.college?.name && ex?.id)
                .map((ex) => ({
                  refId: ex.id,
                  refType: "college",
                  id: ex.college.id,
                  name: ex.college.name + "-" + ex?.course?.name,
                  originalName: ex.college.name,
                  isCreatable: ex?.college?.isCreatable,
                }))
            )}
          onAdd={() => {}}
          onSave={async (projects) => {
            const data = await updateReferCandidate(id, {
              projects,
            });
            onUpdate(data);
          }}
        />
      </Box>
      <Box mb={6}>
        <Text textStyle="h3" mb={2}>
          <EditableInput
            defaultValue="Work Showcase Links"
            isHeading
            value="Work Showcase Links"
            onChange={(value) => {}}
          ></EditableInput>
        </Text>
        <WorkShowcaseList
          workShowcases={resume.workShowcases}
          onAdd={() => {}}
          onSave={async (workShowcases) => {
            const data = await updateReferCandidate(id, {
              workShowcases,
            });
            onUpdate(data);
          }}
        />
      </Box>
      <Box mb={6}>
        <Text textStyle="h3" mb={2}>
          <EditableInput
            defaultValue="Skills"
            isHeading
            value="Skills"
            onChange={(value) => {}}
          ></EditableInput>
        </Text>
        <SkillList
          skills={resume.skills}
          onAdd={() => {}}
          onSave={async (skills) => {
            const data = await updateReferCandidate(id, {
              skills,
            });
            onUpdate(data);
          }}
        />
      </Box>
    </Box>
  );
};

export const ResumePreviews = ({
  linkedInResumeUrl,
  resumeUrl,
}: {
  linkedInResumeUrl?: string;
  resumeUrl?: string;
}) => {
  return (
    <Tabs variant="enclosed" width="100%">
      <TabList>
        <Tab>LinkedIn</Tab>
        <Tab>Resume</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Box width="100%">
            {linkedInResumeUrl && (
              <PDFViewer source={linkedInResumeUrl} showDownloadLink />
            )}
          </Box>
        </TabPanel>
        <TabPanel>
          {resumeUrl && <PDFViewer source={resumeUrl} showDownloadLink />}
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export const CandidateDataVerification = () => {
  const [value, setValue] = useState<ResumeProps>(resume);
  const [isFetching, setIsFetching] = useState(false);
  const [referredCandidate, setReferredCandidate] = useState<
    ReferredCandidateProps | undefined
  >();
  const { verificationId: id }: any = useParams();

  const onReferredCandidateUpdate = (referredCandidate: any) => {
    const {
      firstName,
      lastName,
      email,
      phone,
      careerSummary,
      experience,
      education,
      projects,
      skills,
      workShowcases,
    } = referredCandidate;
    setValue({
      ...value,
      ...{
        firstName,
        lastName,
        email,
        phone,
        careerSummary,
        experience: experience || [],
        education: education || [],
        skills: skills || [],
        projects: projects || [],
        workShowcases: workShowcases || [],
      },
    });
    setReferredCandidate(referredCandidate);
  };

  const fetchReferredCandidate = async (id: string) => {
    setIsFetching(true);
    try {
      const referredCandidate = await getReferCandidate(id);
      onReferredCandidateUpdate(referredCandidate);
    } catch (e) {}
    setIsFetching(false);
  };

  const { status } = referredCandidate || {};
  useEffect(() => {
    fetchReferredCandidate(id);
  }, []);

  if (isFetching) {
    return (
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        height="100vh"
        width="100%"
      >
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box pb={10}>
      <Box>
        <Box
          p={4}
          borderRightWidth={1}
          borderColor={"gray.200"}
          marginRight={2}
          width="45%"
          display={"inline-block"}
          verticalAlign={"top"}
        >
          <CandidateView
            id={id}
            resume={value}
            onUpdate={onReferredCandidateUpdate}
            status={status}
          />
        </Box>
        <Box p={4} width="45%" display={"inline-block"} verticalAlign={"top"}>
          <Box
            position="fixed"
            top={"60px"}
            bottom={0}
            overflow={"scroll"}
            mb="80px"
          >
            <ResumePreviews
              linkedInResumeUrl={referredCandidate?.linkedInResumeUrl}
              resumeUrl={referredCandidate?.resumeUrl}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
