import React from "react";
import { styled } from "baseui";
import { LabelMedium } from "baseui/typography";

const Description = styled("div", {
  color: "#999999",
});

const Wrapper = styled("div", {
  paddingBottom: "16px",
});

const ItemSummary = (props) => (
  <Wrapper>
    <LabelMedium>{props.title}</LabelMedium>
    <Description>{props.description}</Description>
  </Wrapper>
);

export default ItemSummary;
