import { combineReducers } from "redux";
import theme from "./theme";
import user from "./user";
import candidate from "./candidate";
import talentscout from "./talentscout";
import employer from "./employer";
import workspace from "./workspace";
import workspaceMember from "./workspaceMember";
import widget from "./widget";
import wallet from "./wallet";

export default combineReducers({
  theme,
  user,
  candidate,
  employer,
  talentscout,
  workspace,
  workspaceMember,
  widget,
  wallet
});
