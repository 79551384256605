import React, { useState, useRef, useEffect, Suspense } from "react";
import Modal from "../components/Modal";
import Title from "../components/Title";
import Button from "../components/Button";
import firebase from "firebase";
import { toaster } from "baseui/toast";
import { ModalHeader, ModalBody } from "baseui/modal";
import { setWidgetState } from "../redux/actions/widget";
import { setUser } from "../redux/actions/user";
import { connect } from "react-redux";
import { shareEndpoint } from "../api/endpoints";
import { Stage, Layer, Rect, Text, Image } from "react-konva";
import useImage from "use-image";
import { EmailShareButton, LinkedinShareButton } from "react-share";
import { SIZE } from "baseui/modal";
import { updateCandidateShareImage } from "../api/candidate";
import { getCurrencyWords } from "../util/currency";
import { KIND, StyledLoadingSpinner } from "baseui/button";
import { Icon } from "../components/Icon";
const LazyResumePDFDownload = React.lazy(() =>
  import("../components/ResumePDFDownload")
);

const LogoImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/logo_dark.svg",
    "Anonymous"
  );
  return (
    <Image image={image} height={50} width={141} x={props.x} y={props.y} />
  );
};

const UserImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/share_cd_v1.svg",
    "Anonymous"
  );
  return (
    <Image image={image} height={280} width={240} x={props.x} y={props.y} />
  );
};

const JobTitleImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/title.svg",
    "Anonymous"
  );
  return <Image image={image} height={20} width={20} x={props.x} y={props.y} />;
};

const CompanyImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/company.svg",
    "Anonymous"
  );
  return <Image image={image} height={16} width={16} x={props.x} y={props.y} />;
};

const CollegeImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/college.svg",
    "Anonymous"
  );
  return <Image image={image} height={16} width={16} x={props.x} y={props.y} />;
};

const LocationImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/location.svg",
    "Anonymous"
  );
  return <Image image={image} height={16} width={16} x={props.x} y={props.y} />;
};

const SalaryImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/salary.svg",
    "Anonymous"
  );
  return <Image image={image} height={16} width={20} x={props.x} y={props.y} />;
};

const SkillsImage = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/skills.svg",
    "Anonymous"
  );
  return <Image image={image} height={16} width={16} x={props.x} y={props.y} />;
};

const ImageBackground = (props) => {
  const [image] = useImage(
    "https://storage.googleapis.com/quickhire-stage/assets/share/bg_cd_v1.jpg",
    "Anonymous"
  );
  return (
    <Image
      image={image}
      height={props.height}
      width={props.width}
      x={props.x}
      y={props.y}
    />
  );
};

const overflowText = (text, len) => {
  if (!text) return "";
  if (text.length > len) {
    return text.substring(0, len) + "...";
  }
  return text;
};

const ShareCandidateModel = (props) => {
  const [isUploading, setIsUploading] = useState(false);
  const [shareUrl, setShareUrl] = useState(props.shareCandidateImageUrl);
  const stageRef = useRef(null);

  useEffect(() => {
    setShareUrl(props.shareCandidateImageUrl);
  }, [props.shareCandidateImageUrl]);

  const uploadPicture = async () => {
    if (shareUrl) return;
    var dataURL = stageRef.current.toDataURL({ pixelRatio: 2 });
    var storageRef = firebase.storage().ref();
    try {
      setIsUploading(true);
      let uploadSnapshot = await storageRef
        .child(
          "share_pictures/" +
            props.shareCandidate.userId +
            "_" +
            Date.now() +
            ".jpg"
        )
        .putString(dataURL, "data_url");
      let shareUrl = await uploadSnapshot.ref.getDownloadURL();
      await updateCandidateShareImage(props.shareCandidate.userId, shareUrl);
      setShareUrl(shareUrl);
      setIsUploading(false);
    } catch (e) {
      console.log(e);
      setIsUploading(false);
      showUploadError();
    }
  };

  const showUploadError = () => {
    toaster.negative(
      <div>Unable to create the share content. Please try again.</div>,
      {
        autoHideDuration: 3000,
      }
    );
  };

  const shareCandidate = props.shareCandidate;
  if (!shareCandidate) {
    return null;
  }

  let expInYears = parseInt(shareCandidate.totalExperience / 365);
  let expInYearsText = "";
  if (expInYears === 1) {
    expInYearsText = "- " + expInYears + "Y Exp";
  } else if (expInYears > 1) {
    expInYearsText = "- " + expInYears + "Y Exp";
  }

  let midSectionHeight = 100;
  return (
    <Modal
      animate
      autoFocus
      isOpen={props.isShareCandidateModelVisible}
      closeable
      size={SIZE.auto}
      onClose={() => {
        props.setWidgetState({
          isShareCandidateModelVisible: false,
        });
      }}
    >
      <ModalHeader>
        <Title>{"Share Anonymous Profile"}</Title>
        <div style={{ color: "#919191", fontSize: "12px", marginTop: "-12px" }}>
          This helps to get more interviews for your candidate
        </div>
      </ModalHeader>
      <ModalBody>
        <div>
          {shareUrl ? (
            <img src={shareUrl} width={625} height={327} alt={""} />
          ) : (
            <Stage ref={stageRef} width={625} height={327}>
              <Layer>
                <Rect x={0} y={0} width={625} height={327} fill="#067642" />
                <ImageBackground x={0} y={0} width={625} height={327} />
                <UserImage x={-20} y={20} />
                <Text
                  fill={"#FFFFFF80"}
                  text="Interview this candidate ?"
                  fontFamily={"Lato"}
                  x={40}
                  y={30}
                  fontSize={28}
                  fontStyle={"700"}
                />

                <JobTitleImage x={200} y={midSectionHeight} />
                <Text
                  text={overflowText(
                    (shareCandidate.lastExperienceTitle || "Fresher") +
                      expInYearsText,
                    35
                  )}
                  fontFamily={"Lato"}
                  fill={"#FFFFFF"}
                  x={230}
                  y={midSectionHeight + 2}
                  fontSize={20}
                  fontStyle={"600"}
                />
                {shareCandidate.experience.length ? (
                  <>
                    {(midSectionHeight = midSectionHeight + 30) && null}
                    <CompanyImage x={202} y={midSectionHeight} />
                    <Text
                      fill={"#FFFFFFA0"}
                      text={shareCandidate.experience[0].company.name}
                      x={230}
                      y={midSectionHeight + 2}
                      fontFamily={"Lato"}
                      fontSize={16}
                    />
                  </>
                ) : null}
                {shareCandidate.education.length ? (
                  <>
                    {(midSectionHeight = midSectionHeight + 25) && null}
                    <CollegeImage x={202} y={midSectionHeight} />
                    <Text
                      fill={"#FFFFFFA0"}
                      text={
                        shareCandidate.education[0].college.name ||
                        "Company hidden"
                      }
                      x={230}
                      y={midSectionHeight + 2}
                      fontFamily={"Lato"}
                      fontSize={16}
                    />
                  </>
                ) : null}
                {shareCandidate.skills.length ? (
                  <>
                    {(midSectionHeight = midSectionHeight + 25) && null}
                    <SkillsImage x={202} y={midSectionHeight} />
                    <Text
                      fill={"#FFFFFFA0"}
                      text={overflowText(
                        shareCandidate.skills
                          .slice(0, 4)
                          .map((sk) => sk.name)
                          .join(", "),
                        40
                      )}
                      x={230}
                      y={midSectionHeight + 2}
                      fontFamily={"Lato"}
                      fontSize={16}
                    />
                  </>
                ) : null}
                {shareCandidate.jobLocations.length ? (
                  <>
                    {(midSectionHeight = midSectionHeight + 25) && null}
                    <LocationImage x={202} y={midSectionHeight} />
                    <Text
                      fill={"#FFFFFFA0"}
                      text={shareCandidate.jobLocations[0].name}
                      x={230}
                      y={midSectionHeight + 2}
                      fontFamily={"Lato"}
                      fontSize={16}
                    />
                  </>
                ) : null}
                {(midSectionHeight = midSectionHeight + 25) && null}
                <SalaryImage x={202} y={midSectionHeight} />
                <Text
                  fill={"#FFFFFF"}
                  text={
                    shareCandidate.expectedCTCCurrency +
                    " " +
                    getCurrencyWords(shareCandidate.expectedCTC)
                  }
                  x={230}
                  fontFamily={"Lato"}
                  fontStyle={"600"}
                  y={midSectionHeight + 2}
                  fontSize={16}
                />
                <Text
                  fill={"#FFFFFF80"}
                  text="Book an interview now!"
                  x={40}
                  y={280}
                  fontSize={20}
                  fontStyle={"600"}
                  fontFamily={"Lato"}
                />
                <LogoImage x={445} y={255} />
              </Layer>
            </Stage>
          )}
        </div>
        <div>
          <div
            style={{
              padding: "32px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ marginBottom: "8px" }}>
              {/* <Button
                startEnhancer={<Icon size={16} name="download" />}
                buttonStyle={{
                  width: "220px",
                  border: "1px solid black",
                  boxShadow: "none",
                  justifyContent: "flex-start",
                }}
                kind={KIND.tertiary}
              >
                <Suspense fallback={<div>Loading...</div>}>
                  <LazyResumePDFDownload
                    title={"Download Resume"}
                    isAnonymous={true}
                    textColor={"#333333"}
                    candidate={props.shareCandidate}
                  />
                </Suspense>
              </Button> */}
            </div>
            <div>
              <div>
                <LinkedinShareButton
                  beforeOnClick={uploadPicture}
                  url={
                    shareEndpoint +
                    "/api/share/candidate/" +
                    shareCandidate.userId
                  }
                  className="react-share-button"
                >
                  <div
                    style={{
                      width: "220px",
                      padding: "8px 24px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      border: "1px solid #222",
                      borderRadius: "4px",
                      marginBottom: "8px",
                    }}
                  >
                    <Icon size={16} name="linkedin" />
                    <div style={{ marginLeft: "16px" }}>Share on linkedin</div>
                  </div>
                </LinkedinShareButton>
              </div>
              <div>
                <EmailShareButton
                  beforeOnClick={uploadPicture}
                  url={""}
                  body={
                    "Would you like to interview this Premium Candidate ? \n\n " +
                    "\n" +
                    shareCandidate.lastExperienceTitle +
                    " " +
                    expInYearsText +
                    "\n" +
                    (shareCandidate.experience.length
                      ? shareCandidate.experience[0].company.name
                      : "") +
                    "\n" +
                    (shareCandidate.education.length
                      ? shareCandidate.education[0].college.name
                      : "") +
                    "\n" +
                    (shareCandidate.jobLocations.length
                      ? shareCandidate.jobLocations[0].name
                      : "") +
                    "\n" +
                    shareCandidate.expectedCTCCurrency +
                    " " +
                    getCurrencyWords(shareCandidate.expectedCTC) +
                    "\n\n" +
                    "Book Interview now!" +
                    "\n" +
                    "Link: " +
                    shareEndpoint +
                    "/api/share/candidate/" +
                    shareCandidate.userId
                  }
                  subject={
                    shareCandidate.lastExperienceTitle +
                    " " +
                    expInYearsText +
                    " - " +
                    "Interview this candidate"
                  }
                  className={"react-share-button"}
                >
                  <div
                    style={{
                      width: "220px",
                      padding: "8px 24px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      border: "1px solid #222",
                      borderRadius: "4px",
                      marginBottom: "8px",
                    }}
                  >
                    <Icon size={16} name="mail" />
                    <div style={{ marginLeft: "16px" }}>Share on email</div>
                  </div>
                </EmailShareButton>
              </div>
            </div>
            {
              <div
                style={{
                  border: "1px solid #e1e1e1",
                  borderRadius: "4px",
                  padding: "8px",
                  display: "flex",
                  background: "#f5f5f5",
                }}
              >
                <div style={{ marginRight: "8px", color: "#919191" }}>
                  Copy Link:
                </div>
                <div>
                  {shareEndpoint +
                    "/api/share/candidate/" +
                    shareCandidate.userId}
                </div>
              </div>
            }
            <div style={{ height: "32px" }}>
              {isUploading ? (
                <div>
                  Creating share content <StyledLoadingSpinner />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default connect(
  (state) => ({
    user: state.user,
    isShareCandidateModelVisible: state.widget.isShareCandidateModelVisible,
    shareCandidate: state.widget.shareCandidate,
    shareCandidateImageUrl: state.widget.shareCandidateImageUrl,
  }),
  { setUser, setWidgetState }
)(ShareCandidateModel);

// <FacebookShareButton
//   url={
//     shareEndpoint + "/api/share/candidate/" + shareCandidate.userId
//   }
//   quote={"Interview candidate"}
//   className="react-share-button"
// >
//   <div
//     style={{
//       width: "220px",
//       padding: "6px 12px",
//       display: "flex",
//       alignItems: "center",
//       cursor: "pointer",
//       border: "1px solid #222",
//       borderRadius: "4px",
//       marginBottom: "8px",
//     }}
//   >
//     <img
//       alt={""}
//       height={"16px"}
//       src={
//         "https://storage.googleapis.com/quickhire-stage/assets/icons/facebook.svg"
//       }
//     ></img>
//     <div style={{ marginLeft: "16px" }}>
//                         Share on facebook
//                       </div>
//                     </div>
//                   </FacebookShareButton>
