import React from "react";
import PageHeader from "../components/Header";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setUser } from "../redux/actions/user";
import { updateUserRole } from "../api/user";
import Button from "../components/Button";
import {
  CANDIDATE_DASHBOARD,
  EMPLOYER_POST_JOB,
  TALENTSCOUT_MY_CANDIDATES,
} from "../util/routes";
import Title from "../components/Title";
import * as Analytics from "../analytics";
import Card from "../components/Card";
import { Block } from "baseui/block";
import { LabelMedium, ParagraphSmall } from "baseui/typography";

class UserRoleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: "",
    };
  }

  componentDidMount() {
    Analytics.logEvent("page view user role select");
  }

  async onRoleSelect(role) {
    Analytics.logEvent("click select user role", {
      role: role,
    });
    this.setState({
      error: false,
      loading: role,
    });
    try {
      await updateUserRole({
        id: this.props.user.id,
        role,
      });
      this.props.setUser({
        role,
      });
      this.setState({
        error: false,
        loading: "",
      });
      if (role === "CANDIDATE") {
        this.props.history.push(CANDIDATE_DASHBOARD);
      } else if (role === "EMPLOYER") {
        this.props.history.push(EMPLOYER_POST_JOB);
      } else {
        this.props.history.push(TALENTSCOUT_MY_CANDIDATES);
      }
    } catch (e) {
      this.setState({
        error: true,
        loading: "",
      });
    }
  }

  render() {
    let props = this.props;
    return (
      <div style={{ maxWidth: "1170px", margin: "auto" }}>
        <div
          style={{
            height: "100vh",
            minHeight: "100%",
            paddingLeft: 32,
            paddingRight: 32,
            paddingTop: 16,
            margin: "auto",
            marginTop: "64px",
            maxWidth: 520,
          }}
        >
          <Title>Select your role</Title>
          <Block $marginBottom="scale400">
            <div>What would you like to do ?</div>
          </Block>
          <Block $marginBottom="scale400">
            <Card isClickable>
              <LabelMedium>Premium Candidate</LabelMedium>
              <ParagraphSmall>
                First, get the offer, then start interviewing
              </ParagraphSmall>
              <br />
              <Button
                isLoading={this.state.loading === "CANDIDATE"}
                onClick={async () => {
                  await this.onRoleSelect("CANDIDATE");
                }}
                type="primary"
              >
                Select
              </Button>
            </Card>
          </Block>
          <Block $marginBottom="scale400">
            <Card isClickable>
              <LabelMedium>Talent-Scout</LabelMedium>
              <ParagraphSmall>
                Get paid for setting up job interviews
              </ParagraphSmall>
              <br />
              <Button
                isLoading={this.state.loading === "TALENTSCOUT"}
                type="primary"
                onClick={async () => {
                  await this.onRoleSelect("TALENTSCOUT");
                }}
              >
                Select
              </Button>
            </Card>
          </Block>
          <Block $marginBottom="scale400">
            <Card isClickable>
              <LabelMedium>Employer</LabelMedium>
              <ParagraphSmall>
                Interview a pre-screened premium candidate
              </ParagraphSmall>
              <br />
              <Button
                isLoading={this.state.loading === "EMPLOYER"}
                type="primary"
                onClick={async () => {
                  await this.onRoleSelect("EMPLOYER");
                }}
              >
                Select
              </Button>
            </Card>
          </Block>
        </div>
        <PageHeader
          notLoggedIn
          role={this.role}
          user={props.user}
          setUser={props.setUser}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(UserRoleSelect));
