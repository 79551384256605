import React from "react";
import UserImage from "./UserImage";
import ChevronDown from "baseui/icon/chevron-down";
import { LabelSmall, ParagraphXSmall } from "baseui/typography";

const getUserRole = (role) => {
  switch (role) {
    case "CANDIDATE":
      return "Candidate";
    case "EMPLOYER":
      return "Employer";
    case "TALENTSCOUT":
      return "Talent-Scout";
    default:
      return role;
  }
};

const UserSection = (props) => (
  <div
    style={{
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
      boxSizing: "border-box",
      whiteSpace: "nowrap",
    }}
  >
    <div
      style={{
        overflow: "hidden",
        width: "40px",
        height: "40px",
        marginRight: "16px",
      }}
      onClick={() => {}}
    >
      <UserImage height={40} width={40} size={"40px"} user={props.user} />
    </div>

    <div
      style={{
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <LabelSmall>{props.user.name || ""}</LabelSmall>
      <ParagraphXSmall>{getUserRole(props.user.role || "")}</ParagraphXSmall>
    </div>
    <ChevronDown size={24} />
  </div>
);

export default UserSection;
