import React from "react";
import Spacing from "../../components/Spacing";
import TextArea from "../../components/TextArea";
import { searchEntity } from "../../api/entity";
import EmptyCaption from "../../components/EmptyCaption";
import Select from "../../components/Select";
import Title from "../../components/Title";
import { FormControl } from "baseui/form-control";
import Label from "../../components/Label";
import { CenterContainer } from "../../components/CenterContainer";
import Card from "../../components/Card";

class UploadedCandidateSkills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      isFetching: true,
      isAdding: false,
      isSaving: false,
      mode: "ADD",
      modalProps: {},
      editId: "",
      skills: props.skills || [],
      careerSummary: props.careerSummary || "",
      idealNextJob: "",
      values: {
        company: "",
        briefIntro: "",
      },
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      skills: nextProps.skills || [],
      careerSummary: nextProps.careerSummary || "",
    };
  }

  updateFormErrors(id, error) {
    this.setState({
      errors: { ...this.state.errors, ...{ [id]: error } },
    });
  }

  updateFormValues(id, value) {
    this.setState({
      values: { ...this.state.values, ...{ [id]: value } },
    });
  }

  validate(id, validations = {}) {
    if (validations.required && typeof this.state.values[id] === "undefined") {
      return "Required field";
    }
    if (
      validations.requiredArray &&
      (!this.state.values[id] || !this.state.values[id].length)
    ) {
      return "Required field";
    }
    if (
      validations.requiredSelect &&
      (!this.state.values[id] || !this.state.values[id].id)
    ) {
      return "Required field";
    }
  }

  getValidations(id) {
    switch (id) {
      case "idealNextJob":
        return { required: true };
      default:
        return {};
    }
  }

  //   async componentDidMount() {
  //     Analytics.logEvent("[C] page view profile profile");
  //     this.setState({
  //       isFetching: true,
  //     });
  //     try {
  //       let { careerSummary, idealNextJob, skills } =
  //         (await getCandidateProjection(this.props.user.id, "profile")) || {};
  //       this.setState({
  //         careerSummary,
  //         idealNextJob,
  //         skills: skills || [],
  //       });
  //       this.setState({
  //         isFetching: false,
  //       });
  //     } catch (ex) {
  //       this.setState({
  //         isFetching: false,
  //       });
  //       console.log(ex);
  //     }
  //   }

  render() {
    return (
      <CenterContainer>
        <Title>Profile</Title>
        <Card>
          <FormControl
            label={
              <Label
                subtitle={
                  "The shortest way someone will introduce you to your next hiring manager"
                }
              >
                {"Add a short profile summary"}
              </Label>
            }
            caption={<EmptyCaption />}
            error={this.state.careerSummaryError}
          >
            <TextArea
              placeholder={""}
              resize
              onChange={(e) => {
                if (typeof e.target.value !== "undefined") {
                  this.setState({
                    careerSummary: e.target.value,
                  });
                  this.props.updateCareerSummary(e.target.value);
                }
              }}
              value={this.state.careerSummary}
              autoComplete={"off"}
            />
          </FormControl>

          {/* <FormControl
            caption={<EmptyCaption />}
            label={
              <Label>
                {"Describe what are you looking for in your next ideal job?"}
              </Label>
            }
            error={this.state.idealNextJobError}
          >
            <TextArea
              resize
              placeholder={""}
              onChange={(e) => {
                if (typeof e.target.value !== "undefined") {
                  this.setState({
                    idealNextJob: e.target.value.substring(0, 500),
                  });
                }
              }}
              value={this.state.idealNextJob}
              autoComplete={"off"}
            />
          </FormControl> */}

          <FormControl
            label={
              <Label
                appendText={
                  "(" + (this.state.skills || "").length + "/10 skills)"
                }
              >
                {"Add your skills"}
              </Label>
            }
            caption={<EmptyCaption />}
            error={this.state.skillsError}
          >
            <Select
              creatable
              multi
              getOptions={async (query) => searchEntity("skill", query)}
              onChange={(value) => {
                if ((value || []).length > 10) {
                  return;
                }
                this.setState({ skills: value });
                this.props.updateSkills(value);
              }}
              value={this.state.skills}
            />
          </FormControl>

          <Spacing size={4} />
        </Card>
      </CenterContainer>
    );
  }
}

export default UploadedCandidateSkills;

// <FormControl
//                 label={<Label>{"Number of people in the project"}</Label>}
//               >
//                 <Input
//                   id={"teamSize"}
//                   onChange={e =>
//                     this.updateFormValues("teamSize", +e.target.value)
//                   }
//                   value={this.state.values["teamSize"]}
//                   autoComplete={"off"}
//                   endEnhancer={
//                     <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
//                       team members
//                     </span>
//                   }
//                   type={"number"}
//                   min={"0"}
//                   max={"100000"}
//                   onBlur={e => {
//                     let value = parseInt(e.target.value + "");
//                     if (!value) {
//                       value = 0;
//                     }
//                     this.setState({
//                       values: {
//                         ...this.state.values,
//                         ...{
//                           teamSize: value
//                         }
//                       }
//                     });
//                   }}
//                 />
//               </FormControl>
//               <FormControl label={<Label>{"Your role in the project"}</Label>}>
//                 <TextArea
//                   id={"projectRole"}
//                   onChange={e =>
//                     this.updateFormValues("projectRole", e.target.value)
//                   }
//                   value={this.state.values["projectRole"]}
//                   autoComplete={"off"}
//                 />
//               </FormControl>
//               <FormControl label={<Label>{"Outcome of the project"}</Label>}>
//                 <TextArea
//                   id={"outcome"}
//                   onChange={e =>
//                     this.updateFormValues("outcome", e.target.value)
//                   }
//                   value={this.state.values["outcome"]}
//                   autoComplete={"off"}
//                 />
//               </FormControl>
//               <FormControl label={<Label>{"Why this project mattered"}</Label>}>
//                 <TextArea
//                   id={"whyMattered"}
//                   onChange={e =>
//                     this.updateFormValues("whyMattered", e.target.value)
//                   }
//                   value={this.state.values["whyMattered"]}
//                   autoComplete={"off"}
//                 />
//               </FormControl>
//               <FormControl
//                 label={<Label>{"Skills demonstrated in this project"}</Label>}
//                 caption={<EmptyCaption />}
//                 error={this.state.errors["skills"]}
//               >
//                 <Select
//                   creatable
//                   multi
//                   getOptions={async query => searchEntity("skill", query)}
//                   onChange={value => {
//                     this.updateFormValues("skills", value);
//                   }}
//                   value={this.state.values["skills"]}
//                 />
//               </FormControl>
