import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { setUser } from "../redux/actions/user";
import { closeJob, getJobsByWorkspace } from "../api/job";
import Title from "../components/Title";
import * as Analytics from "../analytics";
import LoadingScreen from "../components/LoadingScreen";
import Pagination from "../components/Pagination";
import { EMPLOYER_POST_JOB } from "../util/routes";
import { CenterContainer } from "../components/CenterContainer";
import JobCard from "../components/employer/JobCard";
import { Block } from "baseui/block";

class RCPostedJobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      tabs: ["Active", "Expired"],
      selectedTabIndex: 0,
      page: 0,
      hasNext: false,
      hasPrev: false,
      isFetching: true,
    };
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  async componentDidMount() {
    Analytics.logEvent("[E] page view posted jobs");
    await this.onLoad();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.workspace.id !== prevProps.workspace.id) {
      await this.onLoad();
    }
  }

  async onLoad() {
    this.setState({
      isFetching: true,
    });
    try {
      let { data: jobs, hasNext } =
        (await getJobsByWorkspace(
          this.props.workspace.id,
          this.state.page,
          10
        )) || {};
      this.setState({
        jobs,
        hasNext,
        isFetching: false,
      });
    } catch (ex) {
      console.log(ex);
      this.setState({
        isFetching: false,
      });
    }
  }

  onViewCandidatesClick = (jobId) => {
    this.props.history.push("/employer/jobapplication/" + jobId + "/search");
  };

  render() {
    return (
      <CenterContainer>
        <Block marginBottom="scale400">
          <Title>Posted Jobs</Title>
        </Block>

        <div>
          {this.state.jobs.map((job) => (
            <JobCard
              job={job}
              onViewCandidates={() => {}}
              onEdit={() => {
                this.props.history.push(
                  "/employer/postedjob/" + job.id + "/details"
                );
              }}
              onDuplicate={() => {
                this.props.history.push(
                  "/employer/home/postjob?fromId=" + job.id
                );
              }}
              onShare={() => {}}
              onClose={async () => {
                try {
                  await closeJob(job.id);
                  await this.onLoad();
                } catch (e) {}
              }}
              isOpenNewWindow
              isClickable
              editJobLink={"/employer/postedjob/" + job.id + "/details"}
              viewCandidatesLink={
                "/employer/jobapplication/" + job.id + "/search"
              }
              to={"/employer/jobapplication/" + job.id + "/search"}
            />
          ))}
          {!this.state.jobs.length && !this.state.isFetching && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "100px",
              }}
            >
              <div>
                <div>
                  When you post a job (it's Free), our recruiters will source
                  candidates for you for Free. Like any Candidates? Pay Rs 1,000
                  to book and interview
                </div>
                <div>
                  <Link to={EMPLOYER_POST_JOB}>Post a job.</Link>It's Free.
                </div>
              </div>
            </div>
          )}
        </div>
        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
        <LoadingScreen show={this.state.isFetching} />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
    workspace: state.workspace,
  }),
  { setUser }
)(withRouter(RCPostedJobs));
