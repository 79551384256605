import React, { ReactNode } from "react";
import { Avatar, Box, Flex, Text } from "@chakra-ui/react";
import { DraggableProvided } from "react-beautiful-dnd";
import { Candidate } from "./CandidateFunnelTypes";

export type CandidateFunnelItemProps = {
  candidate: Candidate;
  isDragging: boolean;
  provided: DraggableProvided;
  renderItem: (candidate: Candidate) => ReactNode;
  isClone?: boolean;
  isGroupedOver?: boolean;
  style?: Object;
  index?: number;
};

const getBackgroundColor = (isDragging: boolean, isGroupedOver: boolean) => {
  if (isDragging) {
    return "yellow.50";
  }

  if (isGroupedOver) {
    return "green.300";
  }

  return "white";
};

const getBorderColor = (isDragging: boolean) =>
  isDragging ? "red.100" : "gray.300";

function getStyle(provided: DraggableProvided, style?: Object) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

export const CandidateFunnelItemC = ({
  candidate,
  isDragging,
  isGroupedOver,
  provided,
  style,
  renderItem,
  index,
}: CandidateFunnelItemProps) => {
  return (
    <Box
      as="a"
      borderRadius={"sm"}
      overflow="hidden"
      border="1px solid transparent"
      borderColor={getBorderColor(isDragging)}
      backgroundColor={getBackgroundColor(isDragging, !!isGroupedOver)}
      boxShadow={isDragging ? `1px 1px 1px gray.500` : "none"}
      boxSizing="border-box"
      marginBottom={2}
      userSelect="none"
      color={"gray.900"}
      _hover={{
        color: "gray.900",
        textDecoration: "none",
      }}
      _active={{
        color: "gray.900",
        textDecoration: "none",
      }}
      _focus={{
        outline: "none",
        borderColor: "blue.500",
        boxShadow: "none",
      }}
      display="flex"
      flexDirection={"column"}
      href={candidate.url}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style) as React.CSSProperties}
      data-is-dragging={isDragging}
      data-testid={candidate.id}
      data-index={index}
      aria-label={`${candidate.name} quote`}
    >
      {renderItem(candidate)}
    </Box>
  );
};

export const CandidateFunnelItem = React.memo<CandidateFunnelItemProps>(
  CandidateFunnelItemC
);
