import { Block } from "baseui/block";
import { LabelMedium, ParagraphMedium } from "baseui/typography";
import React, { useState, useEffect } from "react";
import { useLocation, useRouteMatch } from "react-router";
import { getTalentscoutHandPickedCandidates } from "../api/admin";
import Card from "../components/Card";
import Pagination from "../components/Pagination";
import CandidateCard from "../components/employer/CandidateCard";
import Title from "../components/Title";

export const AdminTalentscoutHandPicked = (props) => {
  const { talentscoutId } = props;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const jobId = params.get("jobId");
  const [candidates, setCandidates] = useState([]);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(0);
  const limit = 10;
  const fetchTalentscoutHandPicked = async () => {
    try {
      const { data } = await getTalentscoutHandPickedCandidates(
        talentscoutId,
        jobId,
        page,
        limit
      );
      setCandidates(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchTalentscoutHandPicked();
  }, []);

  const onNextClick = async () => {
    setPage(page + 1);
  };

  const onPrevClick = async () => {
    setPage(page - 1);
  };

  useEffect(() => {
    setHasPrev(page - 1 >= 0);
    fetchTalentscoutHandPicked();
  }, [page]);

  return (
    <Block>
      <Title marginBottom="scale600">
        <>Handpicked profiles</>
      </Title>
      {candidates.map((candidate) => {
        return <CandidateCard candidate={candidate} marginBottom="scale400" />;
      })}
      <Pagination
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        hasNext={hasNext}
        hasPrev={hasPrev}
      />
    </Block>
  );
};
