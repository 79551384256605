import api from "./index";
import { newBaseUrl } from "./endpoints";

export async function updateInterviewRequestNew(id: string, data: any) {
  return await api({
    baseUrl: newBaseUrl,
    url: `/interview_request/${id}/update`,
    method: "POST",
    data,
  });
}
