import React from "react";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { isSafari, isChrome } from "react-device-detect";

const Popover = (props) => (
  <StatefulPopover
    content={props.content}
    returnFocus
    autoFocus
    showArrow
    placement={PLACEMENT.bottomLeft}
    triggerType={props.triggerType}
    overrides={{
      Inner: {
        style: ({ $theme }) => {
          return {
            borderColor: "#dedede",
            borderTopWidth: "1px",
            borderBottomWidth: "1px",
            borderLeftWidth: "1px",
            borderRightWidth: "1px",
            padding: "8px",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            backgroundColor: isSafari || isChrome ? "#FFFFFFD3" : "#FFFFFF",
            backdropFilter: "blur(5px)",
          };
        },
      },
      Body: {
        style: ({ $theme }) => {
          return {
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            backgroundColor: "#FFFFFF00",
          };
        },
      },
      Arrow: {
        style: ({ $theme }) => {
          return {
            backgroundColor: "#FFFFFF",
          };
        },
      },
    }}
  >
    {props.children}
  </StatefulPopover>
);

export default Popover;
