import React, { useEffect, useState } from "react";
import {
  Heading,
  FormControl,
  Input,
  Box,
  Button,
  Checkbox,
  Select,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  Flex,
  FormErrorMessage,
  FormHelperText,
  useToast,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { motion } from "framer-motion";
import { AutoComplete, AutoCompleteItem } from "../../components/AutoComplete";
import { useFirebaseStorage } from "../../lib/hooks/useFirebaseStorage";
import {
  formatCurrency,
  getCurrencyWords,
  parseCurrency,
} from "../../util/currency";
import { searchEntity } from "../../api/entity";
import { addReferCandidate } from "../../api/referred_candidate";
import { useRouteMatch } from "react-router";
import { getJobById } from "../../api/job";
import { RichTextViewer } from "../../components/RichTextEditor";
import { uploadCandidate } from "../../api/talentscout";
import { getFileExtension } from "src/util/file";
import { FormLabel } from "src/lib/uikit";
import { ReferCandidateForm } from "src/lib/forms/Talentscout/ReferCandidate/ReferCandidateForm";

export type UploadCandidateProps = {
  jobId: string;
};

export type Job = {
  id: string;
  jobTitle: AutoCompleteItem;
  company: AutoCompleteItem;
  locations: AutoCompleteItem[];
  functionalAreas: AutoCompleteItem[];
  skills: AutoCompleteItem[];
  jobDescription: string;
  maxExperience: string;
  minExperience: string;
  recruiterNote?: string;
  status?: string;
};

export const UploadCandidate = () => {
  const { uploadFile } = useFirebaseStorage();
  const {
    params: { jobId },
  } = useRouteMatch<UploadCandidateProps>();
  const [isFileUploading, setIsFileUploading] = useState(false);
  // const [isUpdating, setIsUpdating] = useState(false);
  const [job, setJob] = useState<Job | null>(null);
  const user = useSelector((state: any) => state.user);
  const toast = useToast();

  const fetchJob = async (id: string) => {
    try {
      const { job: data } = await getJobById(id);
      setJob(data);
      setInitialValue({
        ...initialValue,
        skills: (data?.skills as any) || [],
        currentLocation: data?.locations?.[0],
        preferredLocations: (data?.locations as any) || [],
        jobFunctions: (data?.functionalAreas as any) || [],
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (jobId) {
      fetchJob(jobId);
    }
  }, [jobId]);
  const [initialValue, setInitialValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    countryCode: "+91",
    resumeFile: null,
    linkedInUrl: "",
    linkedInResumeFile: null,
    currentLocation: null,
    jobFunctions: [],
    preferredLocations: [],
    skills: [],
    currentSalary: 0,
    currentSalaryCurrency: "INR",
    expectedSalary: 0,
    expectedSalaryCurrency: "INR",
    noticePeriod: 0,
    isResigned: false,
    lastWorkingDate: Date.now(),
    isStartupReady: false,
    isRemoteReady: false,
    hasJobOffers: false,
    jobOffer: null,
  });
  // const initialValue = {

  // };
  const onSubmit = async (values: any) => {
    // const linkedInResumeFile = (values.linkedInResumeFile as unknown) as File;
    // const linkedInResumeFileExt = getFileExtension(linkedInResumeFile);
    // const resumeFile = (values.resumeFile as unknown) as File;
    // const resumeFileExt = getFileExtension(resumeFile);
    // setIsFileUploading(true);
    // let linkedInResumeFileUrl = "";
    // let resumeFileUrl = "";
    // try {
    //   linkedInResumeFileUrl = await uploadFile(
    //     `/resumes/${user.id}_${Date.now()}.${linkedInResumeFileExt}`,
    //     linkedInResumeFile
    //   );
    //   resumeFileUrl = await uploadFile(
    //     `/resumes/${user.id}_${Date.now()}.${resumeFileExt}`,
    //     resumeFile
    //   );
    //   setIsFileUploading(false);
    // } catch (e) {
    //   toast({
    //     title: "Error while uploading",
    //     description: "Please try again",
    //     status: "error",
    //     duration: 3000,
    //     isClosable: true,
    //   });
    //   setIsFileUploading(false);
    //   return;
    // }

    // setIsUpdating(true);
    // try {
    const body = {
      jobId,
      talentscoutId: user.id,
      ...values,
      noticePeriod: +values.noticePeriod,
    };
    await uploadCandidate({
      talentscoutId: user.id,
      jobId: jobId,
      candidateEmail: values.email && values.email.trim().toLowerCase(),
      candidateLinkedInProfile: values.linkedInUrl && values.linkedInUrl.trim(),
      candidatePhone: values.phone && values.phone.trim(),
      candidatePhonePrefix: {
        label: "India (भारत)",
        id: "IN",
        dialCode: "+91",
      },
      candidateCTC: values.expectedSalary,
      candidateCTCCurrency: "INR",
      candidateCurrentCTC: values.currentSalary,
      candidateCurrentCTCCurrency: "INR",
      candidateResumeURL: values.resumeFileUrl,
      candidateNoticePeriod: +values.noticePeriod,
      candidateJobFunctions: values.jobFunctions,
      candidateIdealNextJob: "",
      candidateSkills: values.skills,
      candidateStartupTypes: values.isStartupReady
        ? ["EARLY_STAGE", "FUNDED_STARTUPS"]
        : [],
      candidateRemoteReady: values.isRemoteReady ? 1 : 0,
      candidateLocations: values.preferredLocations,
    });
    await addReferCandidate(body);
    toast({
      title: "Saved successfully",
      status: "success",
      duration: 4000,
      isClosable: true,
    });
    if (jobId) {
      fetchJob(jobId);
    }
    // } catch (e) {
    //   throw e;
    // }
    // setIsUpdating(false);
  };

  if (!user) return null;
  return (
    <Box p={4}>
      <Text textStyle="h3" mb={4}>
        Refer Candidate
      </Text>
      <Flex flexWrap="wrap">
        <Box
          width="460px"
          mr={12}
          pr={12}
          borderRight="1px"
          borderColor="gray.100"
          bg="white"
          p={4}
          borderRadius="md"
        >
          <ReferCandidateForm
            onSubmit={onSubmit}
            resetOnSubmit={true}
            values={initialValue}
            // isUpdating={isUpdating}
            submitText="Send Verification Email"
          />
        </Box>
        {job && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <Box maxW={"520px"}>
              <Heading as="h4" size="md" mb={4} mt={8}>
                {job.company.name}
              </Heading>
              <Flex mb={2} justifyContent="space-between">
                <Stat size="sm">
                  <StatLabel>Experience</StatLabel>
                  <StatNumber>{`${job.minExperience}-${job.maxExperience} Years`}</StatNumber>
                </Stat>
                <Stat size="sm">
                  <StatLabel>Job Title</StatLabel>
                  <StatNumber>{job.jobTitle.name}</StatNumber>
                </Stat>
                <Stat size="sm">
                  <StatLabel>Location</StatLabel>
                  <StatNumber>
                    {job.locations.map((loc) => loc.name).join(",")}
                  </StatNumber>
                </Stat>
              </Flex>
              <Flex mb={4} justifyContent="space-between">
                <Stat size="sm">
                  <StatLabel>Functional Areas</StatLabel>
                  <StatNumber>
                    {(job.functionalAreas || []).map((fa) => fa.name).join(",")}
                  </StatNumber>
                </Stat>
                <Stat size="sm">
                  <StatLabel>Skills</StatLabel>
                  <StatNumber>
                    {job.skills.map((sk) => sk.name).join(",")}
                  </StatNumber>
                </Stat>
              </Flex>
              {job.recruiterNote && (
                <RichTextViewer value={job.recruiterNote} />
              )}
              <RichTextViewer value={job.jobDescription} />
            </Box>
          </motion.div>
        )}
      </Flex>
    </Box>
  );
};

// <FormControl
// isInvalid={
//   !!(formik.touched.jobOffers && formik.errors.jobOffers)
// }
// minH={"40px"}
// >
// {/* <FormLabel htmlFor="jobOffers" fontWeight="500">
//   Add highest job offer of the candidate
// </FormLabel> */}
// <FormikProvider value={formik}>
//   <FieldArray name="jobOffers">
//     {({ insert, remove, push }) => (
//       <Box>
//         <Box borderRadius="sm" background="brand.50" mb={1}>
//           {formik
//             .getFieldProps("jobOffers")
//             .value.map((_: any, idx: number) => (
//               <Box>
//                 {/* <Flex
//                   pl={2}
//                   pr={2}
//                   justifyContent="space-between"
//                   alignItems="center"
//                   // borderBottomWidth={1}
//                   // borderColor="green.100"
//                 >
//                   <Text>{`Job Offer #${idx + 1}`}</Text>
//                   <IconButton
//                     size="sm"
//                     variant="ghost"
//                     aria-label="close"
//                     onClick={() => remove(idx)}
//                     icon={<FiX />}
//                   ></IconButton>
//                 </Flex> */}
//                 <Box p={2}>
//                   <Text color="gray.500">Company Name</Text>
//                   <AutoComplete
//                     {...formik.getFieldProps(
//                       `jobOffers[${idx}].company`
//                     )}
//                     id="currentLocation"
//                     focusBorderColor="brand.500"
//                     color="gray.400"
//                     placeholder="Enter company"
//                     items={[]}
//                     fetchItems={async (q) =>
//                       searchEntity("company", q)
//                     }
//                     selected={
//                       formik.getFieldProps(
//                         `jobOffers[${idx}].company`
//                       ).value
//                         ? [
//                             formik.getFieldProps(
//                               `jobOffers[${idx}].company`
//                             ).value,
//                           ]
//                         : []
//                     }
//                     onItemSelect={(
//                       selected: AutoCompleteItem[]
//                     ) => {
//                       console.log({ selected });
//                       if (selected.length) {
//                         formik.setFieldValue(
//                           `jobOffers[${idx}].company`,
//                           selected[0]
//                         );
//                       } else {
//                         formik.setFieldValue(
//                           `jobOffers[${idx}].company`,
//                           null
//                         );
//                       }
//                     }}
//                   />
//                   <Text color="gray.500" mt={1}>
//                     Job Title
//                   </Text>
//                   <AutoComplete
//                     {...formik.getFieldProps(
//                       `jobOffers[${idx}].jobTitle`
//                     )}
//                     id="jobTitle"
//                     focusBorderColor="brand.500"
//                     color="gray.400"
//                     placeholder="Enter job title"
//                     items={[]}
//                     fetchItems={async (q) =>
//                       searchEntity("jobTitle", q)
//                     }
//                     selected={
//                       formik.getFieldProps(
//                         `jobOffers[${idx}].jobTitle`
//                       ).value
//                         ? [
//                             formik.getFieldProps(
//                               `jobOffers[${idx}].jobTitle`
//                             ).value,
//                           ]
//                         : []
//                     }
//                     onItemSelect={(
//                       selected: AutoCompleteItem[]
//                     ) => {
//                       console.log({ selected });
//                       if (selected.length) {
//                         formik.setFieldValue(
//                           `jobOffers[${idx}].jobTitle`,
//                           selected[0]
//                         );
//                       } else {
//                         formik.setFieldValue(
//                           `jobOffers[${idx}].jobTitle`,
//                           null
//                         );
//                       }
//                     }}
//                   />
//                   <Text color="gray.500" mt={1}>
//                     Offered Salary
//                   </Text>
//                   <Input
//                     {...formik.getFieldProps(
//                       `jobOffers[${idx}].offeredSalary`
//                     )}
//                     id="offeredSalary"
//                     color="gray.600"
//                     placeholder=""
//                     focusBorderColor="brand.500"
//                     onChange={(e) =>
//                       formik.setFieldValue(
//                         `jobOffers[${idx}].offeredSalary`,
//                         parseCurrency(e.target.value)
//                       )
//                     }
//                     value={formatCurrency(
//                       formik.getFieldProps(
//                         `jobOffers[${idx}].offeredSalary`
//                       ).value
//                     )}
//                     mt={1}
//                   />
//                 </Box>
//                 {idx !==
//                   formik.getFieldProps("jobOffers").value.length -
//                     1 && <Divider />}
//               </Box>
//             ))}
//         </Box>
//         {/* {formik.getFieldProps("jobOffers").value.length == 0 && (
//           <Button
//             type="button"
//             size="sm"
//             leftIcon={<FiPlus />}
//             onClick={() =>
//               push({
//                 company: null,
//                 offeredSalary: undefined,
//               })
//             }
//           >
//             Add the highest job offer
//           </Button>
//         )} */}
//       </Box>
//     )}
//   </FieldArray>
// </FormikProvider>
// </FormControl>
