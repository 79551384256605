import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { setUser, fetchUser } from "../redux/actions/user";
import { useHistory, withRouter } from "react-router-dom";
import Title from "../components/Title";
import { getCandidateInterviewRequests } from "../api/candidate";
import LoadingScreen from "../components/LoadingScreen";
import InterviewRequestCandidate from "../components/InterviewRequestCandidate";
import Pagination from "../components/Pagination";
import Select from "../components/Select";
import * as Analytics from "../analytics";
import Card from "../components/Card";
import Bold from "../components/Bold";
import Button from "../components/Button";
import { SIZE } from "baseui/button";
import UniqueIdModalForm from "../components/UniqueIdModalForm";
import { useStyletron } from "baseui";
import { CenterContainer } from "../components/CenterContainer";
import { CANDIDATE_PROFILE_BASIC } from "../util/routes";
import { Block } from "baseui/block";
import { LabelLarge, ParagraphMedium } from "baseui/typography";

const CandidateInterviewRequests = (props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState({ id: "", name: "All" });
  const [page, setPage] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);
  const [interviewRequests, setInterviewRequests] = useState([]);
  const [idProofModalVisible, setIdProofModalVisible] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [, theme] = useStyletron();
  const onLoad = useCallback(
    async (page) => {
      setIsFetching(true);
      try {
        let {
          data: interviewRequests,
          hasNext,
        } = await getCandidateInterviewRequests(
          props.user.id,
          page,
          10,
          selectedFilter.id
        );
        setHasNext(hasNext);
        setIsFetching(false);
        setInterviewRequests(interviewRequests);
      } catch (e) {
        setIsFetching(false);
      }
    },
    [props.user.id, selectedFilter.id]
  );

  useEffect(() => {
    Analytics.logEvent("[C] page view interview requests");
    onLoad(0);
    dispatch(fetchUser());
  }, [dispatch, onLoad]);

  const onNextClick = async () => {
    setPage(page + 1);
    setHasPrev(page + 1 > 0);
    await this.onLoad(page + 1);
  };

  const onPrevClick = async () => {
    setPage(page - 1);
    setHasPrev(page - 1 > 0);
    await this.onLoad(page - 1);
  };

  if (isFetching) {
    return (
      <CenterContainer>
        <LoadingScreen />
      </CenterContainer>
    );
  }
  const isIdProofVerified = true || !!props.user.linkedInProfile;

  return (
    <CenterContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Title>Interview Requests</Title>
        <div
          style={{
            display: "flex",
            marginBottom: "8px",
          }}
        >
          <Select
            width={"100px"}
            clearable={false}
            defaultOptions={[
              { id: "", name: "All" },
              { id: "ACTIVE", name: "Active" },
              { id: "INACTIVE", name: "Inactive" },
            ]}
            onChange={async (value) => {
              setSelectedFilter(value);
              setPage(0);
              await onLoad();
            }}
            value={selectedFilter}
          />
        </div>
      </div>

      {!isIdProofVerified && (
        <Card
          backgroundColor={theme.colors.backgroundGrey}
          marginBottom="scale600"
        >
          <LabelLarge>LinkedIn Profile Missing</LabelLarge>
          <ParagraphMedium marginBottom="scale400">
            Please provide your linkedIn url in your profile. This is only used
            to verify your details.
          </ParagraphMedium>
          <Button
            onClick={() => {
              history.push(CANDIDATE_PROFILE_BASIC);
            }}
            size={SIZE.mini}
          >
            Go to Profile
          </Button>
        </Card>
      )}

      {/* {idProofStatus === "REJECTED" && (
        <Card
          backgroundColor={theme.colors.backgroundGrey}
          marginBottom="scale600"
        >
          <Bold>PAN Card Rejected</Bold>
          <div style={{ marginBottom: "8px" }}>
            {props.user.idProof && props.user.idProof.statusReason}
          </div>
          <div>Please upload a new one below</div>
          <Button
            onClick={() => {
              setIdProofModalVisible(true);
            }}
            size={SIZE.mini}
          >
            Upload PAN Card
          </Button>
        </Card>
      )}

      {idProofStatus === "PENDING" && (
        <Card
          backgroundColor={theme.colors.backgroundGrey}
          marginBottom="scale600"
        >
          <Bold>Your PAN Card verification is pending</Bold>
          <div style={{ marginBottom: "8px" }}>
            Thank you for uploading PAN card. FindingJo is yet to verify the
            submission. You can start accepting/rejecting interviews, once the
            verification is complete. We will notify you the verification status
            within 24 hours.
          </div>
        </Card>
      )}

      {!idProofStatus && (
        <Card
          backgroundColor={theme.colors.backgroundGrey}
          marginBottom="scale600"
        >
          <Bold>Submit PAN Card as ID Proof - Pending</Bold>
          <div style={{ marginBottom: "8px" }}>
            You will require to carry your PAN card as ID proof when you go for
            the interviews with the employer. Only ID proof verified candidate
            can accept interviews on FindingJo.
          </div>
          <Button
            size={SIZE.mini}
            onClick={() => {
              setIdProofModalVisible(true);
            }}
          >
            Upload PAN Card
          </Button>
        </Card>
      )} */}

      {interviewRequests.map((interviewRequest, index) => (
        <InterviewRequestCandidate
          isClickable={isIdProofVerified}
          isDisabled={!isIdProofVerified}
          onDisabledClick={() => {
            setIdProofModalVisible(true);
          }}
          key={index}
          interviewRequest={interviewRequest}
          onUpdated={onLoad}
        />
      ))}

      {!interviewRequests.length ? (
        page === 0 && !selectedFilter.id ? (
          <div>
            <div>
              You are yet to receive an Interview Request (salary offer +
              description)
            </div>
            <div>
              Have patience. For some candidates, this process might take some
              time.
            </div>
          </div>
        ) : (
          <div>No interview requests found</div>
        )
      ) : (
        <Pagination
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          hasNext={hasNext}
          hasPrev={hasPrev}
        />
      )}
      <UniqueIdModalForm
        user={props.user}
        setUser={props.setUser}
        modalVisible={idProofModalVisible}
        onSubmit={async () => {
          await onLoad(0);
          dispatch(fetchUser());
        }}
        onClose={() => {
          setIdProofModalVisible(false);
        }}
      />
    </CenterContainer>
  );
};

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateInterviewRequests));
