import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import RouteLink from "../components/RouteLink";
import { getTSRequests } from "../api/admin";
import { styled } from "baseui";
import {
  StyledTable,
  StyledHead,
  StyledHeadCell,
  StyledBody,
  StyledRow,
  StyledCell,
} from "baseui/table";
import { prettyPrint } from "../util/time";

const CellWrapper = styled("div", {
  width: "150px",
  textOverflow: "ellipsis",
  overflow: "hidden",
});

const TableWrapper = styled("div", {
  width: "1500px",
});

const EmployerRow = (props) => (
  <StyledRow key={props.index}>
    <StyledCell>
      <CellWrapper>
        <RouteLink to={"/N0VHxB8M/admin/details/user/" + props.talentscout.id}>
          {props.talentscout.email}
        </RouteLink>
      </CellWrapper>
    </StyledCell>
    <StyledCell>
      <CellWrapper>{props.talentscout.name}</CellWrapper>
    </StyledCell>
    <StyledCell>
      <CellWrapper>{props.talentscout.phone}</CellWrapper>
    </StyledCell>
    <StyledCell>
      <CellWrapper>
        {props.candidate ? (
          <RouteLink to={"/N0VHxB8M/admin/details/user/" + props.candidate.id}>
            {props.candidate.email}
          </RouteLink>
        ) : (
          <div>{props.tsRequest.candidateEmail}</div>
        )}
      </CellWrapper>
    </StyledCell>
    <StyledCell>
      <CellWrapper>
        {props.tsRequest.type === "upload"
          ? props.tsRequest.candidatePhone
          : props.candidate.phone}
      </CellWrapper>
    </StyledCell>
    <StyledCell>
      <CellWrapper>{prettyPrint(props.tsRequest.createdAt)}</CellWrapper>
    </StyledCell>
    <StyledCell>
      <CellWrapper>{props.tsRequest.status}</CellWrapper>
    </StyledCell>
    <StyledCell>
      <CellWrapper>{props.tsRequest.candidateStatus}</CellWrapper>
    </StyledCell>
  </StyledRow>
);

class AdminTSRequestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      users: [],
      tsRequests: [],
      hasNext: false,
      hasPrev: false,
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    try {
      let {
        data: { candidates, talentscouts, tsRequests },
        hasNext,
      } = await getTSRequests(this.state.page, this.state.limit);
      this.setState({
        candidates,
        talentscouts,
        tsRequests,
        hasNext,
      });
    } catch (e) {
      console.log(e);
    }
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  render() {
    return (
      <div>
        <Title>Talentscout Requests</Title>
        <TableWrapper>
          <StyledTable>
            <StyledHead>
              <StyledHeadCell>
                <CellWrapper>TS Email</CellWrapper>
              </StyledHeadCell>
              <StyledHeadCell>
                <CellWrapper>TS Name</CellWrapper>
              </StyledHeadCell>
              <StyledHeadCell>
                <CellWrapper>TS Phone</CellWrapper>
              </StyledHeadCell>
              <StyledHeadCell>
                <CellWrapper>Candidate Email</CellWrapper>
              </StyledHeadCell>
              <StyledHeadCell>
                <CellWrapper>Candidate Phone</CellWrapper>
              </StyledHeadCell>
              <StyledHeadCell>
                <CellWrapper>Created At</CellWrapper>
              </StyledHeadCell>
              <StyledHeadCell>
                <CellWrapper>Status</CellWrapper>
              </StyledHeadCell>
              <StyledHeadCell>
                <CellWrapper>Candidate Status</CellWrapper>
              </StyledHeadCell>
            </StyledHead>
            <StyledBody>
              <div style={{ marginBottom: "32px" }}>
                {this.state.tsRequests.map(
                  (tsRequest, index) =>
                    this.state.candidates[index] &&
                    this.state.talentscouts[index] && (
                      <EmployerRow
                        key={index}
                        tsRequest={tsRequest}
                        candidate={this.state.candidates[index]}
                        talentscout={this.state.talentscouts[index]}
                      />
                    )
                )}
              </div>
            </StyledBody>
          </StyledTable>
        </TableWrapper>
        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminTSRequestList));
