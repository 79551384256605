import api from "./index";
import { baseUrl } from "./endpoints";

export async function createJob(job) {
  return await api({
    baseUrl,
    url: "/api/job/create",
    method: "POST",
    data: job,
  });
}

export async function updateJob(jobId, data) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/update",
    method: "POST",
    data: data,
  });
}

export async function closeJob(jobId) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/close",
    method: "POST",
  });
}

export async function renewJob(jobId) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/renew",
    method: "POST",
  });
}

export async function getJobs({ company, status, page, limit }) {
  return await api({
    baseUrl,
    url: "/api/job",
    method: "GET",
    params: [
      { name: "company", value: company },
      { name: "status", value: status },
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getJobsByWorkspace(workspaceId, page, limit) {
  return await api({
    baseUrl,
    url: "/api/job/workspace/" + workspaceId + "/list",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getReviewedJobs() {
  return await api({
    baseUrl,
    url: "/api/job/reviewed",
    method: "GET",
  });
}

export async function getJobById(jobId) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId,
    method: "GET",
  });
}

export async function getPublicJobById(jobId) {
  return await api({
    baseUrl,
    url: "/api/public/job/" + jobId,
    method: "GET",
  });
}

export async function applyJob(data) {
  return await api({
    baseUrl,
    url: "/api/job/apply",
    method: "POST",
    data: data,
  });
}

export async function searchJob(data) {
  return await api({
    baseUrl,
    url: "/api/job/search",
    method: "POST",
    data: data,
  });
}

export async function searchJobFacets(facet, query) {
  return await api({
    baseUrl,
    url: "/api/job/facets/search",
    method: "GET",
    params: [
      { name: "facet", value: facet },
      { name: "query", value: query },
    ],
  });
}

export async function getCandidateJobApplications(candidateId, page, limit) {
  return await api({
    baseUrl,
    url: "/api/job/applications/candidate/" + candidateId,
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getEmployerJobApplications(
  employerId,
  jobId,
  filter,
  page,
  limit
) {
  return await api({
    baseUrl,
    url: "/api/job/applications/employer/" + employerId + "/job/" + jobId,
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
      { name: "filter", value: filter },
    ],
  });
}

export async function getWorkspaceJobApplications(
  workspaceId,
  jobId,
  filter,
  page,
  limit
) {
  return await api({
    baseUrl,
    url: "/api/job/applications/workspace/" + workspaceId + "/job/" + jobId,
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
      { name: "filter", value: filter },
    ],
  });
}

export async function updateJobShareImage(jobId, shareImageUrl) {
  return await api({
    baseUrl,
    url: "/api/job/shareImage",
    method: "POST",
    data: {
      id: jobId,
      shareImageUrl,
    },
  });
}

export async function getBoughtCandidates(jobId, filter, page, limit) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/bought",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
      { name: "filter", value: filter },
    ],
  });
}

export async function getShortlistedCandidates(jobId, tags, page, limit) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/shortlisted",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
      { name: "tags", value: tags.join(",") },
    ],
  });
}

export async function addShortlistCandidate(jobId, data) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/shortlisted",
    method: "POST",
    data,
  });
}

export async function removeShortlistCandidate(jobId, anonymousId) {
  return await api({
    baseUrl,
    url:
      "/api/job/" +
      jobId +
      "/funnel/candidate/" +
      anonymousId +
      "/shortlisted/remove",
    method: "POST",
    data: {},
  });
}

export async function tagShortlistCandidate(jobId, data) {
  return await api({
    baseUrl,
    url: "/api/job/" + jobId + "/shortlisted/tag",
    method: "POST",
    data,
  });
}
