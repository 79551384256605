import React, { Suspense } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import Resume from "../components/Resume";
import { getMyCandidateDetail } from "../api/talentscout";
import LoadingScreen from "../components/LoadingScreen";
import TSMyCandidate from "../components/TSMyCandidate";
import { TALENTSCOUT_MY_CANDIDATES } from "../util/routes";
import * as Analytics from "../analytics";
import { styled } from "baseui";
import Card from "../components/Card";
import Bold from "../components/Bold";
import Entry from "../components/Entry";
import Currency from "../components/Currency";
import { convertToCandidateSearch } from "../util/common";
import Button from "../components/Button";
import { KIND } from "baseui/button";
import { Icon } from "../components/Icon";
import { CenterContainer } from "../components/CenterContainer";

const LazyResumePDFDownload = React.lazy(() =>
  import("../components/ResumePDFDownload")
);

const Highlights = styled("div", ({ $theme }) => ({
  color: $theme.colors.green,
  background: $theme.colors.lightGreen,
  borderRadius: "4px",
  padding: "8px 16px",
  marginBottom: "8px",
  marginTop: "8px",
  fontSize: "13px",
}));

// import ResumePDFDownload from "../components/ResumePDFDownload";

class TSMyCandidateDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidate: {},
      tsRequest: {},
      isFetching: true,
      isError: false,
      interviewRequests: [],
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[S] page view my candidate details");
    this.setState({
      isFetching: true,
    });
    try {
      let { myCandidateId } = this.props.match.params;
      let {
        tsRequest,
        candidate,
        interviewRequests,
      } = await getMyCandidateDetail(myCandidateId);
      this.setState({
        isFetching: false,
        candidate,
        tsRequest,
        interviewRequests,
      });
    } catch (e) {
      this.setState({
        isFetching: false,
        isError: true,
      });
      console.log(e);
    }
  }

  render() {
    if (this.state.isFetching) {
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    }
    if (this.state.isError) {
      return (
        <CenterContainer>
          <div>Errored. Please retry.....</div>
        </CenterContainer>
      );
    }
    return (
      <CenterContainer>
        <Title>Candidate Details</Title>
        {this.state.tsRequest && this.state.tsRequest.status === "APPROVED" && (
          <Highlights>
            <Bold>
              Promote your candidate to get more interviews & you will earn
              more! How?
            </Bold>
            <div>1. Email the anonymous resume to hiring managers</div>
            <div>
              2. Post the anonymous resume on your LinkedIn page using promote
              feature
            </div>
          </Highlights>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "8px 0px",
            marginBottom: "8px",
          }}
        >
          <div style={{ display: "flex", flex: 1 }}></div>
          {/* <Button
            kind={KIND.tertiary}
            startEnhancer={<Icon size={16} name="download" />}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <LazyResumePDFDownload
                isAnonymous={true}
                textColor={"#333333"}
                candidate={this.state.candidate}
              />
            </Suspense>
          </Button> */}
        </div>
        {/* <ResumePDFDownload
          textColor={"#333333"}
          candidate={this.state.candidate}
        /> */}

        <TSMyCandidate
          isExpandedStatus={true}
          candidate={convertToCandidateSearch(this.state.candidate)}
          myCandidate={this.state.tsRequest}
          onRemove={() => {
            this.props.history.push(TALENTSCOUT_MY_CANDIDATES);
          }}
        />

        {this.state.interviewRequests.length ? (
          <Card style={{ marginBottom: "16px" }}>
            <Bold>Interview Offers Received</Bold>
            {this.state.interviewRequests.map((interviewRequest, idx) => (
              <Entry
                label={"#" + (idx + 1)}
                value={
                  <Currency
                    value={interviewRequest.offeredCTC}
                    currency={interviewRequest.offeredCTCCurrency}
                  />
                }
              />
            ))}
          </Card>
        ) : null}

        <Resume isAnonymous={true} candidate={this.state.candidate} />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(TSMyCandidateDetails));
