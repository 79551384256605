import React from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import { reviewTSRequest, getUserDetails } from "../api/admin";
import Button from "../components/Button";
import Entry from "../components/Entry";
import TextArea from "../components/TextArea";
import * as Analytics from "../analytics";

class AdminTSRequestReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      statusReason: "",
      tsRequest: {},
      user: { idProof: {} },
      errors: {},
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    try {
      let { user } = await getUserDetails(this.props.userId);
      this.setState({
        user,
        statusReason: user.idProof && user.idProof.statusReason,
      });
    } catch (e) {
      console.log(e);
    }
  }

  updateTSRequestStatus = async (status) => {
    try {
      await reviewTSRequest({
        id: this.state.user.id,
        status,
        statusReason: this.state.statusReason,
      });
      if (status === "APPROVED") {
        Analytics.logEvent("[A] approve id proof", {
          userId: this.state.user.id,
        });
      } else if (status === "REJECTED") {
        Analytics.logEvent("[A] reject id proof", {
          userId: this.state.user.id,
        });
      }
      await this.onLoad();
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <div>
        <Title>Candidate ID Proof</Title>
        {this.state.user.idProof &&
          this.state.user.idProof.status === "APPROVED" && (
            <div>This request is approved</div>
          )}
        {this.state.user.idProof &&
          this.state.user.idProof.status === "REJECTED" && (
            <div>This request is rejected</div>
          )}
        {this.state.user.idProof &&
          this.state.user.idProof.status !== "APPROVED" &&
          this.state.user.idProof.status !== "REJECTED" && (
            <div style={{ display: "flex", marginBottom: "32px" }}>
              <div style={{ paddingRight: "32px" }}>
                <Button
                  onClick={() => {
                    this.updateTSRequestStatus("REJECTED");
                  }}
                >
                  Reject
                </Button>
              </div>
              <Button
                onClick={() => {
                  this.updateTSRequestStatus("APPROVED");
                }}
              >
                Accept
              </Button>
            </div>
          )}
        <TextArea
          placeholder={"Enter rejection reason here"}
          onChange={(e) => {
            if (typeof e.target.value !== "undefined") {
              this.setState({
                statusReason: e.target.value,
              });
            }
          }}
          value={this.state.statusReason}
          autoComplete={"off"}
        ></TextArea>
        <div style={{ maxWidth: 420 }}>
          <Entry
            label={"Id Proof"}
            value={this.state.user.idProof.name}
          ></Entry>
          <Entry
            label={"Id Proof Id"}
            value={this.state.user.idProof.id}
          ></Entry>
          <img
            alt={"idproof"}
            src={this.state.user.idProof.photoUrl}
            height={"400"}
          ></img>
          <Entry
            label={"Id Proof Verification"}
            value={this.state.user.idProof.isVerified ? "true" : "false"}
          ></Entry>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminTSRequestReview));
