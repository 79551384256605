import React from "react";
import Button from "../components/Button";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import { submitCandidate, getCandidate } from "../api/candidate";
import { getUser } from "../api/user";
import { toaster } from "baseui/toast";
import { CANDIDATE_DASHBOARD } from "../util/routes";
import Resume from "../components/Resume";
import Title from "../components/Title";
import * as Analytics from "../analytics";
import LoadingScreen from "../components/LoadingScreen";
import { Block } from "baseui/block";
import { CenterContainer } from "../components/CenterContainer";

class CandidateProfileSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidate: {},
      isLoaded: false,
      isSubmitting: false,
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[C] page view profile summary");
    try {
      let candidate = await getCandidate(this.props.user.id);
      this.setState({
        isLoaded: true,
        candidate,
      });
    } catch (e) {
      this.setState({
        isLoaded: true,
      });
      console.log(e);
    }
  }

  onSummaryForReviewClick = async () => {
    try {
      this.setState({
        isSubmitting: true,
      });
      await submitCandidate({
        userId: this.props.user.id,
      });
      Analytics.logEvent("[C] submit candidate profile", {
        isFirstSubmission: !this.props.user.candidateProfile,
      });
      this.setState({
        isSubmitting: false,
      });
      let user = await getUser(this.props.user.id);
      this.props.setUser({
        candidateProfile: user.candidateProfile,
      });
      toaster.positive(<div>Candidate information submitted for review</div>, {
        autoHideDuration: 3000,
        onClose: () => console.log("Toast closed."),
      });
      this.props.history.push(CANDIDATE_DASHBOARD);
    } catch (ex) {
      this.setState({
        isSubmitting: false,
      });
      console.log(ex);
    }
  };

  render() {
    if (!this.state.isLoaded) {
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    }
    return (
      <CenterContainer>
        <Block
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="scale600"
        >
          <Title>Submit for Review</Title>
          <Button
            type={"primary"}
            isLoading={this.state.isSubmitting}
            onClick={this.onSummaryForReviewClick}
          >
            Submit for review
          </Button>
        </Block>
        <Resume candidate={this.state.candidate} />
        <Button
          style={{ marginTop: 32, marginBottom: 32 }}
          type={"primary"}
          isLoading={this.state.isSubmitting}
          onClick={this.onSummaryForReviewClick}
        >
          Submit for review
        </Button>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateProfileSummary));
