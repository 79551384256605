import React, { ReactNode } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import { CandidateFunnelItem } from "./CandidateFunnelItem";
import { Candidate } from "./CandidateFunnelTypes";

export const getBackgroundColor = (
  isDraggingOver: boolean,
  isDraggingFrom: boolean
): string => {
  if (isDraggingOver) {
    return "green.200";
  }
  if (isDraggingFrom) {
    return "red.200";
  }
  return "gray.200";
};

export type CandidateFunnelItemListProps = {
  listId?: string;
  listType?: string;
  candidates: Candidate[];
  title?: string;
  internalScroll?: boolean;
  scrollContainerStyle?: Object;
  isDropDisabled?: boolean;
  isCombineEnabled?: boolean;
  style?: Object;
  ignoreContainerClipping?: boolean;
  useClone?: boolean;
  renderItem: (candidate: Candidate) => ReactNode;
};
const scrollContainerHeight: number = 250;

type CandidateListProps = {
  candidates: Candidate[];
  renderItem: (candidate: Candidate) => ReactNode;
};

const InnerCandidateList = React.memo(function InnerCandidateListC(
  props: CandidateListProps
) {
  return (
    <Box>
      {(props.candidates || []).map((candidate: Candidate, index: number) => (
        <Draggable key={candidate.id} draggableId={candidate.id} index={index}>
          {(
            dragProvided: DraggableProvided,
            dragSnapshot: DraggableStateSnapshot
          ) => (
            <CandidateFunnelItem
              key={candidate.id}
              candidate={candidate}
              renderItem={props.renderItem}
              isDragging={dragSnapshot.isDragging}
              isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
              provided={dragProvided}
            />
          )}
        </Draggable>
      ))}
    </Box>
  );
});

type InnerListProps = {
  dropProvided: DroppableProvided;
  candidates: Candidate[];
  renderItem: (candidate: Candidate) => ReactNode;
  title?: string;
};

function InnerList(props: InnerListProps) {
  const { candidates, renderItem, dropProvided } = props;
  const title = props.title ? (
    <Text fontWeight="500">{props.title}</Text>
  ) : null;

  return (
    <Box>
      {title}
      <Box
        minHeight={`${scrollContainerHeight}px`}
        pb={8}
        ref={dropProvided.innerRef}
      >
        <InnerCandidateList candidates={candidates} renderItem={renderItem} />
        {dropProvided.placeholder}
      </Box>
    </Box>
  );
}

export const CandidateFunnelItemList = ({
  ignoreContainerClipping,
  internalScroll,
  scrollContainerStyle,
  isDropDisabled,
  isCombineEnabled,
  listId = "LIST",
  listType,
  style,
  candidates,
  title,
  useClone,
  renderItem,
}: CandidateFunnelItemListProps) => {
  return (
    <Droppable
      droppableId={listId}
      type={listType}
      ignoreContainerClipping={ignoreContainerClipping}
      isDropDisabled={isDropDisabled}
      isCombineEnabled={isCombineEnabled}
      renderClone={
        useClone
          ? (provided, snapshot, descriptor) => (
              <CandidateFunnelItem
                candidate={candidates[descriptor.source.index]}
                provided={provided}
                renderItem={renderItem}
                isDragging={snapshot.isDragging}
                isClone
              />
            )
          : undefined
      }
    >
      {(
        dropProvided: DroppableProvided,
        dropSnapshot: DroppableStateSnapshot
      ) => (
        <Flex
          backgroundColor={getBackgroundColor(
            dropSnapshot.isDraggingOver,
            Boolean(dropSnapshot.draggingFromThisWith)
          )}
          flexDirection="column"
          opacity={isDropDisabled ? 0.5 : "inherit"}
          padding={2}
          borderWidth={2}
          pb={0}
          transition={"background-color 0.2s ease, opacity 0.1s ease"}
          userSelect="none"
          width="320px"
          style={style}
          isDraggingOver={dropSnapshot.isDraggingOver}
          isDropDisabled={isDropDisabled}
          isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
          {...dropProvided.droppableProps}
        >
          {internalScroll ? (
            <Box
              overflowX="hidden"
              overflowY="auto"
              maxHeight={scrollContainerHeight}
              style={scrollContainerStyle}
            >
              <InnerList
                candidates={candidates}
                renderItem={renderItem}
                title={title}
                dropProvided={dropProvided}
              />
            </Box>
          ) : (
            <InnerList
              candidates={candidates}
              renderItem={renderItem}
              title={title}
              dropProvided={dropProvided}
            />
          )}
        </Flex>
      )}
    </Droppable>
  );
};
