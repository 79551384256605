import { addCurrencyDecimals } from "./formatter";

let currencyMap = {
  INR: { id: "INR", name: "₹" },
  USD: { id: "USD", name: "$" },
  GBP: { id: "GBP", name: "£" },
  EUR: { id: "EUR", name: "€" },
  YEN: { id: "YEN", name: "¥" },
};

const numberPattern = /\d+/g;

export function getCurrency(id) {
  return currencyMap[id] || { id: "INR", name: "₹" };
}

export function getCurrencyMap() {
  return Object.values(currencyMap);
}

export const getCurrencyWords = (ctc) => {
  if (ctc < 100000) {
    return addCurrencyDecimals(ctc, 0);
  }
  if (ctc < 10000000) {
    let lakhs = parseInt(ctc / 100000);
    let thousands = parseInt((ctc % 100000) / 1000);
    return thousands > 0
      ? lakhs + "." + thousands + " lakhs"
      : lakhs + " lakhs";
  }
  let crores = parseInt(ctc / 10000000);
  let lakhs = parseInt((ctc % 10000000) / 100000);
  return lakhs > 0 ? crores + "." + lakhs + " crores" : crores + " crores";
};

export const parseCurrency = (value) => {
  return Number(
    (value.match(numberPattern) || [])
      .join("")
      .split(",")
      .join("")
  );
};

export const formatCurrency = (value) => {
  return `₹ ` + addCurrencyDecimals(value);
};
