import React from "react";
import { styled } from "baseui";
import Button from "./components/Button";
import PageHeader from "./components/Header";
import { Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Input from "./components/Input";
import Label from "./components/Label";
import { FormControl } from "baseui/form-control";
import { setUser, signoutUser } from "./redux/actions/user";
import { validateEmail, validatePassword } from "./util/validator";
import { sendCode } from "./api/otp";
import { checkUserExists } from "./api/user";
import { toaster } from "baseui/toast";
import * as Analytics from "./analytics";
import RouteLink from "./components/RouteLink";
import Card from "./components/Card";
import { HeadingSmall, ParagraphSmall } from "baseui/typography";
import { GoogleLogin } from "./GoogleLogin";
import Title from "./components/Title";
import { Block } from "baseui/block";

const Container = styled(Card, {
  maxWidth: "500px",
  margin: "auto",
});

const TitleWrapper = styled("div", {
  marginBottom: "16px",
});

const GButtonWrapper = styled("div", {
  textAlign: "center",
  paddingTop: "16px",
});

const ORWrapper = styled("div", {
  textAlign: "center",
  marginTop: "32px",
  marginBottom: "32px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const Line = styled("div", {
  display: "flex",
  flex: 1,
  height: "1px",
  backgroundColor: "#d8d8d8",
});

const ORText = styled("div", {
  color: "#919191",
  marginRight: "16px",
  marginLeft: "16px",
});

const Terms = styled("div", {
  color: "#aaaaaa",
  marginTop: "8px",
  fontSize: "13px",
});

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      registerSuccess: false,
      isLoading: false,
      email: "",
      password: "",
      emailError: "",
      passwordError: "",
    };
  }

  componentDidMount() {
    Analytics.logEvent("page view register");
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    await this.onContinue({
      email: this.state.email,
      password: this.state.password,
    });
  };

  checkEmail = (e) => {
    if (!validateEmail(this.state.email)) {
      this.setState({
        emailError: "Email is not valid",
      });
      return false;
    } else {
      this.setState({
        emailError: "",
      });
      return true;
    }
  };

  checkPassword = (e) => {
    if (!validatePassword(this.state.password)) {
      this.setState({
        passwordError: "Password should be atleast 8 characters",
      });
      return false;
    } else {
      this.setState({
        passwordError: "",
      });
      return true;
    }
  };

  async onContinue(values) {
    let { email, password } = values;
    if (!this.checkEmail() || !this.checkPassword()) {
      return;
    }
    Analytics.logEvent("click submit register");
    try {
      this.setState({
        isLoading: true,
      });
      let { exists } = await checkUserExists({
        email: email,
      });
      if (exists) {
        toaster.negative(
          "This email is already registered. Please login with your email id",
          { autoHideDuration: 3000 }
        );
        this.setState({
          isLoading: false,
        });
        return;
      }
      await sendCode({
        sendType: "email",
        sendId: email,
      });
      this.props.history.push({
        pathname: "/verifyEmail",
        state: { email, password },
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    return <Redirect to="/login" />;
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser, signoutUser }
)(withRouter(Register));
