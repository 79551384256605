import React, { useState } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import Pagination from "../components/Pagination";
import {
  getApprovedCandidates,
  getRecentCandidateSubmission,
} from "../api/admin";
import { getExperienceDuration } from "../util/time";
import { getCurrencyWords } from "../util/currency";
import Card from "../components/Card";
import Bold from "../components/Bold";
import Button from "../components/Button";
import { SIZE as BUTTON_SIZE } from "baseui/button";
import AdminHandPickCandidateModal from "./AdminHandPickCandidateModal";
import { searchEntity } from "../api/entity";
import Select from "../components/Select";
import Spacing from "../components/Spacing";
import { Block } from "baseui/block";
import { Icon } from "../components/Icon";

const CandidateRow = ({ user, candidate, history }) => {
  const [isHandPickModalVisible, setIsHandPickModalVisible] = useState(false);
  const [isFetchingSubmission, setIsFetchingSubmission] = useState(false);
  return (
    <Card style={{ marginBottom: "16px" }}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "200px" }}>
          <Bold>{user.name}</Bold>
          <div>{user.email}</div>
          <div>{user.phone}</div>
        </div>
        <div style={{ marginLeft: "16px", width: "200px" }}>
          <div>{candidate.lastExperienceTitle}</div>
          {candidate.experience.length && (
            <div>{candidate.experience[0].company.name}</div>
          )}
          {candidate.education.length && (
            <div>{candidate.education[0].college.name}</div>
          )}
          <div>{getExperienceDuration(candidate.totalExperience)}</div>
          <div>{getCurrencyWords(candidate.expectedCTC)}</div>
          <div>
            {candidate.skills
              .slice(0, 3)
              .map((sk) => sk.name)
              .join(",")}
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <Button
              size={BUTTON_SIZE.mini}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsHandPickModalVisible(true);
              }}
            >
              <Block $marginRight="scale200">
                <Icon size={16} name="check-square" />
              </Block>
              <span>Pick for a JD</span>
            </Button>
          </div>
          <div style={{ marginTop: "8px" }}>
            <Button
              size={BUTTON_SIZE.mini}
              isLoading={isFetchingSubmission}
              onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsFetchingSubmission(true);
                try {
                  let submission = await getRecentCandidateSubmission(
                    candidate.userId
                  );
                  history.push(
                    "/N0VHxB8M/admin/details/submission/" + submission.id
                  );
                  setIsFetchingSubmission(false);
                } catch (e) {}
                setIsFetchingSubmission(false);
              }}
            >
              <Block $marginRight="scale200">
                <Icon size={16} name="edit" background="dark" />
              </Block>
              <span>Edit</span>
            </Button>
          </div>
        </div>
      </div>
      <AdminHandPickCandidateModal
        isVisible={isHandPickModalVisible}
        candidateId={candidate.userId}
        onClose={() => setIsHandPickModalVisible(false)}
      />
    </Card>
  );
};

class AdminCandidateList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      isLoading: false,
      page: 0,
      limit: 10,
      users: {},
      candidates: [],
      jobFunction: "",
      hasNext: false,
      hasPrev: false,
      isHandPickModalVisible: false,
    };
  }

  async componentDidMount() {
    await this.onLoad();
  }

  async onLoad() {
    try {
      let {
        data: { users, candidates },
        hasNext,
      } = await getApprovedCandidates(
        "GREEN",
        this.state.jobFunction.name || "",
        this.state.page,
        this.state.limit
      );
      this.setState({
        users,
        candidates,
        hasNext,
      });
    } catch (e) {
      console.log(e);
    }
  }

  onNextClick = () => {
    this.setState(
      {
        page: this.state.page + 1,
        hasPrev: this.state.page + 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  onPrevClick = () => {
    this.setState(
      {
        page: this.state.page - 1,
        hasPrev: this.state.page - 1 > 0,
      },
      async () => {
        await this.onLoad();
      }
    );
  };

  render() {
    return (
      <div>
        <Title>Candidates</Title>

        <Select
          placeholder={"Select Job Function"}
          getOptions={async (query) => searchEntity("jobFunction", query)}
          onChange={(value) => {
            this.setState(
              {
                jobFunction: value || "",
              },
              () => {
                this.onLoad();
              }
            );
          }}
          value={this.state.jobFunction}
          width={"200px"}
        />
        <Spacing size={"16"} />
        {this.state.candidates.map(
          (candidate, index) =>
            this.state.users[candidate.userId] && (
              <CandidateRow
                history={this.props.history}
                key={index}
                candidate={candidate}
                user={this.state.users[candidate.userId]}
              />
            )
        )}

        <Pagination
          onPrevClick={this.onPrevClick}
          onNextClick={this.onNextClick}
          hasNext={this.state.hasNext}
          hasPrev={this.state.hasPrev}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(AdminCandidateList));
