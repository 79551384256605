import React, { useState } from "react";
import {
  FormControl,
  Input,
  Box,
  Button,
  Checkbox,
  Select,
  Text,
  FormHelperText,
  useToast,
  IconButton,
  Flex,
  Link,
} from "@chakra-ui/react";
import { useFormik, getIn } from "formik";
import * as Yup from "yup";
import {
  AutoComplete,
  AutoCompleteItem,
} from "../../../../components/AutoComplete";
import {
  formatCurrency,
  getCurrencyWords,
  parseCurrency,
} from "../../../../util/currency";
import { searchEntity } from "../../../../api/entity";
import { FormLabel, FormErrorMessage } from "src/lib/uikit";
import { getFileExtension } from "src/util/file";
import { useFirebaseStorage } from "src/lib/hooks/useFirebaseStorage";
import { useSelector } from "react-redux";
import { values } from "lodash";
import { FiFile, FiX } from "react-icons/fi";
import { CheckIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { FaFile } from "react-icons/fa";
import { ReferredCandidateSchema } from "./ReferredCandidateSchema";
import { Salary } from "src/pages/candidate/referred-candidate/Salary";

export type ReferCandidateFormProps = {
  onSubmit: (values: any) => void;
  values: any;
  resetOnSubmit?: boolean;
  showStatus?: boolean;
  submitText?: string;
  isUpdating?: boolean;
  isSubmitDisabled?: boolean;
  disabledFields?: string[];
};

export const ReferCandidateForm = (props: ReferCandidateFormProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const { uploadFile } = useFirebaseStorage();
  const user = useSelector((state: any) => state.user);
  const toast = useToast();
  const isUrl = (f: any) => {
    if (f && f.indexOf && f.indexOf("http") === 0) {
      return true;
    }
    return false;
  };
  const formik = useFormik({
    initialValues: {
      ...props.values,
      isLinkedInProfileAvailable: props.values.linkedInUrl,
    },
    validationSchema: ReferredCandidateSchema,
    onSubmit: async (values) => {
      // if (!values.linkedInUrl) {
      //   values.linkedInUrl = "not-available";
      // }
      // if (values.isLinkedInProfileAvailable && !values.linkedInResumeFile) {
      //   formik.setFieldError("linkedInResumeFile", "resume file missing");
      //   return;
      // }
      if (values.isLinkedInProfileAvailable) {
        if (!values.linkedInUrl) {
          formik.setFieldError("linkedInUrl", "Required");
          toast({
            title: "There are some form errors",
            description: "Please check",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        if (!values.linkedInResumeFile) {
          formik.setFieldError("linkedInResumeFile", "Required");
          toast({
            title: "There are some form errors",
            description: "Please check",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
      }

      setIsUpdating(true);
      if (values.linkedInResumeFile && !isUrl(values.linkedInResumeFile)) {
        const linkedInResumeFile = (values.linkedInResumeFile as unknown) as File;
        try {
          values.linkedInResumeUrl = await uploadFile(
            `/resumes/${user.id}_${Date.now()}`,
            linkedInResumeFile
          );
        } catch (e) {
          toast({
            title: "Error while uploading",
            description: "Please try again",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
      }

      if (values.resumeFile && !isUrl(values.resumeFile)) {
        const resumeFile = (values.resumeFile as unknown) as File;
        try {
          values.resumeUrl = await uploadFile(
            `/resumes/${user.id}_${Date.now()}`,
            resumeFile
          );
          setIsFileUploading(false);
        } catch (e) {
          toast({
            title: "Error while uploading",
            description: "Please try again",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
      }

      if (!values.linkedInUrl) {
        values.linkedInUrl = "https://www.linkedin.com/in/emptyprofile";
      }
      if (!values.linkedInResumeUrl) {
        values.linkedInResumeUrl =
          "https://storage.googleapis.com/quickhire-stage/assets/EmptyLinkedIn.pdf";
      }
      setIsFileUploading(true);
      try {
        await props.onSubmit(values);
        if (props.resetOnSubmit) {
          formik.resetForm({});
          formik.setFieldValue("currentSalary", 0);
          formik.setFieldValue("expectedSalary", 0);
          // formik.setFieldValue("resumeFile", "");
          // formik.setFieldValue("linkedInResumeFile", "");
        }
      } catch (e) {
        const errorMessage = (e as any)?.message || "Please try again";
        toast({
          title: "Error while saving",
          description: errorMessage,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      setIsUpdating(false);
    },
    enableReinitialize: true,
  });
  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <FormControl
        isInvalid={!!(formik.touched.firstName && formik.errors.firstName)}
      >
        <FormLabel htmlFor="firstName">Candidate first name</FormLabel>
        <Input
          {...formik.getFieldProps("firstName")}
          disabled={props.disabledFields?.includes("firstName")}
          autoCorrect="no"
          autoComplete="no"
          id="firstName"
          placeholder=""
        />
        <FormErrorMessage>
          {formik.touched.firstName && formik.errors.firstName
            ? formik.errors.firstName
            : ""}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!(formik.touched.lastName && formik.errors.lastName)}
      >
        <FormLabel htmlFor="lastName">Candidate last name</FormLabel>
        <Input
          {...formik.getFieldProps("lastName")}
          disabled={props.disabledFields?.includes("lastName")}
          autoCorrect="no"
          autoComplete="no"
          id="lastName"
          placeholder=""
        />
        <FormErrorMessage>
          {formik.touched.lastName && formik.errors.lastName
            ? formik.errors.lastName
            : ""}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!(formik.touched.email && formik.errors.email)}>
        <FormLabel htmlFor="email">Candidate email</FormLabel>
        <Input
          {...formik.getFieldProps("email")}
          disabled={props.disabledFields?.includes("email")}
          autoCorrect="no"
          autoComplete="no"
          id="email"
          placeholder=""
        />
        <FormErrorMessage>
          {formik.touched.email && formik.errors.email
            ? formik.errors.email
            : ""}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!(formik.touched.phone && formik.errors.phone)}>
        <FormLabel htmlFor="phone" fontWeight="500">
          Candidate phone
        </FormLabel>
        <Input
          {...formik.getFieldProps("phone")}
          autoCorrect="no"
          disabled={props.disabledFields?.includes("phone")}
          autoComplete="no"
          id="phone"
          placeholder=""
        />
        <FormErrorMessage>
          {formik.touched.phone && formik.errors.phone
            ? formik.errors.phone
            : ""}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!(formik.touched.resumeFile && formik.errors.resumeFile)}
      >
        <FormLabel htmlFor="resumeFile" fontWeight="500">
          Candidate resume
        </FormLabel>
        {formik.getFieldProps("resumeFile").value ? (
          <Flex
            borderWidth={1}
            borderRadius="md"
            p={1}
            justifyContent={"space-between"}
          >
            <Flex alignItems={"center"} mr={4}>
              <Link
                ml={2}
                target="_blank"
                isExternal
                href={formik.getFieldProps("resumeFile").value}
              >
                {`Uploaded File ${formik.getFieldProps("resumeFile")?.value
                  ?.name || ""}`}
                <ExternalLinkIcon mx="2px" />
              </Link>
            </Flex>
            <IconButton
              icon={<FiX />}
              aria-label="close"
              onClick={() => {
                formik.setFieldValue("resumeFile", undefined);
              }}
            />
          </Flex>
        ) : (
          <Input
            // {...formik.getFieldProps("resumeFile")}
            id="resumeFile"
            name="resumeFile"
            type="file"
            pt={"5px"}
            onChange={(event) => {
              formik.setFieldValue(
                "resumeFile",
                event?.currentTarget?.files?.[0]
              );
            }}
            onBlur={formik.getFieldProps("resumeFile").onBlur}
          />
        )}
        <FormErrorMessage>
          {formik.touched.resumeFile && formik.errors.resumeFile
            ? formik.errors.resumeFile
            : ""}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={
          !!(
            formik.touched.isLinkedInProfileAvailable &&
            formik.errors.isLinkedInProfileAvailable
          )
        }
        mb={4}
      >
        <Checkbox
          {...formik.getFieldProps("isLinkedInProfileAvailable")}
          isChecked={formik.getFieldProps("isLinkedInProfileAvailable").value}
          id="isLinkedInProfileAvailable"
          onChange={(e) =>
            formik.setFieldValue("isLinkedInProfileAvailable", e.target.checked)
          }
        >
          <Text fontWeight={"500"}>Is linkedin profile available?</Text>
        </Checkbox>
      </FormControl>
      {formik.values.isLinkedInProfileAvailable ? (
        <>
          <FormControl
            isInvalid={
              !!(formik.touched.linkedInUrl && formik.errors.linkedInUrl)
            }
          >
            <FormLabel htmlFor="linkedInUrl">Candidate linkedin url</FormLabel>
            <Input
              {...formik.getFieldProps("linkedInUrl")}
              autoCorrect="off"
              autoComplete="off"
              id="linkedInUrl"
              placeholder=""
            />
            <FormErrorMessage>
              {formik.touched.linkedInUrl && formik.errors.linkedInUrl
                ? formik.errors.linkedInUrl
                : ""}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!(
                formik.touched.linkedInResumeFile &&
                formik.errors.linkedInResumeFile
              )
            }
          >
            <FormLabel htmlFor="linkedInResumeFile" fontWeight="500">
              Candidate linkedIn resume
            </FormLabel>
            {formik.getFieldProps("linkedInResumeFile").value ? (
              <Flex
                borderWidth={1}
                borderRadius="md"
                p={1}
                justifyContent={"space-between"}
              >
                <Flex alignItems={"center"} mr={4}>
                  <Link
                    ml={2}
                    target="_blank"
                    isExternal
                    href={formik.getFieldProps("linkedInResumeFile").value}
                  >
                    {`Uploaded File ${formik.getFieldProps("linkedInResumeFile")
                      ?.value?.name || ""}`}{" "}
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </Flex>
                <IconButton
                  icon={<FiX />}
                  aria-label="close"
                  onClick={() => {
                    formik.setFieldValue("linkedInResumeFile", undefined);
                  }}
                />
              </Flex>
            ) : (
              <Input
                // {...formik.getFieldProps("linkedInResumeFile")}
                id="linkedInResumeFile"
                name="linkedInResumeFile"
                type="file"
                // value={undefined}
                pt={"5px"}
                onChange={(event) => {
                  formik.setFieldValue(
                    "linkedInResumeFile",
                    event?.currentTarget?.files?.[0]
                  );
                }}
                onBlur={formik.getFieldProps("linkedInResumeFile").onBlur}
              />
            )}
            {
              <FormErrorMessage>
                {formik.touched.linkedInResumeFile &&
                formik.errors.linkedInResumeFile
                  ? formik.errors.linkedInResumeFile
                  : ""}
              </FormErrorMessage>
            }
          </FormControl>
        </>
      ) : null}

      <FormControl
        isInvalid={
          !!(formik.touched.currentLocation && formik.errors.currentLocation)
        }
      >
        <FormLabel htmlFor="currentLocation" fontWeight="500">
          Candidate current location
        </FormLabel>
        <AutoComplete
          {...formik.getFieldProps("currentLocation")}
          id="currentLocation"
          items={[]}
          fetchItems={async (q) => searchEntity("location", q)}
          selected={formik.getFieldProps("currentLocation").value}
          onItemSelect={(selected: AutoCompleteItem | AutoCompleteItem[]) => {
            formik.setFieldTouched("currentLocation", true);
            formik.setFieldValue("currentLocation", selected);
          }}
        />
        {
          <FormErrorMessage>
            {formik.touched.currentLocation && formik.errors.currentLocation
              ? formik.errors.currentLocation
              : ""}
          </FormErrorMessage>
        }
      </FormControl>
      <FormControl
        isInvalid={
          !!(
            formik.touched.preferredLocations &&
            formik.errors.preferredLocations
          )
        }
      >
        <FormLabel htmlFor="preferredLocations">
          Candidate preferred locations
        </FormLabel>
        <AutoComplete
          {...formik.getFieldProps("preferredLocations")}
          id="preferredLocations"
          items={[]}
          isMultiple
          fetchItems={async (q) => searchEntity("location", q)}
          selected={formik.getFieldProps("preferredLocations").value}
          onItemSelect={(selected: AutoCompleteItem | AutoCompleteItem[]) => {
            formik.setFieldTouched("preferredLocations", true);
            formik.setFieldValue("preferredLocations", selected);
          }}
        />

        <FormErrorMessage>
          {formik.touched.preferredLocations && formik.errors.preferredLocations
            ? formik.errors.preferredLocations
            : ""}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={!!(formik.touched.skills && formik.errors.skills)}
      >
        <FormLabel htmlFor="skills">Candidate top 3 skills</FormLabel>
        <AutoComplete
          {...formik.getFieldProps("skills")}
          id="skills"
          fetchItems={async (q) => searchEntity("skill", q)}
          items={[]}
          isMultiple
          selected={formik.getFieldProps("skills").value}
          onItemSelect={(selected: AutoCompleteItem | AutoCompleteItem[]) => {
            formik.setFieldTouched("skills", true);
            formik.setFieldValue("skills", selected);
          }}
        />
        {
          <FormErrorMessage>
            {formik.touched.skills && formik.errors.skills
              ? formik.errors.skills
              : ""}
          </FormErrorMessage>
        }
      </FormControl>
      <FormControl
        isInvalid={
          !!(formik.touched.jobFunctions && formik.errors.jobFunctions)
        }
      >
        <FormLabel htmlFor="jobFunctions">Candidate job functions</FormLabel>
        <AutoComplete
          {...formik.getFieldProps("jobFunctions")}
          id="jobFunctions"
          items={[]}
          fetchItems={async (q) => searchEntity("jobFunction", q)}
          isMultiple
          selected={formik.getFieldProps("jobFunctions").value}
          onItemSelect={(selected: AutoCompleteItem | AutoCompleteItem[]) => {
            formik.setFieldTouched("jobFunctions", true);
            formik.setFieldValue("jobFunctions", selected);
          }}
        />
        <FormErrorMessage>
          {formik.touched.jobFunctions && formik.errors.jobFunctions
            ? formik.errors.jobFunctions
            : ""}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={
          !!(formik.touched.currentSalary && formik.errors.currentSalary)
        }
      >
        <FormLabel htmlFor="currentSalary">Candidate current salary</FormLabel>
        <Salary
          id={"currentSalary"}
          placeholder={`Enter current salary`}
          onChange={(value) => {
            formik.setFieldValue("currentSalary", value);
          }}
          value={formik.getFieldProps("currentSalary").value || 0}
          // defaultValue={props.values?.currentSalary}
        />
        <FormHelperText>
          {getCurrencyWords(formik.getFieldProps("currentSalary").value || 0)}
        </FormHelperText>
        <FormErrorMessage>
          {formik.touched.currentSalary && formik.errors.currentSalary
            ? formik.errors.currentSalary
            : ""}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={
          !!(formik.touched.expectedSalary && formik.errors.expectedSalary)
        }
      >
        <FormLabel htmlFor="expectedSalary">
          Candidate expected salary
        </FormLabel>
        <Salary
          id={"expectedSalary"}
          placeholder={`Enter expected salary`}
          onChange={(value) => {
            formik.setFieldValue("expectedSalary", value);
          }}
          value={formik.getFieldProps("expectedSalary").value || 0}
          // defaultValue={props.values?.expectedSalary}
        />
        <FormHelperText>
          {getCurrencyWords(formik.getFieldProps("expectedSalary").value || 0)}
        </FormHelperText>
        <FormErrorMessage>
          {formik.touched.expectedSalary && formik.errors.expectedSalary
            ? formik.errors.expectedSalary
            : ""}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={!!(formik.touched.isResigned && formik.errors.isResigned)}
        mb={4}
      >
        <Checkbox
          {...formik.getFieldProps("isResigned")}
          isChecked={formik.getFieldProps("isResigned").value}
          id="isResigned"
          onChange={(e) => formik.setFieldValue("isResigned", e.target.checked)}
        >
          <Text>Is the candidate already resigned?</Text>
        </Checkbox>
        {formik.touched.isResigned && formik.errors.isResigned ? (
          <FormErrorMessage>formik.errors.isResigned</FormErrorMessage>
        ) : null}
      </FormControl>

      {formik.values.isResigned ? (
        <FormControl
          bg="gray.50"
          borderRadius={"md"}
          p={4}
          mb={4}
          minH={106}
          isInvalid={
            !!(formik.touched.lastWorkingDate && formik.errors.lastWorkingDate)
          }
        >
          <FormLabel htmlFor="lastWorkingDate" fontWeight="500">
            Last Working Date
          </FormLabel>
          <Input
            {...formik.getFieldProps("lastWorkingDate")}
            id="lastWorkingDate"
            type="date"
            placeholder=""
            onChange={(e) => {
              const newDate = new Date(e.target.value);
              if (newDate.getTime() === newDate.getTime()) {
                // Invalid date object check
                formik.setFieldValue("lastWorkingDate", newDate.getTime());
              }
            }}
            value={
              new Date(formik.getFieldProps("lastWorkingDate").value)
                .toISOString()
                .split("T")[0]
            }
          />
          <FormErrorMessage>
            {formik.touched.lastWorkingDate && formik.errors.lastWorkingDate
              ? formik.errors.lastWorkingDate
              : ""}
          </FormErrorMessage>
        </FormControl>
      ) : (
        <FormControl
          isInvalid={
            !!(formik.touched.noticePeriod && formik.errors.noticePeriod)
          }
        >
          <FormLabel htmlFor="noticePeriod">Candidate notice period</FormLabel>
          <Select
            {...formik.getFieldProps("noticePeriod")}
            id="noticePeriod"
            onChange={(e) => {
              formik.setFieldValue("noticePeriod", +e.target.value);
            }}
          >
            {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
              (i) => (
                <option value={i}>{`${
                  i === 0 ? "Immediate joinee - " : ""
                }${i} weeks`}</option>
              )
            )}
          </Select>
          <FormErrorMessage>
            {formik.touched.noticePeriod && formik.errors.noticePeriod
              ? formik.errors.noticePeriod
              : ""}
          </FormErrorMessage>
        </FormControl>
      )}

      <FormControl
        isInvalid={
          !!(formik.touched.isRemoteReady && formik.errors.isRemoteReady)
        }
      >
        <Checkbox
          {...formik.getFieldProps("isRemoteReady")}
          isChecked={formik.getFieldProps("isRemoteReady").value}
          id="isRemoteReady"
          onChange={(e) =>
            formik.setFieldValue("isRemoteReady", e.target.checked)
          }
        >
          <Text>Is the candidate ready to work remotely?</Text>
        </Checkbox>
        <FormErrorMessage>
          {formik.touched.isRemoteReady && formik.errors.isRemoteReady
            ? formik.errors.isRemoteReady
            : ""}
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isInvalid={
          !!(formik.touched.isStartupReady && formik.errors.isStartupReady)
        }
      >
        <Checkbox
          {...formik.getFieldProps("isStartupReady")}
          isChecked={formik.getFieldProps("isStartupReady").value}
          id="isStartupReady"
          onChange={(e) =>
            formik.setFieldValue("isStartupReady", e.target.checked)
          }
        >
          <Text>Is the candidate ready to work in a startup?</Text>
        </Checkbox>
        <FormErrorMessage>
          {formik.touched.isStartupReady && formik.errors.isStartupReady
            ? formik.errors.isStartupReady
            : ""}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={
          !!(formik.touched.hasJobOffers && formik.errors.hasJobOffers)
        }
      >
        <Checkbox
          {...formik.getFieldProps("hasJobOffers")}
          isChecked={formik.getFieldProps("hasJobOffers").value}
          id="hasJobOffers"
          onChange={(e) => {
            formik.setFieldValue("hasJobOffers", e.target.checked);
            formik.setFieldValue(
              "jobOffer",
              e.target.checked
                ? {
                    company: {
                      id: "Not available",
                      name: "Not available",
                    },
                    jobTitle: null,
                    offeredSalary: undefined,
                    offeredSalaryCurrency: "INR",
                  }
                : null
            );
          }}
        >
          <Text>Does the candidate have an existing job offer?</Text>
        </Checkbox>
      </FormControl>

      {!!formik.getFieldProps("hasJobOffers").value && (
        <FormControl
          isInvalid={!!(formik.touched.jobOffer && formik.errors.jobOffer)}
          minH={"40px"}
        >
          <FormHelperText>
            Enter the highest one if there are multiple offers
          </FormHelperText>
          <Box p={4} mt={2} bg={"gray.50"} borderRadius="md">
            <Text>Company Name</Text>
            <AutoComplete
              {...formik.getFieldProps(`jobOffer.company`)}
              id="currentLocation"
              placeholder="Enter company"
              items={[]}
              fetchItems={async (q) => searchEntity("company", q)}
              selected={formik.getFieldProps(`jobOffer.company`).value}
              onItemSelect={(
                selected: AutoCompleteItem | AutoCompleteItem[]
              ) => {
                formik.setFieldTouched("jobOffer.company", true);
                formik.setFieldValue(`jobOffer.company`, selected);
              }}
            />
            <FormErrorMessage></FormErrorMessage>
            <Text mt={1}>Job Title</Text>
            <AutoComplete
              {...formik.getFieldProps(`jobOffer.jobTitle`)}
              id="jobTitle"
              placeholder="Enter job title"
              items={[]}
              fetchItems={async (q) => searchEntity("jobTitle", q)}
              selected={formik.getFieldProps(`jobOffer.jobTitle`).value}
              onItemSelect={(
                selected: AutoCompleteItem | AutoCompleteItem[]
              ) => {
                formik.setFieldTouched("jobOffer.jobTitle", true);
                formik.setFieldValue(`jobOffer.jobTitle`, selected);
              }}
            />
            {getIn(formik.errors, "jobOffer.jobTitle") ? (
              <FormErrorMessage>
                {getIn(formik.errors, "jobOffer.jobTitle")}
              </FormErrorMessage>
            ) : null}
            <Text mt={1}>Offered Salary</Text>
            <Salary
              id={"jobOffer.offeredSalary"}
              placeholder={`Enter offered salary`}
              onChange={(value) => {
                formik.setFieldValue("jobOffer.offeredSalary", value);
              }}
              value={formik.getFieldProps("jobOffer.offeredSalary").value || 0}
              defaultValue={props.values?.jobOffer?.offeredSalary}
            />
            <FormHelperText>
              {getCurrencyWords(
                formik.getFieldProps("jobOffer.offeredSalary").value || 0
              )}
            </FormHelperText>
            {getIn(formik.errors, "jobOffer.offeredSalary") ? (
              <FormErrorMessage>
                {getIn(formik.errors, "jobOffer.offeredSalary")}
              </FormErrorMessage>
            ) : null}
          </Box>
        </FormControl>
      )}
      <FormErrorMessage>
        {formik.touched.hasJobOffers && formik.errors.hasJobOffers
          ? formik.errors.hasJobOffers
          : ""}
      </FormErrorMessage>
      {props.showStatus && (
        <FormControl
          isInvalid={!!(formik.touched.status && formik.errors.status)}
        >
          <FormLabel htmlFor="status">Status</FormLabel>
          <Select
            {...formik.getFieldProps("status")}
            id="status"
            placeholder="Select"
          >
            {[
              { label: "APPROVE", value: "PARSER" },
              { label: "NEEDS CORRECTION", value: "NEEDSCORRECTION" },
              { label: "REJECTED", value: "REJECTED" },
              { label: "DATAVERIFIED", value: "DATAVERIFIED" },
            ].map(({ label, value }) => (
              <option value={value}>{label}</option>
            ))}
          </Select>

          <FormErrorMessage>
            {formik.touched.status && formik.errors.status
              ? formik.errors.status
              : ""}
          </FormErrorMessage>
        </FormControl>
      )}

      {props.showStatus && (
        <FormControl
          isInvalid={
            !!(formik.touched.statusMessage && formik.errors.statusMessage)
          }
        >
          <FormLabel htmlFor="statusMessage" fontWeight="500">
            Status Message
          </FormLabel>
          <Input
            {...formik.getFieldProps("statusMessage")}
            autoCorrect="no"
            autoComplete="no"
            id="phone"
            placeholder=""
          />
          <FormErrorMessage>
            {formik.touched.statusMessage && formik.errors.statusMessage
              ? formik.errors.statusMessage
              : ""}
          </FormErrorMessage>
        </FormControl>
      )}

      {props.showStatus && (
        <FormControl
          isInvalid={!!(formik.touched.reviewTags && formik.errors.reviewTags)}
        >
          <FormLabel htmlFor="reviewTags">Review Tags</FormLabel>
          <AutoComplete
            {...formik.getFieldProps("reviewTags")}
            id="reviewTags"
            items={[]}
            fetchItems={async (q) => searchEntity("candidateReviewTags", q)}
            isMultiple
            selected={formik.getFieldProps("reviewTags").value}
            onItemSelect={(selected: AutoCompleteItem | AutoCompleteItem[]) => {
              formik.setFieldTouched("reviewTags", true);
              formik.setFieldValue("reviewTags", selected);
            }}
          />
          <FormErrorMessage>
            {formik.touched.reviewTags && formik.errors.reviewTags
              ? formik.errors.reviewTags
              : ""}
          </FormErrorMessage>
        </FormControl>
      )}

      {props.showStatus && (
        <FormControl
          isInvalid={
            !!(formik.touched.reviewComment && formik.errors.reviewComment)
          }
        >
          <FormLabel htmlFor="reviewComment">Review Comment</FormLabel>
          <Input
            {...formik.getFieldProps("reviewComment")}
            disabled={props.disabledFields?.includes("reviewComment")}
            autoCorrect="no"
            autoComplete="no"
            id="reviewComment"
            placeholder=""
          />
          <FormErrorMessage>
            {formik.touched.reviewComment && formik.errors.reviewComment
              ? formik.errors.reviewComment
              : ""}
          </FormErrorMessage>
        </FormControl>
      )}

      {props.showStatus && (
        <FormControl
          isInvalid={!!(formik.touched.isVerified && formik.errors.isVerified)}
        >
          <Checkbox
            {...formik.getFieldProps("isVerified")}
            isChecked={formik.getFieldProps("isVerified").value}
            id="isVerified"
            onChange={(e) =>
              formik.setFieldValue("isVerified", e.target.checked)
            }
          >
            <Text>Is Verified?</Text>
          </Checkbox>
        </FormControl>
      )}

      {!props.isSubmitDisabled && (
        <Button
          colorScheme="brand"
          mt={4}
          type="submit"
          isLoading={isUpdating || props.isUpdating}
          loadingText={`Saving data`}
        >
          {props.submitText || "Refer Candidate"}
        </Button>
      )}
    </form>
  );
};
