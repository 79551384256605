import {
  Box,
  Heading,
  Textarea,
  useToast,
  Button,
  Text,
  FormLabel,
} from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";
import {
  addCandidateReviewCommentApi,
  CandidateReviewCommentProps,
  getCandidateReviewCommentsApi,
} from "src/api/candidate_review_comment";
import { ReferredCandidateProps } from "src/api/referred_candidate";
import { relativeTime } from "src/util/time";

export type CommentsTabProps = {
  referredCandidate: ReferredCandidateProps;
};

export const CommentsTab = ({ referredCandidate }: CommentsTabProps) => {
  const toast = useToast();
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState<CandidateReviewCommentProps[]>([]);
  const [hasNext, setHasNext] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [page, setPage] = useState(0);
  const limit = 100;
  const fetchComments = useCallback(async () => {
    if (!referredCandidate?.id) return;
    try {
      const { comments, hasNext } = await getCandidateReviewCommentsApi({
        page,
        limit,
        referredCandidateId: referredCandidate.id,
      });
      setComments(comments);
      setHasNext(hasNext);
    } catch (e) {
      toast({
        title: "Error while fetching comments",
        description: "Please try again",
      });
    }
  }, [page, referredCandidate, toast]);
  useEffect(() => {
    fetchComments();
  }, [referredCandidate, fetchComments]);
  return (
    <Box pb={10}>
      <Box mt={4}>
        <Box background="gray.50" borderRadius={"md"} boxShadow="md" p={4}>
          <FormLabel>Enter comment</FormLabel>
          <Textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            mb={2}
          ></Textarea>
          <Button
            mt={4}
            isLoading={isAdding}
            onClick={async () => {
              if (!referredCandidate?.id) return;
              setIsAdding(true);
              try {
                await addCandidateReviewCommentApi({
                  text: comment,
                  referredCandidateId: referredCandidate.id,
                });
                setComment("");
                fetchComments();
              } catch (e) {}
              setIsAdding(false);
            }}
          >
            Add Comment
          </Button>
        </Box>
      </Box>
      <Box mt={4}>
        {comments.map((c) => {
          return (
            <Box borderBottomWidth={1} py={2}>
              <Text fontWeight="semibold" mb={2}>
                {c.createdByName}
              </Text>
              <Text mb={1}>{c.text}</Text>
              <Text fontSize="sm" color={"gray.500"}>
                {relativeTime(Date.now(), c.createdAt)}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
