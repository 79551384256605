import api from "./index";
import { baseUrl } from "./endpoints";

export async function findUsers(by, value, page) {
  return await api({
    baseUrl,
    url: "/api/admin/user/find",
    method: "GET",
    params: [
      { name: "by", value: by },
      { name: "value", value: value },
      { name: "page", value: page },
    ],
  });
}

export async function getUserDetails(userId) {
  return await api({
    baseUrl,
    url: "/api/admin/user/" + userId,
    method: "GET",
  });
}

export async function getCandidatesForReview(page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/candidates/review",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getRecentCandidateSubmission(candidateId) {
  return await api({
    baseUrl,
    url: "/api/admin/candidate/" + candidateId + "/submission/recent",
    method: "GET",
  });
}

export async function getApprovedCandidates(status, jobFunction, page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/candidates/approved",
    method: "GET",
    params: [
      { name: "status", value: status },
      { name: "jobFunction", value: jobFunction },
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function createPickedCandidate(data) {
  return await api({
    baseUrl,
    url: "/api/admin/pickedcd/create",
    method: "POST",
    data,
  });
}

export async function removePickedCandidate(data) {
  return await api({
    baseUrl,
    url: "/api/admin/pickedcd/remove",
    method: "POST",
    data,
  });
}

export async function rejectInterviewRequest(data) {
  return await api({
    baseUrl,
    url: "/api/admin/candidates/interview/reject",
    method: "POST",
    data,
  });
}

export async function disableCandidate(data) {
  return await api({
    baseUrl,
    url: "/api/admin/candidate/disable",
    method: "POST",
    data,
  });
}

export async function getEmployersForReview(page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/employers/review",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getTalentscoutsForReview(page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/talentscouts/review",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getTSRequests(page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/tsrequests",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getTSRequestsForReview(page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/tsrequests/review",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getTSRequestDetails(tsRequestId) {
  return await api({
    baseUrl,
    url: "/api/admin/tsrequest/" + tsRequestId,
    method: "GET",
  });
}

export async function getCandidateUploadDetails(email) {
  return await api({
    baseUrl,
    url: "/api/admin/candidate/uploads/" + email,
    method: "GET",
  });
}

export async function getAllTalentscouts(page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/talentscouts",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getAllEmployers(page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/employers",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getAllInterviewRequests(page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/interviewRequests",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getInterviewRequestDetail(interviewRequestId) {
  return await api({
    baseUrl,
    url: "/api/admin/interviewRequest/" + interviewRequestId,
    method: "GET",
  });
}
export async function reviewInterviewRequest(interviewRequestId, status, data) {
  return await api({
    baseUrl,
    url: "/api/admin/interviewRequest/" + interviewRequestId + "/" + status,
    method: "POST",
    data,
  });
}

export async function getAllJobs(page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/jobs",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getWithdrawalRequests(role, status, page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/withdrawals",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
      { name: "role", value: role },
      { name: "status", value: status },
    ],
  });
}

export async function markWithdrawalPaid(data) {
  return await api({
    baseUrl,
    url: "/api/admin/withdrawal/paid",
    method: "POST",
    data,
  });
}

export async function getJobDetail(jobId) {
  return await api({
    baseUrl,
    url: "/api/admin/job/" + jobId,
    method: "GET",
  });
}

export async function getJobsToAssign(candidateId, company, page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/jobs/assign",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
      { name: "company", value: company },
      { name: "candidateId", value: candidateId },
    ],
  });
}

export async function updateJobDetail(job) {
  return await api({
    baseUrl,
    url: "/api/admin/job/" + job.id + "/update",
    method: "POST",
    data: job,
  });
}

export async function reviewJob(jobId, status, data) {
  return await api({
    baseUrl,
    url: "/api/admin/job/" + jobId + "/" + status,
    method: "POST",
    data,
  });
}

export async function updateCandidate(candidateId, data) {
  return await api({
    baseUrl,
    url: "/api/admin/candidate",
    method: "POST",
    data,
  });
}

export async function updateUser(userId, data) {
  return await api({
    baseUrl,
    url: "/api/admin/user/update",
    method: "POST",
    data,
  });
}

export async function updateTalentscout(talentscoutId, data) {
  return await api({
    baseUrl,
    url: "/api/admin/talentscout",
    method: "POST",
    data,
  });
}

export async function updateEmployer(employerId, data) {
  return await api({
    baseUrl,
    url: "/api/admin/employer",
    method: "POST",
    data,
  });
}

export async function getCandidate(candidateId) {
  return await api({
    baseUrl,
    url: "/api/admin/candidate/" + candidateId,
    method: "GET",
  });
}

export async function getTalentscout(talentscoutId) {
  return await api({
    baseUrl,
    url: "/api/admin/talentscout/" + talentscoutId,
    method: "GET",
  });
}

export async function getEmployer(employerId) {
  return await api({
    baseUrl,
    url: "/api/admin/employer/" + employerId,
    method: "GET",
  });
}

export async function getCandidateSubmission(submissionId) {
  return await api({
    baseUrl,
    url: "/api/admin/candidate/submissions/" + submissionId,
    method: "GET",
  });
}

export async function getAllCandidateSubmissions(candidateId, page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/candidate/" + candidateId + "/submissions",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function updateCandidateSubmission(submissionId, data) {
  return await api({
    baseUrl,
    url: "/api/admin/candidate/submissions/" + submissionId + "/update",
    method: "POST",
    data,
  });
}

export async function reloadCandidateSubmission(submissionId, data) {
  return await api({
    baseUrl,
    url: "/api/admin/candidate/submissions/" + submissionId + "/reload",
    method: "POST",
    data,
  });
}

export async function rejectCandidateSubmission(submissionId, data) {
  return await api({
    baseUrl,
    url: "/api/admin/candidate/submissions/" + submissionId + "/reject",
    method: "POST",
    data,
  });
}

export async function approveCandidateSubmission(submissionId, data) {
  return await api({
    baseUrl,
    url: "/api/admin/candidate/submissions/" + submissionId + "/approve",
    method: "POST",
    data,
  });
}

export async function reviewEmployer(data) {
  return await api({
    baseUrl,
    url: "/api/admin/employer/review",
    method: "POST",
    data,
  });
}

export async function reviewTalentscout(data) {
  return await api({
    baseUrl,
    url: "/api/admin/talentscout/review",
    method: "POST",
    data,
  });
}

export async function reviewTSRequest(data) {
  return await api({
    baseUrl,
    url: "/api/admin/tsrequest/review",
    method: "POST",
    data,
  });
}

export async function updateTSSlots(data) {
  return await api({
    baseUrl,
    url: "/api/admin/talentscout/slots",
    method: "POST",
    data,
  });
}

export async function checkScoutEligible(data) {
  return await api({
    baseUrl,
    url: "/api/admin/candidate/scout/check",
    method: "POST",
    data,
  });
}

export async function getAllInvoices(startDate, endDate, page, limit) {
  return await api({
    baseUrl,
    url: "/api/admin/invoices",
    method: "GET",
    params: [
      { name: "startDate", value: startDate },
      { name: "endDate", value: endDate },
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getTalentscoutUploadedCandidates(
  talentscoutId,
  jobId,
  page,
  limit
) {
  return await api({
    baseUrl,
    url: "/api/admin/talentscout/" + talentscoutId + "/uploaded/" + jobId,
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function getTalentscoutHandPickedCandidates(
  talentscoutId,
  jobId,
  page,
  limit
) {
  return await api({
    baseUrl,
    url: "/api/admin/talentscout/" + talentscoutId + "/handpicked/" + jobId,
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function verifyPaymentOrder(userId, data) {
  return await api({
    baseUrl,
    url: "/api/admin/" + userId + "/paymentOrder/verify",
    method: "POST",
    data: data,
  });
}

export async function getWalletTransactions(accountId, accountType, page) {
  return await api({
    baseUrl,
    url: "/api/admin/" + accountId + "/wallet/transactions",
    method: "GET",
    params: [
      { name: "accountId", value: accountId },
      { name: "accountType", value: accountType },
      { name: "page", value: page },
    ],
  });
}

export async function getWalletAccount(accountId, accountType) {
  return await api({
    baseUrl,
    url: "/api/admin/" + accountId + "/wallet",
    method: "GET",
    params: [
      { name: "accountId", value: accountId },
      { name: "accountType", value: accountType },
    ],
  });
}
export async function doWalletPayment(data) {
  return await api({
    baseUrl,
    url: "/api/admin/wallet/payment/manual",
    method: "POST",
    data,
  });
}

export async function getUploadedCandidateStats(talentscoutId) {
  return await api({
    baseUrl,
    url: "/api/admin/talentscout/" + talentscoutId + "/uploadedStats",
    method: "GET",
  });
}

export async function getTalentscoutAssignedJobs(talentscoutId) {
  return await api({
    baseUrl,
    url: "/api/admin/assignedJobs/" + talentscoutId,
    method: "GET",
  });
}

export async function updateTalentscoutAssignedJobs(action, data) {
  return await api({
    baseUrl,
    url: "/api/admin/assignedJobs/" + action,
    method: "POST",
    data: data,
  });
}

export async function getWorkspacesByUserEmail(userEmail) {
  return await api({
    baseUrl,
    url: "/api/admin/workspaces/byemail",
    method: "GET",
    params: [{ name: "email", value: userEmail }],
  });
}

export async function removeCandidateFunnelItem(jobId, itemId, data) {
  return await api({
    baseUrl,
    url: "/api/admin/job/" + jobId + "/funnel/item/" + itemId + "/remove",
    method: "POST",
    data,
  });
}

export async function getCandidateInfo(data) {
  return await api({
    baseUrl,
    url: "/api/admin/candidates/info",
    method: "POST",
    data,
  });
}
