import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setUser } from "../redux/actions/user";
import { createJob, getJobById } from "../api/job";
import { toaster } from "baseui/toast";
import { EMPLOYER_POSTED_JOBS } from "../util/routes";
import Title from "../components/Title";
import * as Analytics from "../analytics";
import PostJobForm from "../components/PostJobForm";
import { CenterContainer } from "../components/CenterContainer";
import Card from "../components/Card";

class PostJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
      clear: false,
      values: {
        jobInternalID: "",
        company: "",
        jobTitle: "",
        functionalAreas: [],
        locations: [],
        minExperience: "",
        maxExperience: "",
        jobDescription: "",
        salaryCurrency: { id: "INR", name: "₹" },
        skills: [],
        minimumSalary: 0,
        maximumSalary: 0,
        targetCompanies: [],
        coolDownPeriod: 0,
        coolDownPeriodQuestion: "",
        recruiterNote: "",
      },
      errors: {},
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[E] page view post job");
    const params = new URLSearchParams(this.props.location.search);
    const fromJobId = params.get("fromId");
    if (fromJobId) {
      const { job: fromJob } = await getJobById(fromJobId);
      this.setState({
        values: {
          ...this.state.values,
          company: this.props.workspace.company,
          ...fromJob,
        },
      });
    } else {
      this.setState({
        values: {
          ...this.state.values,
          company: this.props.workspace.company,
        },
      });
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.workspace.id !== prevProps.workspace.id) {
      this.setState({
        values: {
          company: this.props.workspace.company,
        },
      });
    }
  }

  onContinue = async (values) => {
    Analytics.logEvent("[E] click submit post job");
    this.setState({
      isLoading: true,
    });
    try {
      let newValues = JSON.parse(JSON.stringify(values));
      newValues.salaryCurrency = values.salaryCurrency.id;
      newValues.workspaceId = this.props.workspace.id;
      newValues.createdByName = this.props.user.name;
      await createJob(newValues);
      this.setState(
        {
          values: {
            jobInternalID: "",
            company: "",
            jobTitle: "",
            functionalAreas: [],
            locations: [],
            minExperience: "",
            maxExperience: "",
            jobDescription: "",
            salaryCurrency: { id: "INR", name: "₹" },
            skills: [],
            targetCompanies: [],
            coolDownPeriod: 0,
            coolDownPeriodQuestion: "",
            minimumSalary: 0,
            maximumSalary: 0,
          },
          isLoading: false,
        },
        () => {
          this.props.history.push(EMPLOYER_POSTED_JOBS);
        }
      );
      toaster.positive(<div>Add to your posted jobs</div>, {
        autoHideDuration: 3000,
      });
    } catch (e) {
      console.log("error while creating jobs");
      alert("Error while creating job");
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    return (
      <CenterContainer>
        <Title marginBottom="scale300">Post Job</Title>
        <Card>
          <PostJobForm
            job={this.state.values}
            buttonLabel={"Create Job"}
            onSubmit={this.onContinue}
          />
        </Card>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
    workspace: state.workspace,
  }),
  { setUser }
)(withRouter(PostJob));
