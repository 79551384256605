import React, { useState } from "react";
// import Button from "./Button";
import Input from "./Input";
import { LabelSmall, ParagraphMedium, ParagraphSmall } from "baseui/typography";
import { Button } from "@chakra-ui/button";
import { Text } from "@chakra-ui/layout";
import { FiPlus } from "react-icons/fi";

export const AddFunnelList = ({ onCreate }) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [val, setVal] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div>
      {showPrompt ? (
        <div>
          <Text fontSize="xs" textTransform="uppercase">
            Add New List
          </Text>
          <Input value={val} onChange={(e) => setVal(e.target.value)} />
          <div style={{ height: 16 }}></div>
          <Button
            onClick={async () => {
              setIsLoading(true);
              const { isCreated, error } = await onCreate(val);
              setIsLoading(false);
              if (error) {
                setError(error);
                return;
              }
              if (isCreated) {
                setVal("");
                setShowPrompt(false);
              }
            }}
          >
            Create
          </Button>
          <Button
            variant="ghost"
            onClick={async () => {
              setVal("");
              setShowPrompt(false);
            }}
          >
            Cancel
          </Button>
          {error ? <div style={{ color: "red" }}>{error}</div> : null}
        </div>
      ) : (
        <Button
          onClick={() => {
            setShowPrompt(true);
          }}
          leftIcon={<FiPlus />}
          isLoading={isLoading}
          variant="link"
          colorScheme="white"
        >
          Add Stage
        </Button>
      )}
    </div>
  );
};
