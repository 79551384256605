import React from "react";
import Button from "../components/Button";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import { toaster } from "baseui/toast";
import Verify from "../components/Verify";
import { sendCode, verifyCode } from "../api/otp";
import {
  CANDIDATE_PROFILE_BASIC,
  CANDIDATE_PROFILE_SUMMARY,
} from "../util/routes";
import Title from "../components/Title";
import * as Analytics from "../analytics";
import { CenterContainer } from "../components/CenterContainer";
import { updateCandidateProfile } from "../api/candidate";

class CandidateProfileVerifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidate: {},
      isLoaded: false,
      isLocationError: false,
      isJobFunctionsError: false,
      phoneVerified: false,
      isResendingPhone: false,
      isVerifyingPhone: false,
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[C] page view profile verifications");
    try {
      this.setState({
        phoneVerified: this.props.user.phoneVerified,
      });
      if (!this.props.user.phoneVerified) {
        this.sendPhoneOTP(
          this.props.user.phonePrefix &&
            this.props.user.phonePrefix.dialCode + this.props.user.phone
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  async verifyPhoneOTP(code) {
    Analytics.logEvent("[C] click verify otp phone");
    this.setState({
      isVerifyingPhone: true,
    });
    const { user } = this.props;
    try {
      await verifyCode({
        sendType: "phone",
        sendId: user.phonePrefix && user.phonePrefix.dialCode + user.phone,
        code: code,
      });
      await updateCandidateProfile(user.id, "verifications");
      if ((user.candidateOnboarding || []).indexOf("verifications") === -1) {
        user.candidateOnboarding.push("verifications");
      }
      this.setState({
        phoneVerified: true,
      });
      this.props.setUser({
        phoneVerified: true,
        candidateOnboarding: user.candidateOnboarding,
      });
    } catch (e) {}
    this.setState({
      isVerifyingPhone: false,
    });
  }

  async sendPhoneOTP(phone, isResending) {
    if (isResending) {
      Analytics.logEvent("[C] click resend otp phone");
    }
    this.setState({
      isResendingPhone: true,
    });
    try {
      await sendCode({
        sendType: "phone",
        sendId: phone,
      });
      if (isResending) {
        toaster.show("We have resend the OTP to your phone.");
      }
    } catch (e) {}
    this.setState({
      isResendingPhone: false,
    });
  }

  render() {
    return (
      <CenterContainer>
        <Title>Verifications</Title>
        <Verify
          sendType={"phone"}
          editUrl={CANDIDATE_PROFILE_BASIC}
          displaySendId={
            this.props.user.phonePrefix &&
            this.props.user.phonePrefix.dialCode + " " + this.props.user.phone
          }
          sendId={
            this.props.user.phonePrefix &&
            this.props.user.phonePrefix.dialCode + this.props.user.phone
          }
          isVerified={this.state.phoneVerified}
          isVerifying={this.state.isVerifyingPhone}
          isResending={this.state.isResendingPhone}
          resendCode={async () => {
            await this.sendPhoneOTP(
              this.props.user.phonePrefix &&
                this.props.user.phonePrefix.dialCode + this.props.user.phone,
              true
            );
          }}
          verifyCode={async (code) => {
            await this.verifyPhoneOTP(code);
          }}
        />
        <Button
          style={{ marginTop: 32, opacity: this.state.phoneVerified ? 1 : 0.5 }}
          type={"primary"}
          onClick={async () => {
            if (this.state.phoneVerified) {
              this.props.history.push(CANDIDATE_PROFILE_SUMMARY);
            } else {
              toaster.negative(
                <div>Please complete verifications to proceed</div>,
                {
                  autoHideDuration: 4000,
                }
              );
            }
          }}
        >
          Continue
        </Button>
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(CandidateProfileVerifications));
