import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { searchEntity } from "src/api/entity";
import {
  ReferredCandidateProps,
  updateReferCandidate,
} from "src/api/referred_candidate";
import { AutoComplete, AutoCompleteItem } from "src/components/AutoComplete";
import { ReferredCandidateSchema } from "src/lib/forms/Talentscout/ReferCandidate/ReferredCandidateSchema";
import { getCurrencyWords } from "src/util/currency";
import { Salary } from "./Salary";
import { UpdateModal } from "./UpdateModal";

export type ReferredCandidateUpdateModalProps = {
  editKey: string;
  referredCandidate: ReferredCandidateProps;
  isUpdateModalOpen: boolean;
  setIsUpdateModalOpen: (b: boolean) => void;
  setReferredCandidate: (r: ReferredCandidateProps) => void;
};
export const ReferredCandidateUpdateModal = ({
  editKey,
  referredCandidate,
  isUpdateModalOpen,
  setIsUpdateModalOpen,
  setReferredCandidate,
}: ReferredCandidateUpdateModalProps) => {
  const [errors, setErrors] = useState<
    {
      [key in keyof ReferredCandidateProps]?: string;
    }
  >({});
  const onFormValidate = async (
    editKey: keyof ReferredCandidateProps,
    value: any
  ) => {
    try {
      await ReferredCandidateSchema.validateAt(editKey, value);
      setErrors({ [editKey]: undefined });
    } catch (e) {
      if (e instanceof Error) {
        setErrors({ [editKey]: e.message });
      }
    }
  };
  return (
    <UpdateModal
      referredCandidate={referredCandidate}
      isOpen={isUpdateModalOpen}
      onClose={() => {
        setIsUpdateModalOpen(false);
      }}
      onSaveClick={async (values) => {
        if (!referredCandidate.id) return false;
        if (Object.values(errors).filter((v) => !!v).length > 0) {
          return false;
        }
        setReferredCandidate({
          ...referredCandidate,
          ...values,
        });
        return true;
      }}
      render={(values, onChange) => {
        if (editKey === "email" || editKey === "phone") {
          return (
            <FormControl isInvalid={!!errors?.[editKey]}>
              <FormLabel textTransform={"capitalize"} htmlFor={editKey}>
                {editKey}
              </FormLabel>
              <Input
                value={values[editKey]}
                autoFocus
                autoCorrect="no"
                autoComplete="no"
                id={editKey}
                placeholder={`Enter ${editKey}`}
                onChange={(e) => {
                  onChange({ [editKey]: e.target.value });
                  onFormValidate(editKey, {
                    [editKey]: e.target.value,
                  });
                }}
              />
              <FormErrorMessage>{errors?.[editKey]}</FormErrorMessage>
            </FormControl>
          );
        }
        if (editKey === "expectedSalary" || editKey === "currentSalary") {
          return (
            <FormControl isInvalid={!!errors?.[editKey]}>
              <FormLabel htmlFor="currentSalary">
                {editKey === "expectedSalary"
                  ? "Expected salary"
                  : "Current salary"}
              </FormLabel>
              {/* <InputGroup>
                  <Input
                    id={editKey}
                    placeholder={`Enter ${editKey}`}
                    onChange={(e) => {
                      onChange({
                        [editKey]: parseCurrency(e.target.value) * 100000,
                      });
                      onFormValidate(editKey, {
                        [editKey]: parseCurrency(e.target.value) * 100000,
                      });
                    }}
                    value={formatCurrency((values[editKey] || 0) / 100000)}
                  />
                  <InputRightAddon children="Lakhs per annum" />
                </InputGroup> */}

              <Salary
                id={editKey}
                placeholder={`Enter ${editKey}`}
                onChange={(value) => {
                  onChange({
                    [editKey]: value,
                  });
                  onFormValidate(editKey, {
                    [editKey]: value,
                  });
                }}
                value={values[editKey] || 0}
              />

              <FormHelperText>
                {getCurrencyWords(values[editKey] || 0)}
              </FormHelperText>
              <FormErrorMessage>{errors?.[editKey]}</FormErrorMessage>
            </FormControl>
          );
        }
        if (editKey === "lastWorkingDate") {
          return (
            <FormControl isInvalid={!!errors?.lastWorkingDate}>
              <FormLabel htmlFor="lastWorkingDate" fontWeight="500">
                Last Working Date
              </FormLabel>
              <Input
                id="lastWorkingDate"
                type="date"
                placeholder=""
                onChange={(e) => {
                  const newDate = new Date(e.target.value);
                  // Invalid date object check
                  // eslint-disable-next-line no-self-compare
                  if (newDate.getTime() === newDate.getTime()) {
                    onChange({ [editKey]: newDate.getTime() });
                    onFormValidate(editKey, {
                      [editKey]: newDate.getTime(),
                    });
                  }
                }}
                value={
                  new Date(values["lastWorkingDate"] || 0)
                    .toISOString()
                    .split("T")[0]
                }
              />
              <FormErrorMessage>{errors?.lastWorkingDate}</FormErrorMessage>
            </FormControl>
          );
        }
        if (editKey === "preferredLocations") {
          return (
            <FormControl isInvalid={!!errors?.preferredLocations}>
              <FormLabel htmlFor="preferredLocations">
                Preferred locations
              </FormLabel>
              <AutoComplete
                value={values[editKey]}
                id="preferredLocations"
                items={[]}
                isMultiple
                fetchItems={async (q) => searchEntity("location", q)}
                selected={values[editKey]}
                onItemSelect={(
                  selected: AutoCompleteItem | AutoCompleteItem[]
                ) => {
                  onChange({ preferredLocations: selected });
                  onFormValidate(editKey, {
                    [editKey]: selected,
                  });
                }}
              />

              <FormErrorMessage>{errors?.preferredLocations}</FormErrorMessage>
            </FormControl>
          );
        }
        if (editKey === "noticePeriod") {
          return (
            <FormControl isInvalid={!!errors?.[editKey]}>
              <FormLabel htmlFor="noticePeriod">Notice period</FormLabel>
              <Select
                value={values[editKey]}
                id="noticePeriod"
                onChange={(e) => {
                  onChange({ [editKey]: +e.target.value });
                  onFormValidate(editKey, {
                    [editKey]: +e.target.value,
                  });
                }}
              >
                {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(
                  (i) => (
                    <option value={i}>{`${i} weeks`}</option>
                  )
                )}
              </Select>
              <FormErrorMessage>{errors?.[editKey]}</FormErrorMessage>
            </FormControl>
          );
        }

        if (editKey === "jobOffer") {
          return (
            <FormControl isInvalid={!!errors?.[editKey]}>
              <FormLabel htmlFor="jobOffer">Highest another offer</FormLabel>
              <Salary
                id={editKey}
                placeholder={`Enter another offer`}
                onChange={(value) => {
                  const jobOffer = {
                    ...values.jobOffer,
                    offeredSalary: value,
                  };
                  onChange({ [editKey]: jobOffer });
                  onFormValidate(editKey, {
                    [editKey]: jobOffer,
                  });
                }}
                value={values[editKey]?.offeredSalary || 0}
              />
              <FormHelperText>
                {getCurrencyWords(values[editKey]?.offeredSalary || 0)}
              </FormHelperText>
              <FormErrorMessage>{errors?.[editKey]}</FormErrorMessage>
            </FormControl>
          );
        }
        return null;
      }}
    />
  );
};
