import React, { useEffect, useState } from "react";
import CheckboxGroup from "./CheckboxGroup";
import { searchCandidateFacets } from "../api/candidate";
import { searchEntity } from "../api/entity";

const FilterCheckboxList = (props) => {
  const [options, setOptions] = useState(props.options || []);
  const [isFetching, setIsFetching] = useState(false);
  const getFacetPath = (name) => {
    switch (name) {
      case "company":
        return "experience.company.name";
      case "college":
        return "education.college.name";
      case "jobFunctions":
        return "jobFunctions.name";
      default:
    }
    return "";
  };
  useEffect(() => {
    async function fetchOptions() {
      try {
        if (props.isFetchOptions) {
          if (props.name === "jobFunctions") {
            setIsFetching(true);
            let options = await searchEntity("jobFunction", "");
            options.sort((o1, o2) => (o1.name > o2.name ? 1 : -1));
            setIsFetching(false);
            setOptions(
              options.map((opt) => ({ id: opt.name, name: opt.name }))
            );
          } else {
            setIsFetching(true);
            let { FacetHits: facetHits = [] } = await searchCandidateFacets(
              getFacetPath(props.name),
              ""
            );
            setIsFetching(false);
            setOptions(
              facetHits.map((facet) => ({
                id: facet.value,
                name: facet.value,
              }))
            );
          }
        }
      } catch (e) {
        setIsFetching(false);
      }
    }
    fetchOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {/* <LabelSmall $marginBottom={"8px"}>
        {props.name}
      </LabelSmall> */}
      {isFetching ? (
        <div>Loading ...</div>
      ) : (
        <CheckboxGroup
          single={props.single}
          options={options}
          value={props.value}
          onChange={(val) => props.onChange(props.name, val)}
        />
      )}
    </div>
  );
};
export default FilterCheckboxList;
