import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Box,
  Text,
  Stack,
  Flex,
  Checkbox,
  FormControl,
  RadioGroup,
  Radio,
  Tag,
  Spinner,
  Avatar,
  toast,
  useToast,
} from "@chakra-ui/react";
import { createInterviewRequest as createInterviewRequestAPI } from "src/api/employer";
import { getLastExperienceTitle } from "src/util/common";
import { getExperienceDuration } from "src/util/time";
import { CandidateTags } from "src/components/employer/CandidateTags";
import Currency from "src/components/Currency";
import { getCandidateOffers } from "src/api/candidate";
import { Salary } from "src/pages/candidate/referred-candidate/Salary";
import { FiCheck, FiX } from "react-icons/fi";
import { useSelector } from "react-redux";

export type BulkCreateInterviewDrawerRef = {
  open: () => void;
  close: () => void;
};

export type InterviewRowRef = {
  createInterview: () => Promise<boolean>;
  isEnabled: () => boolean;
};

const InterviewRow = forwardRef<
  InterviewRowRef,
  {
    candidate: any;
    jobId: string;
    isOpen: boolean;
  }
>(({ candidate, jobId, isOpen }, ref) => {
  const [offeredCTCOption, setOfferedCTCOption] = useState<number>(1);
  const [offeredCTCOptions, setOfferedCTCOptions] = useState<
    { ctc: number; currency: string }[]
  >([]);
  const [offersLoading, setOffersLoading] = useState(false);
  const [offeredCTC, setOfferedCTC] = useState<number>(0);
  const offeredCTCCurrency = "INR";
  const user = useSelector((state: any) => state.user);
  const workspace = useSelector((state: any) => state.workspace);
  const [isEnabled, setIsEnabled] = useState(true);
  const [isDone, setIsDone] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isError, setIsError] = useState("");

  const getOffers = useCallback(async () => {
    setOffersLoading(true);
    try {
      let { offers = [], expectedCTC, expectedCTCCurrency } =
        (await getCandidateOffers(candidate.userId)) || {};
      const fjOffers = offers.filter((off: any) => off.source === "FINDINGJO");
      const outsideOffers = offers.filter(
        (off: any) => off.source === "OUTSIDE"
      );

      fjOffers.sort((a: any, b: any) => a.ctc - b.ctc);
      outsideOffers.sort((a: any, b: any) => a.ctc - b.ctc);

      const maxOffer = fjOffers
        .concat(outsideOffers)
        .concat([{ ctc: expectedCTC, currency: expectedCTCCurrency }])
        .reduce((prev: any, current: any) => {
          if (prev.ctc > current.ctc) {
            return prev;
          }
          return current;
        });
      const recommendedOffer = {
        currency: maxOffer.currency,
        ctc: Math.floor(maxOffer.ctc * 1.1),
      };
      const _offeredCTCOptions = [];
      _offeredCTCOptions.push({
        currency: maxOffer.currency,
        ctc: maxOffer.ctc,
      });
      _offeredCTCOptions.push(recommendedOffer);
      setOfferedCTCOptions(_offeredCTCOptions);
    } catch (e) {}
    setOffersLoading(false);
  }, [candidate.userId]);

  useEffect(() => {
    setOfferedCTC(offeredCTCOptions[offeredCTCOption]?.ctc ?? 0);
  }, [offeredCTCOption, offeredCTCOptions]);

  useEffect(() => {
    if (isOpen) getOffers();
  }, [getOffers, candidate.userId, isOpen]);

  const createInterview = async () => {
    setIsCreating(true);
    setIsError("");
    if (offeredCTC < offeredCTCOptions[0].ctc) {
      setIsCreating(false);
      setIsError(
        `Offered ctc should be greater than ₹${offeredCTCOptions[0].ctc}`
      );
      return false;
    }
    try {
      await createInterviewRequestAPI({
        createdByName: user.name,
        workspaceId: workspace.id,
        anonymousId: candidate.userId,
        employerId: user.id,
        jobId: jobId,
        offeredCTC: offeredCTC,
        offeredCTCCurrency: offeredCTCCurrency,
      });
      setIsDone(true);
      setIsCreating(false);
      return true;
    } catch (e) {
      const err = e as any;
      setIsError(err?.response?.data?.actionText ?? "Unable to create");
    }
    setIsCreating(false);
    return false;
  };

  useImperativeHandle(ref, () => ({
    createInterview,
    isEnabled: () => isEnabled && !isDone,
  }));

  const isDisabled = !isEnabled || isDone;
  return (
    <Box
      borderRadius={"md"}
      borderWidth={1}
      boxShadow="base"
      p={4}
      bg={isEnabled ? "inherit" : "gray.100"}
    >
      <Flex
        alignItems="flex-start"
        justifyContent={"space-between"}
        flexWrap="wrap"
      >
        <Flex alignItems="flex-start" maxWidth={"300px"}>
          <Checkbox
            size="lg"
            isDisabled={isDone}
            colorScheme="orange"
            isChecked={isEnabled}
            onChange={(e) => {
              setIsEnabled(e.target.checked);
            }}
            mt={2}
            mr={4}
          />
          <Box>
            <Text fontWeight={"medium"}>
              {getLastExperienceTitle(candidate.experience) ||
                candidate.lastExperienceTitle ||
                "Fresher"}
            </Text>
            <Text fontSize="sm" color="gray.500">
              {getExperienceDuration(candidate.totalExperience)}
            </Text>
            <CandidateTags candidate={candidate} />
            <Text fontSize="sm">{`Exp. CTC ${new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
              notation: "compact",
            })
              .format(candidate.expectedCTC || 0)
              .replace("T", "K")}`}</Text>

            <Text
              color="green.500"
              fontSize="sm"
            >{`Price: ${new Intl.NumberFormat("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            })
              .format(candidate.rcAmount / 100)
              .replace("T", "K")}`}</Text>
            {isEnabled && (
              <Text
                color="orange.500"
                fontSize="sm"
              >{`Your offer: ${new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              })
                .format(offeredCTC || 0)
                .replace("T", "K")}`}</Text>
            )}
          </Box>
        </Flex>
        <Box width={"400px"}>
          {isDone ? (
            <Flex alignItems={"center"} mb={2}>
              <Avatar
                size="sm"
                bg="green.500"
                icon={<FiCheck />}
                color="white"
                mr={2}
              />
              <Text color="green.500">Interview created</Text>
            </Flex>
          ) : null}

          {isError ? (
            <Flex alignItems={"center"} mb={2}>
              <Avatar
                size="sm"
                bg="red.500"
                icon={<FiX />}
                color="white"
                mr={2}
              />
              <Text color="red.500">{`Failed to create: ${isError}`}</Text>
            </Flex>
          ) : null}

          {isCreating ? (
            <Flex alignItems={"center"} mb={2}>
              <Spinner mr={2} />
              <Text color="red.500">Creating interview request</Text>
            </Flex>
          ) : null}

          {!isDisabled ? (
            <FormControl
              label={"Offered CTC(Fixed + Variable)"}
              caption={<div>{"required"}</div>}
              fontSize="sm"
            >
              {offersLoading ? (
                <Spinner />
              ) : (
                <RadioGroup
                  value={offeredCTCOption}
                  onChange={(val) => {
                    setOfferedCTC(offeredCTCOptions[+val]?.ctc || 0);
                    setOfferedCTCOption(+val);
                  }}
                >
                  <Radio value={0} mb={2}>
                    <Flex alignItems="center" fontSize="sm">
                      <Currency
                        inWords
                        value={offeredCTCOptions[0]?.ctc}
                        currency={offeredCTCOptions[0]?.currency}
                      />
                      <Text ml={2} color="gray.400">
                        per Year
                      </Text>
                      <Text>(Match Best Offer)</Text>
                    </Flex>
                  </Radio>
                  <Radio value={1} mb={2}>
                    <Flex fontSize="sm">
                      <Currency
                        inWords
                        value={offeredCTCOptions[1]?.ctc}
                        currency={offeredCTCOptions[1]?.currency}
                      />
                      <Text ml={2} color="gray.400">
                        per Year
                      </Text>
                      <Tag colorScheme="orange" variant={"outline"} ml={2}>
                        Recommended
                      </Tag>
                      <Text ml={2} color="gray.400">
                        (+ 10%)
                      </Text>
                    </Flex>
                  </Radio>
                  <Box>
                    <Radio value={2}>
                      <Text fontSize="sm">Enter CTC</Text>
                    </Radio>
                    {offeredCTCOption === 2 && (
                      <Salary
                        id={"offeredCTC"}
                        onChange={(val: number) => {
                          setOfferedCTC(val);
                        }}
                        placeholder={"in lakhs"}
                        value={offeredCTC || 0}
                      />
                    )}
                  </Box>
                </RadioGroup>
              )}
            </FormControl>
          ) : null}
        </Box>
      </Flex>
    </Box>
  );
});

export const BulkCreateInterviewDrawer = forwardRef<
  BulkCreateInterviewDrawerRef,
  { candidates: any[]; jobId: string; onDone: () => void }
>(({ candidates, jobId, onDone }, ref) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [doneCount, setDoneCount] = useState(0);
  useImperativeHandle(ref, () => ({
    open: onOpen,
    close: onClose,
  }));
  const itemsRef = useRef<InterviewRowRef[]>([]);
  const toast = useToast();
  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, candidates.length);
  }, [candidates]);

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={() => {
        onDone();
        onClose();
      }}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create bulk interviews</DrawerHeader>
        <DrawerBody>
          {candidates.length === 0 ? (
            <Box>
              <Text>No candidates remaining to create interviews </Text>
            </Box>
          ) : (
            <Stack spacing={4}>
              {candidates.map((c, idx) => (
                <InterviewRow
                  key={idx}
                  ref={(el: any) => {
                    itemsRef.current[idx] = el;
                  }}
                  jobId={jobId}
                  candidate={c}
                  isOpen={isOpen}
                />
              ))}
            </Stack>
          )}
        </DrawerBody>
        <DrawerFooter>
          <Button
            colorScheme={"green"}
            isLoading={isLoading}
            loadingText={`Creating ${doneCount}`}
            onClick={async () => {
              setIsLoading(true);
              let successCount = 0;
              for (let i = 0; i < itemsRef.current?.length; i++) {
                setDoneCount(i + 1);
                if (itemsRef.current?.[i].isEnabled()) {
                  const isSuccess = await itemsRef.current?.[
                    i
                  ].createInterview();
                  if (isSuccess) {
                    successCount++;
                  }
                }
              }
              setIsLoading(false);
              if (successCount > 0) {
                toast({
                  title: `${successCount} interviews were created and moved to interview stage`,
                });
              } else {
                toast({
                  title: `Failed to create interviews`,
                });
              }
            }}
          >
            Create interviews
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
});
