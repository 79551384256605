import api from "./index";
import { baseUrl } from "./endpoints";

export async function getCandidate(userId) {
  return await api({
    baseUrl,
    url: "/api/cd/" + userId,
    method: "GET",
  });
}

export async function getAnonymousCandidate(userId) {
  return await api({
    baseUrl,
    url: "/api/cd/" + userId + "/anonymous",
    method: "GET",
  });
}

export async function getPublicCandidate(userId) {
  return await api({
    baseUrl,
    url: "/api/public/candidate/" + userId,
    method: "GET",
  });
}

export async function getMyAnonymousCandidate(userId) {
  return await api({
    baseUrl,
    url: "/api/cd/" + userId + "/anonymous/my",
    method: "GET",
  });
}

export async function getCandidates() {
  return await api({
    baseUrl,
    url: "/api/cd/list",
    method: "GET",
  });
}

export async function getCandidateInterviewRequest(interviewRequestId) {
  return await api({
    baseUrl,
    url: "/api/cd/interview/" + interviewRequestId,
    method: "GET",
  });
}

export async function getCandidateProjection(userId, item) {
  return await api({
    baseUrl,
    url: "/api/cd/" + userId + "/projection/" + item,
    method: "GET",
  });
}

export async function updateCandidateProfile(userId, section, data) {
  return await api({
    baseUrl,
    url: "/api/cd/" + userId + "/" + section + "/update",
    method: "POST",
    data: data,
  });
}

export async function searchCandidate(data) {
  return await api({
    baseUrl,
    url: "/api/candidates/search",
    method: "POST",
    data: data,
  });
}

export async function unlockCandidate(data) {
  return await api({
    baseUrl,
    url: "/api/cd/unlock",
    method: "POST",
    data: data,
  });
}

export async function submitCandidate(data) {
  return await api({
    baseUrl,
    url: "/api/cd/submit",
    method: "POST",
    data: data,
  });
}

export async function searchCandidateFacets(facet, query) {
  return await api({
    baseUrl,
    url: "/api/cd/facets/search",
    method: "GET",
    params: [
      { name: "facet", value: facet },
      { name: "query", value: query },
    ],
  });
}

export async function getTalentScoutRequests(candidateId, page, limit) {
  return await api({
    baseUrl,
    url: "/api/cd/" + candidateId + "/ts/requests",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
    ],
  });
}

export async function disableCandidate(candidateId) {
  return await api({
    baseUrl,
    url: "/api/cd/" + candidateId + "/disable",
    method: "POST",
  });
}

export async function approveTalentScoutRequest(data) {
  return await api({
    baseUrl,
    url: "/api/cd/ts/requests/approve",
    method: "POST",
    data,
  });
}
export async function rejectTalentScoutRequest(data) {
  return await api({
    baseUrl,
    url: "/api/cd/ts/requests/reject",
    method: "POST",
    data,
  });
}

export async function acceptInterviewRequest(data) {
  return await api({
    baseUrl,
    url: "/api/cd/interview/accept",
    method: "POST",
    data,
  });
}

export async function rejectInterviewRequest(data) {
  return await api({
    baseUrl,
    url: "/api/cd/interview/reject",
    method: "POST",
    data,
  });
}

export async function getCandidateInterviewRequests(
  candidateId,
  page,
  limit,
  status
) {
  return await api({
    baseUrl,
    url: "/api/cd/" + candidateId + "/interviews",
    method: "GET",
    params: [
      { name: "page", value: page },
      { name: "limit", value: limit },
      { name: "status", value: status },
    ],
  });
}

export async function getCandidateOffers(candidateId) {
  return await api({
    baseUrl,
    url: "/api/cd/" + candidateId + "/offers",
    method: "GET",
  });
}

export async function updateCandidateStat(candidateId, statName) {
  return await api({
    baseUrl,
    url: "/api/cd/" + candidateId + "/stat/" + statName,
    method: "POST",
  });
}

export async function updateCandidateShareImage(candidateId, shareImageUrl) {
  return await api({
    baseUrl,
    url: "/api/cd/shareImage",
    method: "POST",
    data: {
      id: candidateId,
      shareImageUrl,
    },
  });
}
