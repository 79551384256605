import React from "react";
import { setUser } from "./redux/actions/user";
import { connect } from "react-redux";
import { styled } from "styletron-react";
import { sendCode } from "./api/otp";
import PageHeader from "./components/Header";
import Title from "./components/Title";
import Button from "./components/Button";
import Input from "./components/Input";
import { withRouter, Link } from "react-router-dom";
import { resetPasswordUser } from "./api/user";
import { PinCode } from "baseui/pin-code";
import { validatePassword } from "./util/validator";
import FormControl from "./components/FormControl";
import Label from "./components/Label";
import EmptyCaption from "./components/EmptyCaption";
import * as Analytics from "./analytics";
import { KIND, SIZE } from "baseui/button";

const ResendText = styled("div", {
  fontSize: "12px",
  color: "#919191",
});

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isResetting: false,
      resetError: false,
      isSending: false,
      timeRemaining: 60,
      newPassword: "",
      newPasswordError: "",
      resetSuccess: false,
      values: ["", "", "", "", "", ""],
    };
  }

  checkPassword = (e) => {
    if (!validatePassword(this.state.password)) {
      this.setState({
        passwordError: "Password should be atleast 8 characters",
      });
      return false;
    } else {
      this.setState({
        passwordError: "",
      });
      return true;
    }
  };

  updateTimeRemaining() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = setTimeout(() => {
      let timeRemaining = this.state.timeRemaining;
      if (timeRemaining > 0) {
        this.setState({
          timeRemaining: this.state.timeRemaining - 1,
        });
        this.updateTimeRemaining();
      }
    }, 1000);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  async componentDidMount() {
    Analytics.logEvent("page view reset password");
    let { email } = this.props.location.state || {};
    if (!email) {
      this.props.history.push("/forgotPassword");
      return;
    }
    this.updateTimeRemaining();
  }

  async sendOTP() {
    let { email } = this.props.location.state || {};
    this.setState({
      isLoading: true,
      isSending: true,
      sendCodeError: false,
    });
    try {
      await sendCode({
        sendType: "email",
        sendId: email,
      });
    } catch (e) {
      this.setState({
        sendCodeError: true,
        isSending: false,
      });
    }
    this.setState({
      isLoading: false,
      isSending: false,
      code: "",
    });
  }

  async verifyOTP() {
    let code = this.state.values.join("");
    let { email } = this.props.location.state || {};
    let { password } = this.state;
    if (!this.checkPassword()) {
      return;
    }
    this.setState({
      isResetting: true,
    });
    try {
      await resetPasswordUser({
        email,
        password,
        otp: code,
      });
      this.setState({
        resetSuccess: true,
        isResetting: false,
      });
    } catch (e) {
      this.setState({
        resetError: true,
        isResetting: false,
      });
    }
  }

  render() {
    let { email } = this.props.location.state || {};
    return (
      <div
        style={{
          width: "480px",
          margin: "auto",
          marginTop: "100px",
          backgroundColor: "white",
          borderRadius: "4px",
          padding: 32,
          border: "1px solid #d8d8d8",
        }}
      >
        {this.state.resetSuccess ? (
          <div>
            <Title>Reset Password Successfull</Title>
            <div style={{ marginBottom: 32 }}>
              Successfully changed the password
            </div>
            <Button onClick={() => this.props.history.push("/login")}>
              Go to Sign In
            </Button>
          </div>
        ) : (
          <div>
            <Title>Reset Password</Title>
            <FormControl
              label={<Label>{"Enter your new password"}</Label>}
              caption={<EmptyCaption />}
              error={this.state.passwordError}
            >
              <Input
                id={"password"}
                type="password"
                onChange={(e) => this.setState({ password: e.target.value })}
                value={this.state.password}
                autoComplete={"off"}
                onBlur={this.checkPassword}
              />
            </FormControl>
            <div style={{ marginBottom: "24px" }}>
              {"We have sent a 6 digit code to your email " +
                email +
                " . Please enter the code below"}
            </div>
            <PinCode
              values={this.state.values}
              onChange={({ values }) => this.setState({ values })}
            />
            {this.state.timeRemaining > 0 ? (
              <ResendText>
                {"Not received? Resend code in " +
                  this.state.timeRemaining +
                  " seconds"}
              </ResendText>
            ) : (
              <React.Fragment>
                <Button
                  kind={KIND.minimal}
                  size={SIZE.mini}
                  isLoading={this.state.sending}
                  onClick={async () => await this.sendOTP()}
                >
                  Resend Code
                </Button>
                <ResendText>
                  {this.state.isResending ? "Sending code. Please wait..." : ""}
                </ResendText>
              </React.Fragment>
            )}
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                isLoading={this.state.isResetting}
                onClick={() => this.verifyOTP()}
              >
                {"Reset Password"}
              </Button>
              <Link to={"/forgotPassword"}>
                <Button kind={KIND.minimal} size={SIZE.mini}>
                  {"Edit " + email}
                </Button>
              </Link>
            </div>
          </div>
        )}
        <PageHeader notLoggedIn />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(ResetPassword));
