import React from "react";
import Modal from "../components/Modal";
import Title from "../components/Title";
import Button from "../components/Button";
import { ModalHeader, ModalBody } from "baseui/modal";
import { setWidgetState } from "../redux/actions/widget";
import { setUser } from "../redux/actions/user";
import { connect } from "react-redux";
import TutorialCandidate from "../components/TutorialCandidate";
import TutorialTalentscout from "../components/TutorialTalentscout";
import TutorialEmployer from "../components/TutorialEmployer";

function getTutorialContent(role) {
  switch (role) {
    case "CANDIDATE":
      return <TutorialCandidate />;
    case "EMPLOYER":
      return <TutorialEmployer />;
    case "TALENTSCOUT":
      return <TutorialTalentscout />;
    default:
      console.log("Invalid role");
  }
}

const TutorialModel = (props) => (
  <Modal
    animate
    autoFocus
    isOpen={props.isTutorialModelVisible}
    closeable
    onClose={() => {
      props.setWidgetState({
        isTutorialModelVisible: false,
      });
    }}
  >
    <ModalHeader>
      <Title>{"How FindingJo works ?"}</Title>
    </ModalHeader>
    <ModalBody>
      <div style={{ padding: "32px" }}>
        {getTutorialContent(props.user.role)}
      </div>
    </ModalBody>
    <div style={{ padding: "12px", textAlign: "center" }}>
      <Button
        onClick={() =>
          props.setWidgetState({
            isTutorialModelVisible: false,
          })
        }
      >
        Got it
      </Button>
    </div>
  </Modal>
);

export default connect(
  (state) => ({
    user: state.user,
    isTutorialModelVisible: state.widget.isTutorialModelVisible,
  }),
  { setUser, setWidgetState }
)(TutorialModel);
