import React, { Suspense } from "react";
import { connect } from "react-redux";
import { setUser } from "../redux/actions/user";
import { withRouter } from "react-router-dom";
import Title from "../components/Title";
import Resume from "../components/Resume";
import { getEmployerInterviewRequest } from "../api/employer";
import LoadingScreen from "../components/LoadingScreen";
import Button from "../components/Button";
import InterviewRequestEmployer from "../components/InterviewRequestEmployer";
import * as Analytics from "../analytics";
import Card from "../components/Card";
import Entry from "../components/Entry";
import Currency from "../components/Currency";
import SubTitle from "../components/SubTitle";
import Bold from "../components/Bold";
import { convertToCandidateSearch } from "../util/common";
import { KIND } from "baseui/button";
import { Icon } from "../components/Icon";
import { CenterContainer } from "../components/CenterContainer";
import { Block } from "baseui/block";

const LazyResumePDFDownload = React.lazy(() =>
  import("../components/ResumePDFDownload")
);

class EmployerInterviewDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      candidate: {},
      interviewRequest: {},
      acceptedOffers: [],
      job: {},
      candidateUser: {},
      isFetching: true,
      isError: false,
      isAnonymous: false,
    };
  }

  async componentDidMount() {
    Analytics.logEvent("[E] page view interview details");
    this.setState({
      isFetching: true,
    });
    try {
      let { myCandidateId } = this.props.match.params;
      let {
        interviewRequest,
        candidate,
        job,
        candidateUser,
        acceptedOffers,
      } = await getEmployerInterviewRequest(myCandidateId);
      this.setState({
        isFetching: false,
        candidate,
        interviewRequest,
        job,
        candidateUser,
        acceptedOffers,
        isAnonymous: interviewRequest.status !== "APPROVED",
      });
    } catch (e) {
      this.setState({
        isFetching: false,
        isError: true,
      });
      console.log(e);
    }
  }

  render() {
    if (this.state.isFetching) {
      return (
        <CenterContainer>
          <LoadingScreen />
        </CenterContainer>
      );
    }
    if (this.state.isError) {
      return (
        <CenterContainer>
          <div>Errored. Please retry.....</div>
        </CenterContainer>
      );
    }
    return (
      <CenterContainer>
        <Title>Candidate Details</Title>
        {this.state.interviewRequest.status === "ONHOLD" ? (
          <Card
            style={{
              border: "1px solid #F8756C",
              marginBottom: "16px",
            }}
          >
            <div>
              {
                "This interview requests is rejected by FindingJo for following reasons"
              }
            </div>
            <div>{this.state.interviewRequest.statusReason}</div>
            <div>
              {"Please correct these errors and create a new interview request"}
            </div>
          </Card>
        ) : null}
        {/* {this.state.interviewRequest.status === "REPORTED" ? (
          <div>
            {
              "We have received your request to check regarding this interview request. We will check on this and will get back to you. "
            }
          </div>
        ) : null} */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            padding: "8px 0px",
            marginBottom: "8px",
          }}
        >
          <div style={{ display: "flex", flex: 1 }}></div>
          {this.state.interviewRequest.status === "REJECTED" && (
            <Block marginRight="scale400">
              <Button
                onClick={() => {
                  const baseInterviewLink =
                    "/employer/create-interview/" + this.state.candidate.userId;
                  const interviewLink = this.state.job.id
                    ? baseInterviewLink + "?jobId=" + this.state.job.id
                    : baseInterviewLink;
                  this.props.history.push(interviewLink);
                }}
              >
                Create Interview Request
              </Button>
            </Block>
          )}

          {/* <Button
            kind={KIND.tertiary}
            startEnhancer={<Icon size={16} name="download" />}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <LazyResumePDFDownload
                isAnonymous={this.state.isAnonymous}
                textColor={"#333333"}
                offeredCTCCurrency={
                  this.state.interviewRequest.offeredCTCCurrency
                }
                offeredCTC={this.state.interviewRequest.offeredCTC}
                candidate={this.state.candidate}
              />
            </Suspense>
          </Button> */}
          {/* {this.state.interviewRequest.status !== "REPORTED" ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <PopConfirm
                showArrow
                title="You want to report this interview ?"
                onConfirm={async () => {
                  try {
                    await reportInterviewRequest({
                      id: this.props.interviewRequestId,
                    });
                    toaster.positive(
                      "We have received your request. We will contact you with in 48 hours to resolve this issue"
                    );
                  } catch (e) {}
                }}
              >
                <Button
                  kind={BUTTON_KIND.tertiary}
                  startEnhancer={
                    <img
                      alt={""}
                      width={16}
                      height={16}
                      src={
                        "https://storage.googleapis.com/quickhire-stage/assets/icons/flag.svg"
                      }
                    />
                  }
                  size={SIZE.compact}
                  shape={SHAPE.round}
                >
                  {"Report Issue"}
                </Button>
              </PopConfirm>
            </div>
          ) : null} */}
        </div>
        {this.state.interviewRequest.status === "APPROVED" ? (
          <Card
            style={{
              marginBottom: 16,
            }}
          >
            <SubTitle>Contact details</SubTitle>
            <Entry
              label={"Candidate Name"}
              value={this.state.candidateUser.name}
            />
            <Entry
              label={"Candidate Email"}
              value={this.state.candidateUser.email}
            />
            <Entry
              label={"Candidate Phone"}
              value={this.state.candidateUser.phone}
            />
          </Card>
        ) : null}
        {
          <Card
            style={{
              marginBottom: 16,
            }}
          >
            <SubTitle>Offer details</SubTitle>
            <Entry label={"Job Company"} value={this.state.job.company.name} />
            <Entry label={"Job Title"} value={this.state.job.jobTitle.name} />
            <Entry
              label={"Job offered salary"}
              value={
                <Currency
                  currency={this.state.interviewRequest.offeredCTCCurrency}
                  value={this.state.interviewRequest.offeredCTC}
                ></Currency>
              }
            />
          </Card>
        }
        <InterviewRequestEmployer
          isClickable={false}
          isExpandedStatus
          candidate={convertToCandidateSearch(this.state.candidate)}
          interviewRequest={this.state.interviewRequest}
        />
        {this.state.acceptedOffers.length ? (
          <Card style={{ marginBottom: "16px" }}>
            <Bold>Other Accepted Offers</Bold>
            {this.state.acceptedOffers.map((acceptedOffer, idx) => (
              <Entry
                label={"#" + (idx + 1)}
                value={
                  <Currency
                    value={acceptedOffer.offeredCTC}
                    currency={acceptedOffer.offeredCTCCurrency}
                  />
                }
              />
            ))}
          </Card>
        ) : null}
        <Resume
          isAnonymous={this.state.isAnonymous}
          candidate={this.state.candidate}
        />
      </CenterContainer>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(EmployerInterviewDetails));
