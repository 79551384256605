import React from "react";
import {
  useEditableControls,
  ButtonGroup,
  IconButton,
  Flex,
  Editable,
  Heading,
  EditablePreview,
  EditableInput as EditableInputBase,
  EditableInputProps as EditableInputBaseProps,
  HeadingProps,
  TextProps,
  Text,
  EditableProps,
  Box,
} from "@chakra-ui/react";
import { FaCheck, FaTimes, FaEdit, FaPen } from "react-icons/fa";

export type EditableInputProps = {
  isHeading?: boolean;
  headingProps?: HeadingProps;
  textProps?: TextProps;
  inputProps?: EditableInputBaseProps;
} & EditableProps;

export const EditableInput = ({
  isHeading = false,
  inputProps,
  headingProps,
  textProps,
  ...rest
}: EditableInputProps) => {
  function EditableControls() {
    const {
      isEditing,
      getSubmitButtonProps,
      getCancelButtonProps,
      getEditButtonProps,
    } = useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton
          variant="link"
          aria-label="save"
          icon={<FaCheck />}
          {...getSubmitButtonProps()}
        />
        <IconButton
          variant="link"
          aria-label="cancel"
          icon={<FaTimes />}
          {...getCancelButtonProps()}
        />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton
          aria-label="edit"
          variant="link"
          icon={<FaPen />}
          {...getEditButtonProps()}
        />
      </Flex>
    );
  }

  return (
    <Editable defaultValue="" {...rest} role="group">
      <Flex alignItems="center">
        <EditablePreview />
        <EditableInputBase {...inputProps} />
        <Box opacity={0.3} _groupHover={{ opacity: 1 }}>
          <EditableControls />
        </Box>
      </Flex>
    </Editable>
  );
};
