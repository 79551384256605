import api from "./index";
import { baseUrl } from "./endpoints";
import { Entity } from "src/lib/types/candidate";


export type AddCompanyProps = Entity

export async function addCompanyApi(data: AddCompanyProps) {
    return await api({
        baseUrl,
        url: "/api/company",
        method: "POST",
        data,
    });
}

export async function getCompanyApi(id: string) {
    return await api({
        baseUrl,
        url: "/api/company/" + id,
        method: "GET",
        data: {},
    });
}

export type UpdateCompanyProps = Entity & {
    entityName?: string
}


export async function updateCompanyApi(id: string, data: UpdateCompanyProps) {
    return await api({
        baseUrl,
        url: "/api/company/" + id,
        method: "POST",
        data,
    });
}



export async function getCollegeApi(id: string) {
    return await api({
        baseUrl,
        url: "/api/college/" + id,
        method: "GET",
        data: {},
    });
}


export type AddCollegeProps = Entity

export async function addCollegeApi(data: AddCollegeProps) {
    return await api({
        baseUrl,
        url: "/api/college",
        method: "POST",
        data,
    });
}

export type UpdateCollegeProps = Entity & {
    entityName?: string
}


export async function updateCollegeApi(id: string, data: UpdateCollegeProps) {
    return await api({
        baseUrl,
        url: "/api/college/" + id,
        method: "POST",
        data,
    });
}



export async function searchEntityName(q: string, entityType: 'college' | 'company') {
    return await api({
        baseUrl,
        url: "/api/entityName/search?q=" + q + "&t=" + entityType,
        method: "GET",
        data: {},
    });
}
