import React from "react";
import { styled, withStyle } from "baseui";
import PageHeader from "../components/Header";
import { setUser } from "../redux/actions/user";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import * as Analytics from "../analytics";
import LoadingScreen from "../components/LoadingScreen";
import Title from "../components/Title";
import Button from "../components/Button";
import ErrorPage from "../components/ErrorPage";
import { withRouter } from "react-router-dom";
import { getJobById, getPublicJobById } from "../api/job";
import Job from "../components/Job";
import Bold from "../components/Bold";
import Card from "../components/Card";
import logoDark from "../icons/logo.svg";
import { Block } from "baseui/block";
import {
  LabelMedium,
  LabelSmall,
  ParagraphSmall,
  ParagraphXSmall,
} from "baseui/typography";

const Container = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  maxWidth: "1080px",
  margin: "auto",
  marginTop: "50px",
  paddingTop: "24px",
});

const ImageWrapper = withStyle(Card, {
  marginBottom: "16px",
  background: "#FFFFFF",
  position: "relative",
});

const ImageTitle = styled("div", {
  fontWeight: "500",
  fontSize: "16px",
  textAlign: "center",
});

const HowItWorks = withStyle(Bold, {
  marginTop: "24px",
  marginBottom: "8px",
});

const Step = styled("div", ({ $theme }) => ({
  background: $theme.colors.primary,
  color: "white",
  borderRadius: "30px",
  width: "24px",
  height: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  left: "8px",
  top: "8px",
}));

const Flex = styled("div", {
  flex: 1,
});

const ActionBar = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "20px",
});

const RightBar = styled("div", {
  width: "300px",
  marginTop: "75px",
  marginLeft: "32px",
});

const LogoImage = styled("img", {
  marginLeft: "-4px",
});

const ImageSubTitle = withStyle(Bold, {
  marginTop: "-20px",
  marginBottom: "16px",
  color: "#919191",
});

const dJobs = [
  {
    id: 1,
    jobTitle: "Software Engineer",
    companyName: "ClearTrip",
    location: "Bangalore",
  },
  {
    id: 2,
    jobTitle: "Principal Engineer",
    companyName: "Global Logic",
    location: "Bangalore",
  },
  {
    id: 3,
    jobTitle: "Software Engineer III",
    companyName: "Zynga",
    location: "Bangalore",
  },
];

class PublicJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isFetched: false,
      job: {},
      isErrored: false,
      ts: 0,
    };
  }

  async componentDidMount() {
    try {
      this.setState({
        isLoading: true,
      });
      let { jobId } = this.props.match.params;
      let { job } = await getPublicJobById(jobId);
      this.setState({ job, isLoading: false, isFetched: true });
      Analytics.logEvent("page view public job", {
        jobId: job.id,
        jobTitle: job.jobTitle.name,
        company: job.company.name,
        referrer: document && document.referrer,
      });
    } catch (e) {
      this.setState({
        isLoading: false,
        isFetched: true,
        isErrored: true,
      });
    }
    try {
      const params = new URLSearchParams(this.props.location.search);
      const ts = params.get("ts");
      this.setState({ ts });
    } catch (e) {}
  }

  render() {
    if (!this.state.isFetched) {
      return <div />;
    }
    if (this.state.isLoading) {
      return <LoadingScreen />;
    }
    if (this.state.isErrored) {
      return <ErrorPage />;
    }
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Job Description</title>
        </Helmet>
        <Container>
          <Flex>
            <ActionBar>
              <Title>Job Description</Title>
            </ActionBar>
            <Block display="flex" alignItems="center" marginBottom="scale800">
              <Button
                onClick={() => {
                  Analytics.logEvent("click public job apply job");
                  if (this.state.ts) {
                    this.props.history.push("/register");
                  } else {
                    this.props.history.push(
                      "/public/applyjob/" + this.state.job.id
                    );
                  }
                }}
              >
                Apply With Resume
              </Button>
              <Block padding="scale400"></Block>
              <Button
                kind="tertiary"
                onClick={() => {
                  Analytics.logEvent("click public job similar jobs");
                  this.props.history.push("/");
                }}
              >
                View Similar Jobs
              </Button>
            </Block>
            <Block backgroundColor="#fff8f1">
              <Job job={this.state.job} isAnonymous={true} />
            </Block>
            <Block display="flex" alignItems="center" marginTop="scale600">
              <Button
                onClick={() => {
                  Analytics.logEvent("click public job apply job");
                  if (this.state.ts) {
                    this.props.history.push("/register");
                  } else {
                    this.props.history.push(
                      "/public/applyjob/" + this.state.job.id
                    );
                  }
                }}
              >
                Apply With Resume
              </Button>
              <Block padding="scale400"></Block>
              <Button
                kind="tertiary"
                onClick={() => {
                  Analytics.logEvent("click public job similar jobs");
                  this.props.history.push("/");
                }}
              >
                View Similar Jobs
              </Button>
            </Block>
          </Flex>
          <RightBar>
            <div>
              <LogoImage
                src={logoDark}
                alt={"Finding Jo"}
                height={"60px"}
                width={"120px"}
              />
              <ImageSubTitle>The interview booking platform</ImageSubTitle>
            </div>
            <div>
              First get the salary offer from employers. Then start the
              interview. Remain anonymous till you accept the job offer.
            </div>
            <Block marginTop="scale600">
              <LabelMedium paddingBottom="scale400">Similar Jobs</LabelMedium>
              {dJobs.map(({ id, jobTitle, companyName, location }) => (
                <Card key="id" marginBottom="scale200">
                  <LabelSmall>{jobTitle}</LabelSmall>
                  <ParagraphSmall marginBottom="scale200">
                    {companyName + ", "}
                    <ParagraphXSmall color="primary400" as="span">
                      {location}
                    </ParagraphXSmall>
                  </ParagraphSmall>
                  <Button
                    size="mini"
                    kind="tertiary"
                    onClick={() => {
                      Analytics.logEvent("click public job similar list");
                      this.props.history.push("/");
                    }}
                  >
                    Apply
                  </Button>
                </Card>
              ))}
            </Block>

            <HowItWorks>How it works?</HowItWorks>
            <ImageWrapper>
              <img
                alt={""}
                width={"100%"}
                height={"200px"}
                src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/candidate1.svg"
              ></img>
              <ImageTitle>Create your profile for FREE</ImageTitle>
              <ImageTitle>FindingJo anonymizes your profile</ImageTitle>
              <Step>1</Step>
            </ImageWrapper>
            <ImageWrapper>
              <img
                alt={""}
                width={"100%"}
                height={"200px"}
                src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/candidate2.svg"
              ></img>
              <ImageTitle>
                Employers will first give you the salary offer. Then starts the
                interview process.
              </ImageTitle>
              <Step>2</Step>
            </ImageWrapper>
            <ImageWrapper>
              <img
                alt={""}
                width={"100%"}
                height={"200px"}
                src="https://storage.googleapis.com/quickhire-stage/assets/tutorial/v1/candidate3.svg"
              ></img>
              <ImageTitle>
                Reveal the identity only if you want to accept the salary offer
                and start the interview.
              </ImageTitle>
              <Step>3</Step>
            </ImageWrapper>
          </RightBar>
        </Container>

        <PageHeader headerWidth={"1060px"} notLoggedIn />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { setUser }
)(withRouter(PublicJob));
