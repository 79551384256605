import React from "react";
import { Textarea } from "baseui/textarea";

const StyledTextarea = (props) => (
  <Textarea
    {...props}
    overrides={{
      Input: {
        style: ({ $theme }) => {
          return {
            resize: props.resize ? "vertical" : "none",
            height: props.height || "100px",
          };
        },
      },
      Root: {
        style: ({ $theme }) => {
          return {
            borderRadius: $theme.borders.radius200,
          };
        },
      },
      InputContainer: {
        style: ({ $theme }) => {
          return {
            borderRadius: $theme.borders.radius200,
            background: $theme.colors.background,
            // backgroundColor: "#ffffff",
            // borderTopWidth: "1px",
            // borderBottomWidth: "1px",
            // borderLeftWidth: "1px",
            // borderRightWidth: "1px",
            // borderTopLeftRadius: "4px",
            // borderTopRightRadius: "4px",
            // borderBottomLeftRadius: "4px",
            // borderBottomRightRadius: "4px",
          };
        },
      },
    }}
  >
    {props.children}
  </Textarea>
);

export default StyledTextarea;
