import React from "react";
import { withRouter } from "react-router-dom";
import SidebarItem from "./SidebarItem";

class SidebarDetails extends React.Component {
  onPromoteProfileChange() {}

  render() {
    return (
      <div>
        <SidebarItem
          icon={"arrow-left"}
          enabled
          title={"Go back"}
          onClick={() => {
            if (this.props.backUrl) {
              this.props.history.push(this.props.backUrl);
              return;
            }
            if (this.props.onBack) {
              this.props.onBack();
              return;
            }
            this.props.history.goBack();
          }}
        />
      </div>
    );
  }
}

export default withRouter(SidebarDetails);
