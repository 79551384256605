import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import React from "react";
import { ReactSVG } from "react-svg";
import addIcon from "../icons/add.svg";
import closeIcon from "../icons/close.svg";

const Add = (props) => <img height={props.height} alt={"Add"} src={addIcon} />;
const Close = (props) => (
  <img height={props.height} alt={"Close"} src={closeIcon} />
);

const Icon = ({ size, name, color, onClick, background, ...rest }) => {
  const [css, theme] = useStyletron();
  if (!color) {
    color =
      background === "dark" ? theme.colors.background : theme.colors.primary;
  }
  const style = `width: ${size}px;height: ${size}px;`; //color: ${color}`;
  return (
    <Block
      as="span"
      color={color}
      className={css({
        lineHeight: "0px",
        verticalAlign: "middle",
      })}
    >
      <ReactSVG
        {...rest}
        onClick={onClick}
        beforeInjection={(svg) => {
          svg.setAttribute("style", style);
        }}
        src={`https://storage.googleapis.com/quickhire-stage/assets/icons/${name}.svg`}
      />
    </Block>
  );
};

export { Add, Close, Icon };

// svg.classList.add('svg-class-name')
// const [firstGElement] = [...svg.querySelectorAll('g')]
// firstGElement.setAttribute('fill', 'blue')
