import React from "react";
import { withRouter } from "react-router-dom";
import { ADMIN_JOBS_LIST } from "../util/routes";
import SidebarItem from "./SidebarItem";

class SidebarAdminCandidateSearch extends React.Component {
  onPromoteProfileChange() {}

  render() {
    const { pageId, jobId } = this.props;
    return (
      <div>
        <SidebarItem
          icon="arrow-left"
          title={"Go back"}
          enabled
          onClick={() => {
            this.props.history.push(ADMIN_JOBS_LIST);
          }}
        />
        <SidebarItem
          enabled
          icon="search"
          title={"Source Candidates"}
          selected={pageId === "candidateSearch"}
          onClick={() => {
            this.props.history.push(
              "/N0VHxB8M/admin/details/candidateSearch/" + jobId
            );
          }}
        />
        <SidebarItem
          enabled
          icon="filter"
          title={"Candidate Funnel"}
          selected={pageId === "candidateFunnel"}
          onClick={() => {
            this.props.history.push(
              "/N0VHxB8M/admin/details/candidateFunnel/" + jobId
            );
          }}
        />
        <SidebarItem
          enabled
          icon="check-circle"
          title={"Hand Picked"}
          selected={pageId === "handpicked"}
          onClick={() => {
            this.props.history.push(
              "/N0VHxB8M/admin/details/handpicked/" + jobId
            );
          }}
        />
        <SidebarItem
          enabled
          icon="trash-2"
          title={"Bin"}
          selected={pageId === "handpicked-bin"}
          onClick={() => {
            this.props.history.push(
              "/N0VHxB8M/admin/details/handpicked-bin/" + jobId
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(SidebarAdminCandidateSearch);
