import React, { useEffect, useState } from "react";
import {
  Route,
  useRouteMatch,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import PageHeader from "../components/Header";
import ChangePictureModel from "./ChangePictureModel";
import TutorialModel from "./TutorialModel";
import ShareJobModel from "./ShareJobModel";
import SidebarWrapper from "../components/SidebarWrapper";
import PageWrapper from "../components/PageWrapper";
import Container from "../components/Container";
import RCDashboardSearch from "./RCDashboardSearch";
import PostJob from "./PostJob";
import RCPostedJobs from "./RCPostedJobs";
import UserWallet from "./UserWallet";
import UserCompany from "./UserCompany";
import UserHMDetails from "./UserHMDetails";
import EmployerGST from "./EmployerGST";
import EmployerProfileWorkspace from "./EmployerProfileWorkspace";
import UserReviewDetails from "./UserReviewDetails";
import ShareCandidateModel from "./ShareCandidateModel";
import CandidateDetails from "./CandidateDetails";
import EmployerInterviewDetails from "./EmployerInterviewDetails";
import UserWalletWithdraw from "./UserWalletWithdraw";
import SidebarEmployerHome from "../components/SidebarEmployerHome";
import SidebarEmployerProfile from "../components/SidebarEmployerProfile";
import CreateInterviewRequest from "./CreateInterviewRequest";
import SidebarJobApplications from "../components/SidebarJobApplications";
import EmployerJobDetails from "./EmployerJobDetails";
import SidebarDetails from "../components/SidebarDetails";
import { connect, useDispatch } from "react-redux";
import { setWidgetState } from "../redux/actions/widget";
import { fetchUserConfig } from "../redux/actions/user";
import LoadingScreen from "../components/LoadingScreen";
import HandpickedCandidates from "./employer/jobs/HandpickedCandidates";
import BoughtCandidates from "./employer/jobs/BoughtCandidates";
import ShortlistedCandidates from "./employer/jobs/ShortlistedCandidates";
import { getJobById } from "../api/job";
import { toaster } from "baseui/toast";
import { getHomeRedirect, getProfileRedirect } from "../util/common";
import { Block } from "baseui/block";
import { UploadedCandidates } from "./reviewer/UploadedCandidates";
import { UploadedCandidateDetails } from "./reviewer/UploadedCandidateDetails";

const SidebarHome = ({ page }) => {
  return <SidebarWrapper></SidebarWrapper>;
};

const ReviewerHomeRoute = connect(
  (state) => ({
    user: state.user,
  }),
  { setWidgetState }
)(({ user, setWidgetState }) => {
  const [isFetchingConfig, setIsFetchingConfig] = useState(true);
  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserConfig()).then(() => setIsFetchingConfig(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Switch>
        <Route path={`${path}/uploadedCandidates`}>
          <PageWrapper>
            <SidebarHome page={"postjob"} />
            <UploadedCandidates />
          </PageWrapper>
        </Route>
      </Switch>
      <LoadingScreen show={isFetchingConfig} />
    </div>
  );
});

const ReviewerDetailsRoute = connect(
  (state) => ({
    user: state.user,
  }),
  { setWidgetState }
)(({ user, setWidgetState }) => {
  const [isFetchingConfig, setIsFetchingConfig] = useState(true);
  let { path } = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserConfig()).then(() => setIsFetchingConfig(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Switch>
        <Route path={`${path}/uploadedCandidates/:uploadedCandidateId/details`}>
          <PageWrapper>
            <SidebarHome page={"postjob"} />
            <UploadedCandidateDetails />
          </PageWrapper>
        </Route>
      </Switch>
      <LoadingScreen show={isFetchingConfig} />
    </div>
  );
});

const ReviewerRoute = ({ user }) => {
  let { path } = useRouteMatch();
  return (
    <Container>
      <Switch>
        <Route path={`${path}/home`}>
          <ReviewerHomeRoute user={user} />
        </Route>
        <Route path={`${path}/details`}>
          <ReviewerDetailsRoute user={user} />
        </Route>
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      <PageHeader showTutorial />
    </Container>
  );
};

export default ReviewerRoute;
