import React from "react";
import { Modal } from "baseui/modal";
import { SIZE } from "baseui/modal";
import { isMobile } from "react-device-detect";

export default (props) => (
  <Modal
    overrides={{
      Dialog: {
        style: ({ $theme }) => {
          return {
            borderRadius: $theme.borders.radius300,
          };
        },
      },
      Backdrop: {
        style: ({ $theme }) => {
          return {
            overflow: "scroll",
          };
        },
      },
    }}
    size={isMobile ? SIZE.auto : SIZE.default}
    {...props}
  >
    {props.children}
  </Modal>
);
