export const LOGIN = "/login";
export const REGISTER = "/register";

export const CANDIDATE_FIND_JOBS = "/candidate/home/findjobs";
export const CANDIDATE_DASHBOARD = "/candidate/home/dashboard";
export const CANDIDATE_ANONYMOUS = "/candidate/home/anonymous";
export const CANDIDATE_INTERVIEW_REQUESTS = "/candidate/home/interviewrequests";
export const CANDIDATE_TALENTSCOUT_REQUESTS =
  "/candidate/home/talentscoutrequests";
export const CANDIDATE_COMPLETE = "/candidate/home/complete";
export const CANDIDATE_APPLIED_JOBS = "/candidate/home/appliedjobs";
export const CANDIDATE_STARRED_JOBS = "/candidate/home/starredjobs";
export const CANDIDATE_PROFILE_BASIC = "/candidate/profile/basic";
export const CANDIDATE_PROFILE_EXPERIENCE = "/candidate/profile/experience";
export const CANDIDATE_PROFILE_EDUCATION = "/candidate/profile/education";
export const CANDIDATE_PROFILE_NOTICE_PERIOD =
  "/candidate/profile/notice-period";
export const CANDIDATE_PROFILE_PROJECT = "/candidate/profile/projects";
export const CANDIDATE_PROFILE_PROFILE = "/candidate/profile/profile";
export const CANDIDATE_PROFILE_SUMMARY = "/candidate/profile/summary";
export const CANDIDATE_PROFILE_PREMIUM = "/candidate/profile/premium";
export const CANDIDATE_PROFILE_VERIFICATIONS =
  "/candidate/profile/verifications";
export const CANDIDATE_PROFILE_EXPECTATION = "/candidate/profile/salary";

export const EMPLOYER_SEARCH_CANDIDATES = "/employer/home/search";
export const EMPLOYER_PROFILE_COMPANY = "/employer/profile/company";
export const EMPLOYER_PROFILE_DETAILS = "/employer/profile/details";
export const EMPLOYER_PROFILE_WORKSPACE = "/employer/profile/workspace";
export const EMPLOYER_TAX_DETAILS = "/employer/profile/taxdetails";
export const EMPLOYER_PROFILE_REVIEW = "/employer/profile/review";
export const EMPLOYER_POSTED_JOBS = "/employer/home/postedjobs";
export const EMPLOYER_POST_JOB = "/employer/home/postjob";
export const EMPLOYER_MY_CANDIDATES = "/employer/home/mycandidates";
export const EMPLOYER_WALLET = "/employer/home/wallet";

export const TALENTSCOUT_SEARCH_CANDIDATES = "/talentscout/home/search";
export const TALENTSCOUT_ASSIGNED_JDS = "/talentscout/home/jobs";
export const TALENTSCOUT_PROFILE_DETAILS = "/talentscout/profile/details";
export const TALENTSCOUT_PROFILE_PAYMENT = "/talentscout/profile/payment";
export const TALENTSCOUT_PROFILE_VERIFY = "/talentscout/profile/verify";
export const TALENTSCOUT_PROFILE_REVIEW = "/talentscout/profile/review";
export const TALENTSCOUT_TAX_DETAILS = "/talentscout/profile/taxdetails";
export const TALENTSCOUT_MY_CANDIDATES = "/talentscout/home/referrals";
export const TALENTSCOUT_REFERRALS = "/talentscout/home/referrals";
export const TALENTSCOUT_UPLOAD_CANDIDATE = "/talentscout/home/upload";
export const TALENTSCOUT_SAVED_CANDIDATE = "/talentscout/home/saved";
export const TALENTSCOUT_WALLET = "/talentscout/home/wallet";

export const ADMIN_CANDIDATE_LIST = "/N0VHxB8M/admin/home/candidates";
export const ADMIN_CANDIDATE_SUBMISSION_LIST =
  "/N0VHxB8M/admin/home/submissions";
export const ADMIN_TALENTSCOUT_LIST = "/N0VHxB8M/admin/home/talentscouts";
export const ADMIN_EMPLOYER_LIST = "/N0VHxB8M/admin/home/employers";
// export const ADMIN_TALENTSCOUT_DETAILS =
//   "/admin/details/talentscout-review/{talentscoutId}";
// export const ADMIN_EMPLOYER_DETAILS =
//   "/admin/details/employer-review/{employerId}";
export const ADMIN_TSREQUEST_LIST = "/N0VHxB8M/admin/home/tsrequests";
export const ADMIN_IDPROOFS_LIST = "/N0VHxB8M/admin/home/idproofs";
export const ADMIN_USERS_LIST = "/N0VHxB8M/admin/home/users";
export const ADMIN_INTERVIEW_REQUESTS =
  "/N0VHxB8M/admin/home/interview-requests";
export const ADMIN_JOBS_LIST = "/N0VHxB8M/admin/home/jobs";
export const ADMIN_JOB_APPLICATIONS = "/N0VHxB8M/admin/home/job-applications";
export const ADMIN_TALENTSCOUT_WITHDRAWAL_LIST =
  "/N0VHxB8M/admin/home/talentscout-withdrawals";
export const ADMIN_EMPLOYER_WITHDRAWAL_LIST =
  "/N0VHxB8M/admin/home/employer-withdrawals";
export const ADMIN_VERIFICATION = "/N0VHxB8M/admin/home/verification";
export const ADMIN_TSREQUEST_DETAILS =
  "/N0VHxB8M/admin/details/tsrequest/{itemId}";
export const ADMIN_TALENTSCOUT_REVIEW_DETAILS =
  "/N0VHxB8M/admin/details/talentscout-review/{itemId}";
export const ADMIN_EMPLOYER_REVIEW_DETAILS =
  "/N0VHxB8M/admin/details/employer-review/{itemId}";
export const ADMIN_TRANSACTION_LIST = "/N0VHxB8M/admin/home/transactions";
export const ADMIN_WALLET = "/N0VHxB8M/admin/home/wallet";
